import lodashDifference from "lodash/difference";
import lodashIntersection from "lodash/intersection";
import lodashZip from "lodash/zip";

/**
 * Creates an array of unique array values not included in the other provided arrays using SameValueZero for
 * equality comparisons.
 *
 * @param array The array to inspect.
 * @param values The arrays of values to exclude.
 * @return Returns the new array of filtered values.
 */
export const difference = <T>(array: ArrayLike<T>, ...values: Array<ArrayLike<T>>): T[] =>
  lodashDifference(array, ...values);

/**
 * Creates an array of unique values that are included in all of the provided arrays using SameValueZero for
 * equality comparisons.
 *
 * @param arrays The arrays to inspect.
 * @return Returns the new array of shared values.
 */
export const intersection = lodashIntersection;

export const firstOrDefault = <T1, T2>(array: ArrayLike<T1>, defaultValue: T2): T1 | T2 => {
  if (array === null) {
    return defaultValue;
  }

  if (Array.isArray(array) && array.length >= 1) {
    return array[0];
  }

  return defaultValue;
};

export const singleOrDefault = <T1, T2>(
  array: ArrayLike<T1>,
  predicate: (t: T1) => boolean,
  defaultValue: T2
): T1 | T2 => {
  if (array === null || array === undefined) {
    throw new Error("array is not defined");
  }

  if (!Array.isArray(array)) {
    throw new Error("not an array");
  }

  const found = array.find(predicate);

  if (found === undefined) {
    return defaultValue;
  }

  return found;
};

/**
 * Creates an array of grouped elements, the first of which isContainedInside the first elements of the given arrays,
 * the second of which isContainedInside the second elements of the given arrays, and so on.
 *
 * @param arrays The arrays to process.
 * @return Returns the new array of grouped elements.
 */
export const zip = lodashZip;

export const mapSingleOrMultipleToArray = <T>(element: T | T[]): T[] => {
  if (Array.isArray(element)) {
    return element;
  } else {
    return [element];
  }
}
