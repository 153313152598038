import { IMoney } from "core/money/money.model";
import { Arch } from "core/tooth/tooth.model";
import { PointArea } from "core/point/point.enums";
import {
  BridgeAnchorType,
  CrownAnchorType,
  FoundationAnchorType,
  MarylandAnchorType,
} from "features/orders/order-type/anchor-type.models";

export enum PricingStrategy {
  NotSet = "not-set",
  Individual = "individual",
  Fixed = "fixed",
  PerQuantity = "quantity",
  PerArch = "per-arch",
  PerPoint = "per-point",
  PerPointWithArchBasePrice = "per-point-with-arch-base-price",
  PerCrown = "per-crown",
  PerBridgePoint = "per-bridge-point",
  PerMarylandBridgePoint = "per-maryland-bridge-point",
  PerFoundationPoint = "per-foundation-point", // suprastructures / konstrukcje protetyczne
}

export type PricingNotSet = {
  strategy: PricingStrategy.NotSet;
};

export type IndividualPricing = {
  strategy: PricingStrategy.Individual;
};

export type FixedPricing = {
  strategy: PricingStrategy.Fixed;
  fixedPrice: IMoney;
};

export type PerQuantityPricing = {
  strategy: PricingStrategy.PerQuantity;
  pricePerItem: IMoney;
};

export type PerArchPricing = {
  strategy: PricingStrategy.PerArch;
  pricesPerArch: Record<Arch, IMoney>;
};

export type PerPointPricing = {
  strategy: PricingStrategy.PerPoint;
  pricesPerPoint: Record<PointArea, IMoney>;
};

export type PerPointWithArchBasePricing = {
  strategy: PricingStrategy.PerPointWithArchBasePrice;
  pricePerPoint: IMoney,
  basePriceForArch: IMoney,
};

export type PerCrownPricing = {
  strategy: PricingStrategy.PerCrown;
  pricesPerCrown: Record<PointArea, Record<CrownAnchorType, IMoney>>;
};

export type PerBridgePointPricing = {
  strategy: PricingStrategy.PerBridgePoint;
  pricesPerBridgePoint: Record<PointArea, Record<BridgeAnchorType, IMoney>>;
};

export type PerMarylandBridgePointPricing = {
  strategy: PricingStrategy.PerMarylandBridgePoint;
  pricesPerMarylandBridgePoint: Record<PointArea, Record<MarylandAnchorType, IMoney>>;
};

export type PerFoundationPointPricing = {
  strategy: PricingStrategy.PerFoundationPoint;
  pricesPerFoundationPoint: Record<FoundationAnchorType, IMoney>;
};

export type Pricing =
  | PricingNotSet
  | IndividualPricing
  | PerQuantityPricing
  | FixedPricing
  | PerArchPricing
  | PerPointWithArchBasePricing
  | PerPointPricing
  | PerCrownPricing
  | PerBridgePointPricing
  | PerMarylandBridgePointPricing
  | PerFoundationPointPricing;


