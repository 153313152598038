import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Breadcrumb, PageHeader, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import ErrorScreen from "components/errors/ErrorScreen";
import InvoicingApiContext from "../../invoice.context";
import { ICreateBillingStatementRequest } from "../../models/invoicing.action-models";
import useDentists from "../../../dentist/hooks/useDentists";
import CreateInvoiceWizard from "features/invoicing/pages/create-invoice-page/components/CreateInvoiceWizard";

export interface ICreateInvoiceWizardProps {}

const CreateBillingStatementPage: React.FunctionComponent<ICreateInvoiceWizardProps> = () => {
  const api = React.useContext(InvoicingApiContext);
  const { getAllDentistsResponse } = useDentists();

  const handleCreateInvoice = React.useCallback(
    async (createInvoiceRequest: ICreateBillingStatementRequest) =>
      await api.createBillingStatement(createInvoiceRequest),
    [api]
  );

  if (getAllDentistsResponse === undefined) {
    return <Spin spinning={true} />;
  }

  if (getAllDentistsResponse.isErr()) {
    return <ErrorScreen error={getAllDentistsResponse.err().unwrap()} />;
  }

  return (
    <>
      <FormattedMessage id={"page-titles.create-invoice"} defaultMessage={"Nowa Faktura"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>{" "}
          </Helmet>
        )}
      </FormattedMessage>
      <div className={"w-full"}>
        <HeaderContent>
          <Breadcrumb className="px-4 py-2">
            <Breadcrumb.Item>
              <Links.Home>
                <HomeOutlined />
              </Links.Home>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Links.CreateBillingStatement />
            </Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={<FormattedMessage id="create-order-wizard.create-invoice" defaultMessage="Generuj rozliczenie" />}
          />
        </HeaderContent>
        {getAllDentistsResponse.map((dentists) => <CreateInvoiceWizard dentists={dentists} />).unwrap()}
      </div>
    </>
  );
};

export default CreateBillingStatementPage;
