import * as React from "react";
import { IOrderId } from "features/orders/order.model";
import { ISODateTimeString } from "core/time/time.model";
import { IDentist} from "features/dentist/dentist.model";
import { Space} from "antd";
import OrderApiContext from "../../../../../orders/order.context";
import { IPaginatedList } from "core/pagination/paginated-list";
import { FormattedMessage } from "react-intl";
import PickLabOrdersTable from "features/invoicing/pages/create-invoice-page/components/pick-orders/PickOrdersTable";
import { useCounter } from "react-use";
import { Err } from "core/lib/types/error";
import {
  ILabOrdersProviderParams,
  ILabOrderTableEntry,
} from "features/orders/pages/list/components/lab-orders-table/lab-orders-table.models";
import NextPrevButtons from "components/next-prev-buttons/NextPrevButtons";

interface IPickOrdersStepProps {
  counterparties: IDentist[];
  dentists: IDentist[];
  onBack: () => void;
  onSubmit: (values: { finishDate: [ISODateTimeString, ISODateTimeString]; orders: IOrderId[] }) => void;
}

const PickOrders: React.FunctionComponent<IPickOrdersStepProps> = ({ counterparties, onSubmit, onBack }) => {
  const ordersApi = React.useContext(OrderApiContext);

  const [selectedOrders, setSelectedOrders] = React.useState<IOrderId[]>([]);
  const [callCount, { inc: incCallCount }] = useCounter(0);

  const dataProvider = React.useCallback(
    async (params: ILabOrdersProviderParams) => {
      const result = await ordersApi.getLabOrders(params);

      if (result.isErr()) {
        return new Err<IPaginatedList<ILabOrderTableEntry>>(result.err().unwrap());
      }

      const mappedResult = result.map((orders) => {
        const paginatedEntries: IPaginatedList<ILabOrderTableEntry> = {
          pagination: { ...orders.pagination },
          data: orders.data.map((order) => {
            const entry: ILabOrderTableEntry = {
              orderId: order.orderId,
              labId: order.labId,
              dentist: order.dentist,
              patient: order.patient,
              orderNumber: order.orderNumber,
              startDate: order.startDate,
              dueDate: order.dueDate,
              finishDate: order.finishDate,
              status: order.status,
              note: order.orderNote,
              billingStatement: order.billingStatement,
              assignedEmployees: order.assignedEmployees,
            };

            return entry;
          }),
        };

        return paginatedEntries;
      });

      if (callCount === 0) {
        setSelectedOrders(
          mappedResult
            .unwrap()
            .data.filter((e) => e.billingStatement === null)
            .map((e) => e.orderId)
        );
      }

      incCallCount();

      return mappedResult;
    },
    [callCount, incCallCount, ordersApi]
  );

  const handleNextButtonClick = React.useCallback(
    () =>
      onSubmit({
        finishDate: [null, null] as any, // TODO: 2
        orders: selectedOrders,
      }),
    [onSubmit, selectedOrders]
  );

  return (
    <div className="ml-4 py-6">
      <PickLabOrdersTable
        counterparties={counterparties}
        dataProvider={dataProvider}
        selectedOrders={selectedOrders}
        onSelectedOrdersChanged={setSelectedOrders}
      />
      <div className="ml-4 py-3">
        <Space>
          <NextPrevButtons
            nextVisibility={selectedOrders.length === 0 ? "disabled" : "visible"}
            onNextStep={handleNextButtonClick}
            onPrevStep={onBack}
          />
          <FormattedMessage
            id="pick-orders-step.selected-number-of-orders-message"
            defaultMessage="{ count, plural,
                =0 {nie wybrano żadnego zamówienia}
                one {wybrano jedno zamówienie}
                few {wybrano # zamówienia}
                many {wybrano # zamówień}
                other {wybrano # zamówień}
              }"
            values={{
              count: selectedOrders.length,
            }}
          />
        </Space>
      </div>
    </div>
  );
};

export default PickOrders;
