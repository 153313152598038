import { Arch } from "core/tooth/tooth.model";

export enum PointAreaCode {
  Front = 1,
  Back = 2,
}

export enum PointArea {
  Front = "front",
  Back = "back",
}

export enum PointId {
  // upper arch
  // from left to right ( reading order )
  P18 = 18,
  P17 = 17,
  P16 = 16,
  P15 = 15,
  P14 = 14,
  P13 = 13,
  P12 = 12,
  P11 = 11,
  P21 = 21,
  P22 = 22,
  P23 = 23,
  P24 = 24,
  P25 = 25,
  P26 = 26,
  P27 = 27,
  P28 = 28,
  // lower arch
  // from left to right ( reading order )
  P48 = 48,
  P47 = 47,
  P46 = 46,
  P45 = 45,
  P44 = 44,
  P43 = 43,
  P42 = 42,
  P41 = 41,
  P31 = 31,
  P32 = 32,
  P33 = 33,
  P34 = 34,
  P35 = 35,
  P36 = 36,
  P37 = 37,
  P38 = 38,
}

export enum DotId {
  // upper arch
  P18_P17 = "P18_P17",
  P17_P16 = "P17_P16",
  P16_P15 = "P16_P15",
  P15_P14 = "P15_P14",
  P14_P13 = "P14_P13",
  P13_P12 = "P13_P12",
  P12_P11 = "P12_P11",
  P11_P21 = "P11_P21",
  P21_P22 = "P21_P22",
  P22_P23 = "P22_P23",
  P23_P24 = "P23_P24",
  P24_P25 = "P24_P25",
  P25_P26 = "P25_P26",
  P26_P27 = "P26_P27",
  P27_P28 = "P27_P28",
  // lower arch
  P48_P47 = "P48_P47",
  P47_P46 = "P47_P46",
  P46_P45 = "P46_P45",
  P45_P44 = "P45_P44",
  P44_P43 = "P44_P43",
  P43_P42 = "P43_P42",
  P42_P41 = "P42_P41",
  P41_P31 = "P41_P31",
  P31_P32 = "P31_P32",
  P32_P33 = "P32_P33",
  P33_P34 = "P33_P34",
  P34_P35 = "P34_P35",
  P35_P36 = "P35_P36",
  P36_P37 = "P36_P37",
  P37_P38 = "P37_P38",
}

export const pointToUniversalIndexMap: Readonly<Record<PointId, number>> = {
  [PointId.P18]: 1,
  [PointId.P17]: 2,
  [PointId.P16]: 3,
  [PointId.P15]: 4,
  [PointId.P14]: 5,
  [PointId.P13]: 6,
  [PointId.P12]: 7,
  [PointId.P11]: 8,
  [PointId.P21]: 9,
  [PointId.P22]: 10,
  [PointId.P23]: 11,
  [PointId.P24]: 12,
  [PointId.P25]: 13,
  [PointId.P26]: 14,
  [PointId.P27]: 15,
  [PointId.P28]: 16,
  [PointId.P38]: 17,
  [PointId.P37]: 18,
  [PointId.P36]: 19,
  [PointId.P35]: 20,
  [PointId.P34]: 21,
  [PointId.P33]: 22,
  [PointId.P32]: 23,
  [PointId.P31]: 24,
  [PointId.P41]: 25,
  [PointId.P42]: 26,
  [PointId.P43]: 27,
  [PointId.P44]: 28,
  [PointId.P45]: 29,
  [PointId.P46]: 30,
  [PointId.P47]: 31,
  [PointId.P48]: 32,
};

export const pointToReadingIndexMap: Readonly<Record<PointId, number>> = {
  [PointId.P18]: 1,
  [PointId.P17]: 2,
  [PointId.P16]: 3,
  [PointId.P15]: 4,
  [PointId.P14]: 5,
  [PointId.P13]: 6,
  [PointId.P12]: 7,
  [PointId.P11]: 8,
  [PointId.P21]: 9,
  [PointId.P22]: 10,
  [PointId.P23]: 11,
  [PointId.P24]: 12,
  [PointId.P25]: 13,
  [PointId.P26]: 14,
  [PointId.P27]: 15,
  [PointId.P28]: 16,
  [PointId.P48]: 17,
  [PointId.P47]: 18,
  [PointId.P46]: 19,
  [PointId.P45]: 20,
  [PointId.P44]: 21,
  [PointId.P43]: 22,
  [PointId.P42]: 23,
  [PointId.P41]: 24,
  [PointId.P31]: 25,
  [PointId.P32]: 26,
  [PointId.P33]: 27,
  [PointId.P34]: 28,
  [PointId.P35]: 29,
  [PointId.P36]: 30,
  [PointId.P37]: 31,
  [PointId.P38]: 32,
};

export const pointToArchMap: Readonly<Record<PointId, Arch>> = {
  [PointId.P18]: Arch.UpperArch,
  [PointId.P17]: Arch.UpperArch,
  [PointId.P16]: Arch.UpperArch,
  [PointId.P15]: Arch.UpperArch,
  [PointId.P14]: Arch.UpperArch,
  [PointId.P13]: Arch.UpperArch,
  [PointId.P12]: Arch.UpperArch,
  [PointId.P11]: Arch.UpperArch,
  [PointId.P21]: Arch.UpperArch,
  [PointId.P22]: Arch.UpperArch,
  [PointId.P23]: Arch.UpperArch,
  [PointId.P24]: Arch.UpperArch,
  [PointId.P25]: Arch.UpperArch,
  [PointId.P26]: Arch.UpperArch,
  [PointId.P27]: Arch.UpperArch,
  [PointId.P28]: Arch.UpperArch,
  [PointId.P48]: Arch.LowerArch,
  [PointId.P47]: Arch.LowerArch,
  [PointId.P46]: Arch.LowerArch,
  [PointId.P45]: Arch.LowerArch,
  [PointId.P44]: Arch.LowerArch,
  [PointId.P43]: Arch.LowerArch,
  [PointId.P42]: Arch.LowerArch,
  [PointId.P41]: Arch.LowerArch,
  [PointId.P31]: Arch.LowerArch,
  [PointId.P32]: Arch.LowerArch,
  [PointId.P33]: Arch.LowerArch,
  [PointId.P34]: Arch.LowerArch,
  [PointId.P35]: Arch.LowerArch,
  [PointId.P36]: Arch.LowerArch,
  [PointId.P37]: Arch.LowerArch,
  [PointId.P38]: Arch.LowerArch,
};

export const archToPointsArrayMap: Readonly<Record<Arch, PointId[]>> = {
  [Arch.UpperArch]: [
    PointId.P18,
    PointId.P17,
    PointId.P16,
    PointId.P15,
    PointId.P14,
    PointId.P13,
    PointId.P12,
    PointId.P11,
    PointId.P21,
    PointId.P22,
    PointId.P23,
    PointId.P24,
    PointId.P25,
    PointId.P26,
    PointId.P27,
    PointId.P28,
  ],
  [Arch.LowerArch]: [
    PointId.P48,
    PointId.P47,
    PointId.P46,
    PointId.P45,
    PointId.P44,
    PointId.P43,
    PointId.P42,
    PointId.P41,
    PointId.P31,
    PointId.P32,
    PointId.P33,
    PointId.P34,
    PointId.P35,
    PointId.P36,
    PointId.P37,
    PointId.P38,
  ],
};

export const pointToPointArea: Readonly<Record<PointId, PointArea>> = {
  [PointId.P18]: PointArea.Back,
  [PointId.P17]: PointArea.Back,
  [PointId.P16]: PointArea.Back,
  [PointId.P15]: PointArea.Back,
  [PointId.P14]: PointArea.Back,
  [PointId.P13]: PointArea.Front,
  [PointId.P12]: PointArea.Front,
  [PointId.P11]: PointArea.Front,
  [PointId.P21]: PointArea.Front,
  [PointId.P22]: PointArea.Front,
  [PointId.P23]: PointArea.Front,
  [PointId.P24]: PointArea.Back,
  [PointId.P25]: PointArea.Back,
  [PointId.P26]: PointArea.Back,
  [PointId.P27]: PointArea.Back,
  [PointId.P28]: PointArea.Back,
  [PointId.P48]: PointArea.Back,
  [PointId.P47]: PointArea.Back,
  [PointId.P46]: PointArea.Back,
  [PointId.P45]: PointArea.Back,
  [PointId.P44]: PointArea.Back,
  [PointId.P43]: PointArea.Front,
  [PointId.P42]: PointArea.Front,
  [PointId.P41]: PointArea.Front,
  [PointId.P31]: PointArea.Front,
  [PointId.P32]: PointArea.Front,
  [PointId.P33]: PointArea.Front,
  [PointId.P34]: PointArea.Back,
  [PointId.P35]: PointArea.Back,
  [PointId.P36]: PointArea.Back,
  [PointId.P37]: PointArea.Back,
  [PointId.P38]: PointArea.Back,
};

export const dotToLinkedPoints: Readonly<Record<DotId, [PointId, PointId]>> = {
  [DotId.P18_P17]: [PointId.P18, PointId.P17],
  [DotId.P17_P16]: [PointId.P17, PointId.P16],
  [DotId.P16_P15]: [PointId.P16, PointId.P15],
  [DotId.P15_P14]: [PointId.P15, PointId.P14],
  [DotId.P14_P13]: [PointId.P14, PointId.P13],
  [DotId.P13_P12]: [PointId.P13, PointId.P12],
  [DotId.P12_P11]: [PointId.P12, PointId.P11],
  [DotId.P11_P21]: [PointId.P11, PointId.P21],
  [DotId.P21_P22]: [PointId.P21, PointId.P22],
  [DotId.P22_P23]: [PointId.P22, PointId.P23],
  [DotId.P23_P24]: [PointId.P23, PointId.P24],
  [DotId.P24_P25]: [PointId.P24, PointId.P25],
  [DotId.P25_P26]: [PointId.P25, PointId.P26],
  [DotId.P26_P27]: [PointId.P26, PointId.P27],
  [DotId.P27_P28]: [PointId.P27, PointId.P28],
  [DotId.P48_P47]: [PointId.P48, PointId.P47],
  [DotId.P47_P46]: [PointId.P47, PointId.P46],
  [DotId.P46_P45]: [PointId.P46, PointId.P45],
  [DotId.P45_P44]: [PointId.P45, PointId.P44],
  [DotId.P44_P43]: [PointId.P44, PointId.P43],
  [DotId.P43_P42]: [PointId.P43, PointId.P42],
  [DotId.P42_P41]: [PointId.P42, PointId.P41],
  [DotId.P41_P31]: [PointId.P41, PointId.P31],
  [DotId.P31_P32]: [PointId.P31, PointId.P32],
  [DotId.P32_P33]: [PointId.P32, PointId.P33],
  [DotId.P33_P34]: [PointId.P33, PointId.P34],
  [DotId.P34_P35]: [PointId.P34, PointId.P35],
  [DotId.P35_P36]: [PointId.P35, PointId.P36],
  [DotId.P36_P37]: [PointId.P36, PointId.P37],
  [DotId.P37_P38]: [PointId.P37, PointId.P38],
};

export const dotToExtendedLinkedPoints: Readonly<
  Record<DotId, [PointId | null, PointId | null, PointId | null, PointId | null]>
> = {
  [DotId.P18_P17]: [PointId.P18, PointId.P18, PointId.P17, PointId.P16],
  [DotId.P17_P16]: [PointId.P18, PointId.P17, PointId.P16, PointId.P15],
  [DotId.P16_P15]: [PointId.P17, PointId.P16, PointId.P15, PointId.P14],
  [DotId.P15_P14]: [PointId.P16, PointId.P15, PointId.P14, PointId.P13],
  [DotId.P14_P13]: [PointId.P15, PointId.P14, PointId.P13, PointId.P12],
  [DotId.P13_P12]: [PointId.P14, PointId.P13, PointId.P12, PointId.P11],
  [DotId.P12_P11]: [PointId.P13, PointId.P12, PointId.P11, PointId.P21],
  [DotId.P11_P21]: [PointId.P12, PointId.P11, PointId.P21, PointId.P22],
  [DotId.P21_P22]: [PointId.P11, PointId.P21, PointId.P22, PointId.P23],
  [DotId.P22_P23]: [PointId.P21, PointId.P22, PointId.P23, PointId.P24],
  [DotId.P23_P24]: [PointId.P22, PointId.P23, PointId.P24, PointId.P25],
  [DotId.P24_P25]: [PointId.P23, PointId.P24, PointId.P25, PointId.P26],
  [DotId.P25_P26]: [PointId.P24, PointId.P25, PointId.P26, PointId.P27],
  [DotId.P26_P27]: [PointId.P25, PointId.P26, PointId.P27, PointId.P28],
  [DotId.P27_P28]: [PointId.P26, PointId.P27, PointId.P28, PointId.P28],
  //
  [DotId.P48_P47]: [PointId.P48, PointId.P48, PointId.P47, PointId.P46],
  [DotId.P47_P46]: [PointId.P48, PointId.P47, PointId.P46, PointId.P45],
  [DotId.P46_P45]: [PointId.P47, PointId.P46, PointId.P45, PointId.P44],
  [DotId.P45_P44]: [PointId.P46, PointId.P45, PointId.P44, PointId.P43],
  [DotId.P44_P43]: [PointId.P45, PointId.P44, PointId.P43, PointId.P42],
  [DotId.P43_P42]: [PointId.P44, PointId.P43, PointId.P42, PointId.P41],
  [DotId.P42_P41]: [PointId.P43, PointId.P42, PointId.P41, PointId.P31],
  [DotId.P41_P31]: [PointId.P42, PointId.P41, PointId.P31, PointId.P32],
  [DotId.P31_P32]: [PointId.P41, PointId.P31, PointId.P32, PointId.P33],
  [DotId.P32_P33]: [PointId.P31, PointId.P32, PointId.P33, PointId.P34],
  [DotId.P33_P34]: [PointId.P32, PointId.P33, PointId.P34, PointId.P35],
  [DotId.P34_P35]: [PointId.P33, PointId.P34, PointId.P35, PointId.P36],
  [DotId.P35_P36]: [PointId.P34, PointId.P35, PointId.P36, PointId.P37],
  [DotId.P36_P37]: [PointId.P35, PointId.P36, PointId.P37, PointId.P38],
  [DotId.P37_P38]: [PointId.P36, PointId.P37, PointId.P38, PointId.P38],
};

export const pointToDot: Readonly<Record<PointId, [DotId, DotId] | [DotId]>> = {
  [PointId.P18]: [DotId.P18_P17],
  [PointId.P17]: [DotId.P18_P17, DotId.P17_P16],
  [PointId.P16]: [DotId.P17_P16, DotId.P16_P15],
  [PointId.P15]: [DotId.P16_P15, DotId.P15_P14],
  [PointId.P14]: [DotId.P15_P14, DotId.P14_P13],
  [PointId.P13]: [DotId.P14_P13, DotId.P13_P12],
  [PointId.P12]: [DotId.P13_P12, DotId.P12_P11],
  [PointId.P11]: [DotId.P12_P11, DotId.P11_P21],
  [PointId.P21]: [DotId.P11_P21, DotId.P21_P22],
  [PointId.P22]: [DotId.P21_P22, DotId.P22_P23],
  [PointId.P23]: [DotId.P22_P23, DotId.P23_P24],
  [PointId.P24]: [DotId.P23_P24, DotId.P24_P25],
  [PointId.P25]: [DotId.P24_P25, DotId.P25_P26],
  [PointId.P26]: [DotId.P25_P26, DotId.P26_P27],
  [PointId.P27]: [DotId.P26_P27, DotId.P27_P28],
  [PointId.P28]: [DotId.P27_P28],
  //
  [PointId.P48]: [DotId.P48_P47],
  [PointId.P47]: [DotId.P48_P47, DotId.P47_P46],
  [PointId.P46]: [DotId.P47_P46, DotId.P46_P45],
  [PointId.P45]: [DotId.P46_P45, DotId.P45_P44],
  [PointId.P44]: [DotId.P45_P44, DotId.P44_P43],
  [PointId.P43]: [DotId.P44_P43, DotId.P43_P42],
  [PointId.P42]: [DotId.P43_P42, DotId.P42_P41],
  [PointId.P41]: [DotId.P42_P41, DotId.P41_P31],
  [PointId.P31]: [DotId.P41_P31, DotId.P31_P32],
  [PointId.P32]: [DotId.P31_P32, DotId.P32_P33],
  [PointId.P33]: [DotId.P32_P33, DotId.P33_P34],
  [PointId.P34]: [DotId.P33_P34, DotId.P34_P35],
  [PointId.P35]: [DotId.P34_P35, DotId.P35_P36],
  [PointId.P36]: [DotId.P35_P36, DotId.P36_P37],
  [PointId.P37]: [DotId.P36_P37, DotId.P37_P38],
  [PointId.P38]: [DotId.P37_P38],
};

export const dotToDotNeighbours: Readonly<Record<DotId, { prev: DotId | null; next: DotId | null }>> = {
  [DotId.P18_P17]: { prev: null, next: DotId.P17_P16 },
  [DotId.P17_P16]: { prev: DotId.P18_P17, next: DotId.P16_P15 },
  [DotId.P16_P15]: { prev: DotId.P17_P16, next: DotId.P15_P14 },
  [DotId.P15_P14]: { prev: DotId.P16_P15, next: DotId.P14_P13 },
  [DotId.P14_P13]: { prev: DotId.P15_P14, next: DotId.P13_P12 },
  [DotId.P13_P12]: { prev: DotId.P14_P13, next: DotId.P12_P11 },
  [DotId.P12_P11]: { prev: DotId.P13_P12, next: DotId.P11_P21 },
  [DotId.P11_P21]: { prev: DotId.P12_P11, next: DotId.P21_P22 },
  [DotId.P21_P22]: { prev: DotId.P11_P21, next: DotId.P22_P23 },
  [DotId.P22_P23]: { prev: DotId.P21_P22, next: DotId.P23_P24 },
  [DotId.P23_P24]: { prev: DotId.P22_P23, next: DotId.P24_P25 },
  [DotId.P24_P25]: { prev: DotId.P23_P24, next: DotId.P25_P26 },
  [DotId.P25_P26]: { prev: DotId.P24_P25, next: DotId.P26_P27 },
  [DotId.P26_P27]: { prev: DotId.P25_P26, next: DotId.P27_P28 },
  [DotId.P27_P28]: { prev: DotId.P26_P27, next: null },
  //
  [DotId.P48_P47]: { prev: null, next: DotId.P47_P46 },
  [DotId.P47_P46]: { prev: DotId.P48_P47, next: DotId.P46_P45 },
  [DotId.P46_P45]: { prev: DotId.P47_P46, next: DotId.P45_P44 },
  [DotId.P45_P44]: { prev: DotId.P46_P45, next: DotId.P44_P43 },
  [DotId.P44_P43]: { prev: DotId.P45_P44, next: DotId.P43_P42 },
  [DotId.P43_P42]: { prev: DotId.P44_P43, next: DotId.P42_P41 },
  [DotId.P42_P41]: { prev: DotId.P43_P42, next: DotId.P41_P31 },
  [DotId.P41_P31]: { prev: DotId.P42_P41, next: DotId.P31_P32 },
  [DotId.P31_P32]: { prev: DotId.P41_P31, next: DotId.P32_P33 },
  [DotId.P32_P33]: { prev: DotId.P31_P32, next: DotId.P33_P34 },
  [DotId.P33_P34]: { prev: DotId.P32_P33, next: DotId.P34_P35 },
  [DotId.P34_P35]: { prev: DotId.P33_P34, next: DotId.P35_P36 },
  [DotId.P35_P36]: { prev: DotId.P34_P35, next: DotId.P36_P37 },
  [DotId.P36_P37]: { prev: DotId.P35_P36, next: DotId.P37_P38 },
  [DotId.P37_P38]: { prev: DotId.P36_P37, next: null },
};
