import * as React from "react";
import { FormattedMessage } from "react-intl";
import DueDateFormatter from "components/formatters/dates/DueDateFormatter";
import PatientNameFormatter from "components/formatters/patient-name/PatientNameFormatter";
import Links from "components/links/Links";
import ProTable, { ProColumns, ProTableProps } from "@ant-design/pro-table";
import OrderStatus from "components/status/order-status/OrderStatus";
import { IShortLab, NewLabId } from "features/dental-lab/dental-lab.model";
import moment from "moment";
import {
  DentistOrdersDataProvider, DentistOrderServerColumn,
  IDentistOrderTableEntry,
} from "features/orders/pages/list/components/dentist-orders-table/dentist-orders-table.models";
import { ServerSortDirection } from "features/orders/pages/list/components/orders-table.models";

interface IDentistOrderTableProps {
  dataProvider: DentistOrdersDataProvider;
  labs: IShortLab[];
}

const DentistOrdersTable: React.FunctionComponent<IDentistOrderTableProps> = (props) => {
  const tableColumns: ProColumns<IDentistOrderTableEntry>[] = [
    {
      key: "lab",
      sorter: DentistOrderServerColumn.Lab,
      valueType: "select",
      valueEnum: props.labs.reduce(
        (acc, lab) => ({
          ...acc,
          [lab.id.value]: { text: lab.name },
        }),
        {}
      ),
      title: <FormattedMessage id="common.lab-name" defaultMessage="Laboratorium" />,
      render: (_, entry) => <span>{entry.labName}</span>,
    },
    {
      key: "patient",
      title: <FormattedMessage id="common.patient" defaultMessage="Pacjent" />,
      render: (_, entry) => (entry.patient === null ? null : <PatientNameFormatter patientName={entry.patient} />),
    },
    // data stworzenia zamówienia TODO: 2
    // {
    //   key: "startDate",
    //   title: <FormattedMessage id="common.start-date" defaultMessage="Termin przyjścia" />,
    //   hideInSearch: true,
    //   render: (_, entry) =>
    //     entry.startDate !== null ? <FormattedDate value={new Date(entry.startDate)} dateStyle="short" /> : null,
    // },
    {
      key: "dueDate",
      sorter: DentistOrderServerColumn.DueDate,
      title: <FormattedMessage id="common.due-date" defaultMessage="Na kiedy" />,
      valueType: "dateRange",
      search: {
        transform: (value) => ({
          dueDateRange: { start: value[0], end: value[1] },
        }),
      },
      render: (_, entry) => (entry.dueDate !== null ? <DueDateFormatter dueDate={entry.dueDate} /> : null),
    },
    {
      key: "status",
      sorter: DentistOrderServerColumn.Status,
      title: <FormattedMessage id="common.status" defaultMessage="Status" />,
      hideInSearch: true,
      render: (status: React.ReactNode, entry, index, action) => <OrderStatus status={entry.status} />,
    },
    {
      key: "actions",
      hideInSearch: true,
      title: <FormattedMessage id="common.actions" defaultMessage="Akcje" />,
      render: (_, entry) => (
        <div className="flex flex-row flex-no-wrap items-center">
          <Links.OrderDetails
            params={{
              orderId: entry.orderId,
              orderNumber: null,
            }}
          >
            <FormattedMessage id="common.details" defaultMessage="Idź do zamówienia" />
          </Links.OrderDetails>
        </div>
      ),
    },
  ];

  const handleRequest: ProTableProps<IDentistOrderTableEntry, any>["request"] = React.useCallback(
    async (params, sort) => {
      const { pageSize = 50, current = 1, lab = null, patient = null, dueDateRange = null } = params;

      // noinspection JSObjectNullOrUndefined
      const result = await props.dataProvider({
        pagination: { pageNumber: current, pageSize: pageSize },
        sortBy:
          typeof sort !== "object"
            ? null
            : Object.keys(sort).length !== 1
            ? null
            : {
                column: Object.keys(sort)[0] as DentistOrderServerColumn,
                direction:
                  Object.values(sort)[0] === "ascend" ? ServerSortDirection.Ascending : ServerSortDirection.Descending,
              },
        labId: lab === null ? null : NewLabId(lab),
        patient: patient,
        dueDateRange:
          dueDateRange === null
            ? null
            : {
                start: moment(dueDateRange.start).toISOString(),
                end: moment(dueDateRange.end).toISOString(),
              },
      });

      if (result.isErr()) {
        return { data: [], success: false };
      }

      const data = result.unwrap();

      return {
        data: data.data,
        success: true,
        total: data.pagination.totalElements,
      };
    },
    [props]
  );

  return (
    <ProTable<IDentistOrderTableEntry>
      rowKey={(item) => item.orderId.value}
      columns={tableColumns}
      dateFormatter="string"
      headerTitle={<FormattedMessage id="common.orders" defaultMessage="Zamówienia" />}
      request={handleRequest}
      pagination={{ showTotal: (total, range) => <>{`${range[0]}-${range[1]} z ${total}`}</> }}
      search={{ filterType: "light" }}
      options={{
        fullScreen: true,
        density: true,
        reload: true,
        setting: true,
      }}
    />
  );
};

export default DentistOrdersTable;
