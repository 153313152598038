import * as React from "react";
import { Alert } from "antd";
import { FormattedMessage } from "react-intl";
import Button from "../../../../../components/button/Button";
import useBooleanFlag from "../../../../../hooks/useBooleanFlag";
import { IOrderTypeId} from "features/catalogue/order-type.model";
import { IPricingListId } from "features/pricing-lists/pricing-list.models";
import PriceEntryModalForm from "features/catalogue/prices/components/price-entry-modal/PriceEntryModal";
import { Pricing, PricingStrategy } from "core/pricing/pricing.model";
import { SpecificationType } from "features/orders/specification/order-specification";
import useAccess from "features/access/useAccess";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

export const NoPricingAlert = ({
  pricingListId,
  refreshPricingEntries,
  orderType,
}: {
  pricingListId: IPricingListId;
  orderType: {
    orderTypeId: IOrderTypeId;
    orderCode: OrderCodeEnum | null;
    name: string;
    requiredSpecification: SpecificationType;
  };
  refreshPricingEntries?: () => Promise<void | boolean>;
}) => {
  const pricingListApi = React.useContext(PricingListApiContext);
  const access = useAccess();
  const { flag: isModalVisible, check: showModal, uncheck: hideModal } = useBooleanFlag(false);

  const setPricingEntry = React.useCallback(
    async (pricing: Pricing) => {
      const result = await pricingListApi.setPricingEntry({
        pricing,
        pricingListId,
        orderTypeId: orderType.orderTypeId,
      });

      if (result.isErr()) {
        return;
      }

      await refreshPricingEntries?.();
      hideModal();
    },
    [hideModal, orderType.orderTypeId, pricingListApi, pricingListId, refreshPricingEntries]
  );

  return (
    <>
      <PriceEntryModalForm
        key={`${pricingListId.value}${orderType.orderTypeId.value}`}
        modalType={"create"}
        visible={isModalVisible}
        values={{ pricing: { strategy: PricingStrategy.NotSet } }}
        orderType={orderType}
        onSubmit={setPricingEntry}
        onCancel={hideModal}
      />
      <Alert
        className={"mt-2"}
        type="error"
        message={
          <FormattedMessage id={"order-entry.no-pricing-found-message"} defaultMessage={"Brak zdefiniowanej wyceny"} />
        }
        description={
          <div>
            <FormattedMessage
              id={"order-entry.no-pricing-found-description"}
              defaultMessage={"By stworzyć zamówienie z tą pracą proszę ustal dla niej wycenę"}
            />
            {access.canEditOrderPrices && (
              <Button className={"m-2"} onClick={showModal}>
                <FormattedMessage id="no-pricing-alert.set-price" defaultMessage="Ustaw cenę" />
              </Button>
            )}
          </div>
        }
      />
    </>
  );
};
