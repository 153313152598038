import { Language } from "i18n/i18n.models";
import enUSIntl from "antd/lib/locale/en_US";
import plPLIntl from "antd/lib/locale/pl_PL";

import "moment/locale/pl";
// import "moment/locale/en";

/* tslint:disable */
// if (!(Intl as any).PluralRules) {
//   import('@formatjs/intl-pluralrules/polyfill');
//   import('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
// }

// if (!(Intl as any).RelativeTimeFormat) {
//   import('@formatjs/intl-relativetimeformat/polyfill');
//   import('@formatjs/intl-relativetimeformat/dist/locale-data/de'); // Add locale data for de
// }
/* tslint:enable */

export const antDesignMessages: Record<Language, object> = {
    [Language.English]: enUSIntl,
    [Language.Polish]: plPLIntl,
}
