import * as React from "react";
import { ArchesSpecification, SpecificationType } from "../../../specification/order-specification";
import { Radio } from "antd";
import { Arch } from "core/tooth/tooth.model";
import { FormattedMessage } from "react-intl";

interface IOrderEntrySpecificationProps {
  specification: ArchesSpecification;
  onChange?: (specification: ArchesSpecification) => void;
}

enum ArchesOption {
  OnlyUpperArch = "upper-only",
  OnlyLowerArch = "lower-only",
  LowerAndUpperArch = "upper-and-lower",
}

const map = {
  fromSpecificationToOption: (spec: ArchesSpecification) => {
    if (spec.arches.includes(Arch.UpperArch) && spec.arches.includes(Arch.LowerArch)) {
      return ArchesOption.LowerAndUpperArch;
    } else if (spec.arches.includes(Arch.UpperArch)) {
      return ArchesOption.OnlyUpperArch;
    } else if (spec.arches.includes(Arch.LowerArch)) {
      return ArchesOption.OnlyLowerArch;
    } else {
      return undefined;
    }
  },
  fromOptionToSpecification: (option: ArchesOption): ArchesSpecification => {
    switch (option) {
      case ArchesOption.OnlyUpperArch:
        return { specificationType: SpecificationType.Arches, arches: [Arch.UpperArch] };
      case ArchesOption.OnlyLowerArch:
        return { specificationType: SpecificationType.Arches, arches: [Arch.LowerArch] };
      case ArchesOption.LowerAndUpperArch:
        return { specificationType: SpecificationType.Arches, arches: [Arch.UpperArch, Arch.LowerArch] };
    }
  },
};

const options = [
  {
    label: (
      <FormattedMessage
        id={"arches-specification-controls.lower-and-upper-arch"}
        defaultMessage={"Górny i Dolny Łuk"}
      />
    ),
    value: ArchesOption.LowerAndUpperArch,
  },
  {
    label: <FormattedMessage id={"arches-specification-controls.only-upper-arch"} defaultMessage={"Górny Łuk"} />,
    value: ArchesOption.OnlyUpperArch,
  },
  {
    label: <FormattedMessage id={"arches-specification-controls.only-lower-arch"} defaultMessage={"Dolny Łuk "} />,
    value: ArchesOption.OnlyLowerArch,
  },
];

const ArchesSpecificationControls: React.FunctionComponent<IOrderEntrySpecificationProps> = ({
  specification,
  onChange,
}) => {
  const handleChange = React.useCallback(
    (e: any) => {
      onChange?.(map.fromOptionToSpecification(e.target.value));
    },
    [onChange]
  );

  return (
    <Radio.Group
      options={options}
      onChange={handleChange}
      value={map.fromSpecificationToOption(specification)}
      optionType="button"
      buttonStyle="outline"
    />
  );
};

export default ArchesSpecificationControls;
