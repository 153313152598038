import * as React from "react";
import { PatientName } from "features/orders/patient.model";
import { isNullOrEmpty } from "core/utils/string-utils";

interface IPatientNameFormatterProps {
  patientName: PatientName | null;
  whenEmpty?: React.ReactNode;
  children?: (formattedName: string) => string;
}

export const patientNameToString = (patientName: PatientName | null): string => {
  if (patientName === undefined || patientName === null) {
    return "";
  }

  const { firstName, lastName, patientCode } = patientName;

  if (!isNullOrEmpty(patientCode)) {
    return patientCode!;
  } else if (!isNullOrEmpty(lastName) && !isNullOrEmpty(firstName)) {
    return firstName! + " " + lastName!;
  } else if (isNullOrEmpty(firstName) && !isNullOrEmpty(lastName)) {
    return lastName!;
  } else if (isNullOrEmpty(lastName) && !isNullOrEmpty(firstName)) {
    return firstName!;
  } else {
    return "";
  }
};

const PatientNameFormatter: React.FunctionComponent<IPatientNameFormatterProps> = ({
  patientName,
  whenEmpty,
  children,
}) => {
  const patientNameFormatted = patientNameToString(patientName);

  if (patientNameFormatted === "" && whenEmpty !== undefined && whenEmpty !== null) {
    return <>{whenEmpty}</>;
  }

  if (typeof children === "function") {
    return <>{children(patientNameFormatted)}</>;
  }

  return <>{patientNameFormatted}</>;
};

export default PatientNameFormatter;
