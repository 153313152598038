import * as React from "react";
import classNames from "classnames";
import { Tooltip } from "antd";
import { AnchorType } from "features/orders/order-type/anchor-type.models";
import AnchorTypeFormatter from "components/formatters/anchor/AnchorTypeFormatter";

import { ReactComponent as CementedToPreparedToothIconBase } from "./assets/prepared-tooth-base.svg";
import { ReactComponent as ImplantBase } from "./assets/implant-base.svg";
import { ReactComponent as GlueIcon } from "./assets/glue.svg";
import { ReactComponent as ScrewIcon } from "./assets/screw.svg";
import { ReactComponent as PonticIconBase } from "./assets/pontic-2.svg";

import "./anchor-type-icon.scss";

const CementedToPreparedToothIcon: React.FunctionComponent<{
  isSelected: boolean;
}> = ({ isSelected }) => (
  <Tooltip title={<AnchorTypeFormatter type={AnchorType.CementedToPreparedTooth} />}>
    <div
      className={classNames("anchor-type-icon", {
        "anchor-type-icon--is-selected": isSelected,
      })}
    >
      <div className="anchor-type-icon__base anchor-type-icon__base--prepared-tooth">
        <CementedToPreparedToothIconBase />
      </div>
      <div className="anchor-type-icon__accent anchor-type-icon__accent--glue">
        <GlueIcon />
      </div>
    </div>
  </Tooltip>
);

const CementedToImplantIcon: React.FunctionComponent<{
  isSelected: boolean;
}> = ({ isSelected }) => (
  <Tooltip title={<AnchorTypeFormatter type={AnchorType.CementedToImplant} />}>
    <div
      className={classNames("anchor-type-icon", {
        "anchor-type-icon--is-selected": isSelected,
      })}
    >
      <div className="anchor-type-icon__base anchor-type-icon__base--implant">
        <ImplantBase />
      </div>
      <div className="anchor-type-icon__accent anchor-type-icon__accent--glue">
        <GlueIcon />
      </div>
    </div>
  </Tooltip>
);

const ScrewedToImplantIcon: React.FunctionComponent<{
  isSelected: boolean;
}> = ({ isSelected }) => (
  <Tooltip title={<AnchorTypeFormatter type={AnchorType.ScrewedInToImplant} />}>
    <div
      className={classNames("anchor-type-icon", {
        "anchor-type-icon--is-selected": isSelected,
      })}
    >
      <div className="anchor-type-icon__base anchor-type-icon__base--implant">
        <ImplantBase />
      </div>
      <div className="anchor-type-icon__accent anchor-type-icon__accent--screw">
        <ScrewIcon />
      </div>
    </div>
  </Tooltip>
);

const PonticIcon: React.FunctionComponent<{
  isSelected: boolean;
}> = ({ isSelected }) => (
  <Tooltip title={<AnchorTypeFormatter type={AnchorType.Pontic} />}>
    <div
      className={classNames("anchor-type-icon", {
        "anchor-type-icon--is-selected": isSelected,
      })}
    >
      <div className="anchor-type-icon__base anchor-type-icon__base--pontic">
        <PonticIconBase />
      </div>
    </div>
  </Tooltip>
);

interface IAnchorTypeIconProps {
  isSelected?: boolean;
  anchorType: AnchorType;
}

export const AnchorTypeIcon: React.FunctionComponent<IAnchorTypeIconProps> = ({
  anchorType,
  isSelected = false,
}) => {
  switch (anchorType) {
    case AnchorType.CementedToImplant:
      return <CementedToImplantIcon isSelected={isSelected} />;
    case AnchorType.CementedToPreparedTooth:
      return <CementedToPreparedToothIcon isSelected={isSelected} />;
    case AnchorType.Pontic:
      return <PonticIcon isSelected={isSelected} />;
    case AnchorType.ScrewedInToImplant:
      return <ScrewedToImplantIcon isSelected={isSelected} />;
    default:
      return null;
  }
};
