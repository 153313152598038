import * as React from "react";
import { Button, Form, Modal } from "antd";
import Input from "components/forms/input/Input";
import { FormattedMessage } from "react-intl";
import { DeepPartial, Optional } from "utility-types";
import { IOrderCategory, IOrderCategoryId } from "features/catalogue/category.model";
import CategorySelect from "components/forms/category-select/CategorySelect";
import { SpecificationType } from "features/orders/specification/order-specification";
import SpecificationSelect from "components/forms/specification-select/SpecificationSelect";

export interface IOrderTypeModalFormProps {
  visible: boolean;
  values?: DeepPartial<Optional<IFormValues>>;
  categories: Pick<IOrderCategory, "id" | "code" | "name">[];
  onCancel: () => void;
  onSubmit: (values: ISubmitValues) => Promise<void>;
}

interface ISubmitValues {
  categoryId: IOrderCategoryId;
  name: string;
  specificationType: SpecificationType;
}

interface IFormValues {
  categoryId: string;
  name: string;
  specificationType: SpecificationType;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = {
  categoryId: "categoryId",
  name: "name",
  specificationType: "specificationType",
};

const OrderTypeModalForm: React.FunctionComponent<IOrderTypeModalFormProps> = ({
  visible,
  values = {},
  categories,
  onCancel,
  onSubmit,
}) => {
  const formId = "order-type-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { categoryId, name, specificationType } = store;
      await onSubmit({ categoryId: { value: categoryId, type: "order-category-id" }, name, specificationType });
      form.resetFields();
      onCancel();
    },
    [form, onCancel, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={<FormattedMessage id={"order-type-modal-form.create-order-type"} defaultMessage={"Dodaj"} />}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"order-type-modal-form.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          <FormattedMessage id={"order-type-modal-form.create-button-text"} defaultMessage={"Dodaj"} />
        </Button>,
      ]}
    >
      <Form id={formId} form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ ...values }}>
        <Form.Item
          label={<FormattedMessage id="order-type-modal-form.category" defaultMessage="Kategoria" />}
          name={propertyNames.categoryId}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="order-type-modal-form.category-is-required"
                  defaultMessage="Kategoria jest wymagana"
                />
              ),
            },
          ]}
        >
          <CategorySelect categories={categories} />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="order-type-modal-form.required-specification" defaultMessage="Typ zamówienia" />}
          name={propertyNames.specificationType}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="order-type-modal-form.required-specification-is-missing"
                  defaultMessage="Typ zamówienia jest wymagany"
                />
              ),
            },
          ]}
        >
          <SpecificationSelect />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="order-type-modal-form.order-type-name" defaultMessage="Nazwa" />}
          name={propertyNames.name}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="order-type-modal-form.name-is-required" defaultMessage="Nazwa jest wymagana" />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderTypeModalForm;