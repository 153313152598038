import * as React from "react";

function useBooleanFlag(initialState: boolean) {
  const [currentState, setState] = React.useState<boolean>(initialState);
  const set = React.useCallback((nextState: boolean) => setState(nextState), []);
  const toggle = React.useCallback(() => set(!currentState), [currentState, set]);
  const check = React.useCallback(() => set(true), [set]);
  const uncheck = React.useCallback(() => set(false), [set]);

  return {
    flag: currentState,
    toggle,
    check,
    uncheck,
    set,
  };
}

export default useBooleanFlag;
