import * as React from "react";
import { useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import LoginForm, { ILoginFormSubmitValues } from "../../components/login-form/LoginForm";
import AuthServiceContext from "../../auth-service.context";
import { Ok } from "core/lib/types/ok";
import { Path } from "core/routes/routes";
import { Err } from "core/lib/types/error";
import { AuthPageLayout } from "../../components/auth-page-layout/AuthPageLayout";
import LoginRegisterTabs from "../../components/login-register-tabs/LoginRegisterTabs";
import { Alert } from "antd";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

interface ILoginPageProps {}

const LoginPage: React.FC<ILoginPageProps> = (props) => {
  const authService = useContext(AuthServiceContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setPasswordSuccess } = state || {};

  const handleLogin = useCallback(
    async (submitValues: ILoginFormSubmitValues) => {
      const getAuthContextResult = await authService.login({
        login: submitValues.email,
        password: submitValues.password,
      });

      if (getAuthContextResult.isErr()) {
        return new Err(getAuthContextResult.err().unwrap());
      }

      navigate(Path.ListOrders);

      return new Ok(undefined);
    },
    [authService, navigate]
  );

  return (
    <>
      <FormattedMessage id={"common.login"} defaultMessage={"Logowanie"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AuthPageLayout>
        <LoginRegisterTabs activeTab={"login"} />
        {setPasswordSuccess && (
          <Alert
            message={
              <FormattedMessage
                id="login-page.password-has-changed-successfuly"
                defaultMessage="Twoje hasło zostało zmienione!"
              />
            }
            type="info"
            showIcon
            className="mb-4"
          />
        )}
        <LoginForm onLoginSubmit={handleLogin} />
      </AuthPageLayout>
    </>
  );
};

export default LoginPage;
