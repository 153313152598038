import { IValueObject } from "core/value-object/value-object";
import { ISODateOnlyString } from "core/time/time.model";
import { IMoney } from "core/money/money.model";
import { IOrderId, IOrderItemId } from "features/orders/order.model";
import { IDentist, IDentistId, IShortDentist } from "features/dentist/dentist.model";
import { IAddress } from "core/address/address.model";
import { ITaxId } from "core/taxes/tax-id.model";
import { ISODateTimeString } from "core/time/time.model";
import { ColumnSortBy, ICurrentPageParams } from "components/server-side-table/models/server-side-table.models";
import { IPersonName } from "core/person-name/person-name";
import { IBillingInfo } from "core/billing-info/billing-info";

export interface IBillingStatementId extends IValueObject<string> {
  type: "billing-statement-id";
}

export const NewBillingStatementId = (id: string): IBillingStatementId => ({ type: "billing-statement-id", value: id });

export interface IBillingStatementLineId extends IValueObject<string> {
  type: "billing-statement-line-id";
}

export const NewBillingStatementLineId = (id: string): IBillingStatementLineId => ({
  type: "billing-statement-line-id",
  value: id,
});

export interface IInvoiceItemId extends IValueObject<string> {
  type: "invoice-item-id";
}

export interface IInvoiceIssuer {
  companyName: string;
  taxId: ITaxId;
  address: IAddress;
}

export interface IBillingStatementExcerpt {
  id: IBillingStatementId;
  recipient: IShortDentist;
  statementNumber: number;
  createdOn: ISODateTimeString;
  grandTotal: IMoney;
  notes: string;
}

export interface IInvoiceExcerpt {
  invoiceId: IBillingStatementId;
  invoiceNumber: string;
  issuer: Omit<IBillingInfo, "id">;
  recipient: Omit<IBillingInfo, "id">;
  createdAt: ISODateTimeString;
  issueDate: ISODateTimeString;
  dueDate: ISODateTimeString;
  sellDate: ISODateTimeString;
  grandTotal: IMoney;
  isPaid: boolean;
  notes: string;
}

export interface InvoicedItem {
  invoicedItemId: IInvoiceItemId;
  invoiceId: IBillingStatementId;
  invoiceNumber: string | null;
  orderId: IOrderId;
  orderNumber: number | null;
  recipient: { dentistId: IDentistId; name: IPersonName } | null;
  description: string;
  patient: string | null;
  price: IMoney;
}

export interface ILineItem {
  id: IBillingStatementLineId;
  orderId: IOrderId | null;
  orderNumber: number | null;
  dentistId: IDentistId | null;
  description: string;
  patient: string;
  price: IMoney;
}

export interface IBillingStatement {
  id: IBillingStatementId;
  statementNumber: number;
  createdOn: ISODateTimeString,
  recipient: IShortDentist;
  grandTotal: IMoney;
  notes: string;
  lineItems: ILineItem[];
}

export enum InvoiceSortByColumns {
  IssueDate = "issue-date",
  CreateDate = "create-date",
  InvoiceNumber = "invoice-number",
}

export interface IInvoicesQueryParameters {
  pagination: ICurrentPageParams;
  search: string | null;
  sortBy: ColumnSortBy<InvoiceSortByColumns>;
}

export interface IInvoiceLineItem {
  number: number; // TODO: rename to LineNumber
  orderNumber: number | null;
  orderId: IOrderId | null;
  orderItemId: IOrderItemId | null;
  dentist: IDentist | null;
  patient: string | null;
  finishDate: ISODateOnlyString | null;
  name: string;
  price: IMoney;
}
