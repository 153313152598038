import * as React from "react";
import { List, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { InvoiceNumberingSchemeSection } from "./components/InvoiceNumberScheme";
import { IInvoiceIssuerDataSet, IInvoicingSettings } from "../../models/settings.models";
import { PaymentDueDateSection } from "./components/PaymentDueDate";
import { IssuerDataSets } from "./components/issuer-data-set/IssuerDataSets";
import { InvoiceNumberingSchemeCode, PaymentDueDateCode } from "../../contracts/settings.dto";
import { SectionDivider } from "../section-divider/SectionDivider";

const InvoicingSettingsSection: React.FunctionComponent<{
  settings: IInvoicingSettings;
  onInvoiceNumberingSchemeChanged: (invoiceNumberingScheme: InvoiceNumberingSchemeCode) => Promise<void>;
  onPaymentDueDateChange: (paymentDueDate: PaymentDueDateCode) => Promise<void>;
  onInvoiceIssuerDataSetChange: (invoiceIssuerDataSet: IInvoiceIssuerDataSet[]) => Promise<void>;
}> = ({ settings, onInvoiceNumberingSchemeChanged, onPaymentDueDateChange, onInvoiceIssuerDataSetChange }) => {
  const { invoiceNumberingScheme, paymentDueDate, invoiceIssuerDataSets } = settings;

  const sections = [
    <InvoiceNumberingSchemeSection value={invoiceNumberingScheme} onChange={onInvoiceNumberingSchemeChanged} />,
    <SectionDivider />,
    <PaymentDueDateSection value={paymentDueDate} onChange={onPaymentDueDateChange} />,
    <SectionDivider />,
    <IssuerDataSets values={invoiceIssuerDataSets} onChange={onInvoiceIssuerDataSetChange} />,
  ];

  return (
    <div>
      <Typography.Title level={4}>
        <FormattedMessage id="settings.invoicing" defaultMessage={"Fakturowanie"} />
      </Typography.Title>
      <List itemLayout="horizontal" dataSource={sections} renderItem={(item) => <>{item}</>} />
    </div>
  );
};

export default InvoicingSettingsSection;
