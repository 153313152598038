import * as React from "react";
import { useEffect } from "react";
import { SelectProps } from "antd/lib/select";
import { CreateGuid } from "core/guid/guid";
import { IDentist } from "features/dentist/dentist.model";
import { getFormattedNameAsString, PersonNameFormat } from "components/formatters/person-name/PersonNameFormatter";
import { isNotEmptyString } from "core/utils/utils";
import { Tag } from "antd";
import { Select } from "antd";

export interface IDentistSelectProps extends SelectProps<string> {
  dentists: IDentist[];
}

const fixAutocomplete = () => {
  document?.querySelectorAll(".ant-select-selector input").forEach((e) => {
    e.setAttribute("autocomplete", CreateGuid());
  });
};

const DentistSelect = React.forwardRef<any, IDentistSelectProps>((props, ref: React.Ref<any>) => {
  const { children: _, dentists: unsortedDentists, ...rest } = props;

  const dentists = unsortedDentists.sort((d1, d2) => {
    if (d1.name.lastName < d2.name.lastName) {
      return -1;
    } else if (d1.name.lastName > d2.name.lastName) {
      return 1;
    } else if (d1.name.firstName < d2.name.firstName) {
      return -1;
    } else if (d1.name.firstName < d2.name.firstName) {
      return 1;
    } else {
      return 0;
    }
  });

  const searchPredicate = React.useCallback(
    (input: string, option: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
      if (!isNotEmptyString(option.key)) {
        return false;
      }

      const key = option.key;
      const dentist = dentists.find((d) => d.id.value === key);

      if (dentist === undefined) {
        return false;
      }

      const searchPhrase = input.toLowerCase();

      return (
        dentist.name.lastName.toLowerCase().indexOf(searchPhrase) !== -1
      );
    },
    [dentists]
  );

  useEffect(() => {
    fixAutocomplete();
  }, []);

  return (
    <Select ref={ref} showSearch filterOption={searchPredicate} {...(rest as any)}>
      {dentists.map((dentist) => (
        <Select.Option key={dentist.id.value} value={dentist.id.value}>
          {getFormattedNameAsString({
            personName: dentist.name,
            format: PersonNameFormat.Short,
            useDrIfAvailable: true,
          })}
          {isNotEmptyString(dentist.clinicName) && (
            <Tag className={"mx-4"} color="blue">
              {dentist.clinicName}
            </Tag>
          )}
        </Select.Option>
      ))}
    </Select>
  );
});

export default DentistSelect;
