import * as React from "react";
import { useMemo } from "react";
import { Breadcrumb, Spin } from "antd";
import { useParams } from "react-router";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import useOrdersCatalogue from "features/orders/hooks/useOrdersCatalogue";
import ErrorScreen from "components/errors/ErrorScreen";
import { NewLabId } from "features/dental-lab/dental-lab.model";
import useDentistPricingListForLab from "features/pricing-lists/hooks/dentist/useDentistPricingListForLab";
import useDentistLabs from "features/dental-lab/hook/dentist/useDentistLabs";
import PricingListTable from "features/catalogue/prices/components/pricing-list-table/PricingListTable";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

export interface IDentistsPricingListForLabPageProps {}

const DentistsPricingListForLabPage: React.FunctionComponent<IDentistsPricingListForLabPageProps> = () => {
  const { labId: idString } = useParams<"labId">();
  const labId = useMemo(() => NewLabId(idString!), [idString]);

  const { getAllDentistLabs } = useDentistLabs();
  const { getOrdersCatalogue } = useOrdersCatalogue({ mode: "dentist", labId: labId });
  const { getPricingList } = useDentistPricingListForLab(labId);

  if (getAllDentistLabs == undefined || getOrdersCatalogue === undefined || getPricingList === undefined) {
    return <Spin />;
  }

  if (getAllDentistLabs?.isErr()) {
    return <ErrorScreen error={getAllDentistLabs.err().unwrap()} />;
  } else if (getOrdersCatalogue.isErr()) {
    return <ErrorScreen error={getOrdersCatalogue.err().unwrap()} />;
  } else if (getPricingList.isErr()) {
    return <ErrorScreen error={getPricingList.err().unwrap()} />;
  }

  const labs = getAllDentistLabs.unwrap();
  const orderCatalogue = getOrdersCatalogue.unwrap();
  const pricingList = getPricingList.unwrap();
  const lab = labs.find((l) => l.id.value === labId.value);

  return (
    <>
      <FormattedMessage id={"page-titles.pricing-lists"} defaultMessage={"Cenniki"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.DentistsPricingLists />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.DentistPricingList params={{ labId: labId }}>
              {lab !== undefined && <>{lab.name}</>}
            </Links.DentistPricingList>
          </Breadcrumb.Item>
        </Breadcrumb>
      </HeaderContent>
      <PageContent className={"lg:p-5"}>
        <PricingListTable
          pricingList={{
            ...pricingList,
            name: lab?.name ?? "",
          }}
          orderCatalogue={orderCatalogue}
          readonly={true}
          isDefaultPricingList={true}
        />
      </PageContent>
    </>
  );
};

export default DentistsPricingListForLabPage;
