import { FunctionComponent, ReactNode } from "react";
import { Typography, Row, Col, Rate } from "antd";
import { CheckSquareFilled, CloseSquareFilled, StarOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import {
  Bite,
  Color,
  IOrderFeedbackModelDTO,
  PreparationMargin,
  TangentialPoint,
  Translucency,
} from "features/orders/feedback/feedback.model";
import Button from "components/button/Button";

const MarkGood: FunctionComponent<{ label?: ReactNode }> = ({ label }) => (
  <>
    <CheckSquareFilled className="text-green-400 text-lg align-middle mr-2" /> {label}
  </>
);

const MarkBad: FunctionComponent<{ label: ReactNode }> = ({ label }) => (
  <>
    <CloseSquareFilled className="text-red-400 text-lg align-middle mr-2" /> {label}
  </>
);

const renderPreparationMargin = (preparation: PreparationMargin) => {
  switch (preparation) {
    case PreparationMargin.Good:
      return (
        <MarkGood label={<FormattedMessage id="order-feedback.preparation-margin.good" defaultMessage="szczelne" />} />
      );
    case PreparationMargin.Bad:
      return (
        <MarkBad label={<FormattedMessage id="order-feedback.preparation-margin.bad" defaultMessage="nieszczelne" />} />
      );
  }
};

const renderBite = (bite: Bite) => {
  switch (bite) {
    case Bite.Good:
      return <MarkGood label={<FormattedMessage id="order-feedback.bite.good" defaultMessage="ok" />} />;
    case Bite.TooHigh:
      return <MarkBad label={<FormattedMessage id="order-feedback.bite.too-high" defaultMessage="za wysoko" />} />;
    case Bite.TooLow:
      return <MarkBad label={<FormattedMessage id="order-feedback.bite.too-low" defaultMessage="za nisko" />} />;
  }
};

const renderTangentialPoint = (tangentialPoint: TangentialPoint) => {
  switch (tangentialPoint) {
    case TangentialPoint.Good:
      return <MarkGood label={<FormattedMessage id="order-feedback.tangential-point.good" defaultMessage="ok" />} />;
    case TangentialPoint.None:
      return <MarkBad label={<FormattedMessage id="order-feedback.tangential-point.none" defaultMessage="brak" />} />;
    case TangentialPoint.Tight:
      return (
        <MarkBad label={<FormattedMessage id="order-feedback.tangential-point.tight" defaultMessage="za ciasny" />} />
      );
  }
};

const renderColor = (color: Color) => {
  switch (color) {
    case Color.Good:
      return <MarkGood label={<FormattedMessage id="order-feedback.color.good" defaultMessage="ok" />} />;
    case Color.TooBright:
      return <MarkBad label={<FormattedMessage id="order-feedback.color.too-bright" defaultMessage="za jasny" />} />;
    case Color.TooDark:
      return <MarkBad label={<FormattedMessage id="order-feedback.color.too-dark" defaultMessage="za ciemny" />} />;
  }
};

const renderTranslucency = (translucency: Translucency) => {
  switch (translucency) {
    case Translucency.Good:
      return (
        <MarkGood
          label={<FormattedMessage id="order-feedback.translucency.ok" defaultMessage="ok" />}
        />
      );
    case Translucency.TooTranslucent:
      return (
        <MarkBad
          label={
            <FormattedMessage id="order-feedback.translucency.too-translucent" defaultMessage="za bardzo przezierne" />
          }
        />
      );
    case Translucency.NotEnoughTranslucent:
      return (
        <MarkBad
          label={
            <FormattedMessage id="order-feedback.translucency.not-enough-translucent" defaultMessage="opakerowe" />
          }
        />
      );
  }
};

type PropsType = {
  feedback: IOrderFeedbackModelDTO;
  onProvideDentistFeedback?: () => void;
};

const OrderFeedbackMarks = (props: PropsType) => (
  <>
    <Typography.Title level={5}>
      <FormattedMessage id="order-feedback.rating" defaultMessage="Ocena" />
    </Typography.Title>
    <Row gutter={[16, 10]} align="middle">
      <Col span={12}>
        <FormattedMessage id="order-feedback.dentist-rating" defaultMessage="Ocena od lekarza dentysty" />
      </Col>
      <Col span={12}>
        {props.feedback.dentistRating && (
          <>
            <Rate disabled value={props.feedback.dentistRating} />
          </>
        )}
        {!props.feedback.dentistRating && typeof props.onProvideDentistFeedback === "undefined" && (
          <span>{"-"}</span>
        )}
        {!props.feedback.dentistRating && typeof props.onProvideDentistFeedback === "function" && (
          <Button icon={<StarOutlined className={"pr-2"} />} onClick={props.onProvideDentistFeedback}>
            <FormattedMessage id="order-feedback.add-rating" defaultMessage="Dodaj ocenę" />
          </Button>
        )}
      </Col>
      <Col span={12}>
        <FormattedMessage id="order-feedback.preparation-margin" defaultMessage="Granica preparacji" />
      </Col>
      <Col span={12}>{renderPreparationMargin(props.feedback.preparationMargin)}</Col>
      <Col span={12}>
        <FormattedMessage id="order-feedback.bite" defaultMessage="Zgryz" />
      </Col>
      <Col span={12}>{renderBite(props.feedback.bite)}</Col>
      <Col span={12}>
        <FormattedMessage id="order-feedback.tangential-point" defaultMessage="Punkt styczny" />
      </Col>
      <Col span={12}>{renderTangentialPoint(props.feedback.tangentialPoint)}</Col>
      <Col span={12}>
        <FormattedMessage id="order-feedback.color" defaultMessage="Kolor" />
      </Col>
      <Col span={12}>{renderColor(props.feedback.color)}</Col>
      <Col span={12}>
        <FormattedMessage id="order-feedback.translucency" defaultMessage="Przezierność" />
      </Col>
      <Col span={12}>{renderTranslucency(props.feedback.translucency)}</Col>
    </Row>
  </>
);

export default OrderFeedbackMarks;
