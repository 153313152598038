import * as React from "react";
import useSWR from "swr";
import { nameof } from "ts-simple-nameof";
import CategoriesServiceContext from "features/catalogue/categories.context";
import { IOrderCatalogueService } from "../service/categories.serivce";
import useAuth from "features/auth/hooks/useAuth";
import {
  IArchiveOrderType,
  ICreateOrderCategory,
  ICreateOrderType,
  IEditOrderCategory,
  IEditOrderType,
} from "features/catalogue/category.model";
import { useCallback } from "react";

function useOrderCatalogue() {
  const { authContext } = useAuth();
  const categoriesService = React.useContext(CategoriesServiceContext);

  const { data: getOrderCatalogue, mutate } = useSWR(
    [`method: ${nameof<IOrderCatalogueService>((x) => x.getOrderCatalogue)}`, `labId: ${authContext.labId!.value}`],
    () => categoriesService.getOrderCatalogue(authContext.labId!)
  );

  const createOrderType = useCallback(
    (request: ICreateOrderType) => categoriesService.createOrderType(request),
    [categoriesService]
  );

  const archiveOrderType = useCallback(
    (request: IArchiveOrderType) => categoriesService.archiveOrderType(request),
    [categoriesService]
  );

  const editOrderType = React.useCallback(
    (data: IEditOrderType) => categoriesService.editOrderType(data),
    [categoriesService]
  );

  const createOrderCategory = useCallback(
    (request: ICreateOrderCategory) => categoriesService.createOrderCategory(request),
    [categoriesService]
  );

  const editOrderCategory = useCallback(
    (request: IEditOrderCategory) => categoriesService.editOrderCategory(request),
    [categoriesService]
  );

  return {
    getOrderCatalogue: getOrderCatalogue,
    createOrderType: createOrderType,
    createOrderCategory: createOrderCategory,
    editOrderCategory: editOrderCategory,
    editOrderType: editOrderType,
    archiveOrderType: archiveOrderType,
    isLoading: getOrderCatalogue === undefined,
    mutate,
  };
}

export default useOrderCatalogue;
