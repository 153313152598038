import * as React from "react";
import { useIntl, defineMessages } from "react-intl";
import { SelectProps } from "antd/lib/select";
import Select from "components/forms/select/Select";
import { Honorific } from "core/person-name/honorific";

const mapHonorificToHonorificName = (honorific: Honorific) => {
  switch (honorific) {
    case Honorific.Dr:
      return "Dr.";
    case Honorific.Mr:
      return "Pan";
    case Honorific.Ms:
      return "Pani";
    case Honorific.Mrs:
      return "Pani";
    case Honorific.None:
      return "- brak -";
    default:
      return "";
  }
};

interface IHonorificSelectProps extends SelectProps<Honorific> {}

const HonorificSelect: React.FunctionComponent<IHonorificSelectProps> = (props) => {
  const { children: _, value, ...rest } = props;
  const intl = useIntl();

  const allHonorifics: Honorific[] = [Honorific.Dr, Honorific.Mr, Honorific.Ms, Honorific.None];

  return (
    <Select
      showSearch
      style={{ width: 140 }}
      value={value}
      filterOption={(input, option) => (option as any).props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...(rest as any)}
    >
      {allHonorifics.map((honorific) => (
        <Select.Option key={honorific} value={honorific}>
          {intl.formatMessage({
            id: `honorific-name.${honorific}`,
            defaultMessage: `${mapHonorificToHonorificName(honorific)}`,
          })}
        </Select.Option>
      ))}
    </Select>
  );
};

export default HonorificSelect;

defineMessages({
  "honorific-name.dr": {
    id: "honorific-name.dr",
    defaultMessage: "Dr",
  },
  "honorific-name.mr": {
    id: "honorific-name.mr",
    defaultMessage: "Mr",
  },
  "honorific-name.ms": {
    id: "honorific-name.ms",
    defaultMessage: "Ms",
  },
  "honorific-name.mrs": {
    id: "honorific-name.mrs",
    defaultMessage: "Mrs",
  },
  "honorific-name.none": {
    id: "honorific-name.none",
    defaultMessage: "None",
  },
});
