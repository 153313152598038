import { ILab } from "features/dental-lab/dental-lab.model";
import { IDentalLabDTO } from "features/dental-lab/contracts/dental-lab.dto";
import { mapAddressDtoToAddress } from "contracts/common-dtos/dto.functions";
import { mapTaxIdDTOToTaxId } from "core/taxes/taxes.functions";

export const mapDentalLabDTOtoDentalLab = (dto: IDentalLabDTO): ILab => {
  return {
    id: { type: "dental-lab-id", value: dto.id },
    name: dto.name,
    address: dto.shippingAddress === null ? null : mapAddressDtoToAddress(dto.shippingAddress),
    billing:
      dto.billingAddress === null || dto.taxId === null
        ? null
        : {
            taxId: mapTaxIdDTOToTaxId(dto.taxId),
            address: mapAddressDtoToAddress(dto.billingAddress),
          },
  };
};
