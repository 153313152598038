import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import LocalizationProviders from "root/localization/LocalizationProviders";
import App from "root/App";

interface IRootProps { }

const Root: React.FunctionComponent<IRootProps> = (props) => {
  return (
    <LocalizationProviders>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalizationProviders>
  );
};

export default Root;
