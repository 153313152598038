import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Button, { IButtonProps } from "../button/Button";
import { noop } from "core/utils/function";
import useBooleanFlag from "../../hooks/useBooleanFlag";

export const SubmitButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => (
  <Button type="primary" htmlType="submit" {...props}>
    <FormattedMessage id={"common.submit"} register-form defaultMessage="Wyślij" />
  </Button>
);

export const PrevButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => (
  <Button {...props}>
    <ArrowLeftOutlined className="mx-2" />
    <FormattedMessage id="common.previous" defaultMessage="Wstecz" />
  </Button>
);

export const NextButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => (
  <Button type="primary" {...props}>
    <FormattedMessage id="common.next" defaultMessage="Dalej" />
    <ArrowRightOutlined className="mx-2" />
  </Button>
);

export const SkipButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => (
  <Button type="dashed" htmlType="button" {...props}>
    <WarningOutlined />
    <FormattedMessage id="common.skip" defaultMessage="Pomiń" />
  </Button>
);

export const SearchButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => (
  <Button type="primary" htmlType="submit" {...props}>
    <SearchOutlined className="mr-2" />
    <FormattedMessage id="common.search" defaultMessage="Szukaj" />
  </Button>
);

export const RefreshButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => (
  <Button type="text" icon={<ReloadOutlined />} htmlType="button" {...props} />
);

export const EditButton: React.FunctionComponent<IButtonProps> = ({ children, ...props }) => (
  <Button {...props}>
    <span className={"pr-2"}>
      <EditOutlined />
    </span>
    {children}
  </Button>
);

export const CreateButton: React.FunctionComponent<IButtonProps> = ({ children, ...props }) => (
  <Button icon={<PlusOutlined className={"mr-2"} />} {...props}>
    {children}
  </Button>
);

export const DeleteButton: React.FunctionComponent<IButtonProps> = ({ children, ...props }) => {
  if (children === undefined || children === null) {
    return <Button icon={<DeleteOutlined />} {...props} />;
  }

  return (
    <Button {...props}>
      <DeleteOutlined className={"pr-2"} />
      {children}
    </Button>
  );
};

export const BackArrowButton: React.FunctionComponent<IButtonProps> = ({ children, ...props }) => (
  <Button
    size={"large"}
    icon={<ArrowLeftOutlined />}
    style={{ background: "transparent", border: "none", boxShadow: "unset" }}
    {...props}
  />
);

export const AsyncButton: React.FunctionComponent<IButtonProps> = ({ ...props }) => {
  const { flag: isLoading, uncheck: finishLoading, check: startLoading } = useBooleanFlag(false);

  const handleClick = React.useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const fn = typeof props?.onClick === "function" ? props?.onClick! : noop;
      startLoading();
      await fn(event);
      finishLoading();
    },
    [finishLoading, props?.onClick, startLoading]
  );

  return <Button {...props} onClick={handleClick} loading={isLoading} />;
};
