import * as React from "react";
import { Select } from "antd";
import { FormattedMessage } from "react-intl";
import { SelectProps } from "antd/lib/select";
import { NO_COLOR, NoColor, ShadeGuide } from "features/orders/color/color.model";

export interface IShadeGuideSelectProps extends SelectProps<ShadeGuide | NoColor> {
  allowColorInNote?: boolean;
}

const ShadeGuideSelect = React.forwardRef<any, IShadeGuideSelectProps>((props, ref: React.Ref<any>) => {
  const { allowColorInNote, children: _, value, ...rest } = props;

  return (
    <Select value={value} {...rest} ref={ref}>
      {allowColorInNote && (
        <Select.Option value={NO_COLOR}>
          <FormattedMessage id={"shade-guide-select.no-color"} defaultMessage={"Kolor inny - w notatce"} />
        </Select.Option>
      )}
      <Select.Option value={ShadeGuide.VitaClassical}>Vita Classic</Select.Option>
      <Select.Option value={ShadeGuide.Vita3dMaster}>Vita 3D</Select.Option>
      <Select.Option value={ShadeGuide.Bleach}>Bleach</Select.Option>
    </Select>
  );
});

export default ShadeGuideSelect;
