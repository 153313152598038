import axios from "axios";
import { IHttpJsonRequestConfig, IHttpJsonResponse, IHttpRequestConfig } from "core/http/http.model";
import { IBaseUrlService } from "services/base-url.service";
import { IAuthContextProvider, IAuthTokenProvider } from "features/auth/auth.service";
import { IJsonApiService, JsonApiUrl } from "core/http/json-api.service";
import { IResult } from "core/lib/types/result";
import { argumentOutOfRangeError, invalidOperationError } from "core/errors/generate-error";
import { AccountType } from "features/auth/auth.model";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { Ok } from "core/lib/types/ok";
import { getDomainError, isErrorResponse } from "core/http/http.functions";
import { Err } from "core/lib/types/error";
import { ErrorCode } from "core/errors/error-code";
import TypedEmitter from "../core/event-emitter/typed-event-emitter";
import { EventName, IEvents } from "root/IEvents";
import DomainError, { IDomainError } from "../core/errors/domain-error";

class AxiosJsonApiService implements IJsonApiService {
  private readonly defaultRequestConfig: IHttpRequestConfig;
  private readonly _authTokenProvider: IAuthTokenProvider;
  private readonly _authContextProvider: IAuthContextProvider;
  private readonly _eventEmitter: TypedEmitter<IEvents>;

  constructor(
    baseUrlService: IBaseUrlService,
    authTokenProvider: IAuthTokenProvider,
    authContextProvider: IAuthContextProvider,
    eventEmitter: TypedEmitter<IEvents>
  ) {
    this.defaultRequestConfig = {
      baseURL: baseUrlService.getApiBaseUrl(),
    };

    this._authContextProvider = authContextProvider;
    this._authTokenProvider = authTokenProvider;
    this._eventEmitter = eventEmitter;
  }

  private _getConfig = (authToken: string, requestConfig?: IHttpJsonRequestConfig) => ({
    ...this.defaultRequestConfig,
    ...requestConfig,
    ...{
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...requestConfig?.headers,
      },
    },
  });

  private _retrieveAuthToken = async () => {
    try {
      return await this._authTokenProvider.getAccessToken();
    } catch (error) {
      console.error("error while retrieving auth token", error);
      return null;
    }
  };

  private _computeUrl = (url: JsonApiUrl): string => {
    if (typeof url !== "string" && typeof url !== "function") {
      throw argumentOutOfRangeError("url parameter is invalid", url);
    }

    if (typeof url === "string") {
      return url;
    }

    const authContext = this._authContextProvider.getAuthContext();

    if (authContext.userId === null) {
      throw invalidOperationError("can not preform call for unauthenticated user");
    }

    const userId = authContext.userId!;
    const tenantId = authContext.tenantId;
    const employeeId = authContext.employeeId;
    const accountType: AccountType = AccountType.LabOwner;
    const labId: ILabId | null = tenantId === null ? null : { type: "dental-lab-id", value: tenantId.value };
    return url({ userId: userId, accountType, tenantId, labId, employeeId });
  };

  get = async <TResponse>(
    url: JsonApiUrl,
    requestConfig?: IHttpJsonRequestConfig
  ): Promise<IResult<IHttpJsonResponse<TResponse>>> => {
    try {
      const authToken = await this._retrieveAuthToken();

      if (authToken === null) {
        return new Err<IHttpJsonResponse<TResponse>, IDomainError>(
          new DomainError(ErrorCode.Unauthorized, "unauthorized - no auth token found")
        );
      }

      const urlString = this._computeUrl(url);
      const axiosResponse = await axios.get<TResponse>(urlString, this._getConfig(authToken, requestConfig));

      console.info(`%c [ GET  %s] (%s) %O`, "color: blue", urlString, axiosResponse.statusText, axiosResponse.data);

      return new Ok<IHttpJsonResponse<TResponse>, IDomainError>(axiosResponse);
    } catch (error) {
      return this.mapToDomainError<TResponse>(error);
    }
  };

  private mapToDomainError = <TResponse>(error: any) => {
    const errorData = error?.response?.data;

    if (isErrorResponse(errorData)) {
      console.error(errorData);
      const domainError = getDomainError(errorData);
      this._eventEmitter.emit(EventName.ShowErrorToast, domainError);
      return new Err<IHttpJsonResponse<TResponse>, IDomainError>(domainError);
    } else if (error?.response?.status === 404) {
      console.error(error);
      return new Err<IHttpJsonResponse<TResponse>, IDomainError>(
        new DomainError(ErrorCode.RecordNotFound, "not found")
      );
    } else {
      console.error(error);
      return new Err<IHttpJsonResponse<TResponse>, IDomainError>(
        new DomainError(ErrorCode.InternalError, "unknown error")
      );
    }
  };

  private _call = async <TResponse, TRequestPayload>(
    method: "post" | "put" | "patch",
    url: JsonApiUrl,
    data?: TRequestPayload,
    requestConfig?: IHttpJsonRequestConfig
  ): Promise<IResult<IHttpJsonResponse<TResponse>>> => {
    try {
      const authToken = await this._retrieveAuthToken();

      if (authToken === null) {
        return new Err<IHttpJsonResponse<TResponse>, IDomainError>(
          new DomainError(ErrorCode.Unauthorized, "unauthorized - no auth token found")
        );
      }

      const urlString = this._computeUrl(url);
      const axiosResponse = await axios[method]<TResponse>(urlString, data, this._getConfig(authToken, requestConfig));

      console.info(
        `%c [ ${method}  %s] (%s) %O`,
        "color: blue",
        urlString,
        axiosResponse.statusText,
        axiosResponse.data
      );

      return new Ok<IHttpJsonResponse<TResponse>, IDomainError>(axiosResponse);
    } catch (error: any) {
      const errorData = error?.response?.data;
      this._eventEmitter.emit(EventName.ShowErrorToast, new DomainError(ErrorCode.InternalError, "unknown error"));

      if (isErrorResponse(errorData)) {
        console.error(errorData);
        const domainError = getDomainError(errorData);
        return new Err<IHttpJsonResponse<TResponse>, IDomainError>(domainError);
      } else {
        console.error(error);
        return new Err<IHttpJsonResponse<TResponse>, IDomainError>(
          new DomainError(ErrorCode.InternalError, "unknown error")
        );
      }
    }
  };

  post = async <TResponse, TRequestPayload>(
    url: JsonApiUrl,
    data?: TRequestPayload,
    requestConfig?: IHttpJsonRequestConfig
  ): Promise<IResult<IHttpJsonResponse<TResponse>>> => {
    return this._call("post", url, data, requestConfig);
  };

  put = async <TResponse, TRequestPayload>(
    url: JsonApiUrl,
    data?: TRequestPayload,
    requestConfig?: IHttpJsonRequestConfig
  ): Promise<IResult<IHttpJsonResponse<TResponse>>> => {
    return this._call("put", url, data, requestConfig);
  };

  patch = async <TResponse, TRequestPayload>(
    url: JsonApiUrl,
    data?: TRequestPayload,
    requestConfig?: IHttpJsonRequestConfig
  ): Promise<IResult<IHttpJsonResponse<TResponse>>> => {
    return this._call("patch", url, data, requestConfig);
  };
}

export default AxiosJsonApiService;
