import { ISubject, IObserver } from "core/lib/observer/observer.model";

class Subject<T> implements ISubject<T> {
  private observers: readonly IObserver<T>[];

  constructor() {
    this.observers = [];
  }

  attach = (observer: IObserver<T>) => {
    const isExist = this.observers.includes(observer);

    if (isExist) {
      return;
    }

    let observers = [...this.observers];
    observers.push(observer);

    this.observers = observers;
  };

  detach = (observer: IObserver<T>) => {
    const observerIndex = this.observers.indexOf(observer);

    if (observerIndex === -1) {
      return;
    }

    let observers = [...this.observers];
    observers = observers.splice(observerIndex, 1);

    this.observers = observers;
  };

  notify = (data: T) => {
    for (const observer of this.observers) {
      observer(data);
    }
  };
}

export default Subject;
