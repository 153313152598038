import { IResult } from "core/lib/types/result";
import { IPaginatedList } from "core/pagination/paginated-list";
import { IOrderId } from "features/orders/order.model";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { Patient } from "features/orders/patient.model";
import { ISODateTimeString } from "core/time/time.model";
import { Status } from "features/orders/status/order-status.model";
import { ICurrentPageParams } from "components/server-side-table/models/server-side-table.models";

export interface IBaseOrderTableEntry {
  readonly orderId: IOrderId;
  readonly labId: ILabId;
  readonly patient: Patient | null;
  readonly startDate: ISODateTimeString | null;
  readonly dueDate: ISODateTimeString | null;
  readonly finishDate: ISODateTimeString | null;
  readonly status: Status;
  readonly note: string | null;
}

export interface IPaginatedProviderParams {
  pagination: ICurrentPageParams;
}

export type IDataProvider<
  RecordType extends object,
  ProviderParameters extends IPaginatedProviderParams = IPaginatedProviderParams
> = (parameters: ProviderParameters) => Promise<IResult<IPaginatedList<RecordType>>>;

export enum ServerSortDirection {
  // do not change - without sync with backend
  Ascending = "ascending",
  Descending = "descending",
}
