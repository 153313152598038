import { useIntl } from "react-intl";
import { useMemo } from "react";
import { DomainClaim } from "./contracts/auth-contracts.dto";

export function useDomainClaim() {
  const intl = useIntl();

  const claims = useMemo<Record<DomainClaim, string>>(
    () => ({
      [DomainClaim.ORDER_CREATE]: intl.formatMessage({
        id: "domain-claim.order-create",
        defaultMessage: "create order",
      }),
      [DomainClaim.ORDER_READ]: intl.formatMessage({ id: "domain-claim.order-read", defaultMessage: "read order" }),
      [DomainClaim.ORDER_WRITE]: intl.formatMessage({ id: "domain-claim.order-write", defaultMessage: "write order" }),
      [DomainClaim.INVOICE_READ]: intl.formatMessage({
        id: "domain-claim.invoice-read",
        defaultMessage: "read invoice",
      }),
      [DomainClaim.INVOICE_WRITE]: intl.formatMessage({
        id: "domain-claim.invoice-write",
        defaultMessage: "write invoice",
      }),
      [DomainClaim.PRICES_READ]: intl.formatMessage({ id: "domain-claim.prices-read", defaultMessage: "read prices" }),
      [DomainClaim.PRICES_WRITE]: intl.formatMessage({
        id: "domain-claim.prices-write",
        defaultMessage: "write prices",
      }),
    }),
    [intl]
  );

  return claims;
}
