import * as React from "react";
import { Button, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { IInvoiceIssuerDataSet } from "../../../../models/settings.models";
import { IssuerDataSet } from "./IssuerDataSet";
import useBooleanFlag from "../../../../../../hooks/useBooleanFlag";
import { createEmptyIssuerDataSet } from "./issuer-data-set.functions";

export const InitialIssuerDataSets: React.FunctionComponent<{
  onChange: (value: IInvoiceIssuerDataSet) => Promise<void>;
}> = ({ onChange }) => {
  const emptyIssuerDataSet = createEmptyIssuerDataSet(true);
  const { flag: isAdding, check, uncheck } = useBooleanFlag(false);

  if (isAdding) {
    return (
      <List.Item.Meta
        title={<FormattedMessage id="invoicing.invoice-issuer-data" defaultMessage="Dane do wystawiania faktury" />}
        description={
          <IssuerDataSet initialIsEditing={true} value={emptyIssuerDataSet} onChange={onChange} onCancel={uncheck} />
        }
      />
    );
  }

  return (
    <List.Item.Meta
      title={<FormattedMessage id="invoicing.invoice-issuer-data" defaultMessage="Dane do wystawiania faktury" />}
      description={
        <>
          <div className={"mb-4"}>
            <FormattedMessage
              id="invoicing.no-invoice-issuer-data"
              defaultMessage="brak zapisanych danych do wystawiania faktury"
            />
          </div>
          <Button className={"mt-2"} key={"add"} onClick={check}>
            <PlusOutlined className={"mr-2"} />
            <FormattedMessage id="settings.add" defaultMessage={"Dodaj dane fakturowania"} />
          </Button>
        </>
      }
    />
  );
};
