import React from "react";
import { IntlShape } from "react-intl";
import { HistoryEventPayloadDTO, HistoryEventType } from "features/orders/contracts/order-history.dto";
import { argumentOutOfRangeError } from "core/errors/generate-error";
import StatusName from "components/status/order-status/StatusName";
import { mapStatusCodeToStatus } from "features/orders/status/order-status.mappers";
import { ArrowRightOutlined} from "@ant-design/icons";
import DateTimeChanged from "features/orders/pages/new-details/components/order-history/history-event-formatter/DateTimeChanged";
import PatientNameFormatter from "components/formatters/patient-name/PatientNameFormatter";
import ColorChanged from "features/orders/pages/new-details/components/order-history/history-event-formatter/ColorChanged";

export const historyEventFormatter = (
  intl: IntlShape,
  event: HistoryEventPayloadDTO
): {
  description: React.ReactNode;
  change: React.ReactNode;
} => {
  switch (event.eventType) {
    case HistoryEventType.Created:
      return {
        description: intl.formatMessage({ id: "order-code.created", defaultMessage: "Zamówienie stworzone" }),
        change: null,
      };
    case HistoryEventType.StatusChanged:
      return {
        description: intl.formatMessage({ id: "order-code.status-changed", defaultMessage: "Zmieniono status" }),
        change: (
          <>
            (<StatusName status={mapStatusCodeToStatus(event.statusChange.previous)} />)
            <ArrowRightOutlined className={"mx-2"} />
            (<StatusName status={mapStatusCodeToStatus(event.statusChange.next)} />)
          </>
        ),
      };
    case HistoryEventType.StartDateChanged:
      return {
        description: intl.formatMessage({
          id: "order-code.start-date-changed",
          defaultMessage: "Zmieniono termin przyjścia pracy",
        }),
        change: <DateTimeChanged previous={event.startDateChange.previous} next={event.startDateChange.next} />,
      };
    case HistoryEventType.DueDateChanged:
      return {
        description: intl.formatMessage({
          id: "order-code.due-date-changed",
          defaultMessage: "Zmieniono termin oddania pracy",
        }),
        change: <DateTimeChanged previous={event.dueDateChange.previous} next={event.dueDateChange.next} />,
      };
    case HistoryEventType.PatientChanged:
      return {
        description: intl.formatMessage({
          id: "order-code.patient-changed",
          defaultMessage: "Zaktualizowano dane pacjenta",
        }),
        change: (
          <>
            <PatientNameFormatter patientName={event.patientChange.previous} />
            <ArrowRightOutlined className={"mx-2"} />
            <PatientNameFormatter patientName={event.patientChange.next} />
          </>
        ),
      };
    case HistoryEventType.ColorChanged:
      return {
        description: intl.formatMessage({
          id: "order-code.color-changed",
          defaultMessage: "Zaktualizowano kolor pracy",
        }),
        change: <ColorChanged previous={event.colorChange.previous} next={event.colorChange.next} />,
      };
    case HistoryEventType.NoteChanged:
      return {
        description: intl.formatMessage({ id: "order-code.note-changed", defaultMessage: "Zaktualizowano notatkę:" }),
        change: event.noteChange.next,
      };
    case HistoryEventType.NumberChanged:
      return {
        description: intl.formatMessage(
          { id: "order-code.number-changed", defaultMessage: "Zmieniono numer zamówienia" },
          { number: "asd" }
        ),
        change: (
          <>
            {event.numberChange.previous}
            <ArrowRightOutlined className={"mx-2"} />
            {event.numberChange.next}
          </>
        ),
      };
    case HistoryEventType.Deleted:
      return {
        description: intl.formatMessage({ id: "order-code.order-deleted", defaultMessage: "Zamówienie usunięte" }),
        change: null,
      };
    default:
      throw argumentOutOfRangeError("eventType", event);
  }
};
