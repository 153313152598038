import { IValueObject } from "core/value-object/value-object";

export interface IFileId extends IValueObject<string> {
  type: "file-id";
}

export const NewFileId = (id: string): IFileId => ({ type: "file-id", value: id });

export interface IOrderFile {
  expiration: string;
  extension: string;
  fileId: IFileId;
  name: string;
  url: string;
}
