import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { ExplanationTooltip } from "components/common-components/CommonTooltips";
import ColorInput from "components/forms/color-input/ColorInput";
import { ColorOrNone, NO_COLOR, ShadeGuide } from "features/orders/color/color.model";

export const OrderColorControl = (props: {
  disabled?: boolean;
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    required
    label={
      <>
        <span className="mr-2">
          <FormattedMessage id="common.color" defaultMessage="Kolor" />
        </span>
        <ExplanationTooltip
          title={
            <FormattedMessage
              id={"order-form.single-color-field-explanation"}
              defaultMessage={
                "Jeśli praca jest skomplikowana i wymaga podania wielu kolorów, wpisz tutaj najbardziej dominujący kolor pracy i opisz dokładniej pracę polu z notatkami"
              }
            />
          }
        />
      </>
    }
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="orderColor"
      control={props.control}
      render={({ field: { value, onChange, ...fieldRest } }) => (
        <ColorInput
          value={value}
          disabled={props.disabled}
          onChange={(color: ColorOrNone | null | undefined) =>
            onChange(color === null || color === undefined ? NO_COLOR : color)
          }
          {...fieldRest}
          defaultGuide={ShadeGuide.VitaClassical}
        />
      )}
    />
  </Form.Item>
);
