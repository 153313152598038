import * as React from "react";
import useSWR from "swr";
import { LabApiContext } from "features/dental-lab/dental-lab.context";
import { nameof } from "ts-simple-nameof";
import { IDentalLabApi } from "features/dental-lab/service/IDentalLabApi";
import useAuth from "features/auth/hooks/useAuth";

function useDentistLabs() {
  const { authContext } = useAuth();
  const labApi = React.useContext(LabApiContext);

  const { data: getAllDentistLabs, mutate: revalidate } = useSWR(
    [`method ${nameof<IDentalLabApi>((_) => _.getDentalLabs)}`, `userId ${authContext.userId!.value}`],
    () => labApi.getDentalLabs(authContext.userId!)
  );

  return {
    getAllDentistLabs,
    revalidate,
  };
}

export default useDentistLabs;
