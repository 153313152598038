import { OrderSpecification, SpecificationType } from "features/orders/specification/order-specification";
import { SpecificationDTO, ArchCode, AnchorTypeCode } from "features/orders/contracts/orders.dto.models";
import { Arch } from "core/tooth/tooth.model";
import { argumentOutOfRangeError, notImplementedException } from "core/errors/generate-error";
import { SpecificationTypeCode } from "features/orders/order.model";
import {
  LocatedPoint,
  CrownPoint,
  BridgePoint,
  FoundationPoint,
  MarylandBridgePoint,
} from "features/orders/specification/point-specification";
import { AnchorType } from "features/orders/order-type/anchor-type.models";
import { PointId } from "core/point/point.enums";

const mapArchCodeToArch = (archCode: ArchCode): Arch => {
  switch (archCode) {
    case ArchCode.Upper:
      return Arch.UpperArch;
    case ArchCode.Lower:
      return Arch.LowerArch;
    default:
      throw argumentOutOfRangeError(`archCode`, archCode);
  }
};

const mapArchToArchCode = (arch: Arch): ArchCode => {
  switch (arch) {
    case Arch.UpperArch:
      return ArchCode.Upper;
    case Arch.LowerArch:
      return ArchCode.Lower;
    default:
      throw argumentOutOfRangeError(`arch`, arch);
  }
};

const mapAnchorTypeCodeToAnchorType = (code: AnchorTypeCode): AnchorType => {
  switch (code) {
    case AnchorTypeCode.CementedToImplant:
      return AnchorType.CementedToImplant;
    case AnchorTypeCode.ScrewedInToImplant:
      return AnchorType.ScrewedInToImplant;
    case AnchorTypeCode.CementedToPreparedTooth:
      return AnchorType.CementedToPreparedTooth;
    case AnchorTypeCode.Pontic:
      return AnchorType.Pontic;
    default:
      throw argumentOutOfRangeError(`anchorTypeCode`, code);
  }
};

const mapAnchorTypeToAnchorTypeCode = (anchorType: AnchorType): AnchorTypeCode => {
  switch (anchorType) {
    case AnchorType.CementedToImplant:
      return AnchorTypeCode.CementedToImplant;
    case AnchorType.ScrewedInToImplant:
      return AnchorTypeCode.ScrewedInToImplant;
    case AnchorType.CementedToPreparedTooth:
      return AnchorTypeCode.CementedToPreparedTooth;
    case AnchorType.Pontic:
      return AnchorTypeCode.Pontic;
    default:
      throw argumentOutOfRangeError("anchorType", anchorType);
  }
};

export const mapSpecificationTypeCodeToSpecificationType = (
  specificationTypeCode: SpecificationTypeCode
): SpecificationType => {
  switch (specificationTypeCode) {
    case SpecificationTypeCode.TextOnly:
      return SpecificationType.TextOnly;
    case SpecificationTypeCode.Quantity:
      return SpecificationType.Quantity;
    case SpecificationTypeCode.Arches:
      return SpecificationType.Arches;
    case SpecificationTypeCode.LocatedPoints:
      return SpecificationType.LocatedPoints;
    case SpecificationTypeCode.PartialDentures:
      return SpecificationType.PartialDentures;
    case SpecificationTypeCode.SurgicalGuides:
      return SpecificationType.SurgicalGuides;
    case SpecificationTypeCode.Crowns:
      return SpecificationType.Crowns;
    case SpecificationTypeCode.Bridges:
      return SpecificationType.Bridges;
    case SpecificationTypeCode.MarylandBridges:
      return SpecificationType.MarylandBridges;
    case SpecificationTypeCode.Foundations:
      return SpecificationType.Foundations;
    default:
      throw argumentOutOfRangeError(`specification type code out of range`, specificationTypeCode);
  }
};

export const mapSpecificationTypeToToSpecificationCode = (specification: SpecificationType): SpecificationTypeCode => {
  switch (specification) {
    case SpecificationType.TextOnly:
      return SpecificationTypeCode.TextOnly;
    case SpecificationType.Quantity:
      return SpecificationTypeCode.Quantity;
    case SpecificationType.Arches:
      return SpecificationTypeCode.Arches;
    case SpecificationType.LocatedPoints:
      return SpecificationTypeCode.LocatedPoints;
    case SpecificationType.PartialDentures:
      return SpecificationTypeCode.PartialDentures;
    case SpecificationType.Crowns:
      return SpecificationTypeCode.Crowns;
    case SpecificationType.Bridges:
      return SpecificationTypeCode.Bridges;
    case SpecificationType.MarylandBridges:
      return SpecificationTypeCode.MarylandBridges;
    case SpecificationType.Foundations:
      return SpecificationTypeCode.Foundations;
    default:
      throw argumentOutOfRangeError(`specification type out of range`, specification);
  }
};

export const mapSpecificationDtoToSpecification = (specificationDTO: SpecificationDTO): OrderSpecification => {
  switch (specificationDTO.specificationType) {
    case SpecificationTypeCode.Quantity: {
      const { quantity } = specificationDTO;

      return {
        specificationType: SpecificationType.Quantity,
        quantity: quantity,
      };
    }
    case SpecificationTypeCode.Arches: {
      const { arches } = specificationDTO;

      return {
        specificationType: SpecificationType.Arches,
        arches: arches.map((arch) => mapArchCodeToArch(arch)),
      };
    }
    case SpecificationTypeCode.LocatedPoints: {
      const { points = [] } = specificationDTO;

      return {
        specificationType: SpecificationType.LocatedPoints,
        points: points.map((p) => {
          const locatedPoint: LocatedPoint = {
            location: p.location,
          };

          return locatedPoint;
        }),
      };
    }
    case SpecificationTypeCode.PartialDentures: {
      const { points = [] } = specificationDTO;

      return {
        specificationType: SpecificationType.PartialDentures,
        points: points.map((p) => {
          const locatedPoint: LocatedPoint = {
            location: p.location,
          };

          return locatedPoint;
        }),
      };
    }
    case SpecificationTypeCode.Crowns: {
      const { crowns = [] } = specificationDTO;

      return {
        specificationType: SpecificationType.Crowns,
        points:
          crowns === null
            ? []
            : crowns.map((p) => {
                const crownPoint: CrownPoint = {
                  location: p.location,
                  anchor: mapAnchorTypeCodeToAnchorType(p.anchorType),
                };

                return crownPoint;
              }),
      };
    }
    case SpecificationTypeCode.Foundations: {
      const { foundations = [] } = specificationDTO;

      return {
        specificationType: SpecificationType.Foundations,
        points: foundations.map((foundation) =>
          foundation.map((fp) => {
            const foundationPoint: FoundationPoint = {
              location: fp.location,
              anchor: mapAnchorTypeCodeToAnchorType(fp.anchorType),
            };

            return foundationPoint;
          })
        ),
      };
    }
    case SpecificationTypeCode.Bridges: {
      const { bridges = [] } = specificationDTO;

      return {
        specificationType: SpecificationType.Bridges,
        points: bridges.map((b) =>
          b.map((p) => {
            const bridgePoint: BridgePoint = {
              location: p.location,
              anchor: mapAnchorTypeCodeToAnchorType(p.anchorType),
            };

            return bridgePoint;
          })
        ),
      };
    }
    case SpecificationTypeCode.MarylandBridges: {
      const { bridges = [] } = specificationDTO;

      return {
        specificationType: SpecificationType.MarylandBridges,
        points: bridges.map((b) =>
          b.map((p) => {
            const marylandBridgePoint: MarylandBridgePoint = {
              location: p.location,
              anchor: mapAnchorTypeCodeToAnchorType(p.anchorType),
            };

            return marylandBridgePoint;
          })
        ),
      };
    }
    default:
      throw notImplementedException();
  }
};

export const mapSpecificationToSpecificationDto = (specification: OrderSpecification): SpecificationDTO => {
  switch (specification.specificationType) {
    case SpecificationType.Quantity: {
      const { quantity } = specification;

      return {
        specificationType: SpecificationTypeCode.Quantity,
        quantity: quantity,
      };
    }
    case SpecificationType.Arches: {
      const { arches } = specification;

      return {
        specificationType: SpecificationTypeCode.Arches,
        arches: arches.map((arch) => mapArchToArchCode(arch)),
      };
    }
    case SpecificationType.LocatedPoints: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.LocatedPoints,
        points:
          points === null
            ? []
            : points.map((p) => {
                const locatedPoint: LocatedPoint = {
                  location: p.location,
                };

                return locatedPoint;
              }),
      };
    }
    case SpecificationType.PartialDentures: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.PartialDentures,
        points:
          points === null
            ? []
            : points.map((p) => {
                const locatedPoint: LocatedPoint = {
                  location: p.location,
                };

                return locatedPoint;
              }),
      };
    }
    case SpecificationType.SurgicalGuides: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.SurgicalGuides,
        points:
          points === null
            ? []
            : points.map((p) => {
              const locatedPoint: LocatedPoint = {
                location: p.location,
              };

              return locatedPoint;
            }),
      };
    }
    case SpecificationType.Crowns: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.Crowns,
        crowns:
          points === null
            ? []
            : points.map((p) => {
                const crownPoint: {
                  location: PointId;
                  anchorType: number;
                } = {
                  location: p.location,
                  anchorType: mapAnchorTypeToAnchorTypeCode(p.anchor),
                };

                return crownPoint;
              }),
      };
    }
    case SpecificationType.Foundations: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.Foundations,
        foundations:
          points === null
            ? []
            : points.map((foundation) =>
                foundation.map((fp) => {
                  const foundationPoint: {
                    location: PointId;
                    anchorType: number;
                  } = {
                    location: fp.location,
                    anchorType: mapAnchorTypeToAnchorTypeCode(fp.anchor),
                  };

                  return foundationPoint;
                })
              ),
      };
    }
    case SpecificationType.Bridges: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.Bridges,
        bridges:
          points === null
            ? []
            : points.map((b) =>
                b.map((p) => {
                  const bridgePoint: {
                    location: PointId;
                    anchorType: number;
                  } = {
                    location: p.location,
                    anchorType: mapAnchorTypeToAnchorTypeCode(p.anchor),
                  };

                  return bridgePoint;
                })
              ),
      };
    }
    case SpecificationType.MarylandBridges: {
      const { points } = specification;

      return {
        specificationType: SpecificationTypeCode.MarylandBridges,
        bridges:
          points === null
            ? []
            : points.map((b) =>
                b.map((p) => {
                  const bridgePoint: {
                    location: PointId;
                    anchorType: number;
                  } = {
                    location: p.location,
                    anchorType: mapAnchorTypeToAnchorTypeCode(p.anchor),
                  };

                  return bridgePoint;
                })
              ),
      };
    }
    default:
      throw notImplementedException();
  }
};
