import { IntlShape } from "react-intl";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

export const quantitativeCustomOrderItemFormatter = (orderTypeName: string, count: number) => {
  return `${count}x ${orderTypeName}`;
};

export const quantitativeOrderItemFormatter = (intl: IntlShape, orderCode: OrderCodeEnum, count: number) => {
  switch (orderCode) {
    case OrderCodeEnum.FullAcrylicDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullAcrylicDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza całkowita akrylowa}" +
            "few {# protezy całkowite akrylowe}" +
            "many {# protez całkowitych akrylowych}" +
            "other {# protez całkowitych akrylowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullAcetalDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullAcetalDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza całkowita acetalowa}" +
            "few {# protezy całkowite acetalowe}" +
            "many {# protez całkowitych acetalowych}" +
            "other {# protez całkowitych acetalowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullAcronDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullAcronDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza całkowita akronowa}" +
            "few {# protezy całkowite akronowe}" +
            "many {# protez całkowitych akronowych}" +
            "other {# protez całkowitych akronowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullNylonDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullNylonDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza całkowita nylonowa}" +
            "few {# protezy całkowite nylonowe}" +
            "many {# protez całkowitych nylonowych}" +
            "other {# protez całkowitych nylonowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PartialAcrylicDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PartialAcrylicDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza częściowa akrylowa}" +
            "few {# protezy częściowe akrylowe}" +
            "many {# protez częściowych akrylowych}" +
            "other {# protez częściowych akrylowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PartialAcetalDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PartialAcetalDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza częściowa acetalowa}" +
            "few {# protezy częściowe acetalowe}" +
            "many {# protez częściowych acetalowych}" +
            "other {# protez częściowych acetalowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PartialAcronDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PartialAcronDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza częściowa akronowa}" +
            "few {# protezy częściowe akronowe}" +
            "many {# protez częściowych akronowych}" +
            "other {# protez częściowych akronowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PartialNylonDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PartialNylonDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza częściowa nylonowa}" +
            "few {# protezy częściowe nylonowe}" +
            "many {# protez częściowych nylonowych}" +
            "other {# protez częściowych nylonowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMetalFrameworkClaspsPartialDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMetalFrameworkClaspsPartialDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza szkieletowa klamrowa CrCo}" +
            "few {# protezy szkieletowe klamrowe CrCo}" +
            "many {# protez szkieletowych klamrowych CrCo}" +
            "other {# protez szkieletowych klamrowych CrCo}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TitaniumAloyFrameworkClaspsPartialDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TitaniumAloyFrameworkClaspsPartialDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza szkieletowa klamrowa tytanowa}" +
            "few {# protezy szkieletowe klamrowe tytanowe}" +
            "many {# protez szkieletowych klamrowych tytanowych}" +
            "other {# protez szkieletowych klamrowych tytanowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PeekFrameworkClaspsPartialDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PeekFrameworkClaspsPartialDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza szkieletowa klamrowa peek}" +
            "few {# protezy szkieletowe klamrowe peek}" +
            "many {# protez szkieletowych klamrowych peek}" +
            "other {# protez szkieletowych klamrowych peek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMetalFrameworkContinuousClaspsPartialDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMetalFrameworkContinuousClaspsPartialDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza szkieletowa z klamrą ciągła CrCo}" +
            "few {# protezy szkieletowe z klamrą ciągła CrCo}" +
            "many {# protez szkieletowych z klamrą ciągła CrCo}" +
            "other {# protez szkieletowych z klamrą ciągła CrCo}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TitaniumAloyFrameworkContinuousClaspsPartialDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TitaniumAloyFrameworkContinuousClaspsPartialDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza szkieletowa z klamrą ciągła tytanowa}" +
            "few {# protezy szkieletowe z klamrą ciągła tytanowe}" +
            "many {# protez szkieletowych z klamrą ciągła tytanowych}" +
            "other {# protez szkieletowych z klamrą ciągła tytanowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PeekFrameworkContinuousClaspsPartialDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PeekFrameworkContinuousClaspsPartialDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza szkieletowa z klamrą ciągła peek}" +
            "few {# protezy szkieletowe z klamrą ciągła peek}" +
            "many {# protez szkieletowych z klamrą ciągła peek}" +
            "other {# protez szkieletowych z klamrą ciągła peek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoSplintDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoSplintDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# szynoproteza CrCo}" +
            "few {# szynoprotezy CrCo}" +
            "many {# szynoprotez CrCo}" +
            "other {# szynoprotez CrCo}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PrecisionAttachmentsPartialDenture:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PrecisionAttachmentsPartialDenture",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza do elementów precyzyjnych}" +
            "few {# protezy do elementów precyzyjnych}" +
            "many {# protez do elementów precyzyjnych}" +
            "other {# protez do elementów precyzyjnych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TitaniumAloyCrCoSplintDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TitaniumAloyCrCoSplintDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# szynoproteza tytanowa}" +
            "few {# szynoprotezy tytanowe}" +
            "many {# szynoprotez tytanowych}" +
            "other {# szynoprotez tytanowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PeekCrCoSplintDentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PeekCrCoSplintDentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# szynoproteza peek}" +
            "few {# szynoprotezy peek}" +
            "many {# szynoprotez peek}" +
            "other {# szynoprotez peek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.AcrylicOverdentures:
      return intl.formatMessage(
        {
          id: "order-item-formatter.AcrylicOverdentures",
          defaultMessage:
            "{ count, plural, " +
            "one {# proteza overdenture - akrylowa}" +
            "few {# protezy overdenture - akrylowe}" +
            "many {# protez overdenture - akrylowych}" +
            "other {# protez overdenture - akrylowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.InterlockAttachments:
      return intl.formatMessage(
        {
          id: "order-item-formatter.InterlockAttachments",
          defaultMessage:
            "{ count, plural, " +
            "one {# interlock}" +
            "few {# interlocki}" +
            "many {# interlock}" +
            "other {# interlock}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.RodAttachments:
      return intl.formatMessage(
        {
          id: "order-item-formatter.RodAttachments",
          defaultMessage:
            "{ count, plural, " + "one {# zasuwa}" + "few {# zasuwy}" + "many {# zasuw}" + "other {# zasuw}" + "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.StudAttachments:
      return intl.formatMessage(
        {
          id: "order-item-formatter.StudAttachments",
          defaultMessage:
            "{ count, plural, " +
            "one {# zatrzask}" +
            "few {# zatrzaski}" +
            "many {# zatrzasków}" +
            "other {# zatrzasków}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.LatchAttachments:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LatchAttachments",
          defaultMessage:
            "{ count, plural, " + "one {# rygiel}" + "few {# rygle}" + "many {# rygli}" + "other {# rygli}" + "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.OcclusalShoulder:
      return intl.formatMessage(
        {
          id: "order-item-formatter.OcclusalShoulder",
          defaultMessage:
            "{ count, plural, " +
            "one {# podparcie}" +
            "few {# podparcia}" +
            "many {# podparć}" +
            "other {# podparć}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FracturedDentureService:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FracturedDentureService",
          defaultMessage:
            "{ count, plural, " +
            "one {# interlock}" +
            "few {# interlocki}" +
            "many {# interlock}" +
            "other {# interlock}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.DentureReinforcement:
      return intl.formatMessage(
        {
          id: "order-item-formatter.DentureReinforcement",
          defaultMessage:
            "{ count, plural, " +
            "one {# interlock}" +
            "few {# interlocki}" +
            "many {# interlock}" +
            "other {# interlock}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullContourGlassCeramicCrown:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullContourGlassCeramicCrown",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona pełnokonturowa z ceramiki szklanej}" +
            "few {# korony pełnokonturowe z ceramiki szklanej}" +
            "many {# koron pełnokonturowych z ceramiki szklanej}" +
            "other {# koron pełnokonturowych z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullContourZirconiumCrown:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullContourZirconiumCrown",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona pełnokonturowa z cyrkonu}" +
            "few {# korony pełnokonturowe z cyrkonu}" +
            "many {# koron pełnokonturowych z cyrkonu}" +
            "other {# koron pełnokonturowych z cyrkonu}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullContourGlassCeramicBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullContourGlassCeramicBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most pełnokonturowy z ceramiki szklanej}" +
            "few {# mosty pełnokonturowe z ceramiki szklanej}" +
            "many {# mostów pełnokonturowych z ceramiki szklanej}" +
            "other {# mostów pełnokonturowych z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullContourZirconiumBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullContourZirconiumBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most pełnokonturowy z cyrkonu}" +
            "few {# mosty pełnokonturowe z cyrkonu}" +
            "many {# mostów pełnokonturowych z cyrkonu}" +
            "other {# mostów pełnokonturowych z cyrkonu}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.GlassCeramicMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland pełnokonturowy z ceramiki szklanej}" +
            "few {# mosty maryland pełnokonturowe z ceramiki szklanej}" +
            "many {# mostów maryland pełnokonturowych z ceramiki szklanej}" +
            "other {# mostów maryland pełnokonturowych z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.ZirconiumMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ZirconiumMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland pełnokonturowy z cyrkonu}" +
            "few {# mosty maryland pełnokonturowe z cyrkonu}" +
            "many {# mostów maryland pełnokonturowych z cyrkonu}" +
            "other {# mostów maryland pełnokonturowych z cyrkonu}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CompositeMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland kompozytowy}" +
            "few {# mosty maryland kompozytowy}" +
            "many {# mostów maryland kompozytowy}" +
            "other {# mostów maryland kompozytowy}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMilledMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMilledMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland CrCo frezowany}" +
            "few {# mosty maryland CrCo frezowany}" +
            "many {# mostów maryland CrCo frezowany}" +
            "other {# mostów maryland CrCo frezowany}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMoldedMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMoldedMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland CrCo lany}" +
            "few {# mosty maryland CrCo lany}" +
            "many {# mostów maryland CrCo lany}" +
            "other {# mostów maryland CrCo lany}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.LaserSinteredCrCoMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LaserSinteredCrCoMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland CrCo spiek}" +
            "few {# mosty maryland CrCo spiek}" +
            "many {# mostów maryland CrCo spiek}" +
            "other {# mostów maryland CrCo spiek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullContourGlassCeramicMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullContourGlassCeramicMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland pełnokonturowy z ceramiki szklanej}" +
            "few {# mosty maryland pełnokonturowe z ceramiki szklanej}" +
            "many {# mostów maryland pełnokonturowych z ceramiki szklanej}" +
            "other {# mostów maryland pełnokonturowych z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FullContourZirconiumMarylandBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FullContourZirconiumMarylandBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most maryland pełnokonturowy z cyrkonu}" +
            "few {# mosty maryland pełnokonturowe z cyrkonu}" +
            "many {# mostów maryland pełnokonturowych z cyrkonu}" +
            "other {# mostów maryland pełnokonturowych z cyrkonu}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.GlassCeramicVeneers:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicVeneers",
          defaultMessage:
            "{ count, plural, " +
            "one {# licówka z ceramiki szklanej}" +
            "few {# licówki z ceramiki szklanej}" +
            "many {# licówek z ceramiki szklanej}" +
            "other {# licówek z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CompositeVeneers:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeVeneers",
          defaultMessage:
            "{ count, plural, " +
            "one {# licówka kompozytowa}" +
            "few {# licówki kompozytowe}" +
            "many {# licówek kompozytowych}" +
            "other {# licówek kompozytowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.FeldspaticVeneers:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FeldspaticVeneers",
          defaultMessage:
            "{ count, plural, " +
            "one {# licówka na masie ogniotrwałej}" +
            "few {# licówki na masie ogniotrwałej}" +
            "many {# licówek na masie ogniotrwałej}" +
            "other {# licówek na masie ogniotrwałej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PlatinumFoilVeneers:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PlatinumFoilVeneers",
          defaultMessage:
            "{ count, plural, " +
            "one {# licówka na folii platynowej}" +
            "few {# licówki na folii platynowej}" +
            "many {# licówek na folii platynowej}" +
            "other {# licówek na folii platynowej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.LaserSinteredCrCoFoundation:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LaserSinteredCrCoFoundation",
          defaultMessage:
            "{ count, plural, " +
            "one {# podbudowa CrCo spiek}" +
            "few {# podbudowy CrCo spiek}" +
            "many {# podbudów CrCo spiek}" +
            "other {# podbudów CrCo spiek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.MilledTryInCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledTryInCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona try-in frezowana}" +
            "few {# korony try-in frezowane}" +
            "many {# koron try-in frezowanych}" +
            "other {# koron try-in frezowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PrintedTryInCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PrintedTryInCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona try-in drukowana}" +
            "few {# korony try-in drukowane}" +
            "many {# koron try-in drukowanych}" +
            "other {# koron try-in drukowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.MilledTryInBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledTryInBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most try-in frezowany}" +
            "few {# mosty try-in frezowane}" +
            "many {# mostów try-in frezowanych}" +
            "other {# mostów try-in frezowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PrintedTryInBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PrintedTryInBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most try-in drukowany}" +
            "few {# mosty try-in drukowane}" +
            "many {# mostów try-in drukowanych}" +
            "other {# mostów try-in drukowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.MilledRelaxationSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledRelaxationSplint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna relaksacyjna frezowana}" +
            "few {# szyny relaksacyjne frezowane}" +
            "many {# szyn relaksacyjnych frezowanych}" +
            "other {# szyn relaksacyjnych frezowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.MilledMichiganSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledMichiganSplint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna michigan frezowana}" +
            "few {# szyny michigan frezowane}" +
            "many {# szyn michigan frezowanych}" +
            "other {# szyn michigan frezowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.PrintedKoisSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PrintedKoisSplint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna kois drukowana}" +
            "few {# szyny kois drukowane}" +
            "many {# szyn kois drukowanych}" +
            "other {# szyn kois drukowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.Mouthguard:
      return intl.formatMessage(
        {
          id: "order-item-formatter.Mouthguard",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna ochronna}" +
            "few {# szyny ochronne}" +
            "many {# szyn ochronnych}" +
            "other {# szyn ochronnych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.GingivectomySurgicalGuide:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GingivectomySurgicalGuide",
          defaultMessage:
            "{ count, plural, " +
            "one {# szablon chirurgiczny do gingiwektomii}" +
            "few {# szablony chirurgiczne do gingiwektomii}" +
            "many {# szablonów chirurgicznych do gingiwektomii}" +
            "other {# szablonów chirurgicznych do gingiwektomii}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.ImplantSurgicalGuide:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ImplantSurgicalGuide",
          defaultMessage:
            "{ count, plural, " +
            "one {# szablon do pozycjonowania implantów}" +
            "few {# szablony do pozycjonowania implantów}" +
            "many {# szablonów do pozycjonowania implantów}" +
            "other {# szablonów do pozycjonowania implantów}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.MilledCrCoPostMultiRooted:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledCrCoPostMultiRooted",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy CrCo frezowany - wielo-korzeniowy}" +
            "few {# wkłady koronowo-korzeniowe CrCo frezowane - wielo-korzeniowy}" +
            "many {# wkładów koronowo-korzeniowych CrCo frezowanych - wielo-korzeniowy}" +
            "other {# wkładów koronowo-korzeniowych CrCo frezowanych - wielo-korzeniowy}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.MilledCrCoPost:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledCrCoPost",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy CrCo frezowany}" +
            "few {# wkłady koronowo-korzeniowe CrCo frezowane}" +
            "many {# wkładów koronowo-korzeniowych CrCo frezowanych}" +
            "other {# wkładów koronowo-korzeniowych CrCo frezowanych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.LaserSinteredCrCoPostMultiRooted:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LaserSinteredCrCoPostMultiRooted",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy CrCo spiek - wielo-korzeniowy}" +
            "few {# wkłady koronowo-korzeniowe CrCo spiek - wielo-korzeniowe}" +
            "many {# wkładów koronowo-korzeniowych CrCo spiek - wielo-korzeniowych}" +
            "other {# wkładów koronowo-korzeniowych CrCo spiek - wielo-korzeniowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.LaserSinteredCrCoPost:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LaserSinteredCrCoPost",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy CrCo spiek}" +
            "few {# wkłady koronowo-korzeniowe CrCo spiek}" +
            "many {# wkładów koronowo-korzeniowych CrCo spiek}" +
            "other {# wkładów koronowo-korzeniowych CrCo spiek}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PorcelainMargin:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PorcelainMargin",
          defaultMessage:
            "{ count, plural, " +
            "one {# stopień ceramiczny}" +
            "few {# stopnie ceramiczne}" +
            "many {# stopni ceramicznych}" +
            "other {# stopni ceramicznych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.BiteRegistrationRims:
      return intl.formatMessage(
        {
          id: "order-item-formatter.BiteRegistrationRims",
          defaultMessage:
            "{ count, plural, " +
            "one {# wzornik zwarciowy}" +
            "few {# wzorniki zwarciowe}" +
            "many {# wzorników zwarciowych}" +
            "other {# wzorników zwarciowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CustomTray:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CustomTray",
          defaultMessage:
            "{ count, plural, " +
            "one {# łyżka indiwidualna}" +
            "few {# łyżki indiwidualne}" +
            "many {# łyżek indiwidualnych}" +
            "other {# łyżek indiwidualnych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.SiliconeIndex:
      return intl.formatMessage(
        {
          id: "order-item-formatter.SiliconeIndex",
          defaultMessage:
            "{ count, plural, " +
            "one {# klucz sylikonowy}" +
            "few {# klucze sylikonowe}" +
            "many {# kluczy sylikonowych}" +
            "other {# kluczy sylikonowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.TransparentSiliconeIndex:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TransparentSiliconeIndex",
          defaultMessage:
            "{ count, plural, " +
            "one {# klucz sylikonowy transparentny}" +
            "few {# klucze sylikonowe transparentne}" +
            "many {# kluczy sylikonowych transparentnych}" +
            "other {# kluczy sylikonowych transparentnych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.ImplantAbutmentPlacementJig:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ImplantAbutmentPlacementJig",
          defaultMessage:
            "{ count, plural, " +
            "one {# klucz do pozycjonowania łącznika}" +
            "few {# klucze do pozycjonowania łączników}" +
            "many {# kluczy do pozycjonowania łączników}" +
            "other {# kluczy do pozycjonowania łączników}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PreparationCup:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PreparationCup",
          defaultMessage:
            "{ count, plural, " +
            "one {# czapeczka do szlifowania}" +
            "few {# czapeczki do szlifowania}" +
            "many {# czapeczek do szlifowania}" +
            "other {# czapeczek do szlifowania}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.MetalReinforcement:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MetalReinforcement",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt wzmocnieniony metalem}" +
            "few {# punkty wzmocnienione metalem}" +
            "many {# punktów wzmocnionych metalem}" +
            "other {# punktów wzmocnionych metalem}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.FiberglassReinforcement:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FiberglassReinforcement",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt wzmocnieniony włóknem szklanym}" +
            "few {# punkty wzmocnienione włóknem szklanym}" +
            "many {# punktów wzmocnionych włóknem szklanym}" +
            "other {# punktów wzmocnionych włóknem szklanym}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.MichiganSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.michigan-splint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna michigan}" +
            "few {# szyny michigan}" +
            "many {# szyn michigan}" +
            "other {# szyny michigan}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PrintedMichiganSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.printed-michigan-splint",
          defaultMessage:
            "{ count, plural, " +
            "one {# drukowana szyna michigan}" +
            "few {# drukowane szyny michigan}" +
            "many {# drukowanych szyn michigan}" +
            "other {# szyny michigan}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.KoisSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.kois-splint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna kois}" +
            "few {# szyny kois}" +
            "many {# szyn kois}" +
            "other {# szyny kois}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.WhiteningSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.whitening-splint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna wybielająca}" +
            "few {# szyny wybielające}" +
            "many {# szyn wybielających}" +
            "other {# szyn wybielających}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.RelaxationSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.relaxation-splint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna relaksacyjna}" +
            "few {# szyny relaksacyjne}" +
            "many {# szyn relaksacyjnych}" +
            "other {# szyn relaksacyjnych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PrintedRelaxationSplint:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PrintedRelaxationSplint",
          defaultMessage:
            "{ count, plural, " +
            "one {# szyna relaksacyjna drukowana}" +
            "few {# szyny relaksacyjne drukowane}" +
            "many {# szyn relaksacyjnych drukowanych}" +
            "other {# szyn relaksacyjnych drukowanych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.WaxUp:
      return intl.formatMessage(
        {
          id: "order-item-formatter.WaxUp",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt wax-up}" +
            "few {# punkty wax-up}" +
            "many {# punktów wax-up }" +
            "other {# punktów wax-up}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PrintedWaxUp:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PrintedWaxUp",
          defaultMessage:
            "{ count, plural, " +
            "one {# drukowany punkt wax-up}" +
            "few {# drukowane punkty wax-up}" +
            "many {# drukowanych punktów wax-up }" +
            "other {# drukowanych punktów wax-up}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.GlassCeramicOnlay:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicOnlay",
          defaultMessage:
            "{ count, plural, " +
            "one {# onlay z ceramiki szklanej}" +
            "few {# onlay'e z ceramiki szklanej}" +
            "many {# onlay'i z ceramiki szklanej}" +
            "other {# onlay'i z ceramiki szklanej}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CompositeOnlay:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeOnlay",
          defaultMessage:
            "{ count, plural, " +
            "one {# onlay kompozytowy}" +
            "few {# onlay'e kompozytowe}" +
            "many {# onlay'i kompozytowych}" +
            "other {# onlay'i kompozytowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.GlassCeramicInlay:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicInlay",
          defaultMessage:
            "{ count, plural, " +
            "one {# inlay z ceramiki szklanej}" +
            "few {# inlay'e z ceramiki szklanej}" +
            "many {# inlay'i z ceramiki szklanej}" +
            "other {# inlay'i z ceramiki szklanej}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CompositeInlay:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeInlay",
          defaultMessage:
            "{ count, plural, " +
            "one {# inlay kompozytowy}" +
            "few {# inlay'e kompozytowe}" +
            "many {# inlay'i kompozytowych}" +
            "other {# inlay'i kompozytowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.GlassCeramicOverlay:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicOverlay",
          defaultMessage:
            "{ count, plural, " +
            "one {# overlay z ceramiki szklanej}" +
            "few {# overlay'e z ceramiki szklanej}" +
            "many {# overlay'i z ceramiki szklanej}" +
            "other {# overlay'i z ceramiki szklanej}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CompositeOverlay:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeOverlay",
          defaultMessage:
            "{ count, plural, " +
            "one {# overlay kompozytowy}" +
            "few {# overlay'e kompozytowe}" +
            "many {# overlay'i kompozytowych}" +
            "other {# overlay'i kompozytowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.GlassCeramicEndoCrown:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicEndoCrown",
          defaultMessage:
            "{ count, plural, " +
            "one {# endo-korona z ceramiki szklanej}" +
            "few {# endo-korony z ceramiki szklanej}" +
            "many {# endo-koron z ceramiki szklanej}" +
            "other {# endo-koron z ceramiki szklanej}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CompositeEndoCrown:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeEndoCrown",
          defaultMessage:
            "{ count, plural, " +
            "one {# endo-korona kompozytowa}" +
            "few {# endo-korony kompozytowe}" +
            "many {# endo-koron kompozytowych}" +
            "other {# endo-koron kompozytowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CrCoMoldedPostMultiRooted:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMoldedPostMultiRooted",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z lanego metalu - wielo-korzeniowy}" +
            "few {# wkłady koronowo-korzeniowe z lanego metalu - wielo-korzeniowe}" +
            "many {# wkładów koronowo-korzeniowych z lanego metalu - wielo-korzeniowych}" +
            "other {# wkładów koronowo-korzeniowych z lanego metalu - wielo-korzeniowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.CrCoMoldedPost:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMoldedPost",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z lanego metalu}" +
            "few {# wkłady koronowo-korzeniowe z lanego metalu}" +
            "many {# wkładów koronowo-korzeniowych z lanego metalu}" +
            "other {# wkładów koronowo-korzeniowych z lanego metalu}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.ZirconiumPostMultiRooted:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ZirconiumPostMultiRooted",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z cyrkonu - wielo-korzeniowy}" +
            "few {# wkłady koronowo-korzeniowe z cyrkonu - wielo-korzeniowe}" +
            "many {# wkładów koronowo-korzeniowych z cyrkonu - wielo-korzeniowych}" +
            "other {# wkładów koronowo-korzeniowych z cyrkonu - wielo-korzeniowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.ZirconiumPost:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ZirconiumPost",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z cyrkonu}" +
            "few {# wkłady koronowo-korzeniowe z cyrkonu}" +
            "many {# wkładów koronowo-korzeniowych z cyrkonu}" +
            "other {# wkładów koronowo-korzeniowych z cyrkonu}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.FiberglassPostMultiRooted:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FiberglassPostMultiRooted",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z włókna szklanego - wielo-korzeniowy}" +
            "few {# wkłady koronowo-korzeniowe z włókna szklanego - wielo-korzeniowe}" +
            "many {# wkładów koronowo-korzeniowych z włókna szklanego - wielo-korzeniowych}" +
            "other {# wkładów koronowo-korzeniowych z włókna szklanego - wielo-korzeniowych}" +
            "}",
        },
        { count: count }
      );

    case OrderCodeEnum.FiberglassPost:
      return intl.formatMessage(
        {
          id: "order-item-formatter.FiberglassPost",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z włókna szklanego}" +
            "few {# wkłady koronowo-korzeniowe z włókna szklanego}" +
            "many {# wkładów koronowo-korzeniowych z włókna szklanego}" +
            "other {# wkładów koronowo-korzeniowych z włókna szklanego}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PeekPostMultiRooted:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PeekPostMultiRooted",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z PEEK - wielo-korzeniowy}" +
            "few {# wkłady koronowo-korzeniowe z PEEK - wielo-korzeniowe}" +
            "many {# wkładów koronowo-korzeniowych z PEEK - wielo-korzeniowych}" +
            "other {# wkładów koronowo-korzeniowych z PEEK - wielo-korzeniowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PeekPost:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PeekPost",
          defaultMessage:
            "{ count, plural, " +
            "one {# wkład koronowo-korzeniowy z PEEK}" +
            "few {# wkłady koronowo-korzeniowe z PEEK}" +
            "many {# wkładów koronowo-korzeniowych z PEEK}" +
            "other {# wkładów koronowo-korzeniowych z PEEK}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.StandardImplantAbutment:
      return intl.formatMessage(
        {
          id: "order-item-formatter.StandardImplantAbutment",
          defaultMessage:
            "{ count, plural, " +
            "one {# łącznik standardowy}" +
            "few {# łączniki standardowe}" +
            "many {# łączników standardowych}" +
            "other {# łączników standardowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.IndividualTitanAbutment:
      return intl.formatMessage(
        {
          id: "order-item-formatter.IndividualTitanAbutment",
          defaultMessage:
            "{ count, plural, " +
            "one {# łącznik indiwidualny}" +
            "few {# łączniki indiwidualne}" +
            "many {# łączników indiwidualnych}" +
            "other {# łączników indiwidualnych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.IndividualHybridAbutment:
      return intl.formatMessage(
        {
          id: "order-item-formatter.IndividualHybridAbutment",
          defaultMessage:
            "{ count, plural, " +
            "one {# łącznik hybrydowy}" +
            "few {# łączniki hybrydowe}" +
            "many {# łączników hybrydowych}" +
            "other {# łączników hybrydowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.StandardTemporaryAbutment:
      return intl.formatMessage(
        {
          id: "order-item-formatter.StandardTemporaryAbutment",
          defaultMessage:
            "{ count, plural, " +
            "one {# standardowy łącznik tymczasowy}" +
            "few {# standardowe łączniki tymczasowe}" +
            "many {# standardowych łączników tymczasowych}" +
            "other {# standardowych łączników tymczasowych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.DigitalPrintOrientationModel:
      return intl.formatMessage(
        {
          id: "order-item-formatter.DigitalPrintOrientationModel",
          defaultMessage:
            "{ count, plural, " +
            "one {# drukowany model orientacyjny}" +
            "few {# drukowane modele orientacyjne}" +
            "many {# drukowanych modeli orientacyjnych}" +
            "other {# drukowanych modeli orientacyjnych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PlasterOrientationModel:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PlasterOrientationModel",
          defaultMessage:
            "{ count, plural, " +
            "one {# model orientacyjny}" +
            "few {# modele orientacyjne}" +
            "many {# modeli orientacyjnych}" +
            "other {# modeli orientacyjnych}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.PeekFoundation:
      return intl.formatMessage(
        {
          id: "order-item-formatter.PeekFoundation",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt podbudowy PEEK}" +
            "few {# punkty podbudowy PEEK}" +
            "many {# punktów podbudowy PEEK}" +
            "other {# punktów podbudowy PEEK}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.ZirconiumFoundation:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ZirconiumFoundation",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt podbudowy cyrkonowej}" +
            "few {# punkty podbudowy cyrkonowej}" +
            "many {# punktów podbudowy cyrkonowej}" +
            "other {# punktów podbudowy cyrkonowej}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.MilledCrCoFoundation:
      return intl.formatMessage(
        {
          id: "order-item-formatter.MilledCrCoFoundation",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt podbudowy CrCo frezowanej}" +
            "few {# punkty podbudowy CrCo frezowanej}" +
            "many {# punktów podbudowy CrCo frezowanej}" +
            "other {# punktów podbudowy CrCo frezowanej}" +
            "}",
        },
        { count: count }
      );
    case OrderCodeEnum.GlassCeramicCrowns: {
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona z ceramiki szklanej}" +
            "few {# korony z ceramiki szklanej}" +
            "many {# koron z ceramiki szklanej}" +
            "other {# koron z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    }
    case OrderCodeEnum.ZirconiumCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ZirconiumCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona cyrkonowa}" +
            "few {# korony cyrkonowe}" +
            "many {# koron cyrkonowych}" +
            "other {# koron cyrkonowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CompositeCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona kompozytowa}" +
            "few {# korony kompozytowe}" +
            "many {# koron kompozytowych}" +
            "other {# koron kompozytowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMilledCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMilledCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona CrCo frezowana}" +
            "few {# korony CrCo frezowane}" +
            "many {# koron CrCo frezowanych}" +
            "other {# koron CrCo frezowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMoldedCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMoldedCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona CrCo lana}" +
            "few {# korony CrCo lane}" +
            "many {# koron CrCo lanych}" +
            "other {# koron CrCo lanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.LaserSinteredCrCoCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LaserSinteredCrCoCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona CrCo spiek}" +
            "few {# korony CrCo spiek}" +
            "many {# koron CrCo spiek}" +
            "other {# koron CrCo spiek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TemporaryPmmaCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TemporaryPmmaCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona tymczasowa PMMA}" +
            "few {# korony tymczasowe PMMA}" +
            "many {# koron tymczasowych PMMA}" +
            "other {# koron tymczasowych PMMA}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TemporaryPrintedCrowns:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TemporaryPrintedCrowns",
          defaultMessage:
            "{ count, plural, " +
            "one {# korona tymczasowa drukowana}" +
            "few {# korony tymczasowe drukowane}" +
            "many {# koron tymczasowych drukowanych}" +
            "other {# koron tymczasowych drukowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.GlassCeramicBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.GlassCeramicBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most z ceramiki szklanej}" +
            "few {# mosty z ceramiki szklanej}" +
            "many {# mostów z ceramiki szklanej}" +
            "other {# mosty z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.ZirconiumBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ZirconiumBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most cyrkonowy}" +
            "few {# mosty cyrkonowe}" +
            "many {# mostów cyrkonowych}" +
            "other {# mostów cyrkonowych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CompositeBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most kompozytowy}" +
            "few {# mosty kompozytowy}" +
            "many {# mostów most kompozytowy}" +
            "other {# mostów kompozytowy}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMilledBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMilledBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most CrCo frezowany}" +
            "few {# mosty CrCo frezowane}" +
            "many {# mostów CrCo frezowanych}" +
            "other {# mostów CrCo frezowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CrCoMoldedBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CrCoMoldedBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most CrCo lany}" +
            "few {# mosty CrCo lane}" +
            "many {# mostów CrCo lane}" +
            "other {# mostów CrCo lane}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.LaserSinteredCrCoBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.LaserSinteredCrCoBridges",
          defaultMessage:
            "{ count, plural, " +
            "one {# most CrCo spiek}" +
            "few {# mosty CrCo spiek}" +
            "many {# mostów CrCo spiek}" +
            "other {# mostów CrCo spiek}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TemporaryPmmaBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TemporaryPmmaBridge",
          defaultMessage:
            "{ count, plural, " +
            "one {# most tymczasowy PMMA}" +
            "few {# mosty tymczasowe PMMA}" +
            "many {# mostów tymczasowych PMMA}" +
            "other {# mostów tymczasowych PMMA}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.TemporaryPrintedBridges:
      return intl.formatMessage(
        {
          id: "order-item-formatter.TemporaryPrintedBridge",
          defaultMessage:
            "{ count, plural, " +
            "one {# most tymczasowy drukowany}" +
            "few {# mosty tymczasowe drukowane}" +
            "many {# mostów tymczasowych drukowanych}" +
            "other {# mostów tymczasowych drukowanych}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CeramicGingiva:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CeramicGingiva",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt dziąsła z ceramiki szklanej}" +
            "few {# punkty dziąsła z ceramiki szklanej}" +
            "many {# punktów dziąsła z ceramiki szklanej}" +
            "other {# punktów dziąsła z ceramiki szklanej}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.CompositeGingiva:
      return intl.formatMessage(
        {
          id: "order-item-formatter.CompositeGingiva",
          defaultMessage:
            "{ count, plural, " +
            "one {# punkt dziąsła kompozyt}" +
            "few {# punkty dziąsła kompozyt}" +
            "many {# punktów dziąsła kompozyt}" +
            "other {# punktów dziąsła kompozyt}" +
            "}",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.ColorSelectionInLaboratory:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ColorSelectionInDentalLaboratory",
          defaultMessage: "{count}x dobór koloru w laboratorium",
        },
        {
          count: count,
        }
      );
    case OrderCodeEnum.ColorSelectionInClinic:
      return intl.formatMessage(
        {
          id: "order-item-formatter.ColorSelectionInClinic",
          defaultMessage: "{count}x dobór koloru w gabinecie",
        },
        {
          count: count,
        }
      );
  }
};
