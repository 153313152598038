import { EventEmitter } from "events";

type ListenerSignature<L> = {
  [E in keyof L]: (...args: any[]) => any;
};

export interface ITypedEmitter<L extends ListenerSignature<L>> {
  addListener: <U extends keyof L>(event: U, listener: L[U]) => ITypedEmitter<L>;
  removeListener: <U extends keyof L>(event: U, listener: L[U]) => ITypedEmitter<L>;
  once: <U extends keyof L>(event: U, listener: L[U]) => ITypedEmitter<L>;
  on: <U extends keyof L>(event: U, listener: L[U]) => ITypedEmitter<L>;
  off: <U extends keyof L>(event: U, listener: L[U]) => ITypedEmitter<L>;
  emit: <U extends keyof L>(event: U, ...args: Parameters<L[U]>) => boolean;
}

class TypedEmitter<L extends ListenerSignature<L>> implements ITypedEmitter<L> {
  static defaultMaxListeners: number;
  private emitter: EventEmitter;

  constructor() {
    this.emitter = new EventEmitter();
  }

  addListener = <U extends keyof L>(event: U, listener: L[U]): ITypedEmitter<L> => {
    return this.emitter.addListener(event as any, listener) as ITypedEmitter<L>;
  };

  removeListener = <U extends keyof L>(event: U, listener: L[U]): ITypedEmitter<L> => {
    return this.emitter.removeListener(event as any, listener) as ITypedEmitter<L>;
  };

  once = <U extends keyof L>(event: U, listener: L[U]) => {
    return this.emitter.once(event as any, listener) as ITypedEmitter<L>;
  };

  on = <U extends keyof L>(event: U, listener: L[U]) => {
    return this.emitter.on(event as any, listener) as ITypedEmitter<L>;
  };

  off = <U extends keyof L>(event: U, listener: L[U]) => {
    return this.emitter.off(event as any, listener) as ITypedEmitter<L>;
  };

  emit = <U extends keyof L>(event: U, ...args: Parameters<L[U]>) => {
    return this.emitter.emit(event as any, ...args);
  };
}

export default TypedEmitter;

//   prependListener<U extends keyof L>(event: U, listener: L[U]): this;
//   prependOnceListener<U extends keyof L>(event: U, listener: L[U]): this;
//   removeListener<U extends keyof L>(event: U, listener: L[U]): this;
//   removeAllListeners(event: keyof L): this;

//   listenerCount(type: keyof L): number;
//   listeners<U extends keyof L>(type: U): L[U][];
//   rawListeners<U extends keyof L>(type: U): L[U][];
//   getMaxListeners(): number;
//   setMaxListeners(n: number): this;
