import { IResult } from "core/lib/types/result";
import {
  IBillingStatementExcerpt,
  IBillingStatement,
  IBillingStatementId,
} from "features/invoicing/models/invoicing.models";
import { IPaginatedList } from "core/pagination/paginated-list";
import {
  ICreateBillingStatementRequest,
  IDownloadOrderListRequest,
  IUpdateLinesRequest,
} from "./models/invoicing.action-models";
import {
  IBillingStatementDTO,
  ICreateBillingStatementResponseDTO,
  IDeleteInvoiceResponseDTO,
  ILineItemDTO,
  IQueryBillingStatementResponseDTO,
  QueryInvoicesResponseDTO,
} from "./contracts/invoices.response.dto";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { appendQueryString } from "core/http/http.functions";
import { Ok } from "core/lib/types/ok";
import { mapBillingStatementDTOToBillingStatement } from "./mappers/invoiceMappers";
import { normalizedFileName } from "core/utils/string-utils";
import { ICreateBillingStatementRequestDTO } from "./contracts/invoices.request.dto";
import { honorificCodeToHonorific } from "core/person-name/honorific.functions";
import { mapLineItemToLineItemDTO } from "./mappers/invoiceEntryMappers";
import { IJsonApiService } from "core/http/json-api.service";
import {
  BillingStatementServerColumn,
  IBillingStatementProviderParams,
} from "features/invoicing/components/billing-statements-table/billing-statement-table.models";
import { NewDentistId } from "features/dentist/dentist.model";
import { ServerSortDirection } from "features/orders/pages/list/components/orders-table.models";
import IApiResponseEnvelope from "contracts/envelope/api-response-envelope";

export interface IInvoicingApi {
  getBillingStatement: (id: IBillingStatementId) => Promise<IResult<IBillingStatement>>;
  queryBillingStatements: (
    params: IBillingStatementProviderParams
  ) => Promise<IResult<IPaginatedList<IBillingStatementExcerpt>>>;
  createBillingStatement: (createInvoiceRequest: ICreateBillingStatementRequest) => Promise<IResult<IBillingStatement>>;
  updateBillingStatementLines: (request: IUpdateLinesRequest) => Promise<IResult<IBillingStatement>>;
  removeBillingStatement: (id: IBillingStatementId) => Promise<IResult<IBillingStatementId>>;
  downloadBillingStatement: (request: IDownloadOrderListRequest) => Promise<IResult<void>>;
}

class InvoicingApi implements IInvoicingApi {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  private downloadResponse = (blobResponse: any, filename: string, extension: string) => {
    const url = window.URL.createObjectURL(new Blob([blobResponse]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${normalizedFileName(filename)}.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getBillingStatement = async (statementId: IBillingStatementId): Promise<IResult<IBillingStatement>> => {
    const response = await this.jsonApi.get<IApiResponseEnvelope<IBillingStatementDTO>>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.QueryBillingStatement,
        params: { labId: labId!.value, billingStatementId: statementId.value },
      })
    );

    return response.map((body) => mapBillingStatementDTOToBillingStatement(body.data.result));
  };

  queryBillingStatements = async (
    queryParams: IBillingStatementProviderParams
  ): Promise<IResult<IPaginatedList<IBillingStatementExcerpt>>> => {
    const { pagination, sortBy, statementNumber } = queryParams;

    const queryObject: {
      pageSize: number;
      pageNumber: number;
      sortBy?: BillingStatementServerColumn;
      sortDirection?: ServerSortDirection;
      statementNumber?: number;
      // searchQuery?: string | null;
    } = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      // sortDirection: "descend",
      // ...(!isNullOrEmpty(search) && { searchQuery: search }),
    };

    if (statementNumber !== null) {
      queryObject.statementNumber = statementNumber;
    }

    if (sortBy !== null && sortBy !== undefined) {
      queryObject.sortBy = sortBy.column;
      queryObject.sortDirection = sortBy.direction;
    }

    const response = await this.jsonApi.get<QueryInvoicesResponseDTO>(({ labId }) =>
      appendQueryString(
        parametrizeEndpointPath({
          path: ApiEndpointPath.QueryBillingStatements,
          params: { labId: labId!.value },
        }),
        queryObject
      )
    );

    return response.map((body) => {
      const paginatedResponse: IPaginatedList<IBillingStatementExcerpt> = {
        data: body.data.result.value.map((dto) => {
          const obj: IBillingStatementExcerpt = {
            id: { type: "billing-statement-id", value: dto.id },
            recipient: {
              id: NewDentistId(dto.recipient.id),
              name: {
                firstName: dto.recipient.name.firstName,
                lastName: dto.recipient.name.lastName,
                honorific: honorificCodeToHonorific(dto.recipient.name.honorific),
              },
              clinicName: dto.recipient.clinicName,
            },
            createdOn: dto.createdOn,
            notes: dto.notes,
            grandTotal: dto.grandTotal,
            statementNumber: dto.statementNumber,
          };

          return obj;
        }),
        pagination: body.data.result.pagination,
      };

      return paginatedResponse;
    });
  };

  createBillingStatement = async (
    createInvoiceRequest: ICreateBillingStatementRequest
  ): Promise<IResult<IBillingStatement>> => {
    const { recipient, lineItems } = createInvoiceRequest;

    const requestBody: ICreateBillingStatementRequestDTO = {
      recipient: recipient.value,
      notes: null,
      lineItems: lineItems.map((item) => {
        const dto: ILineItemDTO = {
          id: item.id.value,
          orderId: item.orderId?.value ?? null,
          orderNumber: item.orderNumber,
          dentistId: item.dentistId?.value ?? null,
          description: item.description,
          patient: item.patient,
          price: item.price,
        };

        return dto;
      }),
    };

    const response = await this.jsonApi.post<ICreateBillingStatementResponseDTO, ICreateBillingStatementRequestDTO>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.CreateBillingStatement,
          params: { labId: labId!.value },
        }),
      requestBody
    );

    return response.map((body) => mapBillingStatementDTOToBillingStatement(body.data.result));
  };

  updateBillingStatementLines = async (request: IUpdateLinesRequest): Promise<IResult<IBillingStatement>> => {
    const response = await this.jsonApi.put<IQueryBillingStatementResponseDTO, { lines: ILineItemDTO[] }>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.UpdateBillingStatementLines,
          params: { labId: labId!.value, billingStatementId: request.id.value },
        }),
      {
        lines: request.lines.map((line) => mapLineItemToLineItemDTO(line)),
      }
    );

    return response.map((body) => mapBillingStatementDTOToBillingStatement(body.data.result));
  };

  removeBillingStatement = async (id: IBillingStatementId): Promise<IResult<IBillingStatementId>> => {
    const response = await this.jsonApi.post<IDeleteInvoiceResponseDTO, {}>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.DeleteBillingStatement,
        params: { labId: labId!.value, billingStatementId: id.value },
      })
    );

    return response.map((body) => ({
      type: "billing-statement-id",
      value: body.data.result.id,
    }));
  };

  downloadBillingStatement = async (request: IDownloadOrderListRequest): Promise<IResult<void>> => {
    const response = await this.jsonApi.post(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.DownloadOrderList,
          params: { labId: labId!.value, billingStatementId: request.billingStatementId.value },
        }),
      {},
      { responseType: "blob" }
    );

    if (response.isOk()) {
      const responseBlob = response.unwrap().data as any;
      this.downloadResponse(responseBlob, request.statementNumber.toString(), "txt");
    } else {
      return response.map((_) => undefined);
    }

    return new Ok<void>(undefined);
  };
}

export default InvoicingApi;
