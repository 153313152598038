import * as React from "react";
import { useMemo, useState } from "react";
import { Button, Card, Divider, Row, Col, Space, Spin, Tabs, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Links from "components/links/Links";
import useOrder from "../../hooks/useOrder";
import { IOrderId, IOrderItemId, Reader } from "../../order.model";
import useOrdersCatalogue, { LoadOrdersCatalogueOptions } from "../../hooks/useOrdersCatalogue";
import { OrderStatusCard } from "./components/OrderStatusCard";
import { FormattedMessage } from "react-intl";
import { Money } from "core/money/money.functions";
import Assignments from "features/orders/components/assignments/Assignments";
import { Status } from "../../status/order-status.model";
import Files from "../../../order-files/components/files/Files";
import OrderHistory from "./components/order-history/OrderHistory";
import { IMoney } from "core/money/money.model";
import Access from "features/access/components/Access";
import useAccess from "features/access/useAccess";
import { useNavigate, useParams } from "react-router";
import useAuth from "features/auth/hooks/useAuth";
import { AccountType } from "features/auth/auth.model";
import useConfirmations from "components/confirmations/useConfirmations";
import { Path } from "core/routes/routes";
import { Content } from "antd/lib/layout/layout";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { OrderSummary } from "./components/OrderSummary";
import { OrderInfo } from "features/orders/pages/new-details/components/OrderInfo";
import { whenEvery } from "core/lib/types/result.utils";
import ChangeFinishDateModalForm from "features/orders/components/change-finish-date-modal-form/ChangeFinishDateModal";
import useBooleanFlag from "hooks/useBooleanFlag";
import ChatButton from "features/orders/components/chat-button/ChatButton";
import OrderFeedback from "./components/order-feedback/OrderFeedback";

import "./order-details.scss";

interface IOrderDetailsPageProps {}

enum Tab {
  OrderTab = "OrderTab",
  OrderAssetsTab = "OrderAssetsTab",
  OrderChangeHistory = "OrderChangeHistory",
}

const OrderDetailsPage: React.FunctionComponent<IOrderDetailsPageProps> = (props) => {
  const { authContext } = useAuth();
  const navigate = useNavigate();
  const access = useAccess();
  const { orderId: orderIdParam } = useParams<"orderId">();
  const orderId: IOrderId = React.useMemo(() => ({ type: "order-id", value: orderIdParam! }), [orderIdParam]);
  const { deleteOrderConfirmation } = useConfirmations();
  const { getOrder, changePrice, changeStatus, deleteOrder, changeFinishDate, refresh } = useOrder(orderId);
  const ordersCatalogueOptions: LoadOrdersCatalogueOptions = useMemo(
    () =>
      authContext.accountType !== AccountType.Dentist
        ? { mode: "lab" }
        : getOrder === undefined || getOrder.isErr()
        ? null
        : getOrder
            .map(
              (order) =>
                ({
                  mode: "dentist",
                  labId: order.labId,
                } as const)
            )
            .unwrap(),
    [authContext.accountType, getOrder]
  );

  const { flag: isModalVisible, check: showModal, uncheck: hideModal } = useBooleanFlag(false);
  const { getOrdersCatalogue } = useOrdersCatalogue(ordersCatalogueOptions);
  const screens = useBreakpoint();

  const [tab, setTab] = useState(Tab.OrderTab);
  const handleTabChange = React.useCallback((tab: string) => setTab(tab as Tab), [setTab]);

  const handlePriceChange = React.useCallback(
    async (params: { id: IOrderItemId; newPrice: IMoney; reasonForChange: string }) =>
      changePrice({
        orderId: orderId,
        orderItem: params.id,
        newPrice: params.newPrice,
        reasonForChange: params.reasonForChange,
      }),
    [changePrice, orderId]
  );

  const handleDeleteOrder = React.useCallback(async () => {
    const result = await deleteOrder(orderId);

    if (result.isErr()) {
      return;
    }

    navigate(Path.ListOrders);
  }, [deleteOrder, navigate, orderId]);

  const handleStatusChange = React.useCallback(
    (status: Status) => changeStatus({ orderId: orderId, status }),
    [changeStatus, orderId]
  );

  if (getOrder === undefined || getOrdersCatalogue === undefined) {
    return <Spin />;
  }

  const order = getOrder.unwrap();

  if (!order) {
    return <Spin />;
  }

  const totalOrderPrice = order.orderItems.reduce((sum, oi) => {
    return Money.add(sum, oi.totalPrice);
  }, Money.PLN(0));

  const OrderTabs = () => (
    <Tabs
      activeKey={tab}
      onChange={handleTabChange}
      defaultActiveKey="1"
      className={"order-details__tabs"}
      size={"large"}
    >
      <Tabs.TabPane tab={<span>Zamówienie</span>} key={Tab.OrderTab} />
      <Tabs.TabPane tab={<span>Pliki i zdjęcia</span>} key={Tab.OrderAssetsTab} />
      <Tabs.TabPane tab={<span>Historia zmian</span>} key={Tab.OrderChangeHistory} />
    </Tabs>
  );

  return (
    <>
      {isModalVisible && (
        <ChangeFinishDateModalForm
          visible={true}
          values={{ finishDate: order.finishDate }}
          onCancel={hideModal}
          onSubmit={async ({ finishDate }) => {
            const result = await changeFinishDate({ orderId: order.orderId, finishDate });

            if (result.isOk()) {
              hideModal();
            }

            return result;
          }}
        />
      )}
      <Content className="px-4">
        <Row align="bottom" className="py-4" gutter={16}>
          <Col span={12}>
            <Space direction="vertical" size={"middle"}>
              <Typography.Text className="font-bold uppercase">
                <FormattedMessage id="new-order-details.order-details" defaultMessage={"Szczegóły zamówienia"} />
              </Typography.Text>
              <Breadcrumbs />
              <Space>
                <Button onClick={() => setTab(Tab.OrderAssetsTab)}>
                  <FormattedMessage
                    id="new-order-details-page.upload-files-and-photos"
                    defaultMessage="Prześlij pliki i zdjęcia"
                  />
                </Button>
                <Access accessible={access.toChat}>
                  <ChatButton orderId={orderId} />
                </Access>
                <Access accessible={access.canEditOrders}>
                  <Links.EditOrder params={{ orderId: order.orderId }}>
                    <Button>
                      <FormattedMessage id="new-order-details-page.edit" defaultMessage="Edytuj" />
                    </Button>
                  </Links.EditOrder>
                </Access>
                <Access accessible={access.canDeleteOrder(order.status)}>
                  <Button type="link" onClick={() => deleteOrderConfirmation(handleDeleteOrder)}>
                    <FormattedMessage id="new-order-details-page.delete" defaultMessage="Usuń" />
                  </Button>
                </Access>
              </Space>
            </Space>
          </Col>
          {screens.xl && (
            <Col span={12}>
              <OrderTabs />
            </Col>
          )}
        </Row>

        <Row gutter={16}>
          <Col xs={24} xl={12}>
            <Card>
              <OrderInfo order={order} onChangeFinishDate={access.canEditFinishDate ? showModal : undefined} />
              {order.status === Status.Finished && (
                <>
                  <Divider />
                  <OrderFeedback orderId={order.orderId} labId={order.labId} />
                </>
              )}
              {order.reader === Reader.Lab && (
                <>
                  <Divider />
                  <Typography.Title level={5}>Pracownicy przypisani</Typography.Title>
                  <Assignments orderId={order.orderId} employees={order.assignedEmployees} onAssigned={refresh} />
                </>
              )}
              {screens.xl && (
                <>
                  <Divider />
                  <Typography.Title level={5}>Status</Typography.Title>
                  <OrderStatusCard
                    status={order.status}
                    onChange={access.canChangeOrderStatus ? handleStatusChange : undefined}
                  />
                </>
              )}
            </Card>
          </Col>
          {!screens.xl && (
            <Col span={24}>
              <OrderTabs />
            </Col>
          )}
          <Col xs={24} xl={12}>
            {tab === Tab.OrderTab &&
              whenEvery([getOrder, getOrdersCatalogue])
                .map(([order, ordersCatalogue]) => (
                  <OrderSummary
                    order={order}
                    ordersCatalogue={ordersCatalogue}
                    totalOrderPrice={totalOrderPrice}
                    handlePriceChange={access.canEditOrderPrices ? handlePriceChange : undefined}
                  />
                ))
                .unwrap()}
            {tab === Tab.OrderAssetsTab && getOrder.map((order) => <Files orderId={order.orderId} />).unwrap()}
            {tab === Tab.OrderChangeHistory &&
              getOrder.map((order) => <OrderHistory orderId={order.orderId} />).unwrap()}
          </Col>
        </Row>

        {!screens.xl && (
          <Card className="mt-5">
            <Typography.Title level={4}>Status</Typography.Title>
            <OrderStatusCard
              status={order.status}
              onChange={access.canChangeOrderStatus ? handleStatusChange : undefined}
            />
          </Card>
        )}
      </Content>
    </>
  );
};

export default OrderDetailsPage;
