import { invalidArgumentError } from "core/errors/generate-error";

export function isBetween(start: number, end: number, value: number): boolean {
  if (start > end) {
    throw new Error("invalid start and end parameters");
  }

  return start < value && value < end;
}

export function inRange(a: number, b: number, value: number): boolean {
  return (value - a) * (value - b) <= 0;
}

export function cap(a: number, b: number, value: number): number {
  const min = a < b ? a : b;
  const max = a > b ? a : b;

  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
}

export const numberSequence = (sequenceStart: number, sequenceEnd: number, increment: number = 1): number[] => {
  if (sequenceStart > sequenceEnd) {
    throw invalidArgumentError("sequenceStart can not be larger than sequence end");
  }

  const sequence = [];

  for (let i = sequenceStart; i < sequenceEnd; i += increment) {
    sequence.push(i);
  }

  return sequence;
};

export const safeIntParse = (
  value: string | number | null | undefined
): { isNumber: true; value: number } | { isNumber: false; value: 0 } => {
  switch (typeof value) {
    case "string": {
      const parsedInt = parseInt(value);

      if (isNaN(parsedInt)) {
        return { isNumber: false, value: 0 };
      }

      return { isNumber: true, value: parsedInt };
    }
    case "number": {
      if (isNaN(value)) {
        return { isNumber: false, value: 0 };
      }

      return { isNumber: true, value: value };
    }
    case "undefined":
    case "object":
    case "boolean":
    case "function":
    case "symbol":
    case "bigint":
    default:
      return { isNumber: false, value: 0 };
  }
};
