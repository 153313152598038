import * as React from "react";
import { isNullOrEmpty } from "core/utils/string-utils";
import { Alert } from "antd";

interface IConfirmEmailLinkProps {
  confirmEmailLink: string;
}

const ConfirmEmailLink: React.FC<IConfirmEmailLinkProps> = ({ confirmEmailLink }) => {
  if (isNullOrEmpty(confirmEmailLink)) {
    return null;
  }

  const decodedConfirmEmailLink = window.atob(confirmEmailLink!);
  const url = new URL(decodedConfirmEmailLink);

  if (url.host !== window.location.host) {
    url.host = window.location.host;
  }

  const link = url.href;

  return (
    <Alert
      key={link}
      message={
        <div>
          <a href={link}>{link}</a>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

export default ConfirmEmailLink;
