import * as React from "react";
import { useRef, useState } from "react";
import { Menu } from "antd";
import { ContactsOutlined, IdcardOutlined, ProfileOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import useSize from "../../../../hooks/useSize";
import styles from "./settings-view.module.scss";
import { MenuClickEventHandler, MenuMode } from "rc-menu/lib/interface";
import classNames from "classnames";
import InvoicingSettingsSection from "../invoicing-section/InvoicingSection";
import useSettings from "../../hooks/useSettings";
import { InvoiceNumberingSchemeCode, PaymentDueDateCode } from "../../contracts/settings.dto";
import { IInvoiceIssuerDataSet } from "../../models/settings.models";

enum View {
  Profile = "profile",
  DentalLab = "dental-lab",
  Invoicing = "invoicing",
}

enum Size {
  Small = "sm",
  Medium = "mg",
  Large = "lg",
}

const SettingsView: React.FunctionComponent<{}> = () => {
  const { getInvoicingSettings, updateSettings } = useSettings();
  const [view, setView] = useState<View>(View.Invoicing);

  let ref = useRef(null);

  const [size] = useSize(ref, {
    [Size.Small]: 0,
    [Size.Medium]: 400,
    [Size.Large]: 700,
  });

  const handleInvoiceNumberingScheme = React.useCallback(
    (invoiceNumberingScheme: InvoiceNumberingSchemeCode) => {
      return updateSettings({ invoiceNumberingScheme: invoiceNumberingScheme });
    },
    [updateSettings]
  );

  const handlePaymentDueDateChange = React.useCallback(
    (paymentDueDate: PaymentDueDateCode) => {
      return updateSettings({ paymentDueDate: paymentDueDate });
    },
    [updateSettings]
  );

  const handleInvoiceIssuerDataSet = React.useCallback(
    (invoiceIssuerDataSet: IInvoiceIssuerDataSet[]) => {
      return updateSettings({ invoiceIssuerDataSets: invoiceIssuerDataSet });
    },
    [updateSettings]
  );

  const menuMode: MenuMode = size === Size.Medium ? "horizontal" : "inline";
  const handleMenuChange: MenuClickEventHandler = (data) => setView(data.key as View);

  return (
    <div ref={ref}>
      <div
        className={classNames(styles.container, {
          [styles.small]: size === Size.Small,
          [styles.medium]: size === Size.Medium,
          [styles.large]: size === Size.Large,
        })}
      >
        <div className={styles.menu}>
          <Menu onClick={handleMenuChange} selectedKeys={[view]} mode={menuMode}>
            <Menu.Item disabled key="profile" icon={<IdcardOutlined />}>
              <FormattedMessage id="settings.profile" defaultMessage={"Profil użytkownika"} />
            </Menu.Item>
            <Menu.Item disabled key="dental-lab" icon={<ContactsOutlined />}>
              <FormattedMessage id="settings.dental-lab" defaultMessage={"Laboratorium"} />
            </Menu.Item>
            <Menu.Item key="invoicing" icon={<ProfileOutlined />}>
              <FormattedMessage id="settings.invoicing" defaultMessage={"Fakturowanie"} />
            </Menu.Item>
          </Menu>
        </div>
        <div className={styles.view}>
          {getInvoicingSettings
            ?.map((settings) => (
              <>
                <>
                  {view === View.Invoicing && (
                    <InvoicingSettingsSection
                      settings={settings}
                      onInvoiceNumberingSchemeChanged={handleInvoiceNumberingScheme}
                      onPaymentDueDateChange={handlePaymentDueDateChange}
                      onInvoiceIssuerDataSetChange={handleInvoiceIssuerDataSet}
                    />
                  )}
                </>
                <>{view === View.DentalLab && <h1>Dental Lab</h1>}</>
              </>
            ))
            .unwrap()}
        </div>
      </div>
    </div>
  );
};

export default SettingsView;
