import { DependencyList, useCallback } from "react";
import { useEffectAsync } from "hooks/useEffectAsync";
import { useStateAsync } from "hooks/useStateAsync";

function useMemoAsync<T, I extends T | null = null>(
  factory: () => Promise<T>,
  deps?: DependencyList,
  initial: I = (null as unknown) as I
): [T | I, () => Promise<T | I>, boolean] {
  const [loading, setLoading] = useStateAsync<boolean>(false);
  const [memo, setMemo] = useStateAsync<T | I>(initial);

  useEffectAsync(async () => {
    await setLoading(true);

    try {
      const memo = await factory();

      await setMemo(memo);
    } catch (e) {
      console.error(e);
    } finally {
      await setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const reload = useCallback(async () => {
    await setLoading(true);
    try {
      const memo = await factory();

      await setMemo(memo);

      return memo;
    } catch (e) {
      console.error(e);
      return initial;
    } finally {
      await setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? [factory]);

  return [memo, reload, loading];
}

export default useMemoAsync;
