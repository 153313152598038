import { IResult } from "core/lib/types/result";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { IJsonApiService } from "core/http/json-api.service";
import { IChatApi } from "features/chat/services/chat.service";
import { IOrderId} from "features/orders/order.model";
import { IChatChannelInfo } from "features/chat/contracts/chat.contracts";
import { IUserId, NewUserId } from "features/auth/auth.model";
import { UserResponse } from "stream-chat/src/types";
import IApiResponseEnvelope from "contracts/envelope/api-response-envelope";

class ChatHttpApi implements IChatApi {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  getUserToken = async (): Promise<IResult<string>> => {
    const response = await this.jsonApi.post<IApiResponseEnvelope<{ userToken: string }>, void>(({ userId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.Chat_GetUserToken,
        params: {
          userId: userId.value,
        },
      })
    );

    return response.map((body) => body.data.result.userToken);
  };

  getOrderChannel = async (orderId: IOrderId): Promise<IResult<IChatChannelInfo | null>> => {
    type GetOrderChannelDTO = {
      channel: { channelId: string; members: { userId: string; name: string; online: true }[] } | null;
    };

    const response = await this.jsonApi.get<IApiResponseEnvelope<GetOrderChannelDTO>>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.Chat_GetOrderChannel,
        params: {
          orderId: orderId.value,
        },
      })
    );

    return response.map((body) => {
      const dto = body.data.result;

      if (dto.channel === null) {
        return null;
      }

      const model: IChatChannelInfo = {
        channelId: dto.channel.channelId,
        members: dto.channel.members.map((m) => ({
          userId: NewUserId(m.userId),
          name: m.name,
          online: m.online,
        })),
      };
      return model;
    });
  };

  createOrderChannel = async (orderId: IOrderId): Promise<IResult<IChatChannelInfo>> => {
    type GetOrderChannelDTO = {
      channel: { channelId: string; members: { userId: string; name: string; online: true }[] };
    };

    const response = await this.jsonApi.post<IApiResponseEnvelope<GetOrderChannelDTO>, void>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.Chat_CreateOrderChannel,
        params: { orderId: orderId.value },
      })
    );

    return response.map((body) => {
      const dto = body.data.result;

      const model: IChatChannelInfo = {
        channelId: dto.channel.channelId,
        members: dto.channel.members.map((m) => ({
          userId: NewUserId(m.userId),
          name: m.name,
          online: m.online,
        })),
      };
      return model;
    });
  };

  createConversation = async (members: IUserId[]): Promise<IResult<IChatChannelInfo>> => {
    type GetChannelDTO = {
      channelId: string;
      members: { userId: string; name: string; online: true }[];
    };

    const response = await this.jsonApi.post<IApiResponseEnvelope<GetChannelDTO>, { members: string[] }>(
      ({ userId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.Chat_CreateConversation,
          params: { userId: userId.value },
        }),
      {
        members: members.map((m) => m.value),
      }
    );

    return response.map((body) => {
      const dto = body.data.result;

      const model: IChatChannelInfo = {
        channelId: dto.channelId,
        members: dto.members.map((m) => ({
          userId: NewUserId(m.userId),
          name: m.name,
          online: m.online,
        })),
      };
      return model;
    });
  };

  joinOrderChannel = async (orderId: IOrderId): Promise<IResult<void>> => {
    const response = await this.jsonApi.post<IApiResponseEnvelope<void>, void>(({ userId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.Chat_JoinOrderChannel,
        params: { orderId: orderId.value, userId: userId.value },
      })
    );

    return response.map(() => undefined);
  };

  searchContacts = async (searchPhrase: string | null): Promise<IResult<Array<UserResponse>>> => {
    type Response = { contacts: { userId: string; name: string; online: boolean | null }[] };

    const response = await this.jsonApi.post<IApiResponseEnvelope<Response>, { searchPhrase: string | null }>(
      ({ userId }) =>
        parametrizeEndpointPath({ path: ApiEndpointPath.Chat_SearchContacts, params: { userId: userId.value } }),
      { searchPhrase }
    );

    return response.map((body) => {
      const result = body.data.result.contacts;

      return result.map((contact) => {
        const userResponse: UserResponse = {
          id: contact.userId,
          online: contact.online === null ? undefined : contact.online,
          name: contact.name,
        };

        return userResponse;
      });
    });
  };
}

export default ChatHttpApi;
