import React from "react";
import { Card, Timeline, Typography, Spin, Tooltip } from "antd";
import { IOrderId } from "../../../../order.model";
import { FormattedDate, FormattedTime } from "react-intl";
import useOrderHistory from "features/orders/hooks/useOrderHistory";
import ErrorScreen from "components/errors/ErrorScreen";
import { AccountType } from "features/auth/auth.model";
import styles from "./OrderHistory.module.scss";
import HistoryEventFormatter from "features/orders/pages/new-details/components/order-history/history-event-formatter/HistoryEventFormatter";
import Avatar from "components/avatar/Avatar";

type PropsType = {
  orderId: IOrderId;
};

const OrderHistory = (props: PropsType) => {
  const { getOrderHistory, refresh, isLoading } = useOrderHistory(props.orderId);

  if (getOrderHistory === undefined) {
    return <Spin />;
  }

  if (getOrderHistory.isErr()) {
    return <ErrorScreen error={getOrderHistory.err().unwrap()} />;
  }

  const orderHistory = getOrderHistory.unwrap();
  const events = orderHistory.events;

  return (
    <Card>
      <div>
        <Typography.Title level={4}>Historia zmian</Typography.Title>
        <Timeline mode={"left"}>
          {events.map((eventEntry, idx) => {
            const useDentistColor = eventEntry.user !== null && eventEntry.user.accountType === AccountType.Dentist;

            return (
              <Timeline.Item
                key={idx}
                label={
                  <div className={styles.eventDate}>
                    <FormattedDate value={new Date(eventEntry.date)} />
                    <span>
                      (<FormattedTime value={new Date(eventEntry.date)} />)
                    </span>
                  </div>
                }
                color={useDentistColor ? "blue" : "gray"}
              >
                <section>
                  <HistoryEventFormatter event={eventEntry.event}>
                    {({ change, description }) => {
                      const initials =
                        eventEntry.user === null
                          ? null
                          : eventEntry.user.firstName.substring(0, 1).toUpperCase() +
                            eventEntry.user.lastName.substring(0, 1).toUpperCase();

                      return (
                        <>
                          <div>
                            {eventEntry.user !== null && initials !== null && (
                              <Tooltip title={eventEntry.user.firstName + " " + eventEntry.user.lastName}>
                                <Avatar
                                  size={"default"}
                                  useColor={useDentistColor}
                                  name={initials}
                                  className={"mr-2"}
                                />
                              </Tooltip>
                            )}
                            {description}
                          </div>
                          {change !== null && <div>{change}</div>}
                        </>
                      );
                    }}
                  </HistoryEventFormatter>
                </section>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </Card>
  );
};

export default OrderHistory;
