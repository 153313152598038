import * as React from "react";
import { ReactText, useRef, useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Button, Dropdown, Menu } from "antd";
import ProList from "@ant-design/pro-list";
import { IEmployee } from "../../employee.model";
import PersonNameFormatter, {
  PersonNameFormat,
} from "../../../../components/formatters/person-name/PersonNameFormatter";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import useSize from "../../../../hooks/useSize";
import { ListItemLayout } from "antd/lib/list";
import { Honorific } from "core/person-name/honorific";
import "./employee-list.scss";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { Path } from "core/routes/routes";

interface IEmployeeListProps {
  employees: IEmployee[];
  loading?: boolean;
  onEdit?: (employee: IEmployee) => void;
  onDelete?: (employee: IEmployee) => void;
}

enum Size {
  Small = "sm",
  Large = "lg",
}

const EmployeeList: React.FC<IEmployeeListProps> = ({ employees, loading = false, onEdit, onDelete }) => {
  let ref = useRef(null);

  const [size] = useSize(ref, {
    [Size.Small]: 0,
    [Size.Large]: 700,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly ReactText[]>([]);
  const layout: ListItemLayout = size === Size.Large ? "horizontal" : "vertical";

  return (
    <div className={"employees-list"} ref={ref}>
      <ProList<IEmployee>
        split
        pagination={{
          hideOnSinglePage: true,
          pageSize: 20,
        }}
        size={"large"}
        showActions="always"
        itemLayout={layout}
        headerTitle={<FormattedMessage id="employee-list.employee-list" defaultMessage="Lista pracowników" />}
        rowKey={(record) => record.employeeId.value}
        loading={loading}
        rowClassName={() =>
          classNames("employees-list__employees-row", {
            ["employees-list__row--vertical"]: layout === "vertical",
            ["employees-list__row--horizontal"]: layout === "horizontal",
          })
        }
        dataSource={employees}
        expandable={{
          expandedRowKeys,
          defaultExpandAllRows: false,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
        metas={{
          title: {
            render: (_, entity) => (
              <span>
                <PersonNameFormatter
                  format={PersonNameFormat.Short}
                  personName={{ honorific: Honorific.None, firstName: entity.firstName, lastName: entity.lastName }}
                />
              </span>
            ),
          },
          description: {
            render: () => (
              <ul>
                <li>
                  przyjęto zaproszenie do aplikacji
                  <CheckCircleOutlined />
                </li>
              </ul>
            ),
          },
          actions: {
            render: (text, entity) => [
              <Button key={"invite"} type={"link"} href={parametrizePath({ path: Path.InviteEmployee, params: {employeeId: entity.employeeId.value} })}>
                <FormattedMessage id="employee-list.send-invitation-link" defaultMessage="Zaproś do aplikacji" />
              </Button>,
              <Dropdown
                key={"actions-dropdown"}
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item key={"edit"} onClick={() => onEdit?.(entity)}>
                      <Button type={"link"}>
                        <FormattedMessage id="employee-list.edit-employee" defaultMessage="Edycja" />
                      </Button>
                    </Menu.Item>
                    <Menu.Item key={"delete"} onClick={() => onDelete?.(entity)}>
                      <Button type={"link"} danger={true}>
                        <FormattedMessage id="employee-list.delete-employee" defaultMessage="Usuń" />
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type={"link"} onClick={(e) => e.preventDefault()}>
                  <FormattedMessage id="employee-list.actions" defaultMessage="Akcje" />
                  <DownOutlined className={"ml-2"} />
                </Button>
              </Dropdown>,
            ],
          },
        }}
      />
    </div>
  );
};

export default EmployeeList;
