import * as React from "react";
import { IntlShape } from "react-intl";
import { specificationToCount } from "../../../specification/order-specification.functions";
import { OrderSpecification, SpecificationType } from "../../../specification/order-specification";
import { splitIntoGroups } from "./order-item-formatter.functions";
import { crownPartsFormatter } from "./crown-parts/crown-parts.formatter";
import { bridgePartsFormatter } from "./bridge-parts/bridge-parts.formatter";
import { archesPartFormatter } from "./arches-parts/arches-parts.formatter.";
import {
  quantitativeCustomOrderItemFormatter,
  quantitativeOrderItemFormatter,
} from "./quantity/quantitative-order-item.formatter";
import { mapPointsToSequences } from "../points-sequence/points-sequence.mapper";
import { formatPointsSequences } from "../points-sequence/points-sequence.string-formatter";
import { BridgePoint, CrownPoint } from "../../../specification/point-specification";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

export const orderItemFormatter = (
  intl: IntlShape,
  orderTypeName: string,
  orderCode: OrderCodeEnum | null,
  specification: OrderSpecification
) => {
  switch (specification.specificationType) {
    case SpecificationType.Quantity: {
      const count = specificationToCount(specification);

      if (orderCode === null) {
        return quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        return quantitativeOrderItemFormatter(intl, orderCode, count);
      }
    }
    case SpecificationType.Arches: {
      const count = specificationToCount(specification);
      const arches = specification.arches;
      let excerptFormat = "";

      if (orderCode === null) {
        excerptFormat = quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        excerptFormat = quantitativeOrderItemFormatter(intl, orderCode, count!);
      }

      return excerptFormat + " - " + archesPartFormatter(intl, arches);
    }
    case SpecificationType.LocatedPoints: {
      const count = specificationToCount(specification);
      const sequences = mapPointsToSequences(specification.points);
      let excerptFormat = "";

      if (orderCode === null) {
        excerptFormat = quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        excerptFormat = quantitativeOrderItemFormatter(intl, orderCode, count);
      }

      return excerptFormat + " - " + formatPointsSequences(sequences);
    }
    case SpecificationType.PartialDentures: {
      const count = 1;
      const sequences = mapPointsToSequences(specification.points);
      let excerptFormat = "";

      if (orderCode === null) {
        excerptFormat = quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        excerptFormat = quantitativeOrderItemFormatter(intl, orderCode, count!);
      }

      return excerptFormat + " - " + formatPointsSequences(sequences);
    }
    case SpecificationType.SurgicalGuides: {
      const count = 1;
      const sequences = mapPointsToSequences(specification.points);
      let excerptFormat = "";

      if (orderCode === null) {
        excerptFormat = quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        excerptFormat = quantitativeOrderItemFormatter(intl, orderCode, count!);
      }

      return excerptFormat + " - " + formatPointsSequences(sequences);
    }
    case SpecificationType.Crowns: {
      const count = specificationToCount(specification);
      let excerptFormat = "";

      if (orderCode === null) {
        excerptFormat = quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        excerptFormat = quantitativeOrderItemFormatter(intl, orderCode, count!);
      }

      const formattedCrownParts = formatCrownParts(intl, specification.points);
      return excerptFormat + " - " + formattedCrownParts.join(", ");
    }
    case SpecificationType.Foundations:
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges: {
      const count = specificationToCount(specification);
      let excerptFormat = "";

      if (orderCode === null) {
        excerptFormat = quantitativeCustomOrderItemFormatter(orderTypeName, count);
      } else {
        excerptFormat = quantitativeOrderItemFormatter(intl, orderCode, count);
      }

      const formattedBridges = specification.points
        .map((bridge) => {
          const formattedBridgeParts = formatBridgeParts(intl, bridge);
          return formattedBridgeParts.join(", ");
        })
        .join(" | ");

      return excerptFormat + " - " + formattedBridges;
    }
  }
};

export const formatCrownParts = (intl: IntlShape, points: CrownPoint[]) =>
  splitIntoGroups(points).map((group) => crownPartsFormatter(intl, group));

export const formatBridgeParts = (intl: IntlShape, points: BridgePoint[]) =>
  splitIntoGroups(points).map((group) => bridgePartsFormatter(intl, group));
