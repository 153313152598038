import * as React from "react";
import { Reducer, useReducer } from "react";
import { Breadcrumb, Descriptions, PageHeader, Result } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Button from "components/button/Button";
import HeaderContent from "features/layout/content/HeaderContent";
import Links from "components/links/Links";
import useEmployees from "../../hooks/useEmployees";
import { IEmployee, IEmployeeId } from "../../employee.model";
import useModal from "../../../../components/modals/useModal";
import EmployeeList from "../../components/employees-list/EmployeeList";
import PageContent from "../../../layout/content/PageContent";
import EmployeeModalForm, {
  IEmployeeModalFormSubmitValues,
} from "../../components/employee-modal-form/EmployeeModalForm";
import PersonNameFormatter, {
  PersonNameFormat,
} from "../../../../components/formatters/person-name/PersonNameFormatter";
import { Honorific } from "core/person-name/honorific";
import { IEmployeeQueryFilterValues } from "features/employee/components/dentist-query-filter/EmployeeQueryFilterValues";
import InvitedEmployeesCounts from "../../components/invited-employees-counts/InvitedDentistsCounts";
import EmployeeQueryFilter from "features/employee/components/dentist-query-filter/EmployeeQueryFilter";
import { Helmet } from "react-helmet";

type State =
  | {
      isModalVisible: true;
      modalType: "create";
      employee: null;
      search: IEmployeeQueryFilterValues;
    }
  | {
      isModalVisible: true;
      modalType: "edit";
      employee: IEmployee;
      search: IEmployeeQueryFilterValues;
    }
  | {
      isModalVisible: false;
      modalType: null;
      employee: null;
      search: IEmployeeQueryFilterValues;
    };

type Action =
  | { type: "showCreateModal" }
  | { type: "showEditModal"; employee: IEmployee }
  | { type: "hideModal" }
  | { type: "updateSearch"; search: IEmployeeQueryFilterValues };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showCreateModal":
      return { ...state, isModalVisible: true, modalType: "create", employee: null };
    case "showEditModal":
      return { ...state, isModalVisible: true, modalType: "edit", employee: action.employee };
    case "hideModal":
      return { ...state, isModalVisible: false, modalType: null, employee: null };
    case "updateSearch":
      return { isModalVisible: false, modalType: null, employee: null, search: action.search };
  }
};

interface IListEmployeePageProps {}

const matchesSearchCriteria = (employee: IEmployee, search: IEmployeeQueryFilterValues) => {
  let employeePhrase;

  if (search.searchPhrase === undefined || search.searchPhrase === null || search.searchPhrase.trim() === "") {
    employeePhrase = null;
  } else {
    employeePhrase = search.searchPhrase.trim().toLowerCase();
  }

  if (employeePhrase === null) {
    return true;
  }

  return (
    employee.firstName.toLowerCase().indexOf(employeePhrase) !== -1 ||
    employee.lastName.toLowerCase().indexOf(employeePhrase) !== -1
  );
};

const EmployeeListPage: React.FunctionComponent<IListEmployeePageProps> = () => {
  const modal = useModal();

  const { getEmployeesResponse, isValidating, revalidate, createEmployee, editEmployee, deleteEmployee } =
    useEmployees();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    isModalVisible: false,
    modalType: null,
    employee: null,
    search: {
      searchPhrase: "",
    },
  });

  const handleCreate = React.useCallback(
    async (submitValues: IEmployeeModalFormSubmitValues) => {
      const result = await createEmployee({
        firstName: submitValues.firstName,
        lastName: submitValues.lastName,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [createEmployee, revalidate]
  );

  const handleEdit = React.useCallback(
    (employeeId: IEmployeeId) => async (submitValues: IEmployeeModalFormSubmitValues) => {
      const result = await editEmployee({
        employeeId: employeeId,
        firstName: submitValues.firstName,
        lastName: submitValues.lastName,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [editEmployee, revalidate]
  );

  const handleDelete = React.useCallback(
    async (employeeId: IEmployeeId) => {
      const result = await deleteEmployee({
        employeeId: employeeId,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [deleteEmployee, revalidate]
  );

  const showDeleteConfirm = (employee: IEmployee) => {
    modal.confirm({
      title: (
        <FormattedMessage
          id="employee-list-page.are-you-sure-delete-employee"
          defaultMessage="Czy na pewno chcesz usunąć ?"
        />
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <Descriptions column={1} className={"mt-8"}>
          <Descriptions.Item label={<FormattedMessage id="employee-list-page.employee" defaultMessage="Pracownicy" />}>
            <PersonNameFormatter
              format={PersonNameFormat.Short}
              personName={{ honorific: Honorific.None, firstName: employee.firstName, lastName: employee.lastName }}
            />
          </Descriptions.Item>
        </Descriptions>
      ),
      okText: <FormattedMessage id="employee-list-page.yes" defaultMessage="Tak" />,
      okType: "danger",
      cancelText: <FormattedMessage id="employee-list-page.cancel" defaultMessage="Anuluj" />,
      onOk: () => handleDelete(employee.employeeId),
    });
  };

  return (
    <>
      <FormattedMessage id={"page-titles.employees"} defaultMessage={"Pracownicy"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <EmployeeModalForm
        key={state.modalType === "edit" ? state.employee.employeeId.value : "create-employee"}
        visible={state.isModalVisible}
        modalType={state.modalType === "edit" ? "edit" : "create"}
        values={
          state.modalType === "edit"
            ? {
                firstName: state.employee.firstName,
                lastName: state.employee.lastName,
              }
            : {}
        }
        onCancel={() => dispatch({ type: "hideModal" })}
        onSubmit={state.modalType === "edit" ? handleEdit(state.employee.employeeId) : handleCreate}
      />
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.EmployeesList />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id={"employee-list-page.employees"} defaultMessage={"Pracownicy"} />}
          extra={[
            <Button type={"primary"} key={"create-button"} onClick={() => dispatch({ type: "showCreateModal" })}>
              <PlusOutlined />
              <FormattedMessage id="employee-list-page.create-employee" defaultMessage="Dodaj pracownika" />
            </Button>,
          ]}
        />
      </HeaderContent>
      {getEmployeesResponse !== undefined &&
        getEmployeesResponse
          .map((employees) => {
            if (state.search.searchPhrase === "") {
              return employees;
            }

            return employees.filter((d) => matchesSearchCriteria(d, state.search));
          })
          .map((employees) => (
            <>
              <PageContent>
                <div className="my-8 bg-white">
                  <InvitedEmployeesCounts
                    totalEmployees={employees.length}
                    employeesInvited={0}
                    employeesAcceptedInvitation={0}
                  />
                </div>
              </PageContent>
              <PageContent>
                <div className="px-5 pt-5 pb-0">
                  <EmployeeQueryFilter
                    onSearch={(searchParams) => {
                      dispatch({ type: "updateSearch", search: searchParams });
                      return Promise.resolve();
                    }}
                  />
                </div>
              </PageContent>
              <PageContent>
                <div className="bg-white">
                  <EmployeeList
                    employees={employees}
                    loading={isValidating}
                    onEdit={(employee) => dispatch({ type: "showEditModal", employee })}
                    onDelete={showDeleteConfirm}
                  />
                </div>
              </PageContent>
            </>
          ))
          .unwrapOrElse(() => (
            <Result
              status={"error"}
              title={
                <FormattedMessage
                  id="employee-list-page.failed-to-download-employees-list"
                  defaultMessage="Wystąpił problem podczas ładowania listy pracowników"
                />
              }
            />
          ))}
    </>
  );
};

export default EmployeeListPage;
