import * as React from "react";
import OrderApiContext from "features/orders/order.context";
import { IPaginatedList } from "core/pagination/paginated-list";
import { IOrderId } from "features/orders/order.model";
import { Status } from "features/orders/status/order-status.model";
import useDentists from "features/dentist/hooks/useDentists";
import useEmployees from "features/employee/hooks/useEmployees";
import { Spin } from "antd";
import ErrorScreen from "components/errors/ErrorScreen";
import LabOrdersTable from "features/orders/pages/list/components/lab-orders-table/LabOrdersTable";
import ChangeFinishDateModalForm from "features/orders/components/change-finish-date-modal-form/ChangeFinishDateModal";
import { Reducer, useReducer, useRef } from "react";
import { ISODateTimeString } from "core/time/time.model";
import ToastMessages from "components/message/ToastMessages";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { ActionType } from "@ant-design/pro-table";
import {
  ILabOrdersProviderParams, ILabOrderTableEntry,
  LabOrdersDataProvider,
} from "features/orders/pages/list/components/lab-orders-table/lab-orders-table.models";

interface IListOrderPageProps {}

type State =
  | {
      isModalVisible: true;
      modalType: "changeFinishDate";
      modalProps: { orderId: IOrderId; finishDate: ISODateTimeString | null };
    }
  | {
      isModalVisible: false;
      modalType: null;
    };

type Action =
  | { type: "showChangeFinishDate"; orderId: IOrderId; finishDate: ISODateTimeString | null }
  | { type: "hideModal" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showChangeFinishDate":
      return {
        ...state,
        isModalVisible: true,
        modalType: "changeFinishDate",
        modalProps: { orderId: action.orderId, finishDate: action.finishDate },
      };
    case "hideModal":
      return { ...state, isModalVisible: false, modalType: null };
  }
};

const DateChangedMessage = ({ finishDate }: { finishDate: ISODateTimeString }) => (
  <FormattedMessage
    id={"lab-orders-list-view.finish-date-changed"}
    defaultMessage={"Data została zmieniona na {formattedDate}"}
    values={{
      formattedDate: (
        <>
          <FormattedDate value={new Date(finishDate)} /> <FormattedTime value={new Date(finishDate)} />
        </>
      ),
    }}
  />
);

const LabOrdersListView: React.FunctionComponent<IListOrderPageProps> = () => {
  const ordersApi = React.useContext(OrderApiContext);
  const { messageApi } = React.useContext(ToastMessages);
  const actionRef = useRef<ActionType>();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    isModalVisible: false,
    modalType: null,
  });

  const { getAllDentistsResponse } = useDentists();
  const { getEmployeesResponse } = useEmployees();

  const dataProvider: LabOrdersDataProvider = React.useCallback(
    async (params: ILabOrdersProviderParams) => {
      console.log("params: ", { params });

      const result = await ordersApi.getLabOrders(params);

      return result.map((orders) => {
        const paginatedEntries: IPaginatedList<ILabOrderTableEntry> = {
          pagination: { ...orders.pagination },
          data: orders.data.map((order) => {
            const entry: ILabOrderTableEntry = {
              orderId: order.orderId,
              labId: order.labId,
              dentist: order.dentist,
              patient: order.patient,
              orderNumber: order.orderNumber,
              startDate: order.startDate,
              dueDate: order.dueDate,
              finishDate: order.finishDate,
              status: order.status,
              note: order.orderNote,
              billingStatement: order.billingStatement,
              assignedEmployees: order.assignedEmployees,
            };

            return entry;
          }),
        };

        return paginatedEntries;
      });
    },
    [ordersApi]
  );

  const handleDelete = React.useCallback((orderId: IOrderId) => ordersApi.deleteLabOrder(orderId), [ordersApi]);

  const handleStatusChange = React.useCallback(
    (orderId: IOrderId, nextStatus: Status) =>
      ordersApi.changeOrderStatus({ orderId, status: nextStatus }).then((result) => result.map(() => nextStatus)),
    [ordersApi]
  );

  const handleAssigned = React.useCallback(() => {
    actionRef?.current?.reload();
  }, []);

  if (getAllDentistsResponse === undefined || getEmployeesResponse === undefined) {
    return <Spin />;
  } else if (getAllDentistsResponse.isErr()) {
    return <ErrorScreen error={getAllDentistsResponse.err().unwrap()} />;
  } else if (getEmployeesResponse.isErr()) {
    return <ErrorScreen error={getEmployeesResponse.err().unwrap()} />;
  }

  const handleChangeFinishDate = (orderId: IOrderId) => {
    return async (values: { finishDate: ISODateTimeString }) => {
      const result = await ordersApi.changeFinishDate({ orderId, finishDate: values.finishDate });

      if (result.isOk()) {
        dispatch({ type: "hideModal" });
        messageApi.success(<DateChangedMessage finishDate={values.finishDate} />);

        actionRef?.current?.reload();
      }

      return result;
    };
  };



  return (
    <>
      {state.isModalVisible && state.modalType === "changeFinishDate" && (
        <ChangeFinishDateModalForm
          visible={true}
          values={{ finishDate: state.modalProps.finishDate }}
          onCancel={() => dispatch({ type: "hideModal" })}
          onSubmit={handleChangeFinishDate(state.modalProps.orderId)}
        />
      )}
      <LabOrdersTable
        dentists={getAllDentistsResponse.unwrap()}
        employees={getEmployeesResponse.unwrap()}
        dataProvider={dataProvider}
        actionRef={actionRef}
        onStatusChange={handleStatusChange}
        onAssigned={handleAssigned}
        onChangeFinishDate={(orderId, finishDate) => dispatch({ type: "showChangeFinishDate", orderId, finishDate })}
        onDelete={handleDelete}
      />
    </>
  );
};

export default LabOrdersListView;
