import { prettyJSON } from "core/utils/debugg";

// this would be set for example to true when
// in debug mode
export var isDebugModeActive: boolean = false;

type noopFn = (...args: any[]) => any;

let trap: noopFn = () => {};
let noop: noopFn = () => {};
let logNoop: noopFn = (...args: any[]) => {
  console.info("log: ", prettyJSON(args));
};

if (isDebugModeActive) {
  trap = () => {};
} else {
  trap = () => {
    debugger;
  };
}

export { trap, noop, logNoop };
