import * as React from "react";
import { useCallback, useRef } from "react";
import { ActionType, EditableProTable, ProColumns } from "@ant-design/pro-table";
import { FormattedMessage } from "react-intl";
import LocalizedActionButton from "i18n/translations/3rd-party/editable-table/action-buttons-hack";
import { IBillingStatementLineId, ILineItem } from "features/invoicing/models/invoicing.models";
import { IDentist, NewDentistId } from "features/dentist/dentist.model";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import MoneyFormatter from "components/formatters/money/MoneyFormatter";
import MoneyInput from "components/forms/money-input/MoneyInput";
import Button from "components/button/Button";
import { PlusOutlined } from "@ant-design/icons";
import { CreateGuid } from "core/guid/guid";
import { Currency } from "core/money/money.model";
import { Divider, Popconfirm } from "antd";

type ITableRow = Omit<ILineItem, "dentistId"> & {
  dentistId: string | null;
};

interface ILineItemsTableProps {
  dentists: IDentist[];
  lines: ILineItem[];
  editableIds: IBillingStatementLineId[];
  onSetEditableLines: (lines: IBillingStatementLineId[]) => void;
  onLinesChange: (lines: ILineItem[]) => Promise<void>;
}

const LineItemsTable: React.FunctionComponent<ILineItemsTableProps> = ({
  dentists,
  lines,
  editableIds,
  onLinesChange,
  onSetEditableLines,
  ...props
}) => {
  const tableRef = useRef<ActionType>();
  const rows: ITableRow[] = lines.map((x) => ({ ...x, dentistId: x.dentistId?.value ?? null }));

  const handleSaveRecord = useCallback(
    async (record: ITableRow) => {
      const current: ILineItem = {
        id: record.id,
        patient: record.patient,
        dentistId: record.dentistId === null ? null : NewDentistId(record.dentistId),
        price: record.price,
        description: record.description,
        orderNumber: record.orderNumber,
        orderId: record.orderId,
      };

      const exists = lines.some((lineItem) => lineItem.id.value === record.id.value);
      const next = exists
        ? lines.map((lineItem) => (lineItem.id.value !== record.id.value ? lineItem : current))
        : [...lines, current];

      await onLinesChange(next);

      onSetEditableLines([]);
    },
    [onLinesChange, lines, onSetEditableLines]
  );

  const handleRemoveRecord = useCallback(
    async (recordKey: string) => {
      const exists = lines.some((lineItem) => lineItem.id.value === recordKey);

      if (exists) {
        const next = lines.filter((lineItem) => lineItem.id.value !== recordKey);
        await onLinesChange(next);
        onSetEditableLines([]);
      }
    },
    [onLinesChange, lines, onSetEditableLines]
  );

  const columns: ProColumns<ITableRow>[] = [
    {
      title: <FormattedMessage id="common.order-number" defaultMessage="Numer zamówienia" />,
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: "120px",
      editable: (text, record) => false,
      render: (text: any, record) => text,
    },
    {
      title: <FormattedMessage id="common.order-name" defaultMessage="Nazwa zamówienia" />,
      dataIndex: "description",
      key: "description",
      editable: (text, record) => true,
      render: (text: any, record) => <span>{record.description}</span>,
    },
    {
      title: <FormattedMessage id="common.patient" defaultMessage="Pacjent" />,
      dataIndex: "patient",
      key: "patient",
    },
    {
      key: "dentistId",
      title: <FormattedMessage id="common.recipient" defaultMessage="Odbiorca" />,
      valueType: "select",
      dataIndex: "dentistId",
      valueEnum: (record) =>
        dentists.reduce(
          (acc, dentist) => ({
            ...acc,
            [dentist.id.value]: {
              text: <PersonNameFormatter personName={dentist.name} />,
            },
          }),
          {}
        ),
      render: (_: any, record) => {
        const dentist = dentists.find((d) => record.dentistId !== null && d.id.value === record.dentistId);
        return dentist === undefined ? null : <PersonNameFormatter personName={dentist.name} />;
      },
    },
    {
      key: "price",
      dataIndex: "price",
      title: <FormattedMessage id="common.price" defaultMessage="Cena" />,
      render: (_: any, record) => <MoneyFormatter value={record.price} />,
      renderFormItem: (schema, config, form) => <MoneyInput {...(config as any).fieldProps} />,
    },
    {
      title: <FormattedMessage id="common.option" defaultMessage="Opcje" />,
      valueType: "option",
      render: (text, record, _, action) => {
        return [
          <Button key="edit" type="text" onClick={() => action?.startEditable?.(record.id.value)}>
            <FormattedMessage id="common.edit" defaultMessage="Edycja" />
          </Button>,
          <Divider key="divider" type="vertical" />,
          ...(record.orderId !== null
            ? []
            : [
                <Popconfirm
                  key="delete"
                  title={<FormattedMessage id="common.remove-row-question-mark" defaultMessage="Czy chcesz usunąć ?" />}
                  onConfirm={() => handleRemoveRecord(record.id.value)}
                >
                  <Button type="text">
                    <FormattedMessage id="common.remove" defaultMessage="Usuń" />
                  </Button>
                </Popconfirm>,
              ]),
        ];
      },
    },
  ];

  const handleAddNew = useCallback(() => {
    const newLine: ITableRow = {
      id: { type: "billing-statement-line-id", value: CreateGuid() },
      dentistId: dentists.length === 0 ? null : dentists.length === 1 ? dentists[0].id.value : null,
      orderNumber: null,
      orderId: null,
      patient: "",
      description: "",
      price: { amount: 0, currency: Currency.PLN },
    };

    tableRef.current?.addEditRecord?.(newLine);
  }, [dentists]);

  return (
    <EditableProTable<ITableRow>
      headerTitle={
        <FormattedMessage
          id="line-items-table.billing-statement-line-items"
          defaultMessage={"Pozycje na rozliczeniu"}
        />
      }
      actionRef={tableRef}
      rowKey={(node) => node.id.value}
      style={{ padding: 0 }}
      recordCreatorProps={false}
      columns={columns}
      value={rows.sort((r1, r2) => r2.orderNumber! - r1.orderNumber!)}
      toolBarRender={() => [
        <Button key="button" icon={<PlusOutlined className={"mr-2"} />} onClick={handleAddNew}>
          <FormattedMessage id="line-items-table.add-new-item" defaultMessage={"Dodaj wpis"} />
        </Button>,
      ]}
      pagination={{
        hideOnSinglePage: true,
        showTotal: (total, range) => <>{`${range[0]}-${range[1]} z ${total}`}</>,
      }}
      options={{
        fullScreen: true,
        density: true,
        reload: false,
        setting: false,
      }}
      editable={{
        type: "multiple",
        editableKeys: editableIds.map((k) => k.value),
        actionRender: (_row, _config, defaultDOMs) => [
          <LocalizedActionButton key={"save"} originalButton={defaultDOMs.save}>
            <FormattedMessage id="common.save" defaultMessage="Zapisz" />
          </LocalizedActionButton>,
          <LocalizedActionButton key={"cancel"} originalButton={defaultDOMs.cancel}>
            <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />
          </LocalizedActionButton>,
        ],
        onSave: async (_key, record) => await handleSaveRecord(record),
        onChange: (selectedKeys: React.Key[]) =>
          onSetEditableLines(selectedKeys.map((k) => ({ type: "billing-statement-line-id", value: k as string }))),
      }}
    />
  );
};

export default LineItemsTable;
