import { IAddress } from "core/address/address.model";
import { IValueObject } from "core/value-object/value-object";
import { ITaxId } from "core/taxes/tax-id.model";

export interface ILabId extends IValueObject<string> {
  type: "dental-lab-id";
}

export const NewLabId = (id: string): ILabId => ({ type: "dental-lab-id", value: id });

export interface IShortLab {
  id: ILabId;
  name: string;
}

export interface ILabBillingInfo {
  taxId: ITaxId;
  address: IAddress;
}

export interface ILab extends IShortLab {
  id: ILabId;
  name: string;
  address: IAddress | null;
  billing: ILabBillingInfo | null;
}