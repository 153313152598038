import React from "react";
import { FormattedMessage } from "react-intl";
import { Breadcrumb, PageHeader, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import { Money } from "core/money/money.functions";
import { useNavigate } from "react-router-dom";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { Path } from "core/routes/routes";
import { ICreateDentistOrderRequest } from "features/orders/requests/orders.requests";
import { mapSpecificationToSpecificationDto } from "features/orders/contracts/orders.dto.mappers";
import OrderApiContext from "features/orders/order.context";
import {
  FormMode,
  ICreateDentistOrderSubmitValues,
  OnLoadOrderCatalogueForLabFn,
  OnLoadPricingListForLabFn,
} from "features/orders/components/order-form/order-form.model";
import { whenEvery } from "core/lib/types/result.utils";
import OrderForm from "features/orders/components/order-form/OrderForm";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { IUserId } from "features/auth/auth.model";
import useDentistLabs from "features/dental-lab/hook/dentist/useDentistLabs";
import CategoriesServiceContext from "features/catalogue/categories.context";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";

export interface ICreateDentistOrderViewProps {
  userId: IUserId;
}

const CreateDentistOrderView: React.FunctionComponent<ICreateDentistOrderViewProps> = ({ userId }) => {
  const pricingListApi = React.useContext(PricingListApiContext);
  const ordersApi = React.useContext(OrderApiContext);
  const categoriesService = React.useContext(CategoriesServiceContext);
  const navigate = useNavigate();
  const { getAllDentistLabs } = useDentistLabs();

  const getOrdersCatalogueForLab: OnLoadOrderCatalogueForLabFn = React.useCallback(
    async (labId: ILabId) => {
      return await categoriesService!.getOrderCatalogue(labId);

      // if (response.isErr()) {
      //   return new Err<IOrderCatalogue>(response.err().unwrap());
      // }
      //
      // const [orderCategories, orderTypes] = response.unwrap();
      //
      // const catalogueTree = mapCategoriesAndTypesToCatalogueItemsTree({
      //   orderCategories: orderCategories,
      //   orderTypes: orderTypes,
      // });
      //
      // return new Ok<IOrderCatalogue>({ catalogueTree, orderCategories, orderTypes });
    },
    [categoriesService]
  );

  const handleLoadPricingListForDentist: OnLoadPricingListForLabFn = React.useCallback(
    (dentistId: IUserId, labId: ILabId) => pricingListApi.getForLab(dentistId, labId),
    [pricingListApi]
  );

  const handleCreateOrder = React.useCallback(
    async (values: ICreateDentistOrderSubmitValues) => {
      const request: ICreateDentistOrderRequest = {
        labId: values.labId,
        patient: {
          age: values.patientAge,
          patientCode: values.patientCode,
          gender: values.patientGender,
          lastName: values.patientLastName,
          firstName: values.patientFirstName,
          country: values.patientCountry,
        },
        orderNote: values.orderNote,
        startDate: values.startDate,
        dueDate: values.dueDate,
        orderColor: values.orderColor,
        orderItems: values.orderEntries.map((orderEntry) => ({
          orderTypeId: orderEntry.orderType.id.value,
          specification: mapSpecificationToSpecificationDto(orderEntry.specification),
          price: orderEntry.price === null ? Money.PLN(0) : orderEntry.price,
        })),
      };

      const result = await ordersApi.createDentistOrder(request);

      if (result.isOk()) {
        navigate(parametrizePath({ path: Path.OrderDetails, params: { orderId: result.unwrap().value } }));
      }

      return result;
    },
    [navigate, ordersApi]
  );

  if (getAllDentistLabs === undefined) {
    return <Spin />;
  }

  return (
    <div className={"w-full"}>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.OrdersList />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.CreateOrder />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="create-order-wizard.create-new-oder" defaultMessage="Stwórz nowe zamówienie" />}
        />
      </HeaderContent>
      {whenEvery([getAllDentistLabs])
        .map(([labs]) => (
          <OrderForm
            mode={FormMode.DentistCreate}
            dentistUserId={userId}
            labs={labs}
            onLoadOrderCatalogue={getOrdersCatalogueForLab}
            onLoadPricingListForLab={handleLoadPricingListForDentist}
            onSubmit={handleCreateOrder}
          />
        ))
        .unwrap()}
    </div>
  );
};

export default CreateDentistOrderView;
