import IApiResponseEnvelope from "../../../contracts/envelope/api-response-envelope";
import { AccountType } from "../auth.model";
import { guid } from "core/guid/guid";

export enum KnownClaimTypes {
  UserId = "userId",
  FamilyName = "familyName",
  GivenName = "givenName",
  Email = "email",
  EmailVerified = "emailVerified",
  AccountType = "accountType",
  Roles = "roles",
  TenantId = "tenantId",
  EmployeeId = "employeeId",
  Rights = "rights",
}

export enum UserRights {
  READ_ORDERS = "read:orders",
  WRITE_ORDERS = "write:orders",
  ORDER_ASSIGNMENT_FOR_ALL = "assign-others:orders",
  ORDER_ASSIGNMENT_FOR_SELF = "assign-self:orders",
  READ_ORDER_PRICES = "read:order-prices",
  WRITE_ORDER_PRICES = "write:order-prices",
  READ_INVOICES = "read:invoices",
  WRITE_INVOICES = "write:invoices",
  READ_DENTISTS = "read:dentists",
  WRITE_DENTISTS = "write:dentists",
  INVITE_DENTISTS = "invite:dentists",
  READ_EMPLOYEES = "read:employees",
  WRITE_EMPLOYEES = "write:employees",
  INVITE_EMPLOYEES = "invite:employees",
  READ_PROCEDURES = "read:procedures",
  WRITE_PROCEDURES = "write:procedures",
  READ_PRICE_LISTS = "read:price-lists",
  WRITE_PRICE_LISTS = "write:price-lists",
}

// obsolete
export enum DomainClaim {
  ORDER_CREATE = "ORDER_CREATE",
  ORDER_READ = "ORDER_READ",
  ORDER_WRITE = "ORDER_WRITE",
  INVOICE_READ = "INVOICE_READ",
  INVOICE_WRITE = "INVOICE_WRITE",
  PRICES_READ = "PRICES_READ",
  PRICES_WRITE = "PRICES_WRITE",
}

export interface ILoginRequestDTO {
  login: string;
  password: string;
}

export interface ILoginResponseDTO extends IApiResponseEnvelope<{ token: string }> {}

export interface IRegisterRequestDTO {
  name: string;
  surname: string;
  companyName: string;
  email: string;
  password: string;
  type: AccountType;
  captchaToken: string,
}

export interface IRegisterResponseDTO
  extends IApiResponseEnvelope<{
    tenantId: string;
    userId: string;
    confirmEmailLink: string | null;
  }> {}

export interface IConfirmEmailQueryParamsDTO {
  email: string;
  company: string;
  token: string;
}

export interface IConfirmEmailResponseDTO extends IApiResponseEnvelope<guid> {}

export interface IResetPasswordRequestDTO {
  email: string;
}

export interface IResetPasswordResponseDTO extends IApiResponseEnvelope<{
  url?: string;
}> {}

export interface ISetNewPasswordRequestDTO {
  email: string;
  password: string;
  token: string;
}

export interface ISetNewPasswordResponseDTO extends IApiResponseEnvelope<{}> {}
