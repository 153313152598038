import { ILineItem} from "features/invoicing/models/invoicing.models";
import { ILineItemDTO } from "features/invoicing/contracts/invoices.response.dto";

export const mapLineItemToLineItemDTO = (lineItem: ILineItem): ILineItemDTO => {
  const dto: ILineItemDTO = {
    id: lineItem.id.value,
    dentistId: lineItem.dentistId?.value ?? null,
    orderId: lineItem.orderId?.value ?? null,
    orderNumber: lineItem.orderNumber,
    description: lineItem.description,
    price: lineItem.price,
    patient: lineItem.patient,
  };

  return dto;
};
