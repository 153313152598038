export enum VitaClassicalShade {
  ZERO_M1 = "VC-0M1",
  ZERO_M2 = "VC-0M2",
  ZERO_M3 = "VC-0M3",
  A1 = "VC-A1",
  A2 = "VC-A2",
  A3 = "VC-A3",
  A3_5 = "VC-A3.5",
  A4 = "VC-A4",
  B1 = "VC-B1",
  B2 = "VC-B2",
  B3 = "VC-B3",
  B4 = "VC-B4",
  C1 = "VC-C1",
  C2 = "VC-C2",
  C3 = "VC-C3",
  C4 = "VC-C4",
  D2 = "VC-D2",
  D3 = "VC-D3",
  D4 = "VC-D4",
}

export enum Vita3dMasterShade {
  ZERO_M1 = "V3D-0M1",
  ZERO_M2 = "V3D-0M2",
  ZERO_M3 = "V3D-0M3",
  ONE_M1 = "V3D-1M1",
  ONE_M2 = "V3D-1M2",
  TWO_L1_5 = "V3D-2L1.5",
  TWO_L2_5 = "V3D-2L2.5",
  TWO_M1 = "V3D-2M1",
  TWO_M2 = "V3D-2M2",
  TWO_M3 = "V3D-2M3",
  TWO_R1_5 = "V3D-2R1.5",
  TWO_R2_5 = "V3D-2R2.5",
  THREE_L1_5 = "V3D-3L1.5",
  THREE_L2_5 = "V3D-3L2.5",
  THREE_M1 = "V3D-3M1",
  THREE_M2 = "V3D-3M2",
  THREE_M3 = "V3D-3M3",
  THREE_R1_5 = "V3D-3R1.5",
  THREE_R2_5 = "V3D-3R2.5",
  FOUR_L1_5 = "V3D-4L1.5",
  FOUR_L2_5 = "V3D-4L2.5",
  FOUR_M1 = "V3D-4M1",
  FOUR_M2 = "V3D-4M2",
  FOUR_M3 = "V3D-4M3",
  FOUR_R1_5 = "V3D-4R1.5",
  FOUR_R2_5 = "V3D-4R2.5",
  FIVE_M1 = "V3D-5M1",
  FIVE_M2 = "V3D-5M2",
  FIVE_M3 = "V3D-5M3",
}

export enum BleachShade {
  BL1 = "BL-BL1",
  BL2 = "BL-BL2",
  BL3 = "BL-BL3",
  BL4 = "BL-BL4",
}

export enum ShadeGuide {
  VitaClassical = "VitaClassical",
  Vita3dMaster = "Vita3DMaster",
  Bleach = "Bleach",
}

export interface IVitaClassicalColor {
  readonly guide: ShadeGuide.VitaClassical;
  readonly shade: VitaClassicalShade;
}

export interface IVita3dMasterColor {
  readonly guide: ShadeGuide.Vita3dMaster;
  readonly shade: Vita3dMasterShade;
}

export interface IBleachColor {
  readonly guide: ShadeGuide.Bleach;
  readonly shade: BleachShade;
}

export const NO_COLOR = "not-provided";

export type NoColor = typeof NO_COLOR;
export type Shade = Vita3dMasterShade | VitaClassicalShade | BleachShade;
export type Color = IVitaClassicalColor | IVita3dMasterColor | IBleachColor;
export type ColorOrNone = Color | NoColor;
