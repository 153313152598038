import { Arch, ITooth, Quadrant, ToothGroup } from "./tooth.model";
import { DotId, PointId } from "core/point/point.enums";

const { Incisors, Premolar, Molar } = ToothGroup;

const {
  P18,
  P17,
  P16,
  P15,
  P14,
  P13,
  P12,
  P11,
  P21,
  P22,
  P23,
  P24,
  P25,
  P26,
  P27,
  P28,
  P48,
  P47,
  P46,
  P45,
  P44,
  P43,
  P42,
  P41,
  P31,
  P32,
  P33,
  P34,
  P35,
  P36,
  P37,
  P38,
} = PointId;

const tooth18: ITooth = {
  id: P18,
  group: Molar,
  universalIndex: 1,
  arch: Arch.UpperArch,
  readingSortValue: 1,
  quadrant: Quadrant.UpperLeft,
};
const tooth17: ITooth = {
  id: P17,
  group: Molar,
  universalIndex: 2,
  arch: Arch.UpperArch,
  readingSortValue: 2,
  quadrant: Quadrant.UpperLeft,
};
const tooth16: ITooth = {
  id: P16,
  group: Molar,
  universalIndex: 3,
  arch: Arch.UpperArch,
  readingSortValue: 3,
  quadrant: Quadrant.UpperLeft,
};
const tooth15: ITooth = {
  id: P15,
  group: Premolar,
  universalIndex: 4,
  arch: Arch.UpperArch,
  readingSortValue: 4,
  quadrant: Quadrant.UpperLeft,
};
const tooth14: ITooth = {
  id: P14,
  group: Premolar,
  universalIndex: 5,
  arch: Arch.UpperArch,
  readingSortValue: 5,
  quadrant: Quadrant.UpperLeft,
};
const tooth13: ITooth = {
  id: P13,
  group: Incisors,
  universalIndex: 6,
  arch: Arch.UpperArch,
  readingSortValue: 6,
  quadrant: Quadrant.UpperLeft,
};
const tooth12: ITooth = {
  id: P12,
  group: Incisors,
  universalIndex: 7,
  arch: Arch.UpperArch,
  readingSortValue: 7,
  quadrant: Quadrant.UpperLeft,
};
const tooth11: ITooth = {
  id: P11,
  group: Incisors,
  universalIndex: 8,
  arch: Arch.UpperArch,
  readingSortValue: 8,
  quadrant: Quadrant.UpperLeft,
};
const tooth21: ITooth = {
  id: P21,
  group: Incisors,
  universalIndex: 9,
  arch: Arch.UpperArch,
  readingSortValue: 9,
  quadrant: Quadrant.UpperRight,
};
const tooth22: ITooth = {
  id: P22,
  group: Incisors,
  universalIndex: 10,
  arch: Arch.UpperArch,
  readingSortValue: 10,
  quadrant: Quadrant.UpperRight,
};
const tooth23: ITooth = {
  id: P23,
  group: Incisors,
  universalIndex: 11,
  arch: Arch.UpperArch,
  readingSortValue: 11,
  quadrant: Quadrant.UpperRight,
};
const tooth24: ITooth = {
  id: P24,
  group: Premolar,
  universalIndex: 12,
  arch: Arch.UpperArch,
  readingSortValue: 12,
  quadrant: Quadrant.UpperRight,
};
const tooth25: ITooth = {
  id: P25,
  universalIndex: 13,
  group: Premolar,
  arch: Arch.UpperArch,
  readingSortValue: 13,
  quadrant: Quadrant.UpperRight,
};
const tooth26: ITooth = {
  id: P26,
  group: Molar,
  universalIndex: 14,
  arch: Arch.UpperArch,
  readingSortValue: 14,
  quadrant: Quadrant.UpperRight,
};
const tooth27: ITooth = {
  id: P27,
  group: Molar,
  universalIndex: 15,
  arch: Arch.UpperArch,
  readingSortValue: 15,
  quadrant: Quadrant.UpperRight,
};
const tooth28: ITooth = {
  id: P28,
  group: Molar,
  universalIndex: 16,
  arch: Arch.UpperArch,
  readingSortValue: 16,
  quadrant: Quadrant.UpperRight,
};
const tooth48: ITooth = {
  id: P48,
  group: Molar,
  universalIndex: 32,
  arch: Arch.LowerArch,
  readingSortValue: 17,
  quadrant: Quadrant.LowerLeft,
};
const tooth47: ITooth = {
  id: P47,
  group: Molar,
  universalIndex: 31,
  arch: Arch.LowerArch,
  readingSortValue: 18,
  quadrant: Quadrant.LowerLeft,
};
const tooth46: ITooth = {
  id: P46,
  group: Molar,
  universalIndex: 30,
  arch: Arch.LowerArch,
  readingSortValue: 19,
  quadrant: Quadrant.LowerLeft,
};
const tooth45: ITooth = {
  id: P45,
  group: Premolar,
  universalIndex: 29,
  arch: Arch.LowerArch,
  readingSortValue: 20,
  quadrant: Quadrant.LowerLeft,
};
const tooth44: ITooth = {
  id: P44,
  group: Premolar,
  universalIndex: 28,
  arch: Arch.LowerArch,
  readingSortValue: 21,
  quadrant: Quadrant.LowerLeft,
};
const tooth43: ITooth = {
  id: P43,
  group: Incisors,
  universalIndex: 27,
  arch: Arch.LowerArch,
  readingSortValue: 22,
  quadrant: Quadrant.LowerLeft,
};
const tooth42: ITooth = {
  id: P42,
  group: Incisors,
  universalIndex: 26,
  arch: Arch.LowerArch,
  readingSortValue: 23,
  quadrant: Quadrant.LowerLeft,
};
const tooth41: ITooth = {
  id: P41,
  group: Incisors,
  universalIndex: 25,
  arch: Arch.LowerArch,
  readingSortValue: 24,
  quadrant: Quadrant.LowerLeft,
};
const tooth31: ITooth = {
  id: P31,
  group: Incisors,
  universalIndex: 24,
  arch: Arch.LowerArch,
  readingSortValue: 25,
  quadrant: Quadrant.LowerRight,
};
const tooth32: ITooth = {
  id: P32,
  group: Incisors,
  universalIndex: 23,
  arch: Arch.LowerArch,
  readingSortValue: 26,
  quadrant: Quadrant.LowerRight,
};
const tooth33: ITooth = {
  id: P33,
  group: Incisors,
  universalIndex: 22,
  arch: Arch.LowerArch,
  readingSortValue: 27,
  quadrant: Quadrant.LowerRight,
};
const tooth34: ITooth = {
  id: P34,
  group: Premolar,
  universalIndex: 21,
  arch: Arch.LowerArch,
  readingSortValue: 28,
  quadrant: Quadrant.LowerRight,
};
const tooth35: ITooth = {
  id: P35,
  group: Premolar,
  universalIndex: 20,
  arch: Arch.LowerArch,
  readingSortValue: 29,
  quadrant: Quadrant.LowerRight,
};
const tooth36: ITooth = {
  id: P36,
  group: Molar,
  universalIndex: 19,
  arch: Arch.LowerArch,
  readingSortValue: 30,
  quadrant: Quadrant.LowerRight,
};
const tooth37: ITooth = {
  id: P37,
  group: Molar,
  universalIndex: 18,
  arch: Arch.LowerArch,
  readingSortValue: 31,
  quadrant: Quadrant.LowerRight,
};
const tooth38: ITooth = {
  id: P38,
  group: Molar,
  universalIndex: 17,
  arch: Arch.LowerArch,
  readingSortValue: 32,
  quadrant: Quadrant.LowerRight,
};

export const allTeeths: ITooth[] = [
  tooth18,
  tooth17,
  tooth16,
  tooth15,
  tooth14,
  tooth13,
  tooth12,
  tooth11,
  tooth21,
  tooth22,
  tooth23,
  tooth24,
  tooth25,
  tooth26,
  tooth27,
  tooth28,
  tooth48,
  tooth47,
  tooth46,
  tooth45,
  tooth44,
  tooth43,
  tooth42,
  tooth41,
  tooth31,
  tooth32,
  tooth33,
  tooth34,
  tooth35,
  tooth36,
  tooth37,
  tooth38,
];

export const toothById: Record<PointId, ITooth> = {
  [P18]: tooth18,
  [P17]: tooth17,
  [P16]: tooth16,
  [P15]: tooth15,
  [P14]: tooth14,
  [P13]: tooth13,
  [P12]: tooth12,
  [P11]: tooth11,
  [P21]: tooth21,
  [P22]: tooth22,
  [P23]: tooth23,
  [P24]: tooth24,
  [P25]: tooth25,
  [P26]: tooth26,
  [P27]: tooth27,
  [P28]: tooth28,
  [P48]: tooth48,
  [P47]: tooth47,
  [P46]: tooth46,
  [P45]: tooth45,
  [P44]: tooth44,
  [P43]: tooth43,
  [P42]: tooth42,
  [P41]: tooth41,
  [P31]: tooth31,
  [P32]: tooth32,
  [P33]: tooth33,
  [P34]: tooth34,
  [P35]: tooth35,
  [P36]: tooth36,
  [P37]: tooth37,
  [P38]: tooth38,
};

export const allDots = [
  DotId.P18_P17,
  DotId.P17_P16,
  DotId.P16_P15,
  DotId.P15_P14,
  DotId.P14_P13,
  DotId.P13_P12,
  DotId.P12_P11,
  DotId.P11_P21,
  DotId.P21_P22,
  DotId.P22_P23,
  DotId.P23_P24,
  DotId.P24_P25,
  DotId.P25_P26,
  DotId.P26_P27,
  DotId.P27_P28,
  //
  DotId.P48_P47,
  DotId.P47_P46,
  DotId.P46_P45,
  DotId.P45_P44,
  DotId.P44_P43,
  DotId.P43_P42,
  DotId.P42_P41,
  DotId.P41_P31,
  DotId.P31_P32,
  DotId.P32_P33,
  DotId.P33_P34,
  DotId.P34_P35,
  DotId.P35_P36,
  DotId.P36_P37,
  DotId.P37_P38,
]