import * as React from "react";
import { useCallback, useContext, useState } from "react";
import ResetPasswordForm, {
  IResetPasswordFormSubmitValues,
} from "features/auth/components/reset-password-form/ResetPasswordForm";
import AuthServiceContext from "../../auth-service.context";
import { Ok } from "core/lib/types/ok";
import { Err } from "core/lib/types/error";
import { AuthEmptyPageLayout, AuthPageLayout } from "../../components/auth-page-layout/AuthPageLayout";
import { isNullOrEmpty } from "core/utils/string-utils";
import { Alert, Result } from "antd";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

const ResetLink = ({ resetLink }: { resetLink: string | undefined }) => {
  if (isNullOrEmpty(resetLink)) {
    return null;
  }

  const url = new URL(resetLink!);

  if (url.host !== window.location.host) {
    url.protocol = "http";
    url.host = window.location.host;
  }

  const { href: link } = url;

  return (
    <Alert
      key={link}
      message={
        <div>
          <a href={link}>{link}</a>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

interface IResetPasswordPageProps {}

const ResetPasswordPage: React.FC<IResetPasswordPageProps> = () => {
  const authService = useContext(AuthServiceContext);
  const [state, setState] = useState<{ submitted: boolean; resetLink?: string }>({
    submitted: false,
    resetLink: undefined,
  });

  const handleReset = useCallback(
    async (submitValues: IResetPasswordFormSubmitValues) => {
      const resetPasswordResult = await authService.resetPassword({
        email: submitValues.email,
      });

      if (resetPasswordResult.isErr()) {
        return new Err(resetPasswordResult.err().unwrap());
      }

      const { url } = resetPasswordResult.unwrap();

      if (!isNullOrEmpty(url)) {
        setTimeout(() => setState({ submitted: true, resetLink: url })); // setTimeout prevents state of unmounted component error
      } else {
        setTimeout(() => setState({ submitted: true, resetLink: undefined })); // setTimeout prevents state of unmounted component error
      }

      return new Ok(undefined);
    },
    [authService]
  );

  return (
    <>
      <FormattedMessage id={"common.reset-password"} defaultMessage={"Resetuj hasło"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      {state.submitted ? (
        <AuthEmptyPageLayout>
          <Result
            status={"info"}
            title={
              <FormattedMessage
                id="confirm-email-page.password-reset-operation-begin"
                defaultMessage="Rozpoczęto reset hasła"
              />
            }
            subTitle={
              <FormattedMessage
                id="confirm-email-page.password-reset-operation-subtitle"
                defaultMessage="Na podany adres e-mail został wysłany link, który pozwoli Ci zmienić hasło do Twojego konta."
              />
            }
          />
          <ResetLink resetLink={state.resetLink} />
        </AuthEmptyPageLayout>
      ) : (
        <AuthPageLayout>
          <ResetPasswordForm onSubmit={handleReset} />
        </AuthPageLayout>
      )}
    </>
  );
};

export default ResetPasswordPage;
