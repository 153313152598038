import { IEnvironmentVariables } from "core/global/environment.const";

export interface IBaseUrlService {
  getApiBaseUrl: () => string;
}

export class BaseUrlService implements IBaseUrlService {
  private readonly baseApiUrl: string = "";
  private readonly useCraProxy: boolean = false;

  constructor(env: IEnvironmentVariables) {
    this.baseApiUrl = env.apiBaseUrl;
    this.useCraProxy = env.useCraProxy;
  }

  public getApiBaseUrl = () => {
    if (this.useCraProxy) {
      return (
        window.location.protocol + "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
      );
    }

    return this.baseApiUrl;
  };
}

export default BaseUrlService;
