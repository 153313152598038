import React from "react";
import { Form, Input} from "antd";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";

export const OrderNoteControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    noStyle={true}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="orderNote"
      control={props.control}
      render={({ field }) => <Input.TextArea {...field} rows={5} />}
    />
  </Form.Item>
);
