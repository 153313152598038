import { Path } from "core/routes/routes";
import { FC, useMemo } from "react";
import { useLocation } from "react-router";
import HeaderContent from "../../layout/content/HeaderContent";
import { Breadcrumb, Button, PageHeader, Result } from "antd";
import Links from "../../../components/links/Links";
import { HomeOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import PageContent from "features/layout/content/PageContent";

interface ISubscriptionsCheckoutStatusPageProps {

}

enum CheckoutStatus {
  Success = 'success',
  Cancel = 'cancel',
}

const SubscriptionsCheckoutStatusPage: FC = () => {
  const location = useLocation();

  const status = useMemo(() => {
    switch (location.pathname) {
      case Path.SubscriptionsCanceled:
        return CheckoutStatus.Cancel;
      case Path.SubscriptionsSuccess:
      default:
        return CheckoutStatus.Success;
    }
  }, [location.pathname]);

  return (
    <div>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.Subscriptions />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="common.subscriptions" defaultMessage="Subskrypcje" />}
          subTitle={
            status === CheckoutStatus.Cancel ?
              <FormattedMessage id="subscriptions.cancel-header-subtitle" defaultMessage="Anulowano" />
              :
              <FormattedMessage id="subscriptions.success-header-subtitle" defaultMessage="Sukces" />
          }
        />
      </HeaderContent>
      <PageContent>
        {status === CheckoutStatus.Cancel ?
          <Result
            status="error"
            title={<FormattedMessage id="subscriptions.cancel-title" defaultMessage="Anulowano" />}
            subTitle={<FormattedMessage id="subscriptions.cancel-subtitle" defaultMessage="Operacja zamówienia subskrybcji została anulowana" />}
            extra={
              <Button key="buy" href={Path.Subscriptions}>
                <FormattedMessage id="subscriptions.back-button" defaultMessage="Wróć do subskrybcji" />
              </Button>
            }
          />
          :
          <Result
            status="success"
            title={<FormattedMessage id="subscriptions.success-title" defaultMessage="Zakończony" />}
            subTitle={<FormattedMessage id="subscriptions.success-subtitle" defaultMessage="Zamówiono subskrybcję" />}
            extra={
              <Button key="buy" href={Path.Subscriptions}>
                <FormattedMessage id="subscriptions.back-button" defaultMessage="Wróć do subskrybcji" />
              </Button>
            }
          />
        }
      </PageContent>
    </div>
  );
}

export default SubscriptionsCheckoutStatusPage;