import { assign, Machine } from "xstate";
import { IBillingStatement, IInvoiceLineItem} from "../../../models/invoicing.models";
import { ISODateTimeString } from "core/time/time.model";
import { IOrderId } from "features/orders/order.model";
import { IDentist } from "features/dentist/dentist.model";

enum EventType {
  DentistPicked = "DentistPicked",
  OrdersPicked = "OrdersPicked",
  BillingStatementCreated = "BillingStatementCreated",
  GoBack = "GoBack",
}

type Event =
  | { type: EventType.DentistPicked; payload: { recipient: IDentist; counterparties: IDentist[] } }
  | {
      type: EventType.OrdersPicked;
      payload: { finishDate: [ISODateTimeString, ISODateTimeString]; orders: IOrderId[] };
    }
  | { type: EventType.BillingStatementCreated; payload: { billingStatement: IBillingStatement } }
  | { type: EventType.GoBack };

enum State {
  PickDentist = "PickDentist",
  PickOrders = "PickOrders",
  ProvideLineItems = "ProvideLineItems",
  BillingStatementCreated = "BillingStatementCreated",
}

interface StateSchema {
  states: {
    [State.PickDentist]: {};
    [State.PickOrders]: {};
    [State.ProvideLineItems]: {};
    [State.BillingStatementCreated]: {};
  };
}

interface MachineContext {
  finishDate: [ISODateTimeString, ISODateTimeString] | null;
  dentist: IDentist | null;
  counterparties: IDentist[] | null;
  orders: IOrderId[] | null;
  lineItems: IInvoiceLineItem[] | null;
  createdBillingStatement: IBillingStatement | null;
}

const createInvoiceStateMachine = Machine<MachineContext, StateSchema, Event>({
  id: "create-billing-statement-machine",
  initial: State.PickDentist,
  context: {
    finishDate: null,
    dentist: null,
    counterparties: null,
    orders: null,
    lineItems: null,
    createdBillingStatement: null,
  },
  states: {
    [State.PickDentist]: {
      on: {
        [EventType.DentistPicked]: {
          target: State.PickOrders,
          actions: assign((context, event) => {
            console.info("event: ", { event });
            return {
              ...context,
              dentist: event.payload.recipient,
              counterparties: event.payload.counterparties,
            };
          }),
        },
      },
    },
    [State.PickOrders]: {
      on: {
        [EventType.GoBack]: {
          target: State.PickDentist,
        },
        [EventType.OrdersPicked]: {
          target: State.ProvideLineItems,
          actions: assign((context, event) => ({
            ...context,
            finishDate: event.payload.finishDate,
            orders: event.payload.orders,
          })),
        },
      },
    },
    [State.ProvideLineItems]: {
      on: {
        [EventType.GoBack]: {
          target: State.PickOrders,
        },
        [EventType.BillingStatementCreated]: {
          target: State.BillingStatementCreated,
          actions: assign((context, event) => ({
            ...context,
            createdBillingStatement: event.payload.billingStatement,
          })),
        },
      },
    },
    [State.BillingStatementCreated]: {
      type: "final",
    },
  },
});

export { State as CreateInvoiceState };
export { EventType as CreateInvoiceEvent };

export { createInvoiceStateMachine };

