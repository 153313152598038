import axios from "axios";
import { IHttpService } from "core/http/http.service";
import { IHttpRequestConfig } from "core/http/http.model";
import { IBaseUrlService } from "services/base-url.service";
import { IAuthTokenProvider } from "features/auth/auth.service";

class AxiosHttpService implements IHttpService {
  private readonly baseUrlService: IBaseUrlService;
  private readonly defaultRequestConfig: IHttpRequestConfig;
  private _authTokenProvider: IAuthTokenProvider;

  constructor(baseUrlService: IBaseUrlService, authTokenProvider: IAuthTokenProvider) {
    this.baseUrlService = baseUrlService;

    this.defaultRequestConfig = {
      baseURL: baseUrlService.getApiBaseUrl(),
    };

    this._authTokenProvider = authTokenProvider;
  }

  private _retriveAuthToken = async () => {
    try {
      return await this._authTokenProvider.getAccessToken();
    } catch (error) {
      console.error("error while retriving auth token", error);
      return null;
    }
  };

  get = async <T>(url: string, requestConfig?: IHttpRequestConfig | undefined) => {
    try {
      const authToken = await this._retriveAuthToken();

      const response = await axios.get<T>(url, {
        ...this.defaultRequestConfig,
        ...requestConfig,
        ...{
          headers: {
            Authorization: `Bearer ${authToken}`,
            ...requestConfig?.headers
          },
        },
      });

      // console.info(`%c [ GET  ] (%s) %O`, "color: blue", url, response.data);

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  post = async <T>(url: string, data?: any, requestConfig?: IHttpRequestConfig | undefined) => {
    // console.info(`%c [ POST ] (%s) %O`, "color: blue", url, data);
    try {
      const authToken = await this._retriveAuthToken();

      return await axios.post<T>(url, data, {
        ...this.defaultRequestConfig,
        ...requestConfig,
        ...{
          headers: {
            Authorization: `Bearer ${authToken}`,
            ...requestConfig?.headers
          },
        },
      });
    } catch (error) {
      console.error(`error on ${url}`, { error });
      throw error;
    }
  };

  put = async <T>(url: string, data?: any, requestConfig?: IHttpRequestConfig | undefined) => {
    // console.info(`%c [ POST ] (%s) %O`, "color: blue", url, data);
    try {
      const authToken = await this._retriveAuthToken();

      return await axios.put<T>(url, data, {
        ...this.defaultRequestConfig,
        ...requestConfig,
        ...{
          headers: {
            Authorization: `Bearer ${authToken}`,
            ...requestConfig?.headers
          },
        },
      });
    } catch (error) {
      console.error(`error on ${url}`, { error });
      throw error;
    }
  };
}

export default AxiosHttpService;
