import { IPricingFormValues, PricingFormType } from "./pricing-input.models";
import { argumentOutOfRangeError } from "core/errors/generate-error";
import { Arch } from "core/tooth/tooth.model";
import { Money } from "core/money/money.functions";
import { PointArea } from "core/point/point.enums";
import { AnchorType } from "features/orders/order-type/anchor-type.models";
import { Pricing, PricingStrategy } from "core/pricing/pricing.model";

export const mapPricingToInitialFormValues = (pricing: Pricing): IPricingFormValues => {
  switch (pricing.strategy) {
    case PricingStrategy.NotSet:
      return { formType: PricingFormType.PricingNotSet };
    case PricingStrategy.Individual:
      return { formType: PricingFormType.IndividualPricing };
    case PricingStrategy.Fixed:
      return {
        formType: PricingFormType.FixedPrice,
        fixedPrice: pricing.fixedPrice,
      };
    case PricingStrategy.PerQuantity:
      return {
        formType: PricingFormType.Quantity,
        itemPrice: pricing.pricePerItem,
      };
    case PricingStrategy.PerArch:
      const { [Arch.UpperArch]: upper, [Arch.LowerArch]: lower } = pricing.pricesPerArch;

      if (Money.isEqual(upper, lower)) {
        return {
          formType: PricingFormType.ArchEqualPrices,
          archPrice1: upper,
        };
      }

      return {
        formType: PricingFormType.UpperAndLowerArchPrices,
        archPrice1: upper,
        archPrice2: lower,
      };
    case PricingStrategy.PerPoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerPoint;

      if (Money.isEqual(front, back)) {
        return {
          formType: PricingFormType.PointEqualPrices,
          point1: front,
        };
      }

      return {
        formType: PricingFormType.FrontAndBackPointPrices,
        point2: back,
        point1: front,
      };
    }
    case PricingStrategy.PerPointWithArchBasePrice: {
      const { pricePerPoint, basePriceForArch } = pricing;

      return {
        formType: PricingFormType.PointWithBaseArchPrice,
        itemPrice: pricePerPoint,
        baseArchPrice: basePriceForArch,
      };
    }
    case PricingStrategy.PerCrown: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerCrown;

      if (
        Money.isEqual(front[AnchorType.ScrewedInToImplant], back[AnchorType.ScrewedInToImplant]) &&
        Money.isEqual(front[AnchorType.CementedToPreparedTooth], back[AnchorType.CementedToPreparedTooth]) &&
        Money.isEqual(front[AnchorType.CementedToImplant], back[AnchorType.CementedToImplant])
      ) {
        return {
          formType: PricingFormType.CrownEqualPrices,
          cementedToPreparedTooth1: front[AnchorType.CementedToPreparedTooth],
          screwedToImplant1: front[AnchorType.ScrewedInToImplant],
          cementedToImplant1: front[AnchorType.CementedToImplant],
        };
      }

      return {
        formType: PricingFormType.FrontAndBackCrownPrices,
        cementedToPreparedTooth1: front[AnchorType.CementedToPreparedTooth],
        screwedToImplant1: front[AnchorType.ScrewedInToImplant],
        cementedToImplant1: front[AnchorType.CementedToImplant],
        cementedToPreparedTooth2: back[AnchorType.CementedToPreparedTooth],
        screwedToImplant2: back[AnchorType.ScrewedInToImplant],
        cementedToImplant2: back[AnchorType.CementedToImplant],
      };
    }
    case PricingStrategy.PerBridgePoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerBridgePoint;

      if (
        Money.isEqual(front[AnchorType.ScrewedInToImplant], back[AnchorType.ScrewedInToImplant]) &&
        Money.isEqual(front[AnchorType.CementedToPreparedTooth], back[AnchorType.CementedToPreparedTooth]) &&
        Money.isEqual(front[AnchorType.CementedToImplant], back[AnchorType.CementedToImplant]) &&
        Money.isEqual(front[AnchorType.Pontic], back[AnchorType.Pontic])
      ) {
        return {
          formType: PricingFormType.BridgePointEqualPrices,
          cementedToPreparedTooth1: front[AnchorType.CementedToPreparedTooth],
          screwedToImplant1: front[AnchorType.ScrewedInToImplant],
          cementedToImplant1: front[AnchorType.CementedToImplant],
          pontic1: front[AnchorType.Pontic],
        };
      }

      return {
        formType: PricingFormType.FrontAndBackBridgePointPrices,
        cementedToPreparedTooth1: front[AnchorType.CementedToPreparedTooth],
        screwedToImplant1: front[AnchorType.ScrewedInToImplant],
        cementedToImplant1: front[AnchorType.CementedToImplant],
        pontic1: front[AnchorType.Pontic],
        cementedToPreparedTooth2: back[AnchorType.CementedToPreparedTooth],
        screwedToImplant2: back[AnchorType.ScrewedInToImplant],
        cementedToImplant2: back[AnchorType.CementedToImplant],
        pontic2: back[AnchorType.Pontic],
      };
    }
    case PricingStrategy.PerMarylandBridgePoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerMarylandBridgePoint;

      if (
        Money.isEqual(front[AnchorType.CementedToPreparedTooth], back[AnchorType.CementedToPreparedTooth]) &&
        Money.isEqual(front[AnchorType.Pontic], back[AnchorType.Pontic])
      ) {
        return {
          formType: PricingFormType.MarylandBridgePointEqualPrices,
          cementedToPreparedTooth1: front[AnchorType.CementedToPreparedTooth],
          pontic1: front[AnchorType.Pontic],
        };
      }

      return {
        formType: PricingFormType.FrontAndBackMarylandBridgePointPrices,
        cementedToPreparedTooth1: front[AnchorType.CementedToPreparedTooth],
        pontic1: front[AnchorType.Pontic],
        cementedToPreparedTooth2: back[AnchorType.CementedToPreparedTooth],
        pontic2: back[AnchorType.Pontic],
      };
    }
    case PricingStrategy.PerFoundationPoint: {
      const {
        [AnchorType.Pontic]: pontic,
        [AnchorType.ScrewedInToImplant]: screwedInToImplant,
        [AnchorType.CementedToImplant]: cementedToImplant,
      } = pricing.pricesPerFoundationPoint;

      return {
        formType: PricingFormType.FoundationPointEqualPrices,
        screwedToImplant1: screwedInToImplant,
        cementedToImplant1: cementedToImplant,
        pontic1: pontic,
      };
    }
    default:
      throw argumentOutOfRangeError(`pricing strategy is out of range`, pricing);
  }
};

export const mapFormValuesToPricing = (values: IPricingFormValues): Pricing => {
  switch (values.formType) {
    case PricingFormType.IndividualPricing: {
      return { strategy: PricingStrategy.Individual };
    }
    case PricingFormType.FixedPrice: {
      const { fixedPrice } = values;
      return { strategy: PricingStrategy.Fixed, fixedPrice: fixedPrice };
    }
    case PricingFormType.Quantity: {
      const { itemPrice } = values;
      return { strategy: PricingStrategy.PerQuantity, pricePerItem: itemPrice };
    }
    case PricingFormType.ArchEqualPrices: {
      const { archPrice1 } = values;
      return {
        strategy: PricingStrategy.PerArch,
        pricesPerArch: {
          [Arch.LowerArch]: archPrice1,
          [Arch.UpperArch]: archPrice1,
        },
      };
    }
    case PricingFormType.UpperAndLowerArchPrices: {
      const { archPrice1, archPrice2 } = values;
      return {
        strategy: PricingStrategy.PerArch,
        pricesPerArch: {
          [Arch.LowerArch]: archPrice2,
          [Arch.UpperArch]: archPrice1,
        },
      };
    }
    case PricingFormType.PointEqualPrices: {
      const { point1 } = values;
      return {
        strategy: PricingStrategy.PerPoint,
        pricesPerPoint: {
          [PointArea.Front]: point1,
          [PointArea.Back]: point1,
        },
      };
    }
    case PricingFormType.FrontAndBackPointPrices: {
      const { point1, point2 } = values;
      return {
        strategy: PricingStrategy.PerPoint,
        pricesPerPoint: {
          [PointArea.Front]: point1,
          [PointArea.Back]: point2,
        },
      };
    }
    case PricingFormType.PointWithBaseArchPrice: {
      const { baseArchPrice, itemPrice } = values;
      return {
        strategy: PricingStrategy.PerPointWithArchBasePrice,
        basePriceForArch: baseArchPrice,
        pricePerPoint: itemPrice,
      };
    }
    case PricingFormType.CrownEqualPrices: {
      const { cementedToImplant1, screwedToImplant1, cementedToPreparedTooth1 } = values;
      return {
        strategy: PricingStrategy.PerCrown,
        pricesPerCrown: {
          [PointArea.Front]: {
            [AnchorType.CementedToImplant]: cementedToImplant1,
            [AnchorType.ScrewedInToImplant]: screwedToImplant1,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
          },
          [PointArea.Back]: {
            [AnchorType.CementedToImplant]: cementedToImplant1,
            [AnchorType.ScrewedInToImplant]: screwedToImplant1,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
          },
        },
      };
    }
    case PricingFormType.FrontAndBackCrownPrices: {
      const {
        cementedToImplant1,
        screwedToImplant1,
        cementedToPreparedTooth1,
        cementedToImplant2,
        screwedToImplant2,
        cementedToPreparedTooth2,
      } = values;

      return {
        strategy: PricingStrategy.PerCrown,
        pricesPerCrown: {
          [PointArea.Front]: {
            [AnchorType.CementedToImplant]: cementedToImplant1,
            [AnchorType.ScrewedInToImplant]: screwedToImplant1,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
          },
          [PointArea.Back]: {
            [AnchorType.CementedToImplant]: cementedToImplant2,
            [AnchorType.ScrewedInToImplant]: screwedToImplant2,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth2,
          },
        },
      };
    }
    case PricingFormType.BridgePointEqualPrices: {
      const { cementedToImplant1, screwedToImplant1, cementedToPreparedTooth1, pontic1 } = values;

      return {
        strategy: PricingStrategy.PerBridgePoint,
        pricesPerBridgePoint: {
          [PointArea.Front]: {
            [AnchorType.CementedToImplant]: cementedToImplant1,
            [AnchorType.ScrewedInToImplant]: screwedToImplant1,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
            [AnchorType.Pontic]: pontic1,
          },
          [PointArea.Back]: {
            [AnchorType.CementedToImplant]: cementedToImplant1,
            [AnchorType.ScrewedInToImplant]: screwedToImplant1,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
            [AnchorType.Pontic]: pontic1,
          },
        },
      };
    }
    case PricingFormType.FrontAndBackBridgePointPrices: {
      const {
        cementedToImplant1,
        screwedToImplant1,
        cementedToPreparedTooth1,
        pontic1,
        cementedToImplant2,
        screwedToImplant2,
        cementedToPreparedTooth2,
        pontic2,
      } = values;

      return {
        strategy: PricingStrategy.PerBridgePoint,
        pricesPerBridgePoint: {
          [PointArea.Front]: {
            [AnchorType.CementedToImplant]: cementedToImplant1,
            [AnchorType.ScrewedInToImplant]: screwedToImplant1,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
            [AnchorType.Pontic]: pontic1,
          },
          [PointArea.Back]: {
            [AnchorType.CementedToImplant]: cementedToImplant2,
            [AnchorType.ScrewedInToImplant]: screwedToImplant2,
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth2,
            [AnchorType.Pontic]: pontic2,
          },
        },
      };
    }
    case PricingFormType.MarylandBridgePointEqualPrices: {
      const { cementedToPreparedTooth1, pontic1 } = values;

      return {
        strategy: PricingStrategy.PerMarylandBridgePoint,
        pricesPerMarylandBridgePoint: {
          [PointArea.Front]: {
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
            [AnchorType.Pontic]: pontic1,
          },
          [PointArea.Back]: {
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
            [AnchorType.Pontic]: pontic1,
          },
        },
      };
    }
    case PricingFormType.FrontAndBackMarylandBridgePointPrices: {
      const { cementedToPreparedTooth1, cementedToPreparedTooth2, pontic1, pontic2 } = values;

      return {
        strategy: PricingStrategy.PerMarylandBridgePoint,
        pricesPerMarylandBridgePoint: {
          [PointArea.Front]: {
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth1,
            [AnchorType.Pontic]: pontic1,
          },
          [PointArea.Back]: {
            [AnchorType.CementedToPreparedTooth]: cementedToPreparedTooth2,
            [AnchorType.Pontic]: pontic2,
          },
        },
      };
    }
    case PricingFormType.FoundationPointEqualPrices: {
      const { cementedToImplant1, screwedToImplant1, pontic1 } = values;

      return {
        strategy: PricingStrategy.PerFoundationPoint,
        pricesPerFoundationPoint: {
          [AnchorType.CementedToImplant]: cementedToImplant1,
          [AnchorType.ScrewedInToImplant]: screwedToImplant1,
          [AnchorType.Pontic]: pontic1,
        },
      };
    }

    default:
      throw argumentOutOfRangeError("pricing form unhandled", "pricingForm");
  }
};
