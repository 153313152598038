import * as React from "react";
import { useState } from "react";
import classNames from "classnames";
import { useMeasure } from "react-use";
import { mapWidthToSize } from "core/utils/resize-utils";
import { DotId, PointId } from "core/point/point.enums";
import { PartialRecord } from "core/utils/type";
import UpperJawView from "./jaws/UpperJawView";
import LowerJawView from "./jaws/LowerJawView";
import GraphicalAnchorPicker from "./graphical-anchor-picker/GraphicalAnchorPicker";
import { AnchorType } from "../../../order-type/anchor-type.models";
import { JawViewProps } from "./jaws/jaw-view.model";
import { defaultAnchorOptions, defaultDotOptions, defaultLabelOptions, defaultPointOptions } from "./jaws/jaws.consts";

import "./points-view.scss";

export type PointsViewPoints = PartialRecord<PointId, { selected: boolean }>;
export type PointsViewLabels = PartialRecord<PointId, string[]>;
export type PointsViewDots = PartialRecord<DotId, { selected: boolean; visible: boolean }>;
export type PointsViewAnchors = PartialRecord<PointId, AnchorType>;

export interface IPointsViewProps extends JawViewProps {
  readonly?: boolean;
  onPointAnchored?: (point: PointId, anchor: AnchorType) => void;
}

enum Size {
  Small = "sm",
  Large = "lg",
}

const toSize = mapWidthToSize({
  [Size.Small]: 0,
  [Size.Large]: 820,
});

const PointsView: React.FunctionComponent<IPointsViewProps> = ({
  readonly = false,
  points,
  dots = {},
  labels = {},
  anchors = {},
  pointOptions = defaultPointOptions,
  dotOptions = defaultDotOptions,
  anchorOptions = defaultAnchorOptions,
  labelOptions = defaultLabelOptions,
  onDotSelected,
  onPointSelected,
  onPointAnchored,
}) => {
  const [ref, { width }] = useMeasure();
  const [selectedAnchorType, setSelectedAnchorType] = useState<AnchorType | null>(null);
  const size = toSize(width);

  const handleAnchorChanged = React.useCallback(
    (e) => {
      setSelectedAnchorType(e.target.value);
    },
    [setSelectedAnchorType]
  );

  const handlePointSelected = React.useCallback(
    (point: PointId) => {
      if (anchorOptions.editable !== true) {
        onPointSelected?.(point);
        return;
      }

      if (selectedAnchorType === null) {
        return;
      }

      onPointAnchored?.(point, selectedAnchorType);
    },
    [anchorOptions.editable, selectedAnchorType, onPointAnchored, onPointSelected]
  );

  const handleDotSelected = React.useCallback(
    (dot: DotId) => {
      if (dotOptions.editable !== true) {
        return;
      }
      onDotSelected?.(dot);
    },
    [dotOptions.editable, onDotSelected]
  );

  return (
    <div className={"w-full select-none"} ref={ref as any}>
      {anchorOptions.editable === true && (
        <div className={"points-view__anchor-selector"}>
          <GraphicalAnchorPicker
            value={selectedAnchorType}
            onChange={handleAnchorChanged}
            anchorTypes={anchorOptions.allowedAnchors}
          />
        </div>
      )}
      <div
        className={classNames("points-view w-full select-none", {
          "points-view--size-small": size === Size.Small && readonly === false,
          "points-view--size-large": size === Size.Large && readonly === false,
          "points-view--readonly-small": width < 500 && readonly === true,
          "points-view--readonly-large": width >= 500 && readonly === true,
        })}
        ref={ref as any}
      >
        <div className={"points-view__outer-jaw-container"}>
          <div className={"points-view__inner-jaw-container"}>
            <UpperJawView
              points={points}
              dots={dots}
              anchors={anchors}
              labels={labels}
              pointOptions={pointOptions}
              anchorOptions={anchorOptions}
              dotOptions={dotOptions}
              labelOptions={labelOptions}
              onPointSelected={handlePointSelected}
              onDotSelected={handleDotSelected}
            />
            <LowerJawView
              points={points}
              dots={dots}
              anchors={anchors}
              labels={labels}
              pointOptions={pointOptions}
              anchorOptions={anchorOptions}
              dotOptions={dotOptions}
              labelOptions={labelOptions}
              onPointSelected={handlePointSelected}
              onDotSelected={handleDotSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointsView;
