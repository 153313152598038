import * as React from "react";
import { useMemo, useState } from "react";
import {
  LocatedPointsSpecification,
  PartialDenturesSpecification,
  SpecificationType,
  SurgicalGuidesSpecification,
} from "../../../specification/order-specification";
import useBooleanFlag from "../../../../../hooks/useBooleanFlag";
import PointSelection from "../../points-picker/selections/points-selection/PointSelection";
import { useCounter } from "react-use";
import { IPickedPoints } from "../../points-picker/points-picker.model";
import { OpenModalButton } from "./OpenModalButton";
import { SpecificationModal } from "./SpecificationModal";
import { SelectAtLeastOnePoint } from "./alerts/SelectAtLeastOnePoint";
import { pointToArchMap } from "core/point/point.enums";
import { Arch } from "core/tooth/tooth.model";
import { SelectPointsFromSameArch } from "features/orders/components/order-entry/specification-providers/alerts/SelectPointsFromSameArch";

interface IOrderEntrySpecificationProps<
  T extends LocatedPointsSpecification | PartialDenturesSpecification | SurgicalGuidesSpecification
> {
  specification: T;
  onChange?: (specification: T) => void;
}

const LocatedPointsSpecificationControls: React.FunctionComponent<
  IOrderEntrySpecificationProps<LocatedPointsSpecification | PartialDenturesSpecification | SurgicalGuidesSpecification>
> = ({ specification, onChange }) => {
  const [submitCount, { inc: incrementSubmitCount }] = useCounter(0);
  const { flag: isModalVisible, uncheck: hideModal, check: showModal } = useBooleanFlag(false);

  const [state, setState] = useState<IPickedPoints>({
    singles: specification.points.map(({ location }) => ({ location, anchor: null })),
    linked: [],
  });

  const noPointsSelected = useMemo(() => state.singles.length === 0, [state.singles.length]);

  const pointsBelongToDifferentArches = useMemo(() => {
    const arches = state.singles.map((curr) => pointToArchMap[curr.location]);
    return Array.from(new Set<Arch>(arches)).length >= 2;
  }, [state.singles]);

  const pointsShouldBelongToTheSameArch = specification.specificationType === SpecificationType.PartialDentures
    || specification.specificationType === SpecificationType.SurgicalGuides;

  const isNextDisabled =
    submitCount > 0 &&
    (noPointsSelected || (pointsShouldBelongToTheSameArch && pointsBelongToDifferentArches));

  const handleNextClick = React.useCallback(() => {
    incrementSubmitCount();

    if (noPointsSelected) {
      return;
    }

    if (pointsShouldBelongToTheSameArch && pointsBelongToDifferentArches) {
      return;
    }

    hideModal();

    onChange?.({
      specificationType: specification.specificationType,
      points: state.singles.map(({ location }) => ({ location })),
    });
  }, [
    incrementSubmitCount,
    noPointsSelected,
    pointsShouldBelongToTheSameArch,
    pointsBelongToDifferentArches,
    hideModal,
    onChange,
    specification.specificationType,
    state.singles
  ]);

  return (
    <>
      <OpenModalButton specification={specification} onClick={showModal} />
      <SpecificationModal
        specification={specification}
        isVisible={isModalVisible}
        isBackDisabled={true}
        isNextDisabled={isNextDisabled}
        onCancel={hideModal}
        onBack={hideModal}
        onNext={handleNextClick}
      >
        <>
          {submitCount > 0 && noPointsSelected && (
            <SelectAtLeastOnePoint specificationType={SpecificationType.LocatedPoints} />
          )}
          {submitCount > 0 && pointsShouldBelongToTheSameArch && pointsBelongToDifferentArches && <SelectPointsFromSameArch />}
          <PointSelection value={state} onChange={setState} />
        </>
      </SpecificationModal>
    </>
  );
};

export default LocatedPointsSpecificationControls;
