import React from "react";
import SettingsServiceContext from "../service/settings.service-context";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import useSWR from "swr";
import { IInvoicingSettings } from "../models/settings.models";
import { deepCopy } from "core/utils/object";

function useSettings() {
  const settingsService = React.useContext(SettingsServiceContext);

  const {
    data: getInvoicingSettings,
    isValidating: isLoading,
    mutate: revalidate,
  } = useSWR(ApiEndpointPath.GetInvoicingSettings, async () => {
    return settingsService.getInvoicingSettings();
  });

  const updateSettings = React.useCallback(
    async (patch: Partial<IInvoicingSettings>) => {
      if (getInvoicingSettings === undefined) {
        return;
      }

      if (!getInvoicingSettings.isOk()) {
        return;
      }

      const prev = getInvoicingSettings.unwrap();
      const updateObj = deepCopy(prev);

      if (patch.invoiceIssuerDataSets !== undefined) {
        updateObj.invoiceIssuerDataSets = patch.invoiceIssuerDataSets!;
      }

      if (patch.invoiceNumberingScheme !== undefined) {
        updateObj.invoiceNumberingScheme = patch.invoiceNumberingScheme!;
      }

      if (patch.paymentDueDate !== undefined) {
        updateObj.paymentDueDate = patch.paymentDueDate!;
      }

      const result = await settingsService.updateInvoiceSettings(updateObj);

      if (result.isErr()) {
        console.warn("error while updating invoicing settings");
        return;
      }

      await revalidate(result, false);
    },
    [getInvoicingSettings, settingsService, revalidate]
  );

  return {
    getInvoicingSettings: getInvoicingSettings,
    updateSettings,
    isLoading: isLoading,
    refresh: revalidate,
  };
}

export default useSettings;
