import * as React from "react";
import { invalidArgumentError } from "core/errors/generate-error";
import { IPersonName } from "core/person-name/person-name";
import { Honorific } from "core/person-name/honorific";

export enum PersonNameFormat {
  Short = 1,
  Formal = 2,
}

interface IPersonNameFormatter {
  personName: IPersonName;
  format?: PersonNameFormat;
  useDrIfAvailable?: boolean;
  children?: (formattedName: string) => string;
}

export const getFormattedNameAsString = (
  props: Pick<IPersonNameFormatter, "format" | "personName" | "useDrIfAvailable">
) => {
  const { useDrIfAvailable, format, personName } = props;
  const { firstName, lastName, honorific } = personName;

  if (useDrIfAvailable && honorific === Honorific.Dr) {
    return `Dr ${firstName} ${lastName}`;
  } else if (format === PersonNameFormat.Formal) {
    switch (honorific) {
      case Honorific.Dr:
        return `Dr ${firstName} ${lastName}`;
      case Honorific.Mr:
        return `Pan ${firstName} ${lastName}`;
      case Honorific.Ms:
        return `Pani ${firstName} ${lastName}`;
      case Honorific.None:
        return `${firstName} ${lastName}`;
      default:
        return `${firstName} ${lastName}`;
    }
  } else if (format === PersonNameFormat.Short) {
    return `${firstName} ${lastName}`;
  } else {
    return `${firstName} ${lastName}`;
  }
};

const PersonNameFormatter: React.FunctionComponent<IPersonNameFormatter> = ({
  personName,
  format = PersonNameFormat.Short,
  useDrIfAvailable = true,
  children,
}) => {
  if (format !== PersonNameFormat.Short && format !== PersonNameFormat.Formal) {
    throw invalidArgumentError("PersonName format parameter is invalid");
  }

  const formattedName = getFormattedNameAsString({
    personName,
    format,
    useDrIfAvailable,
  });

  if (typeof children === "function") {
    return <React.Fragment>{children(formattedName)}</React.Fragment>;
  } else {
    return <React.Fragment>{formattedName}</React.Fragment>;
  }
};

export default PersonNameFormatter;
