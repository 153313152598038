import { Point } from "../../../specification/point-specification";
import { PointId } from "core/point/point.enums";
import { isArrayOfLocatedPoints, isArrayOfPointIds } from "core/point/point.guard";
import { PointsSortOrder, sortPoints } from "core/point/point.sort";
import { areNeighbouring } from "core/point/point.functions";
import { IPointSequence } from "./point-sequence.model";

const appendPointToSequence = (sequence: IPointSequence, point: PointId): IPointSequence => {
  if (sequence.end === point) {
    return {
      start: sequence.start,
      end: point,
      duplicated: [...sequence.duplicated, point],
      count: sequence.count + 1,
    };
  }

  return {
    start: sequence.start,
    end: point,
    duplicated: sequence.duplicated,
    count: sequence.count + 1,
  };
};

export const mapPointsToSequences = (points: Point[] | PointId[]): IPointSequence[] => {
  let normalizedPoints: PointId[];

  if (isArrayOfPointIds(points)) {
    normalizedPoints = points;
  } else if (isArrayOfLocatedPoints(points)) {
    normalizedPoints = points.map((p) => p.location);
  } else {
    normalizedPoints = points;
  }

  const sortedPoints = normalizedPoints.sort((p1, p2) => sortPoints(PointsSortOrder.Reading)(p1, p2));

  const allSequences = sortedPoints.reduce((sequences, currPoint, idx, all) => {
    if (idx === 0) {
      sequences.push({
        start: currPoint,
        end: currPoint,
        count: 1,
        duplicated: [],
      });

      return sequences;
    }

    const prevPoint = all[idx - 1];
    const currSeqIdx = sequences.length - 1;

    if (areNeighbouring(currPoint, prevPoint) || currPoint === prevPoint) {
      sequences[currSeqIdx] = appendPointToSequence(sequences[currSeqIdx], currPoint);
      return sequences;
    } else {
      sequences.push({
        start: currPoint,
        end: currPoint,
        count: 1,
        duplicated: [],
      });

      return sequences;
    }
  }, [] as IPointSequence[]);

  return allSequences;
};
