import * as React from "react";
import { Reducer, useCallback, useReducer, useRef, useState } from "react";
import { Badge, Spin, Tooltip } from "antd";
import { ActionType, EditableProTable, ProColumns } from "@ant-design/pro-table";
import { FormattedMessage, useIntl } from "react-intl";
import { isNumber } from "core/utils/utils";
import { ClearOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  CategoryCode,
  IArchiveOrderType,
  ICreateOrderCategory,
  ICreateOrderType,
  IOrderCatalogue,
  IOrderCategoryId,
} from "features/catalogue/category.model";
import LocalizedActionButton from "i18n/translations/3rd-party/editable-table/action-buttons-hack";
import CategoryName from "components/formatters/category/CategoryName";
import OrderCodeName from "components/formatters/order-code-name/OrderCodeName";
import {
  ActiveStatus,
  CatalogueAddNewButtonNode,
  CategoryNode,
  OrderTypeNode,
  OrderCatalogueNode,
} from "features/catalogue/order-catalogue-nodes.models";
import { SpecificationType } from "features/orders/specification/order-specification";
import { NodeType as NodeType } from "features/catalogue/nodeType";
import CreateNewOrderTypeButton from "features/catalogue/components/create-new-order-type-button.tsx/CreateNewOrderTypeButton";
import OrderTypeModalForm from "features/catalogue/components/order-type-modal-form/OrderTypeModalForm";
import useOrderCatalogue from "features/catalogue/hooks/useOrderCatalogue";
import ErrorScreen from "components/errors/ErrorScreen";
import SpecificationFormatter from "components/formatters/specification/SpecificationFormatter";
import { CreateButton } from "components/common-components/CommonButtons";
import OrderCategoryModalForm from "features/catalogue/components/order-category-modal-form/OrderCategoryModalForm";
import useConfirmations from "components/confirmations/useConfirmations";

interface IProvideOrderCatalogueTableProps {}

type State =
  | {
      isModalVisible: true;
      modalType: "create-order-type";
      payload: {
        categoryId?: IOrderCategoryId;
        specificationType?: SpecificationType;
      };
    }
  | {
      isModalVisible: true;
      modalType: "create-order-category";
    }
  | {
      isModalVisible: false;
    };

type Action =
  | {
      type: "showCreateOrderTypeModal";
      payload: {
        categoryId?: IOrderCategoryId;
        specificationType?: SpecificationType;
      };
    }
  | {
      type: "showCreateOrderCategoryModal";
    }
  | { type: "hideModal" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showCreateOrderTypeModal":
      return { ...state, isModalVisible: true, modalType: "create-order-type", payload: action.payload };
    case "showCreateOrderCategoryModal":
      return { ...state, isModalVisible: true, modalType: "create-order-category" };
    case "hideModal":
      return { ...state, isModalVisible: false };
    default:
      return state;
  }
};

const mapCategoryCodeToProbableSpecificationType = (
  categoryCode: CategoryCode | undefined | null
): SpecificationType | undefined => {
  if (categoryCode === undefined || categoryCode === null) {
    return undefined;
  }

  switch (categoryCode) {
    case CategoryCode.Crowns: {
      return SpecificationType.Crowns;
    }
    case CategoryCode.Bridges: {
      return SpecificationType.Bridges;
    }
    case CategoryCode.MarylandBridges: {
      return SpecificationType.MarylandBridges;
    }
    case CategoryCode.Foundations: {
      return SpecificationType.Foundations;
    }
    case CategoryCode.FullDentures: {
      return SpecificationType.Arches;
    }
    case CategoryCode.PartialDentures: {
      return SpecificationType.PartialDentures;
    }
    case CategoryCode.Splints:
    case CategoryCode.OrientationModels:
    case CategoryCode.Services:
    case CategoryCode.MillingServices:
    case CategoryCode.Other: {
      return SpecificationType.Quantity;
    }
    case CategoryCode.Veneers:
    case CategoryCode.ImplantAbutments:
    case CategoryCode.Onlays:
    case CategoryCode.Inlays:
    case CategoryCode.Overlays:
    case CategoryCode.EndoCrown:
    case CategoryCode.WaxUps:
    case CategoryCode.Temporary:
    case CategoryCode.Post:
    case CategoryCode.Gingiva:
      return SpecificationType.LocatedPoints;
  }
    return undefined;
};

const OrderCatalogueTable: React.FunctionComponent<IProvideOrderCatalogueTableProps> = ({ ...props }) => {
  const intl = useIntl();
  const tableRef = useRef<ActionType>();
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, { isModalVisible: false });

  const { archiveOrderTypeConfirmation } = useConfirmations();

  const {
    getOrderCatalogue,
    createOrderType,
    archiveOrderType,
    editOrderType,
    createOrderCategory,
    editOrderCategory,
    mutate,
  } = useOrderCatalogue();

  const handleCreateOrderType = useCallback(
    async (request: ICreateOrderType) => {
      const result = await createOrderType(request);

      if (result.isErr()) {
        return;
      }

      await mutate();
    },
    [createOrderType, mutate]
  );

  const handleCreateOrderCategory = useCallback(
    async (request: ICreateOrderCategory) => {
      const result = await createOrderCategory(request);

      if (result.isErr()) {
        return;
      }

      await mutate();
    },
    [createOrderCategory, mutate]
  );

  const handleArchiveOrderType = useCallback(
    async (request: IArchiveOrderType) => {
      const result = await archiveOrderType(request);

      if (result.isErr()) {
        return;
      }

      await mutate();
    },
    [archiveOrderType, mutate]
  );

  const mapOrderCatalogueToOrderCatalogueNodes = React.useCallback(
    (orderCatalogue: IOrderCatalogue): OrderCatalogueNode[] => {
      return orderCatalogue.categories.map((category) => {
        const orderTypeNodes = category.orderTypes.map((orderType) => {
          const orderTypeNode: OrderTypeNode = {
            id: orderType.id,
            name: orderType.name,
            type: NodeType.OrderType,
            code: orderType.code,
            requiredSpecification: orderType.requiredSpecification,
            active: orderType.isActive ? ActiveStatus.Active : ActiveStatus.NotActive,
          };

          return orderTypeNode;
        });

        const addNew: CatalogueAddNewButtonNode = {
          id: `${category.id.value}_add_new`,
          categoryCode: category.code,
          categoryId: category.id,
          name: "dodaj",
          type: NodeType.AddNew,
        };

        const categoryNode: CategoryNode = {
          id: category.id,
          name: category.name,
          code: category.code,
          type: NodeType.Category,
          children: [...orderTypeNodes, addNew],
        };

        return categoryNode;
      });
    },
    []
  );

  const handleSaveRecord = useCallback(
    async (record: OrderCatalogueNode) => {
      if (record.type === NodeType.AddNew) {
        return;
      }

      if (record.type === NodeType.OrderType) {
        const result = await editOrderType({
          id: record.id,
          isActive: record.active === ActiveStatus.Active,
        });

        if (result.isErr()) {
          return;
        }
      } else if (record.type === NodeType.Category) {
        const result = await editOrderCategory({
          id: record.id,
          name: record.name,
        });

        if (result.isErr()) {
          return;
        }
      }

      await mutate();
    },
    [editOrderCategory, editOrderType, mutate]
  );

  if (getOrderCatalogue === undefined) {
    return <Spin />;
  } else if (getOrderCatalogue.isErr()) {
    return <ErrorScreen error={getOrderCatalogue.err().unwrap()} />;
  }

  const columns: ProColumns<OrderCatalogueNode>[] = [
    {
      key: "name",
      title: <FormattedMessage id={"order-catalogue-table.order-name"} defaultMessage={"Nazwa zamówienia"} />,
      dataIndex: "name",
      formItemProps: (form, { rowIndex }) => ({
        rules:
          rowIndex > 2
            ? [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "catalogue.name-required",
                    defaultMessage: "Nazwa jest wymagana",
                  }),
                },
              ]
            : [],
      }),
      editable: (text, record) => {
        if (record.type === NodeType.OrderType) {
          return false;
        }

        if (record.type === NodeType.Category && record.code !== null) {
          return false;
        }

        return true;
      },
      render: (dom, record) => {
        if (record.type === NodeType.Category && isNumber(record.code)) {
          return <CategoryName code={record.code} />;
        }

        if (record.type === NodeType.OrderType && isNumber(record.code)) {
          return <OrderCodeName code={record.code} />;
        }

        if (record.type === NodeType.AddNew) {
          const specificationType: SpecificationType | undefined = mapCategoryCodeToProbableSpecificationType(
            record.categoryCode
          );

          return (
            <CreateNewOrderTypeButton
              icon={<PlusOutlined className={"mr-2"} />}
              categoryCode={record.categoryCode}
              onClick={() =>
                dispatch({
                  type: "showCreateOrderTypeModal",
                  payload: {
                    categoryId: record.categoryId,
                    specificationType,
                  },
                })
              }
            />
          );
        }

        return record.name;
      },
    },
    {
      key: "active",
      title: <FormattedMessage id="catalogue.active" defaultMessage="Możliwe do zamówienia" />,
      dataIndex: "active",
      editable: (text, record) => {
        if (record.type === NodeType.Category) {
          return false;
        }

        return true;
      },
      valueEnum: (record) => {
        if (record.type === NodeType.Category || record.type === NodeType.AddNew) {
          return (<></>) as any;
        }

        return {
          [ActiveStatus.Active]: {
            text: <FormattedMessage id="catalogue.active" defaultMessage="Możliwe do zamówienia" />,
            status: "Success",
          },
          [ActiveStatus.NotActive]: {
            text: <FormattedMessage id="catalogue.not-active" defaultMessage="Brak możliwości zamówienia" />,
            status: "error",
          },
        };
      },
      render: (_, record) => {
        if (record.type === NodeType.Category || record.type === NodeType.AddNew) {
          return <></>;
        }

        if (record.active === ActiveStatus.Active) {
          return (
            <Badge
              status="success"
              text={<FormattedMessage id="catalogue.active" defaultMessage="Możliwe do zamówienia" />}
            />
          );
        } else {
          return (
            <Badge
              status="error"
              text={<FormattedMessage id="catalogue.not-active" defaultMessage="Brak możliwości zamówienia" />}
            />
          );
        }
      },
    },
    {
      key: "requiredSpecification",
      dataIndex: "requiredSpecification",
      title: (
        <>
          <FormattedMessage id="catalogue.required-specification" defaultMessage="Typ zamówienia" />
          &nbsp;
          <Tooltip
            title={
              <FormattedMessage
                id="common.required-specification-for-order-type"
                defaultMessage="Typ zamówienia, określa jakie informacje należy podać podczas zamawiania pracy"
              />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      editable: (text, record) => {
        return false;
      },
      valueEnum: (record) => {
        if (record.type === NodeType.Category || record.type === NodeType.AddNew) {
          return (<></>) as any;
        }

        return {
          // [SpecificationType.TextOnly]: {
          //   text: (
          //     <FormattedMessage id="catalogue.required-specification.text-only" defaultMessage="Tylko opis tekstowy" />
          //   ),
          // },
          [SpecificationType.Quantity]: {
            text: <FormattedMessage id="catalogue.required-specification.quantity" defaultMessage="Ilość" />,
          },
          [SpecificationType.Arches]: {
            text: <FormattedMessage id="catalogue.required-specification.arches" defaultMessage="Łuki" />,
          },
          [SpecificationType.LocatedPoints]: {
            text: (
              <FormattedMessage
                id="catalogue.required-specification.located-points"
                defaultMessage="Lokalizacja punktów"
              />
            ),
          },
          [SpecificationType.PartialDentures]: {
            text: (
              <FormattedMessage
                id="catalogue.required-specification.partial-dentures"
                defaultMessage="Protezy częściowe"
              />
            ),
          },
          [SpecificationType.SurgicalGuides]: {
            text: (
              <FormattedMessage
                id="catalogue.required-specification.surgical-guides"
                defaultMessage="Szablony chirurgiczne"
              />
            ),
          },
          [SpecificationType.Crowns]: {
            text: <FormattedMessage id="catalogue.required-specification.crowns" defaultMessage="Korony" />,
          },
          [SpecificationType.Bridges]: {
            text: <FormattedMessage id="catalogue.required-specification.bridges" defaultMessage="Mosty" />,
          },
          [SpecificationType.MarylandBridges]: {
            text: (
              <FormattedMessage
                id="catalogue.required-specification.maryland-bridges"
                defaultMessage="Mosty maryland"
              />
            ),
          },
          [SpecificationType.Foundations]: {
            text: <FormattedMessage id="catalogue.required-specification.foundations" defaultMessage="Podbudowy" />,
          },
        };
      },
      render: (_, record) => {
        if (record.type === NodeType.Category || record.type === NodeType.AddNew) {
          return <></>;
        }

        return <SpecificationFormatter specification={record.requiredSpecification} withExplanation={true} />;
      },
    },
    {
      title: <FormattedMessage id="common.option" defaultMessage="Opcje" />,
      valueType: "option",
      width: 160,
      render: (text, record, _, action) => {
        if (record.type === NodeType.AddNew) {
          return (<></>) as any;
        }

        if (record.type === NodeType.Category) {
          if (record.code !== null) {
            return (<></>) as any;
          } else {
            return [
              <a key="editable" onClick={() => action?.startEditable?.(record.id.value)}>
                <FormattedMessage id="common.edit" defaultMessage="Edycja" />
              </a>,
            ];
          }
        }

        if (record.code !== null) {
          return [
            <a key="editable" onClick={() => action?.startEditable?.(record.id.value)}>
              <FormattedMessage id="common.edit" defaultMessage="Edycja" />
            </a>,
          ];
        }

        return [
          <a key="editable" onClick={() => action?.startEditable?.(record.id.value)}>
            <FormattedMessage id="common.edit" defaultMessage="Edycja" />
          </a>,
          <a
            key="archive"
            onClick={() => {
              archiveOrderTypeConfirmation(record.name, () =>
                handleArchiveOrderType({ id: record.id, isArchived: true })
              );
            }}
          >
            <FormattedMessage id="common.ar" defaultMessage="Archiwizuj" />
            <ClearOutlined className={"ml-2"} />
          </a>,
        ];
      },
    },
  ];

  return (
    <>
      {getOrderCatalogue
        .map((orderCatalogue) => (
          <>
            {state.isModalVisible && state.modalType === "create-order-type" && (
              <OrderTypeModalForm
                visible={true}
                values={{
                  categoryId: state.payload.categoryId?.value,
                  specificationType: state.payload.specificationType,
                }}
                categories={orderCatalogue.categories}
                onCancel={() => dispatch({ type: "hideModal" })}
                onSubmit={(data) =>
                  handleCreateOrderType({
                    categoryId: data.categoryId,
                    name: data.name,
                    isActive: true,
                    requiredSpecification: data.specificationType,
                  })
                }
              />
            )}
            {state.isModalVisible && state.modalType === "create-order-category" && (
              <OrderCategoryModalForm
                visible={true}
                onCancel={() => dispatch({ type: "hideModal" })}
                onSubmit={async (data) =>
                  handleCreateOrderCategory({
                    name: data.name,
                  })
                }
              />
            )}
            <EditableProTable<OrderCatalogueNode>
              actionRef={tableRef}
              rowKey={(node) => (node.type === NodeType.AddNew ? node.id : node.id.value)}
              headerTitle={<FormattedMessage id="catalogue.catalogue-table-name" defaultMessage="Katalog zamówień" />}
              style={{ padding: 0 }}
              recordCreatorProps={false}
              toolBarRender={() => [
                <CreateButton
                  key={"create-category"}
                  onClick={() =>
                    dispatch({
                      type: "showCreateOrderCategoryModal",
                    })
                  }
                >
                  <FormattedMessage id="catalogue.create-category" defaultMessage="Dodaj kategorię" />
                </CreateButton>,
                <CreateButton
                  key={"create-order-type"}
                  onClick={() => dispatch({ type: "showCreateOrderTypeModal", payload: {} })}
                >
                  <FormattedMessage id="catalogue.create-order-type" defaultMessage="Dodaj typ zamówienia" />
                </CreateButton>,
              ]}
              columns={columns}
              expandable={{ expandRowByClick: true }}
              value={mapOrderCatalogueToOrderCatalogueNodes(orderCatalogue)}
              editable={{
                type: "multiple",
                editableKeys,
                actionRender: (_row, _config, defaultDOMs) => [
                  <LocalizedActionButton key={"save"} originalButton={defaultDOMs.save}>
                    <FormattedMessage id="common.save" defaultMessage="Zapisz" />
                  </LocalizedActionButton>,
                  <LocalizedActionButton key={"cancel"} originalButton={defaultDOMs.cancel}>
                    <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />
                  </LocalizedActionButton>,
                ],
                onSave: async (_key, record) => await handleSaveRecord(record),
                onChange: setEditableRowKeys,
              }}
            />
          </>
        ))
        .unwrap()}
    </>
  );
};

export default OrderCatalogueTable;
