import { ErrorCode } from "./error-code";

export interface IDomainError {
  readonly code: ErrorCode;
  readonly message: string;
  readonly errorParameters?: { [key: string]: string };
  readonly errorDetails?: string | null;
}

class DomainError implements IDomainError {
  public readonly code: ErrorCode;
  public readonly message: string;
  public readonly errorParameters: { [key: string]: string };
  public readonly errorDetails: string | null; // always null in production

  constructor(
    errorCode: ErrorCode,
    message: string,
    errorParameters?: { [key: string]: string } | null,
    errorDetails?: string | null // always null in production
  ) {
    this.code = errorCode;
    this.message = message;
    this.errorParameters = errorParameters !== undefined && errorParameters !== null ? errorParameters : {};
    this.errorDetails = errorDetails !== undefined && errorDetails !== null ? errorDetails : null;
  }
}

export default DomainError;
