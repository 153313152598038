import { IntlShape } from "react-intl";
import { Status } from "features/orders/status/order-status.model";

export const statusNameFormatter = (intl: IntlShape, status: Status) => {
  switch (status) {
    case Status.ReadyForPickUpFromClinic:
      return intl.formatMessage({ id: "status-name.blocked", defaultMessage: "Do odbioru" });
    case Status.TodoInLab:
      return intl.formatMessage({ id: "status-name.todo", defaultMessage: "Do zrobienia" });
    case Status.InProgressInLab:
      return intl.formatMessage({ id: "status-name.in-progress-lab", defaultMessage: "W trakcie - laboratorium" });
    case Status.InProgressInClinic:
      return intl.formatMessage({ id: "status-name.in-progress-clinic", defaultMessage: "W trakcie - klinika" });
    case Status.Finished:
      return intl.formatMessage({ id: "status-name.finished", defaultMessage: "Zamówienie ukończone" });
  }
};
