import React from "react";
import useSWR from "swr";
import { nameof } from "ts-simple-nameof";
import ChatApiContext from "features/chat/services/ChatApiContext";
import { IChatApi } from "features/chat/services/chat.service";
import { IOrderId } from "features/orders/order.model";

function useOrderChat(orderId: IOrderId) {
  const chatApi = React.useContext(ChatApiContext);

  const { data: getOrderChannel, mutate } = useSWR(
    [nameof<IChatApi>((_) => _.getOrderChannel), orderId.value],
    async () => chatApi.getOrderChannel(orderId)
  );

  const createOrderChannel = React.useCallback(
    async (request: IOrderId) => await chatApi.createOrderChannel(request),
    [chatApi]
  );

  const joinOrderChannel = React.useCallback(
    async (request: IOrderId) => await chatApi.joinOrderChannel(request),
    [chatApi]
  );

  return {
    getOrderChannel: getOrderChannel,
    refresh: mutate,
    mutate,
    createOrderChannel,
    joinOrderChannel,
  };
}

export default useOrderChat;
