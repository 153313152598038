
const isNumeric = (x: number | string | null | undefined) => (typeof x === "number" || typeof x === "string") && !isNaN(Number(x));
const stringIsNotNumeric = (value: any) => !isNumeric(value);

export const enumToValues = <T>(
  enumeration: T,
): T[keyof T][] => {
  const filteredKeys = Object.keys(enumeration).filter(stringIsNotNumeric);

  return filteredKeys.map((key) => enumeration[key as keyof T]);
};

export const enumToKeys = (enumeration: { [s: number]: string }): string[] => {
  const array: string[] = [];

  for (let value in enumeration) {
    if (typeof enumeration[value] === "number") {
      array.push(value);
    }
  }

  return array;
};
