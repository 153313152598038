import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import seedColor from "seed-color";
import { Avatar, Button, Checkbox, List, Tooltip } from "antd";
import { IEmployee, IEmployeeId } from "../../../employee/employee.model";
import { Style } from "components/style/Style";
import Modal from "antd/lib/modal/Modal";
import useMemoAsync from "../../../../hooks/useMemoAsync";
import { EmployeeServiceContext } from "../../../employee/services/employee.service";
import { IOrderId } from "../../order.model";
import Links from "../../../../components/links/Links";
import usePrevious from "../../../../hooks/usePrevious";
import { isEqual, pickBy } from "lodash";
import { useStateAsync } from "hooks/useStateAsync";
import { AccountType } from "../../../auth/auth.model";
import useAuth from "../../../auth/hooks/useAuth";

interface IJobAssignmentsProps {
  orderId: IOrderId;
  employees: IEmployee[];
  onAssigned?: () => Promise<any>;
  readOnly?: boolean;
}

const getEmployeeInitials = (employee: IEmployee) => employee.firstName[0] + employee.lastName[0];
const getEmployeeColor = (employee: IEmployee) => seedColor(employee.firstName + employee.lastName).toHex();
const getEmployeeTitle = (employee: IEmployee) => employee.firstName + " " + employee.lastName;

const Assignments: FC<IJobAssignmentsProps> = ({ employees, orderId, onAssigned, readOnly = false }) => {
  const { authContext } = useAuth();
  const employeesService = useContext(EmployeeServiceContext);
  const [modalVisible, setModalVisible] = useState(false);

  const [employeesWithOrders, reloadEmployeesWithOrders] = useMemoAsync(async () => {
    if (!modalVisible) {
      return null;
    }

    const result = await employeesService.getEmployeesWithOrders();

    if (result.isOk()) {
      return result.unwrap();
    }

    return null;
  }, [modalVisible]);

  const prevEmployees = usePrevious(employees);

  useEffect(() => {
    if (!!prevEmployees && !isEqual(prevEmployees, employees)) {
      reloadEmployeesWithOrders();
    }
  }, [employees, prevEmployees, reloadEmployeesWithOrders]);

  const [newAssignments, setNewAssignments] = useState<Record<string, boolean>>({});

  const currentAssignments = useMemo(() => {
    const currentEmployeesIds = employees?.map((e) => e.employeeId.value) ?? [];

    return (
      employeesWithOrders?.reduce((acc, e) => {
        return {
          ...acc,
          [e.employeeId.value]: currentEmployeesIds.includes(e.employeeId.value),
        };
      }, {} as Record<string, boolean>) ?? {}
    );
  }, [employees, employeesWithOrders]);

  const wasEmployeeChecked = useCallback((employeeId: string) => currentAssignments[employeeId], [currentAssignments]);
  const isEmployeeChecked = useCallback(
    (employeeId: string) => newAssignments[employeeId] ?? currentAssignments[employeeId],
    [newAssignments, currentAssignments]
  );

  const handleToggleAssign = useCallback(
    (employeeId: string) => (e: SyntheticEvent | any) => {
      e?.stopPropagation?.();
      setNewAssignments((a) => ({ ...a, [employeeId]: !isEmployeeChecked(employeeId) }));
    },
    [isEmployeeChecked]
  );

  const clearChangesAndClose = useCallback(() => {
    setNewAssignments({});
    setModalVisible(false);
  }, []);

  const [assigning, setAssigning] = useStateAsync(false);

  const handleAssign = useCallback(async () => {
    if (!authContext.isLabOwner) {
      return;
    }

    await setAssigning(true);

    const employees: IEmployeeId[] = Object.keys(pickBy({ ...currentAssignments, ...newAssignments }, (v) => v)).map(
      (value) => ({ value, type: "employee-id" })
    );

    const response = await employeesService.assignEmployees(orderId, employees);

    if (response.isOk()) {
      await onAssigned?.();
      clearChangesAndClose();
      await setAssigning(false);
    }
  }, [
    authContext.isLabOwner,
    setAssigning,
    currentAssignments,
    newAssignments,
    employeesService,
    orderId,
    onAssigned,
    clearChangesAndClose,
  ]);

  const [assigningSelf, setAssigningSelf] = useStateAsync(false);

  const handleAssignSelf = useCallback(async () => {
    if (!authContext.isLabEmployee) {
      return;
    }

    await setAssigningSelf(true);
    const response = await employeesService.assignSelf(authContext.labId!, orderId);

    if (response.isOk()) {
      await onAssigned?.();
    }
    await setAssigningSelf(false);
  }, [authContext.isLabEmployee, authContext.labId, setAssigningSelf, employeesService, orderId, onAssigned]);

  const toggleModal = useCallback(() => setModalVisible((visible) => !visible), []);

  if (!employees) return null;

  return (
    <div className="wt-assignments flex">
      <Avatar.Group maxCount={3}>
        {employees.map((e, i) => (
          <Tooltip key={i} title={getEmployeeTitle(e)} placement="top">
            <Avatar style={{ backgroundColor: getEmployeeColor(e) }}>{getEmployeeInitials(e)}</Avatar>
          </Tooltip>
        ))}
      </Avatar.Group>
      {(authContext.accountType === AccountType.LabOwner || authContext.accountType === AccountType.LabEmployee) &&
        !readOnly && (
          <Button
            shape="circle"
            className={classNames("inline-flex items-center justify-center text-lg", {
              "-ml-2": employees.length > 0,
            })}
            loading={assigningSelf}
            onClick={authContext.isLabEmployee ? handleAssignSelf : toggleModal}
            icon={<div>+</div>}
          />
        )}
      <Style
        selector=".wt-assignments .ant-avatar-group .ant-avatar-string"
        style={{ display: "block", marginTop: -1 }}
      />
      <Modal
        title={<FormattedMessage id="job-assignments.assign-employees" defaultMessage="Przydziel pracowników" />}
        visible={modalVisible}
        maskClosable
        okButtonProps={{ loading: assigning }}
        onOk={handleAssign}
        onCancel={clearChangesAndClose}
      >
        <List
          itemLayout="horizontal"
          dataSource={employeesWithOrders ?? []}
          renderItem={(e, i) => (
            <List.Item
              key={i}
              onClick={handleToggleAssign(e.employeeId.value)}
              className="hover-effect px-4"
              actions={[
                <Checkbox
                  key={"assigned-checkbox"}
                  checked={isEmployeeChecked(e.employeeId.value)}
                  disabled={e.isDeleted && !wasEmployeeChecked(e.employeeId.value)}
                  onChange={handleToggleAssign(e.employeeId.value)}
                />,
              ]}
            >
              <List.Item.Meta
                className="centered"
                avatar={<Avatar style={{ backgroundColor: getEmployeeColor(e) }}>{getEmployeeInitials(e)}</Avatar>}
                title={getEmployeeTitle(e)}
                description={
                  e.activeOrders?.length ? (
                    <>
                      <FormattedMessage
                        id="job-assignments.assigned-to-orders"
                        defaultMessage="Przydzielony do zamówień"
                      />
                      {": "}
                      <span>
                        {e.activeOrders.map((o, i) => (
                          <>
                            {i !== 0 && ", "}
                            <Links.OrderDetails params={o}>{o.orderNumber}</Links.OrderDetails>
                          </>
                        ))}
                      </span>
                    </>
                  ) : undefined
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default Assignments;
