import { Spin } from "antd";
import { IOrderId } from "../../../../order.model";
import ErrorScreen from "components/errors/ErrorScreen";
import useOrderFeedback from "features/orders/hooks/useOrderFeedback";
import { ILabId } from "features/dental-lab/dental-lab.model";
import OrderFeedbackThumbs from "../order-feedback-thumbs/OrderFeedbackThumbs";
import OrderFeedbackMarks from "../order-feedback-marks/OrderFeedbackMarks";
import OrderFeedbackModalForm, {
  IOrderFeedbackFormValues,
} from "features/orders/pages/new-details/components/order-feedback-modal/OrderFeedbackModalForm";
import useBooleanFlag from "hooks/useBooleanFlag";
import { useCallback } from "react";
import useAccess from "features/access/useAccess";

import "./order-feedback.scss";

type PropsType = {
  orderId: IOrderId;
  labId: ILabId;
};

const OrderFeedback = (props: PropsType) => {
  const { getOrderFeedback, submitPositiveFeedbackAsLab, submitPositiveFeedbackAsDentist, giveFeedback } =
    useOrderFeedback(props.orderId, props.labId);

  const { flag: isModalVisible, uncheck: hideModal, check: showModal } = useBooleanFlag(false);
  const { isDentist } = useAccess();

  const handleFormSubmit = useCallback(
    async (formValues: IOrderFeedbackFormValues) => {
      const { dentistRating, ...other } = formValues;
      await giveFeedback(dentistRating === null ? other : { ...other, dentistRating });
      hideModal();
    },
    [giveFeedback, hideModal]
  );

  const handleThumbsUpClick = useCallback(async () => {
    if (isDentist) {
      await submitPositiveFeedbackAsDentist();
    } else {
      await submitPositiveFeedbackAsLab();
    }
  }, [isDentist, submitPositiveFeedbackAsDentist, submitPositiveFeedbackAsLab]);

  if (getOrderFeedback === undefined) {
    return <Spin />;
  }

  if (getOrderFeedback.isErr()) {
    return <ErrorScreen error={getOrderFeedback.err().unwrap()} />;
  }

  const feedback = getOrderFeedback.unwrap();

  return (
    <>
      <OrderFeedbackModalForm
        initialValues={feedback !== null ? { ...feedback, dentistRating: 5 } : undefined}
        onSubmit={handleFormSubmit}
        onCancel={hideModal}
        isModalVisible={isModalVisible}
      />
      {feedback !== null && (
        <OrderFeedbackMarks feedback={feedback} onProvideDentistFeedback={isDentist ? showModal : undefined} />
      )}
      {feedback === null && <OrderFeedbackThumbs onThumbsUpClick={handleThumbsUpClick} onThumbsDownClick={showModal} />}
    </>
  );
};

export default OrderFeedback;
