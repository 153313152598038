import React from "react";
import { Form, Input, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const PatientCodeControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={
      <span>
        <FormattedMessage id="common.patient-code" defaultMessage="Kod pacjenta" />
        &nbsp;
        <Tooltip
          title={
            <FormattedMessage
              id="common.provide-patient-code"
              defaultMessage="Podaj kod pacjenta lub inne identyfikujące prace dla pacjenta informacje, może być kod którego używa doktor do identyfikowania pacjenta"
            />
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </span>
    }
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="patientCode"
      control={props.control}
      render={({ field: { value, ...rest } }) => (
        <Input value={value === null ? undefined : value} {...rest} autoComplete={"nope"} />
      )}
    />
  </Form.Item>
);
