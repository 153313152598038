import React, { useEffect, useState } from "react";
import { useChannelStateContext, useChatContext } from "stream-chat-react";

import "./ChannelHeader.scss";

import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from "features/chat/chat.model";

import { BackArrowButton } from "components/common-components/CommonButtons";
import { TypingIndicator } from "features/chat/pages/chat-page/components/TypingIndicator/TypingIndicator";

type Props = {
  showOpenChannelListArrow: boolean;
  onOpenChannelList: () => void;
};

const ChannelHeader: React.FC<Props> = (props) => {
  const { client } = useChatContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const { channel } = useChannelStateContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const [channelName] = useState(channel.data?.name || "");
  const [title, setTitle] = useState("");

  const members = Object.values(channel.state.members || {}).filter((member) => member.user?.id !== client?.user?.id);

  useEffect(() => {
    if (!channelName) {
      setTitle(members.map((member) => member.user?.name || member.user?.id || "-").join(", "));
    }
  }, [channelName, members]);

  return (
    <div className="channel-header">
      {props.showOpenChannelListArrow && (
        <div className="channel-header__back-arrow">
          <BackArrowButton onClick={props.onOpenChannelList} />
        </div>
      )}
      <div className="channel-header__name">{channelName || title}</div>
      <div className="channel-header__right">
        <TypingIndicator />
      </div>
    </div>
  );
};

export default React.memo(ChannelHeader);
