import _isEqual from "fast-deep-equal";

export function isNumber(arg: any): arg is number {
  return typeof arg === "number" && isFinite(arg);
}

export function isString(arg: any): arg is string {
  return typeof arg === "string" || arg instanceof String;
}

export function isNotEmptyString(arg: any): arg is string {
  return (typeof arg === "string" || (arg instanceof String)) && arg.trim().length !== 0;
}

export function isEqual(arg1: any, arg2: any): boolean {
  return _isEqual(arg1, arg2) as boolean;
}
