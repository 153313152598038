import IApiResponseEnvelope from "../../../contracts/envelope/api-response-envelope";
import { IMoney } from "core/money/money.model";
import { AnchorTypeCode, ArchCode } from "./orders.dto.models";
import { PointAreaCode} from "core/point/point.enums";

export enum PricingStrategyCode {
  NotSet = 1000,
  Individual = 2000,
  FixedPrice = 3000,
  PerQuantity = 4000,
  PerArch = 5000,
  PerPoint = 6000,
  PerPointWithArchBasePrice = 7000,
  PerCrown = 8000,
  PerBridgePoint = 9000,
  PerMarylandBridgePoint = 10000,
  PerFoundationPoint = 11000,
}

export type PricingDTO =
  | {
      pricingStrategy: PricingStrategyCode.NotSet;
    }
  | {
      pricingStrategy: PricingStrategyCode.Individual;
    }
  | {
      pricingStrategy: PricingStrategyCode.FixedPrice;
      fixedPrice: IMoney;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerQuantity;
      pricePerItem: IMoney;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerArch;
      pricePerArch: Record<ArchCode, IMoney>;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerPoint;
      pricePerPoint: Record<PointAreaCode, IMoney>;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerPointWithArchBasePrice;
      pricePerPoint: IMoney;
      baseArchPrice: IMoney;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerCrown;
      pricePerCrown: Record<
        PointAreaCode,
        Record<
          AnchorTypeCode.CementedToImplant | AnchorTypeCode.ScrewedInToImplant | AnchorTypeCode.CementedToPreparedTooth,
          IMoney
        >
      >;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerBridgePoint;
      pricePerBridgePoint: Record<
        PointAreaCode,
        Record<
          | AnchorTypeCode.CementedToImplant
          | AnchorTypeCode.ScrewedInToImplant
          | AnchorTypeCode.CementedToPreparedTooth
          | AnchorTypeCode.Pontic,
          IMoney
        >
      >;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerMarylandBridgePoint;
      pricePerMarylandBridgePoint: Record<
        PointAreaCode,
        Record<AnchorTypeCode.CementedToPreparedTooth | AnchorTypeCode.Pontic, IMoney>
      >;
    }
  | {
      pricingStrategy: PricingStrategyCode.PerFoundationPoint;
      pricePerFoundationPoint: Record<
        AnchorTypeCode.ScrewedInToImplant | AnchorTypeCode.CementedToImplant | AnchorTypeCode.Pontic,
        IMoney
      >;
    };

export interface IGetPricingCatalogueResponse
  extends IApiResponseEnvelope<{
    dentalLabId: string;
    isDefault: boolean;
    pricingEntries: {
      orderTypeId: string;
      pricing: PricingDTO;
    }[];
  }> {}
