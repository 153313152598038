import * as React from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import ProTable, { ActionType, ProColumns, ProTableProps } from "@ant-design/pro-table";
import moment from "moment";
import useQueryParam from "hooks/useQueryParam";
import { SortOrder } from "antd/lib/table/interface";
import { ServerSortDirection } from "features/orders/pages/list/components/orders-table.models";
import {
  BillingStatementsDataProvider,
  BillingStatementServerColumn,
  IBillingStatementProviderParams,
  IBillingStatementTableEntry,
} from "features/invoicing/components/billing-statements-table/billing-statement-table.models";
import Links from "components/links/Links";
import MoneyFormatter from "components/formatters/money/MoneyFormatter";
import PersonNameFormatter, { PersonNameFormat } from "components/formatters/person-name/PersonNameFormatter";
import { Honorific } from "core/person-name/honorific";
import { isEmptyObject } from "core/utils/object";
import { Divider, Popconfirm } from "antd";
import { IBillingStatementId } from "features/invoicing/models/invoicing.models";
import { IResult } from "core/lib/types/result";
import { DeleteButton } from "components/common-components/CommonButtons";

interface IBillingStatementTableProps {
  dataProvider: BillingStatementsDataProvider;
  onDelete?: (id: IBillingStatementId) => Promise<IResult<void>>;
  actionRef?: React.MutableRefObject<ActionType | undefined>;
}

type TableParams = {
  current: number;
  pageSize: number;
  statementNumber?: number;
  createdOnDateRange?: { start: string /*"YYYY-MM-DD"*/; end: string /*"YYYY-MM-DD"*/ }; // transformed from dueDate
};

type CombinedTableParams = TableParams & {
  sort: Record<string, SortOrder>; /// HERE 1
};

const generateDataProviderParams = (combinedTableParams: CombinedTableParams): IBillingStatementProviderParams => {
  const { sort, ...tableParams } = combinedTableParams;

  const { pageSize = 50, current = 1, statementNumber = null, createdOnDateRange = null } = tableParams;

  const dataProviderParams: IBillingStatementProviderParams = {
    pagination: { pageNumber: current, pageSize: pageSize },
  };

  // if (dentist !== null) {
  //   dataProviderParams.dentistId = NewDentistId(dentist.value);
  // }
  //
  // if (patient !== null) {
  //   dataProviderParams.patient = patient;
  // }
  //
  if (statementNumber !== null) {
    dataProviderParams.statementNumber = statementNumber;
  }

  // if (status !== null) {
  //   dataProviderParams.status = status.map(s => mapStatusToStatusCode(s.value));
  // }

  // if (
  //   (assignmentFilter === AssignmentFilter.All || assignmentFilter === AssignmentFilter.Employee) &&
  //   assignedEmployees !== null
  // ) {
  //   dataProviderParams.assignedEmployee = {
  //     type: "employee-id",
  //     value: assignedEmployees.value,
  //   };
  // }

  // noinspection DuplicatedCode
  if (createdOnDateRange !== null) {
    dataProviderParams.createdOnRange = {
      start: moment(createdOnDateRange.start).startOf("day").toISOString(),
      end: moment(createdOnDateRange.end).endOf("day").toISOString(),
    };
  }

  if (typeof sort === "object" && Object.keys(sort).length === 1) {
    console.info("sort:", sort);

    const [column, direction] = Object.entries(sort)[0];

    dataProviderParams.sortBy = {
      column: column as BillingStatementServerColumn,
      direction: direction === "ascend" ? ServerSortDirection.Ascending : ServerSortDirection.Descending,
    };
  }

  return dataProviderParams;
};

const BillingStatementTable: React.FunctionComponent<IBillingStatementTableProps> = (props) => {
  const intl = useIntl();
  const [tableParamsFromUrl, saveTableParamsToUrl] = useQueryParam<CombinedTableParams>("billingStatements");

  const tableColumns: ProColumns<IBillingStatementTableEntry>[] = [
    {
      key: "statementNumber",
      sorter: BillingStatementServerColumn.StatementNumber,
      defaultSortOrder: tableParamsFromUrl?.sort?.statementNumber,
      title: <FormattedMessage id="common.short-order-number" defaultMessage="Nr." />,
      valueType: "digit",
      hideInSearch: false,
      render: (_, entry) => (
        <Links.BillingStatementDetails params={{ billingStatementId: entry.id }}>
          {entry.statementNumber}
        </Links.BillingStatementDetails>
      ),
    },
    {
      key: "dentist",
      title: <FormattedMessage id="common.recipient" defaultMessage="Wystawiono dla" />,
      sorter: BillingStatementServerColumn.Dentist,
      defaultSortOrder: tableParamsFromUrl?.sort?.dentist,
      hideInSearch: true,
      render: (_, entry) =>
        entry.recipient === null ? null : (
          <PersonNameFormatter
            format={PersonNameFormat.Short}
            personName={{
              honorific: Honorific.None,
              firstName: entry.recipient.name.firstName,
              lastName: entry.recipient.name.lastName,
            }}
          />
        ),
    },
    {
      key: "createdOn",
      sorter: BillingStatementServerColumn.CreatedOn,
      defaultSortOrder: tableParamsFromUrl?.sort?.createdOn,
      title: <FormattedMessage id="common.created-on" defaultMessage="Data utworzenia" />,
      hideInSearch: true,
      render: (_, entry) =>
        entry.createdOn !== null ? <FormattedDate value={new Date(entry.createdOn)} dateStyle="short" /> : null,
    },
    {
      key: "grandTotal",
      sorter: BillingStatementServerColumn.GrandTotal,
      defaultSortOrder: tableParamsFromUrl?.sort?.grandTotal,
      title: <FormattedMessage id="common.grand-total" defaultMessage="Wartość rozliczenia" />,
      hideInSearch: true,
      render: (_, entry) => <MoneyFormatter value={entry.grandTotal} />,
    },
    {
      key: "actions",
      hideInSearch: true,
      title: <FormattedMessage id="common.actions" defaultMessage="Akcje" />,
      render: (_, entry, index, action) => (
        <div className="flex flex-row flex-no-wrap items-center">
          <Links.BillingStatementDetails params={{ billingStatementId: entry.id }}>
            <FormattedMessage id="common.details" defaultMessage="Idź do rozliczenia" />
          </Links.BillingStatementDetails>
        </div>
      ),
    },
  ];

  const handleRequest: ProTableProps<IBillingStatementTableEntry, any>["request"] = React.useCallback(
    async (params: TableParams, sort: Record<string, SortOrder>) => {
      const combinedTableParams: CombinedTableParams = {
        ...params,
        sort: isEmptyObject(sort) ? { ...(tableParamsFromUrl?.sort ?? {}) } : { ...sort },
      };

      saveTableParamsToUrl(combinedTableParams);

      const dataProviderParams = generateDataProviderParams(combinedTableParams);

      const result = await props.dataProvider(dataProviderParams);

      if (result.isErr()) {
        return { data: [], success: false };
      }

      const resultsPage = result.unwrap();
      return { data: resultsPage.data, success: true, total: resultsPage.pagination.totalElements };
    },
    [tableParamsFromUrl?.sort, saveTableParamsToUrl, props]
  );

  return (
    <ProTable<IBillingStatementTableEntry>
      actionRef={props?.actionRef}
      rowKey={(item) => item.id.value}
      columns={tableColumns}
      dateFormatter="string"
      request={handleRequest}
      form={{
        syncToUrl: (_, type) => {
          if (type === "get") {
            if (tableParamsFromUrl === undefined) {
              return {};
            }

            let params: CombinedTableParams = { ...tableParamsFromUrl };

            if (params.createdOnDateRange !== undefined) {
              const { createdOnDateRange, ...rest } = params;
              const start = createdOnDateRange.start;
              const end = createdOnDateRange.end;

              params = {
                dueDate: [start, end],
                ...rest,
              } as any;
            }

            return params;
          }

          return {};
        },
      }}
      columnsState={{
        persistenceKey: "billing-statements-table",
        persistenceType: "localStorage",
      }}
      pagination={{ showTotal: (total, range) => <>{`${range[0]}-${range[1]} z ${total}`}</> }}
      search={{ filterType: "light" }}
      options={{ fullScreen: true, density: true, reload: true, setting: true }}
    />
  );
};

export default BillingStatementTable;

// {
//   key: "clinicName",
//   title: <FormattedMessage id="common.dental-clinic" defaultMessage="Klinika" />,
//   hideInSearch: true,
//   render: (_, entry) => <span>{entry.dentist.clinicName}</span>,
// },
// {
//   key: "dentist",
//   sorter: BillingStatementServerColumn.Dentist,
//   defaultSortOrder: tableParamsFromUrl?.sort?.dentist,
//   title: <FormattedMessage id="common.dentist" defaultMessage="Dentysta" />,
//   render: (_, entry) => <PersonNameFormatter personName={entry.dentist.name} />,
//   formItemProps: {
//     lightProps: {
//       labelFormatter: (value: { value: string; label: string }) => value.label,
//     },
//   },
//   renderFormItem: (schema, config, form) => {
//     if (config.type === "form") {
//       return null;
//     }
//
//     return (
//       <ProFormSelect
//         {...(config as any).fieldProps}
//         showSearch
//         allowClear={false}
//         fieldProps={{ labelInValue: true }}
//         valueEnum={props.dentists.reduce(
//           (acc, dentist) => ({
//             ...acc,
//             [dentist.id.value]: { text: dentist.name.firstName + " " + dentist.name.lastName },
//           }),
//           {}
//         )}
//       />
//     );
//   },
// },
// {
//   key: "patient",
//   title: <FormattedMessage id="common.patient" defaultMessage="Pacjent" />,
//   render: (_, entry) => (entry.patient === null ? null : <PatientNameFormatter patientName={entry.patient} />),
// },
// {
//   key: "startDate",
//   title: <FormattedMessage id="common.start-date" defaultMessage="Termin przyjścia" />,
//   hideInSearch: true,
//   render: (_, entry) =>
//     entry.startDate !== null ? <FormattedDate value={new Date(entry.startDate)} dateStyle="short" /> : null,
// },
// {
//   key: "dueDate",
//   sorter: BillingStatementServerColumn.DueDate,
//   defaultSortOrder: tableParamsFromUrl?.sort?.dueDate,
//   title: <FormattedMessage id="common.due-date" defaultMessage="Na kiedy" />,
//   valueType: "dateRange",
//   search: {
//     transform: (value) => ({
//       dueDateRange: { start: value[0], end: value[1] },
//     }),
//   },
//   render: (_, entry) => (entry.dueDate !== null ? <DueDateFormatter dueDate={entry.dueDate} /> : null),
// },
// {
//   key: "status",
//   sorter: BillingStatementServerColumn.Status,
//   defaultSortOrder: tableParamsFromUrl?.sort?.status,
//   title: <FormattedMessage id="common.status" defaultMessage="Status" />,
//   formItemProps: {
//     lightProps: {
//       labelFormatter: (values: { label: string }[]) => (values ?? []).map((v) => v.label).join(","),
//     },
//   },
//   renderFormItem: (schema, config) => {
//     if (config.type === "form") {
//       return null;
//     }
//
//     return (
//       <ProFormSelect
//         {...(config as any).fieldProps}
//         allowClear={false}
//         mode={"multiple"}
//         fieldProps={{ labelInValue: true }}
//         valueEnum={statusValueEnums}
//       />
//     );
//   },
//   render: (status: React.ReactNode, entry, index, action) => (
//     <OrderStatus
//       status={entry.status}
//       onChange={
//         props.onStatusChange === undefined
//           ? undefined
//           : (nextStatus: Status) => {
//               return props.onStatusChange!(entry.orderId, nextStatus).then(async (result) => {
//                 await action?.reload();
//                 return result;
//               });
//             }
//       }
//     />
//   ),
// },
// {
//   key: "assignedEmployees",
//   title: <FormattedMessage id="common.assignedEmployees" defaultMessage="Pracownicy" />,
//   hideInSearch: activeAssignmentFilter !== AssignmentFilter.All,
//   formItemProps: {
//     lightProps: {
//       labelFormatter: (value: { value: string; label: string }) => value.label,
//     },
//   },
//   render: (_, entry) => (
//     <Assignments orderId={entry.orderId} employees={entry.assignedEmployees} onAssigned={handleOnAssigned} />
//   ),
//   renderFormItem: (schema, config, form) => {
//     if (config.type === "form") {
//       return null;
//     }
//
//     return (
//       <ProFormSelect
//         {...(config as any).fieldProps}
//         showSearch
//         allowClear={false}
//         fieldProps={{ labelInValue: true }}
//         valueEnum={props.employees.reduce(
//           (acc, employee) => ({
//             ...acc,
//             [employee.employeeId.value]: { text: employee.firstName + " " + employee.lastName },
//           }),
//           {}
//         )}
//       />
//     );
//   },
// },
// {
//   key: "invoice",
//   title: <FormattedMessage id="common.invoice" defaultMessage="Faktura" />,
//   hideInSearch: true,
//   render: (_, entry) => {
//     if (entry.invoice === null) {
//       return null;
//     }
//
//     const { invoiceId, invoiceNumber } = entry.invoice;
//
//     return <Links.InvoiceDetails params={{ invoiceId: invoiceId }}>{invoiceNumber}</Links.InvoiceDetails>;
//   },
// },
// {
//   key: "actions",
//   hideInSearch: true,
//   title: <FormattedMessage id="common.actions" defaultMessage="Akcje" />,
//   render: (_, entry, index, action) => (
//     <div className="flex flex-row flex-no-wrap items-center">
//       <Links.OrderDetails
//         params={{
//           orderId: entry.orderId,
//           orderNumber: entry.orderNumber,
//         }}
//       >
//         <FormattedMessage id="common.details" defaultMessage="Idź do zamówienia" />
//       </Links.OrderDetails>
//       {entry.status === Status.Finished && props.onChangeFinishDate !== undefined && (
//         <>
//           <Divider type="vertical" />
//           <Popconfirm
//             title={
//               <FormattedMessage
//                 id="order-item-table.are-you-sure-you-want-to-change-finish-date"
//                 defaultMessage="Czy na pewno chcesz zmienić termin ukończenia zamówienia ?"
//               />
//             }
//             onConfirm={() => props.onChangeFinishDate?.(entry.orderId, entry.finishDate)}
//           >
//             <Tooltip
//               placement={"bottom"}
//               title={
//                 <FormattedMessage
//                   id="order-item-table.order-finish-date"
//                   defaultMessage="Praca ukończona {formattedDate}"
//                   values={{
//                     formattedDate:
//                       entry.finishDate === null ? "" : <FormattedDate value={new Date(entry.finishDate!)} />,
//                   }}
//                 />
//               }
//             >
//               <Button icon={<ScheduleOutlined />} />
//             </Tooltip>
//           </Popconfirm>
//         </>
//       )}
//       {entry.invoice === null && props.onDelete !== undefined && (
//         <>
//           <Divider type="vertical" />
//           <Popconfirm
//             title={
//               <FormattedMessage
//                 id="order-item-table.are-you-sure-you-want-to-delete-order-item"
//                 defaultMessage="Czy na pewno chcesz usunąć zamówienie ?"
//               />
//             }
//             onConfirm={() =>
//               props.onDelete?.(entry.orderId).then(async (result) => {
//                 await action?.reload();
//                 return result;
//               })
//             }
//           >
//             <DeleteButton />
//           </Popconfirm>
//         </>
//       )}
//     </div>
//   ),
// },
