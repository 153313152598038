import { ColorCode } from "features/orders/color/dto/color.dto";
import {
  BleachShade,
  Color,
  Shade,
  ShadeGuide,
  Vita3dMasterShade,
  VitaClassicalShade,
} from "features/orders/color/color.model";
import { argumentOutOfRangeError } from "core/errors/generate-error";

export const mapColorCodeToColor = (colorCode: ColorCode): Color => {
  switch (colorCode) {
    case ColorCode.VC_0M1: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.ZERO_M1 };
    }
    case ColorCode.VC_0M2: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.ZERO_M2 };
    }
    case ColorCode.VC_0M3: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.ZERO_M3 };
    }
    case ColorCode.VC_A1: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A1 };
    }
    case ColorCode.VC_A2: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A2 };
    }
    case ColorCode.VC_A3: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A3 };
    }
    case ColorCode.VC_A3_5: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A3_5 };
    }
    case ColorCode.VC_A4: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A4 };
    }
    case ColorCode.VC_B1: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B1 };
    }
    case ColorCode.VC_B2: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B2 };
    }
    case ColorCode.VC_B3: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B3 };
    }
    case ColorCode.VC_B4: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B4 };
    }
    case ColorCode.VC_C1: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C1 };
    }
    case ColorCode.VC_C2: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C2 };
    }
    case ColorCode.VC_C3: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C3 };
    }
    case ColorCode.VC_C4: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C4 };
    }
    case ColorCode.VC_D2: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.D2 };
    }
    case ColorCode.VC_D3: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.D3 };
    }
    case ColorCode.VC_D4: {
      return { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.D4 };
    }
    case ColorCode.V3D_0_M1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ZERO_M1 };
    }
    case ColorCode.V3D_0_M2: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ZERO_M2 };
    }
    case ColorCode.V3D_0_M3: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ZERO_M3 };
    }
    case ColorCode.V3D_1M1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ONE_M1 };
    }
    case ColorCode.V3D_1M2: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ONE_M2 };
    }
    case ColorCode.V3D_2L1_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_L1_5 };
    }
    case ColorCode.V3D_2L2_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_L2_5 };
    }
    case ColorCode.V3D_2M1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_M1 };
    }
    case ColorCode.V3D_2M2: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_M2 };
    }
    case ColorCode.V3D_2M3: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_M3 };
    }
    case ColorCode.V3D_2R1_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_R1_5 };
    }
    case ColorCode.V3D_2R2_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_R1_5 };
    }
    case ColorCode.V3D_3L1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_L1_5 };
    }
    case ColorCode.V3D_3L2_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_L2_5 };
    }
    case ColorCode.V3D_3M1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_M1 };
    }
    case ColorCode.V3D_3M2: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_M2 };
    }
    case ColorCode.V3D_3M3: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_M3 };
    }
    case ColorCode.V3D_3R1_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_R1_5 };
    }
    case ColorCode.V3D_3R2_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_R2_5 };
    }
    case ColorCode.V3D_4L1_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_L1_5 };
    }
    case ColorCode.V3D_4L2_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_L2_5 };
    }
    case ColorCode.V3D_4M1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_M1 };
    }
    case ColorCode.V3D_4M2: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_M2 };
    }
    case ColorCode.V3D_4M3: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_M3 };
    }
    case ColorCode.V3D_4R1_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_R1_5 };
    }
    case ColorCode.V3D_4R2_5: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_R2_5 };
    }
    case ColorCode.V3D_5M1: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FIVE_M1 };
    }
    case ColorCode.V3D_5M2: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FIVE_M2 };
    }
    case ColorCode.V3D_5M3: {
      return { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FIVE_M3 };
    }
    case ColorCode.BL1: {
      return { guide: ShadeGuide.Bleach, shade: BleachShade.BL1 };
    }
    case ColorCode.BL2: {
      return { guide: ShadeGuide.Bleach, shade: BleachShade.BL2 };
    }
    case ColorCode.BL3: {
      return { guide: ShadeGuide.Bleach, shade: BleachShade.BL3 };
    }
    case ColorCode.BL4: {
      return { guide: ShadeGuide.Bleach, shade: BleachShade.BL4 };
    }
    default:
      throw argumentOutOfRangeError(`invalid shade guide`, colorCode);
  }
};

export const mapShadeToShadeCode = (shade: Shade): ColorCode => {
  switch (shade) {
    case VitaClassicalShade.ZERO_M1: {
      return ColorCode.VC_0M1;
    }
    case VitaClassicalShade.ZERO_M2: {
      return ColorCode.VC_0M2;
    }
    case VitaClassicalShade.ZERO_M3: {
      return ColorCode.VC_0M3;
    }
    case VitaClassicalShade.A1: {
      return ColorCode.VC_A1;
    }
    case VitaClassicalShade.A2: {
      return ColorCode.VC_A2;
    }
    case VitaClassicalShade.A3: {
      return ColorCode.VC_A3;
    }
    case VitaClassicalShade.A3_5: {
      return ColorCode.VC_A3_5;
    }
    case VitaClassicalShade.A4: {
      return ColorCode.VC_A4;
    }
    case VitaClassicalShade.B1: {
      return ColorCode.VC_B1;
    }
    case VitaClassicalShade.B2: {
      return ColorCode.VC_B2;
    }
    case VitaClassicalShade.B3: {
      return ColorCode.VC_B3;
    }
    case VitaClassicalShade.B4: {
      return ColorCode.VC_B4;
    }
    case VitaClassicalShade.C1: {
      return ColorCode.VC_C1;
    }
    case VitaClassicalShade.C2: {
      return ColorCode.VC_C2;
    }
    case VitaClassicalShade.C3: {
      return ColorCode.VC_C3;
    }
    case VitaClassicalShade.C4: {
      return ColorCode.VC_C4;
    }
    case VitaClassicalShade.D2: {
      return ColorCode.VC_D2;
    }
    case VitaClassicalShade.D3: {
      return ColorCode.VC_D3;
    }
    case VitaClassicalShade.D4: {
      return ColorCode.VC_D4;
    }
    case Vita3dMasterShade.ZERO_M1: {
      return ColorCode.V3D_0_M1;
    }
    case Vita3dMasterShade.ZERO_M2: {
      return ColorCode.V3D_0_M2;
    }
    case Vita3dMasterShade.ZERO_M3: {
      return ColorCode.V3D_0_M3;
    }
    case Vita3dMasterShade.ONE_M1: {
      return ColorCode.V3D_1M1;
    }
    case Vita3dMasterShade.ONE_M2: {
      return ColorCode.V3D_1M1;
    }
    case Vita3dMasterShade.TWO_L1_5: {
      return ColorCode.V3D_2L1_5;
    }
    case Vita3dMasterShade.TWO_L2_5: {
      return ColorCode.V3D_2L2_5;
    }
    case Vita3dMasterShade.TWO_M1: {
      return ColorCode.V3D_2M1;
    }
    case Vita3dMasterShade.TWO_M2: {
      return ColorCode.V3D_2M2;
    }
    case Vita3dMasterShade.TWO_M3: {
      return ColorCode.V3D_2M3;
    }
    case Vita3dMasterShade.TWO_R1_5: {
      return ColorCode.V3D_2R1_5;
    }
    case Vita3dMasterShade.TWO_R2_5: {
      return ColorCode.V3D_2R2_5;
    }
    case Vita3dMasterShade.THREE_L1_5: {
      return ColorCode.V3D_3L1;
    }
    case Vita3dMasterShade.THREE_L2_5: {
      return ColorCode.V3D_3L2_5;
    }
    case Vita3dMasterShade.THREE_M1: {
      return ColorCode.V3D_3M1;
    }
    case Vita3dMasterShade.THREE_M2: {
      return ColorCode.V3D_3M2;
    }
    case Vita3dMasterShade.THREE_M3: {
      return ColorCode.V3D_3M3;
    }
    case Vita3dMasterShade.THREE_R1_5: {
      return ColorCode.V3D_3R1_5;
    }
    case Vita3dMasterShade.THREE_R2_5: {
      return ColorCode.V3D_3R2_5;
    }
    case Vita3dMasterShade.FOUR_L1_5: {
      return ColorCode.V3D_4L1_5;
    }
    case Vita3dMasterShade.FOUR_L2_5: {
      return ColorCode.V3D_4L2_5;
    }
    case Vita3dMasterShade.FOUR_M1: {
      return ColorCode.V3D_4M1;
    }
    case Vita3dMasterShade.FOUR_M2: {
      return ColorCode.V3D_4M2;
    }
    case Vita3dMasterShade.FOUR_M3: {
      return ColorCode.V3D_4M3;
    }
    case Vita3dMasterShade.FOUR_R1_5: {
      return ColorCode.V3D_4R1_5;
    }
    case Vita3dMasterShade.FOUR_R2_5: {
      return ColorCode.V3D_4R2_5;
    }
    case Vita3dMasterShade.FIVE_M1: {
      return ColorCode.V3D_5M1;
    }
    case Vita3dMasterShade.FIVE_M2: {
      return ColorCode.V3D_5M2;
    }
    case Vita3dMasterShade.FIVE_M3: {
      return ColorCode.V3D_5M3;
    }
    case BleachShade.BL1: {
      return ColorCode.BL1;
    }
    case BleachShade.BL2: {
      return ColorCode.BL2;
    }
    case BleachShade.BL3: {
      return ColorCode.BL3;
    }
    case BleachShade.BL4: {
      return ColorCode.BL4;
    }
    default:
      throw argumentOutOfRangeError(`invalid shade guide`, shade);
  }
};
