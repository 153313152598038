import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import LabSelect from "components/forms/lab-select/LabSelect";
import { IShortLab } from "features/dental-lab/dental-lab.model";

export const LabControl = (props: {
  labs: IShortLab[];
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
  disabled?: boolean,
}) => (
  <Form.Item
    required
    label={<FormattedMessage id="common.lab" defaultMessage="Laboratorium" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="labId"
      control={props.control}
      render={({ field }) => <LabSelect disabled={props.disabled} labs={props.labs} className={"max-w-lg"} {...field} />}
    />
  </Form.Item>
);
