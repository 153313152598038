import * as React from "react";
import useSWR from "swr";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { nameof } from "ts-simple-nameof";
import { IPricingListApi } from "features/pricing-lists/service/IPricingListApi";
import {
  IDeletePricingListRequest,
  IEditPricingListRequest,
  IPricingListId,
  IUpdatePricingEntryRequest,
  NewPricingListId,
} from "features/pricing-lists/pricing-list.models";

function usePricingList(pricingListId: IPricingListId | null) {
  const pricingListApi = React.useContext(PricingListApiContext);

  const {
    data: getPricingList,
    isValidating: isValidating,
    mutate: revalidate,
  } = useSWR(
    () => [pricingListId?.value, nameof<IPricingListApi>((x) => x.get)],
    async (pricingListId: string) => pricingListApi.get(NewPricingListId(pricingListId))
  );

  const editPricingList = React.useCallback(
    async (request: IEditPricingListRequest) => pricingListApi.edit(request),
    [pricingListApi]
  );

  const deletePricingList = React.useCallback(
    async (request: IDeletePricingListRequest) => pricingListApi.delete(request),
    [pricingListApi]
  );

  const setPricingEntry = React.useCallback(
    async (request: IUpdatePricingEntryRequest) => pricingListApi.setPricingEntry(request),
    [pricingListApi]
  );

  return {
    getPricingList,
    editPricingList,
    deletePricingList,
    setPricingEntry,
    isValidating: isValidating,
    revalidate: revalidate,
  };
}

export default usePricingList;
