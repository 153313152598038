import { ISODateTimeString } from "core/time/time.model";
import { Status, StatusCode } from "features/orders/status/order-status.model";
import { SpecificationTypeCode } from "features/orders/order.model";
import { PointId } from "core/point/point.enums";
import { IMoneyDTO } from "contracts/common-dtos/money.dto";
import { IEmployeeDTO } from "../../employee/contracts/employee.dto.model";
import { IDentistDTO } from "../../dentist/contracts/dentsist.dto";
import { PatientDTO } from "contracts/common-dtos/patient.dto";

export enum ArchCode {
  Upper = 1,
  Lower = 2,
}

export enum AnchorTypeCode {
  CementedToImplant = 1,
  ScrewedInToImplant = 2,
  CementedToPreparedTooth = 3,
  Pontic = 4,
}

type SpecificationBase = {};

type QuantitySpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.Quantity;
  quantity: number;
};

type ArchesSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.Arches;
  arches: ArchCode[];
};

type LocatedPointsSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.LocatedPoints;
  points: { location: PointId }[];
};

type PartialDenturesSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.PartialDentures;
  points: { location: PointId }[];
};

type SurgicalGuidesSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.SurgicalGuides;
  points: { location: PointId }[];
};

type CrownSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.Crowns;
  crowns: {
    location: PointId;
    anchorType: AnchorTypeCode;
  }[];
};

type FoundationsSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.Foundations;
  foundations: {
    location: PointId;
    anchorType: AnchorTypeCode;
  }[][];
};

type BridgeSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.Bridges;
  bridges: {
    location: PointId;
    anchorType: AnchorTypeCode;
  }[][];
};

type MarylandBridgeSpecificationDTO = SpecificationBase & {
  specificationType: SpecificationTypeCode.MarylandBridges;
  bridges: {
    location: PointId;
    anchorType: AnchorTypeCode;
  }[][];
};

export type SpecificationDTO =
  | QuantitySpecificationDTO
  | ArchesSpecificationDTO
  | LocatedPointsSpecificationDTO
  | PartialDenturesSpecificationDTO
  | SurgicalGuidesSpecificationDTO
  | CrownSpecificationDTO
  | FoundationsSpecificationDTO
  | BridgeSpecificationDTO
  | MarylandBridgeSpecificationDTO;

export interface IOrderItemDTO {
  id: string;
  orderTypeId: string;
  specification: SpecificationDTO;
  price: IMoneyDTO;
}

export interface IOrderFileDTO {
  contentType: string; // "image/png" ;
  fileKey: string; // "4e9919ab-0d9d-4bfa-806d-00f3b3dd8b12";
  fileName: string; // "test-image-5.png";
  fileSize: number; //2549;
}

export interface ILabOrderReadModelDTO {
  orderId: string;
  labId: string;
  dentist: IDentistDTO;
  patient: PatientDTO;
  orderNumber: number;
  color: number | null;
  orderNote: string | null;
  startDate: ISODateTimeString | null;
  dueDate: ISODateTimeString | null;
  finishDate: ISODateTimeString | null;
  status: StatusCode;
  billingStatementId: string | null;
  billingStatementNumber: number | null;
  orderItems: IOrderItemDTO[];
  orderFiles: IOrderFileDTO[];
  assignedEmployees: IEmployeeDTO[];
}

export interface IDentistOrderReadModelDTO {
  orderId: string;
  labId: string;
  labName: string;
  patient: PatientDTO;
  color: number | null;
  orderNote: string | null;
  startDate: ISODateTimeString | null;
  dueDate: ISODateTimeString | null;
  finishDate: ISODateTimeString | null;
  status: StatusCode;
  orderItems: IOrderItemDTO[];
  orderFiles: IOrderFileDTO[];
}

export interface ISimpleOrderDTO {
  readonly id: string;
  readonly orderNumber: number;
  readonly status: Status;
}

export type FileDTO = {
  expiration: ISODateTimeString, // "2022-03-07T13:40:03.0801648+00:00"
  extension: string, // "png"
  fileId: string, // "6f185dbf-049d-4ed6-9cca-ba04e455f3e7"
  name: string, // "test-parrot-2"
  url: string, // "https://wt-assets-m
};

export type UploadFileDTO = {
  fileResults: FileResultsType[];
};

type FileResultsType = {
  Error?: ErrorFileResultType;
  IsFailure: boolean;
  IsSuccess: boolean;
  Value?: string;
};

type ErrorFileResultType = {
  code: string;
  name: string;
  message: string;
};
