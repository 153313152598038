/* eslint-disable react/prop-types */
import React from "react";
import { IAddress } from "core/address/address.model";
import { invalidArgumentError } from "core/errors/generate-error";
import CountryName from "components/formatters/country/CountryName";
import { Country } from "core/countries/countries.model";

export enum AddressFormat {
  Short = 1,
  Formal = 2,
  OneLine = 3,
}

interface IAddressFormatter {
  address: IAddress | null;
  format?: AddressFormat;
}

const AddressFormatter: React.FunctionComponent<IAddressFormatter> = ({ address, format = AddressFormat.Short }) => {
  if (format !== AddressFormat.Short && format !== AddressFormat.Formal && format !== AddressFormat.OneLine) {
    throw invalidArgumentError("address format parameter is invalid");
  }

  if (address === null || address === undefined) {
    return null;
  }

  const { addressLine1, addressLine2, addressLine3, addressLine4, postalCode, country } = address;

  if (format === AddressFormat.Short) {
    return (
      <span>
        {`${addressLine3} ${addressLine4} ${postalCode}`} <br /> {` ${addressLine1} ${addressLine2}`}
      </span>
    );
  } else if (format === AddressFormat.Formal) {
    return (
      <React.Fragment>
        {country !== Country.Poland && (
          <span className="mr-1">
            <CountryName country={country} />
          </span>
        )}
        <span>
          {`${addressLine3} ${addressLine4} ${postalCode}`}
          <br />
          {` ${addressLine1} ${addressLine2}`}
        </span>
      </React.Fragment>
    );
  } else if (format === AddressFormat.OneLine) {
    return (
      <React.Fragment>
        {country !== Country.Poland && (
          <span className="mr-1">
            <CountryName country={country} />
          </span>
        )}
        <span>{`${addressLine3} ${addressLine4} ${postalCode} ${addressLine1} ${addressLine2}`}</span>
      </React.Fragment>
    );
  }

  return null;
};

export default AddressFormatter;
