import { FC, useCallback, useMemo } from "react";
import * as React from "react";
import SubscriptionsContext from "../subscriptions.context";
import useMemoAsync from "../../../hooks/useMemoAsync";
import { useStateAsync } from "hooks/useStateAsync";
import HeaderContent from "../../layout/content/HeaderContent";
import { Badge, Breadcrumb, Button, Card, Col, PageHeader, Result, Row } from "antd";
import Links from "../../../components/links/Links";
import { HistoryOutlined, HomeOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import PageContent from "../../layout/content/PageContent";
import { PriceInterval, SubscriptionTypeCode } from "../models/subscriptions.model";
import { FaMedal } from "react-icons/fa";
import { blue, gold, grey, yellow } from "@ant-design/colors";
import { getStandardSort } from "core/utils/sort-utils";
import ClaimsList from "../components/claims-list/ClaimsList";

const SubscriptionsPage: FC = () => {
  const subscriptionsService = React.useContext(SubscriptionsContext);
  const [submittingSubscription, setSubmittingSubscription] = useStateAsync<Record<string, boolean>>({});

  const [subscription, _1, loadingSubscription] = useMemoAsync(async () => {
    return await subscriptionsService.getSubscription();
  }, [])

  const [products, _2, loadingProducts] = useMemoAsync(async () => {
    if (!subscription) {
      return null;
    }
    
    return await subscriptionsService.getSubscriptionProducts();
  }, [subscription]);
  
  const createCheckoutSession = useCallback((priceId: string) => async () => {
    await setSubmittingSubscription(submitting => ({ ...submitting, [priceId]: true }));
    const urlResponse = await subscriptionsService.createCheckoutSession(priceId);

    if (urlResponse.isOk()) {
      window.open(urlResponse.unwrap(), '_blank')?.focus()
    }

    await setSubmittingSubscription(submitting => ({ ...submitting, [priceId]: false }));
  }, [setSubmittingSubscription, subscriptionsService]);

  const hasSubscription = useMemo(() => subscription?.unwrap()?.checkoutCompleted ?? false, [subscription]);
  
  const [panelUrl, _3, loadingPanelUrl] = useMemoAsync(async () => {
    if (!subscription || !hasSubscription) {
      return null;
    }
    
    const urlResponse = await subscriptionsService.createBillingSession();

    if (urlResponse.isOk()) {
      return urlResponse.unwrap();
    }
  }, [hasSubscription, subscription, subscriptionsService]);

  const createBillingSession = useCallback(() => {
    if (panelUrl) {
      window.open(panelUrl, '_blank')?.focus()
    }
  }, [panelUrl]);

  const getInterval = useCallback((interval: PriceInterval) => {
    const icon = <HistoryOutlined />;

    switch (interval) {
      case PriceInterval.Day: return <div className="flex gap-2 items-center"><FormattedMessage id="subscription.interval.daily" defaultMessage="daily" />{icon}</div>
      case PriceInterval.Week: return <div className="flex gap-2 items-center"><FormattedMessage id="subscription.interval.weekly" defaultMessage="weekly" />{icon}</div>
      case PriceInterval.Month: return <div className="flex gap-2 items-center"><FormattedMessage id="subscription.interval.monthly" defaultMessage="monthly" />{icon}</div>
      case PriceInterval.Year: return <div className="flex gap-2 items-center"><FormattedMessage id="subscription.interval.annual" defaultMessage="annual" />{icon}</div>
    }
  }, [])

  const getSubscriptionColor = useCallback((type: SubscriptionTypeCode) => {
    switch (type) {
      case SubscriptionTypeCode.Free: return blue[3];
      case SubscriptionTypeCode.Brown: return yellow[8];
      case SubscriptionTypeCode.Silver: return grey[3];
      case SubscriptionTypeCode.Gold: return gold[5];
    }
  }, [])

  const productsSorted = useMemo(() => {
    return products?.unwrap().sort(getStandardSort('subscriptionTypeCode'));
  }, [products]);

  return (
    <div>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.Subscriptions />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="subscriptions" defaultMessage="Subskrypcje" />}
        />
      </HeaderContent>
      <PageContent separate style={{ background: 'transparent' }}>
        <Row style={{ maxWidth: 1140, margin: "0 auto", rowGap: 16 }} gutter={16}>
          {productsSorted?.map((p, i) => {
            const isUserSubscription = hasSubscription && p.subscriptionTypeCode === subscription?.unwrap().typeCode;

            return (
              <Col xs={24} lg={12} xl={8} key={i}>

                <Badge.Ribbon
                  text={`${p.price} ${p.currency.toUpperCase()}`}
                  style={{top: 70, display: hasSubscription ? "none" : undefined}}
                  color={getSubscriptionColor(p.subscriptionTypeCode)}
                >
                  <Card
                    title={p.name}
                    extra={getInterval(p.interval)}
                  >
                    <div className="flex flex-col gap-4">
                      <Result
                        className="flex flex-col items-stretch w-full p-6"
                        icon={<FaMedal color={getSubscriptionColor(p.subscriptionTypeCode)} size={148} className="mx-auto" />}
                        subTitle={p.description}
                        extra={[
                          <ClaimsList key="list" claims={p.subscriptionClaims} />,
                          <Button
                            key="button"
                            className="w-full mt-8"
                            type="primary"
                            color={isUserSubscription ? "green" : undefined}
                            loading={submittingSubscription[p.priceId] || (isUserSubscription && loadingPanelUrl)}
                            disabled={hasSubscription && !isUserSubscription}
                            onClick={isUserSubscription ? createBillingSession : createCheckoutSession(p.priceId)}
                          >
                            {isUserSubscription ?
                              <FormattedMessage id="subscriptions.details" defaultMessage="Szczegóły" />
                              :
                              <FormattedMessage id="subscriptions.buy" defaultMessage="Kup" />
                            }
                          </Button>
                        ]}
                      />
                    </div>
                  </Card>
                </Badge.Ribbon>
              </Col>
            );
          })}
        </Row>
      </PageContent>
    </div>
  );
};

export default SubscriptionsPage;