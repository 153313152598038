import { DotId, dotToLinkedPoints, PointId } from "core/point/point.enums";
import { allTeeths, toothById } from "core/tooth/tooth.const";
import { inRange } from "core/utils/number-utils";
import { PointsSortOrder } from "../point/point.sort";
import { Quadrant } from "./tooth.model";

export const getArch = (point: PointId) => toothById[point].arch;

export const getAllTeethsInBetween = (a: PointId, b: PointId): PointId[] => {
  if (toothById[a].arch !== toothById[b].arch) {
    return [];
  }

  const teeths = allTeeths.filter((t) => isInBetween(a, b, t.id)).map((t) => t.id);

  return teeths;
};

export const isInBetween = (start: PointId, end: PointId, pointId: PointId) => {
  const startTooth = toothById[start];
  const endTooth = toothById[end];
  const currentTooth = toothById[pointId];

  if (startTooth.arch !== endTooth.arch || startTooth.arch !== currentTooth.arch) {
    return false;
  }

  return inRange(startTooth.universalIndex, endTooth.universalIndex, currentTooth.universalIndex);
};

export const isDotInBetween = (start: PointId, end: PointId, dot: DotId) => {
  const startTooth = toothById[start];
  const endTooth = toothById[end];
  const [dotStart, dotEnd] = dotToLinkedPoints[dot];
  const dotToothStart = toothById[dotStart];
  const dotToothEnd = toothById[dotEnd];

  if (
    startTooth.arch !== endTooth.arch ||
    startTooth.arch !== dotToothStart.arch ||
    startTooth.arch !== dotToothEnd.arch
  ) {
    return false;
  }

  return (
    inRange(startTooth.universalIndex, endTooth.universalIndex, dotToothStart.universalIndex) &&
    inRange(startTooth.universalIndex, endTooth.universalIndex, dotToothEnd.universalIndex)
  );
};

export const getNextTooth = (t: PointId, order: PointsSortOrder): PointId | null => {
  if (order === PointsSortOrder.Clockwise) {
    if (t === PointId.P28) {
      return null;
    } else if (t === PointId.P11) {
      return PointId.P21;
    } else if (t === PointId.P31) {
      return PointId.P41;
    } else if (t === PointId.P48) {
      return null;
    }

    if (toothById[t].quadrant === Quadrant.UpperLeft) {
      return (t - 1) as PointId;
    } else if (toothById[t].quadrant === Quadrant.UpperRight) {
      return (t + 1) as PointId;
    } else if (toothById[t].quadrant === Quadrant.LowerRight) {
      return (t - 1) as PointId;
    } else if (toothById[t].quadrant === Quadrant.LowerLeft) {
      return (t + 1) as PointId;
    } else {
      throw new Error("invalid tooth quadrant");
    }
  } else if (order === PointsSortOrder.Reading) {
    if (t === PointId.P28) {
      return null;
    } else if (t === PointId.P11) {
      return PointId.P21;
    } else if (t === PointId.P41) {
      return PointId.P31;
    } else if (t === PointId.P38) {
      return null;
    }

    if (toothById[t].quadrant === Quadrant.UpperLeft) {
      return (t - 1) as PointId;
    } else if (toothById[t].quadrant === Quadrant.UpperRight) {
      return (t + 1) as PointId;
    } else if (toothById[t].quadrant === Quadrant.LowerRight) {
      return (t + 1) as PointId;
    } else if (toothById[t].quadrant === Quadrant.LowerLeft) {
      return (t - 1) as PointId;
    } else {
      throw new Error("invalid tooth quadrant");
    }
  } else {
    throw new Error("invalid sort order");
  }
};

export const createIsToothSelectedDictionary = (selectedTeeth: PointId[]): Record<PointId, boolean> => {
  return selectedTeeth.reduce(
    (acc, curr) => {
      acc[curr] = true;
      return acc;
    },
    allTeeths.reduce((acc, curr) => {
      acc[curr.id] = false;
      return acc;
    }, {} as Record<PointId, boolean>)
  );
};
