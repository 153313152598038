import * as React from "react";
import { useIntl } from "react-intl";
import { historyEventFormatter } from "./history-event-formatter";
import { HistoryEventPayloadDTO } from "features/orders/contracts/order-history.dto";

type IHistoryEventFormatterProps = {
  event: HistoryEventPayloadDTO;
  children: (renderProps: { description: React.ReactNode; change: React.ReactNode }) => React.ReactNode;
};

const HistoryEventFormatter: React.FunctionComponent<IHistoryEventFormatterProps> = (props) => {
  const intl = useIntl();

  if (props.event === undefined || props.event === null) {
    return null;
  }

  const eventFormattedParts = historyEventFormatter(intl, props.event);
  return <>{props.children(eventFormattedParts)}</>;
};

export default HistoryEventFormatter;
