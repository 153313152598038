export enum HonorificCode {
  None = 1,
  Mr = 2,
  Ms = 3,
  Mrs = 4,
  Dr = 5,
}

export enum Honorific {
  None = "none",
  Mr = "mr",
  Ms = "ms",
  Mrs = "mrs",
  Dr = "dr",
}
