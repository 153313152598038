import React from "react";
import { Form} from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import CountrySelect from "components/forms/country-select/CountrySelect";

export const PatientCountryControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={<FormattedMessage id="common.country" defaultMessage="Kraj" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="patientCountry"
      control={props.control}
      render={({ field: { value, ...rest } }) => (
        <CountrySelect
          value={value === null ? undefined : value}
          {...rest}
          className="w--full"
          allowClear={true}
        />
      )}
    />
  </Form.Item>
);
