import { IPointSequence } from "./point-sequence.model";
import { PointId } from "core/point/point.enums";
import { areNeighbouring } from "core/point/point.functions";

type SubSequence = { start: PointId; end: PointId };

const formatPointSequence = (pointSequence: SubSequence): string => {
  if (pointSequence.start === pointSequence.end) {
    return `${pointSequence.start}`;
  } else if (areNeighbouring(pointSequence.start, pointSequence.end)) {
    return `${pointSequence.start},${pointSequence.end}`;
  } else {
    return `${pointSequence.start}-${pointSequence.end}`;
  }
};

export const formatPointsSequences = (pointSequences: IPointSequence[]): string => {
  const normalizedPointSequences = pointSequences.reduce((acc, curr) => {
    if (curr.duplicated.length === 0) {
      const { start, end } = curr;
      const subSequence: SubSequence = { start, end };
      acc.push(subSequence);
      return acc;
    }

    const array = [curr.start, ...curr.duplicated, curr.end];

    const subSequences = array.reduce((acc, curr, idx) => {
      if (idx === 0) {
        return acc;
      }

      const subSequence: SubSequence = { start: array[idx - 1], end: array[idx] };
      acc.push(subSequence);

      return acc;
    }, [] as SubSequence[]);

    for (let i = 0; i < subSequences.length; i++) {
      acc.push(subSequences[i]);
    }

    return acc;
  }, [] as SubSequence[]);

  return "(" + normalizedPointSequences.map((ps) => formatPointSequence(ps)).join(",") + ")";
};
