import { invalidArgumentError } from "core/errors/generate-error";

import { toothById } from "core/tooth/tooth.const";
import { PointId } from "core/point/point.enums";

export enum PointsSortOrder {
  Reading = "reading-order",
  Clockwise = "clockwise/universal-order",
}

export const sortPoints = (order: PointsSortOrder) => {
  if (order !== PointsSortOrder.Reading && order !== PointsSortOrder.Clockwise) {
    throw invalidArgumentError(
      `order should be either: (${PointsSortOrder.Reading}) or (${PointsSortOrder.Clockwise})`
    );
  }

  if (order === PointsSortOrder.Reading) {
    return sortPointsInReadingOrder;
  } else {
    return sortPointsInClockwiseOrder;
  }
};

const sortPointsInReadingOrder = (a: PointId, b: PointId) => {
  return toothById[a].readingSortValue - toothById[b].readingSortValue;
};

const sortPointsInClockwiseOrder = (a: PointId, b: PointId) => {
  return toothById[a].universalIndex - toothById[b].universalIndex;
};
