import { IDentistDTO } from "features/dentist/contracts/dentsist.dto";
import { IDentist } from "features/dentist/dentist.model";
import { honorificCodeToHonorific } from "core/person-name/honorific.functions";
import { NewPricingListId } from "features/pricing-lists/pricing-list.models";

export const mapDentistDTOtoDentist = (dto: IDentistDTO): IDentist => ({
  id: { type: "dentist-id", value: dto.id },
  clinicName: dto.clinicName,
  name: {
    firstName: dto.name.firstName,
    lastName: dto.name.lastName,
    honorific: honorificCodeToHonorific(dto.name.honorific),
  },
  pricingListId: NewPricingListId(dto.pricingListId),
});
