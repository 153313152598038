import { SpecificationType } from "features/orders/specification/order-specification";
import { IOrderCategoryId } from "features/catalogue/category.model";
import { IValueObject } from "core/value-object/value-object";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

export interface IOrderTypeId extends IValueObject<string> {
  type: "order-type-id";
}

export const NewOrderTypeId = (id: string): IOrderTypeId => ({ type: "order-type-id", value: id });

export interface IOrderType {
  categoryId: IOrderCategoryId;
  id: IOrderTypeId;
  name: string;
  code: OrderCodeEnum | null;
  isActive: boolean;
  isArchived: boolean;
  requiredSpecification: SpecificationType;
}

export interface IShortOrderType extends Pick<IOrderType, "id" | "name" | "code" | "requiredSpecification"> {}
