import { IResult } from "./result";
import DomainError, { IDomainError } from "../../errors/domain-error";
import { Err } from "./error";
import { Ok } from "./ok";

function whenEvery<T1, TE = IDomainError>(
  results: [IResult<T1, TE>]
): IResult<[T1], (TE | null)[]>;
function whenEvery<T1, T2, TE = IDomainError>(
  results: [IResult<T1, TE>, IResult<T2, TE>]
): IResult<[T1, T2], (TE | null)[]>;
function whenEvery<T1, T2, T3, TE = IDomainError>(
  results: [IResult<T1, TE>, IResult<T2, TE>, IResult<T3, TE>]
): IResult<[T1, T2, T3], (TE | null)[]>;
function whenEvery<T1, T2, T3, T4, TE = IDomainError>(
  results: [IResult<T1, TE>, IResult<T2, TE>, IResult<T3, TE>, IResult<T4, TE>]
): IResult<[T1, T2, T3, T4], (TE | null)[]>;
function whenEvery<T1, T2, T3, T4, T5, TE = IDomainError>(
  results: [IResult<T1, TE>, IResult<T2, TE>, IResult<T3, TE>, IResult<T4, TE>, IResult<T5, TE>]
): IResult<[T1, T2, T3, T4], (TE | null)[]>;

function whenEvery(results: IResult<any, any>[]): any {
  let errors: (IResult<any, DomainError> | null)[] = [];
  let oks: (IResult<any, DomainError> | null)[] = [];

  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    const isError = result.isErr();

    if (isError) {
      errors.push(result);
      oks.push(null);
    } else {
      errors.push(null);
      oks.push(result);
    }
  }

  if (errors.some((err) => err !== null)) {
    return new Err<never, (DomainError | null)[]>(errors.map((err) => (err === null ? null : err.err().unwrap())));
  }

  return new Ok<any[]>(oks.map((ok) => ok?.ok().unwrap()) as any) as any;
}

export { whenEvery }
