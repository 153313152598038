import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./auth-page-layout.module.scss";
import Logo, { LogoType } from "components/logo/Logo";

interface IAuthPageLayoutProps {}

export const AuthPageLayout: React.FC<IAuthPageLayoutProps> = (props) => (
  <>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <Link to="/">
                <Logo type={LogoType.FullLogoOnLightBackground} />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.main}>{props.children}</div>
      </div>
    </div>
  </>
);

export const AuthEmptyPageLayout: React.FC<IAuthPageLayoutProps> = (props) => (
  <>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={"max-w-xl m-auto"}>{props.children}</div>
      </div>
    </div>
  </>
);
