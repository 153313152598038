import { IAddress } from "core/address/address.model";
import { IAddressDTO } from "contracts/common-dtos/address.dto";
import {
  mapCountryToCountryCode,
  mapCountryCodeToCountry,
} from "core/countries/countries.functions";

export const mapAddressToAddressDTO = (address: IAddress): IAddressDTO => {
  return {
    line1: address.addressLine1,
    line2: address.addressLine2,
    line3: address.addressLine3,
    line4: address.addressLine4,
    postalCode: address.postalCode,
    country: mapCountryToCountryCode(address.country),
  };
};

export const mapAddressDtoToAddress = (address: IAddressDTO): IAddress => {
  return {
    addressLine1: address.line1,
    addressLine2: address.line2,
    addressLine3: address.line3,
    addressLine4: address.line4,
    postalCode: address.postalCode,
    country: mapCountryCodeToCountry(address.country),
  };
};
