import { IResult } from "core/lib/types/result";
import { ISubscription, ISubscriptionProduct } from "./models/subscriptions.model";
import { IHttpService } from "core/http/http.service";
import { IAuthService } from "../auth/auth.service";
import { Err } from "core/lib/types/error";
import { ErrorCode } from "core/errors/error-code";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { getDomainError, isErrorResponse } from "core/http/http.functions";
import { Ok } from "core/lib/types/ok";
import IApiResponseEnvelope from "../../contracts/envelope/api-response-envelope";
import DomainError, { IDomainError } from "../../core/errors/domain-error";

export interface ISubscriptionsService {
  getSubscription: () => Promise<IResult<ISubscription>>;
  getSubscriptionProducts: () => Promise<IResult<ISubscriptionProduct[]>>;
  createCheckoutSession: (priceId: string) => Promise<IResult<string>>; // url for redirect
  createBillingSession: () => Promise<IResult<string>>; // url for redirect
}

class SubscriptionsService implements ISubscriptionsService {
  private readonly httpService: IHttpService;
  private readonly authService: IAuthService;

  constructor(httpService: IHttpService, authService: IAuthService) {
    this.httpService = httpService;
    this.authService = authService;
  }

  getSubscription = async () => {
    const { isLoggedIn } = await this.authService.getTenantId();

    if (!isLoggedIn) {
      return new Err(new DomainError(ErrorCode.InternalError, "not authenticated"));
    }

    const response = await this.httpService.get<IApiResponseEnvelope<ISubscription>>(ApiEndpointPath.GetSubscriptions);

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err(getDomainError(body));
    }

    return new Ok<ISubscription, IDomainError>(body.result);
  };

  getSubscriptionProducts = async () => {
    const { isLoggedIn } = await this.authService.getTenantId();

    if (!isLoggedIn) {
      return new Err(new DomainError(ErrorCode.InternalError, "not authenticated"));
    }

    const response = await this.httpService.get<IApiResponseEnvelope<ISubscriptionProduct[]>>(
      ApiEndpointPath.GetSubscriptionProducts,
    );

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err(getDomainError(body));
    }

    return new Ok<ISubscriptionProduct[], IDomainError>(body.result);
  };

  createCheckoutSession = async (priceId: string) => {
    const { isLoggedIn } = await this.authService.getTenantId();

    if (!isLoggedIn) {
      return new Err(new DomainError(ErrorCode.InternalError, "not authenticated"));
    }

    const response = await this.httpService.post<IApiResponseEnvelope<string>, any>(ApiEndpointPath.CreateCheckoutSession, {
      priceId,
    });

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err(getDomainError(body));
    }

    return new Ok<string, IDomainError>(body.result);
  };

  createBillingSession = async () => {
    const { isLoggedIn } = await this.authService.getTenantId();

    if (!isLoggedIn) {
      return new Err(new DomainError(ErrorCode.InternalError, "not authenticated"));
    }

    const response = await this.httpService.post<IApiResponseEnvelope<string>, any>(ApiEndpointPath.CreateBillingSession);

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err(getDomainError(body));
    }

    return new Ok<string, IDomainError>(body.result);
  };
}

export default SubscriptionsService;
