import { mapDentistDTOtoDentist } from "features/dentist/contracts/dentsist.dto.mappers";
import {
  IAssignDentistToPriceListRequest,
  ICreateDentistRequest,
  IDeleteDentistRequest,
  IDentist,
  IDentistBillingInfo,
  IDentistId,
  IEditDentistRequest,
} from "features/dentist/dentist.model";
import { IDentistApi } from "./IDentistApi";
import { IResult } from "core/lib/types/result";
import { honorificToHonorificCode } from "core/person-name/honorific.functions";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import {
  AddBillingInfoRequestDTO,
  CreateDentistRequestDTO,
  DeleteDentistRequestDTO,
  EditDentistRequestDTO,
} from "../contracts/dentist.requests.dto";
import { IJsonApiService } from "core/http/json-api.service";
import IApiResponseEnvelope from "../../../contracts/envelope/api-response-envelope";
import { IDentistBillingInfoDTO, IDentistDTO } from "../contracts/dentsist.dto";
import { mapBillingInfoDTOToBillingInfo } from "core/billing-info/billing-info.functions";
import { IBillingInfoValue } from "core/billing-info/billing-info";
import { mapTaxIdToTaxIdDTO } from "core/taxes/taxes.functions";
import { mapAddressToAddressDTO } from "contracts/common-dtos/dto.functions";

const mapDentistBillingDTO = (result: IDentistBillingInfoDTO): IDentistBillingInfo => {
  const { defaultBillingInfo, localBillingInfos, remoteBillingInfos } = result;

  return {
    defaultBillingInfo: defaultBillingInfo === null ? null : mapBillingInfoDTOToBillingInfo(defaultBillingInfo),
    localBillingInfos: localBillingInfos.map((dto) => mapBillingInfoDTOToBillingInfo(dto)),
    remoteBillingInfos: remoteBillingInfos.map((dto) => mapBillingInfoDTOToBillingInfo(dto)),
  };
};

class DentistHttpApi implements IDentistApi {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  getDentists = async (): Promise<IResult<IDentist[]>> => {
    const response = await this.jsonApi.get<IApiResponseEnvelope<IDentistDTO[]>>(({ tenantId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetAllDentists,
        params: { tenantId: tenantId!.value },
      })
    );

    return response.map((resp) => resp.data.result.map(mapDentistDTOtoDentist));
  };

  createDentist = async (request: ICreateDentistRequest): Promise<IResult<IDentistId>> => {
    const { name, clinicName } = request;

    const requestDTO: CreateDentistRequestDTO = {
      dentistName: {
        firstName: name.firstName,
        lastName: name.lastName,
        honorific: honorificToHonorificCode(name.honorific),
      },
      clinicName: clinicName,
    };

    const response = await this.jsonApi.post<IApiResponseEnvelope<IDentistDTO>, CreateDentistRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.CreateDentistEndpoint,
          params: { tenantId: tenantId!.value },
        }),
      requestDTO
    );

    return response.map((resp) => ({
      type: "dentist-id",
      value: resp.data.result.id,
    }));
  };

  editDentist = async (request: IEditDentistRequest): Promise<IResult<IDentistId>> => {
    const { id, name, clinicName } = request;

    const requestDTO: EditDentistRequestDTO = {
      dentistId: id.value,
      dentistName: {
        firstName: name.firstName,
        lastName: name.lastName,
        honorific: honorificToHonorificCode(name.honorific),
      },
      clinicName: clinicName,
    };

    const response = await this.jsonApi.post<IApiResponseEnvelope<IDentistDTO>, CreateDentistRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({
          params: { tenantId: tenantId!.value },
          path: ApiEndpointPath.EditDentistEndpoint,
        }),
      requestDTO
    );

    return response.map((resp) => ({
      type: "dentist-id",
      value: resp.data.result.id,
    }));
  };

  assignDentistToPriceList = async (request: IAssignDentistToPriceListRequest): Promise<IResult<IDentist>> => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<IDentistDTO>,
      { dentistId: string; pricingListId: string }
    >(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.AssignDentistToPricingList,
          params: { tenantId: labId!.value },
        }),
      { dentistId: request.id.value, pricingListId: request.pricingListId.value }
    );

    return response.map((resp) => mapDentistDTOtoDentist(resp.data.result));
  };

  deleteDentist = async (request: IDeleteDentistRequest): Promise<IResult<IDentistId>> => {
    const { id } = request;

    const response = await this.jsonApi.post<IApiResponseEnvelope<void>, DeleteDentistRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({ params: { tenantId: tenantId!.value }, path: ApiEndpointPath.DeleteDentistEndpoint }),
      { dentistId: id.value }
    );

    return response.map(() => id);
  };

  getBillingInfo = async (dentistId: IDentistId): Promise<IResult<IDentistBillingInfo>> => {
    const response = await this.jsonApi.get<IApiResponseEnvelope<IDentistBillingInfoDTO>>(({ tenantId }) =>
      parametrizeEndpointPath({
        params: { tenantId: tenantId!.value, dentistId: dentistId.value },
        path: ApiEndpointPath.GetDentistsBillingInfo,
      })
    );

    return response.map((body) => mapDentistBillingDTO(body.data.result));
  };

  addBillingInfo = async (
    dentistId: IDentistId,
    billingInfo: IBillingInfoValue
  ): Promise<IResult<IDentistBillingInfo>> => {
    const { address, companyName, taxId } = billingInfo;

    const response = await this.jsonApi.post<IApiResponseEnvelope<IDentistBillingInfoDTO>, AddBillingInfoRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({
          params: { tenantId: tenantId!.value },
          path: ApiEndpointPath.AddDentistsBillingInfo,
        }),
      {
        dentistId: dentistId.value,
        companyName: companyName,
        taxId: mapTaxIdToTaxIdDTO(taxId),
        address: mapAddressToAddressDTO(address),
        isDefault: true,
      }
    );

    return response.map((body) => {
      return mapDentistBillingDTO(body.data.result);
    });
  };
}

export default DentistHttpApi;
