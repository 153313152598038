import * as React from "react";
import { useState } from "react";
import { List, Radio, Space, Typography } from "antd";
import { SettingsSection } from "../../settings-section/SettingsSection";
import { FormattedMessage } from "react-intl";
import useSettingsSection from "../../settings-section/useSettingsSection";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { PaymentDueDateCode } from "../../../contracts/settings.dto";

export const PaymentDueDateFormatter: React.FunctionComponent<{
  value: PaymentDueDateCode;
}> = ({ value }) => {
  switch (value) {
    case PaymentDueDateCode.InZeroDays:
      return (
        <FormattedMessage
          id={"settings.payment-in-0-days"}
          defaultMessage={"Termin płatności taki sam jak data wystawienia faktury"}
        />
      );
    case PaymentDueDateCode.In1Days:
      return (
        <FormattedMessage
          id={"settings.payment-in-1-days"}
          defaultMessage={"Termin płatności jeden dzień później niż data wystawienia faktury"}
        />
      );
    case PaymentDueDateCode.In7Days:
      return (
        <FormattedMessage
          id={"settings.payment-in-7-days"}
          defaultMessage={"Termin płatności 7-dni później niż data wystawienia faktury"}
        />
      );
    case PaymentDueDateCode.In14Days:
      return (
        <FormattedMessage
          id={"settings.payment-in-14-days"}
          defaultMessage={"Termin płatności 14-dni później niż data wystawienia faktury"}
        />
      );
    case PaymentDueDateCode.In21Days:
      return (
        <FormattedMessage
          id={"settings.payment-in-21-days"}
          defaultMessage={"Termin płatności 21-dni później niż data wystawienia faktury"}
        />
      );
    case PaymentDueDateCode.In30Days:
      return (
        <FormattedMessage
          id={"settings.payment-in-30-days"}
          defaultMessage={"Termin płatności 30-dni później niż data wystawienia faktury"}
        />
      );
    case PaymentDueDateCode.In60Days:
      return (
        <FormattedMessage
          id={"settings.payment-in-60-days"}
          defaultMessage={"Termin płatności 60-dni później niż data wystawienia faktury"}
        />
      );
  }
};

const paymentDueDateOptions: PaymentDueDateCode[] = [
  PaymentDueDateCode.InZeroDays,
  PaymentDueDateCode.In1Days,
  PaymentDueDateCode.In7Days,
  PaymentDueDateCode.In14Days,
  PaymentDueDateCode.In21Days,
  PaymentDueDateCode.In30Days,
  PaymentDueDateCode.In60Days,
];

export const PaymentDueDateSection: React.FunctionComponent<{
  value: PaymentDueDateCode;
  onChange: (value: PaymentDueDateCode) => Promise<void>;
}> = ({ value: prevValue, onChange }) => {
  const { isEditing, onEdit, onCancel } = useSettingsSection();
  const [currValue, setCurrValue] = useState(prevValue);

  const handleSave = React.useCallback(async () => {
    if (prevValue === currValue) {
      onCancel();
      return;
    }

    await onChange(currValue);
    onCancel();
  }, [prevValue, currValue, onChange, onCancel]);

  return (
    <SettingsSection isEditing={isEditing} onEdit={onEdit} onCancel={onCancel} onSave={handleSave}>
      <>
        {!isEditing && (
          <List.Item.Meta
            title={<FormattedMessage id="invoicing.payment-due-date" defaultMessage="Domyślny termin płatności" />}
            description={
              <Typography.Text type={"secondary"}>
                <PaymentDueDateFormatter value={prevValue} />
              </Typography.Text>
            }
          />
        )}
        {isEditing && (
          <Radio.Group value={currValue} onChange={(e: RadioChangeEvent) => setCurrValue(e.target.value)}>
            <Space direction="vertical">
              {paymentDueDateOptions.map((option) => (
                <Radio value={option}>
                  <Typography.Text>
                    <PaymentDueDateFormatter value={option} />
                  </Typography.Text>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      </>
    </SettingsSection>
  );
};
