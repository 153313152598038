import * as React from 'react';
import { message } from "antd";
import ToastMessages from 'components/message/ToastMessages';

interface ILocalizedToastMessages {

}

const LocalizedToastMessages: React.FunctionComponent<ILocalizedToastMessages> = (props) => {
    const [messageApi, messageContext] = message.useMessage();

    return (
        <ToastMessages.Provider value={{ messageApi }}>
            <>{messageContext}</>
            <>{props.children}</>
        </ToastMessages.Provider>
    );
};

export default LocalizedToastMessages;
