import * as React from "react";
import { QuantitySpecification, SpecificationType } from "../../../specification/order-specification";
import { InputNumber } from "antd";
import { safeIntParse } from "core/utils/number-utils";

interface IOrderEntrySpecificationProps {
  specification: QuantitySpecification;
  onChange?: (specification: QuantitySpecification) => void;
}

const QuantitySpecificationControls: React.FunctionComponent<IOrderEntrySpecificationProps> = ({
  specification,
  onChange,
}) => {
  const handleChange = React.useCallback(
    (val: any) => {
      const { value, isNumber } = safeIntParse(val);
      const quantity = !isNumber ? 1 : value;
      console.info("val: ", { val, value, isNumber, quantity });

      onChange?.({ quantity: quantity, specificationType: SpecificationType.Quantity });
    },
    [onChange]
  );

  return (
    <div className="quantity-specification-controls">
      <InputNumber size="large" value={specification.quantity} min={1} max={100} onChange={handleChange} />
    </div>
  );
};

export default QuantitySpecificationControls;
