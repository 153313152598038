import { IBillingStatementId, ILineItem } from "../models/invoicing.models";
import useSWR from "swr";
import * as React from "react";
import InvoicingApiContext from "../invoice.context";
import {
  IDownloadOrderListRequest,




} from "../models/invoicing.action-models";
import { nameof } from "ts-simple-nameof";
import { IInvoicingApi } from "features/invoicing/invoice.service";

function useBillingStatement(statementId: IBillingStatementId) {
  const api = React.useContext(InvoicingApiContext);

  const {
    data: getBillingStatementResponse,
    isValidating: isLoading,
    mutate,
  } = useSWR([nameof<IInvoicingApi>((_) => _.getBillingStatement), statementId.value], () =>
    api.getBillingStatement(statementId)
  );

  const changeLineItems = React.useCallback(
    async (lines: ILineItem[]) => {
      const result = await api.updateBillingStatementLines({ id: statementId, lines });

      if (result.isErr()) {
        console.warn("error while changing invoice line items");
        return;
      }

      await mutate(result, false);
    },
    [api, statementId, mutate]
  );

  const deleteBillingStatement = React.useCallback(
    async () => await api.removeBillingStatement(statementId),
    [api, statementId]
  );

  const downloadBillingStatement = React.useCallback(
    async (request: IDownloadOrderListRequest) => {
      return api.downloadBillingStatement(request);
    },
    [api]
  );

  return {
    getBillingStatementResponse: getBillingStatementResponse,
    isLoading: isLoading,
    changeLineItems,
    deleteBillingStatement,
    downloadBillingStatement,
  };
}

export default useBillingStatement;
