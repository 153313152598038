import { ReactNode } from "react";
import { Space, SpaceProps } from "antd";

export type LabeledValueProps = {
  label: ReactNode;
  value: ReactNode;
  direction?: SpaceProps["direction"];
  size?: SpaceProps["size"];
};

export const LabeledValue = ({ label, value, direction, size }: LabeledValueProps) => (
  <Space direction={direction ?? "vertical"} size={size ?? 0}>
    <label className="text-xs text-black text-opacity-50 uppercase whitespace-nowrap">{label}</label>
    <span className="font-bold">{value}</span>
  </Space>
);
