import * as React from "react";
import { JSXElementConstructor, ReactElement } from "react";

interface IActionButtonsProps {
  originalButton: React.ReactNode;
}

const LocalizedActionButton: React.FunctionComponent<IActionButtonsProps> = ({ originalButton, children }) => (
  <>
    {React.cloneElement(originalButton as ReactElement<any, string | JSXElementConstructor<any>>, {
      saveText: "Zapisz",
      cancelText: "Anuluj",
      deleteText: "Usuń",
      children: children,
    })}
  </>
);

export default LocalizedActionButton;
