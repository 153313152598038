import * as React from "react";
import { Button, Form } from "antd";
import { FormProps } from "antd/lib/form";
import { FormattedMessage, useIntl } from "react-intl";
import { nameof } from "ts-simple-nameof";
import InputPassword from "../../../../components/forms/input/InputPassword";
import { IResult } from "core/lib/types/result";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";

interface INewPasswordFormProps {
  onSubmit: (submitValues: ISubmitValues) => Promise<IResult<void>>;
}

interface IFormValues {
  readonly password: string;
}

interface ISubmitValues {
  readonly password: string;
}

const formNames = {
  password: nameof<IFormValues>((x) => x.password),
};

const NewPasswordForm: React.FC<INewPasswordFormProps> = ({ onSubmit }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { flag: isLoading, check: startLoading, uncheck: finishLoading } = useBooleanFlag(false);

  const onFinishFailed: FormProps["onFinishFailed"] = ({ errorFields }) => {
    form.scrollToField(errorFields[0]?.name);
  };

  const onFinish: FormProps["onFinish"] = React.useCallback(async (store: IFormValues) => {
    const { password } = store;
    startLoading();

    const submitResult = await onSubmit({ password });

    if (submitResult.isErr()) {
      const err = submitResult.err();
      console.error("setting new password error: ", err);
    }

    finishLoading();
  }, [finishLoading, onSubmit, startLoading]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"vertical"}
      >
        <Form.Item
          name={formNames.password}
          rules={[
            {
              required: true,
              message: <FormattedMessage id="common.password-is-required" defaultMessage="Podaj hasło" />,
            },
          ]}
        >
          <InputPassword
            placeholder={intl.formatMessage({
              id: "new-password-form.password",
              defaultMessage: "Hasło",
            })}
            autoComplete={"stop"}
          />
        </Form.Item>
        
        <Button htmlType={"submit"} type="primary" block loading={isLoading}>
          <FormattedMessage id="new-password-form.change-password" defaultMessage="Zmień hasło" />
        </Button>
      </Form>
    </>
  );
};

export default NewPasswordForm;

export type { ISubmitValues as INewPasswordFormSubmitValues };
