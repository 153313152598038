import * as React from "react";

import logoPath1 from "./dentimate-transp-white-text.png";
import logoPath2 from "./dentimate-transp-black-text_w_400.png";
import logoPath3 from "./dentimate-icon_w_80.png";
import logoPath4 from "./dentimate-white-text-only.png";

export enum LogoType {
  IconOnlyLogo,
  OnlyWhiteCompanyName,
  FullLogoOnDarkBackground,
  FullLogoOnLightBackground,
}

interface ILogoProps extends Omit<React.HTMLAttributes<HTMLImageElement>, "src"> {
  type?: LogoType;
}

const Logo: React.FC<ILogoProps> = ({ type = LogoType.FullLogoOnLightBackground, ...imgProps }) => {
  switch (type) {
    case LogoType.IconOnlyLogo:
      return <img src={logoPath3} alt={"dentimate"} {...imgProps} />;
    case LogoType.OnlyWhiteCompanyName:
      return <img src={logoPath4} alt={"dentimate"} {...imgProps} />;
    case LogoType.FullLogoOnDarkBackground: {
      return <img src={logoPath1} alt={"dentimate"} {...imgProps} />;
    }
    case LogoType.FullLogoOnLightBackground: {
      return <img src={logoPath2} alt={"dentimate"} {...imgProps} />;
    }
  }
};

export default Logo;
