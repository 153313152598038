import { IPricingListApi } from "features/pricing-lists/service/IPricingListApi";
import { IJsonApiService } from "core/http/json-api.service";
import {
  ICreatePricingListRequest,
  IDeletePricingListRequest,
  IEditPricingListRequest,
  IPricingEntry,
  IPricingList,
  IPricingListId,
  IShortPricingList,
  IUpdatePricingEntryRequest,
  NewPricingListId,
} from "features/pricing-lists/pricing-list.models";
import { IResult } from "core/lib/types/result";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import IApiResponseEnvelope from "contracts/envelope/api-response-envelope";
import {
  IPricingListWithDentistsDTO,
  IShortPricingListDTO,
} from "features/pricing-lists/contracts/pricing-list.dto";
import { mapPricingDTOtoPricing, mapPricingToPricingDTO } from "features/orders/contracts/pricing.dto.mappers";
import { NewOrderTypeId } from "features/catalogue/order-type.model";
import { mapPricingListDTOtoPricingList } from "features/pricing-lists/contracts/pricing-list.mapper";
import { IDentistId } from "features/dentist/dentist.model";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { IUserId } from "features/auth/auth.model";

class PricingListApi implements IPricingListApi {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  get = async (id: IPricingListId): Promise<IResult<IPricingList>> => {
    const result = await this.jsonApi.get<IApiResponseEnvelope<IPricingListWithDentistsDTO>>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.PricingList_Get,
        params: { pricingListId: id.value, labId: labId!.value },
      })
    );

    return result.map((body) => mapPricingListDTOtoPricingList(body.data.result));
  };

  getForDentist = async (id: IDentistId): Promise<IResult<IPricingList>> => {
    const result = await this.jsonApi.get<IApiResponseEnvelope<IPricingListWithDentistsDTO>>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.PricingList_GetForLabDentist,
        params: { labId: labId!.value, dentistId: id.value },
      })
    );

    return result.map((body) => mapPricingListDTOtoPricingList(body.data.result));
  };

  getAll = async (): Promise<IResult<IPricingList[]>> => {
    const result = await this.jsonApi.get<IApiResponseEnvelope<IPricingListWithDentistsDTO[]>>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.PricingList_GetAll,
        params: { labId: labId!.value },
      })
    );

    return result.map((body) => body.data.result.map((dto) => mapPricingListDTOtoPricingList(dto)));
  };

  getDefault = async (): Promise<IResult<IPricingList>> => {
    const result = await this.jsonApi.get<IApiResponseEnvelope<IPricingListWithDentistsDTO>>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.PricingList_GetDefault,
        params: { labId: labId!.value },
      })
    );

    return result.map((body) => mapPricingListDTOtoPricingList(body.data.result));
  };

  create = async (request: ICreatePricingListRequest): Promise<IResult<void>> => {
    const result = await this.jsonApi.post<IApiResponseEnvelope<IPricingListWithDentistsDTO>, { name: string }>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.PricingList_Create,
          params: { labId: labId!.value },
        }),
      { name: request.name }
    );

    return result.map((_) => undefined);
  };

  edit = async (request: IEditPricingListRequest): Promise<IResult<void>> => {
    const result = await this.jsonApi.post<IApiResponseEnvelope<IPricingListWithDentistsDTO>, { name: string }>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.PricingList_Edit,
          params: { labId: labId!.value, pricingListId: request.id.value },
        }),
      { name: request.name }
    );

    return result.map((_) => undefined);
  };

  delete = async (request: IDeletePricingListRequest): Promise<IResult<void>> => {
    const result = await this.jsonApi.post<IApiResponseEnvelope<IPricingListWithDentistsDTO>, void>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.PricingList_Delete,
        params: { labId: labId!.value, pricingListId: request.id.value },
      })
    );

    return result.map((_) => undefined);
  };

  setPricingEntry = async (request: IUpdatePricingEntryRequest): Promise<IResult<void>> => {
    const result = await this.jsonApi.post<IApiResponseEnvelope<any>, any>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.PricingList_SetPrice,
          params: { labId: labId!.value, pricingListId: request.pricingListId.value },
        }),
      {
        orderTypeId: request.orderTypeId.value,
        pricing: mapPricingToPricingDTO(request.pricing),
      }
    );

    return result.map((_) => undefined);
  };

  getForLab = async (userId: IUserId, labId: ILabId): Promise<IResult<IShortPricingList>> => {
    const url = parametrizeEndpointPath({
      path: ApiEndpointPath.PricingList_GetForLab,
      params: { userId: userId.value, labId: labId.value },
    });

    const result = await this.jsonApi.get<IApiResponseEnvelope<IShortPricingListDTO>>(url);

    return result.map((response) => {
      const model: IShortPricingList = {
        id: NewPricingListId(response.data.result.id),
        entries: response.data.result.pricingEntries.map((x) => {
          const pricingEntry: IPricingEntry = {
            orderTypeId: NewOrderTypeId(x.orderTypeId),
            pricing: mapPricingDTOtoPricing(x.pricing),
          };

          return pricingEntry;
        }),
      };

      return model;
    });
  };
}

export default PricingListApi;
