import { IResult } from "core/lib/types/result";
import { IJsonApiService } from "core/http/json-api.service";
import { IOrderFilesApi } from "features/orders/service/order-files.service";
import {
  IDeleteOrderFile,
  IFinalizeFileUploadRequest,
  IGetOrderFilesRequest,
  IInitializeFileUploadRequest,
} from "features/orders/requests/order-files.requests";
import { DeleteFileResponseDTO, GetOrderFilesResponseDTO } from "features/orders/contracts/orders.response.dto";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { IFinalizedUploadResponse, ISignedUploadLinkResponse } from "../requests/order-files.responses";
import { ISODateTimeString } from "core/time/time.model";
import IApiResponseEnvelope from "contracts/envelope/api-response-envelope";
import { IOrderFile, NewFileId } from "features/orders/order-files.model";

class OrderFilesHttpService implements IOrderFilesApi {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  getOrderFiles = async (request: IGetOrderFilesRequest): Promise<IResult<IOrderFile[]>> => {
    const response = await this.jsonApi.get<GetOrderFilesResponseDTO>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.OrderFiles_GetOrderFiles,
        params: { orderId: request.orderId.value },
      })
    );

    return response.map((body) =>
      body.data.result.files.map((x) => ({
        extension: x.extension,
        fileId: NewFileId(x.fileId),
        expiration: x.expiration,
        url: x.url,
        name: x.name,
      }))
    );
  };

  deleteOrderFile = async (request: IDeleteOrderFile): Promise<IResult<Pick<IOrderFile, "fileId">>> => {
    const response = await this.jsonApi.post<DeleteFileResponseDTO, void>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.OrderFiles_DeleteOrderFile,
        params: { orderId: request.orderId.value, fileId: request.fileId.value },
      })
    );

    return response.map((body) => ({ fileId: NewFileId(body.data.result.fileId) }));
  };

  initializeFileUpload = async (request: IInitializeFileUploadRequest): Promise<IResult<ISignedUploadLinkResponse>> => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<{ url: string; expiration: ISODateTimeString; fileId: string }>,
      {
        fileName: string;
        fileSize: number;
      }
    >(
      parametrizeEndpointPath({
        path: ApiEndpointPath.OrderFiles_InitializeFileUpload,
        params: { orderId: request.orderId.value },
      }),
      {
        fileName: request.fileName,
        fileSize: request.fileSize,
      }
    );

    return response.map((body) => ({
      url: body.data.result.url,
      expiration: body.data.result.expiration,
      fileId: NewFileId(body.data.result.fileId),
    }));
  };

  finalizeFileUpload = async (request: IFinalizeFileUploadRequest): Promise<IResult<IFinalizedUploadResponse>> => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<{ url: string | null; expiration: ISODateTimeString | null }>,
      { fileId: string; success: boolean }
    >(
      parametrizeEndpointPath({
        path: ApiEndpointPath.OrderFiles_FinalizeFileUpload,
        params: { orderId: request.orderId.value },
      }),
      { fileId: request.fileId.value, success: request.success }
    );

    return response.map((body) => ({ url: body.data.result.url, expiration: body.data.result.expiration }));
  };
}

export default OrderFilesHttpService;
