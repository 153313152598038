import { IMoney } from "core/money/money.model";
import { ISODateTimeString } from "core/time/time.model";
import { IValueObject } from "core/value-object/value-object";
import { IEmployee } from "features/employee/employee.model";
import { OrderSpecification } from "features/orders/specification/order-specification";
import { Status } from "./status/order-status.model";
import { IDentist } from "features/dentist/dentist.model";
import { Patient } from "features/orders/patient.model";
import { IOrderTypeId } from "features/catalogue/order-type.model";
import { IBillingStatementId } from "../invoicing/models/invoicing.models";
import { ColorOrNone } from "./color/color.model";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { IOrderFile } from "features/orders/order-files.model";

export interface IOrderItemId extends IValueObject<string> {
  type: "order-item-id";
}

export interface IOrderId extends IValueObject<string> {
  type: "order-id";
}

export const NewOrderId = (id: string): IOrderId => ({ type: "order-id", value: id });

export enum SpecificationTypeCode {
  TextOnly = 1,
  Quantity = 2,
  Arches = 3,
  LocatedPoints = 4,
  Crowns = 5,
  Bridges = 6,
  MarylandBridges = 7,
  Foundations = 8,
  PartialDentures = 9,
  SurgicalGuides = 10,
}

export interface IOrderItem {
  readonly id: IOrderItemId;
  readonly orderTypeId: IOrderTypeId;
  readonly totalPrice: IMoney; // TODO: rename to price
  readonly specification: OrderSpecification;
}

// export interface IOrderFile {
//   contentType: "image/png" | string;
//   fileKey: string; // "4e9919ab-0d9d-4bfa-806d-00f3b3dd8b12";
//   fileName: string; // "test-image-5.png";
//   fileSize: number; // 2549;
// }

export enum Reader {
  Lab = 1,
  Dentist = 2,
}

interface IBaseOrderReadModel {
  readonly orderId: IOrderId;
  readonly labId: ILabId;
  readonly patient: Patient;
  readonly startDate: ISODateTimeString | null;
  readonly dueDate: ISODateTimeString | null;
  readonly finishDate: ISODateTimeString | null;
  readonly status: Status;
  readonly orderItems: IOrderItem[];
  readonly orderNote: string | null;
  readonly color: ColorOrNone;
}

export interface ILabOrderReadModel extends IBaseOrderReadModel {
  readonly dentist: Omit<IDentist, "pricingListId">;
  readonly orderNumber: number;
  readonly billingStatement: { billingStatementId: IBillingStatementId; billingStatementNumber: number } | null;
  readonly assignedEmployees: IEmployee[];
}

export interface IDentistOrderReadModel extends IBaseOrderReadModel {
  readonly labName: string;
}

export type UnifiedOrderReadModel =
  | ({ readonly reader: Reader.Lab } & ILabOrderReadModel)
  | ({ readonly reader: Reader.Dentist } & IDentistOrderReadModel);

export interface ISimpleOrder {
  readonly orderId: IOrderId;
  readonly orderNumber: number;
  readonly status: Status;
}

export type OrderCommentsListType = {
  comments: OrderCommentType[];
};

export type OrderCommentType = {
  author: string;
  files: IOrderFile[];
  text: string;
  timestamp: string;
  type: string;
};

export type UploadFileType = {
  fileResults: FileResultsType[];
};

type FileResultsType = {
  Error?: ErrorFileResultType;
  IsFailure: boolean;
  IsSuccess: boolean;
  Value?: string;
};

type ErrorFileResultType = {
  code: string;
  name: string;
  message: string;
};

export type OrderNumberAvailability = {
  isAvailable: boolean;
  isTakenBy: null | { orderId: IOrderId };
};

export type CheckOrderNumberAvailabilityRequest = {
  orderNumber: number;
};

