import React from "react";
import useSWR from "swr";
import useAuth from "features/auth/hooks/useAuth";
import { nameof } from "ts-simple-nameof";
import OrderFilesApiContext from "features/orders/order-file.context";
import { IOrderFilesApi } from "features/orders/service/order-files.service";
import { IOrderId } from "features/orders/order.model";
import { IFileId } from "features/orders/order-files.model";

function useOrderFiles(orderId: IOrderId) {
  const orderFilesApi = React.useContext(OrderFilesApiContext);
  const { authContext } = useAuth();

  const {
    data: getOrderFiles,
    isValidating,
    mutate,
  } = useSWR(
    [
      `method: ${nameof<IOrderFilesApi>((x) => x.getOrderFiles)}`,
      `orderId: ${orderId.value}`,
      `userId: ${authContext.userId!.value}`,
    ],
    () => orderFilesApi.getOrderFiles({ orderId: orderId })
  );

  const deleteOrderFile = (fileId: IFileId) => orderFilesApi.deleteOrderFile({ orderId: orderId, fileId: fileId });

  return {
    getOrderFiles,
    deleteOrderFile,
    isLoading: isValidating,
    refresh: mutate,
  };
}

export default useOrderFiles;
