import React from "react";
import { FormattedMessage } from "react-intl";
import { Breadcrumb, PageHeader, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import ErrorScreen from "components/errors/ErrorScreen";
import { Money } from "core/money/money.functions";
import { useNavigate } from "react-router-dom";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { Path } from "core/routes/routes";
import OrderForm from "features/orders/components/order-form/OrderForm";
import OrderApiContext from "features/orders/order.context";
import useDentists from "features/dentist/hooks/useDentists";
import { ICreateLabOrderRequest } from "features/orders/requests/orders.requests";
import useOrdersCatalogue from "features/orders/hooks/useOrdersCatalogue";
import { mapSpecificationToSpecificationDto } from "features/orders/contracts/orders.dto.mappers";
import { IDentistId } from "features/dentist/dentist.model";
import { Ok } from "core/lib/types/ok";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { whenEvery } from "core/lib/types/result.utils";
import {
  FormMode,
  ICreateLabOrderSubmitValues,
  LoadPricingListForDentistFn,
} from "features/orders/components/order-form/order-form.model";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";

export interface ICreateOrderViewProps {
  labId: ILabId;
}

const CreateLabOrderView: React.FunctionComponent<ICreateOrderViewProps> = ({ labId }) => {
  const pricingListApi = React.useContext(PricingListApiContext);
  const navigate = useNavigate();

  const { getOrdersCatalogue } = useOrdersCatalogue({ mode: "lab" });
  const { getAllDentistsResponse } = useDentists();
  const ordersApi = React.useContext(OrderApiContext);

  const handleIsOrderNumberTaken = React.useCallback(
    (orderNumber: number) => ordersApi.isOrderNumberTaken({ orderNumber: orderNumber }),
    [ordersApi]
  );

  const handleLoadPricingListForDentist: LoadPricingListForDentistFn = React.useCallback(
    (labId: ILabId, dentistId: IDentistId) => pricingListApi.getForDentist(dentistId),
    [pricingListApi]
  );

  const handleCreateOrder = React.useCallback(
    async (values: ICreateLabOrderSubmitValues) => {
      const request: ICreateLabOrderRequest = {
        dentistId: values.dentistId,
        patient: {
          age: values.patientAge,
          patientCode: values.patientCode,
          gender: values.patientGender,
          lastName: values.patientLastName,
          firstName: values.patientFirstName,
          country: values.patientCountry,
        },
        orderNumber: values.orderNumber,
        orderNote: values.orderNote,
        startDate: values.startDate,
        dueDate: values.dueDate,
        orderColor: values.orderColor,
        orderItems: values.orderEntries.map((orderEntry) => ({
          orderTypeId: orderEntry.orderType.id.value,
          specification: mapSpecificationToSpecificationDto(orderEntry.specification),
          price: orderEntry.price === null ? Money.PLN(0) : orderEntry.price,
        })),
      };

      const result = await ordersApi.createLabOrder(request);

      if (result.isOk()) {
        navigate(parametrizePath({ path: Path.OrderDetails, params: { orderId: result.unwrap().value } }));
      }

      return result;
    },
    [navigate, ordersApi]
  );

  if (getOrdersCatalogue === undefined || getAllDentistsResponse === undefined) {
    return <Spin spinning={true} />;
  }

  if (getOrdersCatalogue.isErr()) {
    return <ErrorScreen error={getOrdersCatalogue.err().unwrap()} />;
  }

  if (getAllDentistsResponse.isErr()) {
    return <ErrorScreen error={getAllDentistsResponse.err().unwrap()} />;
  }

  return (
    <div className={"w-full"}>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.OrdersList />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.CreateOrder />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="create-order-wizard.create-new-oder" defaultMessage="Stwórz nowe zamówienie" />}
        />
      </HeaderContent>
      {whenEvery([getOrdersCatalogue, getAllDentistsResponse])
        .map(([ordersCatalogue, dentists]) => (
          <OrderForm
            mode={FormMode.LabCreate}
            labId={labId}
            dentists={dentists}
            onLoadOrderCatalogue={async (_: ILabId) => new Ok(ordersCatalogue)}
            isOrderNumberTakenCheck={handleIsOrderNumberTaken}
            onLoadPricingListForDentist={handleLoadPricingListForDentist}
            onSubmit={handleCreateOrder}
          />
        ))
        .unwrap()}
    </div>
  );
};

export default CreateLabOrderView;
