import { ITaxIdDTO } from "contracts/common-dtos/tax-id.dto";
import { IAddressDTO } from "contracts/common-dtos/address.dto";
import { guid } from "core/guid/guid";

export enum InvoiceNumberingSchemeCode {
  NONE = 1,
  YEAR_MONTH_INV_MONTH_DASHED,
  YEAR_MONTH_INV_YEAR_DASHED,
  YEAR_MONTH_INV_MONTH_FORWARD_SLASHED,
  YEAR_MONTH_INV_YEAR_FORWARD_SLASHED,
}

export enum PaymentDueDateCode {
  InZeroDays = 1,
  In1Days,
  In7Days,
  In14Days,
  In21Days,
  In30Days,
  In60Days,
}

export interface IInvoiceNumberingSchemeDTO {
  invoiceNumberingScheme: IInvoiceNumberingSchemeDTO;
}

export interface IIPaymentDueDateDTO {
  paymentDueDate: PaymentDueDateCode;
}

export interface IInvoiceIssuerDataSetDTO {
  id: guid;
  dataSetName: string;
  isDefault: boolean;
  companyName: string;
  taxId: ITaxIdDTO;
  address: IAddressDTO;
}

export interface IInvoicingSettingsDTO {
  paymentDueDate: PaymentDueDateCode;
  invoiceNumberingScheme: InvoiceNumberingSchemeCode;
  invoiceIssuerDataSets: IInvoiceIssuerDataSetDTO[];
}
