import { Country, CountryCode } from "core/countries/countries.model";
import { invalidArgumentError, argumentOutOfRangeError } from "core/errors/generate-error";

export const mapCountryCodeToCountry = (countryCode: CountryCode) => {
  switch (countryCode) {
    case CountryCode.AUT: {
      return Country.Austria;
    }
    case CountryCode.BEL: {
      return Country.Belgium;
    }
    case CountryCode.BGR: {
      return Country.Bulgaria;
    }
    case CountryCode.HRV: {
      return Country.Croatia;
    }
    case CountryCode.CYP: {
      return Country.Cyprus;
    }
    case CountryCode.DNK: {
      return Country.Denmark;
    }
    case CountryCode.EST: {
      return Country.Estonia;
    }
    case CountryCode.FIN: {
      return Country.Finland;
    }
    case CountryCode.FRA: {
      return Country.France;
    }
    case CountryCode.DEU: {
      return Country.Germany;
    }
    case CountryCode.GRC: {
      return Country.Greece;
    }
    case CountryCode.HUN: {
      return Country.Hungary;
    }
    case CountryCode.IRL: {
      return Country.Ireland;
    }
    case CountryCode.ITA: {
      return Country.Italy;
    }
    case CountryCode.LVA: {
      return Country.Latvia;
    }
    case CountryCode.LTU: {
      return Country.Lithuania;
    }
    case CountryCode.LUX: {
      return Country.Luxembourg;
    }
    case CountryCode.MLT: {
      return Country.Malta;
    }
    case CountryCode.NLD: {
      return Country.Netherlands;
    }
    case CountryCode.POL: {
      return Country.Poland;
    }
    case CountryCode.PRT: {
      return Country.Portugal;
    }
    case CountryCode.ROU: {
      return Country.Romania;
    }
    case CountryCode.SVK: {
      return Country.Slovakia;
    }
    case CountryCode.SVN: {
      return Country.Slovenia;
    }
    case CountryCode.ESP: {
      return Country.Spain;
    }
    case CountryCode.SWE: {
      return Country.Sweden;
    }
    case CountryCode.CZE: {
      return Country.CzechRepublic;
    }
    case CountryCode.GBR: {
      return Country.UnitedKingdom;
    }
    default:
      throw argumentOutOfRangeError(`country`, countryCode);
  }
};

export const mapCountryToCountryCode = (country: Country): CountryCode => {
  switch (country) {
    case Country.Austria: {
      return CountryCode.AUT;
    }
    case Country.Belgium: {
      return CountryCode.BEL;
    }
    case Country.Bulgaria: {
      return CountryCode.BGR;
    }
    case Country.Croatia: {
      return CountryCode.HRV;
    }
    case Country.Cyprus: {
      return CountryCode.CYP;
    }
    case Country.Denmark: {
      return CountryCode.DNK;
    }
    case Country.Estonia: {
      return CountryCode.EST;
    }
    case Country.Finland: {
      return CountryCode.FIN;
    }
    case Country.France: {
      return CountryCode.FRA;
    }
    case Country.Germany: {
      return CountryCode.DEU;
    }
    case Country.Greece: {
      return CountryCode.GRC;
    }
    case Country.Hungary: {
      return CountryCode.HUN;
    }
    case Country.Ireland: {
      return CountryCode.IRL;
    }
    case Country.Italy: {
      return CountryCode.ITA;
    }
    case Country.Latvia: {
      return CountryCode.LVA;
    }
    case Country.Lithuania: {
      return CountryCode.LTU;
    }
    case Country.Luxembourg: {
      return CountryCode.LUX;
    }
    case Country.Malta: {
      return CountryCode.MLT;
    }
    case Country.Netherlands: {
      return CountryCode.NLD;
    }
    case Country.Poland: {
      return CountryCode.POL;
    }
    case Country.Portugal: {
      return CountryCode.PRT;
    }
    case Country.Romania: {
      return CountryCode.ROU;
    }
    case Country.Slovakia: {
      return CountryCode.SVK;
    }
    case Country.Slovenia: {
      return CountryCode.SVN;
    }
    case Country.Spain: {
      return CountryCode.ESP;
    }
    case Country.Sweden: {
      return CountryCode.SWE;
    }
    case Country.CzechRepublic: {
      return CountryCode.CZE;
    }
    case Country.UnitedKingdom: {
      return CountryCode.GBR;
    }
    default:
      throw argumentOutOfRangeError(`country`, country);
  }
};

export const mapCountryToCountryName = (country: Country) => {
  switch (country) {
    case Country.Austria: {
      return "Austria";
    }
    case Country.Belgium: {
      return "Belgium";
    }
    case Country.Bulgaria: {
      return "Bulgaria";
    }
    case Country.Cyprus: {
      return "Cyprus";
    }
    case Country.CzechRepublic: {
      return "CzechRepublic";
    }
    case Country.Germany: {
      return "Germany";
    }
    case Country.Denmark: {
      return "Denmark";
    }
    case Country.Estonia: {
      return "Estonia";
    }
    case Country.Spain: {
      return "Spain";
    }
    case Country.Finland: {
      return "Finland";
    }
    case Country.France: {
      return "France";
    }
    case Country.UnitedKingdom: {
      return "UnitedKingdom";
    }
    case Country.Greece: {
      return "Greece";
    }
    case Country.Hungary: {
      return "Hungary";
    }
    case Country.Croatia: {
      return "Croatia";
    }
    case Country.Ireland: {
      return "Ireland";
    }
    case Country.Italy: {
      return "Italy";
    }
    case Country.Lithuania: {
      return "Lithuania";
    }
    case Country.Luxembourg: {
      return "Luxembourg";
    }
    case Country.Latvia: {
      return "Latvia";
    }
    case Country.Malta: {
      return "Malta";
    }
    case Country.Netherlands: {
      return "Netherlands";
    }
    case Country.Poland: {
      return "Poland";
    }
    case Country.Portugal: {
      return "Portugal";
    }
    case Country.Romania: {
      return "Romania";
    }
    case Country.Sweden: {
      return "Sweden";
    }
    case Country.Slovenia: {
      return "Slovenia";
    }
    case Country.Slovakia: {
      return "Slovakia";
    }
    default: {
      throw invalidArgumentError(
        "invalid country code passed to country name formatter component"
      );
    }
  }
};
