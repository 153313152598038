import * as React from "react";
import { Form, Modal, Radio, Rate } from "antd";

import { largeLabelsFormLayout } from "components/forms/form.layouts";
import { FormProps } from "antd/lib/form";
import Button from "components/button/Button";
import useAccess from "features/access/useAccess";
import { FormattedMessage } from "react-intl";
import { Bite, Color, PreparationMargin, TangentialPoint, Translucency } from "features/orders/feedback/feedback.model";
import useBooleanFlag from "hooks/useBooleanFlag";

export interface IOrderFeedbackFormOwnProps {
  isModalVisible: boolean;
  initialValues?: IOrderFeedbackFormValues;
  onSubmit: (feedback: IOrderFeedbackFormValues) => Promise<void>;
  onCancel: () => void;
}

export interface IOrderFeedbackFormValues {
  dentistRating: number | null;
  preparationMargin: PreparationMargin;
  bite: Bite;
  tangentialPoint: TangentialPoint;
  color: Color;
  translucency: Translucency;
}

const propertyNames: Record<keyof IOrderFeedbackFormValues, keyof IOrderFeedbackFormValues> = {
  preparationMargin: "preparationMargin",
  bite: "bite",
  dentistRating: "dentistRating",
  translucency: "translucency",
  tangentialPoint: "tangentialPoint",
  color: "color",
};

const OrderFeedbackModalForm: React.FunctionComponent<IOrderFeedbackFormOwnProps> = ({
  isModalVisible,
  initialValues = {
    bite: Bite.Good,
    color: Color.Good,
    dentistRating: null,
    preparationMargin: PreparationMargin.Good,
    tangentialPoint: TangentialPoint.Good,
    translucency: Translucency.Good,
  },
  onSubmit,
  onCancel,
}) => {
  const { isDentist } = useAccess();
  const [form] = Form.useForm();
  const formId = "order-feedback-modal-form";
  const { flag: submitInProgress, check: start, uncheck: finish } = useBooleanFlag(false);

  const onFinish: FormProps["onFinish"] = async (values: IOrderFeedbackFormValues) => {
    start();

    try {
      await onSubmit(values);
    } finally {
      finish();
    }
  };

  const onFinishFailed: FormProps["onFinishFailed"] = ({ errorFields }) => {
    form.scrollToField(errorFields[0]?.name);
  };

  return (
    <Modal
      visible={isModalVisible}
      title={<FormattedMessage id="order-feedback.order-feedback-modal-title" defaultMessage="Ocena zamówienia" />}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"order-feedback.order-feedback-modal.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit" loading={submitInProgress} disabled={submitInProgress}>
          <FormattedMessage id={"order-feedback.order-feedback-modal.save-button-text"} defaultMessage={"Zapisz"} />
        </Button>,
      ]}
      width={640}
    >
      <Form
        {...{
          ...largeLabelsFormLayout.itemLayout,
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          },
        }}
        id={formId}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        {isDentist && (
          <Form.Item
            name={propertyNames.dentistRating}
            rules={[
              {
                min: 1,
                max: 5,
                type: "number",
                message: (
                  <FormattedMessage
                    id={"order-feedback.dentist-rating-is-missing"}
                    defaultMessage={"Proszę podaj ocenę"}
                  />
                ),
              },
            ]}
            label={
              <span>
                <FormattedMessage id="order-feedback.dentist-rating" defaultMessage="Ocena od lekarza dentysty" />
              </span>
            }
          >
            <Rate />
          </Form.Item>
        )}
        <Form.Item
          name={propertyNames.preparationMargin}
          label={
            <span>
              <FormattedMessage id="order-feedback.preparation-margin" defaultMessage="Granica preparacji" />
            </span>
          }
        >
          <Radio.Group>
            <Radio value={PreparationMargin.Good}>
              <FormattedMessage id="order-feedback.preparation-margin.good" defaultMessage="szczelne" />
            </Radio>
            <Radio value={PreparationMargin.Bad}>
              <FormattedMessage id="order-feedback.preparation-margin.bad" defaultMessage="nieszczelne" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={propertyNames.bite}
          label={
            <span>
              <FormattedMessage id="order-feedback.bite" defaultMessage="Zgryz" />
            </span>
          }
        >
          <Radio.Group>
            <Radio value={Bite.Good}>
              <FormattedMessage id="order-feedback.bite.good" defaultMessage="ok" />
            </Radio>
            <Radio value={Bite.TooHigh}>
              <FormattedMessage id="order-feedback.bite.too-high" defaultMessage="za wysoko" />
            </Radio>
            <Radio value={Bite.TooLow}>
              <FormattedMessage id="order-feedback.bite.too-low" defaultMessage="za nisko" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={propertyNames.tangentialPoint}
          label={
            <span>
              <FormattedMessage id="order-feedback.tangential-point" defaultMessage="Punkt styczny" />
            </span>
          }
        >
          <Radio.Group>
            <Radio value={TangentialPoint.Good}>
              <FormattedMessage id="order-feedback.tangential-point.good" defaultMessage="ok" />
            </Radio>
            <Radio value={TangentialPoint.Tight}>
              <FormattedMessage id="order-feedback.tangential-point.tight" defaultMessage="za ciasny" />
            </Radio>
            <Radio value={TangentialPoint.None}>
              <FormattedMessage id="order-feedback.tangential-point.none" defaultMessage="brak" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={propertyNames.color}
          label={
            <span>
              <FormattedMessage id="order-feedback.color" defaultMessage="Kolor" />
            </span>
          }
        >
          <Radio.Group>
            <Radio value={Color.Good}>
              <FormattedMessage id="order-feedback.color.good" defaultMessage="ok" />
            </Radio>
            <Radio value={Color.TooBright}>
              <FormattedMessage id="order-feedback.color.too-bright" defaultMessage="za jasny" />
            </Radio>
            <Radio value={Color.TooDark}>
              <FormattedMessage id="order-feedback.color.too-dark" defaultMessage="za ciemny" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={propertyNames.translucency}
          label={
            <span>
              <FormattedMessage id="order-feedback.translucency" defaultMessage="Przezierność" />
            </span>
          }
        >
          <Radio.Group>
            <Radio value={Translucency.Good}>
              <FormattedMessage id="order-feedback.translucency.good" defaultMessage="ok" />
            </Radio>
            <Radio value={Translucency.TooTranslucent}>
              <FormattedMessage
                id="order-feedback.translucency.too-translucent"
                defaultMessage="za bardzo przezierne"
              />
            </Radio>
            <Radio value={Translucency.NotEnoughTranslucent}>
              <FormattedMessage id="order-feedback.translucency.not-enough-translucent" defaultMessage="opakerowe" />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderFeedbackModalForm;
