import { Option } from "./option";
import { Err } from "./error";
import { IResult } from "./result";

export class None<T> implements Option<T> {
  map<U>(fn: (a: T) => U): Option<U> {
    return None._instance as Option<U>;
  }
  isSome(): boolean {
    return false;
  }
  isNone(): boolean {
    return true;
  }
  isSomeAnd(fn: (a: T) => boolean): boolean {
    return false;
  }
  isNoneAnd(fn: () => boolean): boolean {
    return fn();
  }
  unwrap(): T {
    console.error("None.unwrap()");
    throw new Error("None.get");
  }
  unwrapOr(def: T): T {
    return def;
  }
  unwrapOrElse(f: () => T): T {
    return f();
  }
  mapOr<U>(def: U, f: (a: T) => U): U {
    return def;
  }
  mapOrElse<U>(def: () => U, f: (arg: T) => U): U {
    return def();
  }
  okOr<E>(err: E): IResult<T, E> {
    return new Err<T, E>(err);
  }
  okOrElse<E>(err: () => E): IResult<T, E> {
    return new Err<T, E>(err());
  }
  and<U>(optb: Option<U>): Option<U> {
    return None.instance<U>();
  }
  andThen<U>(f: (a: T) => Option<U>): Option<U> {
    return None.instance<U>();
  }
  or(optb: Option<T>): Option<T> {
    return optb;
  }
  orElse(f: () => Option<T>): Option<T> {
    return f();
  }
  private static _instance: Option<any> = new None();

  public static instance<X>(): Option<X> {
    return None._instance as Option<X>;
  }

  public toString(): string {
    return "None";
  }
}
