import * as React from "react";
import OrderEntry, { IOrderEntryProps } from "./OrderEntry";
import { OrderSpecification } from "../../specification/order-specification";
import { isEmptySpecification } from "../../specification/order-specification.functions";
import { computePrice } from "../../../catalogue/prices/prices.calculator";
import { IOrderEntry } from "features/orders/components/order-entry/order-entry.model";
import { IPricingEntry } from "features/pricing-lists/pricing-list.models";
import { AlphabeticCharacter } from "features/orders/components/letter-indicator/letter.model";

const OrderEntryEdit: React.FunctionComponent<
  {
    disableEdit?: boolean;
    pricingEntry?: IPricingEntry;
    letter?: AlphabeticCharacter;
    updatePriceOnPricingChange: boolean;
    onChange: (entry: IOrderEntry) => void;
  } & Omit<IOrderEntryProps, "onChange">
> = ({ pricingEntry, value, disableEdit = false, updatePriceOnPricingChange = true, onChange, onDelete, ...props }) => {
  const computePriceHandler = React.useCallback(
    (specification: OrderSpecification) => {
      if (isEmptySpecification(specification)) {
        return null;
      }

      if (pricingEntry === undefined || pricingEntry === null) {
        return null;
      }

      return computePrice(pricingEntry.pricing, specification);
    },
    [pricingEntry]
  );

  return (
    <OrderEntry
      value={value!}
      onChange={disableEdit === true ? undefined : onChange!}
      onDelete={disableEdit === true ? undefined : onDelete}
      onComputePrice={computePriceHandler}
      {...props}
    />
  );
};

export default OrderEntryEdit;
