import * as React from "react";
import useSWR from "swr";
import { nameof } from "ts-simple-nameof";
import { EmployeeServiceContext, IEmployeeService } from "../services/employee.service";
import useAuth from "../../auth/hooks/useAuth";
import { ICreateEmployeeRequest, IDeleteEmployeeRequest, IEditEmployeeRequest } from "../employee.model";

function useEmployees() {
  const { isLoggedIn, authContext } = useAuth();
  const employeeApi = React.useContext(EmployeeServiceContext);

  const {
    data: getAllEmployeesResponse,
    isValidating: isValidating,
    mutate: revalidate,
  } = useSWR(
    () =>
      !isLoggedIn
        ? null
        : [authContext.userId, authContext.tenantId?.value, nameof<IEmployeeService>((x) => x.getEmployees)],
    async () => {
      const result = await employeeApi.getEmployees();
      return result.map((employees) => employees.filter((e) => !e.isDeleted));
    }
  );

  const createEmployee = React.useCallback(
    async (request: ICreateEmployeeRequest) => employeeApi.createEmployee(request),
    [employeeApi]
  );

  const editEmployee = React.useCallback(
    async (request: IEditEmployeeRequest) => employeeApi.editEmployee(request),
    [employeeApi]
  );

  const deleteEmployee = React.useCallback(
    async (request: IDeleteEmployeeRequest) => employeeApi.deleteEmployee(request),
    [employeeApi]
  );

  return {
    getEmployeesResponse: getAllEmployeesResponse,
    isValidating: isValidating,
    createEmployee,
    deleteEmployee,
    editEmployee,
    revalidate: revalidate,
  };
}

export default useEmployees;
