import { CSSProperties, FunctionComponent } from "react";
import { kebabCase, round } from "lodash";

export function hexToRgba(hex: string, alpha: number = 1): string | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

    if (!result) {
        return hex;
    }

    const colorArray = [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
    ];

    let a = alpha;

    if (a === 1 && result[4]) {
        a = round(parseInt(result[4], 16) / 255, 2);
    }

    if (a === 1) {
        return `rgb(${colorArray.join(",")})`;
    }

    return `rgba(${colorArray.join(",")},${a})`;
}

export const getStyleFormatted = (style: CSSProperties, separator: string = ";") => {
    return Object.keys(style)
        .map((key) => {
            const keyFormatted = kebabCase(key);
            let value = (style as any)[key];

            if (
                typeof value === "number" &&
                /(width|height|margin|padding|font-size)/.test(keyFormatted)
            ) {
                value = value + "px";
            }

            return `${keyFormatted}: ${value}`;
        })
        .join(separator);
};

export const Style: FunctionComponent<{
    selector?: string;
    style?: CSSProperties | string;
}> = ({ selector, style }) => {
    const styleToUse =
        typeof style === "string"
            ? selector
                ? `${selector} { ${style} }`
                : style
            : style && selector
                ? `${selector} { ${getStyleFormatted(style)} }`
                : null;

    if (!styleToUse) {
        return null;
    }

    return <style type="text/css" dangerouslySetInnerHTML={{ __html: styleToUse }} />;
};
