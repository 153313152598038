import { PointsViewAnchors, PointsViewDots, PointsViewPoints } from "../../points-view/PointsView";
import { allDots, allTeeths } from "core/tooth/tooth.const";
import {
  DotId,
  dotToDotNeighbours,
  dotToExtendedLinkedPoints,
  dotToLinkedPoints,
  PointId,
} from "core/point/point.enums";
import { isDotInBetween } from "core/tooth/tooth.functions";
import { PointsSortOrder, sortPoints } from "core/point/point.sort";
import { PointsSelectionState } from "./PointSelection";
import { IAnchoredPoint, IPickedPoints } from "../../points-picker.model";

export const arePointsConnected = (dot: DotId, connPts: PointId[][]) => {
  const pointsRelatedToDot = dotToLinkedPoints[dot];
  const [p1, p2] = pointsRelatedToDot;
  return connPts.some((pts) => pts.includes(p1) && pts.includes(p2));
};

export const isNearestNeighbourPointSelected = (dot: DotId, points: PointsViewPoints) => {
  const pointsRelatedToDot = dotToLinkedPoints[dot];
  const [p1, p2] = pointsRelatedToDot;
  return points[p1]?.selected === true && points[p2]?.selected === true;
};

export const isNextNeighbourPointSelected = (dot: DotId, points: PointsViewPoints) => {
  const [p0, p1, p2, p3] = dotToExtendedLinkedPoints[dot];

  if (p0 === null || p1 === null || p2 === null || p3 === null) {
    return false;
  }

  if (points[p1]?.selected === true && points[p2]?.selected !== true) {
    return points[p3]?.selected === true;
  } else if (points[p1]?.selected !== true && points[p2]?.selected === true) {
    return points[p0]?.selected === true;
  }

  return false;
};

export const createDotsVisibilityMap = (points: PointsViewPoints): Record<DotId, boolean> => {
  return allDots.reduce((acc, dot) => {
    const isNearestNeighbourSelected = isNearestNeighbourPointSelected(dot, points);
    const isNextNeighbourSelected = isNextNeighbourPointSelected(dot, points);

    return {
      ...acc,
      [dot]: isNearestNeighbourSelected || isNextNeighbourSelected,
    };
  }, {} as Record<DotId, boolean>);
};

export const mapSingleAndLinkedPointsToPointsSelectionState = (data: IPickedPoints): PointsSelectionState => {
  const { linked, singles } = data;
  const allConnectedPoints = linked.flatMap((value) => value);
  const anchors: PointsViewAnchors = {};

  const points = allTeeths.reduce((acc, tooth) => {
    const selectedAsSingle = singles.some((x) => x.location === tooth.id);
    const selectedAsConnected = allConnectedPoints.some((x) => x.location === tooth.id);

    if (selectedAsSingle) {
      const single = singles.find(x => x.location === tooth.id);
      anchors[tooth.id] = single!.anchor === null ? undefined : single!.anchor;
    } else if (selectedAsConnected) {
      const connected = allConnectedPoints.find(x => x.location === tooth.id);
      anchors[tooth.id] = connected!.anchor === null ? undefined : connected!.anchor;
    }

    return {
      ...acc,
      [tooth.id]: { selected: selectedAsSingle || selectedAsConnected, labels: [] },
    };
  }, {} as PointsViewPoints);

  const dotsVisibilityMap = createDotsVisibilityMap(points);

  let allDotsWithVisibilityInfo = allDots.reduce((acc, curr) => ({
    ...acc,
    [curr]: { selected: false, visible: dotsVisibilityMap[curr] },
  }), {} as PointsViewDots);

  const dots = linked.reduce((acc, connectedPoints) => {
    const sortedPoints = connectedPoints.sort((p1, p2) =>
      sortPoints(PointsSortOrder.Reading)(p1.location, p2.location)
    );

    if (sortedPoints.length < 2) {
      return acc;
    }

    const firstPoint = sortedPoints[0];
    const lastPoint = sortedPoints[sortedPoints.length - 1];

    const connectedDots = allDots.reduce((acc, d) => {
      if (isDotInBetween(firstPoint.location, lastPoint.location, d)) {
        return { ...acc, [d]: { selected: true, visible: true } };
      }

      return acc;
    }, {} as PointsViewDots);

    return {
      ...acc,
      ...connectedDots,
    };
  }, allDotsWithVisibilityInfo as PointsViewDots);

  return {
    dots: dots,
    points: points,
    anchors: anchors,
  };
};

interface IDotSequence {
  start: DotId;
  end: DotId;
  seq: DotId[];
  count: number;
}

export const mapPointsSelectionStateToSingleAndLinkedPoints = (data: PointsSelectionState): IPickedPoints => {
  const { points, dots, anchors } = data;

  const isAtLeastOneDotSelected = !Object.entries(dots).some(([_, { selected }]) => selected);

  if (isAtLeastOneDotSelected) {
    const singles = Object.entries(points).reduce((acc, [point, { selected }]) => {
      const pointId = (point as unknown) as PointId;

      if (!selected) {
        return acc;
      }

      const anchor = anchors[pointId] === undefined || anchors[pointId] === null ? null : anchors[pointId];
      return [...acc, { location: +pointId, anchor: anchor }] as IAnchoredPoint[];
    }, [] as IAnchoredPoint[]);

    return {
      singles: singles,
      linked: [],
    };
  }

  const allSelectedPoints = Object.entries(points).reduce((acc, [point, { selected }]) => {
    const pointId = (point as unknown) as PointId;

    if (!selected) {
      return acc;
    }

    const anchor = anchors[pointId] === undefined || anchors[pointId] === null ? null : anchors[pointId];

    return [...acc, { location: +pointId, anchor: anchor }] as IAnchoredPoint[];
  }, [] as IAnchoredPoint[]);

  const dotSequences = allDots
    .filter((d) => dots[d]?.selected === true)
    .reduce((sequences, currDot, idx, all) => {
      if (idx === 0) {
        sequences.push({
          start: currDot,
          end: currDot,
          seq: [currDot],
          count: 1,
        });

        return sequences;
      }

      const prevDot = all[idx - 1];
      const currSeqIdx = sequences.length - 1;
      const { prev } = dotToDotNeighbours[currDot];

      if (prevDot == prev) {
        sequences[currSeqIdx] = {
          ...sequences[currSeqIdx],
          end: currDot,
          seq: [...sequences[currSeqIdx].seq, currDot],
          count: sequences[currSeqIdx].count,
        };

        return sequences;
      } else {
        sequences.push({
          start: currDot,
          end: currDot,
          seq: [currDot],
          count: 1,
        });

        return sequences;
      }

      return sequences;
    }, [] as IDotSequence[]);

  const linked = dotSequences
    .map((dotSequence) => {
      return dotSequence.seq.reduce((acc, dot) => {
        const [p1, p2] = dotToLinkedPoints[dot];

        if (points[p1]?.selected === true && !acc.some((x) => x.location === p1)) {
          const anchor = anchors[p1] === undefined || anchors[p1] === null ? null : anchors[p1];
          acc.push({ location: p1, anchor: anchor } as IAnchoredPoint);
        }

        if (points[p2]?.selected === true && !acc.some((x) => x.location === p2)) {
          const anchor = anchors[p2] === undefined || anchors[p2] === null ? null : anchors[p2];
          acc.push({ location: p2, anchor: anchor } as IAnchoredPoint);
        }

        return [...acc];
      }, [] as IAnchoredPoint[]);
    })
    .filter((linkedPoints) => linkedPoints.length >= 2);

  const allLinked = linked.flatMap((v) => v);
  const singles = allSelectedPoints.filter((p) => !allLinked.some((x) => x.location === p.location));

  return {
    singles: singles.sort((s1, s2) => sortPoints(PointsSortOrder.Reading)(s1.location, s2.location)),
    linked: linked,
  };
};
