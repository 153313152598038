import { IntlShape } from "react-intl";
import { argumentOutOfRangeError } from "core/errors/generate-error";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

export const orderCodeFormatter = (intl: IntlShape, orderType: { name: string; code: OrderCodeEnum | null }) => {
  const { name, code } = orderType;

  if (code === null) {
    return name;
  }

  switch (code) {
    case OrderCodeEnum.FullAcrylicDentures:
      return intl.formatMessage({
        id: "order-code.full-acrylic-dentures",
        defaultMessage: "Proteza całkowita akrylowa",
      });
    case OrderCodeEnum.FullAcetalDentures:
      return intl.formatMessage({
        id: "order-code.full-acetal-dentures",
        defaultMessage: "Proteza całkowita acetalowa",
      });
    case OrderCodeEnum.FullAcronDentures:
      return intl.formatMessage({ id: "order-code.full-acron-dentures", defaultMessage: "Proteza całkowita akronowa" });
    case OrderCodeEnum.FullNylonDentures:
      return intl.formatMessage({ id: "order-code.full-nylon-dentures", defaultMessage: "Proteza całkowita nylonowa" });
    case OrderCodeEnum.PartialAcrylicDentures:
      return intl.formatMessage({
        id: "order-code.partial-acrylic-dentures",
        defaultMessage: "Proteza całkowita akrylowa",
      });
    case OrderCodeEnum.PartialAcetalDentures:
      return intl.formatMessage({
        id: "order-code.partial-acetal-dentures",
        defaultMessage: "Proteza częściowa acetalowa",
      });
    case OrderCodeEnum.PartialAcronDentures:
      return intl.formatMessage({
        id: "order-code.partial-acron-dentures",
        defaultMessage: "Proteza częściowa akronowa",
      });
    case OrderCodeEnum.PartialNylonDentures:
      return intl.formatMessage({
        id: "order-code.partial-nylon-dentures",
        defaultMessage: "Proteza częściowa nylonowa",
      });
    case OrderCodeEnum.PrecisionAttachmentsPartialDenture:
      return intl.formatMessage({
        id: "order-code.precision-attachments-partial-denture",
        defaultMessage: "Proteza do elementów precyzyjnych",
      });
    case OrderCodeEnum.AcrylicOverdentures:
      return intl.formatMessage({
        id: "order-code.acrylic-overdentures",
        defaultMessage: "Proteza overdenture - akrylowa",
      });
    case OrderCodeEnum.CrCoMetalFrameworkClaspsPartialDentures:
      return intl.formatMessage({
        id: "order-code.cr-co-metal-framework-clasps-partial-dentures",
        defaultMessage: "Proteza szkieletowa klamrowa - CrCo",
      });
    case OrderCodeEnum.TitaniumAloyFrameworkClaspsPartialDentures:
      return intl.formatMessage({
        id: "order-code.titanium-aloy-framework-clasps-partial-dentures",
        defaultMessage: "Proteza szkieletowa klamrowa - tytanowa",
      });
    case OrderCodeEnum.PeekFrameworkClaspsPartialDentures:
      return intl.formatMessage({
        id: "order-code.peek-framework-clasps-partial-dentures",
        defaultMessage: "Proteza szkieletowa klamrowa - peek",
      });
    case OrderCodeEnum.CrCoMetalFrameworkContinuousClaspsPartialDentures:
      return intl.formatMessage({
        id: "order-code.cr-co-metal-framework-continuous-clasps-partial-dentures",
        defaultMessage: "Proteza szkieletowa z klamrą ciągłą - CrCo",
      });
    case OrderCodeEnum.TitaniumAloyFrameworkContinuousClaspsPartialDentures:
      return intl.formatMessage({
        id: "order-code.titanium-aloy-framework-continuous-clasps-partial-dentures",
        defaultMessage: "Proteza szkieletowa z klamrą ciągłą - tytanowa",
      });
    case OrderCodeEnum.PeekFrameworkContinuousClaspsPartialDentures:
      return intl.formatMessage({
        id: "order-code.peek-framework-continuous-clasps-partial-dentures",
        defaultMessage: "Proteza szkieletowa z klamrą ciągłą - peek",
      });
    case OrderCodeEnum.CrCoSplintDentures:
      return intl.formatMessage({ id: "order-code.cr-co-splint-dentures", defaultMessage: "Szynoproteza - CrCo" });
    case OrderCodeEnum.TitaniumAloyCrCoSplintDentures:
      return intl.formatMessage({
        id: "order-code.titanium-aloy-cr-co-splint-dentures",
        defaultMessage: "Szynoproteza - tytanowa",
      });
    case OrderCodeEnum.PeekCrCoSplintDentures:
      return intl.formatMessage({
        id: "order-code.peek-cr-co-splint-dentures",
        defaultMessage: "Szynoproteza - peek",
      });
    case OrderCodeEnum.InterlockAttachments:
      return intl.formatMessage({ id: "order-code.interlock-attachments", defaultMessage: "Interlock" });
    case OrderCodeEnum.RodAttachments:
      return intl.formatMessage({ id: "order-code.rod-attachments", defaultMessage: "Zasuwy" });
    case OrderCodeEnum.StudAttachments:
      return intl.formatMessage({ id: "order-code.stud-attachments", defaultMessage: "Zatrzaski" });
    case OrderCodeEnum.LatchAttachments:
      return intl.formatMessage({ id: "order-code.latch-attachments", defaultMessage: "Rygle" });
    case OrderCodeEnum.OcclusalShoulder:
      return intl.formatMessage({ id: "order-code.occlusal-shoulder", defaultMessage: "Podparcia" });
    case OrderCodeEnum.FracturedDentureService:
      return intl.formatMessage({
        id: "order-code.fractured-denture-service",
        defaultMessage: "Naprawa pękniętej protezy",
      });
    case OrderCodeEnum.DentureReinforcement:
      return intl.formatMessage({ id: "order-code.denture-reinforcement", defaultMessage: "Wzmocnienie protezy" });
    case OrderCodeEnum.MichiganSplint:
      return intl.formatMessage({ id: "order-code.michigan-splint", defaultMessage: "Szyna relaksacyjna Michigan" });
    case OrderCodeEnum.PrintedMichiganSplint:
      return intl.formatMessage({
        id: "order-code.printed-michigan-splint",
        defaultMessage: "Szyna relaksacyjna Michigan drukowana",
      });
    case OrderCodeEnum.KoisSplint: {
      return intl.formatMessage({ id: "order-code.kois-splint", defaultMessage: "Deprogramator koisa akrylowy" });
    }
    case OrderCodeEnum.WhiteningSplint: {
      return intl.formatMessage({ id: "order-code.withering-splint", defaultMessage: "Szyna wybielająca" });
    }
    case OrderCodeEnum.RelaxationSplint: {
      return intl.formatMessage({ id: "order-code.relaxation-splint", defaultMessage: "Szyna relaksacyjna tłoczona" });
    }
    case OrderCodeEnum.PrintedRelaxationSplint: {
      return intl.formatMessage({
        id: "order-code.printed-relaxation-splint",
        defaultMessage: "Szyna relaksacyjna drukowana",
      });
    }
    case OrderCodeEnum.WaxUp: {
      return intl.formatMessage({ id: "order-code.wax-up", defaultMessage: "Wax-Up" });
    }
    case OrderCodeEnum.PrintedWaxUp: {
      return intl.formatMessage({ id: "order-code.printed-wax-up", defaultMessage: "Wax-Up drukowany" });
    }
    case OrderCodeEnum.GlassCeramicOnlay: {
      return intl.formatMessage({ id: "order-code.glass-ceramic-onlay", defaultMessage: "Onlay z ceramiki szklanej" });
    }
    case OrderCodeEnum.CompositeOnlay: {
      return intl.formatMessage({ id: "order-code.composite-onlay", defaultMessage: "Onlay kompozytowy" });
    }
    case OrderCodeEnum.GlassCeramicInlay: {
      return intl.formatMessage({ id: "order-code.glass-ceramic-inlay", defaultMessage: "Inlay z ceramiki szklanej" });
    }
    case OrderCodeEnum.CompositeInlay: {
      return intl.formatMessage({ id: "order-code.composite-inlay", defaultMessage: "Inlay kompozytowy" });
    }
    case OrderCodeEnum.GlassCeramicOverlay: {
      return intl.formatMessage({
        id: "order-code.glass-ceramic-overlay",
        defaultMessage: "Overlay z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.CompositeOverlay: {
      return intl.formatMessage({ id: "order-code.composite-overlay", defaultMessage: "Overlay kompozytowy" });
    }
    case OrderCodeEnum.GlassCeramicEndoCrown: {
      return intl.formatMessage({
        id: "order-code.glass-ceramic-endo-crown",
        defaultMessage: "Endokorona z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.CompositeEndoCrown: {
      return intl.formatMessage({ id: "order-code.composite-endo-crown", defaultMessage: "Endokorona kompozytowa" });
    }
    case OrderCodeEnum.CrCoMoldedPostMultiRooted:
      return intl.formatMessage({
        id: "order-code.root-canal-inlay-casted-metal-multi-rooted",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co lany - wielo-korzeniowy",
      });
    case OrderCodeEnum.CrCoMoldedPost: {
      return intl.formatMessage({
        id: "order-code.root-canal-inlay-casted-metal",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co lany",
      });
    }
    case OrderCodeEnum.ZirconiumPostMultiRooted:
      return intl.formatMessage({
        id: "order-code.root-canal-inlay-casted-metal-multi-rooted",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co lany - wielo-korzeniowy",
      });
    case OrderCodeEnum.ZirconiumPost: {
      return intl.formatMessage({
        id: "order-code.zirconium-root-canal-inlay",
        defaultMessage: "Wkład koronowo-korzeniowy cyrkonowy",
      });
    }
    case OrderCodeEnum.FiberglassPostMultiRooted:
      return intl.formatMessage({
        id: "order-code.fiberglass-post-multi-rooted",
        defaultMessage: "Wkład koronowo-korzeniowy z włóknem szklanym - wielo-korzeniowy",
      });
    case OrderCodeEnum.FiberglassPost: {
      return intl.formatMessage({
        id: "order-code.fiberglass-post",
        defaultMessage: "Wkład koronowo-korzeniowy z włóknem szklanym",
      });
    }
    case OrderCodeEnum.PeekPostMultiRooted:
      return intl.formatMessage({
        id: "order-code.peek-post-multi-rooted",
        defaultMessage: "Wkład koronowo-korzeniowy z włóknem szklanym - wielo-korzeniowy",
      });
    case OrderCodeEnum.PeekPost: {
      return intl.formatMessage({
        id: "order-code.peek-post",
        defaultMessage: "Wkład koronowo-korzeniowy PEEK",
      });
    }
    case OrderCodeEnum.StandardImplantAbutment: {
      return intl.formatMessage({
        id: "order-code.standard-implant-abutment",
        defaultMessage: "Łącznik standardowy tytanowy",
      });
    }
    case OrderCodeEnum.IndividualTitanAbutment: {
      return intl.formatMessage({
        id: "order-code.individual-titan-abutment",
        defaultMessage: "Łącznik indywidualny tytanowy",
      });
    }
    case OrderCodeEnum.IndividualHybridAbutment: {
      return intl.formatMessage({
        id: "order-code.individual-hybrid-abutment",
        defaultMessage: "Łącznik indywidualny hybrydowy",
      });
    }
    case OrderCodeEnum.StandardTemporaryAbutment: {
      return intl.formatMessage({
        id: "order-code.standard-temporary-abutment",
        defaultMessage: "standardowy łącznik tymczasowy",
      });
    }
    case OrderCodeEnum.DigitalPrintOrientationModel: {
      return intl.formatMessage({
        id: "order-code.digital-print-orientation-model",
        defaultMessage: "Model orientacyjny drukowany",
      });
    }
    case OrderCodeEnum.PlasterOrientationModel: {
      return intl.formatMessage({
        id: "order-code.plaster-orientation-model",
        defaultMessage: "Model orientacyjny gipsowy",
      });
    }
    case OrderCodeEnum.PeekFoundation: {
      return intl.formatMessage({ id: "order-code.peek-foundation", defaultMessage: "Podbudowa PEEK" });
    }
    case OrderCodeEnum.ZirconiumFoundation: {
      return intl.formatMessage({ id: "order-code.zirconium-foundation", defaultMessage: "Podbudowa Cyrkonowa" });
    }
    case OrderCodeEnum.ColorSelectionInClinic: {
      return intl.formatMessage({
        id: "order-code.color-selection-in-dental-clinic",
        defaultMessage: "Dobór koloru w gabinecie dentystycznym",
      });
    }
    case OrderCodeEnum.MilledCrCoFoundation: {
      return intl.formatMessage({ id: "order-code.cr-co-foundation", defaultMessage: "Podbudowa Cr-Co Frezowana" });
    }
    case OrderCodeEnum.GlassCeramicCrowns: {
      return intl.formatMessage({
        id: "order-code.glass-ceramic-crowns",
        defaultMessage: "Korona z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.ZirconiumCrowns: {
      return intl.formatMessage({
        id: "order-code.zirconium-crowns",
        defaultMessage: "Korona porcelanowa na podbudowie cyrkonowej",
      });
    }
    case OrderCodeEnum.CompositeCrowns: {
      return intl.formatMessage({ id: "order-code.composite-crowns", defaultMessage: "Korona kompozytowa" });
    }
    case OrderCodeEnum.CrCoMilledCrowns: {
      return intl.formatMessage({ id: "order-code.cr-co-milled-crowns", defaultMessage: "Korona Cr-Co frezowana" });
    }
    case OrderCodeEnum.CrCoMoldedCrowns: {
      return intl.formatMessage({ id: "order-code.cr-co-molded-crowns", defaultMessage: "Korona Cr-Co odlewana" });
    }
    case OrderCodeEnum.LaserSinteredCrCoCrowns: {
      return intl.formatMessage({
        id: "order-code.laser-sintered-cr-co-crowns",
        defaultMessage: "Korona porcelanowa na podbudowie ze spieku laserowego",
      });
    }
    case OrderCodeEnum.TemporaryPmmaCrowns: {
      return intl.formatMessage({ id: "order-code.temporary-pmma-crowns", defaultMessage: "Korona tymczasowa PMMA" });
    }
    case OrderCodeEnum.TemporaryPrintedCrowns: {
      return intl.formatMessage({
        id: "order-code.temporary-printed-crowns",
        defaultMessage: "Korona tymczasowa drukowana",
      });
    }
    case OrderCodeEnum.GlassCeramicBridges: {
      return intl.formatMessage({ id: "order-code.glass-ceramic-bridge", defaultMessage: "Most z ceramiki szklanej" });
    }
    case OrderCodeEnum.ZirconiumBridges: {
      return intl.formatMessage({ id: "order-code.zirconium-bridge", defaultMessage: "Most cyrkonowy" });
    }
    case OrderCodeEnum.CompositeBridges: {
      return intl.formatMessage({ id: "order-code.composite-bridge", defaultMessage: "Most kompozytowy" });
    }
    case OrderCodeEnum.CrCoMilledBridges: {
      return intl.formatMessage({ id: "order-code.cr-co-milled-bridge", defaultMessage: "Most Cr-Co frezowany" });
    }
    case OrderCodeEnum.CrCoMoldedBridges: {
      return intl.formatMessage({ id: "order-code.cr-co-molded-bridge", defaultMessage: "Most Cr-Co odlewany" });
    }
    case OrderCodeEnum.LaserSinteredCrCoBridges: {
      return intl.formatMessage({
        id: "order-code.laser-sintered-cr-co-bridge",
        defaultMessage: "Most porcelanowy na podbudowie ze spieku laserowego",
      });
    }
    case OrderCodeEnum.TemporaryPmmaBridges: {
      return intl.formatMessage({ id: "order-code.temporary-pmma-bridge", defaultMessage: "Most tymczasowy PMMA" });
    }
    case OrderCodeEnum.TemporaryPrintedBridges: {
      return intl.formatMessage({
        id: "order-code.temporary-printed-bridge",
        defaultMessage: "Most tymczasowy drukowany",
      });
    }
    case OrderCodeEnum.CeramicGingiva: {
      return intl.formatMessage({ id: "order-code.emax-gingiva", defaultMessage: "Dziąsło ceramiczne" });
    }
    case OrderCodeEnum.CompositeGingiva: {
      return intl.formatMessage({ id: "order-code.composite-gingiva", defaultMessage: "Dziąsło kompozytowe" });
    }
    case OrderCodeEnum.ColorSelectionInLaboratory: {
      return intl.formatMessage({
        id: "order-code.color-selection-in-dental-laboratory",
        defaultMessage: "Dobór koloru w pracowni",
      });
    }
    case OrderCodeEnum.BiteRegistrationRims: {
      return intl.formatMessage({ id: "order-code.bite-registration-rims", defaultMessage: "Wzornik zwarciowy" });
    }
    case OrderCodeEnum.CompositeMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.composite-maryland-bridges",
        defaultMessage: "Most maryland kompozytowy",
      });
    }
    case OrderCodeEnum.CompositeVeneers: {
      return intl.formatMessage({ id: "order-code.composite-veneers", defaultMessage: "Licówki kompozytowe" });
    }
    case OrderCodeEnum.CrCoMilledMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.cr-co-milled-maryland-bridges",
        defaultMessage: "Most maryland Cr-Co frezowany",
      });
    }
    case OrderCodeEnum.CrCoMoldedMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.cr-co-molded-maryland-bridges",
        defaultMessage: "Most maryland Cr-Co odlewany",
      });
    }
    case OrderCodeEnum.CustomTray: {
      return intl.formatMessage({ id: "order-code.custom-tray", defaultMessage: "Łyżka indiwidualna" });
    }
    case OrderCodeEnum.FeldspaticVeneers: {
      return intl.formatMessage({
        id: "order-code.feldspatic-veneers",
        defaultMessage: "Licówka na masie ogniotrwałej",
      });
    }
    case OrderCodeEnum.FiberglassReinforcement: {
      return intl.formatMessage({
        id: "order-code.fiberglass-reinforcement",
        defaultMessage: "Wzmocnienie mostu włóknem szklanym",
      });
    }
    case OrderCodeEnum.FullContourGlassCeramicBridges: {
      return intl.formatMessage({
        id: "order-code.full-contour-glass-ceramic-bridges",
        defaultMessage: "Most pełnokonturowy z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.FullContourGlassCeramicMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.full-contour-glass-ceramic-maryland-bridges",
        defaultMessage: "Most maryland pełnokonturowy z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.FullContourZirconiumBridges: {
      return intl.formatMessage({
        id: "order-code.full-contour-zirconium-bridges",
        defaultMessage: "Most pełnokonturowy cyrkonowy",
      });
    }
    case OrderCodeEnum.FullContourGlassCeramicCrown: {
      return intl.formatMessage({
        id: "order-code.full-contour-glass-ceramic-crown",
        defaultMessage: "Korona pełnokonturowa z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.FullContourZirconiumMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.full-contour-zirconium-maryland-bridges",
        defaultMessage: "Most maryland pełnokonturowy z cyrkonu",
      });
    }
    case OrderCodeEnum.GlassCeramicMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.glass-ceramic-maryland-bridges",
        defaultMessage: "Most maryland z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.FullContourZirconiumCrown: {
      return intl.formatMessage({
        id: "order-code.full-contour-zirconium-crown",
        defaultMessage: "Korona pełnokonturowa cyrkonowa",
      });
    }
    case OrderCodeEnum.ImplantAbutmentPlacementJig: {
      return intl.formatMessage({
        id: "order-code.implant-abutment-placement-jig",
        defaultMessage: "Klucz do pozycjonowania łącznika",
      });
    }
    case OrderCodeEnum.GlassCeramicVeneers: {
      return intl.formatMessage({
        id: "order-code.glass-ceramic-veneers",
        defaultMessage: "Licówka z ceramiki szklanej",
      });
    }
    case OrderCodeEnum.LaserSinteredCrCoFoundation: {
      return intl.formatMessage({
        id: "order-code.laser-sintered-cr-co-foundation",
        defaultMessage: "Podbudowa ze spieku laserowego",
      });
    }
    case OrderCodeEnum.LaserSinteredCrCoMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.laser-sintered-cr-co-maryland-bridges",
        defaultMessage: "Most maryland ze spieku laserowego",
      });
    }
    case OrderCodeEnum.LaserSinteredCrCoPostMultiRooted:
      return intl.formatMessage({
        id: "order-code.laser-sintered-cr-co-post-multi-rooted",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co ze spieku laserowego - wielo-korzeniowy",
      });
    case OrderCodeEnum.LaserSinteredCrCoPost: {
      return intl.formatMessage({
        id: "order-code.laser-sintered-cr-co-post",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co ze spieku laserowego",
      });
    }
    case OrderCodeEnum.MetalReinforcement: {
      return intl.formatMessage({
        id: "order-code.metal-reinforcement",
        defaultMessage: "Wzmocnienie mostu metalem",
      });
    }
    case OrderCodeEnum.MilledCrCoPostMultiRooted:
      return intl.formatMessage({
        id: "order-code.milled-cr-co-post-multi-rooted",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co frezowany",
      });
    case OrderCodeEnum.MilledCrCoPost: {
      return intl.formatMessage({
        id: "order-code.milled-cr-co-post",
        defaultMessage: "Wkład koronowo-korzeniowy Cr-Co frezowany",
      });
    }
    case OrderCodeEnum.MilledMichiganSplint: {
      return intl.formatMessage({
        id: "order-code.milled-michigan-splint",
        defaultMessage: "Szyna relaksacyjna Michigan frezowana",
      });
    }
    case OrderCodeEnum.MilledRelaxationSplint: {
      return intl.formatMessage({
        id: "order-code.milled-relaxation-splint",
        defaultMessage: "Szyna relaksacyjna frezowana",
      });
    }
    case OrderCodeEnum.MilledTryInBridges: {
      return intl.formatMessage({ id: "order-code.milled-try-in-bridges", defaultMessage: "Most Try-In frezowany" });
    }
    case OrderCodeEnum.MilledTryInCrowns: {
      return intl.formatMessage({ id: "order-code.milled-try-in-crowns", defaultMessage: "Korona Try-In frezowana" });
    }
    case OrderCodeEnum.Mouthguard: {
      return intl.formatMessage({ id: "order-code.mouthguard", defaultMessage: "Szyna ochronna / szyna bokserska" });
    }
    case OrderCodeEnum.GingivectomySurgicalGuide: {
      return intl.formatMessage({
        id: "order-code.gingivectomy-surgical-guide",
        defaultMessage: "Szablon chirurgiczny do gingiwektomii",
      });
    }
    case OrderCodeEnum.ImplantSurgicalGuide: {
      return intl.formatMessage({
        id: "order-code.implant-surgical-guide",
        defaultMessage: "Szablon do pozycjonowania implantów",
      });
    }
    case OrderCodeEnum.PlatinumFoilVeneers: {
      return intl.formatMessage({
        id: "order-code.platinum-foil-veneers",
        defaultMessage: "Licówka na folii platynowej",
      });
    }
    case OrderCodeEnum.PorcelainMargin: {
      return intl.formatMessage({ id: "order-code.porcelain-margin", defaultMessage: "Stopień ceramiczny" });
    }
    case OrderCodeEnum.PreparationCup: {
      return intl.formatMessage({ id: "order-code.preparation-cup", defaultMessage: "Czapeczka do szlifowania zęba" });
    }
    case OrderCodeEnum.PrintedKoisSplint: {
      return intl.formatMessage({
        id: "order-code.printed-kois-splint",
        defaultMessage: "Deprogramator koisa drukowany",
      });
    }
    case OrderCodeEnum.PrintedTryInBridges: {
      return intl.formatMessage({ id: "order-code.printed-try-in-bridges", defaultMessage: "Most Try-In drukowany" });
    }
    case OrderCodeEnum.PrintedTryInCrowns: {
      return intl.formatMessage({ id: "order-code.printed-try-in-crowns", defaultMessage: "Korona Try-In drukowana" });
    }
    case OrderCodeEnum.SiliconeIndex: {
      return intl.formatMessage({ id: "order-code.silicone-index", defaultMessage: "Klucz silikonowy standardowy" });
    }
    case OrderCodeEnum.TransparentSiliconeIndex: {
      return intl.formatMessage({
        id: "order-code.transparent-silicone-index",
        defaultMessage: "Klucz silikonowy transparentny",
      });
    }
    case OrderCodeEnum.ZirconiumMarylandBridges: {
      return intl.formatMessage({
        id: "order-code.zirconium-maryland-bridges",
        defaultMessage: "Most maryland cyrkonowy",
      });
    }
    default:
      throw argumentOutOfRangeError("code", code);
  }
};
