import * as React from "react";
import Radio, { RadioGroupProps } from "antd/lib/radio";
import { AnchorTypeIcon } from "components/formatters/anchor-icon/AnchorTypeIcon";
import { AnchorType } from "features/orders/order-type/anchor-type.models";
import "./graphical-anchor-picker.scss";
import { anchorColors } from "../jaws/jaws.consts";

const sortValues: Readonly<Record<AnchorType, number>> = {
  [AnchorType.ScrewedInToImplant]: 1,
  [AnchorType.CementedToImplant]: 2,
  [AnchorType.CementedToPreparedTooth]: 3,
  [AnchorType.Pontic]: 4,
};

const defaultAnchorTypeOrder = (a: AnchorType, b: AnchorType) => {
  return sortValues[a] - sortValues[b];
};

export interface IGraphicalAnchorPickerProps extends Omit<RadioGroupProps, "value"> {
  value?: AnchorType | null;
  anchorTypes: AnchorType[];
}

const GraphicalAnchorPicker: React.FunctionComponent<IGraphicalAnchorPickerProps> = (props) => {
  const { anchorTypes, value = null, ...redioGroupProps } = props;
  const sortedAnchorTypes = [...anchorTypes].sort(defaultAnchorTypeOrder);

  return (
    <Radio.Group value={value} className={"graphical-anchor-picker"} {...redioGroupProps}>
      {sortedAnchorTypes.map((anchorType) => (
        <Radio.Button value={anchorType} key={anchorType} style={{ height: "auto" }}>
          <AnchorTypeIcon anchorType={anchorType} isSelected={anchorType === value} />
          <div
            className={"graphical-anchor-picker__color-indicator"}
            style={{ backgroundColor: `${anchorColors[anchorType].fill}` }}
          />
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default GraphicalAnchorPicker;
