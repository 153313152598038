import * as React from "react";
import { SWRConfig } from "swr";
import ApplicationRouter from "features/routing/ApplicationRouter";

import { IDentistApi } from "features/dentist/service/IDentistApi";
import DentistApiContext from "features/dentist/dentist.context";

import { IOrderApi } from "features/orders/service/orders.service";
import OrderApiContext from "features/orders/order.context";

import { IOrderCatalogueService } from "features/catalogue/service/categories.serivce";
import CategoriesApiContext from "features/catalogue/categories.context";

import AxiosHttpService from "axios/axios-http.service";
import { IHttpService } from "core/http/http.service";
import BaseUrlService, { IBaseUrlService } from "services/base-url.service";
import { envVariables } from "core/global/environment.const";
import FeatureFlagService, { IFeatureFlagsService } from "features/feature-flags/feature-flags.service";
import FeatureFlagsContext from "features/feature-flags/FeatureFlagsContext";
import DentistHttpApi from "features/dentist/service/DentistHttpApi";
import OrdersHttpApi from "features/orders/service/OrdersHttpSerice";

import CategoriesHttpService from "features/catalogue/service/categories.http.service";
import Clock, { IClock } from "core/clock/clock.service";
import ClockContext from "core/clock/clock.context";
import BaseUrlContext from "services/base-url.context";

import AuthService, { IAuthService } from "features/auth/auth.service";
import AuthServiceContext from "features/auth/auth-service.context";
import { DentalLabServiceContext } from "features/dental-lab/dental-lab.context";
import { LabApiContext } from "features/dental-lab/dental-lab.context";

import { IDentalLabService } from "features/dental-lab/service/IDentalLabService";
import DentalLabService from "features/dental-lab/service/DentalLabService";
import TypedEmitter from "core/event-emitter/typed-event-emitter";
import { IEvents } from "./IEvents";
import EventEmitterContext from "features/event-emitter/event-emitter.context";
import SettingsHttpService, { ISettingsService } from "../features/settings/service/settings.service";
import SettingsServiceContext from "../features/settings/service/settings.service-context";

import SubscriptionsService, { ISubscriptionsService } from "../features/subscriptions/subscriptions.service";
import SubscriptionsContext from "../features/subscriptions/subscriptions.context";

import EmployeeService, {
  EmployeeServiceContext,
  IEmployeeService,
} from "../features/employee/services/employee.service";

import { IJsonApiService } from "core/http/json-api.service";
import AxiosJsonApiService from "axios/axios-json-http.service";
import JsonApiContext from "core/http/json-api.context";
import RegisterService, { RegisterServiceContext } from "features/register/register.service";
import { IDentalLabApi } from "features/dental-lab/service/IDentalLabApi";
import DentalLabApi from "features/dental-lab/service/DentalLabApi";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { IPricingListApi } from "features/pricing-lists/service/IPricingListApi";
import PricingListApi from "features/pricing-lists/service/PricingListApi";
import { IOrderFilesApi } from "features/orders/service/order-files.service";
import OrderFilesHttpService from "features/orders/service/OrderFilesHttpService";
import OrderFilesApiContext from "features/orders/order-file.context";
import ChatHttpApi from "features/chat/services/ChatHttpApi";
import ChatApiContext from "features/chat/services/ChatApiContext";

import "./root.scss";
import InvoicingApi, { IInvoicingApi } from "features/invoicing/invoice.service";
import InvoicingApiContext from "features/invoicing/invoice.context";

export interface IAppProps {}

const eventEmitter = new TypedEmitter<IEvents>();
const clock: IClock = new Clock();

let httpService: IHttpService;
const baseUrlService: IBaseUrlService = new BaseUrlService(envVariables);
const featureFlagService: IFeatureFlagsService = new FeatureFlagService(envVariables);
const authService: IAuthService = new AuthService(baseUrlService, eventEmitter, {
  provide: () => httpService,
});
httpService = new AxiosHttpService(baseUrlService, authService);
const registerService = new RegisterService(httpService, authService);
const jsonApiService: IJsonApiService = new AxiosJsonApiService(baseUrlService, authService, authService, eventEmitter);
const chatApi = new ChatHttpApi(jsonApiService);
const dentalLabService: IDentalLabService = new DentalLabService(jsonApiService);
const dentalLabApi: IDentalLabApi = new DentalLabApi(jsonApiService);
const pricingListApi: IPricingListApi = new PricingListApi(jsonApiService);
const dentistApi: IDentistApi = new DentistHttpApi(jsonApiService);
const orderApi: IOrderApi = new OrdersHttpApi(jsonApiService, httpService, authService, dentalLabService);
const orderFilesApi: IOrderFilesApi = new OrderFilesHttpService(jsonApiService);
const categoriesService: IOrderCatalogueService = new CategoriesHttpService(
  jsonApiService,
  httpService,
  dentalLabService
);
const invoiceApi: IInvoicingApi = new InvoicingApi(jsonApiService);
const subscriptionsService: ISubscriptionsService = new SubscriptionsService(httpService, authService);
const settingsService: ISettingsService = new SettingsHttpService(httpService, authService);
const employeeService: IEmployeeService = new EmployeeService(jsonApiService);

class App extends React.PureComponent<IAppProps> {
  render() {

    // noinspection JSUnusedGlobalSymbols
    return (
      <>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnMount: true,
            revalidateOnReconnect: false,
            onError: (error, key) => {
              console.error("swr error", key, error);
            },
          }}
        >
          <ClockContext.Provider value={clock}>
            <EventEmitterContext.Provider value={eventEmitter}>
              <FeatureFlagsContext.Provider value={featureFlagService}>
                <BaseUrlContext.Provider value={baseUrlService}>
                  <AuthServiceContext.Provider value={authService}>
                    <RegisterServiceContext.Provider value={registerService}>
                      <JsonApiContext.Provider value={jsonApiService}>
                        <ChatApiContext.Provider value={chatApi}>
                          <DentalLabServiceContext.Provider value={dentalLabService}>
                            <LabApiContext.Provider value={dentalLabApi}>
                              <PricingListApiContext.Provider value={pricingListApi}>
                                <DentistApiContext.Provider value={dentistApi}>
                                  <OrderApiContext.Provider value={orderApi}>
                                    <OrderFilesApiContext.Provider value={orderFilesApi}>
                                      <InvoicingApiContext.Provider value={invoiceApi}>
                                        <SubscriptionsContext.Provider value={subscriptionsService}>
                                          <CategoriesApiContext.Provider value={categoriesService}>
                                            <SettingsServiceContext.Provider value={settingsService}>
                                              <EmployeeServiceContext.Provider value={employeeService}>
                                                <ApplicationRouter />
                                              </EmployeeServiceContext.Provider>
                                            </SettingsServiceContext.Provider>
                                          </CategoriesApiContext.Provider>
                                        </SubscriptionsContext.Provider>
                                      </InvoicingApiContext.Provider>
                                    </OrderFilesApiContext.Provider>
                                  </OrderApiContext.Provider>
                                </DentistApiContext.Provider>
                              </PricingListApiContext.Provider>
                            </LabApiContext.Provider>
                          </DentalLabServiceContext.Provider>
                        </ChatApiContext.Provider>
                      </JsonApiContext.Provider>
                    </RegisterServiceContext.Provider>
                  </AuthServiceContext.Provider>
                </BaseUrlContext.Provider>
              </FeatureFlagsContext.Provider>
            </EventEmitterContext.Provider>
          </ClockContext.Provider>
        </SWRConfig>
      </>
    );
  }
}

export default App;
