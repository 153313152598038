import * as React from "react";
import { useCallback } from "react";
import classNames from "classnames";
import { DotId, PointId } from "core/point/point.enums";
import { JawViewProps } from "./jaw-view.model";
import { allTeeths } from "core/tooth/tooth.const";
import {
  defaultAnchorOptions,
  defaultDotOptions,
  defaultLabelOptions,
  defaultPointOptions,
  jawSvgStyles,
} from "./jaws.consts";
import { AnchorType } from "../../../../order-type/anchor-type.models";

const LowerJawView: React.FunctionComponent<JawViewProps> = ({
  points,
  dots = {},
  labels = {},
  anchors = {},
  pointOptions = defaultPointOptions,
  dotOptions = defaultDotOptions,
  anchorOptions = defaultAnchorOptions,
  labelOptions = defaultLabelOptions,
  onDotSelected,
  onPointSelected,
}) => {
  const handlePointClick = useCallback(
    (pointId: PointId) => (_: React.MouseEvent) => {
      onPointSelected?.(pointId);
    },
    [onPointSelected]
  );

  const handleDotClick = useCallback(
    (dot: DotId) => (_: React.MouseEvent) => {
      if (dotOptions?.editable !== true) {
        return;
      }

      onDotSelected?.(dot);
    },
    [dotOptions?.editable, onDotSelected]
  );

  const allLabels = allTeeths.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: labels[curr.id] === undefined ? [] : labels[curr.id],
    }),
    {} as Record<PointId, string[]>
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 601.57 491.85">
      <defs>
        <style>{jawSvgStyles}</style>
      </defs>
      <path
        className="cls-1"
        d="M554.49,71.94c14.32-48.29-13.34-64.09-13.34-64.09l-18-5.44L476.35,12.78c1.39,9.84-9.74,7.32-9.74,21.13,0,165.58-74.88,307.8-164,307.8S141.23,207.48,141.23,41.9c0-13.82-11.79-22-10.82-35.32-6.08-2.07-4.42-4.95-5.13-5-44.95-3.81-59.39.51-59.39.51-40.17,18.19-17.1,66.23-17.1,66.23,2.17,4.07,1.09,19.27,1.09,19.27-14.39,42.34,11.67,57.81,11.67,57.81L67,156.25c-17.1,39.08,11.67,64.65,11.67,64.65l4.07,12.16c-5.7,27.41,18.19,43.43,18.19,43.43l4.34,12.75c-2.44,32.57,33.38,39.63,33.38,39.63v11.67c1.63,38.82,39.9,44,39.9,44l6.79,7.6c11.94,32,48.85,25.52,48.85,25.52l6,9.5c24.43,28.77,63,9.31,63,9.31,2.44-4.52,6.79-1.72,6.79-1.72,40.17,7.6,60.8-12.48,60.8-12.48l6-5.7c37.72,1.36,47.49-30.94,47.49-30.94l4.35-6C472.75,366.87,473,340.27,473,340.27l2.17-11.94c30.67-18.46,25.24-46.68,25.24-46.68v-5.16c26.6-27.14,19.82-50.76,19.82-50.76l1.62-3.25c32.85-28,18.3-69.4,18.3-69.4l7-8.42c18.25-31.16,3.65-62.89,3.65-62.89Z"
      />
      <path
        className="cls-2"
        d="M132.73,8.2c8.18,13.66,6.49,31.85,6.49,31.85-1.88,23.26-14.46,35.41-14.46,35.41,13.21,14.67,12.37,34.16,12.37,34.16,2.3,23.89-12.58,34.16-12.58,34.16,8.6,5.44,15.51,18.23,15.51,18.23,8.38,12.36,2.72,28.91,2.72,28.91-5.23,18.44,3.36,23.05,3.36,23.05,13.2,7.13,17.81,22,17.81,22,3.77,11.31-5.24,24.93-5.24,24.93,8,.84,13.83,6.92,13.83,6.92,9.85,9,8,33.32,8,33.32-.62,15.29,12.37,14.25,12.37,14.25,10.68,2.51,18.44,10.26,18.44,10.26,8.59,6.08,9,21,9,21a33.74,33.74,0,0,1,27.93,10.44c6.71,6.91,11.88,17,11.88,17,7.34-9,23.89-3.56,23.89-3.56C296.8,372.18,304.35,382,304.35,382c5.24-17.18,27-13.83,27-13.83,14.25-1.25,21.37,5.45,21.37,5.45-.83-6.08,5-11.1,5-11.1,7.55-5.66,25.57-9.85,25.57-9.85,10.27-1.68,10.48-12.16,10.48-12.16.83-6.7,5.23-14.25,5.23-14.25,7.76-11.1,23.89-16.76,23.89-16.76,9.85-5,4.61-16.76,4.61-16.76-2.93-11.32,1.26-18.65,1.26-18.65,2.31-7.13,15.09-10.9,15.09-10.9,3.56-.42,2.09-3.77,2.09-3.77-5.86-5.66-2.3-21.8-2.3-21.8,2.09-16.55,18-28.08,18-28.08,3.77-2.51,1.26-9.22,1.26-9.22l-2.52-10.89c-3.77-25.78,10.27-35.21,10.27-35.21,13.2-7.12,2.72-18.65,2.72-18.65-16.34-14.25-5.44-32.27-5.44-32.27,16.34-19.7,10.89-25.77,10.89-25.77C464.44,60,465.49,44,465.49,44c-.29-22.88,8.57-34.09,13.84-38.71a10.85,10.85,0,0,1,7.34-2.67l36.1.49h23.87s21.34,22.71,7,71L550,84s14.6,31.73-3.65,62.89l-7,8.43s14.55,41.43-18.3,69.39l-1.62,3.25s6.78,23.62-19.82,50.76v5.16s5.43,28.22-25.24,46.68l-2.17,11.94s-.27,26.6-44.51,39.36l-4.35,6s-9.77,32.3-47.49,30.94l-6,5.7s-20.62,20.09-60.79,12.49c0,0-4.35-2.81-6.79,1.71,0,0-38.54,19.46-63-9.31l-6-9.5s-36.91,6.51-48.85-25.52l-6.79-7.6s-38.27-5.15-39.9-44V331.07S102,324,104.42,291.45l-4.35-12.76s-23.88-16-18.18-43.43l-4.07-12.15s-28.77-25.57-11.67-64.66L60.72,147.6S34.66,132.12,49.05,89.78c0,0,1.08-15.2-1.09-19.27,0,0-23.07-48,17.1-66.22,0,0,17.83-6,56.26-3.82A16,16,0,0,1,132.73,8.2Z"
      />
      <g id="point-48">
        <path
          onClick={handlePointClick(PointId.P48)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P48] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P48] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P48] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P48] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P48]?.selected === true,
          })}
          d="M82,76.41a189,189,0,0,1-25.53-1.75c-8-1.39-14.19-7.85-17.81-18.67a64.22,64.22,0,0,1-3.05-15.84c0-14.57,5.59-23.36,10.29-28.18a29.07,29.07,0,0,1,10.4-7.09l.1,0h.1a185.88,185.88,0,0,1,21.28-1c14.27,0,27.39,1,27.52,1C133.58,6.92,132.58,35,132.49,36.69,134.39,64,110,73.38,109.73,73.47l0,0h-.05A115.7,115.7,0,0,1,82,76.41Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P48]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P48] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P48] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P48] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P48] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M77.75,4.81c14.39,0,27.44,1,27.44,1,28.4,2.1,26.3,30.88,26.3,30.88,1.91,26.87-22.11,35.83-22.11,35.83-8.79,2.2-18.63,2.88-27.41,2.88a187.46,187.46,0,0,1-25.38-1.73c-18.68-3.24-20-33.57-20-33.57,0-27.47,20-34.29,20-34.29a187.75,187.75,0,0,1,21.16-1m0-2a185.59,185.59,0,0,0-21.4,1l-.21,0L56,3.93a30.31,30.31,0,0,0-10.8,7.34c-4.82,4.95-10.57,14-10.57,28.84v.09a65.62,65.62,0,0,0,3.1,16.11C41.42,67.5,47.84,74.19,56.25,75.65A188.87,188.87,0,0,0,82,77.41a116.6,116.6,0,0,0,27.9-2.94l.11,0,.1,0a39.93,39.93,0,0,0,12.47-8.28c5.54-5.39,11.94-14.86,11-29.44a38.38,38.38,0,0,0-2.66-15.39C127.87,14,121,5,105.34,3.83c-.13,0-13.28-1-27.59-1Z"
        />
        <path
          className="cls-pts-shadow"
          d="M108,15.14c20.27,1.5,18.77,22,18.77,22,1,14.67-8.74,21.86-13.4,24.43,0,0,3.7-23.23-2.93-41.13Z"
        />
        <text className="cls-text" transform="translate(71.66 46.12)">
          48
        </text>
        {allLabels[PointId.P48].length > 0 && (
          <text className="cls-text" transform="translate(6.83 39.39) rotate(93.57)">
            {allLabels[PointId.P48].join("|")}
          </text>
        )}
      </g>
      <g id="point-47">
        <path
          onClick={handlePointClick(PointId.P47)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P47] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P47] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P47] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P47] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P47]?.selected === true,
          })}
          d="M84.57,149.07A175,175,0,0,1,61,147.46c-7.46-1.29-13.17-7.28-16.52-17.3a59.52,59.52,0,0,1-2.82-14.66c0-13.49,5.18-21.63,9.53-26.1a27.09,27.09,0,0,1,9.64-6.57l.1,0H61a171.26,171.26,0,0,1,19.67-1c13.19,0,25.31.93,25.43.94,26.2,1.94,25.32,27.88,25.23,29.52,1.75,25.24-20.85,34-21.08,34.05l-.11,0a107.15,107.15,0,0,1-25.57,2.68Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P47]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P47] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P47] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P47] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P47] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M80.67,82.84c13.3,0,25.36.94,25.36.94,26.24,1.94,24.3,28.53,24.3,28.53,1.76,24.83-20.43,33.11-20.43,33.11a107.57,107.57,0,0,1-25.33,2.65,173.07,173.07,0,0,1-23.45-1.6c-17.26-3-18.49-31-18.49-31,0-25.38,18.49-31.68,18.49-31.68a171.6,171.6,0,0,1,19.55-.94m0-2a173.53,173.53,0,0,0-19.79,1l-.21,0-.2.07a28.16,28.16,0,0,0-10,6.81c-4.48,4.6-9.81,13-9.81,26.76v.08a60.81,60.81,0,0,0,2.87,14.94c3.48,10.39,9.45,16.61,17.28,18a171.76,171.76,0,0,0,23.79,1.63,108.26,108.26,0,0,0,25.82-2.71l.1,0,.11,0a37,37,0,0,0,11.57-7.68c5.15-5,11.08-13.79,10.16-27.31A35.66,35.66,0,0,0,129.87,98c-2.76-6.81-9.12-15.16-23.7-16.24-.11,0-12.27-.94-25.5-.94Z"
        />
        <path
          className="cls-pts-shadow"
          d="M109.78,92.84C128.31,94.21,126.94,113,126.94,113c1.24,17.53-14.42,23.38-14.42,23.38s3.75-10.42,1.32-33a22.07,22.07,0,0,0-4.06-10.54Z"
        />
        <text className="cls-text" transform="translate(72.84 121.88)">
          47
        </text>
        {allLabels[PointId.P47].length > 0 && (
          <text className="cls-text" transform="translate(7.95 118.85) rotate(83.46)">
            {allLabels[PointId.P47].join("|")}
          </text>
        )}
      </g>
      <g id="point-46">
        <path
          onClick={handlePointClick(PointId.P46)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P46] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P46] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P46] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P46] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P46]?.selected === true,
          })}
          d="M83.36,225.26c-14.89,0-19.32-9.77-19.5-10.18-5.86-17.91-6.42-28.86-6.45-29.32-1.5-11,2.26-18.52,5.68-22.91a26.75,26.75,0,0,1,7.92-7l.08,0,.08,0A188.14,188.14,0,0,1,92,150.66a78.9,78.9,0,0,1,10.1-.69c17.78,0,24.37,7.15,25.15,8.07,10.76,9.14,13.81,18.55,14.47,24.84a26.71,26.71,0,0,1-1.15,11.6c-1.54,11.43-12.08,15.83-12.53,16L102.52,221c-7.08,2.84-13.52,4.27-19.15,4.27Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P46]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P46] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P46] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P46] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P46] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M102.11,151c18.34,0,24.44,7.77,24.44,7.77,20.45,17.32,13.06,35.49,13.06,35.49-1.42,11.08-11.92,15.33-11.92,15.33l-25.55,10.51c-7.64,3.05-13.81,4.19-18.77,4.19-14.42,0-18.6-9.58-18.6-9.58-5.85-17.89-6.36-29-6.36-29-2.84-20.73,13.06-29,13.06-29a182.72,182.72,0,0,1,20.73-5.11,75.94,75.94,0,0,1,9.91-.68m0-2a79.47,79.47,0,0,0-10.17.69h-.09a182.34,182.34,0,0,0-21,5.17l-.16.05-.15.08a28.12,28.12,0,0,0-8.25,7.25c-3.53,4.54-7.41,12.32-5.88,23.67.07,1.2.8,12.12,6.45,29.4l0,.09,0,.08c.19.45,4.88,10.79,20.43,10.79,5.76,0,12.32-1.46,19.51-4.33l25.57-10.52c.46-.18,11.45-4.77,13.12-16.69a28.13,28.13,0,0,0,1.17-11.95c-.69-6.46-3.8-16.1-14.77-25.44-1.11-1.28-8.1-8.36-25.86-8.36Z"
        />
        <path
          className="cls-pts-shadow"
          d="M107.85,157.49c10.1-.58,17.7,5.79,17.7,5.79,15.71,13.31,10,27.27,10,27.27a14.27,14.27,0,0,1-4.53,8.66,52.07,52.07,0,0,0-1.65-7.73c-3.67-13.06-9.29-23.88-19.64-32.66Z"
        />
        <text className="cls-text" transform="translate(85.04 195.74)">
          46
        </text>
        {allLabels[PointId.P46].length > 0 && (
          <text className="cls-text" transform="matrix(0.23, 0.97, -0.97, 0.23, 18.95, 200.16)">
            {allLabels[PointId.P46].join("|")}
          </text>
        )}
      </g>
      <g id="point-45">
        <path
          onClick={handlePointClick(PointId.P45)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P45] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P45] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P45] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P45] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P45]?.selected === true,
          })}
          d="M104.18,280.12c-7.11,0-13.52-4.06-18.55-11.76a47.07,47.07,0,0,1-5.45-11.57c-2.87-7.6-3-14.35-.48-20a21,21,0,0,1,10.81-10.4c21.47-8.55,32.37-10.6,32.83-10.68l.11,0h.12c15.42.9,22.76,7.11,23.51,7.78,15.08,10.66,6.62,31.91,6.53,32.12l-.07.19-.14.14c-16,16.16-42,22.95-42.3,23a21.77,21.77,0,0,1-6.91,1.18Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P45]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P45] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P45] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P45] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P45] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M123.52,216.7c15.77.92,22.93,7.56,22.93,7.56,14.68,10.31,6.24,31,6.24,31-16,16.14-41.83,22.75-41.83,22.75a21.2,21.2,0,0,1-6.68,1.14c-16.52,0-23-22.61-23-22.61-8.44-22.38,9.72-29.17,9.72-29.17,21.65-8.62,32.66-10.64,32.66-10.64m-.13-2-.23,0c-.46.09-11.46,2.16-33,10.75a22,22,0,0,0-11.33,10.91c-2.67,5.87-2.52,12.85.45,20.76a48.93,48.93,0,0,0,5.55,11.76c5.22,8,11.93,12.21,19.39,12.21a23.2,23.2,0,0,0,7.24-1.22c1.82-.48,26.78-7.18,42.69-23.27l.28-.28.15-.37a40.35,40.35,0,0,0,2.53-12.28c.39-9.12-2.85-16.38-9.37-21-1-.91-8.64-7.09-24.07-8Z"
        />
        <path
          className="cls-pts-shadow"
          d="M128,222.71c11.31.66,16.43,5.42,16.43,5.42,10.52,7.38,4.47,22.18,4.47,22.18-1,1-2,1.89-3,2.77,0,0,1.69-17.29-13-27.5Z"
        />
        <text className="cls-text" transform="translate(103.78 255.3)">
          45
        </text>
        {allLabels[PointId.P45].length > 0 && (
          <text className="cls-text" transform="matrix(0.23, 0.97, -0.97, 0.23, 37.8, 271.87)">
            {allLabels[PointId.P45].join("|")}
          </text>
        )}
      </g>
      <g id="point-44">
        <path
          onClick={handlePointClick(PointId.P44)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P44] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P44] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P44] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P44] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P44]?.selected === true,
          })}
          d="M128.52,333.33c-19.26,0-25.58-21.54-25.64-21.76-6.17-16.28,2.42-24.63,2.79-25l.1-.1,15.71-8.26L136.26,271a27,27,0,0,1,12.83-3.15A31.44,31.44,0,0,1,162.83,271c20.06,8.71,14.63,29.11,14.57,29.31-6,16.05-39.37,31.19-40.79,31.83l-.07,0-.08,0a30.55,30.55,0,0,1-7.94,1.11Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P44]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P44] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P44] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P44] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P44] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M149.09,268.85a30.63,30.63,0,0,1,13.26,3c19.57,8.5,14.09,28.17,14.09,28.17-5.87,15.76-40.24,31.18-40.24,31.18a29.36,29.36,0,0,1-7.68,1.08c-18.72,0-24.68-21-24.68-21-6-15.93,2.52-24,2.52-24l15.59-8.2,14.75-7.22a26.12,26.12,0,0,1,12.39-3m0-2a28.09,28.09,0,0,0-13.3,3.27l-14.72,7.21h0l-15.59,8.2-.25.13-.19.19c-.39.36-9.43,9.13-3,26.06a39.45,39.45,0,0,0,5.6,11.06c5.4,7.42,12.66,11.34,21,11.34a31.76,31.76,0,0,0,8.2-1.15l.15,0,.15-.07c1.43-.64,35.2-15.95,41.29-32.31l0-.08,0-.08c.06-.21,5.7-21.4-15.14-30.5a32.62,32.62,0,0,0-14.13-3.25Z"
        />
        <path
          className="cls-pts-shadow"
          d="M147.2,275.35a21.47,21.47,0,0,1,14.94,1.42c14.05,6.1,10.11,20.22,10.11,20.22a20.18,20.18,0,0,1-4.71,6.76,31.59,31.59,0,0,0-14.66-25.07Z"
        />
        <text className="cls-text" transform="translate(124.45 307.57)">
          44
        </text>
        {allLabels[PointId.P44].length > 0 && (
          <text className="cls-text" transform="translate(65.25 331.14) rotate(65.88)">
            {allLabels[PointId.P44].join("|")}
          </text>
        )}
      </g>
      <g id="point-43">
        <path
          onClick={handlePointClick(PointId.P43)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P43] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P43] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P43] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P43] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P43]?.selected === true,
          })}
          d="M169.68,387.4c-13.16,0-24.69-11.08-25.17-11.55-15.84-14.74-6.08-33.54-6-33.73l.07-.12.09-.1c14.45-15.23,23.39-19.66,24.35-20.1a22.7,22.7,0,0,1,12.53-3.57c12.56,0,24,8.62,24.48,9,14,9.26,10.21,23,6.27,28.92-4.25,7.61-23,26.22-23.78,27l-.06.06-.06.05a21.32,21.32,0,0,1-12.74,4.16Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P43]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P43] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P43] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P43] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P43] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M175.57,319.23c12.37,0,23.87,8.78,23.87,8.78,16.89,11.2,6.05,27.56,6.05,27.56-4.27,7.65-23.65,26.85-23.65,26.85a20.29,20.29,0,0,1-12.16,4c-12.9,0-24.48-11.27-24.48-11.27-15.47-14.4-5.78-32.54-5.78-32.54,15-15.83,24.1-19.92,24.1-19.92a21.75,21.75,0,0,1,12-3.44m0-2a23.75,23.75,0,0,0-13,3.69c-1.3.62-10.32,5.26-24.59,20.29l-.18.2-.13.24a32.14,32.14,0,0,0-3.14,11.66,28,28,0,0,0,9.33,23.29c.47.45,12.28,11.8,25.84,11.8A22.32,22.32,0,0,0,183,384l.12-.09.11-.1c.8-.8,19.56-19.43,24-27.26.85-1.35,11-18.48-6.61-30.21-1-.76-12.45-9.15-25-9.15Z"
        />
        <path
          className="cls-pts-shadow"
          d="M172.67,325.69c10.78-4.66,23.44,5,23.44,5,12.06,8,4.32,19.69,4.32,19.69s-1.71-9.5-11.5-17a34.28,34.28,0,0,0-8.55-4.71Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="161.88"
          cy="361.1"
          rx="16.72"
          ry="24.29"
          transform="translate(-218.02 250.32) rotate(-49.6)"
        />
        <text className="cls-text" transform="translate(161.12 358.35)">
          43
        </text>
        {allLabels[PointId.P43].length > 0 && (
          <text className="cls-text" transform="matrix(0.74, 0.68, -0.68, 0.74, 116.54, 404.55)">
            {allLabels[PointId.P43].join("|")}
          </text>
        )}
      </g>
      <g id="point-42">
        <path
          onClick={handlePointClick(PointId.P42)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P42] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P42] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P42] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P42] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P42]?.selected === true,
          })}
          d="M219.65,426.81c-12.31,0-23.87-9.52-24.35-9.93-7.05-5.32-11-11-11.77-17a16.71,16.71,0,0,1,3.54-12.34c9.43-13.16,25.32-28.21,25.48-28.36l0,0,.06,0a16.62,16.62,0,0,1,9.84-3.07c10.31,0,20.17,8,21.11,8.78,14.33,8.88,9,26.27,8.92,26.44-5.72,15.92-18.11,29.32-18.23,29.45a20,20,0,0,1-14.64,6.09Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P42]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P42] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P42] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P42] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P42] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M222.5,357.06c10.36,0,20.52,8.58,20.52,8.58,13.91,8.56,8.56,25.33,8.56,25.33-5.71,15.87-18,29.07-18,29.07a19,19,0,0,1-13.91,5.77c-12.1,0-23.72-9.7-23.72-9.7-19.62-14.8-8.34-27.56-8-28,9.46-13.19,25.33-28.17,25.33-28.17a15.52,15.52,0,0,1,9.26-2.88m0-2a17.52,17.52,0,0,0-10.42,3.25l-.12.08-.09.09c-.66.62-16.1,15.24-25.56,28.44l0,0A17.71,17.71,0,0,0,182.54,400c.78,6.2,4.87,12.13,12.16,17.64.9.75,12.52,10.13,25,10.13A21,21,0,0,0,235,421.45l0,0,0,0c.52-.55,12.65-13.68,18.43-29.76l0,0v0c0-.19,5.62-18.27-9.3-27.56-1.26-1-11.22-9-21.69-9Z"
        />
        <path
          className="cls-pts-shadow"
          d="M213.24,368.68c2.69-2.74,4.56-4.52,4.56-4.52,9-6.45,21.56,4.14,21.56,4.14,10.07,6.19,6.2,18.33,6.2,18.33-.22.6-.45,1.2-.69,1.79,0,0-4.6-14.4-22.67-18.49Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="211.74"
          cy="402.14"
          rx="16.72"
          ry="24.29"
          transform="translate(-241.77 372.6) rotate(-58.51)"
        />
        <text className="cls-text" transform="translate(205.11 397.8)">
          42
        </text>
        {allLabels[PointId.P42].length > 0 && (
          <text className="cls-text" transform="matrix(0.74, 0.68, -0.68, 0.74, 167.12, 445.61)">
            <>{allLabels[PointId.P42].join("|")}</>
          </text>
        )}
      </g>
      <g id="point-41">
        <path
          onClick={handlePointClick(PointId.P41)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P41] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P41] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P41] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P41] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P41]?.selected === true,
          })}
          d="M276.89,454.09a59.15,59.15,0,0,1-9.06-.66c-11.17-1.05-19.21-4.81-23.83-11.15-5.92-8.16-3.94-17.49-3.85-17.89,3.07-14.22,14.66-35,15.16-35.87,3.38-5.67,9.24-8.65,16.94-8.65a36.72,36.72,0,0,1,11.3,1.88c14.49,4,17.39,19.81,17.62,21.26,2.75,8.12,1.54,34.45,1.49,35.57-1.45,10-10.59,15.51-25.77,15.51Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P41]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P41] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P41] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P41] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P41] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M272.25,380.87a35.84,35.84,0,0,1,11,1.82c14.72,4.05,17,20.56,17,20.56,2.75,7.93,1.46,35.28,1.46,35.28-1.79,12.38-15.41,14.56-24.77,14.56a59.9,59.9,0,0,1-8.9-.64c-32.37-3.08-26.86-27.84-26.86-27.84,3.07-14.24,15.05-35.6,15.05-35.6,3.87-6.48,10.4-8.14,16.07-8.14m0-2c-8.08,0-14.23,3.15-17.79,9.11v0l0,0c-.49.88-12.16,21.79-15.26,36.16-.09.4-2.16,10.16,4,18.67,4.77,6.58,13,10.47,24.54,11.57a60.7,60.7,0,0,0,9.15.66c15.74,0,25.23-5.78,26.75-16.28v-.19c.06-1.12,1.27-27.44-1.5-35.84-.33-2.07-3.31-17.82-18.34-22a37.85,37.85,0,0,0-11.56-1.91Z"
        />
        <path
          className="cls-pts-shadow"
          d="M262.13,390.65c5.63-9.43,19.21-4.48,19.21-4.48a16,16,0,0,1,10.05,8.54s-7.92-4.63-22.36-4.45Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="272.21"
          cy="432.28"
          rx="16.72"
          ry="24.29"
          transform="translate(-195.22 638.36) rotate(-81.59)"
        />
        <text className="cls-text" transform="translate(259.81 420.41)">
          41
        </text>
        {allLabels[PointId.P41].length > 0 && (
          <text className="cls-text" transform="matrix(0.97, 0.24, -0.24, 0.97, 252.8, 485.12)">
            <>{allLabels[PointId.P41].join("|")}</>
          </text>
        )}
      </g>
      <g id="point-31">
        <path
          onClick={handlePointClick(PointId.P31)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P31] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P31] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P31] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P31] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P31]?.selected === true,
          })}
          d="M334,454.09c-15.17,0-24.32-5.48-25.75-15.42-.07-1.21-1.28-27.54,1.47-35.66.23-1.45,3.14-17.28,17.7-21.28a36.36,36.36,0,0,1,11.22-1.86c7.71,0,13.56,3,16.93,8.62.51.9,12.1,21.68,15.17,35.91.09.39,2.08,9.72-3.85,17.88-4.61,6.34-12.65,10.1-23.89,11.16A59.08,59.08,0,0,1,334,454.09Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P31]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P31] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P31] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P31] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P31] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M338.6,380.87c5.67,0,12.2,1.66,16.07,8.14,0,0,12,21.36,15.06,35.6,0,0,5.5,24.76-26.87,27.84a59.73,59.73,0,0,1-8.9.64c-9.36,0-23-2.18-24.76-14.56,0,0-1.3-27.35,1.45-35.28,0,0,2.27-16.51,17-20.56a35.73,35.73,0,0,1,11-1.82m0-2h0A37.92,37.92,0,0,0,327,380.78c-15,4.18-18,19.93-18.34,22-2.76,8.4-1.55,34.72-1.5,35.84v.1l0,.09c1.51,10.5,11,16.28,26.74,16.28a60.9,60.9,0,0,0,9.16-.66c11.5-1.1,19.76-5,24.54-11.57,6.19-8.51,4.11-18.27,4-18.68-3.1-14.36-14.77-35.27-15.26-36.15v0l0,0c-3.56-6-9.71-9.11-17.79-9.11Z"
        />
        <path
          className="cls-pts-shadow"
          d="M348.73,389.65c-5.64-9.43-19.22-4.48-19.22-4.48a16,16,0,0,0-10.05,8.54s7.92-4.63,22.36-4.45Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="338.64"
          cy="431.76"
          rx="24.29"
          ry="16.72"
          transform="translate(-46.99 41.4) rotate(-6.54)"
        />
        <text className="cls-text" transform="translate(324.12 423.41)">
          31
        </text>
        {allLabels[PointId.P31].length > 0 && (
          <text className="cls-text" transform="matrix(0.96, -0.28, 0.28, 0.96, 345.89, 488.89)">
            <>{allLabels[PointId.P31].join("|")}</>
          </text>
        )}
      </g>
      <g id="point-32">
        <path
          onClick={handlePointClick(PointId.P32)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P32] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P32] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P32] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P32] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P32]?.selected === true,
          })}
          d="M391.2,426.81a20,20,0,0,1-14.62-6.07c-.15-.15-12.54-13.55-18.25-29.43-.07-.21-5.42-17.6,8.92-26.48.93-.78,10.8-8.78,21.11-8.78a16.62,16.62,0,0,1,9.84,3.07l.05,0,.05,0c.16.15,16.05,15.2,25.45,28.32a16.66,16.66,0,0,1,3.57,12.38c-.74,5.93-4.71,11.65-11.8,17C415.07,417.29,403.52,426.81,391.2,426.81Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P32]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P32] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P32] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P32] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P32] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M388.36,357.06a15.42,15.42,0,0,1,9.25,2.88s15.88,15,25.33,28.17c.31.44,11.59,13.2-8,28,0,0-11.62,9.7-23.71,9.7A19,19,0,0,1,377.28,420s-12.3-13.2-18-29.07c0,0-5.35-16.77,8.56-25.33,0,0,10.16-8.58,20.53-8.58m0-2v0h0c-10.48,0-20.44,7.92-21.7,9-14.91,9.29-9.35,27.37-9.29,27.56v.06c5.78,16.08,17.92,29.21,18.43,29.76l0,0,0,0a21,21,0,0,0,15.33,6.36c12.44,0,24.05-9.38,25-10.13,7.29-5.51,11.38-11.44,12.15-17.64A17.67,17.67,0,0,0,424.57,387l0,0c-9.45-13.2-24.9-27.82-25.55-28.44l-.1-.09-.11-.08a17.56,17.56,0,0,0-10.42-3.25Z"
        />
        <path
          className="cls-pts-shadow"
          d="M399.34,368c-3-3-5-5-5-5-10-7.13-23.81,4.56-23.81,4.56-11.11,6.84-6.84,20.24-6.84,20.24.24.67.5,1.33.76,2,0,0,5.08-15.89,25-20.42Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="397.42"
          cy="401.48"
          rx="24.29"
          ry="16.72"
          transform="translate(-155.56 286) rotate(-33.5)"
        />
        <text className="cls-text" transform="translate(378.27 396.28)">
          32
        </text>
        {allLabels[PointId.P32].length > 0 && (
          <text className="cls-text" transform="matrix(0.79, -0.61, 0.61, 0.79, 427.27, 458.35)">
            <>{allLabels[PointId.P32].join("|")}</>
          </text>
        )}
      </g>
      <g id="point-33">
        <path
          onClick={handlePointClick(PointId.P33)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P33] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P33] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P33] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P33] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P33]?.selected === true,
          })}
          d="M441.17,387.4a21.34,21.34,0,0,1-12.74-4.16l-.06-.05-.06-.06c-.79-.78-19.53-19.39-23.82-27.07-3.9-5.88-7.65-19.59,6.37-28.88.43-.33,11.86-8.95,24.42-8.95a22.76,22.76,0,0,1,12.54,3.57c.95.44,9.89,4.87,24.34,20.1l.1.1.06.12c.1.19,9.86,19-6,33.75C465.86,376.32,454.33,387.4,441.17,387.4Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P33]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P33] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P33] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P33] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P33] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M435.28,319.23a21.75,21.75,0,0,1,12.05,3.44s9.09,4.09,24.11,19.92c0,0,9.68,18.14-5.79,32.54,0,0-11.57,11.27-24.48,11.27a20.27,20.27,0,0,1-12.15-4s-19.39-19.2-23.65-26.85c0,0-10.85-16.36,6-27.56,0,0,11.5-8.78,23.87-8.78m0-2h0c-12.58,0-24,8.39-25,9.15-17.62,11.73-7.45,28.86-6.6,30.21,4.4,7.83,23.16,26.46,24,27.26l.11.1.12.09a22.34,22.34,0,0,0,13.33,4.36c13.56,0,25.38-11.35,25.87-11.83a27.94,27.94,0,0,0,9.3-23.26,31.92,31.92,0,0,0-3.14-11.66l-.13-.24-.18-.2c-14.26-15-23.29-19.67-24.59-20.29a23.75,23.75,0,0,0-13-3.69Z"
        />
        <path
          className="cls-pts-shadow"
          d="M439.7,324.52c-11.86-5.13-25.79,5.5-25.79,5.5-13.28,8.81-4.76,21.67-4.76,21.67S411,341.23,421.8,333a38.08,38.08,0,0,1,9.41-5.19Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="449.03"
          cy="361.1"
          rx="24.29"
          ry="16.72"
          transform="translate(-126.58 387.37) rotate(-41.42)"
        />
        <text className="cls-text" transform="translate(426.23 355.63)">
          33
        </text>
        {allLabels[PointId.P33].length > 0 && (
          <text className="cls-text" transform="matrix(0.72, -0.69, 0.69, 0.72, 486.48, 414.1)">
            {allLabels[PointId.P33].join("|")}
          </text>
        )}
      </g>
      <g id="point-34">
        <path
          onClick={handlePointClick(PointId.P34)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P34] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P34] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P34] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P34] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P34]?.selected === true,
          })}
          d="M482.34,333.33a30.62,30.62,0,0,1-7.95-1.11l-.07,0-.08,0c-1.41-.64-34.81-15.78-40.76-31.75-.08-.28-5.51-20.68,14.62-29.43a31.45,31.45,0,0,1,13.66-3.13A27,27,0,0,1,474.62,271l14.72,7.21,15.74,8.27.1.1c.37.34,9,8.69,2.77,25C507.91,311.79,501.59,333.33,482.34,333.33Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P34]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P34] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P34] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P34] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P34] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M461.76,268.85a26,26,0,0,1,12.39,3l14.75,7.22,15.6,8.2s8.55,8.05,2.51,24c0,0-5.95,21-24.67,21a29.43,29.43,0,0,1-7.69-1.08s-34.37-15.42-40.23-31.18c0,0-5.49-19.67,14.08-28.17a30.63,30.63,0,0,1,13.26-3m0-2h0a32.67,32.67,0,0,0-14.13,3.25c-20.84,9.1-15.2,30.29-15.14,30.5l0,.08,0,.08c6.09,16.36,39.86,31.67,41.29,32.31l.15.07.15,0a31.77,31.77,0,0,0,8.21,1.15c8.32,0,15.57-3.92,21-11.34a39.45,39.45,0,0,0,5.6-11.06c6.38-16.93-2.66-25.7-3-26.06l-.2-.19-.24-.13-15.59-8.2h-.06l-14.72-7.21a28.06,28.06,0,0,0-13.3-3.27Z"
        />
        <path
          className="cls-pts-shadow"
          d="M463.67,275.33a21.52,21.52,0,0,0-15,1.42C434.65,282.86,438.59,297,438.59,297a20.1,20.1,0,0,0,4.72,6.77A31.6,31.6,0,0,1,458,278.67Z"
        />
        <text className="cls-text" transform="translate(459.54 305.5)">
          34
        </text>
        {allLabels[PointId.P34].length > 0 && (
          <text className="cls-text" transform="translate(540.32 345.58) rotate(-58.51)">
            {allLabels[PointId.P34].join("|")}
          </text>
        )}
      </g>
      <g id="point-35">
        <path
          onClick={handlePointClick(PointId.P35)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P35] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P35] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P35] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P35] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P35]?.selected === true,
          })}
          d="M506.67,280.12a22,22,0,0,1-7-1.2c-.19,0-26.25-6.83-42.22-23l-.14-.14-.08-.19c-.09-.21-8.54-21.46,6.54-32.12.75-.67,8.08-6.88,23.5-7.78h.12l.12,0c.45.08,11.35,2.13,32.84,10.69a21,21,0,0,1,10.8,10.39c2.56,5.64,2.39,12.39-.51,20.06a47.3,47.3,0,0,1-5.42,11.5C520.2,276.06,513.78,280.12,506.67,280.12Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P35]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P35] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P35] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P35] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P35] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M487.34,216.7s11,2,32.65,10.64c0,0,18.17,6.79,9.73,29.17,0,0-6.53,22.61-23.05,22.61A21.1,21.1,0,0,1,500,278s-25.87-6.61-41.83-22.75c0,0-8.44-20.66,6.23-31,0,0,7.16-6.64,22.94-7.56m.12-2h-.24c-15.43.9-23,7.08-24.06,8-6.52,4.63-9.77,11.89-9.38,21A39.85,39.85,0,0,0,456.32,256l.15.37.27.28c15.91,16.09,40.87,22.79,42.69,23.27a23.25,23.25,0,0,0,7.24,1.22c7.46,0,14.17-4.22,19.39-12.21a48.5,48.5,0,0,0,5.55-11.76c3-7.91,3.12-14.89.46-20.76a22,22,0,0,0-11.38-10.92c-21.54-8.58-32.53-10.65-33-10.74l-.24,0Z"
        />
        <path
          className="cls-pts-shadow"
          d="M483,222.51c-11.45.66-16.65,5.48-16.65,5.48-10.66,7.49-4.53,22.49-4.53,22.49,1,1,2,1.91,3.05,2.8,0,0-1.71-17.52,13.22-27.86Z"
        />
        <text className="cls-text" transform="translate(484.71 254.3)">
          35
        </text>
        {allLabels[PointId.P35].length > 0 && (
          <text className="cls-text" transform="translate(569.23 285.61) rotate(-62.81)">
            {allLabels[PointId.P35].join("|")}
          </text>
        )}
      </g>
      <g id="point-36">
        <path
          onClick={handlePointClick(PointId.P36)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P36] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P36] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P36] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P36] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P36]?.selected === true,
          })}
          d="M527.49,225.26c-5.63,0-12.08-1.43-19.14-4.26l-25.57-10.51c-.44-.18-11-4.58-12.52-16-.62-1.73-6.42-19.65,13.32-36.43.78-.92,7.37-8.07,25.16-8.07a79.26,79.26,0,0,1,10,.68,189,189,0,0,1,20.91,5.15l.08,0,.07,0a26.75,26.75,0,0,1,7.92,7c3.43,4.39,7.19,12,5.67,23,0,.37-.58,11.32-6.4,29.14C546.82,215.49,542.38,225.26,527.49,225.26Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P36]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P36] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P36] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P36] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P36] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M508.74,151a75.83,75.83,0,0,1,9.91.68,182.17,182.17,0,0,1,20.73,5.11s15.9,8.23,13.06,29c0,0-.51,11.07-6.36,29,0,0-4.18,9.58-18.59,9.58-5,0-11.14-1.14-18.77-4.19l-25.56-10.51s-10.5-4.25-11.92-15.33c0,0-7.38-18.17,13.06-35.49,0,0,6.1-7.77,24.44-7.77m0-2h0c-17.76,0-24.75,7.08-25.86,8.36-11,9.34-14.08,19-14.76,25.44a27.79,27.79,0,0,0,1.17,11.95c1.66,11.92,12.65,16.51,13.12,16.7L508,221.92c7.2,2.88,13.77,4.34,19.53,4.34,15.54,0,20.23-10.34,20.42-10.79l0-.08,0-.09c5.65-17.28,6.39-28.2,6.45-29.4,1.53-11.35-2.35-19.13-5.88-23.67A27.85,27.85,0,0,0,540.3,155l-.15-.08-.15-.05a183.4,183.4,0,0,0-21-5.17h-.1a79.35,79.35,0,0,0-10.17-.69Z"
        />
        <path
          className="cls-pts-shadow"
          d="M502.59,158.08c-9.81-.55-17.19,5.63-17.19,5.63-15.26,12.93-9.75,26.49-9.75,26.49a13.86,13.86,0,0,0,4.39,8.41,51.8,51.8,0,0,1,1.61-7.51c3.57-12.68,9-23.19,19.07-31.72Z"
        />
        <text className="cls-text" transform="translate(500.58 195.19)">
          36
        </text>
        {allLabels[PointId.P36].length > 0 && (
          <text className="cls-text" transform="matrix(0.24, -0.97, 0.97, 0.24, 591.19, 216.25)">
            {allLabels[PointId.P36].join("|")}
          </text>
        )}
      </g>
      <g id="point-37">
        <path
          onClick={handlePointClick(PointId.P37)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P37] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P37] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P37] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P37] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P37]?.selected === true,
          })}
          d="M526.28,149.07a107.21,107.21,0,0,1-25.57-2.68l-.11,0c-.22-.08-22.83-8.81-21.08-34-.08-1.64-1-27.58,25.23-29.52.12,0,12.25-.94,25.44-.94a171.07,171.07,0,0,1,19.66,1H550l.1,0c.19.07,19.17,6.83,19.17,32.63a60.2,60.2,0,0,1-2.83,14.7c-3.35,10-9,16-16.49,17.3A176.35,176.35,0,0,1,526.28,149.07Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P37]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P37] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P37] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P37] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P37] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M530.19,82.84a171.53,171.53,0,0,1,19.54.94s18.5,6.3,18.5,31.68c0,0-1.24,28-18.5,31a173.07,173.07,0,0,1-23.45,1.6A107.57,107.57,0,0,1,501,145.42s-22.19-8.28-20.42-33.11c0,0-1.94-26.59,24.3-28.53,0,0,12.05-.94,25.36-.94m0-2v0c-13.23,0-25.4.94-25.52,1C490.1,82.86,483.75,91.21,481,98a35.66,35.66,0,0,0-2.46,14.28c-.91,13.52,5,22.31,10.17,27.31a36.91,36.91,0,0,0,11.56,7.68l.11,0,.11,0a108.17,108.17,0,0,0,25.81,2.71A173.14,173.14,0,0,0,550,148.45c7.86-1.36,13.83-7.58,17.31-18a60.81,60.81,0,0,0,2.87-14.94v-.08c0-13.79-5.34-22.16-9.82-26.76a28.06,28.06,0,0,0-10-6.81l-.2-.07-.21,0a173.34,173.34,0,0,0-19.78-1Z"
        />
        <path
          className="cls-pts-shadow"
          d="M502.83,88.81c-21.95,1.63-20.33,23.87-20.33,23.87-1.47,20.78,17.09,27.7,17.09,27.7S495.15,128,498,101.31a26.27,26.27,0,0,1,4.81-12.5Z"
        />
        <text className="cls-text" transform="translate(512.42 120.88)">
          37
        </text>
        {allLabels[PointId.P37].length > 0 && (
          <text className="cls-text" transform="translate(603.12 134.71) rotate(-83.77)">
            {allLabels[PointId.P37].join("|")}
          </text>
        )}
      </g>
      <g id="point-38">
        <path
          onClick={handlePointClick(PointId.P38)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P38] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P38] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P38] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P38] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P38]?.selected === true,
          })}
          d="M528.88,76.41a115.55,115.55,0,0,1-27.65-2.91h-.06l-.05,0c-.25-.09-24.65-9.52-22.76-36.78-.09-1.71-1.08-29.77,27.23-31.86.13,0,13.24-1,27.52-1a185.57,185.57,0,0,1,21.27,1h.11l.1,0c.2.07,20.69,7.37,20.69,35.23A64.89,64.89,0,0,1,572.22,56c-3.61,10.82-9.76,17.28-17.79,18.67A189.63,189.63,0,0,1,528.88,76.41Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P38]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P38] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P38] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P38] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P38] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M533.11,4.81a187.57,187.57,0,0,1,21.15,1s20,6.82,20,34.29c0,0-1.34,30.33-20,33.57a187.46,187.46,0,0,1-25.38,1.73c-8.77,0-18.61-.68-27.41-2.88,0,0-24-9-22.11-35.83,0,0-2.1-28.78,26.3-30.88,0,0,13.05-1,27.45-1m0-2v0c-14.31,0-27.47,1-27.6,1C489.82,5,483,14,480,21.3a38.1,38.1,0,0,0-2.65,15.39c-1,14.58,5.4,24.05,10.95,29.44a39.83,39.83,0,0,0,12.46,8.28l.11,0,.1,0a116.66,116.66,0,0,0,27.9,2.94,187.44,187.44,0,0,0,25.69-1.76c8.44-1.46,14.86-8.15,18.6-19.34a65.08,65.08,0,0,0,3.1-16.11v-.09c0-14.86-5.75-23.89-10.58-28.84a30.32,30.32,0,0,0-10.79-7.34l-.2-.07-.21,0a185.41,185.41,0,0,0-21.39-1Z"
        />
        <path
          className="cls-pts-shadow"
          d="M502.5,13.63c-21.59,1.59-20,23.47-20,23.47-1.11,15.63,9.31,23.29,14.27,26,0,0-3.93-24.75,3.13-43.82Z"
        />
        <text className="cls-text" transform="translate(514.76 49.12)">
          38
        </text>
        {allLabels[PointId.P38].length > 0 && (
          <text className="cls-text" transform="matrix(-0.11, -0.99, 0.99, -0.11, 602.24, 46.83)">
            {allLabels[PointId.P38].join("|")}
          </text>
        )}
      </g>
      {dotOptions.visible === true && (
        <>
          {dots[DotId.P48_P47]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P48_P47)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P48_P47]?.selected === true })}
              cx="155.1"
              cy="79.18"
              r="14"
            />
          )}
          {dots[DotId.P47_P46]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P47_P46)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P47_P46]?.selected === true })}
              cx="157.67"
              cy="137.59"
              r="14"
            />
          )}
          {dots[DotId.P46_P45]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P46_P45)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P46_P45]?.selected === true })}
              cx="165.63"
              cy="197.51"
              r="14"
            />
          )}
          {dots[DotId.P45_P44]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P45_P44)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P45_P44]?.selected === true })}
              cx="185.59"
              cy="247.91"
              r="14"
            />
          )}
          {dots[DotId.P44_P43]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P44_P43)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P44_P43]?.selected === true })}
              cx="206.54"
              cy="292.17"
              r="14"
            />
          )}
          {dots[DotId.P43_P42]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P43_P42)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P43_P42]?.selected === true })}
              cx="235.06"
              cy="320.23"
              r="14"
            />
          )}
          {dots[DotId.P42_P41]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P42_P41)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P42_P41]?.selected === true })}
              cx="265.26"
              cy="343.92"
              r="14"
            />
          )}
          {dots[DotId.P41_P31]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P41_P31)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P41_P31]?.selected === true })}
              cx="301.96"
              cy="354.36"
              r="14"
            />
          )}
          {dots[DotId.P31_P32]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P31_P32)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P31_P32]?.selected === true })}
              cx="342.26"
              cy="350.04"
              r="14"
            />
          )}
          {dots[DotId.P32_P33]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P32_P33)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P32_P33]?.selected === true })}
              cx="378.1"
              cy="328.8"
              r="14"
            />
          )}
          {dots[DotId.P33_P34]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P33_P34)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P33_P34]?.selected === true })}
              cx="408.08"
              cy="296.84"
              r="14"
            />
          )}
          {dots[DotId.P34_P35]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P34_P35)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P34_P35]?.selected === true })}
              cx="423.08"
              cy="252.12"
              r="14"
            />
          )}
          {dots[DotId.P35_P36]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P35_P36)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P35_P36]?.selected === true })}
              cx="440.75"
              cy="198.24"
              r="14"
            />
          )}
          {dots[DotId.P36_P37]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P36_P37)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P36_P37]?.selected === true })}
              cx="449.03"
              cy="144.37"
              r="14"
            />
          )}
          {dots[DotId.P37_P38]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P37_P38)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P37_P38]?.selected === true })}
              cx="455.75"
              cy="82.84"
              r="14"
            />
          )}
        </>
      )}
    </svg>
  );
};

export default LowerJawView;
