import * as React from "react";
import Result, { ResultProps } from "antd/lib/result";
import { IDomainError } from "core/errors/domain-error";
import { useIntl } from "react-intl";
import { ErrorCode } from "core/errors/error-code";

interface IErrorScreenProps extends Omit<ResultProps, "status"> {
  error: IDomainError | ErrorCode | string;
}

const ErrorScreen: React.FunctionComponent<IErrorScreenProps> = ({ error, ...rest }) => {
  const intl = useIntl();

  const key = {
    title: typeof error === "object" && error !== null ? `error-messages.${error.code}` : `error-messages.${error}`,
    subTitle:
      typeof error === "object" && error !== null
        ? `error-messages.${error.code}-sub-title`
        : `error-messages.${error}-sub-title`,
  };

  const translationsExist = {
    title: intl !== null && intl.messages !== null ? !!intl.messages[key.title] : false,
    subTitle: intl !== null && intl.messages !== null ? !!intl.messages[key.subTitle] : false,
  };

  const fallback = typeof error === "object" && error !== null ? error.message : error;

  if (translationsExist.title && translationsExist.subTitle) {
    return (
      <>
        <Result
          status={"error"}
          title={intl.formatMessage({ id: key.title })}
          subTitle={intl.formatMessage({ id: key.subTitle })}
        />
      </>
    );
  } else if (translationsExist.title && !translationsExist.subTitle) {
    return (
      <>
        <Result status={"error"} title={intl.formatMessage({ id: key.title })} />
      </>
    );
  } else {
    return (
      <>
        <Result status={"error"} title={key.title}>
          {fallback}
        </Result>
      </>
    );
  }
};

export default ErrorScreen;
