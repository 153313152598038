import { IMoney } from "core/money/money.model";

export enum PricingFormType {
  PricingNotSet = 1,
  IndividualPricing,
  FixedPrice,
  Quantity,
  ArchEqualPrices,
  PointEqualPrices,
  UpperAndLowerArchPrices,
  FrontAndBackPointPrices,
  PointWithBaseArchPrice,
  CrownEqualPrices,
  FrontAndBackCrownPrices,
  BridgePointEqualPrices,
  FrontAndBackBridgePointPrices,
  MarylandBridgePointEqualPrices,
  FrontAndBackMarylandBridgePointPrices,
  FoundationPointEqualPrices,
}

export type PricingNotSetFormValues = {
  formType: PricingFormType.PricingNotSet;
};

export type IndividualPricingFormValues = {
  formType: PricingFormType.IndividualPricing;
};

export type FixedPricingFormValues = {
  formType: PricingFormType.FixedPrice;
  fixedPrice: IMoney;
};

export type QuantityPricingFormValues = {
  formType: PricingFormType.Quantity;
  itemPrice: IMoney;
};

export type ArchEqualPricingFormValues = {
  formType: PricingFormType.ArchEqualPrices;
  archPrice1: IMoney;
};

export type PointEqualPricingFormValues = {
  formType: PricingFormType.PointEqualPrices;
  point1: IMoney;
};

export type UpperAndLowerArchFormValues = {
  formType: PricingFormType.UpperAndLowerArchPrices;
  archPrice1: IMoney;
  archPrice2: IMoney;
};

export type PointWithBaseArchPricingFormValues = {
  formType: PricingFormType.PointWithBaseArchPrice;
  itemPrice: IMoney;
  baseArchPrice: IMoney;
};

export type FrontAndBackPointPricingFormValues = {
  formType: PricingFormType.FrontAndBackPointPrices;
  point1: IMoney;
  point2: IMoney;
};

export type CrownEqualPricingFormValues = {
  formType: PricingFormType.CrownEqualPrices;
  cementedToPreparedTooth1: IMoney;
  cementedToImplant1: IMoney;
  screwedToImplant1: IMoney;
};

export type FrontAndBackCrownPricingFormValues = {
  formType: PricingFormType.FrontAndBackCrownPrices;
  cementedToPreparedTooth1: IMoney;
  cementedToImplant1: IMoney;
  screwedToImplant1: IMoney;
  cementedToPreparedTooth2: IMoney;
  cementedToImplant2: IMoney;
  screwedToImplant2: IMoney;
};

export type BridgePointEqualPricingFormValues = {
  formType: PricingFormType.BridgePointEqualPrices;
  cementedToPreparedTooth1: IMoney;
  cementedToImplant1: IMoney;
  screwedToImplant1: IMoney;
  pontic1: IMoney;
};

export type FrontAndBackBridgePointPricingFormValues = {
  formType: PricingFormType.FrontAndBackBridgePointPrices;
  cementedToPreparedTooth1: IMoney;
  cementedToImplant1: IMoney;
  screwedToImplant1: IMoney;
  pontic1: IMoney;
  cementedToPreparedTooth2: IMoney;
  cementedToImplant2: IMoney;
  screwedToImplant2: IMoney;
  pontic2: IMoney;
};

export type MarylandBridgePointEqualPricingFormValues = {
  formType: PricingFormType.MarylandBridgePointEqualPrices;
  cementedToPreparedTooth1: IMoney;
  pontic1: IMoney;
};

export type FrontAndBackMarylandBridgePointPricingFormValues = {
  formType: PricingFormType.FrontAndBackMarylandBridgePointPrices;
  cementedToPreparedTooth1: IMoney;
  pontic1: IMoney;
  cementedToPreparedTooth2: IMoney;
  pontic2: IMoney;
};

export type FoundationPointEqualPricingFormValues = {
  formType: PricingFormType.FoundationPointEqualPrices;
  cementedToImplant1: IMoney;
  screwedToImplant1: IMoney;
  pontic1: IMoney;
};

export type IPricingFormValues =
  | PricingNotSetFormValues
  | IndividualPricingFormValues
  | FixedPricingFormValues
  | QuantityPricingFormValues
  | ArchEqualPricingFormValues
  | PointEqualPricingFormValues
  | UpperAndLowerArchFormValues
  | FrontAndBackPointPricingFormValues
  | PointWithBaseArchPricingFormValues
  | CrownEqualPricingFormValues
  | FrontAndBackCrownPricingFormValues
  | BridgePointEqualPricingFormValues
  | FrontAndBackBridgePointPricingFormValues
  | MarylandBridgePointEqualPricingFormValues
  | FrontAndBackMarylandBridgePointPricingFormValues
  | FoundationPointEqualPricingFormValues;

export type MergedPricingFormValues = Omit<PricingNotSetFormValues, "formType"> &
  Omit<IndividualPricingFormValues, "formType"> &
  Omit<FixedPricingFormValues, "formType"> &
  Omit<QuantityPricingFormValues, "formType"> &
  Omit<ArchEqualPricingFormValues, "formType"> &
  Omit<UpperAndLowerArchFormValues, "formType"> &
  Omit<PointEqualPricingFormValues, "formType"> &
  Omit<FrontAndBackPointPricingFormValues, "formType"> &
  Omit<PointWithBaseArchPricingFormValues, "formType"> &
  Omit<CrownEqualPricingFormValues, "formType"> &
  Omit<FrontAndBackCrownPricingFormValues, "formType"> &
  Omit<BridgePointEqualPricingFormValues, "formType"> &
  Omit<FrontAndBackBridgePointPricingFormValues, "formType"> &
  Omit<MarylandBridgePointEqualPricingFormValues, "formType"> &
  Omit<FrontAndBackMarylandBridgePointPricingFormValues, "formType"> &
  Omit<FoundationPointEqualPricingFormValues, "formType">;
