import * as React from "react";
import classNames from "classnames";
import { Button, Popconfirm, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { ArrowRightOutlined, ArrowLeftOutlined} from "@ant-design/icons";
import { noop } from "lodash";
import { PopconfirmProps } from "antd/lib/popconfirm";

interface INextPrevButtonsProps {
  nextVisibility?: "visible" | "invisible" | "disabled" | "none";
  previousVisibility?: "visible" | "invisible" | "disabled" | "none";
  prevPopconfirm?: PopconfirmProps;
  nextPopconfirm?: PopconfirmProps;
  next?: React.ReactNode;
  prev?: React.ReactNode;
  onPrevStep?: () => void;
  onNextStep?: () => void;
}

const DirectionButton = (props: {
  direction: "prev" | "next";
  visibility: "visible" | "invisible" | "disabled" | "none" | undefined;
  content?: React.ReactNode;
  onClick?: () => void;
}) => (
  <Button
    className={classNames({
      invisible: props.visibility === "invisible",
      hidden: props.visibility === "none",
    })}
    disabled={props.visibility === "disabled"}
    type={props.direction === "next" ? "primary" : "default"}
    onClick={props.onClick}
  >
    {(props.content === null || props.content === undefined) && (
      <>
        {props.direction === "prev" ? (
          <>
            <ArrowLeftOutlined className="mr-2" />
            <FormattedMessage id="common.previous-step" defaultMessage="Poprzedni krok" />
          </>
        ) : (
          <>
            <FormattedMessage id="common.next-step" defaultMessage="Dalej" />
            <ArrowRightOutlined className="ml-2" />
          </>
        )}
      </>
    )}
    {props.content !== null && props.content === undefined && <>{props.content}</>}
  </Button>
);

const NextPrevButtons: React.FunctionComponent<INextPrevButtonsProps> = ({
  nextVisibility = "visible",
  previousVisibility = "visible",
  next = null,
  prev = null,
  onNextStep = noop,
  onPrevStep = noop,
  prevPopconfirm,
  nextPopconfirm,
}) => {
  return (
    <Space>
      {prevPopconfirm === undefined ? (
        <DirectionButton direction={"prev"} visibility={previousVisibility} content={prev} onClick={onPrevStep} />
      ) : (
        <Popconfirm {...prevPopconfirm} onConfirm={onPrevStep}>
          <DirectionButton direction={"prev"} visibility={previousVisibility} content={prev} />
        </Popconfirm>
      )}
      {nextPopconfirm === undefined ? (
        <DirectionButton direction={"next"} visibility={nextVisibility} content={next} onClick={onNextStep} />
      ) : (
        <Popconfirm {...nextPopconfirm} onConfirm={onPrevStep}>
          <DirectionButton direction={"next"} visibility={nextVisibility} content={next} />
        </Popconfirm>
      )}
    </Space>
  );
};

export default NextPrevButtons;
