import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller} from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { IShortLab } from "features/dental-lab/dental-lab.model";

export const LabControlReadonly = (props: { labs: IShortLab[]; control: Control<IOrderFormValues, Object> }) => {
  const getLabName = (labs: IShortLab[], selectedLabId: string) => {
    const lab = labs.find((l) => l.id.value === selectedLabId);

    if (lab === undefined) {
      return "";
    }

    return lab.name;
  };

  return (
    <Form.Item required label={<FormattedMessage id="common.lab" defaultMessage="Laboratorium" />}>
      <Controller
        name="labId"
        control={props.control}
        render={({ field: { value, ...otherProps } }) => <div>{getLabName(props.labs, value)}</div>}
      />
    </Form.Item>
  );
};
