import * as React from "react";
import { useState } from "react";
import { Divider, List, Radio, Space, Typography } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { SettingsSection } from "../../settings-section/SettingsSection";
import { FormattedMessage } from "react-intl";
import useSettingsSection from "../../settings-section/useSettingsSection";
import { TextProps } from "antd/lib/typography/Text";
import { InvoiceNumberingSchemeCode } from "../../../contracts/settings.dto";

const invoiceNumberSchemeExamples = {
  [InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_DASHED]: [
    "2021-01-001",
    "2021-01-002",
    "2021-01-003",
    "2021-02-001",
    "2021-02-002",
    "2021-02-003",
  ],
  [InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_DASHED]: [
    "2021-01-001",
    "2021-01-002",
    "2021-01-003",
    "2021-02-004",
    "2021-02-005",
    "2021-02-006",
  ],
  [InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_FORWARD_SLASHED]: [
    "2021/01/001",
    "2021/01/002",
    "2021/01/003",
    "2021/02/001",
    "2021/02/002",
    "2021/02/003",
  ],
  [InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_FORWARD_SLASHED]: [
    "2021/01/001",
    "2021/01/002",
    "2021/01/003",
    "2021/02/004",
    "2021/02/005",
    "2021/02/006",
  ],
};

export const InvoiceNumberingSchemeTitle: React.FunctionComponent<
  {
    value: InvoiceNumberingSchemeCode;
  } & TextProps
> = ({ value, ...props }) => {
  switch (value) {
    case InvoiceNumberingSchemeCode.NONE:
      return <Typography.Text {...props}>Nie wybrano schematu numeracji faktur</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_DASHED:
      return <Typography.Text {...props}>RRRR-MM-xyz</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_DASHED:
      return <Typography.Text {...props}>RRRR-MM-XYZ</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_FORWARD_SLASHED:
      return <Typography.Text {...props}>RRRR/MM/xyz</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_FORWARD_SLASHED:
      return <Typography.Text {...props}>RRRR/MM/XYZ</Typography.Text>;
  }

  throw new Error("unknown");
};

export const InvoiceNumberingSchemeSubTitle: React.FunctionComponent<
  {
    value: InvoiceNumberingSchemeCode;
  } & TextProps
> = ({ value, ...props }) => {
  switch (value) {
    case InvoiceNumberingSchemeCode.NONE:
      return <Typography.Text {...props}>Numer kolejnej faktury będzie pusty</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_DASHED:
      return <Typography.Text {...props}>numeracja w cyklu miesięcznym</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_DASHED:
      return <Typography.Text {...props}>numeracja w cyklu rocznym</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_FORWARD_SLASHED:
      return <Typography.Text {...props}>numeracja w cyklu miesięcznym</Typography.Text>;
    case InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_FORWARD_SLASHED:
      return <Typography.Text {...props}>numeracja w cyklu rocznym</Typography.Text>;
  }

  throw new Error("unknown");
};

const schemes = [
  InvoiceNumberingSchemeCode.NONE,
  InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_DASHED,
  InvoiceNumberingSchemeCode.YEAR_MONTH_INV_MONTH_FORWARD_SLASHED,
  InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_DASHED,
  InvoiceNumberingSchemeCode.YEAR_MONTH_INV_YEAR_FORWARD_SLASHED,
];

export const InvoiceNumberingSchemeSection: React.FunctionComponent<{
  value: InvoiceNumberingSchemeCode;
  onChange: (value: InvoiceNumberingSchemeCode) => void;
}> = ({ value: prevValue, onChange }) => {
  const { isEditing, onEdit, onCancel } = useSettingsSection();
  const [currValue, setCurrValue] = useState(prevValue);

  const handleSave = React.useCallback(async () => {
    if (prevValue === currValue) {
      onCancel();
      return;
    }

    await onChange(currValue);
    onCancel();
  }, [prevValue, currValue, onChange, onCancel]);

  return (
    <SettingsSection isEditing={isEditing} onEdit={onEdit} onCancel={onCancel} onSave={handleSave}>
      <>
        {!isEditing && (
          <List.Item.Meta
            title={
              <FormattedMessage id="invoicing.invoice-numbering-scheme" defaultMessage="Sposób numerowania faktur" />
            }
            description={
              <div className={"flex flex-col"}>
                <InvoiceNumberingSchemeTitle type={"secondary"} value={prevValue} />
                <InvoiceNumberingSchemeSubTitle type={"secondary"} value={prevValue} />
              </div>
            }
          />
        )}
        {isEditing && (
          <Radio.Group value={currValue} onChange={(e: RadioChangeEvent) => setCurrValue(e.target.value)}>
            <Space direction="vertical">
              {schemes.map((scheme) => (
                <Radio value={scheme}>
                  <div className={"flex flex-col"}>
                    <InvoiceNumberingSchemeTitle value={scheme} />
                    <InvoiceNumberingSchemeSubTitle type={"secondary"} value={scheme} />
                    {scheme !== InvoiceNumberingSchemeCode.NONE && (
                      <Typography.Text type={"secondary"}>
                        {invoiceNumberSchemeExamples[scheme].map((e, idx, arr) => (
                          <>
                            {e}
                            {idx !== arr.length - 1 && <Divider type={"vertical"} />}
                          </>
                        ))}
                      </Typography.Text>
                    )}
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      </>
    </SettingsSection>
  );
};
