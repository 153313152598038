import ProList from "@ant-design/pro-list";
import * as React from "react";
import { IDentist } from "../../dentist.model";
import PersonNameFormatter from "../../../../components/formatters/person-name/PersonNameFormatter";
import { Button, Dropdown, Menu, Space, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import { ReactText, useRef, useState } from "react";
import useSize from "../../../../hooks/useSize";
import classNames from "classnames";
import { ListItemLayout } from "antd/lib/list";
import { isNotEmptyString } from "core/utils/utils";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { Path } from "core/routes/routes";

import "./dentists-list.scss";

interface IDentistListProps {
  dentists: IDentist[];
  loading?: boolean;
  onEdit?: (dentist: IDentist) => void;
  onDelete?: (dentist: IDentist) => void;
  onAssign?: (dentist: IDentist) => void;
}

enum Size {
  Small = "sm",
  Large = "lg",
}

const DentistList: React.FC<IDentistListProps> = ({ dentists, loading = false, onEdit, onDelete, onAssign }) => {
  let ref = useRef(null);

  const [size] = useSize(ref, {
    [Size.Small]: 0,
    [Size.Large]: 700,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly ReactText[]>([]);
  const layout: ListItemLayout = size === Size.Large ? "horizontal" : "vertical";

  return (
    <div className={"dentists-list"} ref={ref}>
      <ProList<IDentist>
        split
        pagination={{
          hideOnSinglePage: true,
          pageSize: 20,
        }}
        size={"large"}
        showActions="always"
        itemLayout={layout}
        headerTitle={<FormattedMessage id="dentist-list.dentist-list" defaultMessage="Lista dentystów" />}
        rowKey={(record) => record.id.value}
        loading={loading}
        rowClassName={() =>
          classNames("dentists-list__dentists-row", {
            ["dentists-list__row--vertical"]: layout === "vertical",
            ["dentists-list__row--horizontal"]: layout === "horizontal",
          })
        }
        dataSource={dentists}
        expandable={{
          expandedRowKeys,
          defaultExpandAllRows: false,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
        metas={{
          title: {
            render: (dom, entity) => (
              <span>
                <PersonNameFormatter personName={entity.name} />
              </span>
            ),
          },
          subTitle: {
            render: (dom, entity) =>
              isNotEmptyString(entity.clinicName) ? (
                <Space size={0}>
                  <Tag color="blue">{entity.clinicName}</Tag>
                </Space>
              ) : null,
          },
          description: {
            render: () => (
              <ul>
                <li>brak danych do faktury</li>
                <li>
                  przyjęto zaproszenie do aplikacji <CheckCircleOutlined />
                </li>
              </ul>
            ),
          },
          actions: {
            render: (text, entity) => [
              <Button
                key={"invite"}
                type={"link"}
                href={parametrizePath({ path: Path.InviteDentist, params: { dentistId: entity.id.value } })}
              >
                <FormattedMessage id="dentist-list.send-invitation-link" defaultMessage="Zaproś do aplikacji" />
              </Button>,
              <Dropdown
                key={"actions-dropdown"}
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item key={"assign"} onClick={() => onAssign?.(entity)}>
                      <Button type={"link"}>
                        <FormattedMessage
                          id="dentist-list.assign-dentist-to-pricing-list"
                          defaultMessage="Wybierz cennik"
                        />
                      </Button>
                    </Menu.Item>
                    <Menu.Item key={"edit"} onClick={() => onEdit?.(entity)}>
                      <Button type={"link"}>
                        <FormattedMessage id="dentist-list.edit-dentist" defaultMessage="Edycja" />
                      </Button>
                    </Menu.Item>
                    <Menu.Item key={"billing-info"}>
                      <Button type={"link"}>
                        <FormattedMessage id="dentist-list.add-billing-info" defaultMessage="Dodaj dane do faktury" />
                      </Button>
                    </Menu.Item>
                    <Menu.Item key={"delete"} onClick={() => onDelete?.(entity)}>
                      <Button type={"link"} danger={true}>
                        <FormattedMessage id="dentist-list.delete-dentist" defaultMessage="Usuń" />
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type={"link"} onClick={(e) => e.preventDefault()}>
                  <FormattedMessage id="dentist-list.actions" defaultMessage="Akcje" />
                  <DownOutlined className={"ml-2"} />
                </Button>
              </Dropdown>,
            ],
          },
        }}
      />
    </div>
  );
};

export default DentistList;
