import * as React from "react";
import { useCallback, useContext } from "react";
import { Ok } from "core/lib/types/ok";
import AuthServiceContext from "../../auth-service.context";
import { Navigate, useNavigate } from "react-router";
import { AuthPageLayout } from "../../components/auth-page-layout/AuthPageLayout";
import { Err } from "core/lib/types/error";
import { Path } from "core/routes/routes";
import { isNullOrEmpty } from "core/utils/string-utils";
import NewPasswordForm, {
  INewPasswordFormSubmitValues,
} from "features/auth/components/new-password-form/NewPasswordForm";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

// parses hash from the URL e.g. "#email=foo&token=bar"
const getHashParams = () =>
  location.hash
    .substring(1)
    .split("&")
    .map((x) => x.split("="))
    .filter(([key]) => key === "email" || key === "token")
    .reduce((map, [key, value]) => ({ ...map, [key]: value }), {} as { email?: string; token?: string });

interface ISetNewPasswordPageProps {}

const SetNewPasswordPage: React.FC<ISetNewPasswordPageProps> = () => {
  const authService = useContext(AuthServiceContext);
  const navigate = useNavigate();
  const { email, token } = getHashParams();

  const handleSetPassword = useCallback(
    async (submitValues: INewPasswordFormSubmitValues) => {
      const registerResponse = await authService.setNewPassword({
        email: window.atob(email!),
        password: submitValues.password,
        token: token!,
      });

      if (registerResponse.isErr()) {
        return new Err<void>(registerResponse.err().unwrap());
      }

      setTimeout(() => navigate(Path.Login, { state: { setPasswordSuccess: true } })); // setTimeout prevents state of unmounted component error

      return new Ok(undefined);
    },
    [authService, navigate]
  );

  if (isNullOrEmpty(email) || isNullOrEmpty(token)) {
    return <Navigate to={Path.Root} />;
  }

  return (
    <>
      <FormattedMessage id={"page-titles.set-new-password"} defaultMessage={"Ustaw nowe hasło"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AuthPageLayout>
        <NewPasswordForm onSubmit={handleSetPassword} />
      </AuthPageLayout>
    </>
  );
};

export default SetNewPasswordPage;
