import React, { useRef, useState } from "react";
import useSize from "hooks/useSize";
import classNames from "classnames";

import { ChannelSort } from "stream-chat";
import { Channel, ChannelList } from "stream-chat-react";
import MessagingChannelList from "./components/ChannelList/ChannelList";
import useAuth from "features/auth/hooks/useAuth";

import { ChannelInner } from "./components/ChannelInner/ChannelInner";
import { ChatPageSize } from "features/chat/pages/chat-page/ChatPageSize";

import { GiphyContext } from "features/chat/contexts/GiphyContext";
import { useChatStore } from "features/chat/hooks/useChatStore";
import MessagingInput from "features/chat/pages/chat-page/components/MessagingInput/MessagingInput";
import CreateChannel from "features/chat/pages/chat-page/components/CreateChannel/CreateChannel";

import MessagingChannelPreview from "features/chat/pages/chat-page/components/MessagingChannelPreview/MessagingChannelPreview";
import { useParams } from "react-router";

import "../../styles/index.scss";
import "./chat-page-redesign.scss";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

const ChatPage = () => {
  const { channelId } = useParams<"channelId">();
  const ref = useRef(null);

  const [size] = useSize(ref, {
    [ChatPageSize.Small]: 0,
    [ChatPageSize.Medium]: 800,
    [ChatPageSize.Large]: 1100,
  });

  const { authContext } = useAuth();

  const { channelListOpened, chatMode, setChannelListOpened, setChatMode } = useChatStore((store) => ({
    channelListOpened: store.channelListOpened,
    chatMode: store.chatMode,
    setChannelListOpened: store.setChannelListOpened,
    setChatMode: store.setChatMode,
  }));

  const [giphyState, setGiphyState] = useState(false);
  const giphyContextValue = { giphyState, setGiphyState };

  const options = { state: true, watch: true, presence: true, limit: 8 };

  const sort: ChannelSort = {
    last_message_at: -1,
    updated_at: -1,
    cid: 1,
  };

  const filters = React.useMemo(
    () => ({ type: "messaging", members: { $in: [authContext.userId!.value] } }),
    [authContext.userId]
  );

  const handleOpenChannelList = () => setChannelListOpened(true);
  const handleCloseChannelList = () => setChannelListOpened(false);
  const toggleCreateChannel = () => setChatMode(chatMode === "create-channel" ? "chat-window" : "create-channel");
  const showOpenChannelListArrow = size === ChatPageSize.Small && !channelListOpened;

  return (
    <>
      <FormattedMessage id={"page-titles.chat-page"} defaultMessage={"Czat"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>{" "}
          </Helmet>
        )}
      </FormattedMessage>
      <div className={"w-full"}>
        <div
          ref={ref}
          className={classNames("chat-page", {
            "chat-page--size-sm": size == ChatPageSize.Small,
            "chat-page--size-md": size == ChatPageSize.Medium,
            "chat-page--size-lg": size == ChatPageSize.Large,
            "chat-page--channel-list-open": size == ChatPageSize.Small && channelListOpened,
          })}
        >
          <div onClick={handleCloseChannelList} className={"chat-page__channel-list-container"}>
            <ChannelList
              customActiveChannel={channelId}
              filters={filters}
              sort={sort}
              options={options}
              List={(props) => <MessagingChannelList {...props} onCreateChannel={toggleCreateChannel} />}
              Preview={(props) => <MessagingChannelPreview {...props} />}
            />
          </div>
          <GiphyContext.Provider value={giphyContextValue}>
            <Channel Input={MessagingInput} maxNumberOfFiles={10} multipleUploads={true}>
              {chatMode === "create-channel" && <CreateChannel onClose={toggleCreateChannel} />}
              {chatMode === "chat-window" && (
                <ChannelInner
                  showOpenChannelListArrow={showOpenChannelListArrow}
                  onOpenChannelList={handleOpenChannelList}
                />
              )}
            </Channel>
          </GiphyContext.Provider>
        </div>
      </div>
    </>
  );
};

export default ChatPage;
