import * as React from "react";
import { Button, Form, Modal } from "antd";
import { Honorific } from "core/person-name/honorific";
import Input from "components/forms/input/Input";
import HonorificSelect from "components/forms/honorific-select/HonorificSelect";
import { FormattedMessage } from "react-intl";
import { DeepPartial } from "utility-types";

export interface IDentistModalFormProps {
  modalType: "edit" | "create";
  visible: boolean;
  values?: DeepPartial<IFormValues>;
  onCancel: () => void;
  onSubmit: (values: ISubmitValues) => Promise<void>;
}

interface ISubmitValues {
  honorific: Honorific;
  firstName: string;
  lastName: string;
  companyName: string | null;
}

interface IFormValues {
  honorific: Honorific;
  firstName: string;
  lastName: string;
  companyName: string;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = {
  honorific: "honorific",
  firstName: "firstName",
  lastName: "lastName",
  companyName: "companyName",
};

interface ICreateDentistFormProps extends IDentistModalFormProps {}

const DentistModalForm: React.FunctionComponent<ICreateDentistFormProps> = ({
  modalType,
  visible,
  values = {},
  onCancel,
  onSubmit,
}) => {
  const formId = "dentist-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { honorific, lastName, firstName, companyName } = store;
      await onSubmit({ firstName, lastName, honorific, companyName });
      form.resetFields();
    },
    [form, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        modalType === "edit" ? (
          <FormattedMessage id={"dentist-modal-form.edit-dentist"} defaultMessage={"Edytuj dentystę"} />
        ) : (
          <FormattedMessage id={"dentist-modal-form.create-dentist"} defaultMessage={"Dodaj dentystę"} />
        )
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"dentist-modal-form.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          {modalType === "edit" ? (
            <FormattedMessage id={"dentist-modal-form.edit-dentist-button-text"} defaultMessage={"Zapisz"} />
          ) : (
            <FormattedMessage id={"dentist-modal-form.create-dentist-button-text"} defaultMessage={"Dodaj"} />
          )}
        </Button>,
      ]}
    >
      <Form id={formId} form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ ...values }}>
        <Form.Item
          label={<FormattedMessage id="dentist-modal-form.honorific" defaultMessage="Tytuł" />}
          name={propertyNames.honorific}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="dentist-modal-form.honorific-is-required" defaultMessage="Tytuł jest wymagany" />
              ),
            },
          ]}
        >
          <HonorificSelect />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="dentist-modal-form.first-name" defaultMessage="Imię" />}
          name={propertyNames.firstName}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="dentist-modal-form.first-name-is-required" defaultMessage="Imię jest wymagane" />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="dentist-modal-form.last-name" defaultMessage="Nazwisko" />}
          name={propertyNames.lastName}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="dentist-modal-form.last-name-is-required"
                  defaultMessage="Nazwisko jest wymagane"
                />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="dentist-modal-form.company-name" defaultMessage="Krótka nazwa kliniki" />}
          name={propertyNames.companyName}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DentistModalForm;

export type { ISubmitValues as IDentistModalFormSubmitValues };
