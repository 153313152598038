import { IBillingInfoDTO } from "contracts/common-dtos/billing-info.dto";
import { IBillingInfo } from "./billing-info";
import { mapTaxIdDTOToTaxId, mapTaxIdToTaxIdDTO } from "../taxes/taxes.functions";
import { mapAddressDtoToAddress, mapAddressToAddressDTO } from "contracts/common-dtos/dto.functions";

export const mapBillingInfoDTOToBillingInfo = ({ id, taxId, address, companyName }: IBillingInfoDTO): IBillingInfo => ({
  id: { type: "billing-info-id", value: id },
  taxId: mapTaxIdDTOToTaxId(taxId),
  address: mapAddressDtoToAddress(address),
  companyName: companyName,
});

export const mapBillingInfoToBillingInfoDTO = ({ id, taxId, address, companyName }: IBillingInfo): IBillingInfoDTO => ({
  id: id.value,
  taxId: mapTaxIdToTaxIdDTO(taxId),
  address: mapAddressToAddressDTO(address),
  companyName: companyName,
});
