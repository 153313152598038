import { Currency, IMoney } from "core/money/money.model";
import { invalidArgumentError } from "core/errors/generate-error";
import { isNumber } from "../utils/utils";

export class Money {
  private readonly currency: Currency;
  private readonly amount: number;

  constructor({ amount, currency }: { amount: number; currency: Currency }) {
    this.amount = amount;
    this.currency = currency;
  }

  toString() {
    return Money.serialize({ amount: this.amount, currency: this.currency });
  }

  static serialize(money: IMoney) {
    return JSON.stringify({ amount: money.amount, currency: money.currency });
  }

  static deserialize(str: string) {
    if (typeof (str as any) !== "string") {
      throw invalidArgumentError(`function expects a string`);
    }

    let obj: object;

    try {
      obj = JSON.parse(str) as object;
    } catch (error) {
      return null;
    }

    try {
      const { amount, currency } = obj as { amount: number; currency: string };

      if (!(isNumber(amount) && isCurrency(currency))) {
        return null;
      }

      return Money.New(amount, currency);
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  static multiply = (pointPrice: IMoney, factor: number) => {
    return Money.New(pointPrice.amount * factor, pointPrice.currency);
  };

  static add = (a: IMoney, b: IMoney) => {
    if (a.currency !== b.currency) {
      if ((a.amount === 0 && b.amount === 0) || (a.amount !== 0 && b.amount !== 0)) {
        throw invalidArgumentError(`can not add different currencies (${a.currency}) + (${b.currency})`);
      }

      return a.amount === 0 && b.amount !== 0 ? b : a;
    }

    return Money.New(a.amount + b.amount, a.currency);
  };

  static New = (amount: number, currency: Currency): IMoney => ({
    amount,
    currency,
  });

  static Zero = (currency: Currency = Currency.PLN): IMoney => ({
    amount: 0,
    currency,
  });

  static PLN = (amount: number): IMoney => ({
    amount,
    currency: Currency.PLN,
  });

  static EUR = (amount: number): IMoney => ({
    amount,
    currency: Currency.EUR,
  });

  static isEqual = (a: IMoney, b: IMoney) => {
    if (a === null || b === null || a === undefined || b === undefined) {
      throw invalidArgumentError(`money argument is invalid inside isEqual function ( a: ${a}, b: ${b})`);
    }

    return a.currency === b.currency && a.amount === b.amount;
  };

  static isZero = (a: IMoney) => {
    return a.amount === 0;
  }

  static isMoney = (value: any): value is IMoney => {
    if (typeof value !== "object") {
      return false;
    }

    const { currency, amount } = value as IMoney;

    if (currency === undefined || amount === undefined) {
      return false;
    }

    if (isCurrency(currency) === false) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    if (typeof (amount as any) !== "number") {
      return false;
    }

    return true;
  };

  static isEqualOrLargerThanZero = (m: IMoney) => m.amount >= 0
}

export const isCurrency = (arg: any): arg is Currency => {
  return (typeof arg === "string" && arg === Currency.EUR) || arg === Currency.PLN;
};
