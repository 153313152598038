import * as React from "react";
import { Gender, GenderCode } from "core/gender/gender.model";
import { useIntl } from "react-intl";
import { mapGenderCodeToGender } from "core/gender/gender.functions";

interface IGenderFormatterProps {
  gender: Gender | GenderCode;
  children?: (formattedGenderString: string) => string;
}

const GenderFormatter: React.FunctionComponent<IGenderFormatterProps> = ({ gender, children }) => {
  const intl = useIntl();

  const unknownGender = intl.formatMessage({ id: "common.gender-unknown", defaultMessage: "Płeć nieznana" });
  const male = intl.formatMessage({ id: "common.gender-male", defaultMessage: "Mężczyzna" });
  const female = intl.formatMessage({ id: "common.gender-female", defaultMessage: "Kobieta" });

  let properGender;
  if (typeof gender === "number") {
    properGender = mapGenderCodeToGender(gender);
  } else {
    properGender = gender;
  }

  if (typeof children === "function") {
    switch (properGender) {
      case Gender.Unknown:
        return <>{children(unknownGender)}</>;
      case Gender.Male:
        return <>{children(male)}</>;
      case Gender.Female:
        return <>{children(female)}</>;
      default:
        return <>{children("")}</>;
    }
  }

  switch (properGender) {
    case Gender.Unknown:
      return <>{unknownGender}</>;
    case Gender.Male:
      return <>{male}</>;
    case Gender.Female:
      return <>{female}</>;
    default:
      return <>{""}</>;
  }
};

export default GenderFormatter;
