import React from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useMachine } from "@xstate/react";
import { Result, Space, Steps} from "antd";
import PageContent from "features/layout/content/PageContent";
import { ISODateTimeString } from "core/time/time.model";
import ToastMessages from "../../../../../components/message/ToastMessages";
import { IDentist } from "features/dentist/dentist.model";
import PickDentists, {
  IPickDentistSubmitValues,
} from "features/invoicing/pages/create-invoice-page/components/pick-dentists/PickDentists";
import PickOrders from "./pick-orders/PickOrders";
import {
  CreateInvoiceEvent,
  CreateInvoiceState,
  createInvoiceStateMachine,
} from "features/invoicing/pages/create-invoice-page/components/create-invoice.state-machine";
import CreateBillingStatementStep from "features/invoicing/pages/create-invoice-page/components/create-billing-statement/CreateBillingStatementStep";
import Button from "components/button/Button";
import { getFormattedNameAsString, PersonNameFormat } from "components/formatters/person-name/PersonNameFormatter";
import Links from "components/links/Links";

export interface ICreateInvoiceWizardProps {
  dentists: IDentist[];
}

const CreateInvoiceWizard: React.FunctionComponent<ICreateInvoiceWizardProps> = ({ dentists }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { messageApi } = React.useContext(ToastMessages);
  const [currentState, sendEvent] = useMachine(createInvoiceStateMachine);

  const context = currentState.context;

  const handleDentistPicked = React.useCallback(
    async (values: IPickDentistSubmitValues) => {
      sendEvent({
        type: CreateInvoiceEvent.DentistPicked,
        payload: { recipient: values.recipient, counterparties: values.counterparties },
      });

      return Promise.resolve();
    },
    [sendEvent]
  );

  React.useCallback((dates: [ISODateTimeString, ISODateTimeString]) => {
    const [fromDate, toDate] = dates;
    const from = moment(fromDate);
    const to = moment(toDate);

    if (from.month === to.month) {
      return from.get("month");
    }

    return from.get("month");
  }, []);

  const header = React.useMemo(() => {
    let step = 0;
    let stateToStateMap: Record<CreateInvoiceState, number>;

    stateToStateMap = {
      [CreateInvoiceState.PickDentist]: 0,
      [CreateInvoiceState.PickOrders]: 1,
      [CreateInvoiceState.ProvideLineItems]: 2,
      [CreateInvoiceState.BillingStatementCreated]: 3,
    };

    for (const [state, stepNumber] of Object.entries(stateToStateMap)) {
      if (currentState.matches(state)) {
        step = stepNumber;
        break;
      }
    }

    return (
      <PageContent className="p-2">
        <Steps current={step} className="flex w-full xl:w-3/4 my-8">
          <Steps.Step
            title={<FormattedMessage id="create-billing-statement.pick-dentist" defaultMessage="Wybierz dentystę" />}
          />
          <Steps.Step
            title={<FormattedMessage id="create-billing-statement.pick-orders" defaultMessage="Wybierz zamówienia" />}
          />
          <Steps.Step
            title={
              <FormattedMessage id="create-billing-statement.invoice-list" defaultMessage="Pozycje na rozliczeniu" />
            }
          />
          <Steps.Step
            title={<FormattedMessage id="create-billing-statement.created" defaultMessage="Stworzono Rozliczenie" />}
          />
        </Steps>
      </PageContent>
    );
  }, [currentState]);

  if (currentState.matches(CreateInvoiceState.PickDentist)) {
    return (
      <>
        {header}
        <PageContent>
          <PickDentists dentists={dentists} onSubmit={handleDentistPicked} />
        </PageContent>
      </>
    );
  }

  if (currentState.matches(CreateInvoiceState.PickOrders)) {
    return (
      <>
        {header}
        <PageContent>
          <PickOrders
            counterparties={currentState.context.counterparties!}
            dentists={dentists}
            onSubmit={(values) => {
              sendEvent({
                type: CreateInvoiceEvent.OrdersPicked,
                payload: {
                  finishDate: values.finishDate,
                  orders: values.orders,
                },
              });
            }}
            onBack={() => sendEvent({ type: CreateInvoiceEvent.GoBack })}
          />
        </PageContent>
      </>
    );
  }

  if (currentState.matches(CreateInvoiceState.ProvideLineItems)) {
    return (
      <>
        {header}
        <PageContent>
          <CreateBillingStatementStep
            recipient={context.dentist!}
            selectedOrders={context.orders!}
            dentists={dentists.filter((d) => context.counterparties!.some((c) => c.id.value === d.id.value))}
            onCancel={() => sendEvent({ type: CreateInvoiceEvent.GoBack })}
            onCreated={(billingStatement) =>
              sendEvent({
                type: CreateInvoiceEvent.BillingStatementCreated,
                payload: { billingStatement: billingStatement },
              })
            }
          />
        </PageContent>
      </>
    );
  }

  if (currentState.matches(CreateInvoiceState.BillingStatementCreated)) {
    const statement = context.createdBillingStatement!;
    return (
      <>
        {header}
        <PageContent>
          <Result
            status="success"
            title={
              <FormattedMessage
                id="create-billing-statement.billing-statement-created"
                defaultMessage="Utworzono rozliczenie {number}"
                values={{ number: statement!.statementNumber }}
              />
            }
            subTitle={
              <>
                <FormattedMessage
                  id="create-billing-statement.billing-statement-info"
                  defaultMessage="Rozliczenie numer {number} dla {dentist} zostało utworzone dnia {createdOn}"
                  values={{
                    number: statement!.statementNumber,
                    dentist: getFormattedNameAsString({
                      personName: statement.recipient.name,
                      format: PersonNameFormat.Short,
                      useDrIfAvailable: true,
                    }),
                    createdOn: intl.formatDate(statement.createdOn),
                  }}
                />
              </>
            }
            extra={
              <Space>
                <Links.BillingStatementDetails params={{ billingStatementId: statement.id }}>
                  <Button type="primary">
                    <FormattedMessage
                      id="create-billing-statement.go-to-billing-statement-details"
                      defaultMessage="Idź do rozliczenia"
                    />
                  </Button>
                </Links.BillingStatementDetails>
                <Links.CreateBillingStatement>
                  <Button>
                    <FormattedMessage
                      id="create-billing-statement.create-another-billing-statement-details"
                      defaultMessage="Stwórz następne rozliczenie"
                    />
                  </Button>
                </Links.CreateBillingStatement>
              </Space>
            }
          />
        </PageContent>
      </>
    );
  }

  return null;
};

export default CreateInvoiceWizard;
