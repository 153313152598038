import { Option } from "./option";
import { Some } from "./some";
import { None } from "./none";
import { IResult } from "./result";
import { Ok } from "./ok";
import { IDomainError } from "core/errors/domain-error";

// Rename to Failure ?
// Default T type is never, does that generate problems ?
export class Err<T = never, E = IDomainError> implements IResult<T, E> {
  constructor(private error: E) {}

  map<U>(fn: (a: T) => U) {
    return this as unknown as Err<U, E>;
  }
  mapErr<U>(fn: (a: E) => U) {
    return new Err<T, U>(fn(this.error));
  }
  isOk(): this is Ok<T, E> {
    return false;
  }
  isErr(): this is Err<T, E> {
    return true;
  }
  ok(): Option<T> {
    return None.instance<T>();
  }
  err(): Option<E> {
    return new Some(this.error);
  }
  and<U>(res: IResult<U, E>) {
    return this as unknown as Err<U, E>;
  }
  andThen<U>(op: (val: T) => IResult<U, E>) {
    return this as unknown as Err<U, E>;
  }
  or(res: IResult<T, E>) {
    return res;
  }
  orElse<U>(op: (err: E) => IResult<T, U>) {
    return op(this.error);
  }
  unwrap(): never {
    throw this.error;
  }
  unwrapOr(optb: T) {
    return optb;
  }
  unwrapOrElse(op: (err: E) => T) {
    return op(this.error);
  }
  toString() {
    return "Err";
  }
}
