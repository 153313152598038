import { IPickedPoints } from "../../../points-picker/points-picker.model";
import { Alert } from "antd";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { LinkedPointsSpecification, SpecificationType } from "../../../../specification/order-specification";

export const FollowingPointsAreNotLinked = (props: {
  specificationType: LinkedPointsSpecification;
  points: IPickedPoints;
}) => {
  let message;
  let points = props.points.singles.map((x) => x.location).join(" ");

  switch (props.specificationType.specificationType) {
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges:
      message = (
        <FormattedMessage
          id={"linked-points-specification.connect-all-single-points-into-bridges"}
          defaultMessage={
            "Następujące punkty nie są w połączone w most, proszę je połączyć w podbudowy lub usunąć ({points})"
          }
          values={{ points: points }}
        />
      );
      break;
    case SpecificationType.Foundations:
      message = (
        <FormattedMessage
          id={"linked-points-specification.connect-all-single-points-into-foundations"}
          defaultMessage={
            "Następujące punkty nie są w połączone w podbudowę, proszę je połączyć w podbudowy lub usunąć ({points})"
          }
          values={{ points: points }}
        />
      );
      break;
  }

  return <Alert className="m-4" type="error" showIcon message={message} />;
};
