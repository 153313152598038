import { IDomainError } from "core/errors/domain-error";

export enum EventName {
  NoLabAssigned = "NoLabAssigned",
  ShowErrorToast = "ShowErrorToast",
  AuthTokenExpired = "AuthTokenExpired",
}

export interface IEvents {
  [EventName.NoLabAssigned]: () => void;
  [EventName.AuthTokenExpired]: () => void;
  [EventName.ShowErrorToast]: (domainError: IDomainError) => void;
}
