import * as React from "react";
import { Result } from "antd";
import { FormattedMessage } from "react-intl";
import { AuthEmptyPageLayout } from "../../components/auth-page-layout/AuthPageLayout";
import { isNullOrEmpty } from "core/utils/string-utils";
import ConfirmEmailLink from "./ConfirmEmailLink";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";

interface IConfirmEmailPageProps {}

const ConfirmEmailPage: React.FC<IConfirmEmailPageProps> = (props) => {
  const params = useParams() as { confirmEmailLink: string | undefined };
  const { confirmEmailLink } = params;

  return (
    <>
      <FormattedMessage id={"common.confirm-email"} defaultMessage={"Potwierdź email"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AuthEmptyPageLayout>
        <Result
          status={"info"}
          title={
            <FormattedMessage
              id="confirm-email-page.confirmation-email-title"
              defaultMessage="Potwierdź swój adres email"
            />
          }
          subTitle={
            <FormattedMessage
              id="confirm-email-page.confirmation-email-subtitle"
              defaultMessage="Gratulacje utworzyłeś konto ! potwierdź swój adres email klikając w link w wiadomości email którą wysłaliśmy na twój adres"
            />
          }
        />
        {!isNullOrEmpty(confirmEmailLink) && <ConfirmEmailLink confirmEmailLink={confirmEmailLink!} />}
      </AuthEmptyPageLayout>
    </>
  );
};

export default ConfirmEmailPage;
