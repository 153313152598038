import * as React from "react";
import { Tag, Menu, Dropdown } from "antd";
import { DownOutlined, SyncOutlined } from "@ant-design/icons";

import { Status } from "features/orders/status/order-status.model";
import { TagProps } from "antd/lib/tag";
import { IResult } from "core/lib/types/result";
import classNames from "classnames";
import StatusName from "components/status/order-status/StatusName";
import { statusToColorMap } from "components/status/order-status/status-colors";
import StatusBadge from "components/status/order-status/StatusBadge";

const StatusTag: React.FunctionComponent<TagProps> = (props) => <Tag {...props} closable={false} />;

interface IOrderStatusProps {
  status: Status;
  onChange?: (nextStatus: Status) => Promise<IResult<Status>>;
}

const OrderStatus: React.FunctionComponent<IOrderStatusProps> = ({ status: currentStatus, onChange, ...props }) => {
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [optimisticStatus, setOptimisticStatus] = React.useState<Status>(currentStatus); // ? null ?

  const status = optimisticStatus !== null && optimisticStatus !== currentStatus ? optimisticStatus : currentStatus;

  const changeStatus = React.useCallback(
    async (nextStatus: Status) => {
      if (onChange === undefined) return;
      if (nextStatus === status) return;

      setInProgress(true);

      const result = await onChange(nextStatus);

      setInProgress(false);

      if (result.isOk()) {
        setOptimisticStatus(result.unwrap());
      }
    },
    [onChange, status]
  );

  const handleMenuClick = React.useCallback(
    (param: any) => {
      param.domEvent.preventDefault();
      param.domEvent.stopPropagation();
      const nextStatus = param.key as Status;
      changeStatus(nextStatus);
    },
    [changeStatus]
  );

  const possibleStatuses: Status[] = [
    Status.ReadyForPickUpFromClinic,
    Status.TodoInLab,
    Status.InProgressInLab,
    Status.InProgressInClinic,
    Status.Finished,
  ];

  return (
    <StatusTag
      className="select-none"
      color={statusToColorMap[status]}
      icon={<SyncOutlined className={classNames({ hidden: !inProgress })} />}
      // onClick={handleButtonClick}
      {...props}
    >
      <StatusName status={status} />
      {onChange !== undefined && (
        <Dropdown
          overlay={
            <Menu onClick={handleMenuClick}>
              {possibleStatuses
                .filter((s) => s !== status)
                .map((s) => (
                  <Menu.Item key={s}>
                    <StatusBadge status={s} />
                    <StatusName status={s} />
                  </Menu.Item>
                ))}
            </Menu>
          }
        >
          <DownOutlined className="p-2" />
        </Dropdown>
      )}
    </StatusTag>
  );
};

export default OrderStatus;
