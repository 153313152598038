export function mapWidthToSize<K extends keyof any>(
  breakpoints: Record<K, number>
): (width: number) => K;
export function mapWidthToSize<K extends keyof any>(
  breakpoints: Record<K, number>,
  width: number
): K;

// FOR NOW IT ONLY WORKS WITH ENUM WITH STRING KEYS
export function mapWidthToSize<K extends keyof any>(
  breakpoints: Record<K, number>,
  width?: number
): K | ((width: number) => K) {
  let biggestSize: K | null = null;

  const entries: [string, number][] = Object.entries(breakpoints);

  // todo: add check if the enum key is not a number ( throw an error ! )

  if (width === undefined) {
    return (width: number) => {
      let biggestSize: K | null = null;

      for (let [size, breakpoint] of entries) {
        if (size === "1" || size === "2" || size === "3") {
          throw new Error(
            "works only with string keys please do not use with keys looking like numbers"
          );
        }

        if (width >= breakpoint) {
          biggestSize = size as K;
        }
      }

      return biggestSize as any;
    };
  }

  for (let [size, breakpoint] of entries) {
    if (size === "1" || size === "2" || size === "3") {
      throw new Error(
        "works only with string keys please do not use with keys looking like numbers"
      );
    }

    if (width >= breakpoint) {
      biggestSize = size as K;
    }
  }

  if (biggestSize === null) {
    throw new Error(
      `breakpoints are invalid width of ${width} is not match able to any breakpoint`
    );
  }

  return biggestSize;
}
