import * as React from "react";
import { useCallback } from "react";
import { MessageOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import useOrderChat from "features/chat/hooks/useOrderChat";
import useAuth from "features/auth/hooks/useAuth";
import { IOrderId } from "features/orders/order.model";
import { IButtonProps } from "components/button/Button";
import { AccountType, IUserId } from "features/auth/auth.model";
import { Ok } from "core/lib/types/ok";
import { IChatChannelInfo } from "features/chat/contracts/chat.contracts";
import { useNavigate } from "react-router";
import { Path } from "core/routes/routes";
import { parametrizePath } from "core/lib/routing/parametrize-route";

interface IChatButtonProps extends Omit<IButtonProps, "children" | "onClick"> {
  orderId: IOrderId;
}

const ChatButton: React.FC<IChatButtonProps> = ({ orderId, ...buttonProps }) => {
  const { getOrderChannel, createOrderChannel, joinOrderChannel, mutate } = useOrderChat(orderId);
  const { authContext } = useAuth();
  const navigate = useNavigate();

  const isMember = (channelInfo: IChatChannelInfo, userId: IUserId) =>
    channelInfo.members.some((m) => m.userId.value === userId!.value);

  const handleGoToChannel = useCallback(() => {
    if (getOrderChannel === undefined || getOrderChannel.isErr()) {
      return;
    }

    const channelInfo = getOrderChannel.unwrap();

    if (channelInfo === null) {
      return;
    }

    navigate(parametrizePath({ path: Path.ChatChannel, params: { channelId: channelInfo.channelId } }));
  }, [getOrderChannel, navigate]);

  const handleCreateChannel = useCallback(async () => {
    const result = await createOrderChannel(orderId);

    if (result.isErr()) {
      return result;
    }

    const channelInfo = result.unwrap();

    if (isMember(channelInfo, authContext.userId!)) {
      await mutate(new Ok(channelInfo), false);

      navigate(parametrizePath({ path: Path.ChatChannel, params: { channelId: channelInfo.channelId } }));

      return;
    }

    await mutate(new Ok(channelInfo), false);

    return result;
  }, [authContext.userId, createOrderChannel, mutate, navigate, orderId]);

  const handleJoinToChannel = useCallback(async () => await joinOrderChannel(orderId), [joinOrderChannel, orderId]);

  const btnContent = React.useMemo(
    () => (
      <>
        {authContext.accountType === AccountType.Dentist && (
          <FormattedMessage id="chat-button.chat-with-lab" defaultMessage="Czat z laboratorium" />
        )}
        {(authContext.accountType === AccountType.LabOwner || authContext.accountType === AccountType.LabEmployee) && (
          <FormattedMessage id="chat-button.chat-with-doctor" defaultMessage="Czat z doktorem" />
        )}
      </>
    ),
    [authContext.accountType]
  );

  if (authContext.userId == null) {
    return null;
  }

  if (getOrderChannel == undefined) {
    return (
      <Button type={"default"} {...buttonProps} loading={true}>
        {btnContent}
      </Button>
    );
  }

  return (
    <>
      {getOrderChannel
        .map((channelInfo) => {
          return channelInfo === null ? (
            <Button
              type={"default"}
              icon={<MessageOutlined className={"pr-2"} />}
              onClick={handleCreateChannel}
              {...buttonProps}
            >
              {btnContent}
            </Button>
          ) : isMember(channelInfo, authContext.userId!) ? (
            <Button
              type={"default"}
              icon={<MessageOutlined className={"pr-2"} />}
              onClick={handleGoToChannel}
              {...buttonProps}
            >
              {btnContent}
            </Button>
          ) : (
            <Button
              type={"default"}
              icon={<MessageOutlined className={"pr-2"} />}
              onClick={handleJoinToChannel}
              {...buttonProps}
            >
              <FormattedMessage id="chat-button.chat" defaultMessage="Dołącz do chatu" />
            </Button>
          );
        })
        .mapErr(() => null)
        .unwrap()}
    </>
  );
};

export default ChatButton;
