import { invalidArgumentError } from "core/errors/generate-error";
import { CategoryCode, IOrderCategory } from "features/catalogue/category.model";
import { SortComparator, SortComparison } from "core/lib/sort/sort.model";

export enum CategoriesSortOrder {
  Standard = "standard",
}

export const sortCategories = (
  sortOrder: CategoriesSortOrder = CategoriesSortOrder.Standard
): SortComparator<IOrderCategory> => {
  if (sortOrder !== CategoriesSortOrder.Standard) {
    throw invalidArgumentError(`sort order is invalid`);
  }

  return sortCategoriesInStandardOrder;
};

const standardCategoryOrder: Record<CategoryCode, number> = {
  [CategoryCode.Crowns]: 1,
  [CategoryCode.Bridges]: 2,
  [CategoryCode.MarylandBridges]: 3,
  [CategoryCode.Veneers]: 4,
  [CategoryCode.FullDentures]: 5,
  [CategoryCode.PartialDentures]: 6,
  [CategoryCode.FrameDentures]: 7,
  [CategoryCode.SplintDentures]: 8,
  [CategoryCode.Overdentures]: 9,
  [CategoryCode.Attachments]: 10,
  [CategoryCode.DentureRepair]: 11,
  [CategoryCode.Foundations]: 12,
  [CategoryCode.ImplantAbutments]: 13,
  [CategoryCode.Onlays]: 14,
  [CategoryCode.Inlays]: 15,
  [CategoryCode.Overlays]: 16,
  [CategoryCode.EndoCrown]: 17,
  [CategoryCode.WaxUps]: 18,
  [CategoryCode.Temporary]: 19,
  [CategoryCode.Splints]: 20,
  [CategoryCode.Post]: 21,
  [CategoryCode.Gingiva]: 22,
  [CategoryCode.OrientationModels]: 23,
  [CategoryCode.Services]: 24,
  [CategoryCode.MillingServices]: 25,
  [CategoryCode.Other]: 26,
};

const sortCategoriesInStandardOrder: SortComparator<Pick<IOrderCategory, "code" | "name">> = (c1, c2) => {
  if (c1.code !== null && c2.code !== null) {
    return standardCategoryOrder[c1.code] - standardCategoryOrder[c2.code];
  } else if (c1.code === null && c2.code === null) {
    return c1.name.localeCompare(c2.name);
  } else if (c1.code === null && c2.code !== null) {
    return SortComparison.Descend;
  } else if (c1.code !== null && c2.code === null) {
    return SortComparison.Ascend;
  } else {
    return SortComparison.Equal;
  }
};
