import * as React from "react";
import Badge from "antd/lib/badge";
import { Status } from "features/orders/status/order-status.model";
import { statusToColorMap } from "components/status/order-status/status-colors";

const StatusBadge = React.memo<{ status: Status }>((props) => {
  return <Badge color={statusToColorMap[props.status]} />;
});

export default StatusBadge;
