import { SpecificationType } from "features/orders/specification/order-specification";
import { PricingFormType } from "../pricing-input/pricing-input.models";
import { argumentOutOfRangeError } from "core/errors/generate-error";

const {
  IndividualPricing,
  Quantity,
  FixedPrice,
  ArchEqualPrices,
  UpperAndLowerArchPrices,
  PointEqualPrices,
  FrontAndBackPointPrices,
  PointWithBaseArchPrice,
  CrownEqualPrices,
  FrontAndBackCrownPrices,
  BridgePointEqualPrices,
  FrontAndBackBridgePointPrices,
  MarylandBridgePointEqualPrices,
  FrontAndBackMarylandBridgePointPrices,
  FoundationPointEqualPrices,
} = PricingFormType;

export const mapSpecificationTypeToPricingFormTypes = (specificationType: SpecificationType) => {
  switch (specificationType) {
    case SpecificationType.TextOnly:
      return new Set<PricingFormType>([IndividualPricing, FixedPrice]);
    case SpecificationType.Quantity:
      return new Set<PricingFormType>([Quantity, FixedPrice]);
    case SpecificationType.Arches:
      return new Set<PricingFormType>([ArchEqualPrices, UpperAndLowerArchPrices, FixedPrice]);
    case SpecificationType.LocatedPoints:
      return new Set<PricingFormType>([PointEqualPrices, FrontAndBackPointPrices, FixedPrice]);
    case SpecificationType.PartialDentures:
      return new Set<PricingFormType>([PointEqualPrices, PointWithBaseArchPrice, FixedPrice]);
    case SpecificationType.SurgicalGuides:
      return new Set<PricingFormType>([PointEqualPrices, PointWithBaseArchPrice, FixedPrice]);
    case SpecificationType.Crowns:
      return new Set<PricingFormType>([
        PointEqualPrices,
        CrownEqualPrices,
        FrontAndBackCrownPrices,
        FixedPrice,
      ]);
    case SpecificationType.Bridges:
      return new Set<PricingFormType>([
        PointEqualPrices,
        BridgePointEqualPrices,
        FrontAndBackBridgePointPrices,
        FixedPrice,
      ]);
    case SpecificationType.MarylandBridges:
      return new Set<PricingFormType>([
        PointEqualPrices,
        MarylandBridgePointEqualPrices,
        FrontAndBackMarylandBridgePointPrices,
        FixedPrice,
      ]);
    case SpecificationType.Foundations:
      return new Set<PricingFormType>([PointEqualPrices, FoundationPointEqualPrices, FixedPrice]);
    default:
      throw argumentOutOfRangeError("specificationType out of bounds", specificationType);
  }
};
