import * as React from "react";
import { AnchorType } from "features/orders/order-type/anchor-type.models";
import { FormattedMessage } from "react-intl";

interface IAnchorNameProps {
  type: AnchorType;
}

const AnchorTypeFormatter: React.FunctionComponent<IAnchorNameProps> = (props) => {
  switch (props.type) {
    case AnchorType.CementedToImplant: {
      return (
        <FormattedMessage
          id="anchor-type.cemented-to-implant"
          defaultMessage="Zacementowane do implantu"
        />
      );
    }
    case AnchorType.ScrewedInToImplant: {
      return (
        <FormattedMessage
          id="anchor-type.screwed-in-to-implant"
          defaultMessage="Przykręcone do implantu"
        />
      );
    }
    case AnchorType.CementedToPreparedTooth: {
      return (
        <FormattedMessage
          id="anchor-type.cemented-to-prepared-tooth"
          defaultMessage="Zacementowane na zębie własnym"
        />
      );
    }
    case AnchorType.Pontic: {
      return <FormattedMessage id="anchor-type.pontic" defaultMessage="Przęsło" />;
    }
  }
  return null;
};

export default AnchorTypeFormatter;
