import { GenderCode, Gender } from "core/gender/gender.model";
import { argumentOutOfRangeError } from "core/errors/generate-error";

export const mapGenderCodeToGender = (genderCode: GenderCode) => {
  switch (genderCode) {
    case GenderCode.Female:
      return Gender.Female;
    case GenderCode.Male:
      return Gender.Male;
    case GenderCode.Unknown:
      return Gender.Unknown;
    default:
      throw argumentOutOfRangeError("genderCode", genderCode);
  }
};

export const mapGenderToGenderCode = (gender: Gender) => {
  switch (gender) {
    case Gender.Female:
      return GenderCode.Female;
    case Gender.Male:
      return GenderCode.Male;
    case Gender.Unknown:
      return GenderCode.Unknown;
    default:
      throw argumentOutOfRangeError("gender", gender);
  }
};
