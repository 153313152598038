export enum SortOrder {
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum SortComparison {
  Ascend = -1,
  Descend = 1,
  Equal = 0,
}

export type SortComparator<T> = (a: T, b: T) => SortComparison;
