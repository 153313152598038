import { IntlShape } from "react-intl";
import { OrderItemFormatterGroup } from "../order-item-formatter.models";
import { AnchorType } from "../../../../order-type/anchor-type.models";
import { IPointSequence } from "../../points-sequence/point-sequence.model";
import { mapPointsToSequences } from "../../points-sequence/points-sequence.mapper";
import { formatPointsSequences } from "../../points-sequence/points-sequence.string-formatter";

export const bridgePartsFormatter = (intl: IntlShape, group: OrderItemFormatterGroup) => {
  const { anchorType, points } = group;
  const sequenceArray: IPointSequence[] = mapPointsToSequences(points);
  const sequence = formatPointsSequences(sequenceArray);

  const count = sequenceArray.reduce((sum, curr) => {
    sum += curr.count;
    return sum;
  }, 0);

  switch (anchorType) {
    case AnchorType.CementedToImplant:
      return intl.formatMessage(
        {
          id: "order-item-formatter.bridge-parts.cemented-to-implant",
          defaultMessage:
            "{ count, plural, " +
            "one {{sequence} cementowany na implancie}" +
            "other {{sequence} cementowane na implantach}" +
            "}",
        },
        { count, sequence }
      );
    case AnchorType.ScrewedInToImplant:
      return intl.formatMessage(
        {
          id: "order-item-formatter.bridge-parts.screwed-in-to-implant",
          defaultMessage:
            "{ count, plural, " +
            "one {{sequence} przykręcany do implantu}" +
            "other {{sequence} przykręcane do implantów}" +
            "}",
        },
        { count, sequence }
      );
    case AnchorType.CementedToPreparedTooth:
      return intl.formatMessage(
        {
          id: "order-item-formatter.bridge-parts.cemented-to-tooth",
          defaultMessage:
            "{ count, plural, " +
            "one {{sequence} cementowany do zęba własnego}" +
            "other {{sequence} cementowane do zebów własnych}" +
            "}",
        },
        { count, sequence }
      );
    case AnchorType.Pontic:
      return intl.formatMessage(
        {
          id: "order-item-formatter.bridge-parts.pontic",
          defaultMessage: "{ count, plural, " + "one {{sequence} przęsło}" + "other {{sequence} przęsła}" + "}",
        },
        { count, sequence }
      );
  }
};
