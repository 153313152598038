import * as React from "react";
import { Button, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { IInvoiceIssuerDataSet } from "../../../../models/settings.models";
import { IssuerDataSet } from "./IssuerDataSet";
import { InitialIssuerDataSets } from "./InitialIssuerDataSets";
import { useCallback } from "react";
import useBooleanFlag from "../../../../../../hooks/useBooleanFlag";
import { createEmptyIssuerDataSet } from "./issuer-data-set.functions";
import { guid } from "core/guid/guid";

export const IssuerDataSets: React.FunctionComponent<{
  values: IInvoiceIssuerDataSet[];
  onChange: (values: IInvoiceIssuerDataSet[]) => Promise<void>;
}> = ({ values, onChange }) => {
  const emptyIssuerDataSet = createEmptyIssuerDataSet(false);
  const totalValues = values.length;
  const { flag: isAdding, check, uncheck } = useBooleanFlag(false);

  const updateDataSet = useCallback(
    async (value: IInvoiceIssuerDataSet) => {
      await onChange(values.map((v) => (v.id === value.id ? value : v)));
    },
    [onChange, values]
  );

  const addDataSet = useCallback(
    async (value: IInvoiceIssuerDataSet) => {
      const nextVal = [...values, value];
      await onChange(nextVal);
    },
    [onChange, values]
  );

  const handleSetDefault = useCallback(
    async (id: guid) => {
      await onChange(values.map((v) => (v.id === id ? { ...v, isDefault: true } : { ...v, isDefault: false })));
    },
    [onChange, values]
  );

  const handleRemove = useCallback(
    async (id: guid) => {
      const toRemove = values.find((v) => v.id === id);

      if (toRemove === undefined) {
        return;
      }

      if (!toRemove.isDefault) {
        await onChange(values.filter((v) => v.id !== id));
      }

      if (toRemove.isDefault) {
        const nextValues = values.filter((v) => v.id !== id);

        if (nextValues.length === 0) {
          return;
        }

        const firstOther = nextValues[0];
        await onChange(
          nextValues.map((v) => (v.id === firstOther.id ? { ...v, isDefault: true } : { ...v, isDefault: false }))
        );
      }
    },
    [onChange, values]
  );

  if (totalValues === 0) {
    return <InitialIssuerDataSets onChange={addDataSet} />;
  }

  return (
    <List.Item.Meta
      title={<FormattedMessage id="invoicing.invoice-issuer-data" defaultMessage="Dane do wystawiania faktury" />}
      description={
        <>
          {values.map((value) => (
            <IssuerDataSet
              value={value}
              onChange={updateDataSet}
              onRemove={totalValues > 1 ? handleRemove : undefined}
              onSetDefault={handleSetDefault}
            />
          ))}
          {isAdding && (
            <IssuerDataSet
              initialIsEditing={true}
              value={emptyIssuerDataSet}
              onChange={addDataSet}
              onCancel={uncheck}
            />
          )}
          {!isAdding && (
            <Button className={"mt-2"} key={"add"} onClick={check}>
              <PlusOutlined className={"mr-2"} />
              <FormattedMessage id="settings.add" defaultMessage={"Dodaj dane fakturowania"} />
            </Button>
          )}
        </>
      }
    />
  );
};
