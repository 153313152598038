import { IOrderEntry } from "features/orders/components/order-entry/order-entry.model";
import { computePrice } from "features/catalogue/prices/prices.calculator";
import { isEqual } from "core/utils/utils";
import { IMoney } from "core/money/money.model";
import { IShortPricingList } from "features/pricing-lists/pricing-list.models";

export const recomputePrices = (pricingList: IShortPricingList, orderEntries: IOrderEntry[]): Record<string, IMoney> => {
  return orderEntries.reduce((acc, entry) => {
    const { id: entryId, specification, price: prevPrice } = entry;

    const priceEntry = pricingList.entries.find((pe) => pe.orderTypeId.value === entry.orderType.id.value);

    if (priceEntry === undefined) {
      return acc;
    }

    const computedPrice = computePrice(priceEntry.pricing, specification);

    if (computedPrice === null) {
      return acc;
    }

    // todo: check if price has been set manually
    if (prevPrice === computedPrice || isEqual(prevPrice, computedPrice)) {
      return acc;
    }

    return { ...acc, [entryId]: computedPrice };
  }, {} as Record<string, IMoney>);
};
