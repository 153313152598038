import * as React from "react";
import { Layout as AntLayout } from "antd";
const { Content } = AntLayout;

interface IHeaderContentProps extends React.HTMLAttributes<HTMLDivElement> {}

const HeaderContent: React.FunctionComponent<IHeaderContentProps> = ({
  style = {},
  ...otherProps
}) => (
  <Content
    style={{
      background: "#fff",
      ...style,
    }}
    {...otherProps}
  />
);

export default HeaderContent;
