import * as React from "react";
import { Reducer, useReducer } from "react";
import { Breadcrumb, Descriptions, PageHeader, Spin } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Button from "components/button/Button";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import Links from "components/links/Links";
import useModal from "components/modals/useModal";
import { IPricingList, IPricingListId } from "features/pricing-lists/pricing-list.models";
import useAllPricingLists from "features/pricing-lists/hooks/lab/useAllPricingLists";
import PricingLists from "features/pricing-lists/components/pricing-lists/PricingLists";
import PricingListModalForm, {
  IPricingListModalFormSubmitValues,
} from "features/pricing-lists/components/modal/PricingListModal";
import { Helmet } from "react-helmet";

type State =
  | {
      isModalVisible: true;
      modalType: "create";
      pricingList: null;
    }
  | {
      isModalVisible: true;
      modalType: "edit";
      pricingList: IPricingList;
    }
  | {
      isModalVisible: true;
      modalType: "assign";
      pricingList: IPricingList;
    }
  | {
      isModalVisible: false;
      modalType: null;
      pricingList: null;
    };

type Action =
  | { type: "showCreateModal" }
  | { type: "showEditModal"; pricingList: IPricingList }
  | { type: "showAssignDentistModal"; pricingList: IPricingList }
  | { type: "hideModal" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showCreateModal":
      return { ...state, isModalVisible: true, modalType: "create", pricingList: null };
    case "showEditModal":
      return { ...state, isModalVisible: true, modalType: "edit", pricingList: action.pricingList };
    case "showAssignDentistModal":
      return { ...state, isModalVisible: true, modalType: "assign", pricingList: action.pricingList };
    case "hideModal":
      return { ...state, isModalVisible: false, modalType: null, pricingList: null };
  }
};

interface IAllLaboratoryPricingListsPageProps {}

const AllLaboratoryPricingListsPage: React.FunctionComponent<IAllLaboratoryPricingListsPageProps> = () => {
  const modal = useModal();
  const { getAllPricingLists, deletePricingList, editPricingList, createPricingList, revalidate } =
    useAllPricingLists();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    isModalVisible: false,
    modalType: null,
    pricingList: null,
  });

  const handleCreate = React.useCallback(
    async (submitValues: IPricingListModalFormSubmitValues) => {
      const result = await createPricingList({
        name: submitValues.name,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [createPricingList, revalidate]
  );

  const handleEdit = React.useCallback(
    (pricingListId: IPricingListId) => async (submitValues: IPricingListModalFormSubmitValues) => {
      const result = await editPricingList({
        id: pricingListId,
        name: submitValues.name,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [editPricingList, revalidate]
  );

  const handleDelete = React.useCallback(
    async (pricingListId: IPricingListId) => {
      const result = await deletePricingList({
        id: pricingListId,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [deletePricingList, revalidate]
  );

  const showDeleteConfirm = (pricingList: IPricingList) => {
    modal.confirm({
      title: (
        <FormattedMessage
          id="pricing-list-page.are-you-sure-delete-dentist"
          defaultMessage="Czy na pewno chcesz usunąć ?"
        />
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <Descriptions column={1} className={"mt-8"}>
          <Descriptions.Item label={<FormattedMessage id="pricing-list-page.dentist" defaultMessage="Cennik" />}>
            {pricingList.name}
          </Descriptions.Item>
        </Descriptions>
      ),
      okText: <FormattedMessage id="pricing-list-page.yes" defaultMessage="Tak" />,
      okType: "danger",
      cancelText: <FormattedMessage id="pricing-list-page.cancel" defaultMessage="Anuluj" />,
      onOk: () => handleDelete(pricingList.id),
    });
  };

  return (
    <>
      <FormattedMessage id={"page-titles.pricing-lists"} defaultMessage={"Cenniki"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <PricingListModalForm
        key={state.modalType === "edit" ? state.pricingList.id.value : "create-dentist"}
        visible={state.isModalVisible}
        modalType={state.modalType === "edit" ? "edit" : "create"}
        values={
          state.modalType === "edit"
            ? {
                name: state.pricingList.name,
              }
            : { name: "" }
        }
        onCancel={() => dispatch({ type: "hideModal" })}
        onSubmit={state.modalType === "edit" ? handleEdit(state.pricingList.id) : handleCreate}
      />
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.LabPricingLists />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id={"pricing-list-page.dentists"} defaultMessage={"Cenniki"} />}
          extra={[
            <Button type={"primary"} key={"create-button"} onClick={() => dispatch({ type: "showCreateModal" })}>
              <PlusOutlined />
              <FormattedMessage id="pricing-list-page.create-dentist" defaultMessage="Dodaj cennik" />
            </Button>,
          ]}
        />
      </HeaderContent>
      {getAllPricingLists === undefined && <Spin />}
      {getAllPricingLists !== undefined && getAllPricingLists.isOk() && (
        <>
          {getAllPricingLists
            .map((pricingLists) => (
              <PageContent>
                <div className="bg-white">
                  <PricingLists
                    pricingLists={pricingLists}
                    onEdit={(pricingList) => dispatch({ type: "showEditModal", pricingList })}
                    onDelete={showDeleteConfirm}
                  />
                </div>
              </PageContent>
            ))
            .unwrap()}
        </>
      )}
    </>
  );
};

export default AllLaboratoryPricingListsPage;
