export const XButtonBackground: React.FC = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2Z'
      fill='#858688'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.7247 15.3997L13.325 12L16.7247 8.60029C17.0918 8.23324 17.0918 7.64233 16.7247 7.27528C16.3577 6.90824 15.7668 6.90824 15.3997 7.27528L12 10.675L8.60029 7.27528C8.23324 6.90824 7.64233 6.90824 7.27528 7.27528C6.90824 7.64233 6.90824 8.23324 7.27528 8.60029L10.675 12L7.27528 15.3997C6.90824 15.7668 6.90824 16.3577 7.27528 16.7247C7.64233 17.0918 8.23324 17.0918 8.60029 16.7247L12 13.325L15.3997 16.7247C15.7668 17.0918 16.3577 17.0918 16.7247 16.7247C17.0892 16.3577 17.0892 15.7642 16.7247 15.3997Z'
      fill='white'
    />
  </svg>
);
