export enum Gender {
  Unknown = "unknown",
  Male = "male",
  Female = "female",
}

export enum GenderCode {
  Unknown = 1,
  Male = 2,
  Female = 3,
}
