/* eslint-disable react/display-name */
import * as React from "react";
import { Input as AntInput } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

export interface IInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size" | "prefix"> {
  prefixCls?: string;
  size?: SizeType;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  allowClear?: boolean;
  testId?: string;
}

const Input = React.forwardRef<AntInput, IInputProps>((props, ref: React.Ref<AntInput>) => {
  const { testId, ...rest } = props;
  return <AntInput ref={ref} {...rest} data-testid={testId} />;
});

export default Input;
