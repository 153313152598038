import React, { useCallback } from "react";
import classNames from "classnames";
import { useMeasure } from "react-use";
import { FormattedMessage } from "react-intl";
import { Alert, Button, Card, Divider, Form, List, Radio, Typography } from "antd";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PageContent from "features/layout/content/PageContent";
import { mapWidthToSize } from "core/utils/resize-utils";
import { PatientEntryMode } from "../../patient.model";
import { Gender } from "core/gender/gender.model";
import { Country } from "core/countries/countries.model";
import { OrderEntrySize as Size } from "../order-entry/OrderEntry";
import { SpecificationType } from "../../specification/order-specification";
import { IMoney } from "core/money/money.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { IOrderEntry } from "features/orders/components/order-entry/order-entry.model";
import { createEmptySpecification } from "features/orders/specification/order-specification.functions";
import { computePrice } from "features/catalogue/prices/prices.calculator";
import { GenerateGuid } from "core/guid/guid";
import { whenEvery } from "core/lib/types/result.utils";
import OrderTypeSearch from "features/orders/components/order-type-search/OrderTypeSearch";
import OrderEntryEdit from "features/orders/components/order-entry/OrderEntryEdit";
import OrderCanvas from "features/orders/components/order-canvas/OrderCanvas";
import { EmptySpecificationAlert } from "features/orders/components/order-entry/alerts/EmptySpecificationAlert";
import { isNotEmptyString } from "core/utils/utils";
import {
  CustomAlerts,
  FormMode,
  IBaseOrderSubmitValues,
  ICreateDentistOrderSubmitValues,
  ICreateLabOrderSubmitValues,
  IEditDentistOrderSubmitValues,
  IEditLabOrderSubmitValues,
  IOrderFormValues,
  OrderFormProps,
} from "features/orders/components/order-form/order-form.model";
import {
  mapOrderFormPropsToOrderFormSchemaProps as mapPropsToSchemaProps,
  useOrderFormValidationSchema,
} from "features/orders/components/order-form/hooks/useOrderFormValidationSchema";
import usePatientEntryMode from "features/orders/components/order-form/hooks/usePatientEntryMode";
import useOrderFormCatalogue, {
  orderFormCatalogueProps,
} from "features/orders/components/order-form/hooks/useOrderFormCatalogue";
import useOrderFormPricingList, {
  orderFormPricingListProps,
} from "features/orders/components/order-form/hooks/useOrderFormPricingList";
import useWatchOnLabChange from "features/orders/components/order-form/hooks/useWatchOnLabChange";
import useWatchOnPricingListChange from "features/orders/components/order-form/hooks/useWatchOnPricingListChange";
import { recomputePrices } from "features/orders/components/order-form/functions/recomputePrices";
import { Money } from "core/money/money.functions";
import { DentistControl } from "features/orders/components/order-form/components/form-controls/DentistControl";
import { LabControl } from "features/orders/components/order-form/components/form-controls/LabControl";
import { StartDateControl } from "features/orders/components/order-form/components/form-controls/StartDateControl";
import { DueDateControl } from "features/orders/components/order-form/components/form-controls/DueDateControl";
import { OrderNumberControl } from "features/orders/components/order-form/components/form-controls/OrderNumberControl";
import { PatientFirstNameControl } from "features/orders/components/order-form/components/form-controls/PatientFirstNameControl";
import { PatientLastNameControl } from "features/orders/components/order-form/components/form-controls/PatientLastNameControl";
import { PatientCodeControl } from "features/orders/components/order-form/components/form-controls/PatientCodeControl";
import { PatientAgeControl } from "features/orders/components/order-form/components/form-controls/PatientAgeControl";
import { PatientCountryControl } from "features/orders/components/order-form/components/form-controls/PatientCountryControl";
import { PatientGenderControl } from "features/orders/components/order-form/components/form-controls/PatientGenderControl";
import { OrderColorControl } from "features/orders/components/order-form/components/form-controls/OrderColorControl";
import { OrderNoteControl } from "features/orders/components/order-form/components/form-controls/OrderNoteControl";
import { IShortPricingList } from "features/pricing-lists/pricing-list.models";
import { Status } from "features/orders/status/order-status.model";
import { LabControlReadonly } from "features/orders/components/order-form/components/form-controls/LabControlReadonly";

import { OrderTypeNode } from "features/catalogue/order-catalogue-nodes.models";
import { NoPricingAlert } from "features/orders/components/order-entry/alerts/NoPricingAlert";
import { IOrderCatalogue } from "features/catalogue/category.model";
import { PricingStrategy } from "core/pricing/pricing.model";
import { allLetters } from "features/orders/components/letter-indicator/letter.model";

import "./order-form.scss";

const { DentistCreate, DentistEdit, LabCreate, LabEdit } = FormMode;

const toSize = mapWidthToSize({
  [Size.Small]: 0,
  [Size.Large]: 820,
});

const OrderForm: React.FunctionComponent<OrderFormProps> = (props) => {
  const [ref, { width }] = useMeasure();
  const size = toSize(width);
  const { orderFormSchema } = useOrderFormValidationSchema(mapPropsToSchemaProps(props));
  const { entryMode, toggleMode } = usePatientEntryMode(
    props.mode === FormMode.DentistEdit || props.mode === FormMode.LabEdit
      ? isNotEmptyString(props.defaultValues?.patientCode)
        ? PatientEntryMode.PatientCode
        : PatientEntryMode.PatientName
      : PatientEntryMode.PatientName
  );

  const defaultValues =
    props.mode === LabEdit || props.mode === DentistEdit
      ? { ...props.defaultValues }
      : { patientGender: Gender.Unknown, patientCountry: Country.Poland };

  const isDentistEditAndOrderStarted =
    props.mode === FormMode.DentistEdit &&
    props.orderStatus !== Status.ReadyForPickUpFromClinic &&
    props.orderStatus !== Status.TodoInLab;

  const {
    resetField,
    setValue,
    trigger,
    control,
    watch,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<IOrderFormValues>({
    resolver: yupResolver(orderFormSchema),
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "orderEntries",
    keyName: "key",
  });

  const orderEntries = watch("orderEntries");
  const { getOrdersCatalogue } = useOrderFormCatalogue(orderFormCatalogueProps(props, control));
  const { getPricingList, reloadPricingList } = useOrderFormPricingList(orderFormPricingListProps(props, control));

  const resetOrderEntries = useCallback(() => resetField("orderEntries"), [resetField]);

  useWatchOnLabChange({
    control,
    enabled: props.mode === FormMode.DentistCreate,
    callback: resetOrderEntries,
  });

  const onPricingListChanged = useCallback(
    async (pricingList: IShortPricingList) => {
      const newPricesDictionary = recomputePrices(pricingList, orderEntries);

      orderEntries.forEach((entry, idx) => {
        const { id, price: oldPrice } = entry;

        if (newPricesDictionary[id] === undefined) {
          return;
        } else if (oldPrice !== null && Money.isEqual(newPricesDictionary[id], oldPrice)) {
          return;
        }

        setValue(`orderEntries.${idx}` as const, {
          ...orderEntries[idx],
          price: newPricesDictionary[id],
        });
      });

      await trigger("orderEntries");
    },
    [orderEntries, setValue, trigger]
  );

  useWatchOnPricingListChange({
    enabled: props.mode === FormMode.LabEdit || props.mode === FormMode.LabCreate,
    getPricingList: getPricingList,
    onPricingListChanged: onPricingListChanged,
  });

  const onSubmitHandled = useCallback(
    (formValues: IOrderFormValues) => {
      const baseValues: IBaseOrderSubmitValues = {
        startDate: formValues.startDate,
        dueDate: formValues.dueDate,
        patientFirstName: formValues.patientFirstName,
        patientLastName: formValues.patientLastName,
        patientCode: formValues.patientCode,
        patientAge: formValues.patientAge,
        patientGender: formValues.patientGender === null ? Gender.Unknown : formValues.patientGender,
        patientCountry: formValues.patientCountry,
        orderColor: formValues.orderColor,
        orderNote: formValues.orderNote,
      };

      if (props.mode === FormMode.LabCreate) {
        const submitValues: ICreateLabOrderSubmitValues = {
          dentistId: { type: "dentist-id", value: formValues.dentistId },
          ...baseValues,
          orderNumber: formValues.orderNumber,
          orderEntries: formValues.orderEntries.map((entry) => ({
            specification: entry.specification,
            orderType: {
              id: entry.orderType.id,
              code: entry.orderType.code,
              name: entry.orderType.name,
              requiredSpecification: entry.orderType.requiredSpecification,
            },
            price: entry.price,
          })),
        };

        props.onSubmit(submitValues);
      } else if (props.mode === FormMode.DentistCreate) {
        const submitValues: ICreateDentistOrderSubmitValues = {
          labId: { type: "dental-lab-id", value: formValues.labId },
          ...baseValues,
          orderEntries: formValues.orderEntries.map((entry) => ({
            orderItemId: { type: "order-item-id", value: entry.id },
            specification: entry.specification,
            orderType: {
              id: entry.orderType.id,
              code: entry.orderType.code,
              name: entry.orderType.name,
              requiredSpecification: entry.orderType.requiredSpecification,
            },
            price: entry.price,
          })),
        };

        props.onSubmit(submitValues);
      } else if (props.mode === FormMode.LabEdit) {
        const submitValues: IEditLabOrderSubmitValues = {
          dentistId: { type: "dentist-id", value: formValues.dentistId },
          ...baseValues,
          orderNumber: formValues.orderNumber,
          orderEntries: formValues.orderEntries.map((entry) => ({
            orderItemId: { type: "order-item-id", value: entry.id },
            specification: entry.specification,
            orderType: {
              id: entry.orderType.id,
              code: entry.orderType.code,
              name: entry.orderType.name,
              requiredSpecification: entry.orderType.requiredSpecification,
            },
            price: entry.price,
          })),
        };

        props.onSubmit(submitValues);
      } else if (props.mode === FormMode.DentistEdit) {
        const submitValues: IEditDentistOrderSubmitValues = {
          ...baseValues,
          orderEntries: formValues.orderEntries.map((entry) => ({
            orderItemId: { type: "order-item-id", value: entry.id },
            specification: entry.specification,
            orderType: {
              id: entry.orderType.id,
              code: entry.orderType.code,
              name: entry.orderType.name,
              requiredSpecification: entry.orderType.requiredSpecification,
            },
            price: entry.price,
          })),
        };

        props.onSubmit(submitValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.mode, props.onSubmit]
  );

  const handleOrderTypeSelected = React.useCallback(
    async (value: OrderTypeNode) => {
      const { requiredSpecification, id: orderTypeId } = value;
      const defaultSpecification = createEmptySpecification(requiredSpecification);
      let price: IMoney | null = null;

      if (requiredSpecification === SpecificationType.Quantity) {
        if (getPricingList === undefined || getPricingList.isErr()) {
          price = null;
        } else {
          const pricingList = getPricingList.unwrap();
          const priceEntry = pricingList.entries.find((pe) => pe.orderTypeId.value === orderTypeId.value);

          if (priceEntry === null || priceEntry === undefined) {
            price = null;
          } else {
            price = computePrice(priceEntry.pricing, defaultSpecification);
          }
        }
      }

      const id = GenerateGuid();

      const newEntryItem: IOrderEntry & { key: string } = {
        key: id,
        id: id,
        orderType: value,
        specification: defaultSpecification,
        price: price,
      };

      append(newEntryItem);

      if (submitCount > 0) {
        await trigger("orderEntries");
      }
    },
    [append, getPricingList, submitCount, trigger]
  );

  const handleRefreshPricingListRequest = async () => {
    await reloadPricingList();
  };

  return (
    <div className={"w-full"} ref={ref as any}>
      <Form
        autoComplete="off"
        layout={"vertical"}
        className={classNames("order-form__container", {
          "order-form__container--size-small": size === Size.Small,
          "order-form__container--size-large": size === Size.Large,
        })}
      >
        <PageContent className={"order-form__basic-info"}>
          <Card
            title={<FormattedMessage id={"order-form.basic-info"} defaultMessage={"Informacje podstawowe"} />}
            bordered={false}
          >
            <div className="order-form__basic-info-inner">
              <div className="sm:w-full lg:w-4/12 lg:mr-20">
                {(props.mode === LabCreate || props.mode === LabEdit) && (
                  <DentistControl dentists={props.dentists} control={control} error={errors.dentistId} />
                )}
                {props.mode === DentistCreate && (
                  <LabControl labs={props.labs} control={control} error={errors.labId} />
                )}
                {props.mode === DentistEdit && <LabControlReadonly labs={props.labs} control={control} />}
                {(props.mode === LabCreate || props.mode === LabEdit) && (
                  <StartDateControl control={control} error={errors.startDate} />
                )}
                <DueDateControl control={control} error={errors.dueDate} />
                {(props.mode === LabCreate || props.mode === LabEdit) && (
                  <OrderNumberControl control={control} error={errors.orderNumber} />
                )}
              </div>
              <Divider className={"lg:hidden"} />
              <div className="sm:w-full lg:w-5/12">
                <Form.Item
                  label={
                    <FormattedMessage
                      id="create-or-pick-patient-form.patient-entry-mode"
                      defaultMessage="Informacja o pacjencie"
                    />
                  }
                >
                  <Radio.Group value={entryMode} onChange={toggleMode}>
                    <Radio.Button value={PatientEntryMode.PatientName}>
                      <FormattedMessage
                        id="create-or-pick-patient-form.patient-name"
                        defaultMessage="Imię i nazwisko"
                      />
                    </Radio.Button>
                    <Radio.Button value={PatientEntryMode.PatientCode}>
                      <FormattedMessage id="create-or-pick-patient-form.patient-code" defaultMessage="Kod pacjenta" />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {entryMode === PatientEntryMode.PatientName && (
                  <div className={"order-form__responsive"}>
                    <div className="order-form__patient-responsive">
                      <PatientFirstNameControl control={control} error={errors.patientFirstName} />
                    </div>
                    <div className="order-form__patient-responsive">
                      <PatientLastNameControl control={control} error={errors.patientLastName} />
                    </div>
                  </div>
                )}
                {entryMode === PatientEntryMode.PatientCode && (
                  <PatientCodeControl control={control} error={errors.patientCode} />
                )}
                <div className="order-form__responsive">
                  <div className="order-form__patient-responsive">
                    <PatientAgeControl control={control} error={errors.patientAge} />
                  </div>
                  <div className="order-form__patient-responsive">
                    <PatientCountryControl control={control} error={errors.patientCountry} />
                  </div>
                </div>
                <PatientGenderControl control={control} error={errors.patientGender} />
              </div>
            </div>
          </Card>
        </PageContent>
        <PageContent className={"order-form__order-items"}>
          <Card title="Zamówienie" bordered={false}>
            <List size="large">
              {props.mode === FormMode.DentistCreate && watch("labId") === undefined && (
                <Alert
                  className={"mt-2"}
                  message={<FormattedMessage id={"order-form.pick-lab"} defaultMessage={"Wybierz laboratorium"} />}
                  type={"error"}
                />
              )}
              {props.mode === FormMode.LabCreate && watch("dentistId") === undefined && (
                <Alert
                  className={"mt-2"}
                  message={<FormattedMessage id={"order-form.pick-dentist"} defaultMessage={"Wybierz dentystę"} />}
                  type={"error"}
                />
              )}
              {getOrdersCatalogue === undefined || getPricingList === undefined ? null : (
                <>
                  {whenEvery([getOrdersCatalogue, getPricingList])
                    .map(([ordersCatalogue, pricingList]) => {
                      let catalogue: IOrderCatalogue;

                      if (props.mode === FormMode.DentistEdit || props.mode === FormMode.DentistCreate) {
                        catalogue = {
                          categories: ordersCatalogue.categories.map((c) => ({
                            ...c,
                            orderTypes: c.orderTypes.filter((ot) =>
                              pricingList.entries.some(
                                (e) =>
                                  e.orderTypeId.value === ot.id.value && e.pricing.strategy !== PricingStrategy.NotSet
                              )
                            ),
                          })),
                        };
                      } else {
                        catalogue = ordersCatalogue;
                      }

                      return (
                        <>
                          {isDentistEditAndOrderStarted ? (
                            <Alert
                              className={"mt-2"}
                              message={"nie można edytować zamówienia gdy prace zostały rozpoczęte"}
                              type={"warning"}
                            />
                          ) : (
                            <>
                              <OrderTypeSearch catalogue={catalogue} onSelected={handleOrderTypeSelected} />
                              <Divider />
                            </>
                          )}
                          {fields.map((item, idx, all) => (
                            <List.Item key={item.id}>
                              <Form.Item
                                className={"w-full"}
                                validateStatus={errors.orderEntries?.[idx] !== undefined ? "error" : undefined}
                              >
                                <Controller
                                  name={`orderEntries.${idx}` as const}
                                  control={control}
                                  render={({ field: { value, onChange, ...fieldRest } }) => {
                                    const entry = value as IOrderEntry;

                                    return (
                                      <>
                                        <OrderEntryEdit
                                          key={item.id}
                                          size={size}
                                          value={value}
                                          letter={all.length <= 1 ? undefined : allLetters[idx]}
                                          disableEdit={isDentistEditAndOrderStarted}
                                          updatePriceOnPricingChange={true}
                                          onChange={(entry) => {
                                            onChange(entry);
                                            if (submitCount > 0) {
                                              // noinspection JSIgnoredPromiseFromCall
                                              trigger(`orderEntries.${idx}`);
                                            }
                                          }}
                                          pricingEntry={pricingList.entries.find(
                                            (pe) => pe.orderTypeId.value === entry.orderType.id.value
                                          )}
                                          onDelete={() => remove(idx)}
                                        />
                                        <ErrorMessage
                                          errors={errors}
                                          name={`orderEntries.${idx}.price` as const}
                                          render={({ message }) => {
                                            const item = ordersCatalogue.categories
                                              .flatMap((c) => c.orderTypes)
                                              .find((ot) => ot.id.value === entry.orderType.id.value);

                                            return message === CustomAlerts.NoPricingAvailable ? (
                                              <NoPricingAlert
                                                pricingListId={pricingList.id}
                                                orderType={{
                                                  orderTypeId: item!.id,
                                                  orderCode: item!.code,
                                                  name: item!.name,
                                                  requiredSpecification: item!.requiredSpecification,
                                                }}
                                                refreshPricingEntries={handleRefreshPricingListRequest}
                                              />
                                            ) : (
                                              <div>
                                                <Typography.Text type="danger">{message}</Typography.Text>
                                              </div>
                                            );
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`orderEntries.${idx}.specification` as const}
                                  render={({ message }) =>
                                    message === CustomAlerts.EmptySpecification ? (
                                      <EmptySpecificationAlert />
                                    ) : (
                                      <div>
                                        <Typography.Text type="danger">{message}</Typography.Text>
                                      </div>
                                    )
                                  }
                                />
                              </Form.Item>
                            </List.Item>
                          ))}
                          <ErrorMessage
                            errors={errors}
                            name={`orderEntries` as const}
                            render={({ message }) => <Typography.Text type="danger">{message}</Typography.Text>}
                          />
                        </>
                      );
                    })
                    .unwrap()}
                </>
              )}
            </List>
          </Card>
        </PageContent>
        <PageContent className={"order-form__order-drawing"}>
          <Card title={"Rysunek zamówienia"} bordered={false}>
            {orderEntries !== undefined && orderEntries !== null && <OrderCanvas orderEntries={orderEntries} />}
          </Card>
        </PageContent>
        <PageContent className={"order-form__order-color"}>
          <Card title="Kolor" bordered={false}>
            <OrderColorControl
              control={control}
              disabled={isDentistEditAndOrderStarted}
              error={errors.orderColor as any}
            />
          </Card>
        </PageContent>
        <PageContent className={"order-form__order-notes"}>
          <Card title="Notatki" bordered={false}>
            <OrderNoteControl control={control} error={errors.orderNote} />
          </Card>
        </PageContent>
        <PageContent className={"order-form__submit-order"}>
          <Card
            title={
              props.mode === FormMode.DentistEdit || props.mode === FormMode.LabEdit ? (
                <FormattedMessage id="order-form.edit-order" defaultMessage="Edytuj zamówienie" />
              ) : (
                <FormattedMessage id="order-form.create-order" defaultMessage="Stwórz zamówienie" />
              )
            }
            bordered={false}
          >
            <Button htmlType={"button"} onClick={handleSubmit(onSubmitHandled)}>
              <FormattedMessage id="order-form.save" defaultMessage="Zapisz" />
            </Button>
          </Card>
        </PageContent>
      </Form>
    </div>
  );
};

export default OrderForm;
