export const standardSort = (a: any, b: any) => (a > b ? 1 : b > a ? -1 : 0);
export const booleanSort = (a: boolean, b: boolean) =>
    (a && b) || (!a && !b) ? 0 : a ? -1 : 1;
export const nullCheckSort = (a: any, b: any) =>
    a === null && b === null ? 0 : a === null ? -1 : 1;

export const getStandardSort = <T extends object, K extends keyof T>(
    propToSortBy: K,
    getFn?: (objToGetFrom: T[K], record: T) => any,
    caseInsensitive?: undefined | boolean,
) => {
    return (a: T, b: T) => {
        let valueA, valueB;

        if (getFn) {
            valueA = getFn(a[propToSortBy], a);
            valueB = getFn(b[propToSortBy], b);
        } else {
            valueA = a[propToSortBy];
            valueB = b[propToSortBy];
        }

        if (
            caseInsensitive !== false &&
            typeof valueA === "string" &&
            typeof valueB === "string"
        ) {
            valueA = valueA.toLowerCase();
            valueB = valueB.toLowerCase();
        }

        return standardSort(valueA, valueB);
    };
};

export const getStandardSortWithNullCheck = <T extends object | null, K extends keyof T>(
    propToSortBy: K,
) => {
    return (a: T, b: T) =>
        a === null || b === null
            ? -booleanSort(a as any, b as any)
            : a![propToSortBy] === null || b![propToSortBy] === null
                ? -booleanSort(a![propToSortBy] as any, b![propToSortBy] as any)
                : standardSort(a![propToSortBy], b![propToSortBy]);
};

export const getBooleanSort = <T extends object, K extends keyof T>(
    propToSortBy: K,
    getFn?: (objToGetFrom: T[K], record: T) => any,
) => {
    return (a: T, b: T) =>
        getFn
            ? booleanSort(getFn(a[propToSortBy], a) as any, getFn(b[propToSortBy], b) as any)
            : booleanSort(a[propToSortBy] as any, b[propToSortBy] as any);
};

export const getBooleanSortWithNullCheck = <T extends object, K extends keyof T>(
    propToSortBy: K,
    getFn?: (objToGetFrom: T[K], record: T) => any,
) => {
    return (a: T, b: T) => {
        const aToCheck = getFn ? getFn(a[propToSortBy], a) : a[propToSortBy];
        const bToCheck = getFn ? getFn(b[propToSortBy], b) : b[propToSortBy];

        return aToCheck === null || bToCheck === null
            ? nullCheckSort(aToCheck as any, bToCheck as any)
            : booleanSort(aToCheck as any, bToCheck as any);
    };
};

export const sortByIndexInArray = <T extends any>(valueA: T, valueB: T, array: T[]) => {
    return array.indexOf(valueA) - array.indexOf(valueB);
};
