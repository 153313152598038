import * as React from "react";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, PageHeader } from "antd";
import { FormattedMessage } from "react-intl";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import Links from "components/links/Links";
import SettingsView from "../components/settings-view/SettingsView";

interface IListOrderPageProps {}

const SettingsPage: React.FunctionComponent<IListOrderPageProps> = () => {
  return (
    <div>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.Settings />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title={<FormattedMessage id="common.settings" defaultMessage="Ustawienia" />} />
      </HeaderContent>
      <PageContent>
        <div className="my-8 bg-white overflow-x-auto">
          <SettingsView />
        </div>
      </PageContent>
    </div>
  );
};

export default SettingsPage;
