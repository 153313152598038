import * as React from "react";
import { useMemo } from "react";
import { Breadcrumb, Card, Collapse, List, Spin } from "antd";
import { useParams } from "react-router";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import PricingListTable from "features/catalogue/prices/components/pricing-list-table/PricingListTable";
import useOrdersCatalogue from "features/orders/hooks/useOrdersCatalogue";
import ErrorScreen from "components/errors/ErrorScreen";
import { IUpdatePricingEntryRequest, NewPricingListId } from "features/pricing-lists/pricing-list.models";
import usePricingList from "features/pricing-lists/hooks/lab/usePricingList";
import useMainPricingList from "features/pricing-lists/hooks/lab/useMainPricingList";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

const LabPricingListPage = () => {
  const { pricingListId: idString } = useParams<"pricingListId">();
  const { getOrdersCatalogue } = useOrdersCatalogue({ mode: "lab" });
  const pricingListId = useMemo(() => NewPricingListId(idString!), [idString]);
  const { getPricingList, setPricingEntry, revalidate: reloadPricingList } = usePricingList(pricingListId);
  const { getPricingList: getMainPricingList, revalidate: reloadMainPricingList } = useMainPricingList();

  const handleSetPricingEntry = async (request: IUpdatePricingEntryRequest) => {
    const result = await setPricingEntry(request);

    if (result.isOk()) {
      await reloadPricingList();
      await reloadMainPricingList();
    }

    return result;
  };

  if (getOrdersCatalogue === undefined || getPricingList === undefined || getMainPricingList === undefined) {
    return <Spin />;
  }

  if (getOrdersCatalogue.isErr()) {
    return <ErrorScreen error={getOrdersCatalogue.err().unwrap()} />;
  } else if (getPricingList.isErr()) {
    return <ErrorScreen error={getPricingList.err().unwrap()} />;
  } else if (getMainPricingList.isErr()) {
    return <ErrorScreen error={getMainPricingList.err().unwrap()} />;
  }

  const orderCatalogue = getOrdersCatalogue.unwrap();
  const pricingList = getPricingList.unwrap();
  const mainPricingList = getMainPricingList.unwrap();

  return (
    <>
      <FormattedMessage id={"page-titles.pricing-lists"} defaultMessage={"Cenniki"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.LabPricingLists />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.LabPricingList pricingListId={pricingList.id}>
              <>{pricingList.name}</>
            </Links.LabPricingList>
          </Breadcrumb.Item>
        </Breadcrumb>
      </HeaderContent>
      <PageContent className={"lg:p-5"}>
        <Collapse>
          <Collapse.Panel header="Przypisani dentysci do cennika" key="1">
            <List
              grid={{ gutter: 8, xs: 1, md: 2, lg: 4, xl: 5 }}
              itemLayout="horizontal"
              dataSource={pricingList.dentists}
              locale={{ emptyText: <span>Brak przypisanych dentystów</span> }}
              renderItem={(dentist) => (
                <List.Item>
                  <Card>
                    <PersonNameFormatter personName={dentist.name} />
                  </Card>
                </List.Item>
              )}
            />
          </Collapse.Panel>
        </Collapse>
      </PageContent>
      <PageContent className={"lg:p-5"}>
        <PricingListTable
          pricingList={pricingList}
          isDefaultPricingList={pricingList.isDefault}
          mainPricingList={mainPricingList}
          orderCatalogue={orderCatalogue}
          onUpdatePricing={handleSetPricingEntry}
        />
      </PageContent>
    </>
  );
};

export default LabPricingListPage;
