import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Select, SelectProps } from "antd";
import { PricingFormType } from "../pricing-input/pricing-input.models";

const PricingFormTypeSelect: React.FunctionComponent<
  { allowedTypes: Set<PricingFormType> } & SelectProps<PricingFormType>
> = ({ allowedTypes, value, ...restProps }) => {
  return (
    <Select<PricingFormType> value={value} {...(restProps as any)}>
      {value === PricingFormType.PricingNotSet && (
        <Select.Option disabled={true} value={PricingFormType.PricingNotSet}>
          <FormattedMessage id="pricing-form.pricing-not-set" defaultMessage="Brak ceny" />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.IndividualPricing) && (
        <Select.Option value={PricingFormType.IndividualPricing}>
          <FormattedMessage id={"pricing-form.individual-pricing"} defaultMessage={"Wycena Indiwidualna"} />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.FixedPrice) && (
        <Select.Option value={PricingFormType.FixedPrice}>
          <FormattedMessage id={"pricing-form.fixed-price"} defaultMessage={"Cena stała"} />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.Quantity) && (
        <Select.Option value={PricingFormType.Quantity}>
          <FormattedMessage id={"pricing-form.per-quantity-pricing"} defaultMessage={"Cena za jedną sztukę"} />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.ArchEqualPrices) && (
        <Select.Option value={PricingFormType.ArchEqualPrices}>
          <FormattedMessage
            id={"pricing-form.per-arch-pricing-all-equal-price"}
            defaultMessage={"Wycena za jeden łuk"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.UpperAndLowerArchPrices) && (
        <Select.Option value={PricingFormType.UpperAndLowerArchPrices}>
          <FormattedMessage
            id={"pricing-form.per-arch-pricing-diff-prices"}
            defaultMessage={"Wycena za łuk górny oraz dolny"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.PointEqualPrices) && (
        <Select.Option value={PricingFormType.PointEqualPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-point-all-equal-price"}
            defaultMessage={"Wycena za jeden punkt"}
          />
          {/*  TODO: zmien tłumaczenie na to samo co jest w sztukach */}
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.FrontAndBackPointPrices) && (
        <Select.Option value={PricingFormType.FrontAndBackPointPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-point-diff-prices-for-each-area"}
            defaultMessage={"Wycena za jeden punkt z przodu oraz z tyłu"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.PointWithBaseArchPrice) && (
        <Select.Option value={PricingFormType.PointWithBaseArchPrice}>
          <FormattedMessage
            id={"pricing-form.base-price-plus-per-item"}
            defaultMessage={"Cena bazowa + za każdy punkt"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.CrownEqualPrices) && (
        <Select.Option value={PricingFormType.CrownEqualPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-crown-all-equal-price"}
            defaultMessage={"Wycena za jedną koronę"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.FrontAndBackCrownPrices) && (
        <Select.Option value={PricingFormType.FrontAndBackCrownPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-crown-diff-prices-for-each-area"}
            defaultMessage={"Wycena za jedną koronę z przodu oraz z tyłu"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.BridgePointEqualPrices) && (
        <Select.Option value={PricingFormType.BridgePointEqualPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-bridge-all-equal-price"}
            defaultMessage={"Wycena za jedną koronę w moście"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.FrontAndBackBridgePointPrices) && (
        <Select.Option value={PricingFormType.FrontAndBackBridgePointPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-bridge-diff-prices-for-each-area"}
            defaultMessage={"Wycena za jedną koronę w moście z przodu oraz z tyłu"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.MarylandBridgePointEqualPrices) && (
        <Select.Option value={PricingFormType.MarylandBridgePointEqualPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-maryland-bridge-all-equal-price"}
            defaultMessage={"Wycena za jedną koronę w moście maryland"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.FrontAndBackMarylandBridgePointPrices) && (
        <Select.Option value={PricingFormType.FrontAndBackMarylandBridgePointPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-maryland-bridge-diff-prices-for-each-area"}
            defaultMessage={"Wycena za jedną koronę w moście maryland z przodu oraz z tyłu"}
          />
        </Select.Option>
      )}
      {allowedTypes.has(PricingFormType.FoundationPointEqualPrices) && (
        <Select.Option value={PricingFormType.FoundationPointEqualPrices}>
          <FormattedMessage
            id={"pricing-form.pricing-per-foundation-point"}
            defaultMessage={"Wycena za jeden punkt w podbudowie"}
          />
        </Select.Option>
      )}
    </Select>
  );
};
export default PricingFormTypeSelect;
