import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { InviteRouteParams, Path } from "core/routes/routes";
import { Breadcrumb, Card, Form, Input, PageHeader, Result, Typography } from "antd";
import Links from "components/links/Links";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Button from "components/button/Button";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import { RegisterServiceContext } from "features/register/register.service";
import { useStateAsync } from "hooks/useStateAsync";
import { useForm } from "antd/es/form/Form";
import useMemoAsync from "hooks/useMemoAsync";
import { Helmet } from "react-helmet";

export enum InviteMode {
  Dentist = "dentist",
  Employee = "employee",
}

interface IInvitePageProps {
  mode: InviteMode;
}

const InvitePage: FC<IInvitePageProps> = ({ mode }) => {
  const [form] = useForm();
  const registerService = useContext(RegisterServiceContext);
  const { employeeId, dentistId } = useParams() as InviteRouteParams;
  const [loading, setLoading] = useStateAsync(false);
  const [sent, setSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const goBackHref = useMemo(() => {
    switch (mode) {
      case InviteMode.Dentist:
        return Path.ListDentists;
      case InviteMode.Employee:
        return Path.ListEmployees;
    }
  }, [mode]);

  const sendInvite = useCallback(
    async ({ email }: { email: string }) => {
      await setLoading(true);

      try {
        switch (mode) {
          case InviteMode.Dentist:
            await registerService.inviteDentist({ email, dentistId: dentistId! });
            break;
          case InviteMode.Employee:
            await registerService.inviteEmployee({ email, employeeId: employeeId! });
            break;
        }

        setSent(true);
      } catch (e) {
        console.error(e);
      } finally {
        await setLoading(false);
      }
    },
    [setLoading, mode, registerService, dentistId, employeeId]
  );

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const [valid] = useMemoAsync(
    async () => {
      if (!email) return false;

      try {
        await form.validateFields(["email"]);
      } finally {
        return form.getFieldsError(["email"])?.[0]?.errors?.length === 0;
      }
    },
    [email, form],
    false
  );

  return (
    <>
      {mode === InviteMode.Dentist && (
        <FormattedMessage id={"page-titles.invite-dentist"} defaultMessage={"Zaproś dentystę"}>
          {(title) => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
      )}
      {mode === InviteMode.Employee && (
        <FormattedMessage id={"page-titles.invite-employee"} defaultMessage={"Zaproś pracownika"}>
          {(title) => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
      )}
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.DentistsList />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <FormattedMessage id={"invite-page.send-invite"} defaultMessage={"Wyślij zaproszenie"} />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title={<FormattedMessage id={"invite-page.send-invite"} defaultMessage={"Wyślij zaproszenie"} />} />
      </HeaderContent>
      <PageContent className="flex justify-center bg-transparent">
        <Card
          title={
            <Typography.Text strong>
              <FormattedMessage
                id="invite-page.enter-email"
                defaultMessage="Wprowadź adres email, żeby wysłać zaproszenie"
              />
            </Typography.Text>
          }
          className="my-8 p-8 bg-white max-w-xl w-full"
        >
          {sent ? (
            <Result
              status="success"
              title={<FormattedMessage id="invite-page.invite-sent" defaultMessage="Wysłano zaproszenie" />}
              subTitle={
                <>
                  <FormattedMessage
                    id="invite-page.sent-to-email"
                    defaultMessage="Zaproszenie zostało wysłane na adres e-mail: "
                  />
                  <Typography.Text strong>{email}</Typography.Text>
                </>
              }
              extra={
                <Button type="primary" href={goBackHref}>
                  <FormattedMessage id="invite-page.go-back" defaultMessage="Wróć do listy" />
                </Button>
              }
            />
          ) : (
            <Form form={form} initialValues={{ email: "" }} onFinish={sendInvite}>
              <Form.Item
                className="mt-4"
                name="email"
                label={<FormattedMessage id="invite-page.email" defaultMessage="Email" />}
                required
                rules={[{ type: "email" }]}
              >
                <Input type="email" placeholder="john.doe@example.com" onChange={handleChange} />
              </Form.Item>
              <Form.Item className="mt-2">
                <div className="flex">
                  <Button type="default" href={goBackHref} className="ml-auto mr-2">
                    <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading} disabled={!valid}>
                    <FormattedMessage id="common.submit" defaultMessage="Wyślij" />
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
        </Card>
      </PageContent>
    </>
  );
};

export default InvitePage;
