import React from "react";
import { Card, List, Space, Typography } from "antd";
import MoneyFormatter from "components/formatters/money/MoneyFormatter";
import ShadeFormatter from "components/formatters/shade/ShadeFormatter";
import ShadeGuideFormatter from "components/formatters/shade/ShadeGuideFormatter";
import { IMoney } from "core/money/money.model";
import { NO_COLOR } from "../../../color/color.model";
import OrderCanvas from "../../../components/order-canvas/OrderCanvas";
import OrderEntry, { IOrderEntryProps, OrderEntrySize } from "../../../components/order-entry/OrderEntry";
import { UnifiedOrderReadModel } from "../../../order.model";
import { LabeledValue } from "./LabeledValue";
import { IOrderCatalogue } from "features/catalogue/category.model";
import { allLetters } from "features/orders/components/letter-indicator/letter.model";

export type OrderSummaryProps = {
  order: Pick<UnifiedOrderReadModel, "orderItems" | "color" | "orderNote">;
  ordersCatalogue: IOrderCatalogue;
  totalOrderPrice: IMoney;
  handlePriceChange: IOrderEntryProps["onChangePrice"];
};

export const OrderSummary: React.FunctionComponent<OrderSummaryProps> = ({
  order,
  ordersCatalogue,
  totalOrderPrice,
  handlePriceChange,
}) => {
  const allOrderTypes = ordersCatalogue.categories.flatMap((c) => c.orderTypes);

  const orderItems = order.orderItems
    .filter((oi) => allOrderTypes.some((ot) => ot.id.value === oi.orderTypeId.value))
    .map((oi) => [oi, allOrderTypes.find((ot) => ot.id.value === oi.orderTypeId.value)!] as const);

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <Card title="Zamówienie" bordered={false}>
        <List size="large">
          {orderItems.map(([orderItem, orderType], idx) => (
            <List.Item key={orderItem.id.value}>
              <OrderEntry
                letter={orderItems.length <= 1 ? undefined : allLetters[idx]}
                value={{
                  id: orderItem.id.value,
                  orderType: orderType,
                  specification: orderItem.specification,
                  price: orderItem.totalPrice,
                }}
                size={OrderEntrySize.Large}
                onChangePrice={handlePriceChange}
              />
            </List.Item>
          ))}
        </List>
        <div className="text-right">
          <MoneyFormatter value={totalOrderPrice}>
            {(formattedMoney) => (
              <LabeledValue label="Wartość zamówienia" value={formattedMoney} direction="horizontal" size="small" />
            )}
          </MoneyFormatter>
        </div>
      </Card>
      <Card title={"Rysunek zamówienia"} bordered={false}>
        <OrderCanvas
          orderEntries={orderItems.map(([orderItem, orderType]) => ({
            id: orderItem.id.value,
            orderType: orderType,
            specification: orderItem.specification,
          }))}
        />
      </Card>
      <Card title="Kolor i notatki" bordered={false}>
        {order.color !== NO_COLOR && (
          <>
            <ShadeGuideFormatter guide={order.color.guide} />
            {" - "}
            <ShadeFormatter shade={order.color.shade} />
          </>
        )}
        <Typography.Paragraph className={"mt-3"}>{order.orderNote}</Typography.Paragraph>
      </Card>
    </Space>
  );
};
