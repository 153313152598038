import * as React from "react";
import { FormattedRelativeTime, FormattedDate } from "react-intl";
import { useInterval } from "react-use";
import { ISODateTimeString } from "core/time/time.model";
import { IClock } from "core/clock/clock.service";
import ClockContext from "core/clock/clock.context";

interface IDueDateFormatterProps {
  dueDate: ISODateTimeString | null;
  disableRelativeTime?: boolean;
  showRelativeTimeWhenInPast?: boolean;
}

const computeRelativeTimeInSeconds = (
  clock: IClock,
  dueDate: ISODateTimeString | null
): number | null => {
  if (dueDate === null) {
    return null;
  }

  const now = clock.now();
  const dueDateUnix = new Date(dueDate).valueOf();
  const relativeSeconds = Math.round((dueDateUnix - now) / 1000);

  return relativeSeconds;
};

const DueDateFormatter: React.FunctionComponent<IDueDateFormatterProps> = ({
  dueDate,
  disableRelativeTime = false,
  showRelativeTimeWhenInPast = false,
}) => {
  const clock = React.useContext(ClockContext);
  const [relativeTime, setRelativeTime] = React.useState<number | null>(
    computeRelativeTimeInSeconds(clock, dueDate)
  );

  useInterval(() => {
    setRelativeTime(computeRelativeTimeInSeconds(clock, dueDate));
  }, 5000);

  if (dueDate === null || relativeTime === null) {
    return null;
  }

  const showRelativeTime =
    disableRelativeTime === false &&
    (relativeTime > 0 || (showRelativeTimeWhenInPast && relativeTime < 0));

  return (
    <>
      <span className="mr-1">
        <FormattedDate value={new Date(dueDate)} />
      </span>
      {showRelativeTime && (
        <span className="whitespace-no-wrap">
          {"("}
          <FormattedRelativeTime
            value={relativeTime}
            numeric="auto"
            updateIntervalInSeconds={60}
          />
          {")"}
        </span>
      )}
    </>
  );
};

export default DueDateFormatter;
