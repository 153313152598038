import { SetStateAction, useCallback, useEffect, useState } from "react";

export type DispatchWithPromise<A> = (value: A) => Promise<void>;

export function useStateAsync<S>(
  initialState: S | (() => S)
): [S, DispatchWithPromise<SetStateAction<S>>] {
  const [state, setState] = useState(initialState);

  const [resolver, setResolver] = useState<null | ((value?: S | PromiseLike<S>) => void)>(
    null
  );

  useEffect(() => {
    if (resolver) {
      resolver(state);
      setResolver(null);
    }
  }, [resolver, state, setResolver]);

  const handleSetState = useCallback(
    (stateAction: SetStateAction<S>) => {
      setState(stateAction);

      return new Promise((resolve) => {
        setResolver(resolve as (value?: S | PromiseLike<S>) => void);
      });
    },
    [setState, setResolver]
  ) as DispatchWithPromise<SetStateAction<S>>;

  return [state, handleSetState];
}
