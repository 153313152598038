import * as React from "react";
import { useIntl } from "react-intl";
import { CategoryCode } from "features/catalogue/category.model";
import { categoryFormatter } from "components/formatters/category/category-formatter";
import { isNumber } from "core/utils/utils";

interface ICategoryNameProps {
  code: CategoryCode;
  name?: string;
}

const CategoryName: React.FunctionComponent<ICategoryNameProps> = (props) => {
  const intl = useIntl();

  if (isNumber(props.code)) {
    return <>{categoryFormatter(intl, { name: props.name ?? "", code: props.code })}</>;
  }

  return null;
};

export default CategoryName;
