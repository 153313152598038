import * as React from "react";
import { Layout as AntLayout, Drawer } from "antd";
import NavigationMenu from "./navigation-menu/NavigationMenu";
import useWindowSize from "hooks/useWindowSize";
import usePrevious from "hooks/usePrevious";
import { MenuState } from "./MenuState";
import { LayoutSize } from "./LayoutSize";
import Header from "features/layout/header/Header";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { companyName } from "core/global/global-config";

const { Sider, Content } = AntLayout;

export interface ILayoutProps {}

const { Small, Medium, Large } = LayoutSize;
const { Hidden, Shown } = MenuState;

const toggleMenuState = (prev: MenuState) => {
  if (prev === Hidden) {
    return Shown;
  }

  return Hidden;
};

const mapWindowSizeToLayout = (width: number): LayoutSize => {
  if (width < 450) {
    return Small;
  } else if (width < 600) {
    return Medium;
  } else {
    return Large;
  }
};

const Layout: React.FunctionComponent<ILayoutProps> = (props) => {
  const windowSize = useWindowSize();

  const size = windowSize.width === undefined ? Small : mapWindowSizeToLayout(windowSize.width);

  const previousSize = usePrevious(size);

  const [menuState, setMenuState] = React.useState<MenuState>(size === Large ? Shown : Hidden);

  const toggleMenu = React.useCallback(() => {
    setMenuState(toggleMenuState(menuState));
  }, [menuState]);

  React.useEffect(() => {
    if (previousSize === size) {
      return;
    }

    const isBecomingSmaller =
      (previousSize === Large && size === Medium) ||
      (previousSize === Large && size === Small) ||
      (previousSize === Medium && size === Small);

    const isBecomingBigger =
      (previousSize === Medium && size === Large) ||
      (previousSize === Small && size === Medium) ||
      (previousSize === Small && size === Large);

    if (isBecomingSmaller && menuState === Shown) {
      setMenuState(Hidden);
    } else if (isBecomingBigger && menuState === Hidden && size === Large) {
      setMenuState(Shown);
    } else if (isBecomingBigger && menuState === Shown && size === Medium) {
      setMenuState(Hidden);
    }
  }, [previousSize, size, menuState]);

  return (
    <>
      <Helmet titleTemplate={`%s | ${companyName}`} defaultTitle={companyName} />
      <AntLayout>
        {size === Small && (
          <Drawer
            placement="left"
            closable={false}
            visible={menuState === Shown}
            bodyStyle={{ padding: 0 }}
            drawerStyle={{ backgroundColor: "#001529" }}
            width={200}
            onClose={toggleMenu}
          >
            <NavigationMenu menuState={menuState} />
          </Drawer>
        )}
        {(size === Medium || size === Large) && (
          <Sider trigger={null} collapsible collapsed={menuState === Hidden}>
            <NavigationMenu menuState={menuState} />
          </Sider>
        )}
        <AntLayout>
          <Content
            style={{
              minHeight: 280,
              overflow: "auto",
            }}
          >
            <Header toggleMenu={toggleMenu} menuState={menuState} />
            <Outlet />
          </Content>
        </AntLayout>
      </AntLayout>
    </>
  );
};

export default Layout;
