import * as React from "react";
import stringify from "json-stable-stringify";
import { OrderSpecification, SpecificationType } from "../../../specification/order-specification";
import QuantitySpecificationControls from "./QuantitySpecificationControls";
import ArchesSpecificationControls from "./ArchesSpecificationControls";
import LocatedPointsControls from "./LocatedPointsControls";
import CrownPointsControls from "./CrownPointsControls";
import PickLinkedPointsControls from "./LinkedPointsControls";
import "./specifications.scss";

interface IOrderEntrySpecificationProps {
  specification: OrderSpecification;
  onChange?: (specification: OrderSpecification) => void;
}

const SpecificationProviders: React.FunctionComponent<IOrderEntrySpecificationProps> = ({
  specification,
  onChange,
}) => {
  switch (specification.specificationType) {
    case SpecificationType.Quantity:
      return <QuantitySpecificationControls specification={specification} onChange={onChange} />;
    case SpecificationType.Arches:
      return <ArchesSpecificationControls specification={specification} onChange={onChange} />;
    case SpecificationType.LocatedPoints:
      return <LocatedPointsControls specification={specification} onChange={onChange} />;
    case SpecificationType.PartialDentures:
      return <LocatedPointsControls specification={specification} onChange={onChange} />;
    case SpecificationType.SurgicalGuides:
      return <LocatedPointsControls specification={specification} onChange={onChange} />;
    case SpecificationType.Crowns:
      return <CrownPointsControls key={stringify(specification)} specification={specification} onChange={onChange} />;
    case SpecificationType.Bridges:
      return (
        <PickLinkedPointsControls key={stringify(specification)} specification={specification} onChange={onChange} />
      );
    case SpecificationType.MarylandBridges:
      return (
        <PickLinkedPointsControls key={stringify(specification)} specification={specification} onChange={onChange} />
      );
    case SpecificationType.Foundations:
      return (
        <PickLinkedPointsControls key={stringify(specification)} specification={specification} onChange={onChange} />
      );
  }

  return null;
};

export default SpecificationProviders;
