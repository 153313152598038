import { Col, Divider, Row, Typography } from "antd";
import { FormattedDate, FormattedMessage } from "react-intl";
import DueDateFormatter from "components/formatters/dates/DueDateFormatter";
import GenderFormatter from "components/formatters/gender/GenderFormatter";
import PatientNameFormatter from "components/formatters/patient-name/PatientNameFormatter";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import { Reader, UnifiedOrderReadModel } from "features/orders/order.model";
import { LabeledValue } from "./LabeledValue";
import Button from "components/button/Button";
import { ScheduleOutlined } from "@ant-design/icons";
import * as React from "react";
import { Status } from "features/orders/status/order-status.model";

type OrderPersonalInfoProps = {
  order: UnifiedOrderReadModel;
  onChangeFinishDate?: () => void;
};

export const OrderInfo = ({ order, onChangeFinishDate }: OrderPersonalInfoProps) => (
  <>
    <div className={"lg:mb-4"}>
      <Typography.Title level={4}>
        {order.reader === Reader.Lab && (
          <>
            <span>
              <PersonNameFormatter personName={order.dentist.name} />
            </span>
            {order.dentist.clinicName !== null && (
              <>
                <span>{" - "}</span>
                <span>{order.dentist.clinicName}</span>
              </>
            )}
          </>
        )}
        {order.reader === Reader.Dentist && <>{order.labName}</>}
      </Typography.Title>
    </div>
    <Row gutter={[16, 16]}>
      {order.reader === Reader.Lab && (
        <Col xs={24} md={24}>
          <LabeledValue label="Numer zamówienia" value={order.orderNumber} />
        </Col>
      )}
      <Col xs={24} md={8}>
        <LabeledValue
          label={<FormattedMessage id="register-form" defaultMessage="Data przyjścia" />}
          value={order.startDate !== null ? <FormattedDate value={new Date(order.startDate)} /> : " - "}
        />
      </Col>
      <Col xs={24} md={8}>
        <LabeledValue
          label={<FormattedMessage id="order-details-page.due-date" defaultMessage="Termin oddania" />}
          value={order.dueDate !== null ? <DueDateFormatter dueDate={order.dueDate} /> : " - "}
        />
      </Col>
      <Col xs={24} md={8}>
        <div className={"w-full flex flex-row items-center gap-4 "}>
          <LabeledValue
            label={<FormattedMessage id="order-details-page.finish-date" defaultMessage="Data ukończenia" />}
            value={order.finishDate !== null ? <FormattedDate value={new Date(order.finishDate)} /> : " - "}
          />
          {order.status === Status.Finished && onChangeFinishDate !== undefined && (
            <Button icon={<ScheduleOutlined />} onClick={onChangeFinishDate} />
          )}
        </div>
      </Col>
    </Row>

    <Divider />

    <Typography.Title level={5}>
      <FormattedMessage id="order-details-page.patient" defaultMessage="Pacjent" />
    </Typography.Title>
    <Row gutter={16}>
      <Col span={10}>
        <LabeledValue
          label={<FormattedMessage id="order-details-page.first-and-last-name" defaultMessage="Imię i nazwisko" />}
          value={<PatientNameFormatter patientName={order.patient} whenEmpty={"-"} />}
        />
      </Col>
      <Col span={10}>
        <LabeledValue
          label={<FormattedMessage id="order-details-page.gender" defaultMessage="Płeć" />}
          value={order.patient !== null ? <GenderFormatter gender={order.patient.gender} /> : " - "}
        />
      </Col>
      <Col span={4}>
        <LabeledValue
          label={<FormattedMessage id="order-details-page.age" defaultMessage="Wiek" />}
          value={order.patient !== null && order.patient.age !== null ? order.patient.age : " - "}
        />
      </Col>
    </Row>
  </>
);
