import { useEffect } from "react";
import { usePrevious } from "react-use";
import { isEqual } from "core/utils/utils";
import { IResult } from "core/lib/types/result";

interface IWatchOnPricingListChangeProps<T> {
  enabled: boolean;
  getPricingList: IResult<T> | undefined;
  onPricingListChanged: (pricingList: T) => void;
}

function useWatchOnPricingListChange<T>(props: IWatchOnPricingListChangeProps<T>) {
  const { getPricingList, onPricingListChanged, enabled } = props;
  const previousGetPricingList = usePrevious(props.getPricingList);

  useEffect(() => {
    if (typeof onPricingListChanged !== "function") {
      return;
    }

    if (!enabled) {
      return;
    }

    if (previousGetPricingList === undefined) {
      return;
    }

    if (getPricingList === undefined) {
      return;
    }

    if (getPricingList.isErr()) {
      return;
    }

    const pricingList = getPricingList.unwrap();

    if (previousGetPricingList.isOk() && isEqual(previousGetPricingList.unwrap(), pricingList)) {
      return;
    }

    onPricingListChanged(pricingList);
  }, [props, previousGetPricingList, onPricingListChanged, enabled, getPricingList]);
}

export default useWatchOnPricingListChange;
