import React from "react";
import { nameof } from "ts-simple-nameof";
import useSWR from "swr";
import CategoriesServiceContext from "features/catalogue/categories.context";
import useAuth from "../../auth/hooks/useAuth";
import { IOrderCatalogueService } from "../../catalogue/service/categories.serivce";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { invalidArgumentError } from "core/errors/generate-error";

export type LoadOrdersCatalogueOptions = { mode: "lab" } | { mode: "dentist"; labId: ILabId } | null;

function useOrdersCatalogue(options: LoadOrdersCatalogueOptions) {
  const categoriesService = React.useContext(CategoriesServiceContext);
  const { authContext } = useAuth();

  const { data: getOrdersCatalogue, mutate: revalidate } = useSWR(
    () =>
      options === null
        ? null
        : [
            options.mode === "dentist" ? options.labId.value : "",
            options.mode === "lab" ? authContext.labId!.value : "",
            nameof<IOrderCatalogueService>((_) => _.getOrderCatalogue),
          ],
    async () => {
      if (options === null) throw invalidArgumentError("option is null");

      if (options.mode === "dentist") {
        return await categoriesService!.getOrderCatalogue(options.labId);
      }

      return await categoriesService!.getOrderCatalogue({
        type: "dental-lab-id",
        value: authContext.tenantId!.value,
      });
    }
  );

  return {
    getOrdersCatalogue: getOrdersCatalogue,
    isLoading: getOrdersCatalogue === undefined,
    refresh: revalidate,
  };
}

export default useOrdersCatalogue;
