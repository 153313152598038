/* tslint:disable */
// import pl from "./translations/compiled/pl.json";
// import en from "./translations/compiled/en.json";
import { defaultLanguage, Language } from "i18n/i18n.models";

const localizedMessages: Record<Language, object | null> = {
  [defaultLanguage]: null,
  [Language.English]: {  },
};

export default localizedMessages;
