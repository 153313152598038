import * as React from "react";
import { PatientEntryMode } from "features/orders/patient.model";

function usePatientEntryMode(initial: PatientEntryMode) {
  const [entryMode, setEntryMode] = React.useState<PatientEntryMode>(initial);

  const setTo = React.useCallback(
    (mode: PatientEntryMode) => {
      if (entryMode === mode) {
        return;
      }
      setEntryMode(mode);
    },
    [entryMode]
  );

  const toggleMode = React.useCallback(() => {
    if (entryMode === PatientEntryMode.PatientCode) {
      setEntryMode(PatientEntryMode.PatientName);
    } else {
      setEntryMode(PatientEntryMode.PatientCode);
    }
  }, [entryMode, setEntryMode]);

  return {
    entryMode,
    toggleMode,
    setTo,
  };
}

export default usePatientEntryMode;
