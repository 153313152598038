import * as React from "react";
import { Breadcrumb, PageHeader, Spin, Tooltip } from "antd";
import useBillingStatement from "features/invoicing/hooks/useBillingStatement";
import useDentists from "../../../dentist/hooks/useDentists";
import { IBillingStatementId, ILineItem } from "../../models/invoicing.models";
import { FormattedMessage } from "react-intl";
import HeaderContent from "../../../layout/content/HeaderContent";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { DownloadOutlined, HomeOutlined } from "@ant-design/icons";
import Links from "components/links/Links";
import BillingStatementInfo from "features/invoicing/pages/invoice-details/components/billing-statement-info/BillingStatementInfo";
import useConfirmations from "components/confirmations/useConfirmations";
import { useCallback } from "react";
import { Path } from "core/routes/routes";
import { useNavigate } from "react-router-dom";
import { AsyncButton, DeleteButton } from "components/common-components/CommonButtons";

interface IInvoiceDetailsPageProps {}

const BillingStatementDetailsPage: React.FunctionComponent<IInvoiceDetailsPageProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const { billingStatementId: urlParam } = useParams<"billingStatementId">();
  const { deleteBillingStatementConfirmation } = useConfirmations();
  const statementId: IBillingStatementId = React.useMemo(
    () => ({ type: "billing-statement-id", value: urlParam! }),
    [urlParam]
  );

  const { getAllDentistsResponse } = useDentists();
  const { getBillingStatementResponse, isLoading, changeLineItems, deleteBillingStatement, downloadBillingStatement } =
    useBillingStatement(statementId);

  const handleDelete = useCallback(async () => {
    const result = await deleteBillingStatement();
    if (result.isOk()) {
      navigate(Path.BillingStatements);
    }
  }, [deleteBillingStatement, navigate]);

  const isLoadingData =
    getAllDentistsResponse === undefined || (getBillingStatementResponse === undefined && isLoading);

  const onLineItemChange = async (lineItems: ILineItem[]) => await changeLineItems(lineItems);

  return (
    <>
      <FormattedMessage id={"page-titles.create-invoice"} defaultMessage={"Nowa Faktura"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>{" "}
          </Helmet>
        )}
      </FormattedMessage>
      <HeaderContent>
        {getBillingStatementResponse
          ?.map((billingStatement) => (
            <>
              <Breadcrumb className="px-4 py-2">
                <Breadcrumb.Item>
                  <Links.Home>
                    <HomeOutlined />
                  </Links.Home>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Links.BillingStatements />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Links.BillingStatementDetails params={{ billingStatementId: statementId }}>
                    <FormattedMessage
                      id={"invoicing.billing-statement-with-number"}
                      defaultMessage={"Rozliczenie {statementNumber}"}
                      values={{ statementNumber: billingStatement.statementNumber }}
                    />
                  </Links.BillingStatementDetails>
                </Breadcrumb.Item>
              </Breadcrumb>
              <PageHeader
                title={
                  <div className="flex flex-row flex-wrap gap-2">
                    <FormattedMessage
                      id={"invoicing.billing-statement-with-number"}
                      defaultMessage={"Rozliczenie {statementNumber}"}
                      values={{ statementNumber: billingStatement.statementNumber }}
                    />
                  </div>
                }
                extra={
                  <div className="flex flex-row flex-wrap gap-4">
                    <AsyncButton
                      type="primary"
                      key="download-invoice"
                      icon={<DownloadOutlined className={"mr-2"} />}
                      onClick={() =>
                        downloadBillingStatement({
                          statementNumber: billingStatement.statementNumber,
                          billingStatementId: billingStatement.id,
                        })
                      }
                    >
                      <FormattedMessage id={"invoicing.download-invoice"} defaultMessage={"Pobierz rozliczenie"} />
                    </AsyncButton>
                    <Tooltip
                      placement={"topRight"}
                      title={
                        <FormattedMessage
                          id="invoice-details-page.delete-billing-statement"
                          defaultMessage="Usuń rozliczenie numer {number}"
                          values={{ number: billingStatement.statementNumber }}
                        />
                      }
                    >
                      <DeleteButton onClick={() => deleteBillingStatementConfirmation(handleDelete)} />
                    </Tooltip>
                  </div>
                }
              />
            </>
          ))
          .unwrap()}
      </HeaderContent>
      {isLoadingData && <Spin />}
      {!isLoadingData && (
        <>
          {getBillingStatementResponse
            ?.map((statement) => (
              <>
                {getAllDentistsResponse
                  ?.map((dentists) => (
                    <BillingStatementInfo
                      statement={statement}
                      dentists={dentists}
                      onLineItemChange={onLineItemChange}
                    />
                  ))
                  .unwrap()}
              </>
            ))
            .unwrap()}
        </>
      )}
    </>
  );
};

export default BillingStatementDetailsPage;

// const markAsPaid = useCallback(async () => {
//   updatePaymentStatusStart();
//   await changePaymentStatus(true);
//   updatePaymentStatusFinished();
//
//   messageApi.success(
//     <FormattedMessage id={"invoicing.invoice-marked-as-paid"} defaultMessage={"Faktura opłacona"} />
//   );
// }, [changePaymentStatus, messageApi, updatePaymentStatusFinished, updatePaymentStatusStart]);

// const markAsUnpaid = useCallback(async () => {
//   updatePaymentStatusStart();
//   await changePaymentStatus(false);
//   updatePaymentStatusFinished();
//
//   messageApi.success(
//     <FormattedMessage id={"invoicing.invoice-marked-as-unpaid"} defaultMessage={"Faktura nieopłacona"} />
//   );
// }, [changePaymentStatus, messageApi, updatePaymentStatusFinished, updatePaymentStatusStart]);
