import * as React from "react";
import { ISODateTimeString } from "core/time/time.model";
import { ArrowRightOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { FormattedDate, FormattedTime } from "react-intl";

interface IDateTimeChangedProps {
  previous: ISODateTimeString | null;
  next: ISODateTimeString | null;
}

const DateTimeChanged: React.FC<IDateTimeChangedProps> = ({ previous, next }) => (
  <>
    {previous === null ? (
      <CloseSquareOutlined />
    ) : (
      <>
        <FormattedDate value={previous} format={""} />
        <span>
          {" - "}
          <FormattedTime value={previous} />
        </span>
      </>
    )}
    <ArrowRightOutlined className={"mx-2"} />
    {next === null ? (
      <CloseSquareOutlined />
    ) : (
      <>
        <FormattedDate value={next} format={""} />
        <span>
          {" - "}
          <FormattedTime value={next} />
        </span>
      </>
    )}
  </>
);

export default DateTimeChanged;
