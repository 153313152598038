import { IInvoiceIssuerDataSetDTO, IInvoicingSettingsDTO } from "../contracts/settings.dto";
import { IInvoiceIssuerDataSet, IInvoicingSettings } from "../models/settings.models";
import { mapAddressDtoToAddress, mapAddressToAddressDTO } from "contracts/common-dtos/dto.functions";
import { mapTaxIdDTOToTaxId, mapTaxIdToTaxIdDTO } from "core/taxes/taxes.functions";

export const mapDTOtoModel = (dto: IInvoicingSettingsDTO): IInvoicingSettings => {
  const settings: IInvoicingSettings = {
    paymentDueDate: dto.paymentDueDate,
    invoiceNumberingScheme: dto.invoiceNumberingScheme,
    invoiceIssuerDataSets: dto.invoiceIssuerDataSets.map((x) => {
      const issuerDataSet: IInvoiceIssuerDataSet = {
        id: x.id,
        dataSetName: x.dataSetName,
        isDefault: x.isDefault,
        companyName: x.companyName,
        taxId: mapTaxIdDTOToTaxId(x.taxId),
        address: mapAddressDtoToAddress(x.address),
      };

      return issuerDataSet;
    }),
  };

  return settings;
};

export const mapModelToDto = (model: IInvoicingSettings): IInvoicingSettingsDTO => {
  const dto: IInvoicingSettingsDTO = {
    paymentDueDate: model.paymentDueDate,
    invoiceNumberingScheme: model.invoiceNumberingScheme,
    invoiceIssuerDataSets: model.invoiceIssuerDataSets.map((x) => {
      const issuerDataSetDTO: IInvoiceIssuerDataSetDTO = {
        id: x.id,
        dataSetName: x.dataSetName,
        isDefault: x.isDefault,
        companyName: x.companyName,
        taxId: mapTaxIdToTaxIdDTO(x.taxId),
        address: mapAddressToAddressDTO(x.address),
      };

      return issuerDataSetDTO;
    }),
  };

  return dto;
};
