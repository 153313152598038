import { List} from "antd";
import React, { FC, useMemo } from "react";
import StateIcon from "../../../../components/state-icon/StateIcon";
import { DomainClaim } from "../../../auth/contracts/auth-contracts.dto";
import { useDomainClaim } from "../../../auth/useDomainClaim";
import { upperFirst } from "lodash";

interface IClaimsListProps {
  claims: DomainClaim[]
}

interface IClaimState {
  claim: DomainClaim
  included: boolean
}

const ClaimsList: FC<IClaimsListProps> = ({claims}) => {
  const allClaims = useMemo<IClaimState[]>(() => {
    return Object.values(DomainClaim).map(c => ({
      claim: c,
      included: claims?.includes(c),
    }))
  }, [claims]);

  const claimsTranslations = useDomainClaim();

  return (
    <List
      size={"default"}
      dataSource={allClaims}
      itemLayout={"horizontal"}
      renderItem={(record) => {
        return (
          <List.Item
            actions={[<StateIcon checked={record.included} />]}
          >
            <span className="font-bold">{upperFirst(claimsTranslations[record.claim])}</span>
          </List.Item>
        );
      }}
    />
  );
}

export default ClaimsList;