import ProList from "@ant-design/pro-list";
import * as React from "react";
import { Button, Dropdown, Menu, Space, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { DownOutlined } from "@ant-design/icons";
import { useRef } from "react";
import useSize from "../../../../hooks/useSize";
import classNames from "classnames";
import { ListItemLayout } from "antd/lib/list";
import { IPricingList } from "features/pricing-lists/pricing-list.models";
import sortBy from "lodash/sortBy";
import Links from "components/links/Links";

interface IPricingListsProps {
  pricingLists: IPricingList[];
  loading?: boolean;
  onEdit?: (pricingList: IPricingList) => void;
  onDelete?: (pricingList: IPricingList) => void;
}

enum Size {
  Small = "sm",
  Large = "lg",
}

const PricingLists: React.FC<IPricingListsProps> = ({ pricingLists, loading = false, onEdit, onDelete }) => {
  let ref = useRef(null);

  const [size] = useSize(ref, {
    [Size.Small]: 0,
    [Size.Large]: 700,
  });

  const layout: ListItemLayout = size === Size.Large ? "horizontal" : "vertical";

  const sortedPricingLists = sortBy(pricingLists, [
    (p: IPricingList) => !p.isDefault,
    (p: IPricingList) => p.name.toLowerCase(),
  ]);

  return (
    <div className={"pricing-lists"} ref={ref}>
      <ProList<IPricingList>
        split
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
        size={"large"}
        showActions="always"
        itemLayout={layout}
        headerTitle={<FormattedMessage id="pricing-lists.header" defaultMessage="Lista cenników" />}
        rowKey={(record) => record.id.value}
        loading={loading}
        rowClassName={() =>
          classNames("pricing-lists__pricing-lists-row", {
            ["pricing-lists__row--vertical"]: layout === "vertical",
            ["pricing-lists__row--horizontal"]: layout === "horizontal",
          })
        }
        dataSource={sortedPricingLists}
        metas={{
          title: {
            render: (dom, entity) => (
              <Links.LabPricingList pricingListId={entity.id}>
                <span>{entity.name}</span>
              </Links.LabPricingList>
            ),
          },
          subTitle: {
            render: (dom, entity) =>
              entity.isDefault && (
                <Space size={0}>
                  <Tag color="blue">{"Cennik Główny"}</Tag>
                </Space>
              ),
          },
          actions: {
            render: (text, entity) => [
              <Dropdown
                key={"actions-dropdown"}
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item key={"edit"} onClick={() => onEdit?.(entity)}>
                      <Button type={"link"}>
                        <FormattedMessage id="pricing-list.edit" defaultMessage="Edycja" />
                      </Button>
                    </Menu.Item>
                    <Menu.Item key={"delete"} onClick={() => onDelete?.(entity)}>
                      <Button type={"link"} danger={true}>
                        <FormattedMessage id="pricing-list.delete" defaultMessage="Usuń" />
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type={"link"} onClick={(e) => e.preventDefault()}>
                  <FormattedMessage id="pricing-list.actions" defaultMessage="Akcje" />
                  <DownOutlined className={"ml-2"} />
                </Button>
              </Dropdown>,
            ],
          },
        }}
      />
    </div>
  );
};

export default PricingLists;
