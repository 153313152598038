import * as React from "react";
import { ILabId } from "../../dental-lab/dental-lab.model";
import { IResult } from "core/lib/types/result";
import {
  ICreateEmployeeRequest,
  IDeleteEmployeeRequest,
  IEditEmployeeRequest,
  IEmployee,
  IEmployeeId,
  IEmployeeWithOrders,
} from "../employee.model";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { IEmployeeDTO, IEmployeeWithOrdersDTO } from "../contracts/employee.dto.model";
import { IOrderId } from "../../orders/order.model";
import IApiResponseEnvelope from "../../../contracts/envelope/api-response-envelope";
import { IJsonApiService } from "core/http/json-api.service";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";

export interface IEmployeeService {
  getEmployees: () => Promise<IResult<IEmployee[]>>;
  getEmployeesWithOrders: () => Promise<IResult<IEmployeeWithOrders[]>>;
  assignEmployees: (orderId: IOrderId, ids: IEmployeeId[]) => Promise<IResult<void>>;
  assignSelf: (tenantId: ILabId, orderId: IOrderId) => Promise<IResult<void>>;
  createEmployee: (request: ICreateEmployeeRequest) => Promise<IResult<IEmployee>>;
  editEmployee: (request: IEditEmployeeRequest) => Promise<IResult<IEmployeeId>>;
  deleteEmployee: (request: IDeleteEmployeeRequest) => Promise<IResult<IEmployeeId>>;
}

class EmployeeService implements IEmployeeService {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  getEmployeesWithOrders = async () => {
    const response = await this.jsonApi.get<IApiResponseEnvelope<IEmployeeWithOrdersDTO[]>>(
      ApiEndpointPath.GetEmployeesWithOrders
    );

    return response.map((body) => {
      const employeeWithOrders: IEmployeeWithOrders[] = body.data.result.map((order) => ({
        ...order,
        employeeId: { value: order.employeeId, type: "employee-id" },
        activeOrders: order.activeOrders.map((o) => ({
          ...o,
          orderId: { type: "order-id", value: o.id },
        })),
      }));

      return employeeWithOrders;
    });
  };

  getEmployees = async () => {
    const response = await this.jsonApi.get<IApiResponseEnvelope<IEmployeeDTO[]>>(ApiEndpointPath.GetEmployees);

    return response.map((body) => {
      const employee: IEmployee[] = body.data.result.map((order) => ({
        ...order,
        employeeId: { value: order.employeeId, type: "employee-id" },
      }));

      return employee;
    });
  };

  assignEmployees = async (orderId: IOrderId, employeeIds: IEmployeeId[]) => {
    const response = await this.jsonApi.post(ApiEndpointPath.EmployeesAssignOrder, {
      employeeIds: employeeIds.map(({ value }) => value),
      orderId: orderId.value,
    });

    return response.map((_) => undefined);
  };

  assignSelf = async (tenantId: ILabId, orderId: IOrderId) => {
    const response = await this.jsonApi.post(ApiEndpointPath.EmployeesAssignOrderSelf, { orderId: orderId.value });

    return response.map((_) => undefined);
  };

  createEmployee = async (request: ICreateEmployeeRequest): Promise<IResult<IEmployee>> => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<IEmployeeDTO>,
      Omit<IEmployeeDTO, "employeeId" | "isDeleted">
    >(ApiEndpointPath.CreateEmployee, { firstName: request.firstName, lastName: request.lastName });

    return response.map((body) => {
      const dto = body.data.result;
      const employee: IEmployee = {
        ...dto,
        employeeId: { type: "employee-id", value: dto.employeeId },
      };

      return employee;
    });
  };

  editEmployee = async (request: IEditEmployeeRequest): Promise<IResult<IEmployeeId>> => {
    const response = await this.jsonApi.post<IApiResponseEnvelope<void>, Omit<IEmployeeDTO, "isDeleted">>(
      ApiEndpointPath.EditEmployee,
      { employeeId: request.employeeId.value, firstName: request.firstName, lastName: request.lastName }
    );

    return response.map(() => request.employeeId);
  };

  deleteEmployee = async (request: IDeleteEmployeeRequest): Promise<IResult<IEmployeeId>> => {
    const response = await this.jsonApi.post<IApiResponseEnvelope<void>, void>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.DeleteEmployee,
        params: { employeeId: request.employeeId.value },
      })
    );

    return response.map(() => request.employeeId);
  };
}

export const EmployeeServiceContext = React.createContext<IEmployeeService>(null as never);

export default EmployeeService;
