import { assertNoDuplicateErrorCodesExists } from "./error-code.sanity-check";

export enum ErrorCode {
  //Auth
  CouldNotConfirmEmail = "Auth_CouldNotConfirmEmail",
  RegistrationFailed = "Auth_RegistrationFailed",
  CouldNotAttachUserToRole = "Auth_CouldNotAttachUserToRole",
  PasswordToShort = "Auth_PasswordToShort",
  InvalidLoginCredentials = "Auth_InvalidLoginCredentials",
  //General
  Unauthorized = "General_Unauthorized",
  InternalError = "General_InternalError",
  EntityUpdateFailed = "General_EntityUpdateFailed",
  RecordNotFound = "General_RecordNotFound",
  ServerError = "General_ServerError",
  //Orders
  OrderAlreadyExists = "Orders_OrderAlreadyExists",
  OrderNumberAlreadyTaken = "Orders_OrderNumberAlreadyTaken",
  OrderItemNotFound = "Orders_OrderItemNotFound",
  OrderItemAlreadyExists = "Orders_OrderItemAlreadyExists",
  OrderNotFound = "Orders_OrderNotFound",
  CanNotRetrieveOrderAsThisOrderIsDeleted = "Orders_CanNotRetrieveOrderAsThisOrderIsDeleted",
  CanNotChangeOrderFinishDateForOrderWithFollowingStatus = "Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus",
  CanNotAccessOrder = "Orders_CanNotAccessOrder",
  //OrderFiles
  DeleteFailed = "OrderFiles_DeleteFailed",
  FileTooBig = "OrderFiles_FileTooBig",
  OrderFileDoesNotExist = "OrderFiles_OrderFileDoesNotExist",
  GetFileFailed = "OrderFiles_GetFileFailed",
  UploadFailed = "OrderFiles_UploadFailed",
  CanNotAddOrderFileBecauseItAlreadyExists = "OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists",
  UploadFiled = "UploadFiled",
  //OrderComments
  OrderCreateFailed = "OrderComments_OrderCreateFailed",
  CommentNotFound = "OrderComments_CommentNotFound",
  OnlyOwnerCanManipulateComment = "OrderComments_OnlyOwnerCanManipulateComment",
  //OrderTree
  IsActiveValueIsMissing = "OrderTree_IsActiveValueIsMissing",
  OrderTypeIdMissing = "OrderTree_OrderTypeIdMissing",
  OrderTypeBelongsToDifferentTree = "OrderTree_OrderTypeBelongsToDifferentTree",
  OrderCategoryBelongsToDifferentTree = "OrderTree_OrderCategoryBelongsToDifferentTree",
  ParentIdMissing = "OrderTree_ParentIdMissing",
  //Pricing
  OrderSpecificationTypeIsInvalid = "Pricing_OrderSpecificationTypeIsInvalid",
  CatalogDoesNotHavePricingForOrderType = "Pricing_CatalogDoesNotHavePricingForOrderType",
  NoDefaultPricingCatalogueForDentalLabFound = "Pricing_NoDefaultPricingCatalogueForDentalLabFound",
  CouldNotAddOrUpdatePriceForThisCatalogueId = "Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId",
  //DentalLab
  DentalLabNotFound = "DentalLab_DentalLabNotFound",
  DentalLabCreationFailure = "DentalLab_DentalLabCreationFailure",
  DentalLabAlreadyExists = "DentalLab_DentalLabAlreadyExists",
  //Payment
  GetSubscriptionProductsFailed = "Payment_GetSubscriptionProductsFailed",
  GetProductFailed = "Payment_GetProductFailed",
  CreateCustomerFailed = "Payment_CreateCustomerFailed",
  CreateCheckoutSessionFailed = "Payment_CreateCheckoutSessionFailed",
  CreateBillingPortalSessionFailed = "Payment_CreateBillingPortalSessionFailed",
  //Idempotency
  IdempotencyOperationDuplicated = "Idempotency_IdempotencyOperationDuplicated",
}

export type ErrorCodeName = keyof typeof ErrorCode;

if (process.env.NODE_ENV === "development") {
  assertNoDuplicateErrorCodesExists();
}
