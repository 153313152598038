import * as React from "react";
import { Button, Form, Modal } from "antd";
import Input from "components/forms/input/Input";
import { FormattedMessage } from "react-intl";
import { DeepPartial, Optional } from "utility-types";

export interface IOrderCategoryModalFormProps {
  visible: boolean;
  values?: DeepPartial<Optional<IFormValues>>;
  onCancel: () => void;
  onSubmit: (values: ISubmitValues) => Promise<void>;
}

interface ISubmitValues {
  name: string;
}

interface IFormValues {
  name: string;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = {
  name: "name",
};

const OrderCategoryModalForm: React.FunctionComponent<IOrderCategoryModalFormProps> = ({
  visible,
  values = {},
  onCancel,
  onSubmit,
}) => {
  const formId = "order-category-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { name } = store;
      await onSubmit({ name });
      form.resetFields();
      onCancel();
    },
    [form, onCancel, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={<FormattedMessage id={"order-category-modal-form.create-order-type"} defaultMessage={"Dodaj nową kategorię"} />}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"order-category-modal-form.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          <FormattedMessage id={"order-category-modal-form.create-button-text"} defaultMessage={"Dodaj"} />
        </Button>,
      ]}
    >
      <Form id={formId} form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ ...values }}>
        <Form.Item
          label={<FormattedMessage id="order-category-modal-form.category-name" defaultMessage="Nazwa" />}
          name={propertyNames.name}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="order-category-modal-form.name-is-required"
                  defaultMessage="Nazwa jest wymagana"
                />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderCategoryModalForm;
