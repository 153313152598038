import React from "react";
import { NotificationInstance } from "antd/lib/notification";

export interface INotificationMessagesContext {
  notificationApi: NotificationInstance;
}

const NotificationMessageContext = React.createContext<INotificationMessagesContext>({ notificationApi: null } as never);

export default NotificationMessageContext;
