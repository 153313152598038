import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";
import type { UserResponse } from "stream-chat";
import _debounce from "lodash/debounce";

import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from "features/chat/chat.model";
import ChatApiContext from "features/chat/services/ChatApiContext";
import { NewUserId } from "features/auth/auth.model";
import { XButtonBackground } from "features/chat/assets";

import './CreateChannel.scss';

const UserResult = ({ user }: { user: UserResponse<UserType> }) => (
  <li className="messaging-create-channel__user-result">
    <Avatar image={user.image} size={40} />
    {user.online && <div className="messaging-create-channel__user-result-online" />}
    <div className="messaging-create-channel__user-result__details">
      <span>{user.name}</span>
    </div>
  </li>
);

type Props = { onClose: () => void };

const CreateChannel: React.FC<Props> = (props) => {
  const { onClose } = props;
  const chatApi = useContext(ChatApiContext);

  const { client, setActiveChannel } = useChatContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const [focusedUser, setFocusedUser] = useState<number>();
  const [inputText, setInputText] = useState("");
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<UserResponse<UserType>[]>([]);
  const [users, setUsers] = useState<UserResponse<UserType>[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const clearState = () => {
    setInputText("");
    setResultsOpen(false);
    setSearchEmpty(false);
  };

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState();
    };

    document.addEventListener("click", clickListener);

    return () => document.removeEventListener("click", clickListener);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const findUsers = async () => {
    if (searching) return;
    setSearching(true);

    try {
      const response = await chatApi.searchContacts(inputText);

      if (response.isErr()) {
        setSearchEmpty(true);
        return;
      }

      const result = response.unwrap();
      const minusAlreadyAdded = result.filter((u1) => !selectedUsers.some((u2) => u2.id == u1.id));

      if (!minusAlreadyAdded.length) {
        setSearchEmpty(true);
      } else {
        setSearchEmpty(false);
        setUsers(minusAlreadyAdded);
      }

      setResultsOpen(true);
    } catch (error) {
      console.log({ error });
    }

    setSearching(false);
  };

  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  });

  useEffect(() => {
    if (inputText) {
      findUsersDebounce();
    }
  }, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

  const createChannel = async () => {
    const selectedUsersIds = selectedUsers.map((u) => u.id);

    if (!selectedUsersIds.length || !client.userID) return;

    const createConversation = await chatApi.createConversation(selectedUsersIds.map((uid) => NewUserId(uid)));

    if (createConversation.isErr()) {
      setSelectedUsers([]);
      setUsers([]);
      onClose();
      return;
    } else if (createConversation.isOk()) {
      const { channelId } = createConversation.unwrap();
      const conversation = await client.channel("messaging", channelId);

      setActiveChannel?.(conversation);
      setSelectedUsers([]);
      setUsers([]);
      onClose();
    }
  };

  const addUser = (addedUser: UserResponse<UserType>) => {
    const isAlreadyAdded = selectedUsers.find((user) => user.id === addedUser.id);
    if (isAlreadyAdded) return;

    setSelectedUsers([...selectedUsers, addedUser]);
    setResultsOpen(false);
    setInputText("");

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeUser = (user: UserResponse<UserType>) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // check for up(ArrowUp) or down(ArrowDown) key
      if (event.key === "ArrowUp") {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1;
        });
      }
      if (event.key === "ArrowDown") {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1;
        });
      }
      if (event.key === "Enter") {
        event.preventDefault();
        if (focusedUser !== undefined) {
          addUser(users[focusedUser]);
          return setFocusedUser(undefined);
        }
      }
    },
    [users, focusedUser] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="messaging-create-channel">
      <header>
        <div className="messaging-create-channel__left">
          <div className="messaging-create-channel__left-text">To: </div>
          <div className="users-input-container">
            {!!selectedUsers?.length && (
              <div className="messaging-create-channel__users">
                {selectedUsers.map((user) => (
                  <div className="messaging-create-channel__user" onClick={() => removeUser(user)} key={user.id}>
                    <div className="messaging-create-channel__user-text">{user.name}</div>
                    {/*<XButton />*/}
                  </div>
                ))}
              </div>
            )}
            <form>
              <input
                autoFocus
                ref={inputRef}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={!selectedUsers.length ? "Start typing for suggestions" : ""}
                type="text"
                className="messaging-create-channel__input"
              />
            </form>
          </div>
          <div className="close-mobile-create" onClick={() => onClose()}>
            <XButtonBackground />
          </div>
        </div>
        <button className="create-channel-button" onClick={createChannel}>
          Start chat
        </button>
      </header>
      {inputText && (
        <main>
          <ul className="messaging-create-channel__user-results">
            {!!users?.length && !searchEmpty && (
              <div>
                {users.map((user, i) => (
                  <div
                    className={`messaging-create-channel__user-result ${focusedUser === i && "focused"}`}
                    onClick={() => addUser(user)}
                    key={user.id}
                  >
                    <UserResult user={user} />
                  </div>
                ))}
              </div>
            )}
            {searchEmpty && (
              <div
                onClick={() => {
                  inputRef.current?.focus();
                  clearState();
                }}
                className="messaging-create-channel__user-result empty"
              >
                No people found...
              </div>
            )}
          </ul>
        </main>
      )}
    </div>
  );
};

export default React.memo(CreateChannel);
