import { HonorificCode, Honorific } from "core/person-name/honorific";

const honorificCodeToHonorificMap: Record<HonorificCode, Honorific> = {
  [HonorificCode.None]: Honorific.None,
  [HonorificCode.Mr]: Honorific.Mr,
  [HonorificCode.Ms]: Honorific.Ms,
  [HonorificCode.Mrs]: Honorific.Mrs,
  [HonorificCode.Dr]: Honorific.Dr,
};

const honorificToHonorificCodeMap: Record<Honorific, HonorificCode> = {
  [Honorific.None]: HonorificCode.None,
  [Honorific.Mr]: HonorificCode.Mr,
  [Honorific.Ms]: HonorificCode.Ms,
  [Honorific.Mrs]: HonorificCode.Mrs,
  [Honorific.Dr]: HonorificCode.Dr,
};

export const honorificCodeToHonorific = (honorificCode: HonorificCode): Honorific => {
  return honorificCodeToHonorificMap[honorificCode];
};

export const honorificToHonorificCode = (honorific: Honorific): HonorificCode => {
  return honorificToHonorificCodeMap[honorific];
};
