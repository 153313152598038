import * as React from "react";
import { useState } from "react";
import PointSelection from "../../points-picker/selections/points-selection/PointSelection";
import { CrownsSpecification, SpecificationType } from "../../../specification/order-specification";
import { IPickedPoints } from "../../points-picker/points-picker.model";
import { allPossibleCrownAnchorTypes } from "../../../order-type/anchor-type.const";
import { OpenModalButton } from "./OpenModalButton";
import { SpecificationModal } from "./SpecificationModal";
import { SelectAtLeastOnePoint } from "./alerts/SelectAtLeastOnePoint";
import { FollowingPointsDontHaveAnchorType } from "./alerts/FollowingPointsDontHaveAnchorType";

interface ICrownPointsControlsProps {
  specification: CrownsSpecification;
  onChange?: (specification: CrownsSpecification) => void;
}

enum Step {
  PickCrowns = "pick-crowns",
  PickAnchorTypes = "pick-anchor-types",
}

type CrownPointsControlsState = {
  step: Step;
  submitCount: Record<Step, number>;
  pickedCrowns: IPickedPoints;
  isModalVisible: boolean;
};

const CrownPointsControls: React.FunctionComponent<ICrownPointsControlsProps> = ({ specification, onChange }) => {
  const [state, setState] = useState<CrownPointsControlsState>({
    step: Step.PickCrowns,
    submitCount: { [Step.PickCrowns]: 0, [Step.PickAnchorTypes]: 0 },
    pickedCrowns: {
      singles: specification.points.map((x) => ({ location: x.location, anchor: x.anchor })),
      linked: [],
    },
    isModalVisible: false,
  });

  const handleShowModal = React.useCallback(() => {
    setState({ ...state, isModalVisible: true });
  }, [state]);

  const hideModal = React.useCallback(() => {
    setState({ ...state, isModalVisible: false });
  }, [state]);

  const handlePointSelectionChange = React.useCallback(
    (pickedCrowns: IPickedPoints) => {
      setState({ ...state, pickedCrowns: pickedCrowns });
    },
    [state]
  );

  const handleGoBack = React.useCallback(() => {
    setState({ ...state, step: Step.PickCrowns });
  }, [state]);

  const handleNextClick = React.useCallback(() => {
    if (state.step === Step.PickCrowns) {
      const selectedCount = state.pickedCrowns.singles.length;

      if (selectedCount === 0) {
        setState({ ...state, submitCount: { ...state.submitCount, [state.step]: state.submitCount[state.step] + 1 } });
        return;
      }

      setState({
        ...state,
        step: Step.PickAnchorTypes,
        submitCount: { ...state.submitCount, [state.step]: state.submitCount[state.step] + 1 },
      });
    } else if (state.step === Step.PickAnchorTypes) {
      const selectedCrownsCount = state.pickedCrowns.singles.length;
      const anchoredCrownsCount = state.pickedCrowns.singles.reduce(
        (sum, curr) => (curr.anchor === null ? sum : sum + 1),
        0
      );

      if (anchoredCrownsCount < selectedCrownsCount) {
        setState({ ...state, submitCount: { ...state.submitCount, [state.step]: state.submitCount[state.step] + 1 } });
        return;
      }

      hideModal();

      onChange?.({
        specificationType: SpecificationType.Crowns,
        points: state.pickedCrowns.singles.map(({ location, anchor }) => ({
          location: location,
          anchor: anchor!,
        })),
      });
    }
  }, [hideModal, onChange, state]);

  const { step, submitCount, pickedCrowns } = state;
  const anchoredCrownsCount = pickedCrowns.singles.reduce((sum, curr) => (curr.anchor === null ? sum : sum + 1), 0);
  const selectedCrownsCount = pickedCrowns.singles.length;

  return (
    <>
      <OpenModalButton specification={specification} onClick={handleShowModal} />
      <SpecificationModal
        specification={specification}
        isVisible={state.isModalVisible}
        isBackDisabled={state.step === Step.PickCrowns}
        onCancel={hideModal}
        onBack={handleGoBack}
        onNext={handleNextClick}
      >
        {state.step === Step.PickCrowns && (
          <>
            {pickedCrowns.singles.length === 0 && submitCount[Step.PickCrowns] > 0 && (
              <SelectAtLeastOnePoint specificationType={SpecificationType.Crowns} />
            )}
            <PointSelection
              dotOptions={{ visible: false, editable: false }}
              value={pickedCrowns}
              onChange={handlePointSelectionChange}
            />
          </>
        )}
        {step === Step.PickAnchorTypes && (
          <>
            {anchoredCrownsCount < selectedCrownsCount && submitCount[Step.PickAnchorTypes] > 0 && (
              <FollowingPointsDontHaveAnchorType
                specificationType={SpecificationType.Crowns}
                points={pickedCrowns.singles.filter((x) => x.anchor === null).map((x) => x.location)}
              />
            )}
            <PointSelection
              anchorOptions={{ visible: true, editable: true, allowedAnchors: allPossibleCrownAnchorTypes }}
              dotOptions={{ visible: false, editable: false }}
              value={state.pickedCrowns}
              onChange={handlePointSelectionChange}
            />
          </>
        )}
      </SpecificationModal>
    </>
  );
};

export default CrownPointsControls;
