import * as React from "react";
import * as yup from "yup";
import { Button, Form, Modal, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPricingListId, NewPricingListId } from "features/pricing-lists/pricing-list.models";
import { IDentist } from "features/dentist/dentist.model";
import PricingListSelect from "components/forms/pricing-list-select/PricingListSelect";
import useAllPricingLists from "features/pricing-lists/hooks/lab/useAllPricingLists";
import { useCallback } from "react";

export interface IAssignPricingListModalFormProps {
  visible: boolean;
  dentist: IDentist;
  onCancel: () => void;
  onSubmit: (values: ISubmitValues) => Promise<void>;
}

interface ISubmitValues {
  pricingListId: IPricingListId;
}

interface IFormValues {
  pricingListId: string;
}

interface ICreateDentistFormProps extends IAssignPricingListModalFormProps {}

const AssignPricingListModalForm: React.FunctionComponent<ICreateDentistFormProps> = ({
  visible,
  dentist,
  onCancel,
  onSubmit,
}) => {
  const intl = useIntl();
  const { getAllPricingLists } = useAllPricingLists();

  const schema = React.useMemo(
    () =>
      yup.object({
        pricingListId: yup.string().required(
          intl.formatMessage({
            id: "assign-pricing-list.pricing-list-is-required",
            defaultMessage: "Przypisz dentystę do cennika",
          })
        ),
      }),
    [intl]
  );

  const handleSubmit = useCallback(
    async (values: IFormValues) => await onSubmit({ pricingListId: NewPricingListId(values.pricingListId) }),
    [onSubmit]
  );

  const {
    handleSubmit: submitForm,
    control,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { pricingListId: dentist.pricingListId.value },
  });

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={<FormattedMessage id={"assign-pricing-list.assign-pricing-list"} defaultMessage={"Przypisz cennik"} />}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"assign-pricing-list.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button key="submit" type={"primary"} onClick={submitForm(handleSubmit)}>
          <FormattedMessage id={"assign-pricing-list.edit-dentist-button-text"} defaultMessage={"Zapisz"} />
        </Button>,
      ]}
    >
      <Form id={"assign-pricing-list"} layout="vertical">
        <Form.Item
          required
          label={<FormattedMessage id="common.pricing-list" defaultMessage="Cennik" />}
          validateStatus={errors.pricingListId !== undefined ? "error" : undefined}
          help={errors.pricingListId?.message}
        >
          {getAllPricingLists === undefined && <Spin />}
          {getAllPricingLists !== undefined && (
            <>
              {getAllPricingLists
                .map((pricingLists) => (
                  <Controller
                    name="pricingListId"
                    control={control}
                    render={({ field }) => (
                      <PricingListSelect pricingLists={pricingLists} className={"max-w-lg"} {...field} />
                    )}
                  />
                ))
                .unwrap()}
            </>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignPricingListModalForm;

export type { ISubmitValues as IAssignPricingListSubmitValues };
