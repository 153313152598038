import * as React from "react";
import { useEffect } from "react";
import { SelectProps } from "antd/lib/select";
import { CreateGuid } from "core/guid/guid";
import { isNotEmptyString } from "core/utils/utils";
import { Tag } from "antd";
import { Select } from "antd";
import { IPricingList } from "features/pricing-lists/pricing-list.models";
import { FormattedMessage } from "react-intl";

export interface IPricingListSelectProps extends SelectProps<string> {
  pricingLists: Pick<IPricingList, "id" | "name" | "isDefault">[];
}

const fixAutocomplete = () => {
  document?.querySelectorAll(".ant-select-selector input").forEach((e) => {
    e.setAttribute("autocomplete", CreateGuid());
  });
};

const PricingListSelect = React.forwardRef<any, IPricingListSelectProps>((props, ref: React.Ref<any>) => {
  const { children: _, pricingLists, ...rest } = props;

  const searchPredicate = React.useCallback(
    (input: string, option: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
      if (!isNotEmptyString(option.key)) {
        return false;
      }

      const key = option.key;
      const pricingList = pricingLists.find((d) => d.id.value === key);

      if (pricingList === undefined) {
        return false;
      }

      const searchPhrase = input.toLowerCase();
      return pricingList.name.toLowerCase().indexOf(searchPhrase) !== -1;
    },
    [pricingLists]
  );

  useEffect(() => {
    fixAutocomplete();
  }, []);

  return (
    <Select ref={ref} showSearch filterOption={searchPredicate} {...(rest as any)}>
      {pricingLists.map((list) => (
        <Select.Option key={list.id.value} value={list.id.value}>
          {list.name}
          {list.isDefault && (
            <Tag className={"mx-4"} color="blue">
              <FormattedMessage id={"common"} defaultMessage={"Cennik główny"} />
            </Tag>
          )}
        </Select.Option>
      ))}
    </Select>
  );
});

export default PricingListSelect;
