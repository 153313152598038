import { useContext } from "react";
import useSWR from "swr";
import { ILabId } from "./../../dental-lab/dental-lab.model";
import { IOrderId } from "features/orders/order.model";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import OrderApiContext from "features/orders/order.context";
import useBooleanFlag from "hooks/useBooleanFlag";
import {
  Bite,
  Color,
  IDentistOrderSurveyFeedback,
  PreparationMargin,
  TangentialPoint,
  Translucency,
  ILabOrderSurveyFeedback,
} from "features/orders/feedback/feedback.model";

const labPositiveFeedback: ILabOrderSurveyFeedback = {
  bite: Bite.Good,
  color: Color.Good,
  preparationMargin: PreparationMargin.Good,
  tangentialPoint: TangentialPoint.Good,
  translucency: Translucency.Good,
};

const dentistPositiveFeedback: IDentistOrderSurveyFeedback = {
  ...labPositiveFeedback,
  dentistRating: 5,
};

function useOrderFeedback(orderId: IOrderId, labId: ILabId) {
  const orderApi = useContext(OrderApiContext);
  const { flag: inProgress, set: setInProgress } = useBooleanFlag(false);

  const {
    data: getOrderFeedback,
    isValidating,
    mutate: reload,
  } = useSWR(
    parametrizeEndpointPath({
      path: ApiEndpointPath.GetOrderFeedback,
      params: { labId: labId.value, orderId: orderId.value },
    }),
    () => orderApi.getOrderFeedback(orderId, labId)
  );

  const giveFeedback = async (feedback: ILabOrderSurveyFeedback | IDentistOrderSurveyFeedback) => {
    setInProgress(true);
    await orderApi.giveOrderFeedback(orderId, labId, feedback);
    await reload();
    setInProgress(false);
  };

  const submitPositiveFeedbackAsLab = async () => {
    setInProgress(true);
    await orderApi.giveOrderFeedback(orderId, labId, labPositiveFeedback);
    await reload();
    setInProgress(false);
  };

  const submitPositiveFeedbackAsDentist = async () => {
    setInProgress(true);
    await orderApi.giveOrderFeedback(orderId, labId, dentistPositiveFeedback);
    await reload();
    setInProgress(false);
  };

  return {
    getOrderFeedback,
    isLoading: isValidating,
    refresh: reload,
    giveFeedback,
    submitPositiveFeedbackAsLab,
    submitPositiveFeedbackAsDentist,
    giveFeedbackInProgress: inProgress,
  };
}

export default useOrderFeedback;
