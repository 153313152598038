import { UnixTimestamp } from "core/time/time.model";

export interface IClock {
  now(): UnixTimestamp;
}

class Clock implements IClock {
  now = (): UnixTimestamp => {
    return Date.now();
  };
}

export default Clock;
