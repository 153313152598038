import React from "react";
import { Form, Input} from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";

export const PatientFirstNameControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={<FormattedMessage id="common.first-name" defaultMessage="Imię" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="patientFirstName"
      control={props.control}
      render={({ field: { value, ...rest } }) => (
        <Input value={value === null ? undefined : value} {...rest} autoComplete={"nope"} />
      )}
    />
  </Form.Item>
);
