import { PointId } from "core/point/point.enums";
import { Color, NoColor } from "features/orders/color/color.model";

// todo: change to the same values as first digit of Point
export enum Quadrant {
  UpperRight = 1,
  UpperLeft = 2,
  LowerLeft = 3,
  LowerRight = 4,
}

export enum ToothGroup {
  Incisors = "incisors",
  Premolar = "premolar",
  Molar = "molar",
}

export enum Arch {
  UpperArch = "upper-arch", // maxillar ( Maxillary teeth )
  LowerArch = "lower-arch", // Mandibular teeth
}

export interface ITooth {
  id: PointId;
  group: ToothGroup;
  arch: Arch;
  quadrant: Quadrant;
  universalIndex: number;
  readingSortValue: number;
}

export enum ToothType {
  ToothWithoutColor = "ToothWithoutColor",
  ToothWithColor = "ToothWithColor",
}

export type ToothWithoutColor = {
  type: ToothType.ToothWithoutColor;
  point: PointId;
};

export type ToothWithColor = {
  type: ToothType.ToothWithColor;
  point: PointId;
  color: Color[] | NoColor;
};

export type Tooth = ToothWithoutColor | ToothWithColor;
