import * as React from "react";
import { Reducer, useReducer } from "react";
import { Breadcrumb, PageHeader, Result, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import Button from "components/button/Button";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import Links from "components/links/Links";
import useDentists from "../../hooks/useDentists";
import InvitedDentistsCounts from "../../components/invited-dentists-counts/InvitedDentistsCounts";
import DentistList from "../../components/dentists-list/DentistList";
import DentistModalForm, { IDentistModalFormSubmitValues } from "../../components/dentist-modal-form/DentistModalForm";
import { Honorific } from "core/person-name/honorific";
import { IDentist, IDentistId } from "../../dentist.model";
import DentistQueryFilter, { DentistQuerySearchParams } from "../../components/dentist-query-filter/DentistQueryFilter";
import useModal from "../../../../components/modals/useModal";
import AssignPricingListModalForm, {
  IAssignPricingListSubmitValues,
} from "features/dentist/components/assign-pricing-list/AssignPricingListModalForm";
import useConfirmations from "components/confirmations/useConfirmations";
import { Helmet } from "react-helmet";

type State =
  | {
      isModalVisible: true;
      modalType: "create";
      dentist: null;
      search: DentistQuerySearchParams;
    }
  | {
      isModalVisible: true;
      modalType: "edit";
      dentist: IDentist;
      search: DentistQuerySearchParams;
    }
  | {
      isModalVisible: true;
      modalType: "assign";
      dentist: IDentist;
      search: DentistQuerySearchParams;
    }
  | {
      isModalVisible: false;
      modalType: null;
      dentist: null;
      search: DentistQuerySearchParams;
    };

type Action =
  | { type: "showCreateModal" }
  | { type: "showEditModal"; dentist: IDentist }
  | { type: "assignToPriceList"; dentist: IDentist }
  | { type: "hideModal" }
  | { type: "updateSearch"; search: DentistQuerySearchParams };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showCreateModal":
      return { ...state, isModalVisible: true, modalType: "create", dentist: null };
    case "showEditModal":
      return { ...state, isModalVisible: true, modalType: "edit", dentist: action.dentist };
    case "assignToPriceList":
      return { ...state, isModalVisible: true, modalType: "assign", dentist: action.dentist };
    case "hideModal":
      return { ...state, isModalVisible: false, modalType: null, dentist: null };
    case "updateSearch":
      return { isModalVisible: false, modalType: null, dentist: null, search: action.search };
  }
};

interface IListDentistPageProps {}

const matchesSearchCriteria = (dentist: IDentist, search: DentistQuerySearchParams) => {
  let dentistPhrase;
  let companyNamePhrase;

  if (search.dentist === undefined || search.dentist === null || search.dentist.trim() === "") {
    dentistPhrase = null;
  } else {
    dentistPhrase = search.dentist.trim().toLowerCase();
  }

  if (search.companyName === undefined || search.companyName === null || search.companyName.trim() === "") {
    companyNamePhrase = null;
  } else {
    companyNamePhrase = search.companyName.trim().toLowerCase();
  }

  if (dentistPhrase === null && companyNamePhrase === null) {
    return true;
  }

  if (dentistPhrase !== null && dentist.name.firstName.toLowerCase().indexOf(dentistPhrase) !== -1) {
    return true;
  }

  if (dentistPhrase !== null && dentist.name.lastName.toLowerCase().indexOf(dentistPhrase) !== -1) {
    return true;
  }

  if (
    companyNamePhrase !== null &&
    dentist.clinicName !== null &&
    dentist.clinicName.toLowerCase().indexOf(companyNamePhrase) !== -1
  ) {
    return true;
  }

  return false;
};

const DentistListPage: React.FunctionComponent<IListDentistPageProps> = () => {
  const modal = useModal();
  const { deleteDentistConfirmation } = useConfirmations();

  const {
    getAllDentistsResponse,
    isValidating,
    createDentist,
    editDentist,
    deleteDentist,
    assignDentistToPriceList,
    revalidate,
  } = useDentists();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    isModalVisible: false,
    modalType: null,
    dentist: null,
    search: {
      dentist: "",
      companyName: "",
    },
  });

  const handleCreate = React.useCallback(
    async (submitValues: IDentistModalFormSubmitValues) => {
      const result = await createDentist({
        name: {
          firstName: submitValues.firstName,
          lastName: submitValues.lastName,
          honorific: submitValues.honorific,
        },
        clinicName: submitValues.companyName,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [createDentist, revalidate]
  );

  const handleEdit = React.useCallback(
    (dentistId: IDentistId) => async (submitValues: IDentistModalFormSubmitValues) => {
      const result = await editDentist({
        id: dentistId,
        name: {
          firstName: submitValues.firstName,
          lastName: submitValues.lastName,
          honorific: submitValues.honorific,
        },
        clinicName: submitValues.companyName,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [editDentist, revalidate]
  );

  const handleAssignPricingList = React.useCallback(
    (dentistId: IDentistId) => async (submitValues: IAssignPricingListSubmitValues) => {
      const result = await assignDentistToPriceList({
        id: dentistId,
        pricingListId: submitValues.pricingListId,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [assignDentistToPriceList, revalidate]
  );

  const handleDelete = React.useCallback(
    async (dentistId: IDentistId) => {
      const result = await deleteDentist({
        id: dentistId,
      });

      if (result.isOk()) {
        await revalidate();
        dispatch({ type: "hideModal" });
      }
    },
    [deleteDentist, revalidate]
  );

  return (
    <>
      <FormattedMessage id={"page-titles.dentists"} defaultMessage={"Dentyści"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <DentistModalForm
        key={state.modalType === "edit" ? state.dentist.id.value : "create-dentist"}
        visible={state.isModalVisible && (state.modalType === "create" || state.modalType === "edit")}
        modalType={state.modalType === "edit" ? "edit" : "create"}
        values={
          state.modalType === "edit"
            ? {
                honorific: state.dentist.name.honorific,
                firstName: state.dentist.name.firstName,
                lastName: state.dentist.name.lastName,
                companyName: state.dentist.clinicName === null ? undefined : state.dentist.clinicName,
              }
            : { honorific: Honorific.Dr }
        }
        onCancel={() => dispatch({ type: "hideModal" })}
        onSubmit={state.modalType === "edit" ? handleEdit(state.dentist.id) : handleCreate}
      />
      {state.modalType === "assign" && (
        <AssignPricingListModalForm
          visible={true}
          dentist={state.dentist}
          onCancel={() => dispatch({ type: "hideModal" })}
          onSubmit={handleAssignPricingList(state.dentist.id)}
        />
      )}
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.DentistsList />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id={"dentist-list-page.dentists"} defaultMessage={"Dentyści"} />}
          extra={[
            <Button type={"primary"} key={"create-button"} onClick={() => dispatch({ type: "showCreateModal" })}>
              <PlusOutlined />
              <FormattedMessage id="dentist-list-page.create-dentist" defaultMessage="Dodaj dentystę" />
            </Button>,
          ]}
        />
      </HeaderContent>
      {getAllDentistsResponse === undefined && <Spin />}
      {getAllDentistsResponse
        ?.map((dentists) => {
          if (state.search.dentist === "" && state.search.companyName === "") {
            return dentists;
          }

          return dentists.filter((d) => matchesSearchCriteria(d, state.search));
        })
        .map((dentists) => (
          <>
            <PageContent>
              <div className="my-8 bg-white">
                <InvitedDentistsCounts
                  totalDentists={dentists.length}
                  dentistsInvited={0}
                  dentistsAcceptedInvitation={0}
                />
              </div>
            </PageContent>
            <PageContent>
              <div className="px-5 pt-5 pb-0">
                <DentistQueryFilter
                  onSearch={async (searchParams) => {
                    dispatch({ type: "updateSearch", search: searchParams });
                  }}
                />
              </div>
              <div className="bg-white">
                <DentistList
                  dentists={dentists}
                  loading={isValidating}
                  onEdit={(dentist) => dispatch({ type: "showEditModal", dentist })}
                  onAssign={(dentist) => dispatch({ type: "assignToPriceList", dentist })}
                  onDelete={(dentist) => deleteDentistConfirmation(dentist, () => handleDelete(dentist.id))}
                />
              </div>
            </PageContent>
          </>
        ))
        .unwrapOrElse(() => (
          <Result
            status={"error"}
            title={
              <FormattedMessage
                id="dentist-list-page.failed-to-download-dentists-list"
                defaultMessage="Wystąpił problem podczas ładowania listy dentystów"
              />
            }
          />
        ))}
    </>
  );
};

export default DentistListPage;
