import * as React from "react";
import { useIntl } from "react-intl";
import { SpecificationType } from "features/orders/specification/order-specification";
import { specificationFormatter } from "components/formatters/specification/specification-formatter";
import { ExplanationTooltip } from "components/common-components/CommonTooltips";
import { specificationTypeExplanationFormatter } from "components/formatters/specification/specification-type-explanation-formatter";

interface ISpecificationFormatterProps {
  specification: SpecificationType;
  withExplanation?: boolean;
}

const SpecificationFormatter: React.FunctionComponent<ISpecificationFormatterProps> = ({
  specification,
  withExplanation = false,
}) => {
  const intl = useIntl();

  return (
    <>
      {specificationFormatter(intl, specification)}
      {withExplanation && (
        <>
          &nbsp;
          <ExplanationTooltip title={<>{specificationTypeExplanationFormatter(intl, specification)}</>} />
        </>
      )}
    </>
  );

  return <></>;
};

export default SpecificationFormatter;
