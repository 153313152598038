export enum CountryCode {
  AUT = 40,
  BEL = 56,
  BGR = 100,
  HRV = 191,
  CYP = 196,
  DNK = 208,
  EST = 233,
  FIN = 246,
  FRA = 250,
  DEU = 276,
  GRC = 300,
  HUN = 348,
  IRL = 372,
  ITA = 380,
  LVA = 428,
  LTU = 440,
  LUX = 442,
  MLT = 470,
  NLD = 528,
  POL = 616,
  PRT = 620,
  ROU = 642,
  SVK = 703,
  SVN = 705,
  ESP = 724,
  SWE = 752,
  CZE = 203,
  GBR = 826,
}

export enum Country {
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Germany = "DE",
  Denmark = "DK",
  Estonia = "EE",
  Spain = "ES",
  Finland = "FI",
  France = "FR",
  UnitedKingdom = "GB",
  Greece = "GR",
  Hungary = "HU",
  Croatia = "HR",
  Ireland = "IE",
  Italy = "IT",
  Lithuania = "LT",
  Luxembourg = "LU",
  Latvia = "LV",
  Malta = "MT",
  Netherlands = "NL",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Sweden = "SE",
  Slovenia = "SI",
  Slovakia = "SK",
}
