import * as React from "react";
import useSWR from "swr";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { nameof } from "ts-simple-nameof";
import { IPricingListApi } from "features/pricing-lists/service/IPricingListApi";
import sortBy from "lodash/sortBy";
import {
  ICreatePricingListRequest,
  IDeletePricingListRequest,
  IEditPricingListRequest,
  IPricingList,
  IUpdatePricingEntryRequest,
} from "features/pricing-lists/pricing-list.models";

function useAllPricingLists() {
  const pricingListApi = React.useContext(PricingListApiContext);

  const {
    data: getAllPricingLists,
    isValidating: isValidating,
    mutate: revalidate,
  } = useSWR(
    () => [nameof<IPricingListApi>((x) => x.getAll)],
    async () => {
      const result = await pricingListApi.getAll();

      if (result.isErr()) {
        return result;
      }

      return result.map((lists) =>
        sortBy(lists, [(p: IPricingList) => !p.isDefault, (p: IPricingList) => p.name.toLowerCase()])
      );
    }
  );

  const createPricingList = React.useCallback(
    async (request: ICreatePricingListRequest) => pricingListApi.create(request),
    [pricingListApi]
  );

  const editPricingList = React.useCallback(
    async (request: IEditPricingListRequest) => pricingListApi.edit(request),
    [pricingListApi]
  );

  const deletePricingList = React.useCallback(
    async (request: IDeletePricingListRequest) => pricingListApi.delete(request),
    [pricingListApi]
  );

  const setPricingEntry = React.useCallback(
    async (request: IUpdatePricingEntryRequest) => pricingListApi.setPricingEntry(request),
    [pricingListApi]
  );

  return {
    getAllPricingLists: getAllPricingLists,
    createPricingList,
    editPricingList,
    deletePricingList,
    setPricingEntry,
    isValidating: isValidating,
    revalidate: revalidate,
  };
}

export default useAllPricingLists;
