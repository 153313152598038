import { useContext, useState, useEffect, useCallback } from "react";
import AuthServiceContext from "../auth-service.context";
import AuthContext from "features/auth/auth-context";

function useAuth() {
  const authService = useContext(AuthServiceContext);
  const [currAuthCtx, setCurrAuthCtx] = useState<AuthContext>(authService.getAuthContext());

  const handleAuthContextChange = useCallback((next: AuthContext) => {
    setCurrAuthCtx((prevAuthContext) => {
      if (prevAuthContext !== null && prevAuthContext.equals(next)) {
        return prevAuthContext;
      }

      // TODO: clear swr cache on logout ( around here )
      // const { cache } = useSWRConfig()
      // cache.get(key) // Get the current data for a key.
      // cache.clear()  // ⚠️ Clear all the cache. SWR will revalidate upon re-render.

      return next;
    });
  }, []);

  useEffect(() => {
    authService.authContextSubject.attach(handleAuthContextChange);

    return () => {
      authService.authContextSubject.detach(handleAuthContextChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = currAuthCtx !== null && !currAuthCtx.equals(AuthContext.Empty());

  return {
    isLoggedIn: isLoggedIn,
    authContext: currAuthCtx,
  };
}

export default useAuth;
