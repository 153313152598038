import * as React from "react";
import { IOrderId } from "features/orders/order.model";
import OrderApiContext from "features/orders/order.context";
import useSWR from "swr";
import { Spin } from "antd";
import ErrorScreen from "components/errors/ErrorScreen";
import { IDentist } from "features/dentist/dentist.model";
import { IBillingStatement, ILineItem } from "features/invoicing/models/invoicing.models";
import { whenEvery } from "core/lib/types/result.utils";
import useOrdersCatalogue from "features/orders/hooks/useOrdersCatalogue";
import { nameof } from "ts-simple-nameof";
import { IOrderApi } from "features/orders/service/orders.service";
import { CreateGuid } from "core/guid/guid";
import { patientNameToString } from "components/formatters/patient-name/PatientNameFormatter";
import { orderItemFormatter } from "features/orders/components/formatters/order-item-formatter/order-item-formatter";
import { useIntl } from "react-intl";
import CreateBillingStatementForm from "features/invoicing/pages/create-invoice-page/components/create-billing-statement/CreateBillingStatementForm";
import InvoicingApiContext from "features/invoicing/invoice.context";
import { useCallback } from "react";

interface IProvideLineItemsProps {
  selectedOrders: IOrderId[];
  recipient: IDentist;
  dentists: IDentist[];
  onCancel: () => void;
  onCreated: (billingStatement: IBillingStatement) => void;
}

const CreateBillingStatementStep: React.FunctionComponent<IProvideLineItemsProps> = ({
  selectedOrders,
  recipient,
  dentists,
  onCancel,
  onCreated,
}) => {
  const intl = useIntl();
  const invoicingApi = React.useContext(InvoicingApiContext);

  const handleSubmit = useCallback(
    async (lines: ILineItem[]) => {
      const createResult = await invoicingApi.createBillingStatement({
        recipient: recipient.id,
        lineItems: lines,
        notes: "",
      });

      if (createResult.isErr()) {
        return;
      }

      onCreated(createResult.unwrap());
    },
    [invoicingApi, recipient.id]
  );

  const ordersApi = React.useContext(OrderApiContext);
  const { getOrdersCatalogue } = useOrdersCatalogue({ mode: "lab" });

  const { data: getOrdersResult } = useSWR(
    [nameof<IOrderApi>((_) => _.getOrdersByIds), ...selectedOrders.map((o) => o.value)],
    () => ordersApi.getOrdersByIds(selectedOrders)
  );

  if (getOrdersCatalogue === undefined || getOrdersResult === undefined) {
    return <Spin />;
  }

  if ([getOrdersCatalogue, getOrdersResult].some((result) => result.isErr())) {
    const firstError = [getOrdersCatalogue, getOrdersResult].find((result) => result.isErr())!;
    return <ErrorScreen error={firstError.err().unwrap()} />;
  }

  return whenEvery([getOrdersCatalogue, getOrdersResult])
    .map(([ordersCatalogue, orders]) => {
      const orderTypes = ordersCatalogue.categories.flatMap((c) => c.orderTypes);

      const lines = orders
        .filter((o) => dentists.some((d) => d.id.value == o.dentist.id.value))
        .flatMap((o) =>
          o.orderItems
            .filter((oi) => orderTypes.some((ot) => ot.id.value === oi.orderTypeId.value))
            .map((oi) => {
              const orderType = orderTypes.find((ot) => ot.id.value === oi.orderTypeId.value)!;
              const dentist = dentists.find((d) => d.id.value === o.dentist.id.value)!;

              const lineItem: ILineItem = {
                id: { type: "billing-statement-line-id", value: CreateGuid() },
                dentistId: dentist.id,
                orderId: o.orderId,
                orderNumber: o.orderNumber,
                patient: patientNameToString(o.patient),
                price: oi.totalPrice,
                description: orderItemFormatter(intl, orderType.name, orderType.code, oi.specification),
              };

              return lineItem;
            })
        );

      return (
        <CreateBillingStatementForm dentists={dentists} lines={lines} onSubmit={handleSubmit} onCancel={onCancel} />
      );
    })
    .unwrap();
};

export default CreateBillingStatementStep;
