import React from "react";
import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { IDentist } from "features/dentist/dentist.model";
import DentistSelect from "components/forms/dentist-select/DentistSelect";

export const DentistControl = (props: {
  dentists: IDentist[];
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    required
    label={<FormattedMessage id="common.dentist" defaultMessage="Dentysta" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="dentistId"
      control={props.control}
      render={({ field }) => <DentistSelect dentists={props.dentists} className={"max-w-lg"} {...field} />}
    />
  </Form.Item>
);
