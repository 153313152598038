import React from "react";
import { IOrderId } from "../../order.model";
import { useParams } from "react-router";
import useAccess from "features/access/useAccess";
import useAuth from "features/auth/hooks/useAuth";
import EditLabOrderView from "features/orders/pages/edit/components/EditLabOrderView";
import EditDentistOrderView from "features/orders/pages/edit/components/EditDentistOrderView";

export interface IEditOrderPageProps {}

const EditOrderPage: React.FunctionComponent<IEditOrderPageProps> = (props) => {
  const { orderId: orderIdParam } = useParams<"orderId">();
  const orderId: IOrderId = React.useMemo(() => ({ type: "order-id", value: orderIdParam! }), [orderIdParam]);

  const { isDentist, isLabOwner, isLabEmployee } = useAccess();
  const { authContext } = useAuth();

  if (isDentist) {
    return <EditDentistOrderView userId={authContext.userId!} orderId={orderId} />;
  } else if (isLabEmployee || isLabOwner) {
    return <EditLabOrderView labId={authContext.labId!} orderId={orderId} />;
  } else {
    return null;
  }
};

export default EditOrderPage;
