import * as React from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { IMoney, Currency } from "core/money/money.model";
import { argumentOutOfRangeError } from "core/errors/generate-error";

interface IMoneyFormatterProps {
  value: IMoney;
  children?: (formattedMoney: string) => React.ReactNode;
}

const mapCurrencyToReactIntlCurrency = (currency: Currency) => {
  switch (currency) {
    case Currency.PLN:
      return "pln";
    case Currency.EUR:
      return "eur";
    default:
      throw argumentOutOfRangeError(`unsuported currency`, JSON.stringify(currency));
  }
};

const MoneyFormatter: React.FunctionComponent<IMoneyFormatterProps> = ({ value, children }) => {
  const intl = useIntl();
  const { amount, currency } = value;
  const reactIntlCurrency = mapCurrencyToReactIntlCurrency(currency);

  if (typeof children === "function") {
    const formattedNumber = intl.formatNumber(amount, { style: "currency", currency: reactIntlCurrency });
    return <>{children(formattedNumber)}</>;
  }

  return (
    <FormattedNumber
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      value={amount}
      currency={reactIntlCurrency}
    />
  );
};

export default MoneyFormatter;
