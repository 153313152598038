import * as React from "react";
import { Avatar as AntAvatar } from "antd";
import { AvatarProps as IAntAvatarProps } from "antd/lib/avatar";

interface IAvatarProps extends IAntAvatarProps {
  name: string;
  firstLetterOnly?: boolean;
  useColor?: boolean;
  // add userId for color hashing
}

const Avatar: React.FunctionComponent<IAvatarProps> = (props) => {
  const { name, firstLetterOnly = false, useColor = true, ...otherProps } = props;

  return (
    <AntAvatar style={!useColor ? undefined : { backgroundColor: stringToHslColor(name) }} size="large" {...otherProps}>
      {firstLetterOnly && name.substring(0, 1).toUpperCase()}
      {!firstLetterOnly && name}
    </AntAvatar>
  );
};

export default Avatar;

const stringToHslColor = (str: string, s?: number, l?: number) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  const saturation = s === undefined ? 65 : s;
  const lightness = l === undefined ? 50 : l;
  return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
};
