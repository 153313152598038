import * as React from "react";
import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { PointBasedSpecification, SpecificationType } from "../../../specification/order-specification";

export const SpecificationModal = (props: {
  specification: PointBasedSpecification;
  isVisible: boolean;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
  children: React.ReactNode;
}) => {
  let modalTitle;

  switch (props.specification.specificationType) {
    case SpecificationType.LocatedPoints:
    case SpecificationType.PartialDentures:
    case SpecificationType.SurgicalGuides:
      modalTitle = <FormattedMessage id={"specification-modal.pick-points"} defaultMessage={"Wybierz"} />;
      break;
    case SpecificationType.Crowns:
      modalTitle = <FormattedMessage id={"specification-modal.pick-crowns"} defaultMessage={"Wybierz korony"} />;
      break;
    case SpecificationType.MarylandBridges:
    case SpecificationType.Bridges:
      modalTitle = <FormattedMessage id={"specification-modal.pick-bridges"} defaultMessage={"Wybierz mosty"} />;
      break;
    case SpecificationType.Foundations:
      modalTitle = (
        <FormattedMessage id={"specification-modal.pick-foundations"} defaultMessage={"Wybierz podbudowy"} />
      );
      break;
  }

  return (
    <Modal
      title={modalTitle}
      visible={props.isVisible}
      onCancel={props.onCancel}
      width={1200}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button key="cancel" onClick={props.onCancel}>
          <FormattedMessage id={"common.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button key="back" onClick={props.onBack} disabled={props.isBackDisabled}>
          <FormattedMessage id={"linked-points-specification-controls.cancel"} defaultMessage={"Wstecz"} />
        </Button>,
        <Button key="submit" type="primary" onClick={props.onNext} disabled={props.isNextDisabled}>
          <FormattedMessage id={"linked-points-specification-controls.next"} defaultMessage={"Dalej"} />
        </Button>,
      ]}
    >
      {props.children}
    </Modal>
  );
};

