import { ICloneable, IEquatable } from "core/interfaces/ICloneable";
import { UserRights } from "features/auth/contracts/auth-contracts.dto";
import { getStandardSort } from "core/utils/sort-utils";
import { deepCopy } from "core/utils/object";
import { isEqual } from "lodash";
import { AccountType, IClaim, ITenantId, IUserId, IUserProfile, UserRole } from "features/auth/auth.model";
import {
  extractAccountType,
  extractEmployeeId,
  extractTenantId,
  extractUserId,
  extractUserProfile,
  extractUserRights,
  extractUserRoles,
} from "features/auth/claim.functions";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { IEmployeeId } from "features/employee/employee.model";

class AuthContext implements ICloneable<AuthContext>, IEquatable<AuthContext> {
  public readonly claims: IClaim[] = [];
  public readonly userId: IUserId | null = null;
  public readonly userProfile: IUserProfile | null = null;
  public readonly accountType: AccountType | null = null;
  public readonly tenantId: ITenantId | null = null;
  public readonly employeeId: IEmployeeId | null = null;
  public readonly roles: UserRole[] | null = null;
  public readonly rights: UserRights[] | null;

  private constructor(claims: IClaim[]) {
    this.claims = [...claims].sort(getStandardSort("type"));
    this.userId = extractUserId(this.claims);
    this.userProfile = extractUserProfile(this.claims);
    this.tenantId = extractTenantId(this.claims);
    this.employeeId = extractEmployeeId(this.claims);
    this.accountType = extractAccountType(this.claims);
    this.roles = extractUserRoles(this.claims);
    this.rights = extractUserRights(this.claims);
  }

  clone(): AuthContext {
    if (this.isEmpty()) {
      return AuthContext.Empty();
    } else {
      return AuthContext.New(deepCopy(this.claims));
    }
  }

  equals = (other: AuthContext) => {
    if (other.claims.length !== this.claims.length) {
      return false;
    }

    if (other.userProfile !== null && this.userProfile === null) {
      return false;
    }

    if (other.userProfile === null && this.userProfile !== null) {
      return false;
    }

    if (!isEqual(this.userProfile, other.userProfile)) {
      return false;
    }

    return isEqual(this.claims, other.claims);
  };

  public isEmpty = () => {
    return this.claims.length === 0 && this.userProfile === null;
  };

  static Empty(): AuthContext {
    return new AuthContext([]);
  }

  static New(claims: IClaim[]): AuthContext {
    return new AuthContext(claims);
  }

  get isLabOwner() {
    return this.accountType === AccountType.LabOwner;
  }

  get isLabEmployee() {
    return this.accountType === AccountType.LabEmployee;
  }

  get isDentist() {
    return this.accountType === AccountType.Dentist;
  }

  get labId(): ILabId | null {
    return this.tenantId === null ? null : { type: "dental-lab-id", value: this.tenantId.value };
  }

  inRole = (roles: UserRole | UserRole[]): boolean => {
    if (this.roles === null) {
      return false;
    }

    const requiredRoles = typeof roles === "string" ? [roles] : roles;
    return requiredRoles.every((requiredRole) => this.roles!.includes(requiredRole));
  };

  hasRight = (rights: UserRights | UserRights[]): boolean => {
    if (this.rights === null) {
      return false;
    }

    const requiredRights = typeof rights === "string" ? [rights] : rights;
    return requiredRights.every((requiredRight) => this.rights!.includes(requiredRight));
  };
}

export default AuthContext;
