import { Breadcrumb } from "antd";
import Links from "components/links/Links";
import { FormattedMessage } from "react-intl";

export const Breadcrumbs = () =>
  <Breadcrumb>
    <Breadcrumb.Item>
      <Links.OrdersList />
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <FormattedMessage id="orders.order-details" defaultMessage="Szczegóły zamówienia" />
    </Breadcrumb.Item>
  </Breadcrumb>;
