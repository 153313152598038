import * as React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { TooltipPropsWithTitle } from "antd/lib/tooltip";

export const ExplanationTooltip: React.FunctionComponent<TooltipPropsWithTitle> = ({
  children = undefined,
  title,
  ...props
}) => (
  <Tooltip
    title={
      title === undefined ? (
        <>
          <FormattedMessage
            id="points-form.you-can-also-input-sequences"
            defaultMessage="Można podać sekwencje punktów np. 28-15 by podać wiele punków naraz"
          />
        </>
      ) : (
        <>{title}</>
      )
    }
    {...props}
  >
    {children !== undefined && <>{children}</>}
    {children === undefined && <QuestionCircleOutlined />}
  </Tooltip>
);
