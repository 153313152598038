import * as React from "react";
import { useCallback, useRef } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { IOrderId, NewOrderId } from "features/orders/order.model";
import { StatusCode } from "features/orders/status/order-status.model";
import PatientNameFormatter from "components/formatters/patient-name/PatientNameFormatter";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import Links from "components/links/Links";
import ProTable, { ActionType, ProColumns, ProTableProps } from "@ant-design/pro-table";
import OrderStatus from "components/status/order-status/OrderStatus";
import moment from "moment";
import { ProFormInstance } from "@ant-design/pro-form";
import { Radio } from "antd";
import { sleepForMs } from "core/utils/promise";
import ClockContext from "core/clock/clock.context";
import { IDentist } from "features/dentist/dentist.model";
import { ServerSortDirection } from "features/orders/pages/list/components/orders-table.models";
import {
  AssignmentFilter,
  ILabOrdersProviderParams,
  ILabOrderTableEntry,
  LabOrdersDataProvider,
  LabOrderServerColumn,
} from "features/orders/pages/list/components/lab-orders-table/lab-orders-table.models";

interface IPickLabOrdersTableProps {
  dataProvider: LabOrdersDataProvider;
  counterparties: IDentist[];
  selectedOrders: IOrderId[];
  onSelectedOrdersChanged: (selectedOrders: IOrderId[]) => void;
}

const PickLabOrdersTable: React.FunctionComponent<IPickLabOrdersTableProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const tableRef = React.useRef<ActionType>();
  const clock = React.useContext(ClockContext);
  const now = clock.now();

  const prevMonthStart = moment(now).subtract(1, "month").startOf("month");
  const prevMonthEnd = moment(now).subtract(1, "month").endOf("month");
  const currentMonthStart = moment(now).startOf("month");
  const currentMonthEnd = moment(now).endOf("month");
  const currentDayEnd = moment(now).endOf("day");

  const setFinishDateFilter = useCallback(
    (type: "currentMonth" | "lastMonth") => {
      const prevMonthRange: [moment.Moment, moment.Moment] = [prevMonthStart, prevMonthEnd];
      const currentMonthRange: [moment.Moment, moment.Moment] = [currentMonthStart, currentMonthEnd];
      return async () => {
        if (!formRef.current) {
          return;
        }

        switch (type) {
          case "lastMonth":
            formRef.current.setFieldsValue({ finishDate: prevMonthRange });
            break;
          case "currentMonth":
            formRef.current.setFieldsValue({ finishDate: currentMonthRange });
            break;
        }

        await sleepForMs(400);

        if (!tableRef.current) {
          return;
        }

        formRef.current?.submit?.();
      };
    },
    [currentMonthEnd, currentMonthStart, prevMonthEnd, prevMonthStart]
  );

  const tableColumns: ProColumns<ILabOrderTableEntry>[] = [
    {
      key: "finishDate", // TODO: 1
      sorter: LabOrderServerColumn.FinishDate,
      title: <FormattedMessage id="common.finish-date" defaultMessage="Termin oddania" />,
      hideInTable: true,
      valueType: "dateRange",
      formItemProps: {
        rules: [
          {
            required: true,
            message: "Zakres dat jest wymagany",
          },
        ],
      },
      initialValue: [prevMonthStart, currentDayEnd],
      search: {
        transform: (value) => ({
          finishDateRange: { start: value[0], end: value[1] },
        }),
      },
    },
    {
      key: "onlyFinishedFilter",
      hideInTable: true,
      title: <FormattedMessage id="pick-orders-table.order" defaultMessage="Zamówienia" />,
      valueType: "checkbox",
      valueEnum: {
        ["finished"]: { text: "Ukończone" },
        ["not-finished"]: { text: "Nieukończone" },
      },
      initialValue: ["finished"],
      formItemProps: {
        rules: [
          {
            required: true,
            message: "Proszę podać",
          },
        ],
      },
    },
    {
      key: "orderNumber",
      sorter: LabOrderServerColumn.OrderNumber,
      hideInSearch: true,
      title: <FormattedMessage id="common.short-order-number" defaultMessage="Nr." />,
      render: (_, entry) => (
        <Links.OrderDetails
          params={{
            orderId: entry.orderId,
            orderNumber: entry.orderNumber,
          }}
        >
          {entry.orderNumber}
        </Links.OrderDetails>
      ),
    },
    {
      key: "clinicName",
      title: <FormattedMessage id="common.dental-clinic" defaultMessage="Klinika" />,
      hideInSearch: true,
      render: (_, entry) => <span>{entry.dentist.clinicName}</span>,
    },
    {
      key: "dentist",
      sorter: false,
      hideInSearch: true,
      title: <FormattedMessage id="common.dentist" defaultMessage="Dentysta" />,
      render: (_, entry) => <PersonNameFormatter personName={entry.dentist.name} />,
    },
    {
      key: "patient",
      title: <FormattedMessage id="common.patient" defaultMessage="Pacjent" />,
      render: (_, entry) => (entry.patient === null ? null : <PatientNameFormatter patientName={entry.patient} />),
    },
    {
      key: "startDate",
      title: <FormattedMessage id="common.start-date" defaultMessage="Termin przyjścia" />,
      hideInSearch: true,
      render: (_, entry) =>
        entry.startDate !== null ? <FormattedDate value={new Date(entry.startDate)} dateStyle="short" /> : null,
    },
    {
      key: "status",
      sorter: LabOrderServerColumn.Status,
      title: <FormattedMessage id="common.status" defaultMessage="Status" />,
      hideInSearch: true,
      render: (status: React.ReactNode, entry) => <OrderStatus status={entry.status} />,
    },
    {
      key: "finishDate",
      sorter: LabOrderServerColumn.FinishDate,
      hideInSearch: true,
      title: <FormattedMessage id="common.finish-date" defaultMessage="Termin oddania" />,
      render: (_, entry) => (entry.finishDate !== null ? <FormattedDate value={new Date(entry.finishDate)} /> : null),
    },
    {
      key: "billed",
      title: <FormattedMessage id="invoicing.is-in-billint-statement" defaultMessage="Rozliczone ?" />,
      hideInSearch: true,
      render: (_, entry) => {
        if (entry.billingStatement === null) {
          return null;
        }

        const { billingStatementId, billingStatementNumber } = entry.billingStatement;

        return (
          <Links.BillingStatementDetails params={{ billingStatementId: billingStatementId }}>
            <FormattedMessage
              id="invoicing.order-is-in-billing-statement"
              defaultMessage="Tak - ({number})"
              values={{ number: billingStatementNumber }}
            />
          </Links.BillingStatementDetails>
        );
      },
    },
  ];

  const onRowSelectionChanged = React.useCallback(
    (selectedKeys: React.Key[]) => {
      props.onSelectedOrdersChanged((selectedKeys as string[]).map((x) => NewOrderId(x)));
    },
    [props]
  );

  const handleRequest: ProTableProps<ILabOrderTableEntry, any>["request"] = React.useCallback(
    async (params, sort) => {
      const { pageSize = 50, current = 1, patient = null, onlyFinishedFilter = null, finishDateRange = null } = params;

      const dataProviderParams: ILabOrdersProviderParams = {
        pagination: { pageNumber: current, pageSize: pageSize },
        dentistIds: props.counterparties.map((d) => d.id),
        assignmentFilter: AssignmentFilter.All,
      };

      if (patient !== null) {
        dataProviderParams.patient = patient;
      }

      if (onlyFinishedFilter !== null && Array.isArray(onlyFinishedFilter) && onlyFinishedFilter.length >= 1) {
        let status: StatusCode[] = [];

        if (onlyFinishedFilter.some((x) => x === "not-finished")) {
          status = [
            ...status,
            StatusCode.ReadyForPickUpFromClinic,
            StatusCode.TodoInLab,
            StatusCode.InProgressInLab,
            StatusCode.InProgressInClinic,
          ];
        }

        if (onlyFinishedFilter.some((x) => x === "finished")) {
          status = [...status, StatusCode.Finished];
        }

        dataProviderParams.status = status;
      }

      // noinspection DuplicatedCode
      if (finishDateRange !== null) {
        dataProviderParams.finishDateRange = {
          start: moment(finishDateRange.start).startOf("day").toISOString(),
          end: moment(finishDateRange.end).endOf("day").toISOString(),
        };
      }

      if (typeof sort === "object" && Object.keys(sort).length === 1) {
        const [column, direction] = Object.entries(sort)[0];
        dataProviderParams.sortBy = {
          column: column as LabOrderServerColumn,
          direction: direction === "ascend" ? ServerSortDirection.Ascending : ServerSortDirection.Descending,
        };
      }

      const result = await props.dataProvider(dataProviderParams);

      if (result.isErr()) {
        return { data: [], success: false };
      }

      const resultsPage = result.unwrap();
      return { data: resultsPage.data, success: true, total: resultsPage.pagination.totalElements };
    },
    [props]
  );

  return (
    <div>
      <div className={"flex flex-row gap-4 ml-5 mt-4"}>
        <Radio.Group>
          <Radio.Button
            className={"capitalize"}
            value={"a"}
            onClick={setFinishDateFilter("currentMonth")}
          >{`${currentMonthStart.format("MMMM")}`}</Radio.Button>
          <Radio.Button
            className={"capitalize"}
            value={"b"}
            onClick={setFinishDateFilter("lastMonth")}
          >{`${prevMonthStart.format("MMMM")}`}</Radio.Button>
        </Radio.Group>
      </div>
      <ProTable<ILabOrderTableEntry>
        actionRef={tableRef}
        rowKey={(item) => item.orderId.value}
        columns={tableColumns}
        dateFormatter="string"
        headerTitle={
          <FormattedMessage id="pick-orders.header-title" defaultMessage="Zamówienia do nowego rozliczenia" />
        }
        request={handleRequest}
        form={{ ignoreRules: false }}
        formRef={formRef}
        rowSelection={{
          selectedRowKeys: props.selectedOrders.map((x) => x.value),
          onChange: onRowSelectionChanged,
          preserveSelectedRowKeys: true,
          getCheckboxProps: (record: ILabOrderTableEntry) => ({ disabled: record.billingStatement !== null }),
        }}
        tableAlertRender={({ selectedRows }) => {
          if (selectedRows.length === 0) {
            return false;
          }

          return (
            <FormattedMessage
              id="pick-orders-table.selected-count-of-orders"
              defaultMessage="Wybrano { count, plural,
                =0 {zero zamówień}
                one {jedno zamówienie}
                few {# zamówienia}
                many {# zamówień}
                other {# zamówień}
              }."
              values={{ count: selectedRows.length }}
            />
          );
        }}
        pagination={{ showTotal: (total, range) => <>{`${range[0]}-${range[1]} z ${total}`}</> }}
        search={{ filterType: "query", layout: "vertical", defaultCollapsed: false }}
        options={{
          fullScreen: true,
          density: true,
          reload: true,
          setting: true,
        }}
      />
    </div>
  );
};

export default PickLabOrdersTable;
