import React from "react";
import { IModalApi } from "./modal.models";

export interface IModalMessagesContext {
  modalApi: IModalApi;
}

const ModalMessageContext = React.createContext<IModalMessagesContext>({ modalApi: null } as never);

export default ModalMessageContext;
