import React, { useContext } from "react";
import { Attachment, logChatPromiseExecution, UserResponse } from "stream-chat";
import { MessageList, MessageInput, Window, StreamMessage, useChannelActionContext} from "stream-chat-react";

import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from "features/chat/chat.model";

import ChannelHeader from "features/chat/pages/chat-page/components/ChannelHeader/ChannelHeader";
import { GiphyContext } from "features/chat/contexts/GiphyContext";

export type ChannelInnerProps = {
  showOpenChannelListArrow: boolean;
  onOpenChannelList: () => void;
};

export const ChannelInner: React.FC<ChannelInnerProps> = (props) => {
  const { giphyState, setGiphyState } = useContext(GiphyContext);

  const { sendMessage } = useChannelActionContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const overrideSubmitHandler = (message: {
    attachments: Attachment[];
    mentioned_users: UserResponse[];
    text: string;
    parent?: StreamMessage;
  }) => {
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith("/giphy")) {
      const updatedText = message.text.replace("/giphy", "");
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, "send message");
    }

    setGiphyState(false);
  };

  const actions = ["delete", "edit", "flag", "mute", "react", "reply"];

  return (
    <>
      <Window>
        <ChannelHeader
          showOpenChannelListArrow={props.showOpenChannelListArrow}
          onOpenChannelList={props.onOpenChannelList}
        />
        <MessageList messageActions={actions} />
        {/*// @ts-ignore*/}
        <MessageInput focus overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
    </>
  );
};
