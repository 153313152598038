import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button, { IButtonProps } from 'components/button/Button';
import { CategoryCode } from 'features/catalogue/category.model';

interface ICreateNewOrderTypeButtonProps extends IButtonProps {
    categoryCode: CategoryCode | null,
}

const ButtonMessage: React.FunctionComponent<{ categoryCode: CategoryCode | null }> = ({ categoryCode }) => {
    switch (categoryCode) {
        case CategoryCode.FullDentures:
            return <FormattedMessage id="create-order-type.create-for-full-dentures" defaultMessage="Stwórz nowy rodzaj protez całkowitych" />;
        case CategoryCode.PartialDentures:
            return <FormattedMessage id="create-order-type.create-for-partial-dentures" defaultMessage="Stwórz nowy rodzaj protez częściowych" />;
        case CategoryCode.FrameDentures:
            return <FormattedMessage id="create-order-type.create-for-frame-dentures" defaultMessage="Stwórz nowy rodzaj protez szkieletowych" />;
        case CategoryCode.SplintDentures:
            return <FormattedMessage id="create-order-type.create-for-splint-dentures" defaultMessage="Stwórz nowy rodzaj szynoprotez" />;
        case CategoryCode.Overdentures:
            return <FormattedMessage id="create-order-type.create-for-overdentures" defaultMessage="Stwórz nowy rodzaj protezy overdenture" />;
        case CategoryCode.Attachments:
            return <FormattedMessage id="create-order-type.create-for-attachments" defaultMessage="Stwórz nowy rodzaj attachmentow / elementów precyzyjnych" />;
        case CategoryCode.DentureRepair:
            return (
              <FormattedMessage
                id="create-order-type.create-for-denture-repair"
                defaultMessage="Stwórz nową usługę związaną z protezami"
              />
            );
        case CategoryCode.Crowns: {
            return <FormattedMessage id="create-order-type.create-for-crowns" defaultMessage="Stwórz nowy rodzaj koron" />;
        }
        case CategoryCode.Bridges: {
            return <FormattedMessage id="create-order-type.create-for-bridges" defaultMessage="Stwórz nowy rodzaj mostu" />;
        }
        case CategoryCode.MarylandBridges: {
            return <FormattedMessage id="create-order-type.create-for-maryland-bridges" defaultMessage="Stwórz nowy rodzaj mostu maryland" />;
        }
        case CategoryCode.Veneers: {
            return <FormattedMessage id="create-order-type.create-for-veneers" defaultMessage="Stwórz nowy rodzaj licówek" />;
        }
        case CategoryCode.Foundations: {
            return <FormattedMessage id="create-order-type.create-for-foundations" defaultMessage="Stwórz nowy rodzaj podbudowy" />;
        }
        case CategoryCode.ImplantAbutments: {
            return <FormattedMessage id="create-order-type.create-for-implant-connectors" defaultMessage="Stwórz nowy rodzaj łącznika" />;
        }
        case CategoryCode.Onlays: {
            return <FormattedMessage id="create-order-type.create-for-onlays" defaultMessage="Stwórz nowy rodzaj onlay'a" />;
        }
        case CategoryCode.Inlays: {
            return <FormattedMessage id="create-order-type.create-for-inlays" defaultMessage="Stwórz nowy rodzaj inlaya'a" />;
        }
        case CategoryCode.Overlays: {
            return <FormattedMessage id="create-order-type.create-for-overlays" defaultMessage="Stwórz nowy rodzaj over'laya" />;
        }
        case CategoryCode.EndoCrown: {
            return <FormattedMessage id="create-order-type.create-for-endo-crown" defaultMessage="Stwórz nowy rodzaj endo korony" />;
        }
        case CategoryCode.WaxUps: {
            return <FormattedMessage id="create-order-type.create-for-wax-ups" defaultMessage="Stwórz nowy rodzaj wax-up'ów" />;
        }
        case CategoryCode.Temporary: {
            return <FormattedMessage id="create-order-type.create-for-temporary" defaultMessage="Stwórz nowy rodzaj prac tymczasowych" />;
        }
        case CategoryCode.Splints: {
            return <FormattedMessage id="create-order-type.create-for-splints" defaultMessage="Stwórz nowy rodzaj szyn" />;
        }
        case CategoryCode.Post: {
            return <FormattedMessage id="create-order-type.create-for-root-canal-inlays" defaultMessage="Stwórz nowy rodzaj wkładu koronowo korzeniowego" />;
        }
        case CategoryCode.Gingiva: {
            return <FormattedMessage id="create-order-type.create-for-gingiva" defaultMessage="Stwórz nowy rodzaj dziąsła" />;
        }
        case CategoryCode.OrientationModels: {
            return <FormattedMessage id="create-order-type.create-for-orientation-models" defaultMessage="Stwórz nowy rodzaj modeli orientacyjnych" />;
        }
        case CategoryCode.Services: {
            return <FormattedMessage id="create-order-type.create-for-services" defaultMessage="Stwórz nową usługę" />;
        }
        case CategoryCode.MillingServices: {
            return <FormattedMessage id="create-order-type.create-for-milling-services" defaultMessage="Stwórz nową usługę frezowania" />;
        }
        case CategoryCode.Other: {
            return <FormattedMessage id="create-order-type.create-for-other" defaultMessage="Stwórz nowy rodzaj zamówienia" />;
        }
        default:
            return <FormattedMessage id="create-order-type.create-for-unknown-category-type" defaultMessage="Stwórz" />;
    }
}

const CreateNewOrderTypeButton: React.FunctionComponent<ICreateNewOrderTypeButtonProps> = ({ categoryCode, ...buttonProps }) => (
    <Button {...buttonProps}>
        <ButtonMessage categoryCode={categoryCode} />
    </Button>
)

export default CreateNewOrderTypeButton;