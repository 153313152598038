import * as React from "react";
import ReactDOM from "react-dom";

import "./styles/styles.scss";

import Root from "./root/Root";

// if (process.env.NODE_ENV !== "production") {
//   const { whyDidYouUpdate } = require("why-did-you-update");
//   whyDidYouUpdate(React);
// }

if (process.env.NODE_ENV !== "production") {
  // this one is better but still has issues with 'new' keyword
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(<Root />, document.getElementById("root"));
