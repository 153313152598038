import * as React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { OrderSpecification } from "../../specification/order-specification";
import OrderCodeName from "../../../../components/formatters/order-code-name/OrderCodeName";
import Button from "../../../../components/button/Button";
import { Popconfirm, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import SpecificationProviders from "./specification-providers/SpecificationProviders";
import OrderEntrySpecification from "./OrderEntrySpecification";
import { IOrderEntry } from "./order-entry.model";
import { IMoney } from "core/money/money.model";
import { isEmptySpecification } from "../../specification/order-specification.functions";
import MoneyFormatter from "../../../../components/formatters/money/MoneyFormatter";
import "./order-entry.scss";
import ChangePriceModal from "./change-price-modal/ChangePriceModal";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";
import { EditButton } from "components/common-components/CommonButtons";
import { useCallback } from "react";
import { IOrderId, IOrderItemId } from "../../order.model";
import { IResult } from "core/lib/types/result";
import { AlphabeticCharacter } from "features/orders/components/letter-indicator/letter.model";
import LetterIndicator from "features/orders/components/letter-indicator/LetterIndicator";

enum Size {
  Small = "sm",
  Large = "lg",
}

export interface IOrderEntryProps {
  size: Size;
  value: IOrderEntry;
  letter?: AlphabeticCharacter;
  onChange?: (entry: IOrderEntry) => void;
  onComputePrice?: (specification: OrderSpecification) => IMoney | null;
  onChangePrice?: (changePrice: {
    id: IOrderItemId;
    newPrice: IMoney;
    reasonForChange: string;
  }) => Promise<IResult<IOrderId>>;
  onDelete?: (entry: IOrderEntry) => void;
}

const OrderEntry: React.FunctionComponent<IOrderEntryProps> = ({
  size,
  value,
  letter = undefined,
  onChange,
  onComputePrice,
  onChangePrice,
  onDelete,
  ...otherProps
}) => {
  const { flag: isVisible, check: show, uncheck: hide } = useBooleanFlag(false);

  const handleChangePrice = useCallback(
    async (newPrice: IMoney, reasonForChange: string) => {
      if (onChangePrice === undefined) {
        return;
      }

      const result = await onChangePrice({ id: { type: "order-item-id", value: value.id }, newPrice, reasonForChange });

      if (result.isOk()) {
        hide();
      }

      return result;
    },
    [value.id, hide, onChangePrice]
  );

  const handleChange = React.useCallback(
    (specification: OrderSpecification) => {
      if (onChange === undefined || onComputePrice === undefined) {
        return;
      }

      const newPrice = onComputePrice(specification);

      const entry = { id: value.id, orderType: value.orderType, specification, price: newPrice };

      onChange(entry);
    },
    [onChange, onComputePrice, value.orderType, value.id]
  );

  const handleDelete = React.useCallback(() => {
    onDelete?.(value);
  }, [onDelete, value]);

  let name;

  if (value.orderType.code !== null) {
    name = <OrderCodeName code={value.orderType.code} />;
  } else {
    name = <React.Fragment key={value.orderType.name}>{value.orderType.name}</React.Fragment>;
  }

  console.info("onChange: ", onChange);

  return (
    <div className={"order-entry__outer-container"} {...otherProps}>
      <div
        className={classNames("order-entry__inner-container", {
          "order-entry__inner-container--size-large": size === Size.Large,
          "order-entry__inner-container--size-small": size === Size.Small,
        })}
      >
        <div className="order-entry__name">
          {letter !== undefined && <LetterIndicator letter={letter} className={"mr-2"} />}
          <Typography.Text>{name}</Typography.Text>
        </div>
        <div className="order-entry__spec">
          <Typography.Text type={"secondary"}>
            <OrderEntrySpecification entry={value} />
          </Typography.Text>
        </div>
        <div className="order-entry__btn">
          {onChange === undefined ? null : (
            <SpecificationProviders specification={value.specification} onChange={handleChange} />
          )}
        </div>
        <div className="order-entry__price">
          <span {...otherProps}>
            <FormattedMessage id={"order-entry.price"} defaultMessage={"Cena"} />
            <span className={"pl-3"}>
              {isEmptySpecification(value.specification) || value.price === null ? (
                <span> --- </span>
              ) : (
                <>
                  <MoneyFormatter value={value.price} />
                  {onChangePrice !== undefined && (
                    <>
                      <span className={"pl-3"}>
                        <EditButton type={"text"} onClick={show}>
                          Edytuj Cenę
                        </EditButton>
                      </span>
                      <ChangePriceModal
                        visible={isVisible}
                        onSubmit={handleChangePrice}
                        oldPrice={value.price}
                        onCancel={hide}
                      />
                    </>
                  )}
                </>
              )}
            </span>
          </span>
        </div>
        <div className="order-entry__delete">
          {onDelete !== undefined && (
            <Popconfirm
              title={
                <FormattedMessage
                  id={"order-entry.delete-item"}
                  defaultMessage={"Jesteś pewien że chcesz usunąć ten wpis ?"}
                />
              }
              onConfirm={handleDelete}
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderEntry;

export { Size as OrderEntrySize };
