import * as React from "react";
import { useEffect } from "react";
import { SelectProps } from "antd/lib/select";
import { CreateGuid } from "core/guid/guid";
import { isNotEmptyString } from "core/utils/utils";
import { Select } from "antd";
import { useIntl } from "react-intl";
import { SpecificationType } from "features/orders/specification/order-specification";
import { allSpecificationTypes } from "features/orders/specification/order-specification.const";
import { specificationFormatter } from "components/formatters/specification/specification-formatter";
import SpecificationFormatter from "components/formatters/specification/SpecificationFormatter";

export interface ISpecificationSelectProps extends SelectProps<string> {
  allowedSpecifications?: SpecificationType[];
}

const fixAutocomplete = () => {
  document?.querySelectorAll(".ant-select-selector input").forEach((e) => {
    e.setAttribute("autocomplete", CreateGuid());
  });
};

const SpecificationSelect = React.forwardRef<any, ISpecificationSelectProps>((props, ref: React.Ref<any>) => {
  const { children: _, allowedSpecifications = allSpecificationTypes, ...rest } = props;
  const intl = useIntl();

  const searchPredicate = React.useCallback(
    (input: string, option: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
      if (!isNotEmptyString(option.key)) {
        return false;
      }

      const key = option.key;
      const specification = allowedSpecifications.find((s) => s === key);

      if (specification === undefined) {
        return false;
      }

      const searchPhrase = input.toLowerCase();
      const specificationName = specificationFormatter(intl, specification);
      return specificationName.toLowerCase().indexOf(searchPhrase) !== -1;
    },
    [allowedSpecifications, intl]
  );

  useEffect(() => {
    fixAutocomplete();
  }, []);

  return (
    <Select ref={ref} showSearch filterOption={searchPredicate} {...(rest as any)}>
      {allowedSpecifications.map((specification) => (
        <Select.Option key={specification} value={specification}>
          <SpecificationFormatter specification={specification} withExplanation={true} />
        </Select.Option>
      ))}
    </Select>
  );
});

export default SpecificationSelect;
