import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IBillingStatement,
  IBillingStatementLineId,
  ILineItem,
} from "../../../../models/invoicing.models";
import classes from "./invoice-info.module.scss";
import { IDentist } from "features/dentist/dentist.model";
import { Card, Descriptions, Statistic } from "antd";
import LineItemsTable from "features/invoicing/components/line-items-table/LineItemsTable";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import { useState } from "react";

interface IInvoiceInfoProps {
  statement: IBillingStatement;
  dentists: IDentist[];
  onLineItemChange: (lines: ILineItem[]) => Promise<void>;
}

const BillingStatementInfo: React.FunctionComponent<IInvoiceInfoProps> = ({
  statement,
  dentists,
  onLineItemChange,
}) => {
  const intl = useIntl();

  const [editableKeys, setEditableRowKeys] = useState<IBillingStatementLineId[]>([]);

  const { createdOn, recipient, grandTotal, lineItems } = statement;

  const uniqueDentistsInBillingStatement = Array.from(new Set(lineItems.map((l) => l.dentistId?.value ?? null)))
    .filter((dId) => dId !== null)
    .map((dId) => dentists.find((d) => d.id.value == dId!))
    .filter((d) => d !== undefined);

  return (
    <div>
      <div className={classes.cardsContainer}>
        <Card
          className={classes.card}
          title={<FormattedMessage id="invoicing.invoice-issuer" defaultMessage="Wystawiona dla:" />}
        >
          <Descriptions column={1} bordered={false}>
            <Descriptions.Item label={<FormattedMessage id="invoicing.customer" defaultMessage="Klient" />}>
              <PersonNameFormatter personName={recipient.name} />
            </Descriptions.Item>
            {recipient.clinicName === null ? null : (
              <Descriptions.Item label={<FormattedMessage id="invoicing.company-name" defaultMessage="Firma" />}>
                {recipient.clinicName}
              </Descriptions.Item>
            )}
          </Descriptions>
        </Card>
        <Card
          className={classes.card}
          title={<FormattedMessage id="invoicing.dentist-list" defaultMessage="Dentyści na rozliczeniu" />}
        >
          <div>
            <span>
              {uniqueDentistsInBillingStatement.map((d) => (
                <PersonNameFormatter personName={d!.name} />
              ))}
            </span>
          </div>
        </Card>
        <Card className={classes.card}>
          <div className={classes.paymentCard}>
            <Statistic
              title={<FormattedMessage id="invoicing.created-on" defaultMessage="Wystawiono dnia" />}
              value={intl.formatDate(createdOn)}
            />
            <Statistic
              title={<FormattedMessage id="invoicing.invoice-grand-total" defaultMessage="Kwota łącznie" />}
              value={grandTotal.amount}
              precision={2}
              suffix="zł"
            />
          </div>
        </Card>
      </div>
      <div className={classes.tableContainer}>
        <LineItemsTable
          lines={lineItems}
          dentists={dentists}
          editableIds={editableKeys}
          onSetEditableLines={setEditableRowKeys}
          onLinesChange={onLineItemChange}
        />
      </div>
    </div>
  );
};

export default BillingStatementInfo;
