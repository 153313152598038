import React from "react";
import { FormattedMessage } from "react-intl";
import { Breadcrumb, PageHeader, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import ErrorScreen from "components/errors/ErrorScreen";
import { Money } from "core/money/money.functions";
import { useNavigate } from "react-router-dom";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { Path } from "core/routes/routes";
import OrderForm from "features/orders/components/order-form/OrderForm";
import OrderApiContext from "features/orders/order.context";
import useDentists from "features/dentist/hooks/useDentists";
import { IEditLabOrderRequest } from "features/orders/requests/orders.requests";
import useOrdersCatalogue from "features/orders/hooks/useOrdersCatalogue";
import { mapSpecificationToSpecificationDto } from "features/orders/contracts/orders.dto.mappers";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { ILabOrderReadModel, IOrderId } from "features/orders/order.model";
import useOrder from "features/orders/hooks/useOrder";
import { whenEvery } from "core/lib/types/result.utils";
import { Ok } from "core/lib/types/ok";
import { IDentistId } from "features/dentist/dentist.model";
import { IOrderEntry } from "features/orders/components/order-entry/order-entry.model";
import {
  FormMode,
  IEditLabOrderSubmitValues,
  LoadPricingListForDentistFn,
} from "features/orders/components/order-form/order-form.model";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";

import { IOrderCatalogue } from "features/catalogue/category.model";

export interface IEditLabOrderViewProps {
  labId: ILabId;
  orderId: IOrderId;
}

const EditLabOrderView: React.FunctionComponent<IEditLabOrderViewProps> = ({ labId, orderId }) => {
  const pricingListApi = React.useContext(PricingListApiContext);
  const ordersApi = React.useContext(OrderApiContext);
  const navigate = useNavigate();

  const { getOrder } = useOrder(orderId);
  const { getOrdersCatalogue } = useOrdersCatalogue({ mode: "lab" });
  const { getAllDentistsResponse } = useDentists();

  const handleLoadPricingListForDentist: LoadPricingListForDentistFn = React.useCallback(
    (labId: ILabId, dentistId: IDentistId) => pricingListApi.getForDentist(dentistId),
    [pricingListApi]
  );

  const handleIsOrderNumberTaken = React.useCallback(
    (orderNumber: number) => ordersApi.isOrderNumberTaken({ orderNumber: orderNumber }),
    [ordersApi]
  );

  const handleEditOrder = React.useCallback(
    async (values: IEditLabOrderSubmitValues) => {
      const request: IEditLabOrderRequest = {
        orderId: orderId,
        dentistId: values.dentistId,
        patient: {
          age: values.patientAge,
          patientCode: values.patientCode,
          gender: values.patientGender,
          lastName: values.patientLastName,
          firstName: values.patientFirstName,
          country: values.patientCountry,
        },
        orderNumber: values.orderNumber,
        orderNote: values.orderNote,
        startDate: values.startDate,
        dueDate: values.dueDate,
        orderColor: values.orderColor,
        orderItems: values.orderEntries.map((orderEntry) => ({
          id: orderEntry.orderItemId.value,
          orderTypeId: orderEntry.orderType.id.value,
          specification: mapSpecificationToSpecificationDto(orderEntry.specification),
          price: orderEntry.price === null ? Money.PLN(0) : orderEntry.price,
        })),
      };

      const editResult = await ordersApi.editLabOrder(request);

      if (editResult.isOk()) {
        navigate(parametrizePath({ path: Path.OrderDetails, params: { orderId: orderId.value } }));
      }

      return editResult;
    },
    [navigate, orderId, ordersApi]
  );

  if (getOrdersCatalogue === undefined || getAllDentistsResponse === undefined || getOrder === undefined) {
    return <Spin spinning={true} />;
  }

  if (getOrdersCatalogue.isErr()) {
    return <ErrorScreen error={getOrdersCatalogue.err().unwrap()} />;
  }

  if (getAllDentistsResponse.isErr()) {
    return <ErrorScreen error={getAllDentistsResponse.err().unwrap()} />;
  }

  if (getOrder.isErr()) {
    return <ErrorScreen error={getOrder.err().unwrap()} />;
  }

  return (
    <div className={"w-full"}>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.OrdersList />
          </Breadcrumb.Item>
          {getOrder
            .map((order) => (
              <>
                <Breadcrumb.Item>
                  <Links.OrderDetails
                    params={{ orderId: orderId, orderNumber: (order as ILabOrderReadModel).orderNumber }}
                  />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Links.EditOrder params={{ orderId: orderId }} />
                </Breadcrumb.Item>
              </>
            ))
            .unwrap()}
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="create-order-wizard.edit-order" defaultMessage="Edytuj zamówienie" />}
        />
      </HeaderContent>
      {whenEvery([getOrdersCatalogue, getAllDentistsResponse, getOrder])
        .map(([ordersCatalogue, dentists, order]) => {
          const labOrder = order as ILabOrderReadModel;
          const allOrderTypes = ordersCatalogue.categories.flatMap((c) => c.orderTypes);

          const orderEntries = order.orderItems.map((oi) => {
            const orderType = allOrderTypes.find((ot) => ot.id.value === oi.orderTypeId.value);

            if (orderType === undefined) {
              throw new Error("could not find order type" + oi.orderTypeId.value);
            }

            const orderEntry: IOrderEntry = {
              id: oi.id.value,
              orderType: {
                id: oi.orderTypeId,
                code: orderType.code,
                requiredSpecification: orderType.requiredSpecification,
                name: orderType.name,
              },
              price: oi.totalPrice,
              specification: oi.specification,
            };

            return orderEntry;
          });

          return (
            <OrderForm
              mode={FormMode.LabEdit}
              labId={labId}
              dentists={dentists}
              defaultValues={{
                dentistId: labOrder.dentist.id.value,
                patientCode: labOrder.patient.patientCode,
                patientFirstName: labOrder.patient.firstName,
                patientLastName: labOrder.patient.lastName,
                patientAge: labOrder.patient.age,
                patientGender: labOrder.patient.gender,
                patientCountry: labOrder.patient.country,
                dueDate: labOrder.dueDate,
                startDate: labOrder.startDate,
                orderNumber: labOrder.orderNumber,
                orderEntries: orderEntries,
                orderColor: labOrder.color,
                orderNote: labOrder.orderNote === null ? undefined : labOrder.orderNote,
              }}
              onLoadOrderCatalogue={async (_: ILabId) => new Ok<IOrderCatalogue>(ordersCatalogue)}
              isOrderNumberTakenCheck={handleIsOrderNumberTaken}
              onLoadPricingListForDentist={handleLoadPricingListForDentist}
              onSubmit={handleEditOrder}
            />
          );
        })
        .unwrap()}
    </div>
  );
};

export default EditLabOrderView;
