import * as React from "react";
import { Breadcrumb, Card, List, PageHeader, Spin } from "antd";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import Links from "components/links/Links";
import useDentistLabs from "features/dental-lab/hook/dentist/useDentistLabs";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

interface IAllLaboratoryPricingListsPageProps {}

const AllDentistsPricingListsPage: React.FunctionComponent<IAllLaboratoryPricingListsPageProps> = () => {
  const { getAllDentistLabs } = useDentistLabs();

  if (getAllDentistLabs === undefined) {
    return <Spin />;
  }

  return (
    <>
      <FormattedMessage id={"page-titles.pricing-lists"} defaultMessage={"Cenniki"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.DentistsPricingLists />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id={"all-dentists-pricing-lists-page.page-title"} defaultMessage={"Laboratoria"} />}
        />
      </HeaderContent>
      <PageContent>
        <div className="bg-white p-8">
          {getAllDentistLabs
            .map((labs) => (
              <List
                grid={{ gutter: 16, xs: 1, md: 2, lg: 4, xl: 5 }}
                itemLayout="horizontal"
                dataSource={labs}
                locale={{ emptyText: <span>Brak powiązanych laboratoriów</span> }}
                renderItem={(lab) => (
                  <List.Item>
                    <Links.DentistPricingList params={{ labId: lab.id }}>
                      <Card hoverable={true}>
                        <div className="font-bold">{lab.name}</div>
                      </Card>
                    </Links.DentistPricingList>
                  </List.Item>
                )}
              />
            ))
            .unwrap()}
        </div>
      </PageContent>
    </>
  );
};

export default AllDentistsPricingListsPage;
