import * as React from "react";
import { Button, Form } from "antd";
import { FormProps } from "antd/lib/form";
import { FormattedMessage, useIntl } from "react-intl";
import { nameof } from "ts-simple-nameof";
import { IResult } from "core/lib/types/result";
import Input from "../../../../components/forms/input/Input";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";

interface IResetPasswordFormProps {
  onSubmit: (submitValues: ISubmitValues) => Promise<IResult<void>>;
}

interface IFormValues {
  readonly email: string;
}

interface ISubmitValues {
  readonly email: string;
}

const formNames = {
  email: nameof<IFormValues>((x) => x.email),
};

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({ onSubmit }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { flag: isLoading, check: startLoading, uncheck: finishLoading } = useBooleanFlag(false);

  const onFinishFailed: FormProps["onFinishFailed"] = ({ errorFields }) => {
    form.scrollToField(errorFields[0]?.name);
  };

  const onFinish: FormProps["onFinish"] = async (store: IFormValues) => {
    const { email } = store;
    startLoading();

    try {
      await onSubmit({ email });
    } finally {
      finishLoading();
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"vertical"}
      >
        <Form.Item
          name={formNames.email}
          rules={[
            {
              required: true,
              message: <FormattedMessage id="common.email-is-required" defaultMessage="Podaj email" />,
            },
            {
              type: "email",
              message: <FormattedMessage id="common.email-is-invalid" defaultMessage="Niepoprawny email" />,
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: "login-form.email",
              defaultMessage: "Email",
            })}
            type={"email"}
            autoComplete={"stop"}
          />
        </Form.Item>
        
        <Button htmlType={"submit"} type="primary" block loading={isLoading}>
          <FormattedMessage id="reset-password-form.reset" defaultMessage="Zresetuj hasło" />
        </Button>
      </Form>
    </>
  );
};

export default ResetPasswordForm;

export type { ISubmitValues as IResetPasswordFormSubmitValues };
