import * as React from "react";
import { useCallback } from "react";
import classNames from "classnames";
import { DotId, PointId } from "core/point/point.enums";
import { JawViewProps } from "./jaw-view.model";
import { allTeeths } from "core/tooth/tooth.const";
import {
  defaultAnchorOptions,
  defaultDotOptions,
  defaultLabelOptions,
  defaultPointOptions,
  jawSvgStyles,
} from "./jaws.consts";
import { AnchorType } from "../../../../order-type/anchor-type.models";

const UpperJawView: React.FunctionComponent<JawViewProps> = ({
  dots = {},
  labels = {},
  anchors = {},
  pointOptions = defaultPointOptions,
  dotOptions = defaultDotOptions,
  anchorOptions = defaultAnchorOptions,
  labelOptions = defaultLabelOptions,
  points,
  onDotSelected,
  onPointSelected,
}) => {
  const handlePointClick = useCallback(
    (pointId: PointId) => (_: React.MouseEvent) => {
      onPointSelected?.(pointId);
    },
    [onPointSelected]
  );

  const handleDotClick = useCallback(
    (dot: DotId) => (_: React.MouseEvent) => {
      if (dotOptions?.editable !== true) {
        return;
      }

      onDotSelected?.(dot);
    },
    [dotOptions?.editable, onDotSelected]
  );

  const allLabels = allTeeths.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: labels[curr.id] === undefined ? [] : labels[curr.id],
    }),
    {} as Record<PointId, string[]>
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 601.57 491.85">
      <defs>
        <style>{jawSvgStyles}</style>
      </defs>
      <path
        className="cls-1"
        d="M83.08,490.71H59.22s-21.34-24.71-7-73l3.65-9.83S41.25,376.15,59.5,345l7-8.43S52,295.13,84.81,267.17l1.63-3.25s-6.79-23.62,19.81-50.76V208s-5.43-28.22,25.24-46.68l2.17-11.94s.28-26.6,44.52-39.36l4.34-6s9.77-32.3,47.5-30.94l6-5.7s20.63-20.09,60.8-12.49c0,0,4.34,2.81,6.78-1.71,0,0,38.54-19.46,63,9.31l6,9.5s36.92-6.51,48.86,25.52l6.78,7.6s38.27,5.15,39.9,44v11.67s35.83,7.05,33.39,39.62l4.34,12.76s23.88,16,18.18,43.43L528,268.74s28.78,25.57,11.68,64.66l5.42,10.85s26.06,15.48,11.68,57.82c0,0-1.09,15.2,1.08,19.27,0,0,23.07,48-17.1,66.22,0,0-20.73,7-65.68,3.15-1.9-.16-29.59-.7-47.23-37.62,0,0-36.66-82.78-122-79.25,0,0-71-8.14-117.38,75.18,0,0-11.13,31.22-42.07,39.36Z"
      />
      <path
        className="cls-2"
        d="M473.13,483.65c-8.19-13.66-6.5-31.85-6.5-31.85,1.89-23.26,14.46-35.41,14.46-35.41-13.2-14.67-12.36-34.16-12.36-34.16-2.31-23.89,12.57-34.16,12.57-34.16-8.59-5.44-15.51-18.23-15.51-18.23-8.38-12.36-2.72-28.91-2.72-28.91,5.24-18.44-3.35-23.05-3.35-23.05-13.21-7.13-17.82-22-17.82-22-3.77-11.31,5.24-24.93,5.24-24.93-8-.84-13.83-6.92-13.83-6.92-9.85-9-8-33.32-8-33.32C426,175.41,413,176.45,413,176.45c-10.69-2.51-18.44-10.26-18.44-10.26-8.6-6.08-9-21-9-21a33.75,33.75,0,0,1-27.94-10.44c-6.7-6.91-11.88-17-11.88-17-7.33,9-23.89,3.56-23.89,3.56-12.78-1.67-20.32-11.52-20.32-11.52-5.24,17.18-27,13.83-27,13.83-14.25,1.25-21.37-5.45-21.37-5.45.84,6.08-5,11.1-5,11.1-7.54,5.66-25.56,9.85-25.56,9.85C212.24,140.83,212,151.31,212,151.31c-.84,6.7-5.24,14.25-5.24,14.25-7.75,11.1-23.89,16.76-23.89,16.76-9.85,5-4.61,16.76-4.61,16.76,2.93,11.32-1.26,18.65-1.26,18.65-2.3,7.13-15.08,10.9-15.08,10.9-3.57.42-2.1,3.77-2.1,3.77,5.87,5.66,2.3,21.8,2.3,21.8-2.09,16.55-18,28.08-18,28.08-3.77,2.51-1.25,9.22-1.25,9.22l2.51,10.89c3.77,25.78-10.27,35.21-10.27,35.21-13.2,7.12-2.72,18.65-2.72,18.65,16.34,14.25,5.45,32.27,5.45,32.27-16.35,19.7-10.9,25.77-10.9,25.77,14.46,17.6,13.41,33.53,13.41,33.53.42,32.48-17.62,41.43-17.62,41.43l-39.66,1.46H59.22s-21.34-24.71-7-73l3.65-9.83S41.25,376.15,59.5,345l7-8.43S52,295.13,84.81,267.17l1.63-3.25s-6.79-23.62,19.81-50.76V208s-5.43-28.22,25.24-46.68l2.17-11.94s.28-26.6,44.52-39.36l4.34-6s9.77-32.3,47.5-30.94l6-5.7s20.63-20.09,60.8-12.49c0,0,4.34,2.81,6.78-1.71,0,0,38.54-19.46,63,9.31l6,9.5s36.92-6.51,48.86,25.52l6.78,7.6s38.27,5.15,39.9,44v11.67s35.83,7.05,33.39,39.62l4.34,12.76s23.88,16,18.18,43.43L528,268.74s28.78,25.57,11.68,64.66l5.42,10.85s26.06,15.48,11.68,57.82c0,0-1.09,15.2,1.08,19.27,0,0,23.07,48-17.1,66.22,0,0-17.82,6-56.26,3.82A15.94,15.94,0,0,1,473.13,483.65Z"
      />
      <g id="point-18">
        <path
          onClick={handlePointClick(PointId.P18)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P18] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P18] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P18] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P18] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P18]?.selected === true,
          })}
          d="M72.75,488a185.88,185.88,0,0,1-21.28-1h-.1l-.1,0c-.21-.07-20.69-7.37-20.69-35.23a64.07,64.07,0,0,1,3.05-15.88c3.62-10.82,9.77-17.28,17.79-18.67A189.82,189.82,0,0,1,77,415.44a115.7,115.7,0,0,1,27.66,2.91l.1,0c.25.09,24.66,9.52,22.76,36.78.09,1.71,1.09,29.77-27.23,31.86C100.14,487,87,488,72.75,488Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P18]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P18] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P18] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P18] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P18] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M77,416.44c8.78,0,18.62.68,27.41,2.88,0,0,24,9,22.11,35.83,0,0,2.1,28.78-26.3,30.88,0,0-13,1-27.44,1a187.75,187.75,0,0,1-21.16-1s-20-6.82-20-34.29c0,0,1.33-30.33,20-33.57A187.46,187.46,0,0,1,77,416.44m0-2v0h0a187.2,187.2,0,0,0-25.68,1.76c-8.45,1.46-14.87,8.15-18.61,19.34a65.62,65.62,0,0,0-3.1,16.11v.09c0,14.86,5.75,23.89,10.57,28.84A30.31,30.31,0,0,0,51,487.92l.19.07.21,0a187.88,187.88,0,0,0,21.4,1c14.31,0,27.46-1,27.6-1,15.68-1.16,22.52-10.14,25.49-17.47a38.38,38.38,0,0,0,2.66-15.39c1-14.58-5.41-24.05-11-29.44a39.93,39.93,0,0,0-12.47-8.28l-.1,0-.11,0A116.6,116.6,0,0,0,77,414.44Z"
        />
        <path
          className="cls-pts-shadow"
          d="M103.36,478.22c21.59-1.59,20-23.47,20-23.47,1.11-15.63-9.31-23.29-14.27-26,0,0,3.94,24.75-3.12,43.82Z"
        />
        <text className="cls-text" transform="translate(59.44 462.14)">
          18
        </text>
        {allLabels[PointId.P18].length > 0 && (
          <text className="cls-text" transform="translate(22.11 460.12) rotate(-94.98)">
            {allLabels[PointId.P18].join("|")}
          </text>
        )}
      </g>
      <g id="point-17">
        <path
          onClick={handlePointClick(PointId.P17)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P17] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P17] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P17] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P17] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P17]?.selected === true,
          })}
          d="M75.67,410A171.26,171.26,0,0,1,56,409.06h-.1l-.1,0c-.2-.07-19.17-6.83-19.17-32.63a59.37,59.37,0,0,1,2.82-14.7c3.35-10,9.06-16,16.5-17.3a175.91,175.91,0,0,1,23.62-1.61,107.15,107.15,0,0,1,25.57,2.68l.06,0,0,0c.23.08,22.83,8.81,21.08,34.05.09,1.64,1,27.58-25.23,29.52C101,409.08,88.86,410,75.67,410Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P17]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P17] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P17] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P17] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P17] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M79.57,343.78a107.57,107.57,0,0,1,25.33,2.65s22.19,8.28,20.43,33.11c0,0,1.94,26.59-24.3,28.53,0,0-12.06.94-25.36.94a171.6,171.6,0,0,1-19.55-.94s-18.49-6.3-18.49-31.68c0,0,1.23-28,18.49-31a173.07,173.07,0,0,1,23.45-1.6m0-2h0a172.9,172.9,0,0,0-23.75,1.62C48,344.76,42,351,38.5,361.37a60.81,60.81,0,0,0-2.87,14.94v.08c0,13.79,5.33,22.16,9.81,26.76a28.16,28.16,0,0,0,10,6.81l.2.07.21,0a173.53,173.53,0,0,0,19.79.95c13.23,0,25.39-.94,25.51-.95,14.57-1.07,20.93-9.42,23.69-16.23a35.66,35.66,0,0,0,2.46-14.28c.92-13.52-5-22.31-10.16-27.31a37,37,0,0,0-11.57-7.68l-.11,0-.1,0a108.26,108.26,0,0,0-25.82-2.71Z"
        />
        <path
          className="cls-pts-shadow"
          d="M103,403c22-1.63,20.33-23.87,20.33-23.87,1.47-20.78-17.09-27.7-17.09-27.7s4.45,12.35,1.57,39.07A26.17,26.17,0,0,1,103,403Z"
        />
        <text className="cls-text" transform="translate(65.79 386.38)">
          17
        </text>
        {allLabels[PointId.P17].length > 0 && (
          <text className="cls-text" transform="translate(20.18 383.01) rotate(-81.59)">
            {allLabels[PointId.P17].join("|")}
          </text>
        )}
      </g>
      <g id="point-16">
        <path
          onClick={handlePointClick(PointId.P16)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P16] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P16] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P16] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P16] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P16]?.selected === true,
          })}
          d="M97.12,341.88a79.29,79.29,0,0,1-10.05-.68A187.24,187.24,0,0,1,66.17,336l-.08,0L66,336a26.75,26.75,0,0,1-7.92-7c-3.42-4.39-7.18-11.95-5.67-23,0-.37.58-11.33,6.4-29.14.22-.5,4.65-10.27,19.55-10.27,5.63,0,12.07,1.43,19.14,4.26l25.56,10.51c.44.18,11,4.58,12.52,16,.62,1.73,6.43,19.65-13.32,36.43C121.49,334.73,114.9,341.88,97.12,341.88Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P16]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P16] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P16] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P16] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P16] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M78.37,267.59c5,0,11.13,1.14,18.77,4.19l25.55,10.51s10.5,4.25,11.92,15.33c0,0,7.39,18.17-13.06,35.49,0,0-6.1,7.77-24.44,7.77a75.94,75.94,0,0,1-9.91-.68,182.72,182.72,0,0,1-20.73-5.11s-15.9-8.23-13.06-29c0,0,.52-11.07,6.36-29,0,0,4.18-9.58,18.6-9.58m0-2h0c-15.55,0-20.24,10.34-20.43,10.79l0,.08,0,.09c-5.65,17.28-6.38,28.2-6.45,29.4-1.53,11.35,2.35,19.13,5.88,23.67a28.12,28.12,0,0,0,8.25,7.25l.15.08.16.05a182.34,182.34,0,0,0,21,5.17h.09a79.47,79.47,0,0,0,10.17.69c17.76,0,24.75-7.08,25.86-8.36,11-9.34,14.08-19,14.77-25.44a28.13,28.13,0,0,0-1.17-11.95c-1.67-11.92-12.66-16.51-13.13-16.7L97.9,269.93c-7.21-2.88-13.77-4.34-19.53-4.34Z"
        />
        <path
          className="cls-pts-shadow"
          d="M103.27,333.77c9.8.55,17.18-5.63,17.18-5.63,15.26-12.93,9.75-26.49,9.75-26.49a13.86,13.86,0,0,0-4.39-8.41,52.44,52.44,0,0,1-1.6,7.51c-3.57,12.68-9,23.19-19.08,31.72Z"
        />
        <text className="cls-text" transform="translate(75.62 314.07)">
          16
        </text>
        {allLabels[PointId.P16].length > 0 && (
          <text className="cls-text" transform="matrix(0.24, -0.97, 0.97, 0.24, 28.81, 301.94)">
            {allLabels[PointId.P16].join("|")}
          </text>
        )}
      </g>
      <g id="point-15">
        <path
          onClick={handlePointClick(PointId.P15)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P15] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P15] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P15] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P15] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P15]?.selected === true,
          })}
          d="M118.34,276.13c-.46-.08-11.36-2.13-32.85-10.69A21,21,0,0,1,74.7,255.05c-2.56-5.64-2.39-12.39.5-20.06a47.12,47.12,0,0,1,5.43-11.5c5-7.7,11.44-11.76,18.55-11.76a22,22,0,0,1,7,1.2c.2,0,26.26,6.83,42.23,23l.14.14.07.19c.09.21,8.55,21.46-6.53,32.12-.75.67-8.09,6.88-23.51,7.78h-.12Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P15]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P15] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P15] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P15] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P15] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M99.18,212.73a21.2,21.2,0,0,1,6.68,1.14s25.87,6.61,41.83,22.75c0,0,8.44,20.66-6.24,31,0,0-7.16,6.64-22.93,7.56,0,0-11-2-32.66-10.64,0,0-18.16-6.79-9.72-29.17,0,0,6.52-22.61,23-22.61m0-2h0c-7.46,0-14.17,4.22-19.39,12.21a48.93,48.93,0,0,0-5.55,11.76c-3,7.91-3.12,14.89-.45,20.76a22,22,0,0,0,11.37,10.92c21.54,8.58,32.54,10.65,33,10.74l.23,0h.24c15.43-.9,23-7.08,24.07-8,6.52-4.63,9.76-11.89,9.37-21a40.35,40.35,0,0,0-2.53-12.28l-.15-.37-.28-.28c-15.91-16.09-40.87-22.79-42.69-23.27a23.19,23.19,0,0,0-7.23-1.22Z"
        />
        <path
          className="cls-pts-shadow"
          d="M122.84,269.34c11.46-.66,16.65-5.48,16.65-5.48,10.66-7.49,4.53-22.49,4.53-22.49-1-1-2-1.91-3.05-2.8,0,0,1.71,17.52-13.22,27.86Z"
        />
        <text className="cls-text" transform="translate(94.49 253.96)">
          15
        </text>
        {allLabels[PointId.P15].length > 0 && (
          <text className="cls-text" transform="matrix(0.28, -0.96, 0.96, 0.28, 49.37, 231.3)">
            {allLabels[PointId.P15].join("|")}
          </text>
        )}
      </g>
      <g id="point-14">
        <path
          onClick={handlePointClick(PointId.P14)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P14] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P14] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P14] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P14] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P14]?.selected === true,
          })}
          d="M144.09,224a27,27,0,0,1-12.86-3.17l-14.72-7.21-15.74-8.27-.1-.1c-.37-.34-9-8.69-2.76-25.05,0-.14,6.35-21.68,25.61-21.68a30.55,30.55,0,0,1,7.94,1.11l.08,0,.07,0c1.42.64,34.82,15.78,40.76,31.75.09.28,5.52,20.68-14.62,29.43A31.4,31.4,0,0,1,144.09,224Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P14]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P14] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P14] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P14] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P14] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M123.52,159.52a29.36,29.36,0,0,1,7.68,1.08s34.37,15.42,40.24,31.18c0,0,5.48,19.67-14.09,28.17A30.63,30.63,0,0,1,144.09,223,26.12,26.12,0,0,1,131.7,220L117,212.73l-15.59-8.2s-8.55-8.05-2.52-24c0,0,6-21,24.68-21m0-2v0h0c-8.32,0-15.58,3.92-21,11.34a39.45,39.45,0,0,0-5.6,11.06c-6.38,16.93,2.66,25.7,3,26.06l.2.19.24.13L116,214.5h0l14.72,7.21a28.09,28.09,0,0,0,13.3,3.27,32.62,32.62,0,0,0,14.13-3.25c20.84-9.1,15.2-30.29,15.14-30.5l0-.08,0-.08c-6.09-16.36-39.86-31.67-41.29-32.31l-.15-.07-.15,0a31.76,31.76,0,0,0-8.2-1.15Z"
        />
        <path
          className="cls-pts-shadow"
          d="M142.18,216.52a21.52,21.52,0,0,0,15-1.42c14.07-6.11,10.12-20.25,10.12-20.25a20.06,20.06,0,0,0-4.71-6.77,31.63,31.63,0,0,1-14.68,25.1Z"
        />
        <text className="cls-text" transform="translate(119.66 199.75)">
          14
        </text>
        {allLabels[PointId.P14].length > 0 && (
          <text className="cls-text" transform="translate(75.26 169.67) rotate(-58.51)">
            {allLabels[PointId.P14].join("|")}
          </text>
        )}
      </g>
      <g id="point-13">
        <path
          onClick={handlePointClick(PointId.P13)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P13] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P13] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P13] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P13] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P13]?.selected === true,
          })}
          d="M170.57,173.62A22.7,22.7,0,0,1,158,170.05c-1-.44-9.9-4.87-24.35-20.1l-.09-.1-.07-.12c-.1-.19-9.86-19,6-33.74.47-.46,12-11.53,25.16-11.53a21.25,21.25,0,0,1,12.74,4.16l.07,0,0,.06c.8.78,19.53,19.39,23.82,27.07,3.9,5.88,7.66,19.59-6.37,28.88C194.57,165,183.13,173.62,170.57,173.62Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P13]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P13] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P13] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P13] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P13] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M164.68,105.45a20.29,20.29,0,0,1,12.16,4s19.38,19.2,23.65,26.85c0,0,10.84,16.36-6.05,27.56,0,0-11.5,8.78-23.87,8.78a21.75,21.75,0,0,1-12-3.44s-9.09-4.09-24.1-19.92c0,0-9.69-18.14,5.78-32.54,0,0,11.58-11.26,24.48-11.27m0-2h0c-13.56,0-25.37,11.35-25.87,11.83a28,28,0,0,0-9.3,23.26,32.14,32.14,0,0,0,3.14,11.66l.13.24.18.2c14.27,15,23.29,19.67,24.59,20.29a23.75,23.75,0,0,0,13,3.69c12.58,0,24-8.39,25-9.15,17.63-11.73,7.46-28.86,6.61-30.21-4.41-7.83-23.17-26.46-24-27.26l-.11-.1-.12-.1a22.37,22.37,0,0,0-13.33-4.35Z"
        />
        <path
          className="cls-pts-shadow"
          d="M166.15,167.33c11.87,5.13,25.8-5.5,25.8-5.5,13.28-8.81,4.75-21.67,4.75-21.67s-1.88,10.46-12.65,18.69a38.08,38.08,0,0,1-9.41,5.19Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="156.82"
          cy="130.75"
          rx="24.29"
          ry="16.72"
          transform="translate(-47.28 136.45) rotate(-41.42)"
        />
        <text className="cls-text" transform="translate(152.98 149.63)">
          13
        </text>
        {allLabels[PointId.P13].length > 0 && (
          <text className="cls-text" transform="translate(123.43 100.61) rotate(-43.67)">
            {allLabels[PointId.P13].join("|")}
          </text>
        )}
      </g>
      <g id="point-12">
        <path
          onClick={handlePointClick(PointId.P12)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P12] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P12] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P12] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P12] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P12]?.selected === true,
          })}
          d="M217.5,135.79a16.61,16.61,0,0,1-9.84-3.06l-.06,0,0-.05c-.16-.15-16.05-15.2-25.45-28.32a16.75,16.75,0,0,1-3.57-12.38c.75-5.93,4.72-11.65,11.81-17,.45-.38,12-9.9,24.32-9.9a20,20,0,0,1,14.62,6.07c.14.15,12.53,13.55,18.24,29.43.07.21,5.42,17.6-8.91,26.48C237.67,127.8,227.81,135.79,217.5,135.79Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected": points[PointId.P12]?.selected === true,
            "cls-pts-border--selected-pontic": anchors[PointId.P12] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P12] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P12] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P12] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
          })}
          d="M214.66,66a19,19,0,0,1,13.91,5.77s12.3,13.2,18,29.07c0,0,5.35,16.77-8.56,25.33,0,0-10.16,8.58-20.52,8.58a15.52,15.52,0,0,1-9.26-2.88s-15.87-15-25.33-28.17c-.31-.44-11.59-13.2,8-28,0,0,11.62-9.7,23.72-9.7m0-2h0c-12.44,0-24.06,9.39-25,10.13-7.29,5.51-11.38,11.44-12.16,17.64a17.71,17.71,0,0,0,3.75,13.09l0,0c9.46,13.19,24.9,27.82,25.56,28.44l.09.09.12.08a17.52,17.52,0,0,0,10.42,3.25c10.47,0,20.43-7.92,21.69-9,14.92-9.29,9.35-27.37,9.3-27.56v0l0,0C242.68,84.13,230.55,71,230,70.45l0,0,0,0A21,21,0,0,0,214.66,64Z"
        />
        <path
          className="cls-pts-shadow"
          d="M206.52,123.86c3,3,5,5,5,5,10,7.13,23.8-4.56,23.8-4.56,11.12-6.84,6.84-20.24,6.84-20.24-.24-.67-.49-1.33-.76-2,0,0-5.07,15.89-25,20.42Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="208.43"
          cy="90.37"
          rx="24.29"
          ry="16.72"
          transform="translate(-15.26 130.03) rotate(-33.5)"
        />
        <text className="cls-text" transform="translate(200.93 108.98)">
          12
        </text>
        {allLabels[PointId.P12].length > 0 && (
          <text className="cls-text" transform="translate(174.68 56.46) rotate(-29.43)">
            {allLabels[PointId.P12].join("|")}
          </text>
        )}
      </g>
      <g id="point-11">
        <path
          onClick={handlePointClick(PointId.P11)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P11] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P11] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P11] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P11] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P11]?.selected === true,
          })}
          d="M267.25,112c-7.7,0-13.56-3-16.93-8.62-.51-.9-12.1-21.68-15.17-35.91-.09-.39-2.07-9.72,3.85-17.88,4.62-6.34,12.66-10.1,23.9-11.16a58.86,58.86,0,0,1,9-.65c15.18,0,24.32,5.48,25.76,15.42.06,1.21,1.27,27.54-1.48,35.66-.23,1.45-3.14,17.28-17.7,21.28A36.28,36.28,0,0,1,267.25,112Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P11] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P11] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P11] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P11] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P11]?.selected === true,
          })}
          d="M271.89,38.76c9.36,0,23,2.18,24.77,14.56,0,0,1.29,27.35-1.46,35.28,0,0-2.27,16.51-17,20.56a35.84,35.84,0,0,1-11,1.82c-5.67,0-12.2-1.66-16.07-8.14,0,0-12-21.36-15.05-35.6,0,0-5.51-24.76,26.86-27.84a59.9,59.9,0,0,1,8.9-.64m0-2h0a60.7,60.7,0,0,0-9.15.66c-11.51,1.1-19.77,5-24.54,11.57-6.19,8.51-4.12,18.27-4,18.68,3.1,14.36,14.77,35.27,15.26,36.15l0,0v0c3.56,6,9.72,9.11,17.79,9.11a37.85,37.85,0,0,0,11.56-1.91c15-4.18,18-19.93,18.34-22,2.77-8.4,1.56-34.72,1.5-35.84V53c-1.52-10.5-11-16.28-26.75-16.28Z"
        />
        <path
          className="cls-pts-shadow"
          d="M257.13,102.2c5.63,9.43,19.21,4.48,19.21,4.48a16,16,0,0,0,10.05-8.54s-7.92,4.63-22.36,4.45Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="267.21"
          cy="60.09"
          rx="24.29"
          ry="16.72"
          transform="translate(-5.11 30.84) rotate(-6.54)"
        />
        <text className="cls-text" transform="translate(255.09 84.85)">
          11
        </text>
        {allLabels[PointId.P11].length > 0 && (
          <text className="cls-text" transform="translate(252.04 23.02) rotate(-9.43)">
            {allLabels[PointId.P11].join("|")}
          </text>
        )}
      </g>
      <g id="point-21">
        <path
          onClick={handlePointClick(PointId.P21)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P21] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P21] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P21] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P21] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P21]?.selected === true,
          })}
          d="M333.6,112a36.8,36.8,0,0,1-11.3-1.88c-14.48-4-17.39-19.81-17.62-21.26-2.74-8.12-1.53-34.45-1.48-35.57,1.44-10,10.59-15.51,25.76-15.51a59.36,59.36,0,0,1,9.07.66c11.17,1,19.21,4.81,23.82,11.15,5.93,8.16,3.94,17.49,3.85,17.89-3.07,14.22-14.66,35-15.15,35.87C347.16,109,341.31,112,333.6,112Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P21] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P21] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P21] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P21] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P21]?.selected === true,
          })}
          d="M329,38.76a59.73,59.73,0,0,1,8.9.64c32.37,3.08,26.87,27.84,26.87,27.84-3.08,14.24-15.06,35.6-15.06,35.6-3.87,6.48-10.4,8.14-16.07,8.14a35.73,35.73,0,0,1-11-1.82c-14.73-4-17-20.56-17-20.56-2.75-7.93-1.45-35.28-1.45-35.28C306,40.94,319.6,38.76,329,38.76m0-2c-15.73,0-25.23,5.78-26.74,16.28l0,.09v.1c-.05,1.12-1.26,27.44,1.5,35.84.33,2.07,3.31,17.82,18.34,22A37.92,37.92,0,0,0,333.6,113c8.08,0,14.23-3.15,17.79-9.11l0,0v0c.49-.88,12.16-21.79,15.26-36.16.09-.4,2.17-10.16-4-18.67-4.78-6.58-13-10.47-24.54-11.57a60.9,60.9,0,0,0-9.16-.66Z"
        />
        <path
          className="cls-pts-shadow"
          d="M343.73,101.2c-5.64,9.43-19.22,4.48-19.22,4.48a16,16,0,0,1-10.05-8.54s7.92,4.63,22.36,4.45Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="333.64"
          cy="59.57"
          rx="16.72"
          ry="24.29"
          transform="translate(225.93 380.92) rotate(-81.59)"
        />
        <text className="cls-text" transform="translate(319.4 84.85)">
          21
        </text>
        {allLabels[PointId.P21].length > 0 && (
          <text className="cls-text" transform="matrix(0.95, 0.32, -0.32, 0.95, 342.12, 20.11)">
            {allLabels[PointId.P21].join("|")}
          </text>
        )}
      </g>
      <g id="point-22">
        <path
          onClick={handlePointClick(PointId.P22)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P22] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P22] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P22] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P22] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P22]?.selected === true,
          })}
          d="M383.36,135.79c-10.31,0-20.18-8-21.11-8.77-14.34-8.88-9-26.27-8.93-26.44,5.72-15.92,18.11-29.32,18.23-29.45A20,20,0,0,1,386.2,65c12.32,0,23.87,9.52,24.35,9.93,7.06,5.32,11,11,11.77,17a16.62,16.62,0,0,1-3.54,12.34c-9.43,13.16-25.32,28.21-25.48,28.36l-.05.05-.05,0a16.61,16.61,0,0,1-9.84,3.06Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P22] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P22] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P22] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P22] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P22]?.selected === true,
          })}
          d="M386.2,66c12.1,0,23.71,9.7,23.71,9.7,19.62,14.8,8.34,27.56,8,28-9.45,13.19-25.33,28.17-25.33,28.17a15.49,15.49,0,0,1-9.25,2.88c-10.37,0-20.53-8.58-20.53-8.58-13.91-8.56-8.56-25.33-8.56-25.33,5.71-15.87,18-29.07,18-29.07A19,19,0,0,1,386.2,66m0-2a21,21,0,0,0-15.33,6.36l0,0,0,0c-.51.55-12.65,13.68-18.43,29.76v.06c-.06.19-5.62,18.27,9.29,27.56,1.26,1,11.22,9,21.7,9a17.56,17.56,0,0,0,10.42-3.25l.11-.08.1-.09c.65-.62,16.1-15.25,25.55-28.44l0,0a17.67,17.67,0,0,0,3.74-13.09c-.77-6.2-4.86-12.13-12.15-17.64-.91-.75-12.52-10.13-25-10.13Z"
        />
        <path
          className="cls-pts-shadow"
          d="M392.61,123.17c-2.68,2.74-4.56,4.52-4.56,4.52-9,6.45-21.56-4.14-21.56-4.14-10.07-6.19-6.19-18.33-6.19-18.33.21-.6.44-1.2.68-1.79,0,0,4.6,14.4,22.67,18.49Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="394.11"
          cy="89.71"
          rx="16.72"
          ry="24.29"
          transform="translate(111.72 378.9) rotate(-58.51)"
        />
        <text className="cls-text" transform="translate(374.09 107.45)">
          22
        </text>
        {allLabels[PointId.P22].length > 0 && (
          <text className="cls-text" transform="matrix(0.79, 0.62, -0.62, 0.79, 419.42, 52.27)">
            {allLabels[PointId.P22].join("|")}
          </text>
        )}
      </g>
      <g id="point-23">
        <path
          onClick={handlePointClick(PointId.P23)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P23] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P23] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P23] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P23] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P23]?.selected === true,
          })}
          d="M430.28,173.62c-12.56,0-24-8.62-24.47-9-14-9.26-10.22-23-6.28-28.92,4.25-7.61,23-26.22,23.78-27l.06-.06.06,0a21.27,21.27,0,0,1,12.74-4.16c13.16,0,24.69,11.07,25.18,11.54,15.83,14.74,6.07,33.54,6,33.73l-.06.12-.1.1c-14.45,15.23-23.39,19.66-24.34,20.1a22.76,22.76,0,0,1-12.54,3.57Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P23] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P23] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P23] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P23] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P23]?.selected === true,
          })}
          d="M436.17,105.45c12.91,0,24.48,11.27,24.48,11.27,15.47,14.4,5.79,32.54,5.79,32.54-15,15.83-24.11,19.92-24.11,19.92a21.75,21.75,0,0,1-12.05,3.44c-12.37,0-23.87-8.78-23.87-8.78-16.89-11.2-6-27.56-6-27.56,4.26-7.65,23.65-26.85,23.65-26.85a20.27,20.27,0,0,1,12.15-4m0-2a22.4,22.4,0,0,0-13.33,4.35l-.12.1-.11.1c-.8.8-19.56,19.43-24,27.26-.85,1.35-11,18.47,6.6,30.21,1,.76,12.45,9.15,25,9.15a23.75,23.75,0,0,0,13-3.69c1.3-.62,10.33-5.26,24.59-20.29l.18-.2.13-.24a31.92,31.92,0,0,0,3.14-11.66A28,28,0,0,0,462,115.25c-.46-.45-12.28-11.8-25.84-11.8Z"
        />
        <path
          className="cls-pts-shadow"
          d="M433.18,166.16c-10.78,4.66-23.43-5-23.43-5-12.07-8-4.32-19.69-4.32-19.69s1.71,9.5,11.49,17a34.45,34.45,0,0,0,8.55,4.71Z"
        />
        <ellipse
          className="cls-pts-shadow"
          cx="443.98"
          cy="130.75"
          rx="16.72"
          ry="24.29"
          transform="translate(56.64 384.09) rotate(-49.6)"
        />
        <text className="cls-text" transform="translate(418.08 146.91)">
          23
        </text>
        {allLabels[PointId.P23].length > 0 && (
          <text className="cls-text" transform="translate(469.65 94.19) rotate(46.33)">
            {allLabels[PointId.P23].join("|")}
          </text>
        )}
      </g>
      <g id="point-24">
        <path
          onClick={handlePointClick(PointId.P24)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P24] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P24] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P24] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P24] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P24]?.selected === true,
          })}
          d="M456.76,224A31.37,31.37,0,0,1,443,220.83c-20.06-8.71-14.63-29.11-14.58-29.31,6-16.05,39.38-31.19,40.79-31.83l.08,0,.07,0a30.62,30.62,0,0,1,7.95-1.11c19.25,0,25.57,21.54,25.63,21.76,6.18,16.28-2.42,24.63-2.79,25l-.1.1-15.71,8.26-14.78,7.24A27,27,0,0,1,456.76,224Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P24] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P24] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P24] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P24] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P24]?.selected === true,
          })}
          d="M477.34,159.52c18.72,0,24.67,21,24.67,21,6,15.93-2.51,24-2.51,24l-15.6,8.2L469.15,220A26.1,26.1,0,0,1,456.76,223,30.63,30.63,0,0,1,443.5,220c-19.57-8.5-14.08-28.17-14.08-28.17,5.87-15.76,40.23-31.18,40.23-31.18a29.43,29.43,0,0,1,7.69-1.08m0-2a31.77,31.77,0,0,0-8.21,1.15l-.15,0-.14.07c-1.44.64-35.21,15.95-41.3,32.31l0,.08,0,.08c-.06.21-5.7,21.4,15.14,30.5A32.67,32.67,0,0,0,456.76,225a28.06,28.06,0,0,0,13.3-3.27l14.72-7.21h.06l15.59-8.2.24-.13.2-.19c.39-.36,9.42-9.13,3-26.06a39.45,39.45,0,0,0-5.6-11.06c-5.4-7.42-12.65-11.34-21-11.34Z"
        />
        <path
          className="cls-pts-shadow"
          d="M458.65,216.5a21.45,21.45,0,0,1-14.93-1.42c-14.05-6.1-10.11-20.22-10.11-20.22a20,20,0,0,1,4.71-6.76A31.56,31.56,0,0,0,453,213.17Z"
        />
        <text className="cls-text" transform="translate(454.75 199.69)">
          24
        </text>
        {allLabels[PointId.P24].length > 0 && (
          <text className="cls-text" transform="matrix(0.49, 0.87, -0.87, 0.49, 520.99, 158.96)">
            {allLabels[PointId.P24].join("|")}
          </text>
        )}
      </g>
      <g id="point-25">
        <path
          onClick={handlePointClick(PointId.P25)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P25] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P25] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P25] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P25] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P25]?.selected === true,
          })}
          d="M482.28,276.15c-15.42-.9-22.76-7.11-23.5-7.78-15.08-10.66-6.63-31.91-6.54-32.12l.08-.19.14-.14c16-16.16,42-22.95,42.29-23a21.83,21.83,0,0,1,6.92-1.18c7.11,0,13.53,4.06,18.56,11.76a48,48,0,0,1,5.45,11.57c2.87,7.6,3,14.35.48,20a21,21,0,0,1-10.82,10.4c-21.47,8.55-32.37,10.6-32.82,10.68l-.12,0Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P25] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P25] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P25] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P25] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P25]?.selected === true,
          })}
          d="M501.67,212.73c16.52,0,23.05,22.61,23.05,22.61,8.44,22.38-9.73,29.17-9.73,29.17-21.65,8.62-32.65,10.64-32.65,10.64-15.78-.92-22.94-7.56-22.94-7.56-14.67-10.31-6.23-31-6.23-31,16-16.14,41.83-22.75,41.83-22.75a21.1,21.1,0,0,1,6.67-1.14m0-2a23.25,23.25,0,0,0-7.24,1.22c-1.82.48-26.78,7.18-42.69,23.27l-.27.28-.15.37a39.85,39.85,0,0,0-2.54,12.28c-.39,9.12,2.86,16.38,9.38,21,1,.91,8.63,7.09,24.06,8h.24l.24,0c.46-.09,11.45-2.16,33-10.75a22.13,22.13,0,0,0,11.34-10.91c2.66-5.87,2.51-12.85-.46-20.76a48.5,48.5,0,0,0-5.55-11.76c-5.22-8-11.93-12.21-19.39-12.21Z"
        />
        <path
          className="cls-pts-shadow"
          d="M477.86,269.14c-11.3-.66-16.43-5.42-16.43-5.42-10.52-7.38-4.47-22.18-4.47-22.18,1-1,2-1.89,3-2.77,0,0-1.69,17.29,13,27.5Z"
        />
        <text className="cls-text" transform="translate(475.43 253.96)">
          25
        </text>
        {allLabels[PointId.P25].length > 0 && (
          <text className="cls-text" transform="matrix(0.438448, 0.898757, -0.898757, 0.438448, 541.718628, 203.338181)">
            {allLabels[PointId.P25].join("|")}
          </text>
        )}
      </g>
      <g id="point-26">
        <path
          onClick={handlePointClick(PointId.P26)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P26] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P26] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P26] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P26] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P26]?.selected === true,
          })}
          d="M503.74,341.88c-17.79,0-24.38-7.15-25.16-8.07-10.75-9.14-13.8-18.55-14.47-24.84a26.71,26.71,0,0,1,1.15-11.6c1.54-11.43,12.08-15.83,12.53-16l25.55-10.5c7.08-2.84,13.52-4.27,19.15-4.27,14.89,0,19.33,9.77,19.51,10.19,5.85,17.89,6.42,28.85,6.44,29.31,1.51,11-2.25,18.52-5.68,22.91a26.75,26.75,0,0,1-7.92,7l-.07,0-.08,0a187.3,187.3,0,0,1-20.86,5.15,78.87,78.87,0,0,1-10.09.69Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P26] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P26] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P26] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P26] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P26]?.selected === true,
          })}
          d="M522.49,267.59c14.41,0,18.59,9.58,18.59,9.58,5.85,17.89,6.36,29,6.36,29,2.84,20.73-13.06,29-13.06,29a182.17,182.17,0,0,1-20.73,5.11,75.83,75.83,0,0,1-9.91.68c-18.34,0-24.44-7.77-24.44-7.77-20.44-17.32-13.06-35.49-13.06-35.49,1.42-11.08,11.92-15.33,11.92-15.33l25.56-10.51c7.63-3,13.81-4.19,18.77-4.19m0-2c-5.76,0-12.33,1.46-19.52,4.33L477.4,280.44c-.46.18-11.45,4.77-13.11,16.69a27.79,27.79,0,0,0-1.17,11.95c.68,6.46,3.8,16.1,14.76,25.44,1.11,1.28,8.1,8.36,25.86,8.36a79.35,79.35,0,0,0,10.17-.69h.1A182.34,182.34,0,0,0,535,337l.15-.05.15-.08a27.85,27.85,0,0,0,8.25-7.25c3.53-4.54,7.41-12.32,5.88-23.67-.06-1.2-.8-12.12-6.45-29.4l0-.09,0-.08c-.19-.45-4.88-10.79-20.42-10.79Z"
        />
        <path
          className="cls-pts-shadow"
          d="M498,334.36c-10.1.58-17.7-5.79-17.7-5.79-15.71-13.31-10-27.27-10-27.27a14.21,14.21,0,0,1,4.52-8.66,52.07,52.07,0,0,0,1.65,7.73c3.68,13.06,9.29,23.88,19.64,32.66Z"
        />
        <text className="cls-text" transform="translate(494.17 313.52)">
          26
        </text>
        {allLabels[PointId.P26].length > 0 && (
          <text className="cls-text" transform="translate(569.17 283.66) rotate(76.73)">
            {allLabels[PointId.P26].join("|")}
          </text>
        )}
      </g>
      <g id="point-27">
        <path
          onClick={handlePointClick(PointId.P27)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P27] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P27] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P27] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P27] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P27]?.selected === true,
          })}
          d="M525.19,410c-13.19,0-25.32-.93-25.44-.94-26.2-1.94-25.31-27.88-25.23-29.52-1.75-25.24,20.86-34,21.08-34.05l.06,0,0,0a107.24,107.24,0,0,1,25.58-2.68,175.41,175.41,0,0,1,23.6,1.61c7.46,1.29,13.16,7.28,16.51,17.3a59.52,59.52,0,0,1,2.82,14.66c0,13.49-5.18,21.63-9.52,26.1a27.27,27.27,0,0,1-9.64,6.57l-.1,0h-.11a171.07,171.07,0,0,1-19.66.95Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P27] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P27] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P27] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P27] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P27]?.selected === true,
          })}
          d="M521.29,343.78a173,173,0,0,1,23.44,1.6c17.26,3,18.5,31,18.5,31,0,25.38-18.5,31.68-18.5,31.68a171.53,171.53,0,0,1-19.54.94c-13.31,0-25.36-.94-25.36-.94-26.24-1.94-24.3-28.53-24.3-28.53C473.76,354.71,496,346.43,496,346.43a107.6,107.6,0,0,1,25.34-2.65m0-2a108.2,108.2,0,0,0-25.82,2.71l-.11,0-.11,0a36.91,36.91,0,0,0-11.56,7.68c-5.15,5-11.08,13.79-10.17,27.31A35.66,35.66,0,0,0,476,393.83c2.77,6.81,9.12,15.16,23.7,16.24.11,0,12.28.94,25.51.94a173.34,173.34,0,0,0,19.78-.95l.21,0,.2-.07a28.06,28.06,0,0,0,10-6.81c4.48-4.6,9.82-13,9.82-26.76v-.08a60.81,60.81,0,0,0-2.87-14.94c-3.47-10.39-9.45-16.61-17.27-18a171.93,171.93,0,0,0-23.79-1.63Z"
        />
        <path
          className="cls-pts-shadow"
          d="M496.07,399c-18.52-1.37-17.16-20.14-17.16-20.14-1.24-17.53,14.43-23.38,14.43-23.38s-3.75,10.42-1.33,33A22.07,22.07,0,0,0,496.07,399Z"
        />
        <text className="cls-text" transform="translate(506.36 386.38)">
          27
        </text>
        {allLabels[PointId.P27].length > 0 && (
          <text className="cls-text" transform="matrix(-0.017452, 0.999848, 0.999848, 0.017452, 596.39209, 351.449677)">
            {allLabels[PointId.P27].join("|")}
          </text>
        )}
      </g>
      <g id="point-28">
        <path
          onClick={handlePointClick(PointId.P28)}
          className={classNames("cls-pts-fill", {
            "cls-pts-fill--selected-pontic": anchors[PointId.P28] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-fill--cemented-to-prepared-tooth":
              anchors[PointId.P28] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-fill--cemented-to-implant":
              anchors[PointId.P28] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-fill--screwed-in-to-implant":
              anchors[PointId.P28] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-fill--selected": points[PointId.P28]?.selected === true,
          })}
          d="M528.11,488c-14.28,0-27.39-1-27.53-1-28.3-2.09-27.31-30.15-27.22-31.86-1.89-27.26,22.51-36.69,22.76-36.78l.11,0a115.58,115.58,0,0,1,27.66-2.91,188.7,188.7,0,0,1,25.52,1.75c8.05,1.39,14.2,7.85,17.81,18.67a64.22,64.22,0,0,1,3,15.84c0,14.57-5.59,23.36-10.28,28.18a29.25,29.25,0,0,1-10.4,7.09l-.1,0h-.11a185.57,185.57,0,0,1-21.27,1Z"
        />
        <path
          className={classNames("cls-pts-border", {
            "cls-pts-border--selected-pontic": anchors[PointId.P28] === AnchorType.Pontic && anchorOptions.visible,
            "cls-pts-border--cemented-to-prepared-tooth":
              anchors[PointId.P28] === AnchorType.CementedToPreparedTooth && anchorOptions.visible,
            "cls-pts-border--cemented-to-implant":
              anchors[PointId.P28] === AnchorType.CementedToImplant && anchorOptions.visible,
            "cls-pts-border--screwed-in-to-implant":
              anchors[PointId.P28] === AnchorType.ScrewedInToImplant && anchorOptions.visible,
            "cls-pts-border--selected": points[PointId.P28]?.selected === true,
          })}
          d="M523.89,416.44a187.39,187.39,0,0,1,25.37,1.73c18.68,3.24,20,33.57,20,33.57,0,27.47-20,34.29-20,34.29a187.57,187.57,0,0,1-21.15,1c-14.4,0-27.45-1-27.45-1-28.4-2.1-26.3-30.88-26.3-30.88-1.9-26.87,22.11-35.83,22.11-35.83,8.8-2.2,18.64-2.88,27.42-2.88m0-2A116.69,116.69,0,0,0,496,417.38l-.1,0-.11,0a39.83,39.83,0,0,0-12.46,8.28c-5.55,5.39-11.94,14.86-10.95,29.44A38.1,38.1,0,0,0,475,470.55c3,7.33,9.81,16.31,25.51,17.47.12,0,13.28,1,27.59,1a187.69,187.69,0,0,0,21.39-1l.21,0,.2-.07a30.32,30.32,0,0,0,10.79-7.34c4.83-4.95,10.58-14,10.58-28.84v-.09a65.08,65.08,0,0,0-3.1-16.11c-3.74-11.19-10.16-17.88-18.56-19.34a189.05,189.05,0,0,0-25.72-1.76Z"
        />
        <path
          className="cls-pts-shadow"
          d="M497.88,476.71c-20.27-1.5-18.77-22-18.77-22-1-14.67,8.74-21.86,13.4-24.43,0,0-3.7,23.23,2.93,41.13Z"
        />
        <text className="cls-text" transform="translate(507.54 462.14)">
          28
        </text>
        {allLabels[PointId.P28].length > 0 && (
          <text className="cls-text" transform="translate(579.5 445) rotate(93.37)">
            {allLabels[PointId.P28].join("|")}
          </text>
        )}
      </g>
      {dotOptions.visible === true && (
        <>
          {dots[DotId.P18_P17]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P18_P17)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P18_P17]?.selected === true })}
              cx="150.1"
              cy="409.01"
              r="14"
            />
          )}
          {dots[DotId.P17_P16]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P17_P16)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P17_P16]?.selected === true })}
              cx="156.82"
              cy="347.48"
              r="14"
            />
          )}
          {dots[DotId.P16_P15]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P16_P15)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P16_P15]?.selected === true })}
              cx="165.1"
              cy="293.61"
              r="14"
            />
          )}
          {dots[DotId.P15_P14]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P15_P14)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P15_P14]?.selected === true })}
              cx="182.77"
              cy="239.73"
              r="14"
            />
          )}
          {dots[DotId.P14_P13]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P14_P13)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P14_P13]?.selected === true })}
              cx="197.77"
              cy="195"
              r="14"
            />
          )}
          {dots[DotId.P13_P12]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P13_P12)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P13_P12]?.selected === true })}
              cx="226.18"
              cy="161.26"
              r="14"
            />
          )}
          {dots[DotId.P12_P11]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P12_P11)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P12_P11]?.selected === true })}
              cx="263.59"
              cy="141.81"
              r="14"
            />
          )}
          {dots[DotId.P11_P21]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P11_P21)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P11_P21]?.selected === true })}
              cx="303.89"
              cy="137.49"
              r="14"
            />
          )}
          {dots[DotId.P21_P22]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P21_P22)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P21_P22]?.selected === true })}
              cx="340.59"
              cy="147.93"
              r="14"
            />
          )}
          {dots[DotId.P22_P23]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P22_P23)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P22_P23]?.selected === true })}
              cx="370.8"
              cy="171.62"
              r="14"
            />
          )}
          {dots[DotId.P23_P24]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P23_P24)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P23_P24]?.selected === true })}
              cx="399.31"
              cy="199.68"
              r="14"
            />
          )}
          {dots[DotId.P24_P25]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P24_P25)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P24_P25]?.selected === true })}
              cx="420.26"
              cy="243.94"
              r="14"
            />
          )}
          {dots[DotId.P25_P26]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P25_P26)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P25_P26]?.selected === true })}
              cx="440.23"
              cy="294.34"
              r="14"
            />
          )}
          {dots[DotId.P26_P27]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P26_P27)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P26_P27]?.selected === true })}
              cx="448.18"
              cy="354.26"
              r="14"
            />
          )}
          {dots[DotId.P27_P28]?.visible === true && (
            <circle
              onClick={handleDotClick(DotId.P27_P28)}
              className={classNames("cls-dot", { "cls-dot--selected": dots[DotId.P27_P28]?.selected === true })}
              cx="450.75"
              cy="412.67"
              r="14"
            />
          )}
        </>
      )}
    </svg>
  );
};

export default UpperJawView;
