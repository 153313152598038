import * as React from "react";
import UserMenu from "features/layout/user-menu/UserMenu";
import { Layout as AntLayout, Menu } from "antd";
import { MenuState } from "features/layout/MenuState";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import "./header.scss";

const { Header: AntHeader } = AntLayout;

interface IHeaderProps {
  toggleMenu: () => void;
  menuState: MenuState;
}

const Header: React.FunctionComponent<IHeaderProps> = ({ toggleMenu, menuState, ...props }) => (
  <AntHeader className="header">
    <Menu mode="horizontal" style={{ lineHeight: "64px" }} selectable={false}>
      <Menu.Item key={"toggler"} className="header__menu-toggle" onClick={toggleMenu}>
        {menuState === MenuState.Shown ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </Menu.Item>
    </Menu>
    <div className="header__user-menu">
      <UserMenu />
    </div>
  </AntHeader>
);

export default Header;
