import { Country } from "core/countries/countries.model";
import { Gender } from "core/gender/gender.model";

export enum PatientEntryMode {
  PatientName = "patient-name",
  PatientCode = "patient-code",
}

export interface PatientName {
  firstName: string | null;
  lastName: string | null;
  patientCode: string | null;
}

export interface Patient extends PatientName {
  readonly gender: Gender;
  readonly age: number | null;
  readonly country: Country | null;
}
