import { FC, useCallback, useContext, useMemo, useState } from "react";
import { Button, Form, Input, Result, Switch, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { useParams } from "react-router";
import { InviteRouteParams, Path } from "core/routes/routes";
import { useSearchParam } from "react-use";
import { AuthPageLayout } from "features/auth/components/auth-page-layout/AuthPageLayout";
import {
  IRegisterDentistRequest,
  IRegisterEmployeeRequest,
  IRegisterRequestBase,
} from "features/register/register.model";
import { useForm } from "antd/es/form/Form";
import { RegisterServiceContext } from "features/register/register.service";
import { useStateAsync } from "hooks/useStateAsync";
import CountrySelect from "components/forms/country-select/CountrySelect";
import { Country } from "core/countries/countries.model";
import { mapCountryToCountryCode } from "core/countries/countries.functions";
import { AxiosResponse } from "axios";
import { Helmet } from "react-helmet";

export enum RegisterMode {
  Dentist = "dentist",
  Employee = "employee",
}

interface IRegisterByInvitePageProps {
  mode: RegisterMode;
}

type IRegisterByInviteValues = Omit<IRegisterRequestBase, "country"> & { country?: Country };

const RegisterByInvitePage: FC<IRegisterByInvitePageProps> = ({ mode }) => {
  const registerService = useContext(RegisterServiceContext);
  const [form] = useForm<IRegisterByInviteValues>();
  const { dentistId, employeeId } = useParams() as InviteRouteParams;
  const token = useSearchParam("token");
  const tenantId = useSearchParam("tenantId");
  const emailEncoded = useSearchParam("email");
  const [loading, setLoading] = useStateAsync(false);
  const [created, setCreated] = useStateAsync(false);
  const [taxInfo, setTaxInfo] = useState(false);

  const email = useMemo(() => (emailEncoded ? atob(emailEncoded) : null), [emailEncoded]);

  const initialValues: IRegisterByInviteValues = useMemo(
    () => ({
      token: token!,
      tenantId: tenantId!,
      email: email!,
      password: "",
      name: "",
      surname: "",
      clinicName: undefined,
      companyName: undefined,
      taxId: undefined,
      country: undefined,
      line1: undefined,
      line2: undefined,
      line3: undefined,
      line4: undefined,
      postalCode: undefined,
    }),
    [email, tenantId, token]
  );

  const register = useCallback(
    async (values: IRegisterByInviteValues) => {
      await setLoading(true);

      const mapped: IRegisterDentistRequest & IRegisterEmployeeRequest = {
        ...values,
        token,
        tenantId,
        ...(dentistId ? { dentistId } : {}),
        ...(employeeId ? { employeeId } : {}),
        ...(values.country ? { country: mapCountryToCountryCode(values.country) } : {}),
      } as any;

      try {
        let resp: AxiosResponse;

        switch (mode) {
          case RegisterMode.Dentist:
            resp = await registerService.registerDentist(mapped);
            break;
          case RegisterMode.Employee:
            resp = await registerService.registerEmployee(mapped);
            break;
        }

        await setCreated(true);
      } catch (e) {
        console.error(e);
      } finally {
        await setLoading(false);
      }
    },
    [dentistId, employeeId, mode, registerService, setCreated, setLoading, tenantId, token]
  );

  const goBackHref = useMemo(() => {
    return Path.Root;
  }, []);

  const loginHref = useMemo(() => {
    return Path.Login;
  }, []);

  return (
    <>
      <FormattedMessage id={"common.invitation"} defaultMessage={"Zaproszenie"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AuthPageLayout>
        <Typography.Text strong>
          <FormattedMessage id={"register-by-invite-page.rejestracja"} defaultMessage={"Rejestracja"} />
        </Typography.Text>
        {(!email || !token || !tenantId) && !created && (
          <Result
            status="error"
            title={
              <FormattedMessage
                id="register-by-invite-page.invite-url-corrupted"
                defaultMessage="E-mail lub token jest niepoprawny"
              />
            }
            subTitle={
              <FormattedMessage
                id="register-by-invite-page.url-corrupted-info"
                defaultMessage="Prawdopodobnie link w zaproszeniu jest niepoprawny."
              />
            }
          />
        )}
        {created && (
          <Result
            status="success"
            title={
              <FormattedMessage id="register-by-invite-page.user-created" defaultMessage="Utworzono użytkownika" />
            }
            subTitle={
              <>
                <FormattedMessage
                  id="register-by-invite-page.user-created-info"
                  defaultMessage="Można się zalogować używając adresu e-mail: "
                />
                <Typography.Text strong>{email}</Typography.Text>
              </>
            }
            extra={
              <Button type="primary" href={loginHref}>
                <FormattedMessage id="register-by-invite-page.login" defaultMessage="Przejdź do logowania" />
              </Button>
            }
          />
        )}
        {!!email && !!token && tenantId && !created && (
          <Form layout={"vertical"} form={form} initialValues={initialValues} onFinish={register}>
            <Form.Item
              className="mt-4"
              name="email"
              label={<FormattedMessage id="register-by-invite-page.email" defaultMessage="Email" />}
            >
              <Input type="email" disabled />
            </Form.Item>
            <Form.Item
              className="mt-4"
              required
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="register-by-invite-page.provide-name" defaultMessage="Proszę podać imię" />
                  ),
                },
              ]}
              name="name"
              label={<FormattedMessage id="register-by-invite-page.name" defaultMessage="Imię" />}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mt-4"
              required
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="register-by-invite-page.provide-surname"
                      defaultMessage="Proszę podać nazwisko"
                    />
                  ),
                },
              ]}
              name="surname"
              label={<FormattedMessage id="register-by-invite-page.surname" defaultMessage="Nazwisko" />}
            >
              <Input autoComplete={"none"} />
            </Form.Item>
            <Form.Item
              className="mt-4"
              required
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="register-by-invite-page.provide-password"
                      defaultMessage="Proszę podać hasło"
                    />
                  ),
                },
              ]}
              name="password"
              label={<FormattedMessage id="register-by-invite-page.password" defaultMessage="Hasło" />}
            >
              <Input type="password" autoComplete={"nope"} />
            </Form.Item>

            {mode === RegisterMode.Dentist && (
              <Form.Item
                className="mt-4"
                label={<FormattedMessage id="register-by-invite-page.tax-info" defaultMessage="Dane do faktury" />}
              >
                <Switch checked={taxInfo} onChange={setTaxInfo} />
              </Form.Item>
            )}

            {mode === RegisterMode.Dentist && taxInfo && (
              <>
                <Form.Item
                  className="mt-4"
                  name="companyName"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="register-by-invite-page.provide-companyName"
                          defaultMessage="Proszę podać nazwę firmy"
                        />
                      ),
                    },
                  ]}
                  label={<FormattedMessage id="register-by-invite-page.companyName" defaultMessage="Nazwa firmy" />}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="mt-4"
                  name="taxId"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="register-by-invite-page.provide-taxId"
                          defaultMessage="Proszę podać numer NIP"
                        />
                      ),
                    },
                  ]}
                  label={<FormattedMessage id="register-by-invite-page.taxId" defaultMessage="NIP" />}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="common.street" defaultMessage="Ulica" />}
                  name="line1"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="register-by-invite-page.provide-street"
                          defaultMessage="Proszę podać ulicę"
                        />
                      ),
                    },
                  ]}
                >
                  <Input autoComplete={"nope"} />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="common.apartment-and-building" defaultMessage="Numer mieszkania" />}
                  name={"line2"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="common.provide-apartment-and-building"
                          defaultMessage="Proszę podać numer mieszkania"
                        />
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="common.postalCode" defaultMessage="Kod pocztowy" />}
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="common.provide-postal-code" defaultMessage="Proszę podać kod pocztowy" />
                      ),
                    },
                  ]}
                >
                  <Input autoComplete={"nope"} />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="common.city" defaultMessage="Miasto" />}
                  name="line3"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="common.provide-city" defaultMessage="Proszę podać miasto" />,
                    },
                  ]}
                >
                  <Input autoComplete={"nope"} />
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage id="common.region" defaultMessage="Województwo" />}
                  name="line4"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="common.provide-region" defaultMessage="Proszę podać województwo" />
                      ),
                    },
                  ]}
                >
                  <Input autoComplete={"nope"} />
                </Form.Item>
                <Form.Item
                  className="mt-4"
                  name="country"
                  required
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="register-by-invite-page.provide-country"
                          defaultMessage="Proszę podać kraj"
                        />
                      ),
                    },
                  ]}
                  label={<FormattedMessage id="register-by-invite-page.country" defaultMessage="Kraj" />}
                >
                  <CountrySelect />
                </Form.Item>
              </>
            )}

            <Form.Item className="mt-2">
              <div className="flex">
                <Button type="default" href={goBackHref} className="ml-auto mr-2">
                  <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  // disabled={!valid}
                >
                  <FormattedMessage id="register-by-invite-page.register" defaultMessage="Rejestruj" />
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </AuthPageLayout>
    </>
  );
};

export default RegisterByInvitePage;
