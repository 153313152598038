import {
  IRegisterDentistRequest,
  IRegisterEmployeeRequest,
  ISendDentistInvitationRequest,
  ISendEmployeeInvitationRequest,
} from "./register.model";
import { IHttpService } from "core/http/http.service";
import { IAuthService } from "features/auth/auth.service";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { createContext } from "react";
import { AxiosResponse } from "axios";

interface IRegisterService {
  inviteDentist(request: ISendDentistInvitationRequest): Promise<AxiosResponse<void>>
  registerDentist(request: IRegisterDentistRequest): Promise<AxiosResponse<void>>
  inviteEmployee(request: ISendEmployeeInvitationRequest): Promise<AxiosResponse<void>>
  registerEmployee(request: IRegisterEmployeeRequest): Promise<AxiosResponse<void>>
}

class RegisterService implements IRegisterService {
  private readonly httpService: IHttpService;
  private readonly authService: IAuthService;

  constructor(httpService: IHttpService, authService: IAuthService) {
    this.httpService = httpService;
    this.authService = authService;
  }

  inviteDentist = async (request: ISendDentistInvitationRequest) => {
    const { tenantId } = await this.authService.getTenantId();

    return await this.httpService.post<void, ISendDentistInvitationRequest>(parametrizeEndpointPath({
      path: ApiEndpointPath.SendDentistInvitation,
      params: { tenantId: tenantId!.value },
    }), { ...request, labName: "abc"} as any);
  }

  registerDentist = async (request: IRegisterDentistRequest) => {
    return await this.httpService.put<void, IRegisterDentistRequest>(ApiEndpointPath.RegisterDentist, request);
  }

  inviteEmployee = async (request: ISendEmployeeInvitationRequest) => {
    const { tenantId } = await this.authService.getTenantId();

    return await this.httpService.post<void, ISendEmployeeInvitationRequest>(parametrizeEndpointPath({
      path: ApiEndpointPath.SendEmployeeInvitation,
      params: { tenantId: tenantId!.value },
    }), request);
  }

  registerEmployee = async (request: IRegisterEmployeeRequest) => {
    return await this.httpService.put<void, IRegisterEmployeeRequest>(ApiEndpointPath.RegisterEmployee, request);
  }
}

export const RegisterServiceContext = createContext<IRegisterService>(null as never);

export default RegisterService;