import {
  AnchorType,
  BridgeAnchorType,
  CrownAnchorType,
  FoundationAnchorType,
  MarylandAnchorType,
} from "./anchor-type.models";

export const allPossibleCrownAnchorTypes: CrownAnchorType[] = [
  AnchorType.CementedToImplant,
  AnchorType.ScrewedInToImplant,
  AnchorType.CementedToPreparedTooth,
];

export const allPossibleBridgeAnchorTypes: BridgeAnchorType[] = [
  AnchorType.CementedToImplant,
  AnchorType.ScrewedInToImplant,
  AnchorType.CementedToPreparedTooth,
  AnchorType.Pontic,
];

export const allPossibleFoundationAnchorTypes: FoundationAnchorType[] = [
  AnchorType.CementedToImplant,
  AnchorType.ScrewedInToImplant,
  AnchorType.Pontic,
];

export const allPossibleMarylandBridgeAnchorTypes: MarylandAnchorType[] = [
  AnchorType.CementedToPreparedTooth,
  AnchorType.Pontic,
];
