import useAuth from "features/auth/hooks/useAuth";
import { UserRights } from "features/auth/contracts/auth-contracts.dto";
import { useMemo } from "react";
import { AccountType } from "features/auth/auth.model";
import { Status } from "features/orders/status/order-status.model";

// noinspection JSUnusedLocalSymbols
const {
  READ_ORDERS,
  WRITE_ORDERS,
  ORDER_ASSIGNMENT_FOR_ALL,
  ORDER_ASSIGNMENT_FOR_SELF,
  READ_ORDER_PRICES,
  WRITE_ORDER_PRICES,
  READ_INVOICES,
  WRITE_INVOICES,
  READ_DENTISTS,
  WRITE_DENTISTS,
  INVITE_DENTISTS,
  READ_EMPLOYEES,
  WRITE_EMPLOYEES,
  INVITE_EMPLOYEES,
  READ_PROCEDURES,
  WRITE_PROCEDURES,
  READ_PRICE_LISTS,
  WRITE_PRICE_LISTS,
} = UserRights;

function useAccess() {
  const { authContext: ctx } = useAuth();

  // noinspection UnnecessaryLocalVariableJS
  const access = useMemo(
    () => ({
      isDentist: ctx.accountType === AccountType.Dentist,
      isLabAccount: ctx.accountType === AccountType.LabOwner || ctx.accountType === AccountType.LabEmployee,
      isLabOwner: ctx.accountType === AccountType.LabOwner,
      isLabEmployee: ctx.accountType === AccountType.LabEmployee,
      toInvoices: ctx.hasRight(READ_INVOICES),
      toLabClients: ctx.isLabOwner || ctx.isLabEmployee,
      toLabEmployees: ctx.isLabOwner || ctx.isLabEmployee,
      toOrderCatalogue: ctx.isLabOwner || ctx.isLabEmployee,
      toPricingLists: ctx.isLabOwner || ctx.isLabEmployee,
      toSubscriptions: false, //toSubscriptions: ctx.isLabOwner,
      toSettings: false, //toSettings: ctx.isLabOwner,
      toChat: false, //toSettings: ctx.isLabOwner,
      canDeleteOrder: (orderStatus: Status) => {
        switch (ctx.accountType) {
          case AccountType.LabOwner:
          case AccountType.LabEmployee:
            return true;
          case AccountType.Dentist:
            return (
              (orderStatus === Status.ReadyForPickUpFromClinic || orderStatus === Status.TodoInLab) &&
              ctx.hasRight(WRITE_ORDERS)
            );
          default:
            return false;
        }
      },
      canEditOrders: ctx.hasRight(WRITE_ORDERS),
      canEditFinishDate: ctx.accountType === AccountType.LabEmployee || ctx.accountType === AccountType.LabOwner,
      canChangeOrderStatus: ctx.hasRight(WRITE_ORDERS) && ctx.accountType !== AccountType.Dentist,
      canReadOrderPrices: ctx.hasRight(READ_ORDER_PRICES),
      canEditOrderPrices: (ctx.isLabOwner || ctx.isLabEmployee) && ctx.hasRight(WRITE_ORDER_PRICES),
      canReadInvoices: ctx.hasRight(READ_INVOICES),
      canWriteInvoices: ctx.hasRight(WRITE_INVOICES),
    }),
    [ctx]
  );

  return access;
}

export default useAccess;
