import { IResult } from "core/lib/types/result";
import { ILab } from "../dental-lab.model";
import { IDentalLabService } from "./IDentalLabService";
import { IJsonApiService } from "core/http/json-api.service";
import { GetLabResponseDTO } from "../contracts/dental-lab.responses";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { mapDentalLabDTOtoDentalLab } from "../contracts/dental-lab.mappers";

class DentalLabService implements IDentalLabService {
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.jsonApi = jsonApi;
  }

  getActiveDentalLab = async (): Promise<IResult<ILab>> => {
    const result = await this.jsonApi.get<GetLabResponseDTO>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetDentalLab,
        params: { labId: labId!.value },
      })
    );

    return result.map((response) => mapDentalLabDTOtoDentalLab(response.data.result));
  };
}

export default DentalLabService;
