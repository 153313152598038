import * as React from "react";
import AuthContext from "features/auth/auth-context";
import useAuth from "features/auth/hooks/useAuth";

type Predicate = (authContext: AuthContext) => boolean;

interface IAccessProps {
  accessible: boolean | Predicate | Predicate[];
  fallback?: React.ReactNode;
}

const Access: React.FC<IAccessProps> = ({ accessible, fallback, children }) => {
  const { authContext } = useAuth();

  if (typeof accessible === "boolean") {
    if (accessible) {
      return children as any;
    } else if (React.isValidElement(fallback)) {
      return <>{fallback}</>;
    } else {
      return <>{null}</>;
    }
  } else {
    let predicates: Predicate[];

    if (Array.isArray(accessible)) {
      predicates = accessible;
    } else {
      predicates = [accessible];
    }

    const isAccessible = predicates.reduce((acc, curr) => {
      if (!acc) {
        return acc;
      }

      return curr(authContext);
    }, true);

    if (isAccessible) {
      return children as any;
    } else if (React.isValidElement(fallback)) {
      return <>{fallback}</>;
    } else {
      return <>{null}</>;
    }
  }
};

export default Access;
