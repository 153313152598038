import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "features/auth/hooks/useAuth";
import ChatApiContext from "features/chat/services/ChatApiContext";
import { StreamChat } from "stream-chat";
import { streamVariables } from "core/global/environment.const";
import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from "features/chat/chat.model";
import { Chat } from "stream-chat-react";
import { useChatStore } from "features/chat/hooks/useChatStore";

export interface IChatProviderChatProvider {}

const apiKey = streamVariables.streamApiKey;

const chatClasses = {
  chat: "str-chat",
  chatContainer: "str-chat__container",
  channel: "str-chat-channel",
  channelList: "str-chat-channel-list",
  message: "str-chat__li str-chat__li--${groupStyles}",
  messageList: "str-chat__list",
  thread: "str-chat__thread",
  threadList: "str-chat__list--thread",
  virtualMessage: "str-chat__virtual-list-message-wrapper",
  virtualizedMessageList: "str-chat__virtual-list",
};

const ChatProvider: React.FunctionComponent<IChatProviderChatProvider> = () => {
  const chatApi = useContext(ChatApiContext);
  const { authContext } = useAuth();
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);

  const setTotalUnreadCount = useChatStore((state) => state.setTotalUnreadCount);

  const userToConnect: { id: string; name?: string; image?: string } = React.useMemo(
    () => ({
      id: authContext.userId!.value,
      name: authContext.userProfile!.firstName + " " + authContext.userProfile!.lastName,
    }),
    [authContext.userId, authContext.userProfile]
  );

  useEffect(() => {
    const initChat = async () => {
      const client = StreamChat.getInstance<
        AttachmentType,
        ChannelType,
        CommandType,
        EventType,
        MessageType,
        ReactionType,
        UserType
      >(apiKey!, { enableInsights: true, enableWSFallback: true });

      const response = await client.connectUser(userToConnect, async () => {
        const result = await chatApi.getUserToken();
        if (result.isErr()) {
          // todo: show message via root event subscription here
          console.error("could not create chat token", result.err().unwrap());
          return "";
        }

        return result.unwrap();
      });

      if (response !== undefined) {
        const totalUnreadCount = response?.me?.total_unread_count;
        setTotalUnreadCount(totalUnreadCount === undefined ? 0 : totalUnreadCount);
      }

      setChatClient(client);
    };

    // noinspection JSIgnoredPromiseFromCall
    initChat();

    return () => {
      chatClient?.disconnectUser();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (chatClient === undefined || chatClient === null) {
      return;
    }

    const eventSubscription = chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        setTotalUnreadCount(event.total_unread_count);
      }
    });

    return () => eventSubscription.unsubscribe();
  }, [chatClient]); // eslint-disable-line

  if (!chatClient) return null;

  return (
    <Chat client={chatClient}>
      <Outlet />
    </Chat>
  );
};

export default ChatProvider;
