import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SpecificationType } from "../../specification/order-specification";
import OrderItemFormatter from "../formatters/order-item-formatter/OrderItemFormatter";
import { IOrderEntry } from "./order-entry.model";

interface IOrderEntryProps {
  entry: IOrderEntry;
}

const OrderEntrySpecification: React.FunctionComponent<IOrderEntryProps> = ({ entry }) => {
  const { specification, orderType } = entry;

  if (specification.specificationType === SpecificationType.Arches && specification.arches.length === 0) {
    return (
      <FormattedMessage id="order-entry-specification.please-pick-arches" defaultMessage={"nie wybrano żadnego łuku"} />
    );
  } else if (
    (specification.specificationType === SpecificationType.LocatedPoints ||
      specification.specificationType === SpecificationType.PartialDentures ||
      specification.specificationType === SpecificationType.SurgicalGuides) &&
    specification.points.length === 0
  ) {
    return (
      <FormattedMessage
        id="order-entry-specification.please-pick-points"
        defaultMessage={"nie wybrano żadnych punktów"}
      />
    );
  } else if (specification.specificationType === SpecificationType.Crowns && specification.points.length === 0) {
    return (
      <FormattedMessage
        id="order-entry-specification.please-pick-crowns"
        defaultMessage={"nie wybrano żadnych koron"}
      />
    );
  } else if (specification.specificationType === SpecificationType.Bridges && specification.points.length === 0) {
    return (
      <FormattedMessage
        id="order-entry-specification.please-pick-bridges"
        defaultMessage={"nie wybrano żadnych mostów"}
      />
    );
  } else if (specification.specificationType === SpecificationType.Foundations && specification.points.length === 0) {
    return (
      <FormattedMessage
        id="order-entry-specification.please-pick-foundation"
        defaultMessage={"nie wybrano żadnych podbudów"}
      />
    );
  }
  return <OrderItemFormatter orderTypeName={orderType.name} orderCode={orderType.code} specification={specification} />;
};

export default OrderEntrySpecification;
