import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Alert } from "antd";
import { LinkedPointsSpecification, SpecificationType } from "../../../../specification/order-specification";

export const LinkPointsIntoGroups = (props: { specificationType: LinkedPointsSpecification["specificationType"] }) => {
  let message;

  switch (props.specificationType) {
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges:
      message = (
        <FormattedMessage
          id={"linked-points-specification-controls.connect-all-single-points-into-bridges"}
          defaultMessage={"Proszę połączyć pojedyńcze punkty w mosty, za pomocą kropek pomiędzy punktami"}
        />
      );
      break;
    case SpecificationType.Foundations:
      message = (
        <FormattedMessage
          id={"linked-points-specification-controls.connect-all-single-points-in-to-foundations"}
          defaultMessage={"Proszę połączyć pojedyńcze punkty w podbudowy, za pomocą kropek pomiędzy punktami"}
        />
      );
      break;
  }

  return <Alert className="m-4" type="error" showIcon message={message} />;
};
