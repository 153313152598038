import * as React from "react";
import { Button, Form } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { nameof } from "ts-simple-nameof";
import { IResult } from "core/lib/types/result";
import { ErrorCode } from "core/errors/error-code";
import Input from "../../../../components/forms/input/Input";
import InputPassword from "../../../../components/forms/input/InputPassword";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";
import { Path } from "core/routes/routes";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import * as yup from "yup";
import { ExplanationTooltip } from "components/common-components/CommonTooltips";

interface ILoginFormProps {
  onLoginSubmit: (submitValues: ISubmitValues) => Promise<IResult<void>>;
}

interface IFormValues {
  readonly email: string;
  readonly password: string;
  // readonly rememberMe: boolean;
}

interface ISubmitValues {
  readonly email: string;
  readonly password: string;
  // readonly rememberMe: boolean;
}

const formNames = {
  email: nameof<IFormValues>((x) => x.email),
  password: nameof<IFormValues>((x) => x.password),
  // rememberMe: nameof<IFormValues>((x) => x.rememberMe),
};

const rememberMeExplanation = (
  <ExplanationTooltip
    title={
      <FormattedMessage
        id={"login-form.remember-me-explanation"}
        defaultMessage={"Po zalogowaniu nie będzie potrzeby ponownego logowania na tej przeglądarce przez 2 dni"}
      />
    }
  />
);

const LoginForm: React.FC<ILoginFormProps> = ({ onLoginSubmit }) => {
  const intl = useIntl();
  const { flag: isLoading, check: startLoading, uncheck: finishLoading } = useBooleanFlag(false);

  const schema = useMemo(
    () =>
      yup
        .object({
          password: yup
            .string()
            .required(
              intl.formatMessage({ id: "login-form.password-is-required", defaultMessage: "Hasło jest wymagane" })
            ),
          email: yup
            .string()
            .email(intl.formatMessage({ id: "login-form.email-is-invalid", defaultMessage: "Nie poprawny email" }))
            .required(
              intl.formatMessage({ id: "login-form.email-is-required", defaultMessage: "Email jest wymagany" })
            ),
          // rememberMe: yup.boolean(),
        })
        .required(),
    [intl]
  );

  const {
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      [formNames.email]: "",
      [formNames.password]: "",
      // [formNames.rememberMe]: true,
    },
  });

  const handleLogin = async (formValues: IFormValues) => {
    startLoading();

    try {
      const submitResult = await onLoginSubmit({
        email: formValues.email,
        password: formValues.password,
        // rememberMe: formValues.rememberMe,
      });

      if (submitResult.isErr()) {
        const err = submitResult.err().unwrap();

        if (err.code === ErrorCode.InvalidLoginCredentials) {
          const errorFields = [
            {
              type: "manual",
              name: "password",
              message: intl.formatMessage({
                id: "login-form.invalid-login-credentials",
                defaultMessage: "niepoprawny email albo hasło",
              }),
            },
            {
              type: "manual",
              name: "email",
              message: intl.formatMessage({
                id: "login-form.invalid-login-credentials",
                defaultMessage: "niepoprawny email albo hasło",
              }),
            },
          ];

          errorFields.forEach(({ name, type, message }) => setError(name as any, { type, message }));
        }
      }

      if (submitResult.isOk()) {
        return;
      }
    } finally {
      finishLoading();
    }
  };

  return (
    <>
      <Form autoComplete="off" layout={"vertical"}>
        <Form.Item
          name={formNames.email}
          validateStatus={errors.email !== undefined ? "error" : undefined}
          help={errors.email?.message}
        >
          <Controller
            name="email"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Input
                placeholder={intl.formatMessage({
                  id: "login-form.email",
                  defaultMessage: "Email",
                })}
                value={value === null ? undefined : value}
                type={"email"}
                autoComplete={"stop"}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name={formNames.password}
          validateStatus={errors.password !== undefined ? "error" : undefined}
          help={errors.password?.message}
        >
          <Controller
            name="password"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <InputPassword
                placeholder={intl.formatMessage({
                  id: "login-form.password",
                  defaultMessage: "Hasło",
                })}
                autoComplete={"stop"}
                value={value === null ? undefined : value}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <div style={{ marginBottom: 24, textAlign: "right" }}>
          {/*<Form.Item*/}
          {/*  name={formNames.rememberMe}*/}
          {/*  validateStatus={errors.rememberMe !== undefined ? "error" : undefined}*/}
          {/*  help={errors.rememberMe?.message}*/}
          {/*>*/}
          {/*  <Controller*/}
          {/*    name="rememberMe"*/}
          {/*    control={control}*/}
          {/*    render={({ field: { value, ...rest } }) => (*/}
          {/*      <>*/}
          {/*        <Checkbox checked={value} {...rest}>*/}
          {/*          <FormattedMessage id="login-form.remember-me" defaultMessage="Zapamiętaj mnie" />*/}
          {/*        </Checkbox>*/}
          {/*        {rememberMeExplanation}*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*  />*/}
          {/*  <Link to={Path.ResetPassword} style={{ float: "right" }}>*/}
          {/*    <FormattedMessage id="login-form.forgot-password" defaultMessage="Zapomniałeś hasła?" />*/}
          {/*  </Link>*/}
          {/*</Form.Item>*/}
          <Link to={Path.ResetPassword}>
            <FormattedMessage id="login-form.forgot-password" defaultMessage="Zapomniałeś hasła?" />
          </Link>
        </div>
        <Button htmlType={"submit"} type="primary" block onClick={handleSubmit(handleLogin)} loading={isLoading}>
          <FormattedMessage id="login-form.login" defaultMessage="Zaloguj" />
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;

export type { ISubmitValues as ILoginFormSubmitValues };
