import * as React from "react";
import OrderApiContext from "features/orders/order.context";
import { IPaginatedList } from "core/pagination/paginated-list";
import DentistOrdersTable from "features/orders/pages/list/components/dentist-orders-table/DentistOrdersTable";
import useDentistLabs from "features/dental-lab/hook/dentist/useDentistLabs";
import { IUserId } from "features/auth/auth.model";
import { Spin } from "antd";
import ErrorScreen from "components/errors/ErrorScreen";
import {
  DentistOrdersDataProvider, IDentistOrdersProviderParams,
  IDentistOrderTableEntry,
} from "features/orders/pages/list/components/dentist-orders-table/dentist-orders-table.models";

interface IListOrderPageProps {
  userId: IUserId;
}

const DentistOrdersListView: React.FunctionComponent<IListOrderPageProps> = ({ userId }) => {
  const ordersApi = React.useContext(OrderApiContext);
  const { getAllDentistLabs } = useDentistLabs();

  const dataProvider: DentistOrdersDataProvider = React.useCallback(
    async (params: IDentistOrdersProviderParams) => {
      const result = await ordersApi.getDentistOrders(params);

      return result.map((orders) => {
        const paginatedEntries: IPaginatedList<IDentistOrderTableEntry> = {
          pagination: { ...orders.pagination },
          data: orders.data.map((order) => {
            const entry: IDentistOrderTableEntry = {
              orderId: order.orderId,
              labId: order.labId,
              labName: order.labName,
              patient: order.patient,
              startDate: order.startDate,
              dueDate: order.dueDate,
              finishDate: order.finishDate,
              status: order.status,
              note: order.orderNote,
            };

            return entry;
          }),
        };

        return paginatedEntries;
      });
    },
    [ordersApi]
  );

  if (getAllDentistLabs === undefined) {
    return <Spin />;
  } else if (getAllDentistLabs.isErr()) {
    return <ErrorScreen error={getAllDentistLabs.err().unwrap()} />;
  }

  return <DentistOrdersTable labs={getAllDentistLabs.unwrap()} dataProvider={dataProvider} />;
};

export default DentistOrdersListView;