import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Form, Input, Button } from "antd";
import { IMoney } from "core/money/money.model";
import MoneyInput from "../../../../../components/forms/money-input/MoneyInput";
import MoneyFormatter from "../../../../../components/formatters/money/MoneyFormatter";
import { Money } from "core/money/money.functions";

interface IChangePriceModalProps {
  visible: boolean;
  oldPrice: IMoney | null;
  onCancel: () => void;
  onSubmit: (newPrice: IMoney, reasonForChange: string) => void;
}

interface IFormValues {
  newPrice: IMoney;
  reasonForChange: string;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = {
  newPrice: "newPrice",
  reasonForChange: "reasonForChange",
};

const ChangePriceModal: React.FunctionComponent<IChangePriceModalProps> = ({
  oldPrice,
  visible,
  onSubmit,
  onCancel,
}) => {
  const formId = "change-price-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { newPrice, reasonForChange } = store;
      await onSubmit(newPrice, reasonForChange);
      form.resetFields();
    },
    [form, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      title={<FormattedMessage id={"change-price-modal.price-change"} defaultMessage={"Zmiana ceny"} />}
      okText={<FormattedMessage id={"change-price-modal.change-price"} defaultMessage={"Zmień cenę"} />}
      cancelText={<FormattedMessage id={"change-price-modal.cancel"} defaultMessage={"Anuluj"} />}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"change-price-modal.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          <FormattedMessage id={"change-price-modal.change-price-text"} defaultMessage={"Zmień cenę"} />
        </Button>,
      ]}
    >
      <Form
        id={formId}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{ [propertyNames.newPrice]: oldPrice }}
      >
        <Form.Item label={<FormattedMessage id={"change-price-modal.old-price"} defaultMessage={"Poprzednia cena"} />}>
          {oldPrice !== null ? <MoneyFormatter value={oldPrice} /> : " - "}
        </Form.Item>
        <Form.Item
          name={propertyNames.newPrice}
          label={<FormattedMessage id={"change-price-modal.new-price"} defaultMessage={"Nowa cena"} />}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="change-price-modal.new-price-is-required"
                  defaultMessage="Proszę podać nową cenę"
                />
              ),
            },
            {
              validator: async (rule: any /*ant form rule*/, value: IMoney) => {
                if (oldPrice !== null && Money.isEqual(value, oldPrice)) {
                  return Promise.reject(new Error("old price and new price are equal"));
                }
              },
              message: (
                <FormattedMessage
                  id={"change-price-modal.new-price-is-equal-to-old-price"}
                  defaultMessage={"Nowa cena jest identyczna jak poprzednia"}
                />
              ),
            },
          ]}
        >
          <MoneyInput />
        </Form.Item>
        <Form.Item
          name={propertyNames.reasonForChange}
          label={
            <FormattedMessage
              id={"change-price-modal.reason-for-change"}
              defaultMessage={"Opis / Objaśnienie zmiany ceny"}
            />
          }
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="change-price-modal.reason-for-change-is-required"
                  defaultMessage="Proszę podać opis lub objaśnienie"
                />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePriceModal;
