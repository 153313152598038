import * as React from "react";
import { Button, Form, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { Pricing} from "core/pricing/pricing.model";
import PricingInput from "features/catalogue/prices/components/pricing-input/PricingInput";
import { SpecificationType } from "features/orders/specification/order-specification";
import { IOrderTypeId} from "features/catalogue/order-type.model";
import OrderCodeName from "components/formatters/order-code-name/OrderCodeName";
import { DeepPartial } from "utility-types";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

export interface IPriceEntryModalFormProps {
  modalType: "edit" | "create";
  orderType: {
    orderTypeId: IOrderTypeId;
    orderCode: OrderCodeEnum | null;
    name: string;
    requiredSpecification: SpecificationType;
  };
  visible: boolean;
  values?: DeepPartial<IFormValues>;
  onCancel: () => void;
  onSubmit: (values: Pricing) => Promise<void>;
}

interface IFormValues {
  pricing: Pricing;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = {
  pricing: "pricing",
};

const PriceEntryModalForm: React.FunctionComponent<IPriceEntryModalFormProps> = ({
  modalType,
  visible,
  values = {},
  orderType,
  onCancel,
  onSubmit,
}) => {
  const formId = "price-entry-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { pricing } = store;
      await onSubmit(pricing);
      form.resetFields();
    },
    [form, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        modalType === "edit" ? (
          <FormattedMessage id={"price-entry-modal-form.edit-dentist"} defaultMessage={"Edytuj wycenę"} />
        ) : (
          <FormattedMessage id={"price-entry-modal-form.create-dentist"} defaultMessage={"Dodaj wycenę"} />
        )
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"price-entry-modal-form.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          {modalType === "edit" ? (
            <FormattedMessage id={"price-entry-modal-form.edit-dentist-button-text"} defaultMessage={"Zapisz"} />
          ) : (
            <FormattedMessage id={"price-entry-modal-form.create-dentist-button-text"} defaultMessage={"Dodaj"} />
          )}
        </Button>,
      ]}
    >
      <div className={"mb-5"}>
        {orderType.orderCode !== null ? <OrderCodeName code={orderType.orderCode} /> : <>{orderType.name}</>}
      </div>
      <Form
        id={formId}
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ ...values }}
      >
        <Form.Item name={propertyNames.pricing}>
          {/* @ts-ignore */}
          <PricingInput requiredSpecification={orderType.requiredSpecification} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PriceEntryModalForm;
