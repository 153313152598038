import { IntlShape } from "react-intl";
import { argumentOutOfRangeError } from "core/errors/generate-error";
import { SpecificationType } from "features/orders/specification/order-specification";

export const specificationFormatter = (intl: IntlShape, specification: SpecificationType) => {
  if (specification === null || specification === undefined || typeof specification !== "string") {
    return "-";
  }

  switch (specification) {
    case SpecificationType.TextOnly: {
      return intl.formatMessage({ id: "specification-name.text-only", defaultMessage: "Wyłącznie opis tekstowy" });
    }
    case SpecificationType.Quantity: {
      return intl.formatMessage({ id: "specification-name.quantity", defaultMessage: "Ilość" });
    }
    case SpecificationType.Arches: {
      return intl.formatMessage({ id: "specification-name.arches", defaultMessage: "Łuki" });
    }
    case SpecificationType.LocatedPoints: {
      return intl.formatMessage({ id: "specification-name.located-points", defaultMessage: "Punkty" });
    }
    case SpecificationType.PartialDentures: {
      return intl.formatMessage({ id: "specification-name.PartialDentures", defaultMessage: "Protezy częściowe" });
    }
    case SpecificationType.SurgicalGuides: {
      return intl.formatMessage({ id: "specification-name.SurgicalGuides", defaultMessage: "Szablony chirurgiczne" });
    }
    case SpecificationType.Crowns: {
      return intl.formatMessage({ id: "specification-name.crowns", defaultMessage: "Korony" });
    }
    case SpecificationType.Bridges: {
      return intl.formatMessage({ id: "specification-name.bridges", defaultMessage: "Mosty" });
    }
    case SpecificationType.MarylandBridges: {
      return intl.formatMessage({ id: "specification-name.maryland-bridges", defaultMessage: "Mosty maryland" });
    }
    case SpecificationType.Foundations: {
      return intl.formatMessage({ id: "specification-name.foundations", defaultMessage: "Podbudowy" });
    }
    default:
      throw argumentOutOfRangeError("specification type", specification);
  }
};
