import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Col, Row } from "antd";
import { CheckCircleOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import styles from "./invited-employees-counts.module.scss";

interface IInvitedEmployeesCountsProps {
  totalEmployees: number;
  employeesInvited: number;
  employeesAcceptedInvitation: number;
}

const Info: React.FC<{
  title: React.ReactNode;
  value: React.ReactNode;
  bordered?: boolean;
}> = ({ title, value, bordered }) => (
  <div className={styles.headerInfo}>
    <span>{title}</span>
    <p>{value}</p>
    {bordered && <em />}
  </div>
);

const InvitedEmployeesCounts: React.FC<IInvitedEmployeesCountsProps> = ({
  totalEmployees,
  employeesInvited,
  employeesAcceptedInvitation,
}) => (
  <Card bordered={false}>
    <Row>
      <Col sm={8} xs={24}>
        <Info
          title={
            <FormattedMessage id="invited-employees-counts.total-employees" defaultMessage="Łącznie pracowników" />
          }
          value={
            <span>
              <UserOutlined className={"mr-2"} />
              {totalEmployees}
            </span>
          }
          bordered
        />
      </Col>
      <Col sm={8} xs={24}>
        <Info
          title={
            <FormattedMessage
              id="invited-employees-counts.total-invites-sent"
              defaultMessage="Łącznie wysłanych zaproszeń"
            />
          }
          value={
            <span>
              <MailOutlined className={"mr-2"} />
              {employeesInvited}
            </span>
          }
          bordered
        />
      </Col>
      <Col sm={8} xs={24}>
        <Info
          title={
            <FormattedMessage
              id="invited-employees-counts.total-invites-accepted"
              defaultMessage="Łącznie zaakceptowanych zaproszeń"
            />
          }
          value={
            <>
              <CheckCircleOutlined className={"mr-2"} />
              {employeesAcceptedInvitation}
            </>
          }
        />
      </Col>
    </Row>
  </Card>
);

export default InvitedEmployeesCounts;
