import * as React from "react";
import { Layout as AntLayout } from "antd";
import classNames from "classnames";
import { Children } from "react";

const { Content } = AntLayout;

interface IPageContentProps extends React.HTMLAttributes<HTMLDivElement> { }

interface IPageContentProps extends React.HTMLAttributes<HTMLDivElement> {
  separate?: boolean;
}

const PageContent: React.FunctionComponent<IPageContentProps> = ({
  style = {},
  children,
  separate,
  ...otherProps
}) => {
  const contentProps = {
    style: {
      margin: "24px 16px",
      background: "#fff",
      ...style,
    },
    ...otherProps,
  };

  if (separate) {
    return (
      <>
        {Children.toArray(children).map((c, i) => (
          <Content key={i} {...contentProps}>
            {c}
          </Content>
        ))}
      </>
    );
  } else {
    return <Content {...contentProps}>{children}</Content>;
  }
};

export const StandardPageContent: React.FunctionComponent<IPageContentProps> = ({
  ...otherProps
}) => (
  <Content
    className={classNames("flex flex-col my-7 mx-4 bg-white", otherProps.className)}
    {...otherProps}
  />
);


export default PageContent;
