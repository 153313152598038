import { IntlShape } from "react-intl";
import { CategoryCode } from "features/catalogue/category.model";
import { argumentOutOfRangeError } from "core/errors/generate-error";

export const categoryFormatter = (intl: IntlShape, category: { name: string; code: CategoryCode | null }) => {
  const { name, code } = category;

  if (code === null) {
    return name;
  }

  switch (code) {
    case CategoryCode.FullDentures:
      return intl.formatMessage({ id: "order-category-name.full-dentures", defaultMessage: "Protezy całkowite" });
    case CategoryCode.PartialDentures:
      return intl.formatMessage({ id: "order-category-name.partial-dentures", defaultMessage: "Protezy częściowe" });
    case CategoryCode.FrameDentures:
      return intl.formatMessage({ id: "order-category-name.frame-dentures", defaultMessage: "Protezy szkieletowe" });
    case CategoryCode.SplintDentures:
      return intl.formatMessage({ id: "order-category-name.splint-dentures", defaultMessage: "Szynoprotezy" });
    case CategoryCode.Overdentures:
      return intl.formatMessage({ id: "order-category-name.overdentures", defaultMessage: "Protezy overdentures" });
    case CategoryCode.Attachments:
      return intl.formatMessage({ id: "order-category-name.attachments", defaultMessage: "Attachmenty (elementy precyzyjne)" });
    case CategoryCode.DentureRepair:
      return intl.formatMessage({ id: "order-category-name.denture-repair", defaultMessage: "Naprawa protezy" });
    case CategoryCode.Crowns:
      return intl.formatMessage({ id: "order-category-name.crowns", defaultMessage: "Korony" });
    case CategoryCode.Bridges:
      return intl.formatMessage({ id: "order-category-name.bridges", defaultMessage: "Mosty" });
    case CategoryCode.MarylandBridges:
      return intl.formatMessage({ id: "order-category-name.maryland-bridges", defaultMessage: "Mosty maryland" });
    case CategoryCode.Veneers:
      return intl.formatMessage({ id: "order-category-name.veneers", defaultMessage: "Licówki" });
    case CategoryCode.Foundations:
      return intl.formatMessage({ id: "order-category-name.foundations", defaultMessage: "Podbudowy" });
    case CategoryCode.ImplantAbutments:
      return intl.formatMessage({ id: "order-category-name.implant-abutments", defaultMessage: "Łączniki" });
    case CategoryCode.Onlays:
      return intl.formatMessage({ id: "order-category-name.onlays", defaultMessage: "Onlaye" });
    case CategoryCode.Inlays:
      return intl.formatMessage({ id: "order-category-name.inlays", defaultMessage: "Inlaye" });
    case CategoryCode.Overlays:
      return intl.formatMessage({ id: "order-category-name.overlays", defaultMessage: "Overlaye" });
    case CategoryCode.EndoCrown:
      return intl.formatMessage({ id: "order-category-name.endo-crown", defaultMessage: "Endokorony" });
    case CategoryCode.WaxUps:
      return intl.formatMessage({ id: "order-category-name.wax-ups", defaultMessage: "Wax-up'y" });
    case CategoryCode.Temporary:
      return intl.formatMessage({ id: "order-category-name.temporary", defaultMessage: "Prace tymczasowe" });
    case CategoryCode.Splints:
      return intl.formatMessage({ id: "order-category-name.splints", defaultMessage: "Szyny" });
    case CategoryCode.Post:
      return intl.formatMessage({
        id: "order-category-name.root-canal-inlays",
        defaultMessage: "Wkłady koronowo-korzeniowe",
      });
    case CategoryCode.Gingiva:
      return intl.formatMessage({ id: "order-category-name.gingiva", defaultMessage: "Dziąsła" });
    case CategoryCode.OrientationModels:
      return intl.formatMessage({
        id: "order-category-name.orientation-models",
        defaultMessage: "Modele orientacyjne",
      });
    case CategoryCode.Services:
      return intl.formatMessage({ id: "order-category-name.services", defaultMessage: "Usługi" });
    case CategoryCode.MillingServices:
      return intl.formatMessage({ id: "order-category-name.milling-services", defaultMessage: "Usługi frezowania" });
    case CategoryCode.Other:
      return intl.formatMessage({ id: "order-category-name.other", defaultMessage: "Inne" });
    default:
      throw argumentOutOfRangeError("orderCode", code);
  }
};
