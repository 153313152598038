import useSWR from "swr";
import {
  FormMode,
  IOrderFormValues,
  OnLoadOrderCatalogueForLabFn,
  OrderFormProps,
} from "features/orders/components/order-form/order-form.model";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { Control, useWatch } from "react-hook-form";
import { IUserId } from "features/auth/auth.model";

export const orderFormCatalogueProps = (
  props: OrderFormProps,
  control: Control<IOrderFormValues, Object>
): OrderFormCatalogueProps => {
  switch (props.mode) {
    case FormMode.LabCreate:
    case FormMode.LabEdit:
      return {
        mode: props.mode,
        labId: props.labId,
        onLoadOrderCatalogue: props.onLoadOrderCatalogue,
        control,
      };
    case FormMode.DentistCreate:
    case FormMode.DentistEdit:
      return {
        mode: props.mode,
        dentistId: props.dentistUserId,
        onLoadOrderCatalogue: props.onLoadOrderCatalogue,
        control,
      };
  }
};

type BaseOptions = {
  control: Control<IOrderFormValues, Object>;
  onLoadOrderCatalogue: OnLoadOrderCatalogueForLabFn;
};

type OrderFormCatalogueProps =
  | ({ mode: FormMode.LabEdit | FormMode.LabCreate; labId: ILabId } & BaseOptions)
  | ({ mode: FormMode.DentistEdit | FormMode.DentistCreate; dentistId: IUserId } & BaseOptions);

function useOrderFormCatalogue(props: OrderFormCatalogueProps) {
  // reload order catalogue when picked lab has changed - will change only for [dentist forms]
  const pickedLabIdString = useWatch({
    control: props.control,
    name: "labId",
    disabled: props.mode === FormMode.LabCreate || props.mode === FormMode.LabEdit,
  });

  const swrKeyFn = (): [string, string, string] => {
    if (props.mode === FormMode.LabCreate || props.mode === FormMode.LabEdit) {
      return ["order-form-get-order-catalogue", "", props.labId.value];
    } else if (props.mode === FormMode.DentistEdit || props.mode === FormMode.DentistCreate) {
      return ["order-form-get-order-catalogue", props.dentistId.value, pickedLabIdString];
    } else {
      throw new Error("invalid condition" + props.mode);
    }
  };

  const { data: getOrdersCatalogue } = useSWR(swrKeyFn, (_, __, labIdString: string) =>
    props.onLoadOrderCatalogue({ type: "dental-lab-id", value: labIdString })
  );

  return {
    getOrdersCatalogue,
  };
}

export default useOrderFormCatalogue;
