import * as React from "react";
import { Button, Form, Modal } from "antd";
import Input from "components/forms/input/Input";
import { FormattedMessage } from "react-intl";
import { DeepPartial } from "utility-types";

export interface IEmployeeModalFormProps {
  modalType: "edit" | "create";
  visible: boolean;
  values?: DeepPartial<IFormValues>;
  onCancel: () => void;
  onSubmit: (values: ISubmitValues) => Promise<void>;
}

interface ISubmitValues {
  firstName: string;
  lastName: string;
}

interface IFormValues {
  firstName: string;
  lastName: string;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = {
  firstName: "firstName",
  lastName: "lastName",
};

interface ICreateEmployeeFormProps extends IEmployeeModalFormProps {}

const EmployeeModalForm: React.FunctionComponent<ICreateEmployeeFormProps> = ({
  modalType,
  visible,
  values = {},
  onCancel,
  onSubmit,
}) => {
  const formId = "employee-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { firstName, lastName } = store;
      await onSubmit({ firstName, lastName });
      form.resetFields();
    },
    [form, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        modalType === "edit" ? (
          <FormattedMessage id={"employee-modal-form.edit-employee"} defaultMessage={"Edytuj pracownika"} />
        ) : (
          <FormattedMessage id={"employee-modal-form.create-employee"} defaultMessage={"Dodaj pracownika"} />
        )
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"employee-modal-form.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          {modalType === "edit" ? (
            <FormattedMessage id={"employee-modal-form.edit-employee-button-text"} defaultMessage={"Zapisz"} />
          ) : (
            <FormattedMessage id={"employee-modal-form.create-employee-button-text"} defaultMessage={"Dodaj"} />
          )}
        </Button>,
      ]}
    >
      <Form id={formId} form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ ...values }}>
        <Form.Item
          label={<FormattedMessage id="employee-modal-form.first-name" defaultMessage="Imię" />}
          name={propertyNames.firstName}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage id="employee-modal-form.first-name-is-required" defaultMessage="Imię jest wymagane" />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="employee-modal-form.last-name" defaultMessage="Nazwisko" />}
          name={propertyNames.lastName}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="employee-modal-form.last-name-is-required"
                  defaultMessage="Nazwisko jest wymagane"
                />
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeModalForm;

export type { ISubmitValues as IEmployeeModalFormSubmitValues };
