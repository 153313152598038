import * as React from "react";
import { ButtonProps } from "antd/lib/button";
import { Button as AntButton } from "antd";

export interface IButtonProps extends ButtonProps {
  testId?: string;
}

// eslint-disable-next-line react/display-name
const Button = React.forwardRef<any, IButtonProps>((props, ref: React.Ref<any>) => {
  const { testId, ...rest } = props;
  return <AntButton ref={ref} {...rest} data-testid={testId} />;
});

export default Button;
