import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Input, InputProps } from "antd";
import { Currency, IMoney } from "core/money/money.model";

interface IPriceInputProps extends Omit<InputProps, "value" | "onChange" | "parser" | "formatter" | "defaultValue"> {
  testId?: string;
  defaultCurrency?: Currency;
  currencyChangeDisabled?: boolean;
  value?: IMoney;
  onChange?: (value: IMoney) => void;
}

const MoneyInput = React.forwardRef<any, IPriceInputProps>((props, ref: React.Ref<any>) => {
  const {
    testId,
    value,
    defaultCurrency = Currency.PLN,
    currencyChangeDisabled = true,
    onChange,
    ...restProps
  } = props;

  const [strValue, setStrValue] = useState(value?.amount.toString() ?? "");

  const getDefaultCurrency = useCallback(() => {
    if (value !== undefined && value !== null) {
      return value.currency;
    }

    if (defaultCurrency !== undefined && defaultCurrency !== null) {
      return defaultCurrency;
    }

    return Currency.PLN;
  }, [defaultCurrency, value]);

  const triggerChange = useCallback(
    (changeDiff: Partial<IMoney>) => {
      const defaultCurrency = { currency: getDefaultCurrency() } as Pick<IMoney, "currency">;
      const next = { ...defaultCurrency, ...value, ...changeDiff } as IMoney;
      onChange?.(next);
    },
    [getDefaultCurrency, onChange, value]
  );

  const handleNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const v = event.target.value;

      if (v === null) {
        setStrValue("");
        triggerChange({ amount: 0 });
        return;
      }

      setStrValue(v.toString());
      const parsedNumber = parseFloat(v.toString().replace(",", "."));

      if (isNaN(parsedNumber)) {
        return;
      }

      const normalizedValue = Math.floor(parsedNumber * 100) / 100;

      if (value?.amount === normalizedValue) {
        return;
      }

      triggerChange({ amount: normalizedValue });
    },
    [value?.amount, triggerChange]
  );

  useEffect(() => {
    if (value === undefined) {
      setStrValue("");
      return;
    }

    setStrValue(value.amount.toString());
  }, [value, setStrValue]);

  return (
    <>
      {currencyChangeDisabled && (
        <div className={"inline-flex flex-nowrap items-center w-full"}>
          <Input
            ref={ref}
            type={"number"}
            value={strValue}
            onChange={handleNumberChange}
            {...restProps}
            suffix={"zł"}
          />
        </div>
      )}
    </>
  );
});

export default MoneyInput;
