import * as React from "react";
import { useEffect } from "react";
import { useIntl, defineMessages } from "react-intl";
import { SelectProps } from "antd/lib/select";
import Select from "components/forms/select/Select";

import { Country } from "core/countries/countries.model";
import { europeanCountries } from "core/countries/countries.const";
import { mapCountryToCountryName } from "components/formatters/country/CountryName";
import { fixAutocomplete } from "components/utils/fix-autocomplete/fix-autocomplete";

interface ICountrySelectProps extends SelectProps<Country> {}

const CountrySelect = React.forwardRef<any, ICountrySelectProps>((props, ref: React.Ref<any>) => {
  const { children: _, ...rest } = props;
  const intl = useIntl();

  useEffect(() => {
    fixAutocomplete();
  }, []);

  return (
    <Select
      ref={ref}
      showSearch
      filterOption={(input, option) => (option as any).props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...(rest as any)}
    >
      {europeanCountries.map((country) => (
        <Select.Option key={country} value={country}>
          {intl.formatMessage({
            id: `country-name.${country}`,
            defaultMessage: `${mapCountryToCountryName(country)}`,
          })}
        </Select.Option>
      ))}
    </Select>
  );
});

export default CountrySelect;

defineMessages({
  "country-name.AT": {
    id: "country-name.AT",
    defaultMessage: "AT",
  },
  "country-name.BE": {
    id: "country-name.BE",
    defaultMessage: "BE",
  },
  "country-name.BG": {
    id: "country-name.BG",
    defaultMessage: "BG",
  },
  "country-name.CY": {
    id: "country-name.CY",
    defaultMessage: "CY",
  },
  "country-name.CZ": {
    id: "country-name.CZ",
    defaultMessage: "CZ",
  },
  "country-name.DE": {
    id: "country-name.DE",
    defaultMessage: "DE",
  },
  "country-name.DK": {
    id: "country-name.DK",
    defaultMessage: "DK",
  },
  "country-name.EE": {
    id: "country-name.EE",
    defaultMessage: "EE",
  },
  "country-name.ES": {
    id: "country-name.ES",
    defaultMessage: "ES",
  },
  "country-name.FI": {
    id: "country-name.FI",
    defaultMessage: "FI",
  },
  "country-name.FR": {
    id: "country-name.FR",
    defaultMessage: "FR",
  },
  "country-name.GB": {
    id: "country-name.GB",
    defaultMessage: "GB",
  },
  "country-name.GR": {
    id: "country-name.GR",
    defaultMessage: "GR",
  },
  "country-name.HU": {
    id: "country-name.HU",
    defaultMessage: "HU",
  },
  "country-name.HR": {
    id: "country-name.HR",
    defaultMessage: "HR",
  },
  "country-name.IE": {
    id: "country-name.IE",
    defaultMessage: "IE",
  },
  "country-name.IT": {
    id: "country-name.IT",
    defaultMessage: "IT",
  },
  "country-name.LT": {
    id: "country-name.LT",
    defaultMessage: "LT",
  },
  "country-name.LU": {
    id: "country-name.LU",
    defaultMessage: "LU",
  },
  "country-name.LV": {
    id: "country-name.LV",
    defaultMessage: "LV",
  },
  "country-name.MT": {
    id: "country-name.MT",
    defaultMessage: "MT",
  },
  "country-name.NL": {
    id: "country-name.NL",
    defaultMessage: "NL",
  },
  "country-name.PL": {
    id: "country-name.PL",
    defaultMessage: "PL",
  },
  "country-name.PT": {
    id: "country-name.PT",
    defaultMessage: "PT",
  },
  "country-name.RO": {
    id: "country-name.RO",
    defaultMessage: "RO",
  },
  "country-name.SE": {
    id: "country-name.SE",
    defaultMessage: "SE",
  },
  "country-name.SI": {
    id: "country-name.SI",
    defaultMessage: "SI",
  },
  "country-name.SK": {
    id: "country-name.SK",
    defaultMessage: "SK",
  },
});
