import * as React from "react";
import { useEffect } from "react";
import { SelectProps } from "antd/lib/select";
import { CreateGuid } from "core/guid/guid";
import { isNotEmptyString } from "core/utils/utils";
import { Select } from "antd";
import { IOrderCategory } from "features/catalogue/category.model";
import { categoryFormatter } from "components/formatters/category/category-formatter";
import { useIntl } from "react-intl";

export interface ICategorySelectProps extends SelectProps<string> {
  categories: Pick<IOrderCategory, "id" | "name" | "code">[];
}

const fixAutocomplete = () => {
  document?.querySelectorAll(".ant-select-selector input").forEach((e) => {
    e.setAttribute("autocomplete", CreateGuid());
  });
};

const CategorySelect = React.forwardRef<any, ICategorySelectProps>((props, ref: React.Ref<any>) => {
  const { children: _, categories, ...rest } = props;
  const intl = useIntl();
  // categoryFormatter

  // const categorys = unsortedCategorys.sort((d1, d2) => {
  //   if (d1.name.lastName < d2.name.lastName) {
  //     return -1;
  //   } else if (d1.name.lastName > d2.name.lastName) {
  //     return 1;
  //   } else if (d1.name.firstName < d2.name.firstName) {
  //     return -1;
  //   } else if (d1.name.firstName < d2.name.firstName) {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // });

  const searchPredicate = React.useCallback(
    (input: string, option: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
      if (!isNotEmptyString(option.key)) {
        return false;
      }

      const key = option.key;
      const category = categories.find((d) => d.id.value === key);

      if (category === undefined) {
        return false;
      }

      const searchPhrase = input.toLowerCase();
      const categoryName = categoryFormatter(intl, { name: category.name, code: category.code });

      return categoryName.toLowerCase().indexOf(searchPhrase) !== -1;
    },
    [categories, intl]
  );

  useEffect(() => {
    fixAutocomplete();
  }, []);

  return (
    <Select ref={ref} showSearch filterOption={searchPredicate} {...(rest as any)}>
      {categories.map((category) => (
        <Select.Option key={category.id.value} value={category.id.value}>
          {categoryFormatter(intl, { name: category.name, code: category.code })}
        </Select.Option>
      ))}
    </Select>
  );
});

export default CategorySelect;
