import useSWR from "swr";
import {
  FormMode,
  IOrderFormValues,
  LoadPricingListForDentistFn,
  OnLoadPricingListForLabFn,
  OrderFormProps,
} from "features/orders/components/order-form/order-form.model";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { Control, useWatch } from "react-hook-form";
import { IUserId } from "features/auth/auth.model";
import { IDentistId } from "features/dentist/dentist.model";

export const orderFormPricingListProps = (
  props: OrderFormProps,
  control: Control<IOrderFormValues, Object>
): OrderFormPricingListProps => {
  switch (props.mode) {
    case FormMode.LabCreate:
    case FormMode.LabEdit:
      return {
        mode: props.mode,
        labId: props.labId,
        onLoadPricingListForDentist: props.onLoadPricingListForDentist,
        control,
      };
    case FormMode.DentistCreate:
    case FormMode.DentistEdit:
      return {
        mode: props.mode,
        onLoadPricingListForLab: props.onLoadPricingListForLab,
        userId: props.dentistUserId,
        control,
      };
  }
};

type BaseOptions = {
  control: Control<IOrderFormValues, Object>;
};

type OrderFormPricingListProps =
  | ({
      mode: FormMode.LabEdit | FormMode.LabCreate;
      labId: ILabId;
      onLoadPricingListForDentist: LoadPricingListForDentistFn;
    } & BaseOptions)
  | ({
      mode: FormMode.DentistEdit | FormMode.DentistCreate;
      userId: IUserId;
      onLoadPricingListForLab: OnLoadPricingListForLabFn;
    } & BaseOptions);

function useOrderFormPricingList(props: OrderFormPricingListProps) {
  // reload pricing list when picked lab has changed - will change only for [dentist forms]
  const pickedLabIdString = useWatch({
    control: props.control,
    name: "labId",
    disabled: props.mode !== FormMode.DentistCreate && props.mode !== FormMode.DentistEdit,
  });

  // reload pricing list when picked dentist has changed - will change only for [lab forms]
  const pickedDentistIdString = useWatch({
    control: props.control,
    name: "dentistId",
    disabled: props.mode !== FormMode.LabCreate && props.mode !== FormMode.LabEdit,
  });

  const swrKeyFn = (): [string, FormMode, string, string] | null => {
    if (props.mode === FormMode.LabCreate || props.mode === FormMode.LabEdit) {
      return pickedDentistIdString === undefined
        ? null
        : ["order-form-pricing", props.mode, props.labId.value, pickedDentistIdString];
    } else if (props.mode === FormMode.DentistCreate || props.mode === FormMode.DentistEdit) {
      return pickedLabIdString === undefined
        ? null
        : ["order-form-pricing", props.mode, props.userId.value, pickedLabIdString];
    } else {
      throw new Error("invalid condition" + props.mode);
    }
  };

  const { data: getPricingList, mutate: reloadPricingList } = useSWR(
    swrKeyFn,
    (_1, formMode: FormMode, s1: string, s2: string) => {
      if (formMode !== props.mode) {
        console.error("race occured investigate");
        throw new Error("race occured investigate");
      }

      if (props.mode === FormMode.LabCreate || props.mode === FormMode.LabEdit) {
        const labId: ILabId = { type: "dental-lab-id", value: s1 };
        const dentistId: IDentistId = { type: "dentist-id", value: s2 };
        return props.onLoadPricingListForDentist(labId, dentistId);
      } else if (props.mode === FormMode.DentistCreate || props.mode === FormMode.DentistEdit) {
        const dentistUserId: IUserId = { type: "user-id", value: s1 };
        const labId: ILabId = { type: "dental-lab-id", value: s2 };
        return props.onLoadPricingListForLab(dentistUserId, labId);
      }
    }
  );

  return {
    getPricingList,
    reloadPricingList,
  };
}

export default useOrderFormPricingList;
