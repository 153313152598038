import * as React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Statistic } from "antd";
import { FormattedMessage} from "react-intl";
import { IDentist } from "features/dentist/dentist.model";
import { IBillingStatementLineId, ILineItem } from "features/invoicing/models/invoicing.models";
import LineItemsTable from "features/invoicing/components/line-items-table/LineItemsTable";
import { useState } from "react";
import NextPrevButtons from "components/next-prev-buttons/NextPrevButtons";

export interface ILineItemsFormOwnProps {
  dentists: IDentist[];
  lines: ILineItem[];
  onCancel: () => void;
  onSubmit: (lines: ILineItem[]) => void;
}

interface ILineItemsFormProps extends ILineItemsFormOwnProps {}

const CreateBillingStatementForm: React.FunctionComponent<ILineItemsFormProps> = ({
  lines: initialLines,
  dentists,
  onCancel,
  onSubmit,
}) => {
  const [lines, setLines] = useState(initialLines);
  const total = lines.reduce((sum, curr) => sum + curr.price.amount, 0);
  const [editableKeys, setEditableRowKeys] = useState<IBillingStatementLineId[]>([]);

  return (
    <>
      <LineItemsTable
        dentists={dentists}
        lines={lines}
        editableIds={editableKeys}
        onSetEditableLines={setEditableRowKeys}
        onLinesChange={async (lines) => setLines(lines)}
      />
      <div className="flex flex-row justify-between p-5">
        <NextPrevButtons
          prevPopconfirm={{
            title: (
              <FormattedMessage
                id="common.all-changes-will-be-lost"
                defaultMessage="Wszystkie zmiany zostaną utracone"
              />
            ),
            icon: <QuestionCircleOutlined style={{ color: "red" }} />,
          }}
          onPrevStep={onCancel}
          nextVisibility={editableKeys.length !== 0 ? "disabled" : "visible"}
          onNextStep={() => onSubmit(lines)}
        />
        <Statistic
          title={<FormattedMessage id="provide-line-items.total" defaultMessage="Suma" />}
          value={total}
          precision={2}
          suffix="zł"
        />
      </div>
    </>
  );
};

export default CreateBillingStatementForm;
