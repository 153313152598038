export enum PreparationMargin {
  Good = 1,
  Bad = 2,
}

export enum Bite {
  TooLow = 1,
  Good = 2,
  TooHigh = 3,
}

export enum TangentialPoint {
  Tight = 1,
  Good = 2,
  None = 3,
}

export enum Color {
  TooDark = 1,
  Good = 2,
  TooBright = 3,
}

export enum Translucency {
  NotEnoughTranslucent = 1,
  Good = 2,
  TooTranslucent = 3,
}

export interface IOrderFeedbackSurvey {
  preparationMargin: PreparationMargin;
  bite: Bite;
  tangentialPoint: TangentialPoint;
  color: Color;
  translucency: Translucency;
}

export interface IDentistOrderSurveyFeedback extends IOrderFeedbackSurvey {
  dentistRating: number;
}

export interface ILabOrderSurveyFeedback extends IOrderFeedbackSurvey {

}

export interface IOrderFeedbackModelDTO {
  preparationMargin: PreparationMargin;
  bite: Bite;
  tangentialPoint: TangentialPoint;
  color: Color;
  translucency: Translucency;
  dentistRating: number | null;
}
