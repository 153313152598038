import create from "zustand";

type ChatMode = "chat-window" | "create-channel";

export type ChatStore = {
  totalUnreadCount: number;
  channelListOpened: boolean;
  chatMode: ChatMode;
  setTotalUnreadCount: (count: number) => void;
  setChatMode: (mode: ChatStore["chatMode"]) => void;
  setChannelListOpened: (opened: boolean) => void;
};

export const useChatStore = create<ChatStore>((set) => ({
  totalUnreadCount: 0,
  channelListOpened: false,
  chatMode: "chat-window",
  setTotalUnreadCount: (count: number) => set((state) => ({ ...state, totalUnreadCount: count })),
  setChatMode: (mode: ChatMode) => set((state) => ({ ...state, chatMode: mode })),
  setChannelListOpened: (opened: boolean) => set((state) => ({ ...state, channelListOpened: opened })),
}));
