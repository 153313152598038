import * as React from "react";
import useSWR from "swr";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import DentistApiContext from "../dentist.context";
import {
  IAssignDentistToPriceListRequest,
  ICreateDentistRequest,
  IDeleteDentistRequest,
  IEditDentistRequest,
} from "../dentist.model";

function useDentists() {
  const dentistApi = React.useContext(DentistApiContext);

  const {
    data: getAllDentistsResponse,
    isValidating: isValidating,
    mutate: revalidate,
  } = useSWR(
    parametrizeEndpointPath({
      path: ApiEndpointPath.GetAllDentists,
      params: { tenantId: "" },
    }),
    () => dentistApi.getDentists()
  );

  const createDentist = React.useCallback(
    async (request: ICreateDentistRequest) => dentistApi.createDentist(request),
    [dentistApi]
  );

  const editDentist = React.useCallback(
    async (request: IEditDentistRequest) => dentistApi.editDentist(request),
    [dentistApi]
  );

  const assignDentistToPriceList = React.useCallback(
    async (request: IAssignDentistToPriceListRequest) => dentistApi.assignDentistToPriceList(request),
    [dentistApi]
  );

  const deleteDentist = React.useCallback(
    async (request: IDeleteDentistRequest) => dentistApi.deleteDentist(request),
    [dentistApi]
  );

  return {
    getAllDentistsResponse: getAllDentistsResponse,
    createDentist,
    editDentist,
    deleteDentist,
    assignDentistToPriceList,
    isValidating: isValidating,
    revalidate: revalidate,
  };
}

export default useDentists;
