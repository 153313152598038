import * as React from "react";
import { useState } from "react";
import { LinkedPointsSpecification, SpecificationType } from "../../../specification/order-specification";
import { IPickedPoints } from "../../points-picker/points-picker.model";
import { OpenModalButton } from "./OpenModalButton";
import { SpecificationModal } from "./SpecificationModal";
import { SelectAtLeastTwoPoints } from "./alerts/SelectAtLeastTwoPoints";
import {
  allPossibleBridgeAnchorTypes,
  allPossibleFoundationAnchorTypes,
  allPossibleMarylandBridgeAnchorTypes,
} from "../../../order-type/anchor-type.const";
import { FollowingPointsAreNotLinked } from "./alerts/FollowingPointsAreNotLinked";
import { LinkPointsIntoGroups } from "./alerts/LinkPointsIntoGroups";
import { FollowingPointsDontHaveAnchorType } from "./alerts/FollowingPointsDontHaveAnchorType";
import PointSelection from "../../points-picker/selections/points-selection/PointSelection";

interface ILinkedPointsControlsProps {
  specification: LinkedPointsSpecification;
  onChange?: (specification: LinkedPointsSpecification) => void;
}

enum Step {
  PickLinkedPoints = "pick-linked-points",
  PickAnchorTypes = "pick-anchor-types",
}

type PickLinkedPointsControlsState = {
  step: Step;
  submitCount: Record<Step, number>;
  points: IPickedPoints;
  isModalVisible: boolean;
};

const PickLinkedPointsControls = (props: ILinkedPointsControlsProps) => {
  const [state, setState] = useState<PickLinkedPointsControlsState>({
    step: Step.PickLinkedPoints,
    submitCount: { [Step.PickLinkedPoints]: 0, [Step.PickAnchorTypes]: 0 },
    points: {
      singles: [],
      linked: [...props.specification.points],
    },
    isModalVisible: false,
  });

  const handleShowModal = React.useCallback(() => {
    setState({ ...state, isModalVisible: true });
  }, [state]);

  const hideModal = React.useCallback(() => {
    setState({ ...state, isModalVisible: false });
  }, [state]);

  const handlePointSelectionChange = React.useCallback(
    (pickedPoints: IPickedPoints) => {
      setState({ ...state, points: pickedPoints });
    },
    [state]
  );

  const handleGoBack = React.useCallback(() => {
    setState({ ...state, step: Step.PickLinkedPoints });
  }, [state]);

  const handleNextClick = React.useCallback(() => {
    if (state.step === Step.PickLinkedPoints) {
      if (state.points.singles.length !== 0 || state.points.linked.length === 0) {
        setState({
          ...state,
          submitCount: { ...state.submitCount, [state.step]: state.submitCount[state.step] + 1 },
        });
        return;
      }

      setState({
        ...state,
        step: Step.PickAnchorTypes,
        submitCount: { ...state.submitCount, [state.step]: state.submitCount[state.step] + 1 },
      });
    } else if (state.step === Step.PickAnchorTypes) {
      const allLinkedPointsCount = state.points.linked.flatMap((x) => x).length;

      const allLinkedAndAnchoredPointsCount = state.points.linked
        .flatMap((x) => x)
        .reduce((sum, curr) => (curr.anchor === null ? sum : sum + 1), 0);

      if (allLinkedAndAnchoredPointsCount < allLinkedPointsCount) {
        setState({
          ...state,
          submitCount: { ...state.submitCount, [state.step]: state.submitCount[state.step] + 1 },
        });
        return;
      }

      hideModal();

      props.onChange?.({
        specificationType: props.specification.specificationType,
        points: state.points.linked.map((b) => b.map((p) => ({ anchor: p.anchor!, location: p.location }))),
      } as any);
    }
  }, [hideModal, props, state]);

  const { step, submitCount, points } = state;
  const allLinkedPointsCount = state.points.linked.flatMap((x) => x).length;
  const allLinkedAndAnchoredPointsCount = state.points.linked
    .flatMap((x) => x)
    .reduce((sum, curr) => (curr.anchor === null ? sum : sum + 1), 0);

  return (
    <>
      <OpenModalButton specification={props.specification} onClick={handleShowModal} />
      <SpecificationModal
        isVisible={state.isModalVisible}
        onCancel={hideModal}
        onBack={handleGoBack}
        onNext={handleNextClick}
        isBackDisabled={state.step === Step.PickLinkedPoints}
        specification={props.specification}
      >
        {state.step === Step.PickLinkedPoints && (
          <>
            {points.linked.length === 0 && points.singles.length <= 1 && submitCount[Step.PickLinkedPoints] > 0 && (
              <SelectAtLeastTwoPoints />
            )}
            {points.linked.length === 0 && points.singles.length >= 2 && submitCount[Step.PickLinkedPoints] > 0 && (
              <LinkPointsIntoGroups specificationType={props.specification.specificationType} />
            )}
            {points.linked.length > 0 && points.singles.length > 0 && submitCount[Step.PickLinkedPoints] > 0 && (
              <FollowingPointsAreNotLinked specificationType={props.specification} points={points} />
            )}
            <PointSelection
              dotOptions={{ editable: true, visible: true }}
              value={points}
              onChange={handlePointSelectionChange}
            />
          </>
        )}
        {step === Step.PickAnchorTypes && (
          <>
            {allLinkedAndAnchoredPointsCount < allLinkedPointsCount && submitCount[Step.PickAnchorTypes] > 0 && (
              <FollowingPointsDontHaveAnchorType
                specificationType={props.specification.specificationType}
                points={state.points.linked
                  .flatMap((x) => x)
                  .filter((x) => x.anchor === null)
                  .map((x) => x.location)}
              />
            )}
            {props.specification.specificationType === SpecificationType.Foundations && (
              <PointSelection
                anchorOptions={{ visible: true, editable: true, allowedAnchors: allPossibleFoundationAnchorTypes }}
                dotOptions={{ visible: true, editable: false }}
                value={state.points}
                onChange={handlePointSelectionChange}
              />
            )}
            {props.specification.specificationType === SpecificationType.Bridges && (
              <PointSelection
                anchorOptions={{ visible: true, editable: true, allowedAnchors: allPossibleBridgeAnchorTypes }}
                dotOptions={{ visible: true, editable: false }}
                value={state.points}
                onChange={handlePointSelectionChange}
              />
            )}
            {props.specification.specificationType === SpecificationType.MarylandBridges && (
              <PointSelection
                anchorOptions={{ visible: true, editable: true, allowedAnchors: allPossibleMarylandBridgeAnchorTypes }}
                dotOptions={{ visible: true, editable: false }}
                value={state.points}
                onChange={handlePointSelectionChange}
              />
            )}
          </>
        )}
      </SpecificationModal>
    </>
  );
};

export default PickLinkedPointsControls;
