import * as React from "react";
import { useRef } from "react";
import { Button, List, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import useSize from "../../../../hooks/useSize";
import styles from "./SettingsSection.module.scss";

enum Size {
  Small = "sm",
  Medium = "mg",
}

export const SettingsSection: React.FunctionComponent<{
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void | Promise<void>;
  onRemove?: () => void;
  extraButtons?: React.ReactNode[];
}> = ({ isEditing, onEdit, onSave, onCancel, onRemove, extraButtons = [], children }) => {
  let ref = useRef(null);

  const [size] = useSize(ref, {
    [Size.Small]: 0,
    [Size.Medium]: 380,
  });

  const actions = isEditing
    ? [
        <Button key={"save"} type={"text"} onClick={onSave}>
          <FormattedMessage id="settings.save" defaultMessage={"Zapisz"} />
        </Button>,
        <Button key={"cancel"} type={"text"} onClick={onCancel}>
          <FormattedMessage id="settings.cancel" defaultMessage={"Anuluj"} />
        </Button>,
      ]
    : [
        <Button key={"edit"} type={"text"} onClick={onEdit}>
          <FormattedMessage id="settings.edit" defaultMessage={"Edycja"} />
        </Button>,
        ...(onRemove !== undefined
          ? [
              <Popconfirm
                title={<FormattedMessage id="common.remove-row-question-mark" defaultMessage={"Czy chcesz usunąć ?"} />}
                onConfirm={onRemove}
              >
                <Button key={"remove"} type={"text"}>
                  <FormattedMessage id="settings.remove" defaultMessage={"Usuń"} />
                </Button>
              </Popconfirm>,
            ]
          : []),
        ...extraButtons,
      ];

  return (
    <div ref={ref}>
      <div
        className={classNames(styles.container, {
          [styles.edit]: isEditing && size === Size.Small,
        })}
      >
        <List.Item actions={actions}>{children}</List.Item>
      </div>
    </div>
  );
};
