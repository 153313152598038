import * as React from "react";
import { useContext, useEffect } from "react";
import { AuthEmptyPageLayout } from "../../components/auth-page-layout/AuthPageLayout";
import queryString from "query-string";
import { isNullOrEmpty } from "core/utils/string-utils";
import { Result, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";
import { Path } from "core/routes/routes";
import AuthServiceContext from "../../auth-service.context";
import { Navigate, useLocation } from "react-router";
import { Helmet } from "react-helmet";

interface IVerifyEmailPageProps {}

type IVerifyEmailQueryParams = {
  email: string;
  company: string;
  token: string;
};

const VerifyEmailPage: React.FC<IVerifyEmailPageProps> = (props) => {
  const authService = useContext(AuthServiceContext);
  let location = useLocation();

  const { flag: emailConfirmed, check: markAsConfirmed } = useBooleanFlag(false);
  const { flag: isLoading, check: startLoading, uncheck: finishLoading } = useBooleanFlag(false);

  const query = queryString.parse(location.search) as IVerifyEmailQueryParams;

  // @ts-ignore
  useEffect(() => {
    if (emailConfirmed) {
      return;
    }

    let isMounted = true;

    startLoading();

    authService
      .confirmEmail({
        email: query.email,
        token: query.token,
        company: query.company,
      })
      .then(() => {
        if (!isMounted) {
          return;
        }
        finishLoading();
        markAsConfirmed();
      });

    return () => {
      isMounted = false;
    };
  }, [
    authService,
    emailConfirmed,
    finishLoading,
    markAsConfirmed,
    query.company,
    query.email,
    query.token,
    startLoading,
  ]);

  const pageTitle = (
    <FormattedMessage id={"page-titles.email-verification"} defaultMessage={"Weryfikacja email"}>
      {(title) => (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
    </FormattedMessage>
  );

  if (emailConfirmed) {
    return (
      <>
        {pageTitle}
        <Navigate to={Path.Login} replace={true} />
      </>
    );
  }

  if (isNullOrEmpty(query.company) || isNullOrEmpty(query.token) || isNullOrEmpty(query.email)) {
    return (
      <>
        {pageTitle}
        <AuthEmptyPageLayout>
          <Result status={"error"}>
            <span>
              <FormattedMessage
                id="verify-email-page.confirm-email-link-is-broken"
                defaultMessage="link do potwierdzania adresu email jest uszkodzony, proszę zgłoś problem do pomocy technicznej"
              />
            </span>
          </Result>
        </AuthEmptyPageLayout>
      </>
    );
  }

  return (
    <>
      {pageTitle}
      <AuthEmptyPageLayout>
        <div>{isLoading && <Spin />}</div>
      </AuthEmptyPageLayout>
    </>
  );
};

export default VerifyEmailPage;
