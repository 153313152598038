import React, { forwardRef, FunctionComponent } from "react";

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { green, red } from "@ant-design/colors";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

interface IStateIconProps extends Omit<AntdIconProps, "ref" | "size" | "checked"> {
  checked: boolean | undefined | null;
  twoTone?: boolean;
  size?: "small" | "large";
}

const StateIcon: FunctionComponent<IStateIconProps> = forwardRef<any, IStateIconProps>(
  ({ checked, twoTone = true, size = "small", style, ...iconProps }, ref) => {
    if (twoTone) {
      return checked ? (
        <CheckCircleTwoTone
          ref={ref}
          {...iconProps}
          twoToneColor={green[6]}
          style={size === "large" ? { fontSize: 26, ...style } : style}
        />
      ) : (
        <CloseCircleTwoTone
          ref={ref}
          {...iconProps}
          twoToneColor={red[5]}
          style={size === "large" ? { fontSize: 26, ...style } : style}
        />
      );
    } else {
      return checked ? (
        <CheckCircleOutlined
          ref={ref}
          {...iconProps}
          style={size === "large" ? { fontSize: 26, ...style } : style}
        />
      ) : (
        <CloseCircleOutlined
          ref={ref}
          {...iconProps}
          style={size === "large" ? { fontSize: 26, ...style } : style}
        />
      );
    }
  }
);

export default StateIcon;
