import DomainError, { IDomainError } from "core/errors/domain-error";
import { IResult } from "core/lib/types/result";
import { IHttpService } from "core/http/http.service";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { Err } from "core/lib/types/error";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ErrorCode } from "core/errors/error-code";
import { IAuthService } from "../../auth/auth.service";
import { getDomainError, isErrorResponse } from "core/http/http.functions";
import { IInvoicingSettings } from "../models/settings.models";
import { Ok } from "core/lib/types/ok";
import { mapDTOtoModel, mapModelToDto } from "../mappers/invoicing-settings.mappers";
import {
  IGetInvoicingSettingsResponseDTO,
  IUpdateInvoicingSettingsResponseDTO,
} from "../contracts/settings.responses.dto";
import { IUpdateInvoicingSettingsRequestDTO } from "../contracts/settings.requests.dto";

export interface ISettingsService {
  getInvoicingSettings: () => Promise<IResult<IInvoicingSettings>>;
  updateInvoiceSettings: (invoicingSettings: IInvoicingSettings) => Promise<IResult<IInvoicingSettings>>;
}

class SettingsHttpService implements ISettingsService {
  private readonly httpService: IHttpService;
  private readonly authService: IAuthService;

  constructor(httpService: IHttpService, authService: IAuthService) {
    this.httpService = httpService;
    this.authService = authService;
  }

  getInvoicingSettings = async (): Promise<IResult<IInvoicingSettings>> => {
    const { isLoggedIn, tenantId } = await this.authService.getTenantId();

    if (!isLoggedIn) {
      return new Err<never, IDomainError>(new DomainError(ErrorCode.InternalError, "not authenticated"));
    }

    const response = await this.httpService.get<IGetInvoicingSettingsResponseDTO>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetInvoicingSettings,
        params: {
          tenantId: tenantId!.value,
        },
      })
    );

    const responseBody = response.data;

    if (isErrorResponse(responseBody)) {
      const domainError = getDomainError(responseBody);
      return new Err<IInvoicingSettings, IDomainError>(domainError);
    }

    return new Ok<IInvoicingSettings>(mapDTOtoModel(responseBody.result));
  };

  updateInvoiceSettings = async (invoicingSettings: IInvoicingSettings): Promise<IResult<IInvoicingSettings>> => {
    const { isLoggedIn, tenantId } = await this.authService.getTenantId();

    if (!isLoggedIn) {
      return new Err<never, IDomainError>(new DomainError(ErrorCode.InternalError, "not authenticated"));
    }

    const dto = mapModelToDto(invoicingSettings);

    const response = await this.httpService.post<
      IUpdateInvoicingSettingsResponseDTO,
      IUpdateInvoicingSettingsRequestDTO
    >(
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetInvoicingSettings,
        params: { tenantId: tenantId!.value },
      }),
      {
        invoicingSettings: dto,
      }
    );

    const responseBody = response.data;

    if (isErrorResponse(responseBody)) {
      const domainError = getDomainError(responseBody);
      return new Err<IInvoicingSettings, IDomainError>(domainError);
    }

    return new Ok<IInvoicingSettings>(mapDTOtoModel(responseBody.result));
  };
}

export default SettingsHttpService;
