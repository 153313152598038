import React from "react";
import { ChannelListMessengerProps} from "stream-chat-react";

import "./ChannelList.scss";
import { SkeletonLoader } from "features/chat/pages/chat-page/components/ChannelList/SkeletonLoader";


const CreateChannelIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17.9708H0V13.7278L13.435 0.292787C13.8255 -0.0975955 14.4585 -0.0975955 14.849 0.292787L17.678 3.12179C18.0684 3.51229 18.0684 4.14529 17.678 4.53579L6.243 15.9708H18V17.9708ZM2 15.9708H3.414L12.728 6.65679L11.314 5.24279L2 14.5568V15.9708ZM15.556 3.82879L14.142 5.24279L12.728 3.82879L14.142 2.41479L15.556 3.82879Z"
      fill="#E9E9EA"
    />
  </svg>
);

type Props = ChannelListMessengerProps & {
  onCreateChannel: () => void;
};

const MessagingChannelList: React.FC<Props> = (props) => {
  const { children, error = false, loading, onCreateChannel } = props;

  const ListHeaderWrapper: React.FC = ({ children }) => {
    return (
      <div className="channel-list">
        <div className="channel-list__header">
          <span>Wiadomości</span>
          <button className="channel-list__header__button" onClick={onCreateChannel}>
            <CreateChannelIcon />
          </button>
        </div>
        {children}
      </div>
    );
  };

  if (error) {
    return (
      <ListHeaderWrapper>
        <div className="channel-list__message">Error loading conversations, please try again momentarily.</div>
      </ListHeaderWrapper>
    );
  }

  if (loading) {
    return (
      <ListHeaderWrapper>
        <div className="channel-list__message">
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    );
  }

  return <ListHeaderWrapper>{children}</ListHeaderWrapper>;
};

export default React.memo(MessagingChannelList);
