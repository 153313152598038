import React from "react";
import { Form, Radio} from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { Gender } from "core/gender/gender.model";

export const PatientGenderControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={<FormattedMessage id="common.gender" defaultMessage="Płeć" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      control={props.control}
      name="patientGender"
      render={({ field: { onChange, value } }) => (
        <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
          <Radio value={Gender.Female}>
            <FormattedMessage id="common.female" defaultMessage="Kobieta" />
          </Radio>
          <Radio value={Gender.Male}>
            <FormattedMessage id="common.male" defaultMessage="Mężczyzna" />
          </Radio>
          <Radio value={Gender.Unknown}>
            <FormattedMessage id="common.gender-unknown" defaultMessage="Płeć nieznana" />
          </Radio>
        </Radio.Group>
      )}
    />
  </Form.Item>
);
