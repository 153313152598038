import { Country } from "./countries.model";

export const europeanCountries: Country[] = [
  Country.Austria,
  Country.Belgium,
  Country.Bulgaria,
  Country.Cyprus,
  Country.CzechRepublic,
  Country.Germany,
  Country.Denmark,
  Country.Estonia,
  Country.Spain,
  Country.Finland,
  Country.France,
  Country.UnitedKingdom,
  Country.Greece,
  Country.Hungary,
  Country.Croatia,
  Country.Ireland,
  Country.Italy,
  Country.Lithuania,
  Country.Luxembourg,
  Country.Latvia,
  Country.Malta,
  Country.Netherlands,
  Country.Poland,
  Country.Portugal,
  Country.Romania,
  Country.Sweden,
  Country.Slovenia,
  Country.Slovakia,
];
