import React from "react";
import useAccess from "features/access/useAccess";
import CreateLabOrderView from "features/orders/pages/create/components/CreateLabOrderView";
import useAuth from "features/auth/hooks/useAuth";
import CreateDentistOrderView from "features/orders/pages/create/components/CreateDentistOrderView";

export interface ICreateOrderPageProps {}

const CreateOrderPage: React.FunctionComponent<ICreateOrderPageProps> = () => {
  const { isDentist, isLabOwner, isLabEmployee } = useAccess();
  const { authContext } = useAuth();

  if (isDentist) {
    return <CreateDentistOrderView userId={authContext.userId!} />;
  } else if (isLabEmployee || isLabOwner) {
    return <CreateLabOrderView labId={authContext.labId!} />;
  } else {
    return null;
  }
};

export default CreateOrderPage;
