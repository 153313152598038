import { InvoiceDTO } from "../contracts/invoice.dto";
import {
  IBillingStatement,
  NewBillingStatementId,
  NewBillingStatementLineId,
} from "../models/invoicing.models";
import { honorificCodeToHonorific } from "core/person-name/honorific.functions";
import { IBillingStatementDTO } from "features/invoicing/contracts/invoices.response.dto";
import { NewDentistId } from "features/dentist/dentist.model";
import { NewOrderId } from "features/orders/order.model";

export const mapBillingStatementDTOToBillingStatement = (dto: IBillingStatementDTO): IBillingStatement => {
  const statement: IBillingStatement = {
    id: NewBillingStatementId(dto.id),
    grandTotal: dto.grandTotal,
    statementNumber: dto.statementNumber,
    createdOn: dto.createdOn,
    recipient: {
      id: NewDentistId(dto.recipient.id),
      name: {
        firstName: dto.recipient.name.firstName,
        lastName: dto.recipient.name.lastName,
        honorific: honorificCodeToHonorific(dto.recipient.name.honorific),
      },
      clinicName: dto.recipient.clinicName,
    },
    lineItems: dto.lineItems.map((x) => {
      const y: IBillingStatement["lineItems"][0] = {
        id: NewBillingStatementLineId(x.id),
        orderId: x.orderId == null ? null : NewOrderId(x.orderId),
        orderNumber: x.orderNumber == null ? null : x.orderNumber,
        dentistId: x.dentistId == null ? null : NewDentistId(x.dentistId),
        description: x.description,
        patient: x.patient,
        price: x.price,
      };

      return y;
    }),
    notes: dto.notes,
  };

  return statement;
};

export const mapInvoiceDTOtoInvoice = (dto: InvoiceDTO): IBillingStatement => {
  throw new Error("asd");
  // const invoice: IBillingStatement = {
  //   id: {
  //     type: "billing-statement-id",
  //     value: dto.invoiceId,
  //   },
  //   invoiceNumber: dto.invoiceNumber,
  //   issuer: {
  //     companyName: dto.issuer.companyName,
  //     address: mapAddressDtoToAddress(dto.issuer.address),
  //     taxId: mapTaxIdDTOToTaxId(dto.issuer.taxId),
  //   },
  //   recipient: {
  //     companyName: dto.recipient.companyName,
  //     address: mapAddressDtoToAddress(dto.recipient.address),
  //     taxId: mapTaxIdDTOToTaxId(dto.recipient.taxId),
  //   },
  //   dueDate: dto.dueDate,
  //   issueDate: dto.issueDate,
  //   createdAt: dto.createdAt,
  //   sellDate: dto.sellDate,
  //   grandTotal: {
  //     currency: dto.grandTotal.currency,
  //     amount: dto.grandTotal.amount,
  //   },
  //   notes: dto.notes,
  //   isPaid: dto.isPaid,
  //   invoicedItems: dto.invoicedItems.map((ie) => {
  //     const invoicedItem: InvoicedItem = {
  //       invoicedItemId: { type: "invoice-item-id", value: ie.invoicedItemId },
  //       invoiceId: { type: "billing-statement-id", value: ie.invoiceId },
  //       invoiceNumber: ie.invoiceNumber,
  //       orderId: { type: "order-id", value: ie.orderId },
  //       orderNumber: ie.orderNumber,
  //       description: ie.description,
  //       recipient:
  //         ie.recipient === null
  //           ? null
  //           : {
  //               dentistId: {
  //                 type: "dentist-id",
  //                 value: ie.recipient.dentistId,
  //               },
  //               name: {
  //                 honorific: honorificCodeToHonorific(ie.recipient.name.honorific),
  //                 firstName: ie.recipient.name.firstName,
  //                 lastName: ie.recipient.name.lastName,
  //               },
  //             },
  //       patient: ie.patient,
  //       price: ie.price,
  //     };
  //
  //     return invoicedItem;
  //   }),
  // };

  // return invoice;
};
