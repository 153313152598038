import { IApiErrorResponse } from "contracts/envelope/api-response-envelope";
import { invalidArgumentError } from "core/errors/generate-error";
import DomainError from "core/errors/domain-error";

export const isErrorResponse = (response: any): response is IApiErrorResponse => {
  return (
    response !== null &&
    response !== undefined &&
    typeof response === "object" &&
    response.errorCode != null &&
    response.errorMessage != null
  );
};

export const getDomainError = (response: IApiErrorResponse): DomainError => {
  const { errorCode, errorMessage, errorParameters, errorDetails } = response;

  if (errorCode === null) {
    throw invalidArgumentError("response does not contain error code");
  }

  return new DomainError(
    errorCode,
    errorMessage === null ? "" : errorMessage,
    errorParameters,
    errorDetails,
  );
};

export const objectToQueryString = (obj: object): string => {
  const str = [];

  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (Array.isArray((obj as any)[p])) {
        const arrayValue = (obj as any)[p];

        arrayValue.forEach((element: any) => {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(element));
        });
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent((obj as any)[p]));
      }
    }
  }
  return str.join("&");
};

export const appendQueryString = (path: string, obj: object | null | undefined): string => {
  if (typeof obj !== "object") {
    return path;
  }

  if (obj === null || obj === undefined) {
    return path;
  }

  if (Object.keys(obj).length === 0) {
    return path;
  }

  return `${path}?${objectToQueryString(obj)}`;
};
