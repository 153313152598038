import * as React from "react";
import {
  VitaClassicalShade,
  Vita3dMasterShade,
  BleachShade,
} from "features/orders/color/color.model";

interface IShadeFormatterProps {
  shade: VitaClassicalShade | Vita3dMasterShade | BleachShade;
}

const decomposeShade = (shade: VitaClassicalShade | Vita3dMasterShade | BleachShade) => {
  const shadeAsString: string = shade;
  const [shadePrefix, shadeName] = shadeAsString.split("-");

  return {
    shadePrefix,
    shadeName,
  };
};

const ShadeFormatter: React.FunctionComponent<IShadeFormatterProps> = ({ shade }) => {
  const { shadeName } = decomposeShade(shade);
  return <>{shadeName}</>;
};

export default ShadeFormatter;
