import * as React from "react";
import { useCallback, useState } from "react";
import classNames from "classnames";
import MoneyInput from "../../../../../components/forms/money-input/MoneyInput";
import { SpecificationType } from "features/orders/specification/order-specification";
import PricingFormTypeSelect from "../pricing-form-type-select/PricingFormTypeSelect";
import { mapFormValuesToPricing, mapPricingToInitialFormValues } from "./pricing-form.functions";
import {
  ArchEqualPricingFormValues,
  BridgePointEqualPricingFormValues,
  CrownEqualPricingFormValues,
  FixedPricingFormValues,
  FrontAndBackBridgePointPricingFormValues,
  FrontAndBackCrownPricingFormValues,
  FrontAndBackPointPricingFormValues,
  IPricingFormValues,
  MergedPricingFormValues,
  PointWithBaseArchPricingFormValues,
  PricingFormType,
  QuantityPricingFormValues,
  UpperAndLowerArchFormValues,
} from "./pricing-input.models";
import { Money } from "core/money/money.functions";
import { IMoney } from "core/money/money.model";
import { nameof } from "ts-simple-nameof";
import { mapSpecificationTypeToPricingFormTypes } from "../pricing-form-type-select/pricing-form-type-select.functions";
import { FormattedMessage } from "react-intl";
import { Pricing } from "core/pricing/pricing.model";

import "./pricing-input.scss";

export interface IPricingInputProps {
  requiredSpecification: SpecificationType;
  value: Pricing;
  oneColumn?: boolean;
  onChange: (pricing: Pricing) => void;
}

const formNames: Record<keyof MergedPricingFormValues, string> = {
  fixedPrice: nameof<FixedPricingFormValues>((_) => _.fixedPrice),
  itemPrice: nameof<QuantityPricingFormValues>((_) => _.itemPrice),
  baseArchPrice: nameof<PointWithBaseArchPricingFormValues>((_) => _.baseArchPrice),
  archPrice1: nameof<ArchEqualPricingFormValues>((_) => _.archPrice1),
  archPrice2: nameof<UpperAndLowerArchFormValues>((_) => _.archPrice2),
  point1: nameof<FrontAndBackPointPricingFormValues>((_) => _.point1),
  point2: nameof<FrontAndBackPointPricingFormValues>((_) => _.point2),
  cementedToPreparedTooth1: nameof<CrownEqualPricingFormValues>((_) => _.cementedToPreparedTooth1),
  cementedToImplant1: nameof<CrownEqualPricingFormValues>((_) => _.cementedToImplant1),
  screwedToImplant1: nameof<CrownEqualPricingFormValues>((_) => _.screwedToImplant1),
  cementedToPreparedTooth2: nameof<FrontAndBackCrownPricingFormValues>((_) => _.cementedToPreparedTooth2),
  cementedToImplant2: nameof<FrontAndBackCrownPricingFormValues>((_) => _.cementedToImplant2),
  screwedToImplant2: nameof<FrontAndBackCrownPricingFormValues>((_) => _.screwedToImplant2),
  pontic1: nameof<BridgePointEqualPricingFormValues>((_) => _.pontic1),
  pontic2: nameof<FrontAndBackBridgePointPricingFormValues>((_) => _.pontic2),
};

const PricingInput: React.FunctionComponent<IPricingInputProps> = ({
  requiredSpecification,
  value,
  oneColumn = false,
  onChange,
}) => {
  const allPossibleFormTypes = mapSpecificationTypeToPricingFormTypes(requiredSpecification);
  const [formValues, setFormValues] = useState<IPricingFormValues>(mapPricingToInitialFormValues(value));

  const setFormValuesAndTriggerChange = useCallback(
    (pricingFormValues: IPricingFormValues) => {
      setFormValues(pricingFormValues);
      const pricing = mapFormValuesToPricing(pricingFormValues);
      onChange?.(pricing);
    },
    [onChange]
  );

  const onFormTypeChange = React.useCallback(
    (pricingFormType: PricingFormType) => {
      if (formValues.formType === pricingFormType) {
        return;
      }

      switch (pricingFormType) {
        case PricingFormType.IndividualPricing:
          setFormValuesAndTriggerChange({ formType: PricingFormType.IndividualPricing });
          break;
        case PricingFormType.FixedPrice:
          setFormValuesAndTriggerChange({ formType: PricingFormType.FixedPrice, fixedPrice: Money.PLN(0) });
          break;
        case PricingFormType.Quantity:
          setFormValuesAndTriggerChange({ formType: PricingFormType.Quantity, itemPrice: Money.PLN(0) });
          break;
        case PricingFormType.ArchEqualPrices:
          setFormValuesAndTriggerChange({ formType: PricingFormType.ArchEqualPrices, archPrice1: Money.PLN(0) });
          break;
        case PricingFormType.UpperAndLowerArchPrices:
          setFormValuesAndTriggerChange({
            formType: PricingFormType.UpperAndLowerArchPrices,
            archPrice1: Money.PLN(0),
            archPrice2: Money.PLN(0),
          });
          break;
        case PricingFormType.PointEqualPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.PointEqualPrices,
            point1: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.FrontAndBackPointPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.FrontAndBackPointPrices,
            point1: Money.PLN(0),
            point2: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.PointWithBaseArchPrice: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.PointWithBaseArchPrice,
            itemPrice: Money.PLN(0),
            baseArchPrice: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.CrownEqualPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.CrownEqualPrices,
            cementedToPreparedTooth1: Money.PLN(0),
            screwedToImplant1: Money.PLN(0),
            cementedToImplant1: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.FrontAndBackCrownPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.FrontAndBackCrownPrices,
            cementedToPreparedTooth1: Money.PLN(0),
            screwedToImplant1: Money.PLN(0),
            cementedToImplant1: Money.PLN(0),
            cementedToPreparedTooth2: Money.PLN(0),
            screwedToImplant2: Money.PLN(0),
            cementedToImplant2: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.BridgePointEqualPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.BridgePointEqualPrices,
            cementedToImplant1: Money.PLN(0),
            screwedToImplant1: Money.PLN(0),
            cementedToPreparedTooth1: Money.PLN(0),
            pontic1: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.FrontAndBackBridgePointPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.FrontAndBackBridgePointPrices,
            cementedToImplant1: Money.PLN(0),
            cementedToPreparedTooth1: Money.PLN(0),
            screwedToImplant1: Money.PLN(0),
            pontic1: Money.PLN(0),
            cementedToImplant2: Money.PLN(0),
            cementedToPreparedTooth2: Money.PLN(0),
            screwedToImplant2: Money.PLN(0),
            pontic2: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.MarylandBridgePointEqualPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.MarylandBridgePointEqualPrices,
            cementedToPreparedTooth1: Money.PLN(0),
            pontic1: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.FrontAndBackMarylandBridgePointPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.FrontAndBackMarylandBridgePointPrices,
            cementedToPreparedTooth1: Money.PLN(0),
            pontic1: Money.PLN(0),
            cementedToPreparedTooth2: Money.PLN(0),
            pontic2: Money.PLN(0),
          });
          break;
        }
        case PricingFormType.FoundationPointEqualPrices: {
          setFormValuesAndTriggerChange({
            formType: PricingFormType.FoundationPointEqualPrices,
            cementedToImplant1: Money.PLN(0),
            screwedToImplant1: Money.PLN(0),
            pontic1: Money.PLN(0),
          });
          break;
        }
      }
    },
    [formValues.formType, setFormValuesAndTriggerChange]
  );

  const handleChangePrice = React.useCallback(
    (formType: PricingFormType, formPropertyName: string /* keyof IPricingFormValues */) => (price: IMoney) => {
      const next = { ...formValues, formType: formType, [formPropertyName]: price } as IPricingFormValues;
      setFormValuesAndTriggerChange(next);
    },
    [formValues, setFormValuesAndTriggerChange]
  );

  return (
    <div
      className={classNames("pricing-input", {
        "pricing-input--one-column": oneColumn === true,
        "pricing-input--two-columns": oneColumn === false,
      })}
    >
      <div className="pricing-input__label">
        <FormattedMessage id={"pricing-input.pricing-type"} defaultMessage={"Typ wyceny"} />
      </div>
      <div className="pricing-input__input">
        <PricingFormTypeSelect
          allowedTypes={allPossibleFormTypes}
          value={formValues.formType}
          onChange={onFormTypeChange}
        />
      </div>
      {formValues.formType === PricingFormType.Quantity && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.price"} defaultMessage={"Cena"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.itemPrice}
              onChange={handleChangePrice(formValues.formType, formNames.itemPrice)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.FixedPrice && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.fixed-price"} defaultMessage={"Stała cena"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.fixedPrice}
              onChange={handleChangePrice(formValues.formType, formNames.fixedPrice)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.ArchEqualPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.arch-price"} defaultMessage={"Cena za łuk"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.archPrice1}
              onChange={handleChangePrice(formValues.formType, formNames.archPrice1)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.PointEqualPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.point-equal-prices-label-1"} defaultMessage={"Cena za punkt"} />
            {/*  TODO: zmienić nazwę na cena za sztukę */}
          </div>
          <div className="pricing-input__input">
            <MoneyInput value={formValues.point1} onChange={handleChangePrice(formValues.formType, formNames.point1)} />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.UpperAndLowerArchPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.upper-arch-price"} defaultMessage={"Cena za górny łuk"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.archPrice1}
              onChange={handleChangePrice(formValues.formType, formNames.archPrice1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.lower-arch-price"} defaultMessage={"Cena za dolny łuk"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.archPrice2}
              onChange={handleChangePrice(formValues.formType, formNames.archPrice2)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.FrontAndBackPointPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.front-and-back-point-prices-label-1"} defaultMessage={"Cena za przedni punkt"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput value={formValues.point1} onChange={handleChangePrice(formValues.formType, formNames.point1)} />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.front-and-back-point-prices-label-2"} defaultMessage={"Cena za tylni punkt"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput value={formValues.point2} onChange={handleChangePrice(formValues.formType, formNames.point2)} />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.PointWithBaseArchPrice && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.base-price"} defaultMessage={"Cena bazowa"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.baseArchPrice}
              onChange={handleChangePrice(formValues.formType, formNames.baseArchPrice)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.per-point-price"} defaultMessage={"Cena za punkt"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.itemPrice}
              onChange={handleChangePrice(formValues.formType, formNames.itemPrice)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.CrownEqualPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-crown-cemented-to-tooth"}
              defaultMessage={"Cena za koronę cementowaną do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-crown-cemented-to-implant"}
              defaultMessage={"Cena za koronę cementowaną do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-crown-screwed-to-implant"}
              defaultMessage={"Cena za koronę przykręcaną na implancie"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant1)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.FrontAndBackCrownPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-crown-cemented-to-tooth"}
              defaultMessage={"Cena za przednią koronę cementowaną do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-crown-cemented-to-implant"}
              defaultMessage={"Cena za przednią koronę cementowaną do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-crown-screwed-to-implant"}
              defaultMessage={"Cena za przednią koronę przykręcaną na implancie"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-crown-cemented-to-tooth"}
              defaultMessage={"Cena za tylną koronę cementowaną do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth2}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth2)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-crown-cemented-to-implant"}
              defaultMessage={"Cena za tylną koronę cementowaną do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant2}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant2)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-crown-screwed-to-implant"}
              defaultMessage={"Cena za tylną koronę przykręcaną na implancie"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant2}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant2)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.BridgePointEqualPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-bridge-point-cemented-to-tooth"}
              defaultMessage={"Cena za punkt cementowany do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.price-for-bridge-point-pontic"} defaultMessage={"Cena za przęsło"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic1}
              onChange={handleChangePrice(formValues.formType, formNames.pontic1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-bridge-point-cemented-to-implant"}
              defaultMessage={"Cena za punkt cementowany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-bridge-point-screwed-to-implant"}
              defaultMessage={"Cena za punkt przykręcany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant1)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.FrontAndBackBridgePointPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-bridge-point-cemented-to-tooth"}
              defaultMessage={"Cena za przedni punkt cementowany do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-bridge-point-pontic"}
              defaultMessage={"Cena za przednie przęsło"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic1}
              onChange={handleChangePrice(formValues.formType, formNames.pontic1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-bridge-point-cemented-to-implant"}
              defaultMessage={"Cena za przedni punkt cementowany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-bridge-point-screwed-to-implant"}
              defaultMessage={"Cena za przedni punkt przykręcany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-bridge-point-cemented-to-tooth"}
              defaultMessage={"Cena za tylni punkt cementowany do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth2}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth2)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-bridge-point-pontic"}
              defaultMessage={"Cena za tylnie przęsło"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic2}
              onChange={handleChangePrice(formValues.formType, formNames.pontic2)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-bridge-point-cemented-to-implant"}
              defaultMessage={"Cena za tylni punkt cementowany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant2}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant2)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-bridge-point-screwed-to-implant"}
              defaultMessage={"Cena za tylni punkt przykręcany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant2}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant2)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.MarylandBridgePointEqualPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-maryland-bridge-point-cemented-to-tooth"}
              defaultMessage={"Cena za punkt cementowany do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-maryland-bridge-point-pontic"}
              defaultMessage={"Cena za przęsło"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic1}
              onChange={handleChangePrice(formValues.formType, formNames.pontic1)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.FrontAndBackMarylandBridgePointPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-maryland-bridge-point-cemented-to-tooth"}
              defaultMessage={"Cena za przedni punkt cementowany do zęba"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-maryland-bridge-point-pontic"}
              defaultMessage={"Cena za przednie przęsło"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic1}
              onChange={handleChangePrice(formValues.formType, formNames.pontic1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-front-maryland-bridge-point-screwed-to-implant"}
              defaultMessage={"Cena za przedni punkt przykręcany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToPreparedTooth2}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToPreparedTooth2)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-back-maryland-bridge-point-pontic"}
              defaultMessage={"Cena za tylnie przęsło"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic2}
              onChange={handleChangePrice(formValues.formType, formNames.pontic2)}
            />
          </div>
        </>
      )}
      {formValues.formType === PricingFormType.FoundationPointEqualPrices && (
        <>
          <div className="pricing-input__label">
            <FormattedMessage id={"pricing-input.price-for-bridge-point-pontic"} defaultMessage={"Cena za przęsło"} />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.pontic1}
              onChange={handleChangePrice(formValues.formType, formNames.pontic1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-bridge-point-cemented-to-implant"}
              defaultMessage={"Cena za punkt cementowany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.cementedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.cementedToImplant1)}
            />
          </div>
          <div className="pricing-input__label">
            <FormattedMessage
              id={"pricing-input.price-for-bridge-point-screwed-to-implant"}
              defaultMessage={"Cena za punkt przykręcany do implantu"}
            />
          </div>
          <div className="pricing-input__input">
            <MoneyInput
              value={formValues.screwedToImplant1}
              onChange={handleChangePrice(formValues.formType, formNames.screwedToImplant1)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PricingInput;
