export enum ColorCode {
  VC_0M1 = 131073,
  VC_0M2 = 131074,
  VC_0M3 = 131075,
  VC_A1 = 131076,
  VC_A2 = 131077,
  VC_A3 = 131078,
  VC_A3_5 = 131079,
  VC_A4 = 131080,
  VC_B1 = 131081,
  VC_B2 = 131082,
  VC_B3 = 131083,
  VC_B4 = 131084,
  VC_C1 = 131085,
  VC_C2 = 131086,
  VC_C3 = 131087,
  VC_C4 = 131088,
  VC_D2 = 131089,
  VC_D3 = 131090,
  VC_D4 = 131091,
  V3D_0_M1 = 65537,
  V3D_0_M2 = 65538,
  V3D_0_M3 = 65539,
  V3D_1M1 = 65540,
  V3D_1M2 = 65541,
  V3D_2L1_5 = 65542,
  V3D_2L2_5 = 65543,
  V3D_2M1 = 65544,
  V3D_2M2 = 65545,
  V3D_2M3 = 65546,
  V3D_2R1_5 = 65547,
  V3D_2R2_5 = 65548,
  V3D_3L1 = 65549,
  V3D_3L2_5 = 65550,
  V3D_3M1 = 65551,
  V3D_3M2 = 65552,
  V3D_3M3 = 65553,
  V3D_3R1_5 = 65554,
  V3D_3R2_5 = 65555,
  V3D_4L1_5 = 65556,
  V3D_4L2_5 = 65557,
  V3D_4M1 = 65558,
  V3D_4M2 = 65559,
  V3D_4M3 = 65560,
  V3D_4R1_5 = 65561,
  V3D_4R2_5 = 65562,
  V3D_5M1 = 65563,
  V3D_5M2 = 65564,
  V3D_5M3 = 65565,
  BL1 = 196609,
  BL2 = 196610,
  BL3 = 196611,
  BL4 = 196612,
}
