import React from "react";
import { Row, Col, Button } from "antd";
import { companyName } from "core/global/global-config";

const Footer = () => (
  <footer id="footer" className="dark">
    <div className="footer-wrap">
      <Row>
        <Col lg={6} sm={24} xs={24}>
          <div className="footer-center">
            <h2>DentiMate</h2>
            <div>
              <a target="_blank " href="https://google.com">
                Regulamin
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Polityka prywatności
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Kontakt
              </a>
            </div>
          </div>
        </Col>
        <Col lg={6} sm={24} xs={24}>
          <div className="footer-center">
            <h2>Dokumentacja / Pomoc</h2>
            <div>
              <a target="_blank " href="https://google.com">
                Zamówienia
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Zarządzanie bazą dentystów
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Fakturowanie
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Zarządzanie pracownikami
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Przypisywanie do prac
              </a>
            </div>
            <div>
              <a target="_blank " href="https://google.com">
                Płatne pakiety
              </a>
            </div>
          </div>
        </Col>
        <Col lg={6} sm={24} xs={24}>
          <div className="footer-center">
            <h2>Social media</h2>
            <div>
              <a href="#">Facebook</a>
            </div>
          </div>
        </Col>
        <Col lg={6} sm={24} xs={24}>
          <div className="footer-center">
            <h2>Kontakt</h2>
            <div>
              <a rel="noopener" href="http://google.com">
                Email
              </a>
              <span> - </span>
              {/*<span></span>*/}
            </div>
            <div>
              <a rel="noopener" href="https://google.com/">
                Telegram
              </a>
              <span> - </span>
              {/*<span>+123456789</span>*/}
            </div>
            <div>
              <a rel="noopener" href="https://google.com/">
                Whats App
              </a>
              <span> - </span>
              {/*<span>+123456789</span>*/}
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <Row className="bottom-bar">
      <Col lg={6} sm={24}>
        <div className="translate-button">
          <Button ghost size="small">
            Polski
          </Button>
        </div>
      </Col>
      <Col lg={18} sm={24}>
          <span
            style={{
              lineHeight: "16px",
              paddingRight: 12,
              marginRight: 11,
              borderRight: "1px solid rgba(255, 255, 255, 0.55)",
            }}
          >
            <a href="https://google.com" rel="noopener noreferrer">
              Polityka prywatności
            </a>
          </span>
        <span style={{ marginRight: 24 }}>
            <a href="https://google.com" rel="noopener noreferrer">
              Regulamin
            </a>
          </span>
        <span style={{ marginRight: 12 }}>Copyright © {companyName}</span>
      </Col>
    </Row>
  </footer>
);

export default Footer;
