import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import QueueAnim from "rc-queue-anim";
// @ts-ignore
import TweenOne from "rc-tween-one";
import BannerSVGAnim from "./component/BannerSVGAnim";
import Links from "components/links/Links";

import bannerSvg from "./svg/banner.svg";
import Logo, { LogoType } from "components/logo/Logo";

const Banner: React.FunctionComponent<{ isMobile: boolean }> = (props) => {
  return (
    <div className="banner-wrapper">
      {props.isMobile && (
        <TweenOne animation={{ opacity: 1 }} className="banner-image-wrapper">
          <div className="home-banner-image">
            <img alt="banner" src={bannerSvg} width="100%" />
          </div>
        </TweenOne>
      )}
      <QueueAnim className="banner-title-wrapper" type={props.isMobile ? "bottom" : "right"}>
        <div className={"flex flex-row"}>
          <Logo type={LogoType.FullLogoOnLightBackground} className={"w-full"} />
        </div>
        <div key="line" className="title-line-wrapper">
          <div className="title-line" style={{ transform: "translateX(-64px)", marginTop: '30px' }} />
        </div>
        <div key="button" className="button-wrapper">
          <Links.LoginPage>
            <Button type="primary">
              <FormattedMessage id="banner.login-link" defaultMessage="Zaloguj" />
            </Button>
          </Links.LoginPage>
          <Button style={{ margin: "0 16px" }} type="primary" ghost>
            <Links.RegisterPage>
              <FormattedMessage id="banner.register-link" defaultMessage="Rejestracja" />
            </Links.RegisterPage>
          </Button>
        </div>
      </QueueAnim>
      {!props.isMobile && (
        <TweenOne animation={{ opacity: 1 }} className="banner-image-wrapper">
          <BannerSVGAnim />
        </TweenOne>
      )}
    </div>
  );
};

export default Banner;
