import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Form, Input } from "antd";
import { FormProps } from "antd/lib/form";
import CountrySelect from "../../../../../../components/forms/country-select/CountrySelect";
import { Country } from "core/countries/countries.model";

interface IFormValues {
  dataSetName: string;
  companyName: string;
  taxId: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postalCode: string;
  country: Country;
}

interface IIssuerDataSetFormProps extends FormProps {
  initialValues: IFormValues;
}

const IssuerDataSetForm: React.FunctionComponent<IIssuerDataSetFormProps> = ({ initialValues, ...restProps }) => {
  return (
    <Form
      {...restProps}
      className={"w-full max-w-xl"}
      name="invoice-issuer-settings-form"
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="dataSetName"
        label={
          <FormattedMessage
            id="settings.data-set-name"
            defaultMessage="Krótka nazwa dla danych ( może być nazwa firmy )"
          />
        }
        rules={[
          {
            required: true,
            whitespace: true,
            message: (
              <FormattedMessage
                id="settings.data-set-name-required"
                defaultMessage="Proszę podaj krótki opis danych, krótka nazwa firmy"
              />
            ),
          },
        ]}
      >
        <Input autoComplete="stop" />
      </Form.Item>
      <Form.Item
        name="companyName"
        label={<FormattedMessage id="settings.company-name" defaultMessage="Pełna nazwa firmy" />}
        rules={[
          {
            required: true,
            whitespace: true,
            message: <FormattedMessage id="settings.company-name-required" defaultMessage="Proszę podać nazwę firmy" />,
          },
        ]}
      >
        <Input autoComplete="stop" />
      </Form.Item>
      <Form.Item
        name="taxId"
        label={<span>NIP</span>}
        rules={[
          {
            required: true,
            whitespace: true,
            message: <FormattedMessage id="common.tax-id-is-required" defaultMessage="Proszę podać nip" />,
          },
          {
            min: 10,
            message: (
              <FormattedMessage
                id="common.tax-id-length-requirement-not-meet"
                defaultMessage="Nip musi mieć długość minimum 10 znaków"
              />
            ),
          },
        ]}
      >
        <Input autoComplete="stop" />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="common.street" defaultMessage="Ulica" />}
        name="addressLine1"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="dental-clinic-address-form.provide-street" defaultMessage="Proszę podać ulicę" />
            ),
          },
        ]}
      >
        <Input autoComplete={"nope"} />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="common.apartment-and-building" defaultMessage="Numer mieszkania" />}
        name={"addressLine2"}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="common.provide-apartment-and-building"
                defaultMessage="Proszę podać numer mieszkania"
              />
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="common.city" defaultMessage="Miasto" />}
        name="addressLine3"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="common.provide-city" defaultMessage="Proszę podać miasto" />,
          },
        ]}
      >
        <Input autoComplete={"nope"} />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="common.region" defaultMessage="Województwo" />}
        name="addressLine4"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="common.provide-region" defaultMessage="Proszę podać województwo" />,
          },
        ]}
      >
        <Input autoComplete={"nope"} />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="common.postal-code" defaultMessage="Kod pocztowy" />}
        name="postalCode"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="common.provide-postal-code" defaultMessage="Proszę podać kod pocztowy" />,
          },
        ]}
      >
        <Input autoComplete={"nope"} />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="common.country" defaultMessage="Kraj" />}
        name="country"
        rules={[
          {
            required: true,
            message: <FormattedMessage id="common.provide-country" defaultMessage="Proszę podać kraj" />,
          },
        ]}
      >
        <CountrySelect style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};

export default IssuerDataSetForm;
