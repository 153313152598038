import { invalidArgumentError } from "core/errors/generate-error";
import { SortComparator, SortComparison } from "core/lib/sort/sort.model";
import { IOrderType } from "features/catalogue/order-type.model";

export enum OrderTypeSortOrder {
  Standard = "standard",
}

export const sortOrderTypes = (
  sortOrder: OrderTypeSortOrder = OrderTypeSortOrder.Standard
): SortComparator<IOrderType> => {
  if (sortOrder !== OrderTypeSortOrder.Standard) {
    throw invalidArgumentError(`sort order is invalid`);
  }

  return sortOrderTypesInStandardOrder;
};


const sortOrderTypesInStandardOrder: SortComparator<Pick<IOrderType, "code" | "name">> = (o1, o2) => {
  if (o1.code !== null && o2.code !== null) {
    return o1.code - o2.code;
  } else if (o1.code === null && o2.code === null) {
    return o1.name.localeCompare(o2.name);
  } else if (o1.code === null && o2.code !== null) {
    return SortComparison.Descend;
  } else if (o1.code !== null && o2.code === null) {
    return SortComparison.Ascend;
  } else {
    return SortComparison.Equal;
  }
};
