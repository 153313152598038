import * as React from "react";
import { Button, DatePicker, Form, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { ISODateTimeString } from "core/time/time.model";
import moment from "moment";

export interface IChangeFinishDateModalFormProps {
  visible: boolean;
  values: { finishDate: ISODateTimeString | null };
  onCancel: () => void;
  onSubmit: (values: ISubmitValues) => Promise<any>;
}

interface ISubmitValues {
  finishDate: ISODateTimeString;
}

interface IFormValues {
  finishDate: moment.Moment | undefined | null;
}

const propertyNames: Record<keyof IFormValues, keyof IFormValues> = { finishDate: "finishDate" };

interface ICreateChangeFinishDateFormProps extends IChangeFinishDateModalFormProps {}

const ChangeFinishDateModalForm: React.FunctionComponent<ICreateChangeFinishDateFormProps> = ({
  visible,
  values,
  onCancel,
  onSubmit,
}) => {
  const formId = "change-finish-date-modal-form";
  const [form] = Form.useForm();

  const handleSubmit = React.useCallback(
    async (store: IFormValues) => {
      const { finishDate } = store;
      if (finishDate === undefined || finishDate === null) {
        return;
      }

      await onSubmit({ finishDate: finishDate.toISOString() });
      form.resetFields();
    },
    [form, onSubmit]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        <FormattedMessage
          id={"change-finish-date-modal-form.edit-change-finish-date"}
          defaultMessage={"Edytuj datę ukończenia"}
        />
      }
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id={"change-finish-date-modal-form.cancel"} defaultMessage={"Anuluj"} />
        </Button>,
        <Button type={"primary"} form={formId} key="submit" htmlType="submit">
          <FormattedMessage id={"change-finish-date-modal-form.save-button-text"} defaultMessage={"Zapisz"} />
        </Button>,
      ]}
    >
      <Form
        id={formId}
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          [propertyNames.finishDate]:
            values?.finishDate === undefined || values?.finishDate === null ? undefined : moment(values.finishDate),
        }}
      >
        <Form.Item
          label={<FormattedMessage id="change-finish-date-modal-form.finish-date" defaultMessage="Data ukończenia" />}
          name={propertyNames.finishDate}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="change-finish-date-modal-form.date-is-required"
                  defaultMessage={"Trzeba podać datę ukończenia"}
                />
              ),
            },
          ]}
        >
          <DatePicker
            showNow
            inputReadOnly
            className="w-full max-w-lg"
            format="YYYY-MM-DD HH:mm"
            showTime={{
              defaultValue: moment("10:00:00", "HH:mm:ss"),
              showHour: true,
              hideDisabledOptions: true,
              disabledHours: () => [0, 1, 2, 3, 4, 5, 7, 9, 11, 13, 15, 17, 19, 21, 22, 23],
              showMinute: false,
              showSecond: false,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeFinishDateModalForm;
