import { IntlShape } from "react-intl";
import { argumentOutOfRangeError } from "core/errors/generate-error";
import { SpecificationType } from "features/orders/specification/order-specification";
import * as React from "react";

export const specificationTypeExplanationFormatter = (intl: IntlShape, specification: SpecificationType) => {
  if (specification === null || specification === undefined || typeof specification !== "string") {
    return "-";
  }

  switch (specification) {
    case SpecificationType.TextOnly: {
      return intl.formatMessage({ id: "specification-name.text-only", defaultMessage: "Wyłącznie opis tekstowy" });
    }
    case SpecificationType.Quantity: {
      return intl.formatMessage({
        id: "catalogue.required-specification.quantity-description",
        defaultMessage: "Typ zamówienia w którym wystarczy podać liczbę sztuk",
      });
    }
    case SpecificationType.Arches: {
      return intl.formatMessage({
        id: "catalogue.required-specification.arches-description",
        defaultMessage:
          "Typ zamówienia w którym wystarczy wybrać czy trzeba wykonać zamówienie na górny lub/i dolny łuk zębowy",
      });
    }
    case SpecificationType.LocatedPoints: {
      return intl.formatMessage({
        id: "catalogue.required-specification.located-points-description",
        defaultMessage:
          "Typ zamówienia w którym trzeba podać informację ile oraz w jakim miejscu mają znajdować się punkty, ten typ zamówienia jest odpowiedni do zamawiania wax-upów, licówek, endokoron, stopni ceramicznych etc.",
      });
    }
    case SpecificationType.PartialDentures: {
      return intl.formatMessage({
        id: "catalogue.required-specification.PartialDentures",
        defaultMessage:
          "Typ zamówienia w którym podajemy informacje w którym miejscu trzeba umieścić zęby. (wybranie punktów na rysunku jamy ustnej) - punkty te muszą się znajdować na tym samym łuku",
      });
    }
    case SpecificationType.SurgicalGuides: {
      return intl.formatMessage({
        id: "catalogue.required-specification.SurgicalGuides",
        defaultMessage:
          "Typ zamówienia w którym podajemy informacje w którym miejscu będą znajdować się implanty związane z szablonem chirurgicznym - punkty te muszą się znajdować na tym samym łuku",
      });
    }
    case SpecificationType.Crowns: {
      return intl.formatMessage({
        id: "catalogue.required-specification.crowns-description",
        defaultMessage:
          "Typ zamówienia specyficzny dla zamawiania koron, trzeba określić gdzie korony się znajdują i wskazać które z koron są przykręcane a które cementowane na implancie/implantach",
      });
    }
    case SpecificationType.Bridges: {
      return intl.formatMessage({
        id: "catalogue.required-specification.bridges-description",
        defaultMessage:
          "Typ zamówienia specyficzny dla zamawiania mostów, trzeba określić które punkty łączą się w most oraz wskazać które punkty mostu są cementowane a które przykręcane na implantach",
      });
    }
    case SpecificationType.MarylandBridges: {
      return intl.formatMessage({
        id: "catalogue.required-specification.maryland-bridges-description",
        defaultMessage:
          "Typ zamówienia specyficzny dla zamawiania mostów maryland, w tym przypadku określamy położenie punktów w moście. Musimy wskazać które punkty są cementowane na zębach własnych pacjenta a które punkty to przęsła",
      });
    }
    case SpecificationType.Foundations: {
      return intl.formatMessage({
        id: "catalogue.required-specification.foundations-description",
        defaultMessage:
          "Typ zamówienia specyficzny dla zamawiania podbudów, w tym przypadku określamy położenie podbudowy oraz łączenia dla poszczególnych punktów tej podbudowy",
      });
    }
    default:
      throw argumentOutOfRangeError("specification type", specification);
  }
};
