import { FeatureFlag } from "features/feature-flags/feature-flags.models";
import { IEnvironmentVariables, EnvironmentName } from "core/global/environment.const";

export interface IFeatureFlagsService {
  isEnabled: (flag: FeatureFlag) => boolean;
}

type FeatureFlagEnabledMap = Record<FeatureFlag, boolean>;

class FeatureFlagService implements IFeatureFlagsService {
  private env: EnvironmentName;

  private readonly featureMap: Record<EnvironmentName, FeatureFlagEnabledMap> = {
    development: {
      ShowFormsWithFillWithDumyDataButtons: true,
    },
    production: {
      ShowFormsWithFillWithDumyDataButtons: false,
    },
    test: {
      ShowFormsWithFillWithDumyDataButtons: false,
    },
  };

  constructor(envVariables: IEnvironmentVariables) {
    this.env = envVariables.env;
  }

  isEnabled = (flag: FeatureFlag) => {
    return this.featureMap[this.env][flag];
  };
}

export default FeatureFlagService;
