export const defaultFormLayout = {
  itemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 },
      lg: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 },
      lg: { span: 8 },
    },
  },
  tailsItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
      md: {
        span: 12,
        offset: 4,
      },
    },
  },
};


export const largeLabelsFormLayout = {
  itemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 },
      lg: { span: 12 },
    },
  },
  tailsItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
      md: {
        span: 12,
        offset: 8,
      },
    },
  },
};
