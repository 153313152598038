import { Status, StatusCode } from "features/orders/status/order-status.model";

export const mapStatusCodeToStatus = (statusCode: StatusCode): Status => {
  switch (statusCode) {
    case StatusCode.ReadyForPickUpFromClinic:
      return Status.ReadyForPickUpFromClinic;
    case StatusCode.TodoInLab:
      return Status.TodoInLab;
    case StatusCode.InProgressInLab:
      return Status.InProgressInLab;
    case StatusCode.InProgressInClinic:
      return Status.InProgressInClinic;
    case StatusCode.Finished:
      return Status.Finished;
  }
};

export const mapStatusToStatusCode = (status: Status): StatusCode => {
  switch (status) {
    case Status.ReadyForPickUpFromClinic:
      return StatusCode.ReadyForPickUpFromClinic;
    case Status.TodoInLab:
      return StatusCode.TodoInLab;
    case Status.InProgressInLab:
      return StatusCode.InProgressInLab;
    case Status.InProgressInClinic:
      return StatusCode.InProgressInClinic;
    case Status.Finished:
      return StatusCode.Finished;
  }
};
