import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext } from "react";
import { Typography } from "antd";
import EventEmitterContext from "features/event-emitter/event-emitter.context";
import { EventName } from "root/IEvents";
import { useNavigate } from "react-router";

import { Path } from "core/routes/routes";
import { IDomainError } from "core/errors/domain-error";
import { formatDomainErrorMessages } from "components/errors/error-message.formatter";
import NotificationMessageContext from "../../components/notifications/NotificationMessageContext";

interface IEventSubscriptionProps {}

const RootEventsSubscriptions: React.FunctionComponent<IEventSubscriptionProps> = (props) => {
  const { notificationApi: notifications } = useContext(NotificationMessageContext);
  const emitter = useContext(EventEmitterContext);
  const navigate = useNavigate();
  const intl = useIntl();

  React.useEffect(() => {
    const onNoLabAssigned = () => {
      navigate(Path.Login);
    };

    emitter.on(EventName.NoLabAssigned, onNoLabAssigned);

    return () => {
      emitter.removeListener(EventName.NoLabAssigned, onNoLabAssigned);
    };
  }, [emitter, navigate]);

  React.useEffect(() => {
    const onAuthTokenExpired = () => {
      navigate(Path.Login);

      notifications.warning({
        message: (
          <FormattedMessage
            id={"root-event.auth-token-expired"}
            defaultMessage={"Twoja sesja wygasła, zostałeś wylogowany"}
          />
        ),
      });
    };

    emitter.on(EventName.AuthTokenExpired, onAuthTokenExpired);

    return () => {
      emitter.removeListener(EventName.NoLabAssigned, onAuthTokenExpired);
    };
  }, [emitter, navigate, notifications]);

  React.useEffect(() => {
    const showErrorToast = async (domainError: IDomainError) => {
      const formattedDomainError = formatDomainErrorMessages(intl, domainError);

      if (typeof domainError.errorDetails === "string" && domainError.errorDetails.length !== 0) {
        notifications.error({
          style: { width: 800 },
          message: <Typography.Paragraph copyable={true}>{formattedDomainError.title}</Typography.Paragraph>,
          description: (
            <>
              <p>{formattedDomainError.subTitle}</p>
              <Typography.Paragraph code={true} copyable={true}>
                {domainError.errorDetails}
              </Typography.Paragraph>
            </>
          ),
        });
      } else {
        notifications.error({
          message: formattedDomainError.title,
          description: formattedDomainError.subTitle,
        });
      }
    };

    emitter.on(EventName.ShowErrorToast, showErrorToast);

    return () => {
      emitter.removeListener(EventName.ShowErrorToast, showErrorToast);
    };
  }, [emitter, intl, notifications]);

  return null;
};

export default RootEventsSubscriptions;
