import * as React from "react";
import { useIntl } from "react-intl";
import { orderCodeFormatter } from "./order-code-formatter";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

interface IOrderCodeNameProps {
  code: OrderCodeEnum;
}

const OrderCodeName: React.FunctionComponent<IOrderCodeNameProps> = (props) => {
  const intl = useIntl();

  if (props.code === undefined || props.code === null) {
    return null;
  }

  const codeFormatted = orderCodeFormatter(intl, { name: "", code: props.code });

  return <>{codeFormatted}</>;
};

export default OrderCodeName;
