import React, { useState } from "react";
import { enquireScreen } from "enquire-js";

import Header from "./Header";
import Banner from "./Banner";
import Features from "features/landing/Features";
import Footer from "./Footer";

import "./landing-styles.scss";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

let initialIsMobile: boolean;

enquireScreen((b: boolean) => {
  initialIsMobile = b;
});

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(initialIsMobile);

  React.useEffect(() => {
    enquireScreen((b: boolean) => setIsMobile(b));
  }, []);

  return (
    <>
      <FormattedMessage id={"common.welcome"} defaultMessage={"Witaj"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div>
        <Header />
        <div className="home-wrapper">
          <Banner isMobile={isMobile} />
          <Features isMobile={isMobile} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
