import { IValueObject } from "core/value-object/value-object";
import { IOrderType, IOrderTypeId } from "features/catalogue/order-type.model";
import { SpecificationType } from "features/orders/specification/order-specification";

export enum CategoryCode {
  Crowns = 20000,
  Bridges = 30000,
  MarylandBridges = 40000,
  Veneers = 50000,
  FullDentures = 51000,
  PartialDentures = 52000,
  FrameDentures = 53000,
  SplintDentures = 55000,
  Overdentures = 56000,
  Attachments = 57000,
  DentureRepair = 58000,
  Foundations = 60000,
  ImplantAbutments = 70000,
  Onlays = 90000,
  Inlays = 100000,
  Overlays = 110000,
  EndoCrown = 120000,
  WaxUps = 130000,
  Temporary = 140000,
  Splints = 150000,
  Post = 160000,
  Gingiva = 170000,
  OrientationModels = 180000,
  Services = 190000,
  MillingServices = 200000,
  Other = 210000,
}

export interface IOrderCategoryId extends IValueObject<string> {
  type: "order-category-id";
}

export interface IOrderCategory {
  id: IOrderCategoryId;
  name: string;
  code: CategoryCode | null;
  orderTypes: IOrderType[];
}

export interface IEditOrderCategory {
  id: IOrderCategoryId;
  name: string;
}

export interface IEditOrderType {
  id: IOrderTypeId;
  isActive: boolean;
}

export interface ICreateOrderType {
  categoryId: IOrderCategoryId;
  name: string;
  requiredSpecification: SpecificationType;
  isActive: boolean;
}

export interface IArchiveOrderType {
  id: IOrderTypeId;
  isArchived: boolean;
}

export interface ICreateOrderCategory {
  name: string;
}

export interface IOrderCatalogue {
  categories: IOrderCategory[];
}
