import { ILabId } from "features/dental-lab/dental-lab.model";
import { ISODateTimeString } from "core/time/time.model";
import {
  IBaseOrderTableEntry,
  IDataProvider,
  IPaginatedProviderParams,
  ServerSortDirection,
} from "features/orders/pages/list/components/orders-table.models";

export enum DentistOrderServerColumn {
  // do not change - without sync with backend
  Lab = "lab",
  DueDate = "dueDate",
  Status = "status",
}

export interface IDentistOrdersProviderParams extends IPaginatedProviderParams {
  sortBy: { column: DentistOrderServerColumn; direction: ServerSortDirection } | null;
  labId: ILabId | null;
  patient: string | null;
  dueDateRange: { start: ISODateTimeString; end: ISODateTimeString } | null;
}

export interface IDentistOrderTableEntry extends IBaseOrderTableEntry {
  readonly labName: string;
}

export type DentistOrdersDataProvider = IDataProvider<IDentistOrderTableEntry, IDentistOrdersProviderParams>;