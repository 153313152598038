import {
  OrderSpecification,
  SpecificationType,
} from "./order-specification";
import { IPickedPoints } from "../components/points-picker/points-picker.model";
import { argumentOutOfRangeError } from "core/errors/generate-error";

export const specificationToCount = (specification: OrderSpecification) => {
  switch (specification.specificationType) {
    case SpecificationType.PartialDentures:
      return 1; // one point means one arch, and there never are two arches so always - 1 quantity
    case SpecificationType.Quantity:
      return specification.quantity;
    case SpecificationType.Arches:
      return specification.arches.length;
    case SpecificationType.LocatedPoints:
    case SpecificationType.Crowns:
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges:
    case SpecificationType.Foundations:
      return specification.points.length;
  }

  throw argumentOutOfRangeError("specification type is out of range", specification);
};

export const isSuitableForGraphicalPoints = (specification: SpecificationType) => {
  switch (specification) {
    case SpecificationType.TextOnly:
    case SpecificationType.Quantity:
    case SpecificationType.Arches:
      return false;
    case SpecificationType.LocatedPoints:
    case SpecificationType.PartialDentures:
    case SpecificationType.SurgicalGuides:
    case SpecificationType.Crowns:
    case SpecificationType.Bridges:
    case SpecificationType.Foundations:
      return true;
  }
};

export const isSpecificationWithLinkedPoints = (specification: SpecificationType) => {
  switch (specification) {
    case SpecificationType.TextOnly:
    case SpecificationType.Quantity:
    case SpecificationType.Arches:
    case SpecificationType.LocatedPoints:
    case SpecificationType.PartialDentures:
    case SpecificationType.SurgicalGuides:
    case SpecificationType.Crowns:
      return false;
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges:
    case SpecificationType.Foundations:
      return true;
  }
};

export const isEmptySpecification = (specification: OrderSpecification) => {
  switch (specification.specificationType) {
    case SpecificationType.Quantity:
      return specification.quantity <= 0;
    case SpecificationType.Arches:
      return specification.arches.length === 0;
    case SpecificationType.LocatedPoints:
    case SpecificationType.PartialDentures:
    case SpecificationType.SurgicalGuides:
    case SpecificationType.Crowns:
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges:
    case SpecificationType.Foundations:
      return specification.points.length === 0;
  }
};

export const createEmptySpecification = (specificationType: SpecificationType): OrderSpecification => {
  switch (specificationType) {
    case SpecificationType.Quantity:
      return { specificationType: SpecificationType.Quantity, quantity: 1 };
    case SpecificationType.Arches:
      return { specificationType: SpecificationType.Arches, arches: [] };
    case SpecificationType.LocatedPoints:
      return { specificationType: SpecificationType.LocatedPoints, points: [] };
    case SpecificationType.PartialDentures:
      return { specificationType: SpecificationType.PartialDentures, points: [] };
    case SpecificationType.SurgicalGuides:
      return { specificationType: SpecificationType.SurgicalGuides, points: [] };
    case SpecificationType.Crowns:
      return { specificationType: SpecificationType.Crowns, points: [] };
    case SpecificationType.Bridges:
      return { specificationType: SpecificationType.Bridges, points: [] };
    case SpecificationType.MarylandBridges:
      return { specificationType: SpecificationType.MarylandBridges, points: [] };
    case SpecificationType.Foundations:
      return { specificationType: SpecificationType.Foundations, points: [] };
    default:
      throw argumentOutOfRangeError("specificationType", specificationType);
  }
};

export const mapSpecificationToPointsSelection = (specification: OrderSpecification): IPickedPoints => {
  switch (specification.specificationType) {
    case SpecificationType.Quantity:
    case SpecificationType.Arches:
      throw new Error(`specification ${specification.specificationType} can not be converted to picked points`);
    case SpecificationType.LocatedPoints: {
      return { singles: specification.points.map(({ location }) => ({ location, anchor: null })), linked: [] };
    }
    case SpecificationType.PartialDentures: {
      return { singles: specification.points.map(({ location }) => ({ location, anchor: null })), linked: [] };
    }
    case SpecificationType.SurgicalGuides: {
      return { singles: specification.points.map(({ location }) => ({ location, anchor: null })), linked: [] };
    }
    case SpecificationType.Crowns: {
      return {
        singles: specification.points.map(({ location, anchor }) => ({ location, anchor: anchor })),
        linked: [],
      };
    }
    case SpecificationType.Bridges: {
      return {
        singles: [],
        linked: [...specification.points],
      };
    }
    case SpecificationType.MarylandBridges: {
      return {
        singles: [],
        linked: [...specification.points],
      };
    }
    case SpecificationType.Foundations: {
      return {
        singles: [],
        linked: [...specification.points],
      };
    }
  }
};
