import * as React from "react";
import { Image } from "antd";
import Button from "components/button/Button";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { isImageExtension } from "features/order-files/utils/file.utils";
import { useCallback } from "react";

import styles from "./File.module.scss";
import { IOrderFile } from "features/orders/order-files.model";

interface IFileProps {
  file: IOrderFile;
  onSave: (file: IOrderFile) => void;
  onRemove: (file: IOrderFile) => void;
}

const File: React.FC<IFileProps> = ({ file, onRemove, onSave }) => {
  const isImageFile = isImageExtension(file);

  const handleRemove = useCallback(() => {
    onRemove(file);
  }, [file, onRemove]);

  const handleSave = useCallback(() => {
    onSave(file);
  }, [file, onSave]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.preview}>
          {isImageFile ? (
            <Image height={220 - 60} width={220} src={file.url} />
          ) : (
            <div className={styles.extensionName}>{file.extension}</div>
          )}
        </div>
        <div className={styles.description}>
          <Button size={"small"} icon={<DownloadOutlined />} onClick={handleSave} />
          <div className={styles.name}>{file.name}</div>
          <Button size={"small"} icon={<DeleteOutlined />} onClick={handleRemove} />
        </div>
      </div>
    </>
  );
};

export default File;
