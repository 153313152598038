import React from "react";
import classNames from "classnames";
import MoneyFormatter from "../../../../../components/formatters/money/MoneyFormatter";
import { IMoney } from "core/money/money.model";

import "./pricing-entry-template.scss";

type PricingEntry = {
  label: React.ReactNode;
  price: IMoney;
  subEntries?: {
    label: React.ReactNode;
    price: IMoney;
  }[];
};

interface IPricingTemplateProps extends React.HTMLAttributes<HTMLDivElement> {
  pricingEntries: PricingEntry[];
}

const PricingTemplate: React.FunctionComponent<IPricingTemplateProps> = ({
  pricingEntries,
  className,
  ...restProps
}) => {
  return (
    <div className={classNames("pricing-template", className)} {...restProps}>
      {pricingEntries.map(({ price, label, subEntries = [] }, index) => (
        <React.Fragment key={index}>
          <div className="pricing-template__label">{label}</div>
          <div className="pricing-template__price">
            <MoneyFormatter value={price} />
          </div>
          {subEntries.map(({ price: subPrice, label: subLabel }, index2) => (
            <React.Fragment key={index2}>
              <div className="pricing-template__label pl-4">{subLabel}</div>
              <div className="pricing-template__price">
                <MoneyFormatter value={subPrice} />
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
export default PricingTemplate;
