import { ILab, ILabId, IShortLab } from "features/dental-lab/dental-lab.model";
import { IResult } from "core/lib/types/result";
import { IDentalLabApi } from "features/dental-lab/service/IDentalLabApi";
import { IJsonApiService } from "core/http/json-api.service";
import { GetLabResponseDTO, GetLabsResponseDTO } from "features/dental-lab/contracts/dental-lab.responses";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { mapDentalLabDTOtoDentalLab } from "features/dental-lab/contracts/dental-lab.mappers";
import { IUserId } from "features/auth/auth.model";

class DentalLabApi implements IDentalLabApi {
  private api: IJsonApiService;

  constructor(jsonApi: IJsonApiService) {
    this.api = jsonApi;
  }

  getDentalLab = async (labId: ILabId): Promise<IResult<ILab>> => {
    const result = await this.api.get<GetLabResponseDTO>(() =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetDentalLab,
        params: { labId: labId.value },
      })
    );

    return result.map((response) => mapDentalLabDTOtoDentalLab(response.data.result));
  };

  getDentalLabs = async (dentistId: IUserId): Promise<IResult<IShortLab[]>> => {
    const result = await this.api.get<GetLabsResponseDTO>(() =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetDentalLabs,
        params: { dentistId: dentistId.value },
      })
    );

    return result.map((response) =>
      response.data.result.map((dto) => ({
        id: { type: "dental-lab-id", value: dto.labId },
        name: dto.name,
      }))
    );
  };
}

export default DentalLabApi;
