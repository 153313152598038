import { IResult } from "core/lib/types/result";
import { IHttpService } from "core/http/http.service";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { IOrderCatalogueService } from "features/catalogue/service/categories.serivce";
import {
  IArchiveOrderType,
  ICreateOrderCategory,
  ICreateOrderType,
  IEditOrderCategory,
  IEditOrderType,
  IOrderCatalogue,
  IOrderCategory,
} from "features/catalogue/category.model";
import {
  GetOrderCatalogueResponse,
  IAddOrUpdatePricingEntryDTO,
  IsPricingSetResponseDTO,
} from "features/orders/contracts/cateogires.response.dto";
import { Ok } from "core/lib/types/ok";
import { Err } from "core/lib/types/error";
import { getDomainError, isErrorResponse } from "core/http/http.functions";
import { IOrderType, IOrderTypeId } from "features/catalogue/order-type.model";
import {
  mapSpecificationTypeCodeToSpecificationType,
  mapSpecificationTypeToToSpecificationCode,
} from "features/orders/contracts/orders.dto.mappers";
import { IDentalLabService } from "features/dental-lab/service/IDentalLabService";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { mapPricingToPricingDTO } from "features/orders/contracts/pricing.dto.mappers";
import { IDomainError } from "core/errors/domain-error";
import { IJsonApiService } from "core/http/json-api.service";
import { ILabId } from "features/dental-lab/dental-lab.model";
import { IPricingEntry } from "features/pricing-lists/pricing-list.models";
import { SpecificationTypeCode } from "features/orders/order.model";
import IApiResponseEnvelope from "contracts/envelope/api-response-envelope";
import { CategoriesSortOrder, sortCategories } from "features/catalogue/category.sort";
import { OrderTypeSortOrder, sortOrderTypes } from "features/catalogue/order-type.sort";

class CategoriesHttpService implements IOrderCatalogueService {
  private readonly httpService: IHttpService;
  private readonly dentalLabService: IDentalLabService;
  private readonly jsonApi: IJsonApiService;

  constructor(jsonApiService: IJsonApiService, httpService: IHttpService, dentalLabService: IDentalLabService) {
    this.jsonApi = jsonApiService;
    this.httpService = httpService;
    this.dentalLabService = dentalLabService;
  }

  getOrderCatalogue = async (labId: ILabId): Promise<IResult<IOrderCatalogue>> => {
    const response = await this.jsonApi.get<GetOrderCatalogueResponse>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetOrderCatalogue,
        params: { labId: labId.value },
      })
    );

    return response.map((body) => {
      const categoryDTOs = body.data.result.categories;

      const categories = categoryDTOs.map((categoryDTO) => {
        const orderTypes = categoryDTO.orderTypes.map((orderTypeDTO) => {
          const orderType: IOrderType = {
            categoryId: { type: "order-category-id", value: orderTypeDTO.categoryId },
            id: { type: "order-type-id", value: orderTypeDTO.orderTypeId },
            name: orderTypeDTO.name,
            code: orderTypeDTO.orderCode,
            isActive: orderTypeDTO.isActive,
            isArchived: orderTypeDTO.isArchived,
            requiredSpecification: mapSpecificationTypeCodeToSpecificationType(orderTypeDTO.requiredSpecification),
          };
          return orderType;
        });

        const notArchivedOrderTypes = orderTypes.filter((ot) => !ot.isArchived);
        const sortedOrderTypes = notArchivedOrderTypes.sort(sortOrderTypes(OrderTypeSortOrder.Standard));

        const orderCategory: IOrderCategory = {
          id: { type: "order-category-id", value: categoryDTO.categoryId },
          name: categoryDTO.name,
          code: categoryDTO.categoryCode,
          orderTypes: sortedOrderTypes,
        };

        return orderCategory;
      });

      const sortedCategories = categories.sort(sortCategories(CategoriesSortOrder.Standard));

      const orderCatalogue: IOrderCatalogue = {
        categories: sortedCategories,
      };
      return orderCatalogue;
    });
  };

  createOrderType = async (request: ICreateOrderType) => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<void>,
      {
        categoryId: string;
        name: string;
        requiredSpecification: SpecificationTypeCode;
        isActive: boolean;
      }
    >(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.CreateOrderType,
          params: { labId: labId!.value },
        }),
      {
        name: request.name,
        categoryId: request.categoryId.value,
        requiredSpecification: mapSpecificationTypeToToSpecificationCode(request.requiredSpecification),
        isActive: request.isActive,
      }
    );

    return response.map((_) => undefined);
  };

  editOrderType = async (request: IEditOrderType) => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<void>,
      {
        orderTypeId: string;
        isActive: boolean;
      }
    >(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.EditOrderType,
          params: { labId: labId!.value },
        }),
      {
        orderTypeId: request.id.value,
        isActive: request.isActive,
      }
    );

    return response.map((_) => undefined);
  };

  archiveOrderType = async (request: IArchiveOrderType) => {
    const response = await this.jsonApi.post<
      IApiResponseEnvelope<void>,
      {
        orderTypeId: string;
        isArchived: boolean;
      }
    >(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.ArchiveOrderType,
          params: { labId: labId!.value },
        }),
      {
        orderTypeId: request.id.value,
        isArchived: request.isArchived,
      }
    );

    return response.map((_) => undefined);
  };

  createOrderCategory = async (request: ICreateOrderCategory): Promise<IResult<void>> => {
    const response = await this.jsonApi.post<IApiResponseEnvelope<void>, { name: string }>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.CreateOrderCategory,
          params: { labId: labId!.value },
        }),
      { name: request.name }
    );

    return response.map((_) => undefined);
  };

  editOrderCategory = async (request: IEditOrderCategory) => {
    const response = await this.jsonApi.post<IApiResponseEnvelope<void>, { name: string; categoryId: string }>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.EditOrderCategory,
          params: { labId: labId!.value },
        }),
      {
        categoryId: request.id.value,
        name: request.name,
      }
    );

    return response.map((_) => undefined);
  };

  getAllPricingEntries = async (): Promise<IResult<IPricingEntry[]>> => {
    throw new Error("depricated");

    // const getPricingCatalogueResponse = await this.jsonApi.get<IGetPricingCatalogueResponse>(({ tenantId }) => {
    //   return parametrizeEndpointPath({
    //     path: ApiEndpointPath.GetDefaultPricingCatalogue,
    //     params: { labId: tenantId!.value },
    //   });
    // });
    //
    // if (getPricingCatalogueResponse.isErr()) {
    //   return new Err(getPricingCatalogueResponse.err().unwrap());
    // }
    //
    // const response = getPricingCatalogueResponse.unwrap();
    //
    // try {
    //   const pricingEntries: IPricingEntry[] = response.data.result.pricingEntries.map(({ orderTypeId, pricing }) => {
    //     const pricingEntry: IPricingEntry = {
    //       orderTypeId: { type: "order-type-id", value: orderTypeId },
    //       pricing: mapPricingDTOtoPricing(pricing),
    //     };
    //
    //     return pricingEntry;
    //   });
    //   return new Ok<IPricingEntry[]>(pricingEntries);
    // } catch (error) {
    //   console.error("error: ", error);
    //   throw new Error("error while processing response");
    // }
  };

  // getOrderCatalogueForLab = async (labId: ILabId): Promise<IResult<IOrderCatalogue>> => {
  //   const getAllTreesResponse = await this.jsonApi.get<IGetAllOrderTreesResponse>(
  //     parametrizeEndpointPath({
  //       path: ApiEndpointPath.GetOrderCatalogues,
  //       params: { labId: labId.value },
  //     })
  //   );
  //
  //   // getAllTreesResponse
  //
  //   if (getAllTreesResponse.isErr()) {
  //     return new Err(getAllTreesResponse.err().unwrap());
  //   }
  //
  //   const allTreesResponse = getAllTreesResponse.unwrap();
  //
  //   if (allTreesResponse.data.result.length !== 1) {
  //     return new Err(new DomainError(ErrorCode.InternalError, "at least one order tree should exist"));
  //   }
  //
  //   const treeId = allTreesResponse.data.result[0].id;
  //
  //   const getCategoriesResponse = await this.jsonApi.get<GetOrderCatalogueResponse>(
  //     parametrizeEndpointPath({
  //       path: ApiEndpointPath.GetOrderCatalogue,
  //       params: { treeId: treeId },
  //     })
  //   );
  //
  //   if (getCategoriesResponse.isErr()) {
  //     return new Err(getCategoriesResponse.err().unwrap());
  //   }
  //
  //   const orderCategoriesDTOs = getCategoriesResponse.unwrap();
  //   const asd = orderCategoriesDTOs.data.result;
  //
  //   throw new Error("asd not implemented");
  //
  //   // try {
  //   //   const orderCategories: IOrderCategory[] = categories.map((c) => {
  //   //     const category: IOrderCategory = {
  //   //       id: { type: "order-category-id", value: c.categoryId },
  //   //       name: c.categoryName,
  //   //       isActive: true, // TODO: categories are always actvie, todo: remove
  //   //       code: c.categoryCode,
  //   //       parentId: c.parentCategoryId === null ? null : { type: "order-category-id", value: c.parentCategoryId },
  //   //     };
  //   //
  //   //     return category;
  //   //   });
  //   //
  //   //   const orderTypes: IOrderType[] = types.map((t) => {
  //   //     const orderTypeId: IOrderTypeId = { type: "order-type-id", value: t.id };
  //   //     const orderCategoryId: IOrderCategoryId = { type: "order-category-id", value: t.categoryId };
  //   //
  //   //     const orderType: IOrderType = {
  //   //       orderTypeId: orderTypeId,
  //   //       name: t.name,
  //   //       isActive: t.isActive,
  //   //       categoryId: orderCategoryId,
  //   //       requiredSpecification: mapSpecificationTypeCodeToSpecificationType(t.requiredSpecification),
  //   //       orderCode: t.orderCode,
  //   //     };
  //   //
  //   //     return orderType;
  //   //   });
  //   //
  //   //   return new Ok<[IOrderCategory[], IOrderType[]]>([orderCategories, orderTypes]);
  //   // } catch (error) {
  //   //   console.error("error:", error);
  //   //   throw error;
  //   // }
  // };

  addOrUpdatePricingEntry = async (pricingEntry: IPricingEntry): Promise<IResult<string>> => {
    const response = await this.jsonApi.post<string, IAddOrUpdatePricingEntryDTO>(
      ({ labId }) =>
        parametrizeEndpointPath({ path: ApiEndpointPath.AddOrUpdatePricingEntry, params: { labId: labId!.value } }),
      {
        orderTypeId: pricingEntry.orderTypeId.value,
        pricing: mapPricingToPricingDTO(pricingEntry.pricing),
      }
    );

    return response.map((body) => body.data);
  };

  isPricingEntrySet = async (id: IOrderTypeId): Promise<IResult<boolean>> => {
    const activeLabResult = await this.dentalLabService.getActiveDentalLab();

    if (activeLabResult.isErr()) {
      return new Err<never, IDomainError>(activeLabResult.err().unwrap());
    }

    const activeLab = activeLabResult.unwrap();

    const response = await this.httpService.get<IsPricingSetResponseDTO>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.IsPricingEntrySet,
        params: { labId: activeLab.id.value, orderTypeId: id.value },
      })
    );

    const responseBody = response.data;

    if (isErrorResponse(responseBody)) {
      return new Err<boolean, IDomainError>(getDomainError(responseBody));
    }

    return new Ok<boolean, IDomainError>(responseBody.result);
  };
}

export default CategoriesHttpService;
