import * as React from "react";
import {
  Routes,
  Route, Navigate,
} from "react-router-dom";
import { Path } from "core/routes/routes";
import Layout from "features/layout/Layout";
import DentistListPage from "features/dentist/pages/list/DentistListPage";
import ListOrdersPage from "features/orders/pages/list/OrdersList";
import CreateBillingStatement from "features/invoicing/pages/create-invoice-page/CreateBillingStatementPage";
import InvoiceListPage from "features/invoicing/pages/InvoiceListPage";
import EmptyLayout from "features/layout/EmptyLayout";
import RootEventsSubscriptions from "features/event-emitter/RootEventsSubscriptions";
import OrderCataloguePage from "features/catalogue/pages/OrderCataloguePage";
import BillingStatementDetailsPage from "features/invoicing/pages/invoice-details/BillingStatementDetailsPage";
import SettingsPage from "../settings/pages/SettingsPage";
import LandingPage from "../landing/LandingPage";
import CreateOrderPage from "../orders/pages/create/CreateOrderPage";
import OrderDetailsPage from "../orders/pages/new-details/OrderDetailsPage";
import LoginPage from "../auth/pages/login-page/LoginPage";
import RegisterPage from "../auth/pages/register-page/RegisterPage";
import ConfirmEmailPage from "../auth/pages/confirm-email-page/ConfirmEmailPage";
import VerifyEmailPage from "../auth/pages/verify-email-page/VerifyEmailPage";
import ResetPasswordPage from "features/auth/pages/reset-password-page/ResetPasswordPage";
import SetNewPasswordPage from "features/auth/pages/set-new-password-page/SetNewPasswordPage";
import SubscriptionsPage from "../subscriptions/pages/SubscriptionsPage";
import SubscriptionsCheckoutStatusPage from "../subscriptions/pages/SubscriptionsCheckoutStatusPage";
import EditOrderPage from "../orders/pages/edit/EditOrderPage";
import EmployeeListPage from "../employee/pages/list/EmployeeListPage";
import InvitePage, { InviteMode } from "features/register/pages/invite-page/InvitePage";
import RegisterByInvitePage, { RegisterMode } from "features/register/pages/register-by-invite-page/RegisterByInvitePage";
import RouteGuard from "features/routing/RouteGuard";
import LabsPricingListsPage from "features/pricing-lists/pages/lab/AllLaboratoryPricingListsPage";
import LabPricingListPage from "features/pricing-lists/pages/lab/LabPricingListPage";
import AllDentistsPricingListsPage from "features/pricing-lists/pages/dentist/AllDentistsPricingListsPage";
import DentistsPricingListForLabPage from "features/pricing-lists/pages/dentist/DentistsPricingListForLabPage";
import ChatProvider from "features/chat/provider/ChatProviderRoute";
import ChatPage from "features/chat/pages/chat-page/ChatPage";

export interface IRouterProps { }

class Router extends React.PureComponent<IRouterProps> {
  public render() {
    return (
      <React.Fragment>
        <Routes>
          <Route element={<RootEventsSubscriptions />} />
          <Route element={<EmptyLayout />}>
            <Route path={Path.Root} element={<LandingPage />}/>
            <Route path={Path.Login} element={<LoginPage />}/>
            <Route path={Path.Register} element={<RegisterPage />}/>
            <Route path={Path.RegisterDentist} element={<RegisterByInvitePage mode={RegisterMode.Dentist} />} />
            <Route path={Path.RegisterEmployee} element={<RegisterByInvitePage mode={RegisterMode.Employee} />} />
            <Route path={Path.ConfirmEmail} element={<ConfirmEmailPage />}/>
            <Route path={Path.ConfirmEmailWithEmbeddedLink} element={<ConfirmEmailPage />}/>
            <Route path={Path.ResetPassword} element={<ResetPasswordPage />} />
            <Route path={Path.SetNewPassword} element={<SetNewPasswordPage />} />
            <Route path={Path.EXTERNALY_REFERENCED_VerifyEmail} element={<VerifyEmailPage />}
            />
          </Route>
          <Route element={<Layout />}>
            <Route element={<RouteGuard guards={["isAuthenticated"]} />}>
              <Route path={Path.ListOrders} element={<ListOrdersPage />}/>
              <Route path={Path.OrderDetails} element={<OrderDetailsPage />} />
              <Route path={Path.CreateOrder} element={<CreateOrderPage />} />
              <Route path={Path.EditOrder} element={<EditOrderPage />} />
              <Route element={<RouteGuard guards={["isChatEnabled"]} fallback={<Navigate to={Path.ListOrders} replace />} />}>
                <Route element={<ChatProvider />}>
                  <Route path={Path.ChatChannel} element={<ChatPage />}/>
                  <Route path={Path.ChatList} element={<ChatPage />}/>
                </Route>
              </Route>
              <Route element={<RouteGuard guards={["hasAccessToInvoices"]} />} >
                <Route path={Path.CreateBillingStatement} element={<CreateBillingStatement />} />
                <Route path={Path.BillingStatementDetails} element={<BillingStatementDetailsPage />} />
                <Route path={Path.BillingStatements} element={<InvoiceListPage/>} />
              </Route>
              <Route element={<RouteGuard guards={["isSubscriptionPageAvailable"]} />} >
                <Route path={Path.SubscriptionsSuccess} element={<SubscriptionsCheckoutStatusPage/>} />
                <Route path={Path.SubscriptionsCanceled} element={<SubscriptionsCheckoutStatusPage/>} />
                <Route path={Path.Subscriptions} element={<SubscriptionsPage/>} />
              </Route>
              <Route element={<RouteGuard guards={["isSettingsPageAvailable"]} />}>
                <Route path={Path.Settings} element={<SettingsPage/>} />
              </Route>
              <Route path={Path.ListDentists} element={<DentistListPage/>} />
              <Route path={Path.InviteDentist} element={<InvitePage mode={InviteMode.Dentist} />} />
              <Route path={Path.ListEmployees} element={<EmployeeListPage />} />
              <Route path={Path.InviteEmployee} element={<InvitePage mode={InviteMode.Employee} />} />
              <Route path={Path.OrderCatalogue} element={<OrderCataloguePage />} />
              <Route path={Path.LaboratoryPricingLists} element={<LabsPricingListsPage />} />
              <Route path={Path.LabPricingList} element={<LabPricingListPage />} />
              <Route path={Path.DentistsPricingLists} element={<AllDentistsPricingListsPage />} />
              <Route path={Path.DentistPricingList} element={<DentistsPricingListForLabPage />} />
            </Route>
          </Route>
        </Routes>
      </React.Fragment>
    );
  }
}

export default Router;
