import React from "react";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import Parallax from "rc-scroll-anim/lib/ScrollParallax";
import QueueAnim from "rc-queue-anim";
// @ts-ignore
import TweenOne from "rc-tween-one";

import svg1 from "./svg/1.svg";
import svg2 from "./svg/2.svg";
import svg3 from "./svg/3.svg";
import svg4 from "./svg/4.svg";
import svg5 from "./svg/5.svg";
import svg7 from "./svg/7.svg";
import svg8 from "./svg/8.svg";
import svg9 from "./svg/9.svg";

// @ts-ignore
const { TweenOneGroup } = TweenOne;

const features = [
  {
    title: "Komunikacja",
    content: "Narzędzia do komunikacji z dentystą!",
    src: svg5,
    color: "#FAAD14",
    shadowColor: "rgba(250,173,20,.12)",
  },
  {
    title: "Fakturowanie",
    content: "Oszczędzaj czas dzięki usługom fakturowania",
    src: svg2,
    color: "#2F54EB",
    shadowColor: "rgba(47,84,235,.12)",
  },
  {
    title: "Wpisywanie prac",
    content: "Monitoruj zlecenia w swojej pracowni",
    src: svg3,
    color: "#F5222D",
    shadowColor: "rgba(245,34,45,.12)",
  },
  {
    title: "Rozliczanie pracowników",
    content: "Proste i efektywne rozliczenia za wykonane prace",
    src: svg7,
    color: "#FA8C16",
    shadowColor: "rgba(250,140,22,.12)",
  },
  {
    title: "Dostępność",
    content: "Możliwość wpisywania prac na komórce i komputerach",
    src: svg4,
    color: "#1AC44D",
    shadowColor: "rgba(26,196,77,.12)",
  },
  {
    title: "Przydzielanie prac pracownikom",
    content: "Rozwiązanie tworzone z myślą o protetykach",
    src: svg1,
    color: "#13C2C2",
    shadowColor: "rgba(19,194,194,.12)",
  },
  {
    title: "Bezpieczeństwo",
    content: "Centralny i bezpieczny punkt wymiany zdjęć i plików związanych z zamówieniem",
    src: svg8,
    color: "#EB2F96",
    shadowColor: "rgba(235,45,150,.12)",
  },
  {
    title: "Raporty",
    content: "Generuj miesięczne raporty",
    src: svg9,
    color: "#1890FF",
    shadowColor: "rgba(24,144,255,.12)",
  },
  // {
  //   title: "Wsparcie wielu języków",
  //   content: "",
  //   src: svg6,
  //   color: "#722ED1",
  //   shadowColor: "rgba(114,46,209,.12)",
  // },
];

const pointPos = [
  { x: -30, y: -10 },
  { x: 20, y: -20 },
  { x: -65, y: 15 },
  { x: -45, y: 80 },
  { x: 35, y: 5 },
  { x: 50, y: 50, opacity: 0.2 },
];

interface IFeaturesProps {
  isMobile: boolean;
}

interface IFeaturesState {
  hoverNum: null | number;
}

class Features extends React.PureComponent<IFeaturesProps, IFeaturesState> {
  constructor(props: IFeaturesProps) {
    super(props);
    this.state = {
      hoverNum: null,
    };
  }
  onMouseOver = (i: any) => {
    this.setState({
      hoverNum: i,
    });
  };
  onMouseOut = () => {
    this.setState({
      hoverNum: null,
    });
  };
  getEnter = (e: any) => {
    const i = e.index;
    const r = Math.random() * 2 - 1;
    const y = Math.random() * 10 + 5;
    const delay = Math.round(Math.random() * (i * 50));
    return [
      {
        delay,
        opacity: 0.4,
        ...pointPos[e.index],
        ease: "easeOutBack",
        duration: 300,
      },
      {
        y: r > 0 ? `+=${y}` : `-=${y}`,
        duration: Math.random() * 1000 + 2000,
        yoyo: true,
        repeat: -1,
      },
    ];
  };
  render() {
    const { hoverNum } = this.state;
    let children: any = [[], [], []];
    features.forEach((item, i) => {
      const isHover = hoverNum === i;

      const pointChild = ["point-0 left", "point-0 right", "point-ring", "point-1", "point-2", "point-3"].map(
        (className) => (
          <TweenOne
            component="i"
            className={className}
            key={className}
            style={{
              background: item.color,
              borderColor: item.color,
            }}
          />
        )
      );
      const child = (
        <li key={i.toString()}>
          <div
            className="page1-box"
            onMouseEnter={() => {
              this.onMouseOver(i);
            }}
            onMouseLeave={this.onMouseOut}
          >
            <TweenOneGroup
              className="page1-point-wrapper"
              enter={this.getEnter}
              leave={{
                x: 0,
                y: 30,
                opacity: 0,
                duration: 300,
                ease: "easeInBack",
              }}
              resetStyleBool={false}
            >
              {(this.props.isMobile || isHover) && pointChild}
            </TweenOneGroup>
            <div
              className="page1-image"
              style={{
                boxShadow: `${isHover ? "0 12px 24px" : "0 6px 12px"} ${item.shadowColor}`,
              }}
            >
              <img src={item.src} alt="img" style={i === 4 ? { marginLeft: -15 } : {}} />
            </div>
            <h3>{item.title}</h3>
            <p>{item.content}</p>
          </div>
        </li>
      );
      children[Math.floor(i / 3)].push(child);
    });

    children = children.map((item: any, i: any) => (
      <QueueAnim
        className="page1-box-wrapper"
        key={i.toString()}
        type="bottom"
        leaveReverse
        delay={[i * 100, (children.length - 1 - i) * 100]}
        component="ul"
      >
        {item}
      </QueueAnim>
    ));

    return (
      <div className="home-page page1">
        <div className="home-page-wrapper" id="page1-wrapper">
          {!this.props.isMobile && (
            <Parallax
              className="page1-bg"
              animation={{ translateY: 200, ease: "linear", playScale: [0, 1.65] }}
              location="page1-wrapper"
            >
              DentiMate
            </Parallax>
          )}
          <h2>
            W czym możemy <span>Ci</span> pomóc ?
          </h2>
          <div className="title-line-wrapper page1-line">
            <div className="title-line" />
          </div>
          <OverPack>{children}</OverPack>
        </div>
      </div>
    );
  }
}

export default Features;
