import * as React from "react";
import useAuth from "features/auth/hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import { Path } from "core/routes/routes";
import AuthContext from "features/auth/auth-context";
import { routeGuards, Guard } from "features/routing/route-guards";
import { invalidArgumentError } from "core/errors/generate-error";
import ToastMessages from "components/message/ToastMessages";
import { FormattedMessage } from "react-intl";
import { mapSingleOrMultipleToArray } from "core/utils/array-utils";

type Predicate = (authContext: AuthContext) => boolean;

interface IRouteGuardProps {
  guards?: Guard | Guard[];
  fallback?: "redirect-to-login" | React.ReactNode;
  showErrorToast?: boolean;
}

const RouteGuard: React.FC<IRouteGuardProps> = ({ guards, fallback = "redirect-to-login", showErrorToast = true }) => {
  const { messageApi } = React.useContext(ToastMessages);
  const { isLoggedIn, authContext } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to={Path.Login} replace />;
  }

  const guardPredicates: Predicate[] =
    Array.isArray(guards) || typeof guards === "string"
      ? mapSingleOrMultipleToArray(guards).map((key) => {
          const validKeyType = key as any as keyof typeof routeGuards;
          return routeGuards[validKeyType];
        })
      : [];

  const passedAllGuards = guardPredicates.reduce((acc, predicate) => {
    if (!acc) {
      return acc;
    }

    return predicate(authContext);
  }, true);

  if (!passedAllGuards) {
    messageApi.error(
      <FormattedMessage id={"route-guard.access-not-granted"} defaultMessage={"Brak wystarczających uprawnień"} />
    );

    if (fallback === "redirect-to-login") {
      return <Navigate to={Path.Login} replace />;
    } else if (React.isValidElement(fallback)) {
      return fallback;
    } else {
      throw invalidArgumentError("invalid argument fallback");
    }
  }

  return <Outlet />;
};

export default RouteGuard;
