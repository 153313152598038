import { SpecificationType } from "features/orders/specification/order-specification";

export const allSpecificationTypes: SpecificationType[] = [
  // SpecificationType.TextOnly,
  SpecificationType.Quantity,
  SpecificationType.Arches,
  SpecificationType.LocatedPoints,
  SpecificationType.PartialDentures,
  SpecificationType.SurgicalGuides,
  SpecificationType.Crowns,
  SpecificationType.Bridges,
  SpecificationType.MarylandBridges,
  SpecificationType.Foundations,
];
