import * as React from "react";
import { ProFormText, QueryFilter } from "@ant-design/pro-form";
import { SearchOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

export type DentistQuerySearchParams = { dentist: string; companyName: string };

const propertyNames: Record<keyof DentistQuerySearchParams, keyof DentistQuerySearchParams> = {
  dentist: "dentist",
  companyName: "companyName",
};

const DentistQueryFilter: React.FunctionComponent<{
  onSearch: (searchArgs: DentistQuerySearchParams) => Promise<void>;
}> = (props) => (
  <QueryFilter<DentistQuerySearchParams>
    onFinish={props.onSearch}
    onValuesChange={props.onSearch}
    optionRender={(_1, _2, dom) => {
      const [resetBtn, submitBtn] = dom as any;
      const initialReset = resetBtn?.props?.onClick;
      const initialSubmit = submitBtn?.props?.onClick;

      const resetClick = function () {
        initialReset?.();
        initialSubmit?.();
      };

      return [
        React.cloneElement(resetBtn, { type: "link", onClick: resetClick }),
        React.cloneElement(submitBtn, { type: "default", icon: <SearchOutlined /> }),
      ];
    }}
  >
    <ProFormText
      name={propertyNames.dentist}
      placeholder={""}
      label={<FormattedMessage id="dentist-query-filter.dentist" defaultMessage="Dentysta" />}
    />
    <ProFormText
      name={propertyNames.companyName}
      placeholder={""}
      label={<FormattedMessage id="dentist-query-filter.clinic" defaultMessage="Klinika" />}
    />
  </QueryFilter>
);

export default DentistQueryFilter;
