import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Col, Row } from "antd";
import { CheckCircleOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import styles from "./invited-dentists-counts.module.scss";

interface IInvitedDentistsCountsProps {
  totalDentists: number;
  dentistsInvited: number;
  dentistsAcceptedInvitation: number;
}

const Info: React.FC<{
  title: React.ReactNode;
  value: React.ReactNode;
  bordered?: boolean;
}> = ({ title, value, bordered }) => (
  <div className={styles.headerInfo}>
    <span>{title}</span>
    <p>{value}</p>
    {bordered && <em />}
  </div>
);

const InvitedDentistsCounts: React.FC<IInvitedDentistsCountsProps> = ({
  totalDentists,
  dentistsInvited,
  dentistsAcceptedInvitation,
}) => (
  <Card bordered={false}>
    <Row>
      <Col sm={8} xs={24}>
        <Info
          title={<FormattedMessage id="invited-dentists-counts.total-dentists" defaultMessage="Łącznie dentystów" />}
          value={
            <span>
              <UserOutlined className={"mr-2"} />
              {totalDentists}
            </span>
          }
          bordered
        />
      </Col>
      <Col sm={8} xs={24}>
        <Info
          title={
            <FormattedMessage
              id="invited-dentists-counts.total-invites-sent"
              defaultMessage="Łącznie wysłanych zaproszeń"
            />
          }
          value={
            <span>
              <MailOutlined className={"mr-2"} />
              {dentistsInvited}
            </span>
          }
          bordered
        />
      </Col>
      <Col sm={8} xs={24}>
        <Info
          title={
            <FormattedMessage
              id="invited-dentists-counts.total-invites-accepted"
              defaultMessage="Łącznie zaakceptowanych zaproszeń"
            />
          }
          value={
            <>
              <CheckCircleOutlined className={"mr-2"} />
              {dentistsAcceptedInvitation}
            </>
          }
        />
      </Col>
    </Row>
  </Card>
);

export default InvitedDentistsCounts;
