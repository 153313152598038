import { PointId } from "core/point/point.enums";
import { AnchorType } from "../../../order-type/anchor-type.models";
import { PointsSortOrder, sortPoints } from "core/point/point.sort";
import { OrderItemFormatterGroup } from "./order-item-formatter.models";
import { BridgePoint, CrownPoint } from "../../../specification/point-specification";

const sortOrder = {
  [AnchorType.CementedToPreparedTooth]: 1,
  [AnchorType.CementedToImplant]: 2,
  [AnchorType.ScrewedInToImplant]: 3,
  [AnchorType.Pontic]: 4,
};

export const splitIntoGroups = (points: CrownPoint[] | BridgePoint[]): OrderItemFormatterGroup[] => {
  const splicedGroups = points
    .reduce((groups, curr) => {
      const { location, anchor } = curr;

      const idx = groups.findIndex((g) => g.anchorType === anchor);

      if (idx === -1) {
        return [...groups, { anchorType: anchor, points: [location] }];
      }

      groups[idx].points.push(location);
      groups[idx].points.sort((p1, p2) => sortPoints(PointsSortOrder.Reading)(p1, p2));

      return groups;
    }, [] as { anchorType: AnchorType; points: PointId[] }[])
    .sort((g1, g2) => sortOrder[g1.anchorType] - sortOrder[g2.anchorType]);

  return splicedGroups;
};
