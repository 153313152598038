import {
  IPricingEntry,
  IPricingList,
  NewPricingListId,
} from "features/pricing-lists/pricing-list.models";
import { IPricingListWithDentistsDTO } from "features/pricing-lists/contracts/pricing-list.dto";
import { NewOrderTypeId } from "features/catalogue/order-type.model";
import { mapPricingDTOtoPricing } from "features/orders/contracts/pricing.dto.mappers";
import { NewDentistId } from "features/dentist/dentist.model";
import { honorificCodeToHonorific } from "core/person-name/honorific.functions";

export const mapPricingListDTOtoPricingList = (dto: IPricingListWithDentistsDTO): IPricingList => {
  const pricingList: IPricingList = {
    id: NewPricingListId(dto.id),
    name: dto.name,
    isDefault: dto.isDefault,
    entries: dto.pricingEntries.map((e) => {
      const pricingEntry: IPricingEntry = {
        orderTypeId: NewOrderTypeId(e.orderTypeId),
        pricing: mapPricingDTOtoPricing(e.pricing),
      };

      return pricingEntry;
    }),
    dentists: dto.dentists.map((d) => {
      const dentist: IPricingList["dentists"][0] = {
        id: NewDentistId(d.id),
        name: {
          firstName: d.name.firstName,
          lastName: d.name.lastName,
          honorific: honorificCodeToHonorific(d.name.honorific),
        },
        clinicName: d.clinicName,
      };

      return dentist;
    }),
  };

  return pricingList;
};
