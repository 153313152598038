import * as React from "react";
import { Button, Checkbox, Form } from "antd";
import { DevTool } from "@hookform/devtools";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import Input from "../../../../components/forms/input/Input";
import InputPassword from "../../../../components/forms/input/InputPassword";
import { IResult } from "core/lib/types/result";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import { envVariables } from "core/global/environment.const";

interface IRegisterFormProps {
  onRegisterSubmit: (submitValues: ISubmitValues) => Promise<IResult<void>>;
}

interface IFormValues {
  readonly email: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly companyName: string;
  readonly acceptTermsAndConditions: boolean;
  readonly captchaToken: string;
}

interface ISubmitValues {
  readonly email: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly companyName: string;
  readonly acceptTermsAndConditions: boolean;
  readonly captchaToken: string;
}

const formNames: Record<keyof IFormValues, keyof IFormValues> = {
  email: "email",
  password: "password",
  firstName: "firstName",
  lastName: "lastName",
  companyName: "companyName",
  acceptTermsAndConditions: "acceptTermsAndConditions",
  captchaToken: "captchaToken",
};

const RegisterForm: React.FC<IRegisterFormProps> = ({ onRegisterSubmit }) => {
  const intl = useIntl();
  const { flag: isLoading, check: startLoading, uncheck: finishLoading } = useBooleanFlag(false);

  const schema = useMemo(
    () =>
      yup
        .object({
          [formNames.password]: yup
            .string()
            .required(
              intl.formatMessage({ id: "register-form.password-is-required", defaultMessage: "Hasło jest wymagane" })
            ),
          [formNames.firstName]: yup
            .string()
            .required(
              intl.formatMessage({ id: "register-form.first-name-is-required", defaultMessage: "Imie jest wymagane" })
            ),
          [formNames.lastName]: yup.string().required(
            intl.formatMessage({
              id: "register-form.last-name-is-required",
              defaultMessage: "Nazwisko jest wymagane",
            })
          ),
          [formNames.companyName]: yup.string().required(
            intl.formatMessage({
              id: "register-form.company-name-is-required",
              defaultMessage: "Podaj nazwę laboratorium protetycznego",
            })
          ),
          [formNames.email]: yup
            .string()
            .email(intl.formatMessage({ id: "register-form.email-is-invalid", defaultMessage: "Nie poprawny email" }))
            .required(
              intl.formatMessage({ id: "register-form.email-is-required", defaultMessage: "Email jest wymagany" })
            ),
          [formNames.captchaToken]: yup
            .string()
            .required(
              intl.formatMessage({ id: "register-form.captcha-is-required", defaultMessage: "Nie wypełniono captcha" })
            ),
          [formNames.acceptTermsAndConditions]: yup.boolean().oneOf(
            [true],
            intl.formatMessage({
              id: "register-form.confirmation-for-terms-and-conditions-is-missing",
              defaultMessage: "Akceptacja regulaminu jest wymagana",
            })
          ),
        })
        .required(),
    [intl]
  );

  const {
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      [formNames.email]: "",
      [formNames.password]: "",
      [formNames.companyName]: "",
      [formNames.firstName]: "",
      [formNames.lastName]: "",
      [formNames.acceptTermsAndConditions]: false,
    },
  });

  const handleRegister = React.useCallback(
    async ({ email, password, firstName, lastName, companyName, acceptTermsAndConditions, captchaToken }: IFormValues) => {
      try {
        startLoading();

        const submitResult = await onRegisterSubmit({
          email,
          password,
          firstName,
          lastName,
          companyName,
          acceptTermsAndConditions,
          captchaToken,
        });

        if (submitResult.isErr()) {
          const err = submitResult.err();
          console.error("register error: ", err);
        }
      } catch (e) {
        console.error(e);
      } finally {
        finishLoading();
      }
    },
    [finishLoading, onRegisterSubmit, startLoading]
  );

  return (
    <>
      <Form autoComplete="off" layout={"vertical"}>
        <Form.Item
          name={formNames.email}
          validateStatus={errors.email !== undefined ? "error" : undefined}
          help={errors.email?.message}
        >
          <Controller
            name="email"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Input
                placeholder={intl.formatMessage({
                  id: "register-form.email",
                  defaultMessage: "Email",
                })}
                value={value === null ? undefined : value}
                type={"email"}
                autoComplete={"stop"}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name={formNames.companyName}
          validateStatus={errors.companyName !== undefined ? "error" : undefined}
          help={errors.companyName?.message}
        >
          <Controller
            name="companyName"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Input
                placeholder={intl.formatMessage({
                  id: "register-form.companyName",
                  defaultMessage: "Nazwa laboratorium protetycznego",
                })}
                value={value === null ? undefined : value}
                autoComplete={"stop"}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name={formNames.firstName}
          validateStatus={errors.firstName !== undefined ? "error" : undefined}
          help={errors.firstName?.message}
        >
          <Controller
            name="firstName"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Input
                placeholder={intl.formatMessage({
                  id: "register-form.firstName",
                  defaultMessage: "Imię",
                })}
                value={value === null ? undefined : value}
                autoComplete={"stop"}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name={formNames.lastName}
          validateStatus={errors.lastName !== undefined ? "error" : undefined}
          help={errors.lastName?.message}
        >
          <Controller
            name="lastName"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Input
                placeholder={intl.formatMessage({
                  id: "register-form.lastName",
                  defaultMessage: "Nazwisko",
                })}
                value={value === null ? undefined : value}
                autoComplete={"stop"}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name={formNames.password}
          validateStatus={errors.password !== undefined ? "error" : undefined}
          help={errors.password?.message}
        >
          <Controller
            name="password"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <InputPassword
                placeholder={intl.formatMessage({
                  id: "register-form.password",
                  defaultMessage: "Hasło",
                })}
                autoComplete={"stop"}
                value={value === null ? undefined : value}
                {...rest}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name={formNames.captchaToken}
          validateStatus={errors.captchaToken !== undefined ? "error" : undefined}
          help={errors.captchaToken?.message}
        >
          <Controller
            name="captchaToken"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <ReCAPTCHA sitekey={envVariables.recaptchaSiteKey} onChange={rest.onChange} />
            )}
          />
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <Form.Item
            name={formNames.acceptTermsAndConditions}
            validateStatus={errors.acceptTermsAndConditions !== undefined ? "error" : undefined}
            help={errors.acceptTermsAndConditions?.message}
          >
            <Controller
              name="acceptTermsAndConditions"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <>
                  <Checkbox checked={value} {...rest}>
                    <FormattedMessage
                      id="register-form.accept-terms-and-conditions"
                      defaultMessage="Akceptuję regulamin"
                    />
                    <span
                      style={{
                        display: "inline-block",
                        marginRight: "4px",
                        color: "#ff4d4f",
                        fontSize: "14px",
                        lineHeight: "1",
                        content: "*",
                      }}
                    >
                      {"*"}
                    </span>
                  </Checkbox>
                </>
              )}
            />
          </Form.Item>
        </div>
        <Button htmlType={"submit"} type="primary" block loading={isLoading} onClick={handleSubmit(handleRegister)}>
          <FormattedMessage id="register-form.register" defaultMessage="Rejestruj" />
        </Button>
        <DevTool control={control} />
      </Form>
    </>
  );
};

export default RegisterForm;

export type { ISubmitValues as IRegisterFormSubmitValues };
