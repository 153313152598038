import * as React from "react";
import { Form, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { NextButton } from "components/common-components/CommonButtons";
import { defaultFormLayout } from "components/forms/form.layouts";
import { IDentist} from "features/dentist/dentist.model";
import DentistSelect from "../../../../../../components/forms/dentist-select/DentistSelect";
import { Controller, useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";

interface IPickDentistProps {
  dentists: IDentist[];
  onSubmit: (values: ISubmitValues) => Promise<void>;
}

interface IFormValues {
  recipient: string;
  counterparties: string[];
}

interface ISubmitValues {
  recipient: IDentist;
  counterparties: IDentist[];
}

const formNames: Record<keyof IFormValues, keyof IFormValues> = {
  counterparties: "counterparties",
  recipient: "recipient",
};

const PickDentists: React.FunctionComponent<IPickDentistProps> = ({ dentists, onSubmit }) => {
  const intl = useIntl();

  const onSuccess = React.useCallback(
    async (values: IFormValues) => {
      await onSubmit({
        recipient: dentists.find((d) => d.id.value === values.recipient)!,
        counterparties: dentists.filter((d) => values.counterparties.some((id) => d.id.value === id)),
      });
    },
    [dentists, onSubmit]
  );

  const schema = React.useMemo(
    () =>
      yup.object({
        [formNames.recipient]: yup.string().required(
          intl.formatMessage({
            id: "pick-orders.dentist-is-required",
            defaultMessage: "Proszę wybrać dentystę",
          })
        ),
        [formNames.counterparties]: yup.array().min(
          1,
          intl.formatMessage({
            id: "pick-orders.dentist-is-required",
            defaultMessage: "Proszę wybrać przynajmniej jednego dentystę",
          })
        ),
      }),
    [intl]
  );

  const {
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      recipient: "",
      counterparties: [],
    },
  });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "recipient" && type === "change") {
        console.info("here");
        setValue("counterparties", [value.recipient!]);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  return (
    <Form className="ml-4 py-6" name="pick-dentist-form" {...defaultFormLayout.itemLayout}>
      <Form.Item
        name={formNames.recipient}
        label={<FormattedMessage id="common.billing-statement-recipient" defaultMessage="Rozliczenie dla" />}
        validateStatus={errors.recipient !== undefined ? "error" : undefined}
        help={errors.recipient?.message}
      >
        <Controller
          name={formNames.recipient}
          control={control}
          render={({ field }) => (
            <DentistSelect dentists={dentists} style={{ minWidth: 200 }} allowClear {...(field as any)} />
          )}
        />
      </Form.Item>
      <Form.Item
        name={formNames.counterparties}
        label={
          <FormattedMessage id="common.billing-statement-counterparies" defaultMessage="Dentyści na rozliczeniu" />
        }
      >
        <Controller
          name={formNames.counterparties}
          control={control}
          render={({ field }) => (
            <DentistSelect
              mode={"multiple"}
              dentists={dentists}
              style={{ minWidth: 200 }}
              allowClear
              {...(field as any)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`${formNames.counterparties}` as const}
          render={({ message }) => <Typography.Text type="danger">{message}</Typography.Text>}
        />
      </Form.Item>
      <Form.Item className="my-4" {...defaultFormLayout.tailsItemLayout}>
        <NextButton htmlType={"submit"} onClick={handleSubmit(onSuccess)} />
      </Form.Item>
    </Form>
  );
};

export type { ISubmitValues as IPickDentistSubmitValues };

export default PickDentists;
