import * as React from "react";
import {
  BleachShade,
  ColorOrNone,
  NO_COLOR,
  NoColor,
  Shade,
  ShadeGuide,
  Vita3dMasterShade,
  VitaClassicalShade,
} from "features/orders/color/color.model";
import ShadeGuideSelect from "components/forms/shade-guide-select/ShadeGuideSelect";
import ShadeSelect from "components/forms/shade-select/ShadeSelect";
import { isBleachShade, isVita3dMasterShade, isVitaClassicShade } from "features/orders/color/color.guards";

export interface IColorInputProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "value"> {
  value?: ColorOrNone | null;
  disabled?: boolean;
  defaultGuide?: ShadeGuide;
  onChange?: (color: ColorOrNone | null | undefined) => void;
}

const getGuide = (defaultGuide: ShadeGuide, colorOrNone?: ColorOrNone | null) => {
  if (colorOrNone === NO_COLOR) {
    return NO_COLOR;
  }

  if (colorOrNone === null || colorOrNone === undefined) {
    return defaultGuide;
  }

  return colorOrNone.guide;
};

const ColorInput = React.forwardRef<HTMLDivElement, IColorInputProps>((props, ref) => {
  const { onChange, value, defaultGuide = ShadeGuide.VitaClassical, disabled = false, ...otherProps } = props;
  const guide: ShadeGuide | NoColor = getGuide(defaultGuide, value);

  const handleGuideChange = React.useCallback(
    (guide: ShadeGuide | NoColor) => {
      if (guide === NO_COLOR) {
        onChange?.(NO_COLOR);
      }

      if (guide === ShadeGuide.VitaClassical) {
        onChange?.({ guide: guide, shade: VitaClassicalShade.A1 });
        return;
      }

      if (guide === ShadeGuide.Vita3dMaster) {
        onChange?.({ guide: guide, shade: Vita3dMasterShade.ONE_M1 });
        return;
      }

      if (guide === ShadeGuide.Bleach) {
        onChange?.({ guide: guide, shade: BleachShade.BL1 });
        return;
      }
    },
    [onChange]
  );

  const handleShadeChange = React.useCallback(
    (shade: Shade) => {
      if (value === NO_COLOR) {
        return;
      }

      if (isVitaClassicShade(shade)) {
        onChange?.({ guide: ShadeGuide.VitaClassical, shade: shade });
        return;
      }

      if (isVita3dMasterShade(shade)) {
        onChange?.({ guide: ShadeGuide.Vita3dMaster, shade: shade });
        return;
      }

      if (isBleachShade(shade)) {
        onChange?.({ guide: ShadeGuide.Bleach, shade: shade });
        return;
      }
    },
    [onChange, value]
  );

  return (
    <div {...otherProps} className={"flex flex-row flex-wrap"}>
      <div className="w-1/2 pr-2">
        <ShadeGuideSelect
          ref={ref}
          className="w-full"
          allowColorInNote={true}
          disabled={disabled}
          value={guide}
          onChange={handleGuideChange}
      />
      </div>
      <div className="w-1/2 pl-2">
        {value !== NO_COLOR && (
          <>
            {guide === ShadeGuide.VitaClassical && (
              <ShadeSelect.VitaClassic
                className="w-full"
                disabled={disabled}
                value={value?.shade as VitaClassicalShade}
                onChange={handleShadeChange}
              />
            )}
            {guide === ShadeGuide.Vita3dMaster && (
              <ShadeSelect.Vita3dMaster
                className="w-full"
                disabled={disabled}
                value={value?.shade as Vita3dMasterShade}
                onChange={handleShadeChange}
              />
            )}
            {guide === ShadeGuide.Bleach && (
              <ShadeSelect.Bleach
                className="w-full"
                disabled={disabled}
                value={value?.shade as BleachShade}
                onChange={handleShadeChange}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default ColorInput;
