export enum AnchorType {
  CementedToImplant = "CementedToImplant",
  ScrewedInToImplant = "ScrewedInToImplant",
  CementedToPreparedTooth = "CementedToPreparedTooth",
  Pontic = "Pontic",
}

export type CrownAnchorType =
  | AnchorType.CementedToImplant
  | AnchorType.ScrewedInToImplant
  | AnchorType.CementedToPreparedTooth;

export type FoundationAnchorType =
  | AnchorType.CementedToImplant
  | AnchorType.ScrewedInToImplant
  | AnchorType.Pontic;

export type MarylandAnchorType =
  | AnchorType.CementedToPreparedTooth
  | AnchorType.Pontic;

export type BridgeAnchorType = AnchorType;
