import * as React from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Divider, Popconfirm, Tooltip } from "antd";
import { IShortDentist, NewDentistId } from "features/dentist/dentist.model";
import { IEmployee } from "features/employee/employee.model";
import { IOrderId } from "features/orders/order.model";
import { Status } from "features/orders/status/order-status.model";
import DueDateFormatter from "components/formatters/dates/DueDateFormatter";
import PatientNameFormatter from "components/formatters/patient-name/PatientNameFormatter";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import Links from "components/links/Links";
import ProTable, { ActionType, ListToolBarProps, ProColumns, ProTableProps } from "@ant-design/pro-table";
import OrderStatus from "components/status/order-status/OrderStatus";
import { IResult } from "core/lib/types/result";
import { DeleteButton } from "components/common-components/CommonButtons";
import moment from "moment";
import { ISODateTimeString } from "core/time/time.model";
import Button from "components/button/Button";
import { ScheduleOutlined } from "@ant-design/icons";
import { ProFormSelect } from "@ant-design/pro-form";
import Assignments from "features/orders/components/assignments/Assignments";
import useQueryParam from "hooks/useQueryParam";
import { SortOrder } from "antd/lib/table/interface";
import { ServerSortDirection } from "features/orders/pages/list/components/orders-table.models";
import {
  AssignmentFilter,
  ILabOrdersProviderParams,
  ILabOrderTableEntry,
  LabOrdersDataProvider,
  LabOrderServerColumn,
} from "features/orders/pages/list/components/lab-orders-table/lab-orders-table.models";
import { allOrderStatuses } from "features/orders/status/order-status.const";
import { statusNameFormatter } from "components/status/order-status/status-name.formatter";
import { mapStatusToStatusCode } from "features/orders/status/order-status.mappers";
import { isEmptyObject } from "core/utils/object";

interface ILabOrdersTableProps {
  dataProvider: LabOrdersDataProvider;
  dentists: IShortDentist[];
  employees: IEmployee[];
  actionRef?: React.MutableRefObject<ActionType | undefined>;
  onChangeFinishDate?: (orderId: IOrderId, finishDate: ISODateTimeString | null) => void;
  onAssigned?: () => void;
  onDelete?: (orderId: IOrderId) => Promise<IResult<IOrderId>>;
  onStatusChange?: (orderId: IOrderId, nextStatus: Status) => Promise<IResult<Status>>;
}

type TableParams = {
  current: number;
  pageSize: number;
  patient?: string;
  orderNumber?: number;
  status: { value: Status; label: string; key: string }[];
  assignedEmployees?: { value: string; label: string; key: string };
  dentist?: { value: string; label: string; key: string };
  dueDateRange?: { start: string /*"YYYY-MM-DD"*/; end: string /*"YYYY-MM-DD"*/ }; // transformed from dueDate
};

type CombinedTableParams = TableParams & {
  sort: Record<string, SortOrder>;
  assignmentFilter?: AssignmentFilter;
};

const generateDataProviderParams = (combinedTableParams: CombinedTableParams): ILabOrdersProviderParams => {
  const { sort, assignmentFilter, ...tableParams } = combinedTableParams;

  const {
    pageSize = 50,
    current = 1,
    orderNumber = null,
    dentist = null,
    patient = null,
    dueDateRange = null,
    assignedEmployees = null,
    status = null,
  } = tableParams;

  const dataProviderParams: ILabOrdersProviderParams = {
    pagination: { pageNumber: current, pageSize: pageSize },
    assignmentFilter: assignmentFilter,
  };

  if (dentist !== null) {
    dataProviderParams.dentistIds = [NewDentistId(dentist.value)];
  }

  if (patient !== null) {
    dataProviderParams.patient = patient;
  }

  if (orderNumber !== null) {
    dataProviderParams.orderNumber = orderNumber;
  }

  if (status !== null) {
    dataProviderParams.status = status.map((s) => mapStatusToStatusCode(s.value));
  }

  if (
    (assignmentFilter === AssignmentFilter.All || assignmentFilter === AssignmentFilter.Employee) &&
    assignedEmployees !== null
  ) {
    dataProviderParams.assignedEmployee = {
      type: "employee-id",
      value: assignedEmployees.value,
    };
  }

  // noinspection DuplicatedCode
  if (dueDateRange !== null) {
    dataProviderParams.dueDateRange = {
      start: moment(dueDateRange.start).startOf("day").toISOString(),
      end: moment(dueDateRange.end).endOf("day").toISOString(),
    };
  }

  if (typeof sort === "object" && Object.keys(sort).length === 1) {
    const [column, direction] = Object.entries(sort)[0];

    dataProviderParams.sortBy = {
      column: column as LabOrderServerColumn,
      direction: direction === "ascend" ? ServerSortDirection.Ascending : ServerSortDirection.Descending,
    };
  }

  return dataProviderParams;
};

const LabOrdersTable: React.FunctionComponent<ILabOrdersTableProps> = (props) => {
  const intl = useIntl();
  const [tableParamsFromUrl, saveTableParamsToUrl] = useQueryParam<CombinedTableParams>("orders");

  const [activeAssignmentFilter, setActiveOwnershipType] = React.useState<AssignmentFilter>(
    tableParamsFromUrl?.assignmentFilter ?? AssignmentFilter.All
  );

  const { actionRef } = props;

  const handleOnAssigned = React.useCallback(async () => {
    props.onAssigned?.();
  }, [props]);

  const statusValueEnums = allOrderStatuses.reduce(
    (acc, status) => ({
      ...acc,
      [status]: { text: statusNameFormatter(intl, status) },
    }),
    {}
  );

  const tableColumns: ProColumns<ILabOrderTableEntry>[] = [
    {
      key: "orderNumber",
      sorter: LabOrderServerColumn.OrderNumber,
      defaultSortOrder: tableParamsFromUrl?.sort?.orderNumber,
      title: <FormattedMessage id="common.short-order-number" defaultMessage="Nr." />,
      valueType: "digit",
      render: (_, entry) => (
        <Links.OrderDetails
          params={{
            orderId: entry.orderId,
            orderNumber: entry.orderNumber,
          }}
        >
          {entry.orderNumber}
        </Links.OrderDetails>
      ),
    },
    {
      key: "clinicName",
      title: <FormattedMessage id="common.dental-clinic" defaultMessage="Klinika" />,
      hideInSearch: true,
      render: (_, entry) => <span>{entry.dentist.clinicName}</span>,
    },
    {
      key: "dentist",
      sorter: LabOrderServerColumn.Dentist,
      defaultSortOrder: tableParamsFromUrl?.sort?.dentist,
      title: <FormattedMessage id="common.dentist" defaultMessage="Dentysta" />,
      render: (_, entry) => <PersonNameFormatter personName={entry.dentist.name} />,
      formItemProps: {
        lightProps: {
          labelFormatter: (value: { value: string; label: string }) => value.label,
        },
      },
      renderFormItem: (schema, config, form) => {
        if (config.type === "form") {
          return null;
        }

        return (
          <ProFormSelect
            {...(config as any).fieldProps}
            showSearch
            allowClear={false}
            fieldProps={{ labelInValue: true }}
            valueEnum={props.dentists.reduce(
              (acc, dentist) => ({
                ...acc,
                [dentist.id.value]: { text: dentist.name.firstName + " " + dentist.name.lastName },
              }),
              {}
            )}
          />
        );
      },
    },
    {
      key: "patient",
      title: <FormattedMessage id="common.patient" defaultMessage="Pacjent" />,
      render: (_, entry) => (entry.patient === null ? null : <PatientNameFormatter patientName={entry.patient} />),
    },
    {
      key: "startDate",
      title: <FormattedMessage id="common.start-date" defaultMessage="Termin przyjścia" />,
      hideInSearch: true,
      render: (_, entry) =>
        entry.startDate !== null ? <FormattedDate value={new Date(entry.startDate)} dateStyle="short" /> : null,
    },
    {
      key: "dueDate",
      sorter: LabOrderServerColumn.DueDate,
      defaultSortOrder: tableParamsFromUrl?.sort?.dueDate,
      title: <FormattedMessage id="common.due-date" defaultMessage="Na kiedy" />,
      valueType: "dateRange",
      search: {
        transform: (value) => ({
          dueDateRange: { start: value[0], end: value[1] },
        }),
      },
      render: (_, entry) => (entry.dueDate !== null ? <DueDateFormatter dueDate={entry.dueDate} /> : null),
    },
    {
      key: "status",
      sorter: LabOrderServerColumn.Status,
      defaultSortOrder: tableParamsFromUrl?.sort?.status,
      title: <FormattedMessage id="common.status" defaultMessage="Status" />,
      formItemProps: {
        lightProps: {
          labelFormatter: (values: { label: string }[]) => (values ?? []).map((v) => v.label).join(","),
        },
      },
      renderFormItem: (schema, config) => {
        if (config.type === "form") {
          return null;
        }

        return (
          <ProFormSelect
            {...(config as any).fieldProps}
            allowClear={false}
            mode={"multiple"}
            fieldProps={{ labelInValue: true }}
            valueEnum={statusValueEnums}
          />
        );
      },
      render: (status: React.ReactNode, entry, index, action) => (
        <OrderStatus
          status={entry.status}
          onChange={
            props.onStatusChange === undefined
              ? undefined
              : (nextStatus: Status) => {
                  return props.onStatusChange!(entry.orderId, nextStatus).then(async (result) => {
                    await action?.reload();
                    return result;
                  });
                }
          }
        />
      ),
    },
    {
      key: "assignedEmployees",
      title: <FormattedMessage id="common.assignedEmployees" defaultMessage="Pracownicy" />,
      hideInSearch: activeAssignmentFilter !== AssignmentFilter.All,
      formItemProps: {
        lightProps: {
          labelFormatter: (value: { value: string; label: string }) => value.label,
        },
      },
      render: (_, entry) => (
        <Assignments orderId={entry.orderId} employees={entry.assignedEmployees} onAssigned={handleOnAssigned} />
      ),
      renderFormItem: (schema, config, form) => {
        if (config.type === "form") {
          return null;
        }

        return (
          <ProFormSelect
            {...(config as any).fieldProps}
            showSearch
            allowClear={false}
            fieldProps={{ labelInValue: true }}
            valueEnum={props.employees.reduce(
              (acc, employee) => ({
                ...acc,
                [employee.employeeId.value]: { text: employee.firstName + " " + employee.lastName },
              }),
              {}
            )}
          />
        );
      },
    },
    {
      key: "billingStatement",
      title: <FormattedMessage id="common.billing-statement" defaultMessage="Rozliczenie" />,
      hideInSearch: true,
      render: (_, entry) => {
        if (entry.billingStatement === null) {
          return null;
        }

        const { billingStatementNumber, billingStatementId } = entry.billingStatement;

        return (
          <Links.BillingStatementDetails params={{ billingStatementId: billingStatementId }}>
            {billingStatementNumber}
          </Links.BillingStatementDetails>
        );
      },
    },
    {
      key: "actions",
      hideInSearch: true,
      title: <FormattedMessage id="common.actions" defaultMessage="Akcje" />,
      render: (_, entry, index, action) => (
        <div className="flex flex-row flex-no-wrap items-center">
          <Links.OrderDetails
            params={{
              orderId: entry.orderId,
              orderNumber: entry.orderNumber,
            }}
          >
            <FormattedMessage id="common.details" defaultMessage="Idź do zamówienia" />
          </Links.OrderDetails>
          {entry.status === Status.Finished && props.onChangeFinishDate !== undefined && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={
                  <FormattedMessage
                    id="order-item-table.are-you-sure-you-want-to-change-finish-date"
                    defaultMessage="Czy na pewno chcesz zmienić termin ukończenia zamówienia ?"
                  />
                }
                onConfirm={() => props.onChangeFinishDate?.(entry.orderId, entry.finishDate)}
              >
                <Tooltip
                  placement={"bottom"}
                  title={
                    <FormattedMessage
                      id="order-item-table.order-finish-date"
                      defaultMessage="Praca ukończona {formattedDate}"
                      values={{
                        formattedDate:
                          entry.finishDate === null ? "" : <FormattedDate value={new Date(entry.finishDate!)} />,
                      }}
                    />
                  }
                >
                  <Button icon={<ScheduleOutlined />} />
                </Tooltip>
              </Popconfirm>
            </>
          )}
          {entry.billingStatement === null && props.onDelete !== undefined && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={
                  <FormattedMessage
                    id="order-item-table.are-you-sure-you-want-to-delete-order-item"
                    defaultMessage="Czy na pewno chcesz usunąć zamówienie ?"
                  />
                }
                onConfirm={() =>
                  props.onDelete?.(entry.orderId).then(async (result) => {
                    await action?.reload();
                    return result;
                  })
                }
              >
                <DeleteButton />
              </Popconfirm>
            </>
          )}
        </div>
      ),
    },
  ];

  const toolbar: ListToolBarProps = {
    menu: {
      type: "tab",
      activeKey: activeAssignmentFilter,
      items: [
        {
          key: AssignmentFilter.All,
          label: <FormattedMessage id="order-item-table.ownership.all" defaultMessage="Wszystkie" />,
        },
        {
          key: AssignmentFilter.Employee,
          label: <FormattedMessage id="order-item-table.ownership.mine" defaultMessage="Moje" />,
        },
        {
          key: AssignmentFilter.Unassigned,
          label: <FormattedMessage id="order-item-table.ownership.unassigned" defaultMessage="Nieprzypisane" />,
        },
      ],
      onChange: (key) => {
        setActiveOwnershipType(key as AssignmentFilter);
        actionRef?.current?.reload();
      },
    },
  };

  const handleRequest: ProTableProps<ILabOrderTableEntry, any>["request"] = React.useCallback(
    async (params: TableParams, sort: Record<string, SortOrder>) => {
      const combinedTableParams: CombinedTableParams = {
        ...params,
        sort: isEmptyObject(sort) ? { ...(tableParamsFromUrl?.sort ?? {}) } : { ...sort },
        assignmentFilter: activeAssignmentFilter,
      };

      saveTableParamsToUrl(combinedTableParams);

      const dataProviderParams = generateDataProviderParams(combinedTableParams);

      const result = await props.dataProvider(dataProviderParams);

      if (result.isErr()) {
        return { data: [], success: false };
      }

      const resultsPage = result.unwrap();
      return { data: resultsPage.data, success: true, total: resultsPage.pagination.totalElements };
    },
    [tableParamsFromUrl?.sort, activeAssignmentFilter, saveTableParamsToUrl, props]
  );

  return (
    <ProTable<ILabOrderTableEntry>
      actionRef={props?.actionRef}
      rowKey={(item) => item.orderId.value}
      columns={tableColumns}
      toolbar={toolbar}
      dateFormatter="string"
      request={handleRequest}
      form={{
        syncToUrl: (_, type) => {
          if (type === "get") {
            if (tableParamsFromUrl === undefined) {
              return {};
            }

            let params: CombinedTableParams = { ...tableParamsFromUrl };

            if (params.dueDateRange !== undefined) {
              const { dueDateRange, ...rest } = params;
              const start = dueDateRange.start;
              const end = dueDateRange.end;

              params = {
                dueDate: [start, end],
                ...rest,
              } as any;
            }

            return params;
          }

          return {};
        },
      }}
      columnsState={{
        persistenceKey: "lab-orders-table",
        persistenceType: "localStorage",
      }}
      pagination={{ showTotal: (total, range) => <>{`${range[0]}-${range[1]} z ${total}`}</> }}
      search={{ filterType: "light" }}
      options={{
        fullScreen: true,
        density: true,
        reload: true,
        setting: true,
      }}
    />
  );
};

export default LabOrdersTable;
