import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  CheckSquareOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FormOutlined,
  MedicineBoxOutlined,
  MessageOutlined,
  PlusCircleFilled,
  SettingOutlined,
  TagsOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useEffectOnce } from "react-use";
import { matchPath, NavLink } from "react-router-dom";
import { Menu } from "antd";

import { Path } from "core/routes/routes";
import { useLocation } from "react-router";
import useAccess from "features/access/useAccess";
import Logo, { LogoType } from "components/logo/Logo";
import { MenuState } from "features/layout/MenuState";

interface INavigationMenuProps {
  menuState: MenuState;
}

enum Keys {
  Root = "Root",
  CreateOrder = "CreateOrder",
  Dentists = "Dentists",
  ListOrders = "ListOrders",
  ListBillingStatements = "ListBillingStatements",
  CreateInvoice = "CreateInvoice",
  OrderCatalogue = "OrderCatalogue",
  Settings = "Settings",
  Subscriptions = "Subscriptions",
  PricingLists = "PricingLists",
  Employees = "Employees",
  Chat = "Chat",
}

const menuItemPathToSelectedMenuKeys: Partial<Record<Path, Keys[]>> = {
  [Path.Root]: [Keys.Root],
  [Path.ListOrders]: [Keys.ListOrders],
  [Path.OrderDetails]: [Keys.ListOrders],
  [Path.CreateOrder]: [Keys.CreateOrder],
  [Path.EditOrderItems]: [Keys.ListOrders],
  [Path.BillingStatements]: [Keys.ListBillingStatements],
  [Path.CreateBillingStatement]: [Keys.CreateInvoice],
};

const convertPathToSelectedMenuKeys = (path: Path | null): Keys[] => {
  if (path === null) {
    return [];
  }

  const selectedMenuKeys = menuItemPathToSelectedMenuKeys[path];

  if (selectedMenuKeys === undefined) {
    return [];
  }

  return selectedMenuKeys;
};

const NavigationMenu: FunctionComponent<INavigationMenuProps> = ({ menuState, ...props }) => {
  const access = useAccess();
  const [selectedKeys, setSelectedKeys] = useState<Keys[]>([]);
  const location = useLocation();

  const reversePathLocationToPathName = useCallback((pathLocation: string) => {
    let foundPath: Path | null = null;

    for (const path of Object.values(Path)) {
      const isPath = matchPath(pathLocation, path) !== null;

      foundPath = isPath ? path : foundPath;

      if (foundPath !== null) {
        break;
      }
    }

    return foundPath;
  }, []);

  const onRouteUpdate = useCallback(
    (pathLocation: string) => {
      const activePath = reversePathLocationToPathName(pathLocation);
      const nextSelectedKeys = convertPathToSelectedMenuKeys(activePath);
      setSelectedKeys(nextSelectedKeys);
    },
    [reversePathLocationToPathName]
  );

  useEffectOnce(() => {
    onRouteUpdate(location.pathname);
  });

  useEffect(() => {
    onRouteUpdate(location.pathname);
  }, [location, onRouteUpdate]);

  return (
    <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
      <NavLink to={Path.ListOrders} className={"flex p-3"} style={{ height: "75px" }}>
        {menuState === MenuState.Shown ? (
          <Logo type={LogoType.FullLogoOnDarkBackground} />
        ) : (
          <Logo type={LogoType.IconOnlyLogo} />
        )}
      </NavLink>
      <Menu.Item key={Keys.CreateOrder} icon={<PlusCircleFilled />}>
        <NavLink to={Path.CreateOrder}>
          <FormattedMessage id="common.create-order" defaultMessage="Stwórz zamówienie" />
        </NavLink>
      </Menu.Item>
      <Menu.Item key={Keys.ListOrders} icon={<FormOutlined />}>
        <NavLink to={Path.ListOrders}>
          <FormattedMessage id="common.orders" defaultMessage="Zamówienia" />
        </NavLink>
      </Menu.Item>
      {access.toChat && (
        <Menu.Item key={Keys.Chat} icon={<MessageOutlined />}>
          <NavLink to={Path.ChatList}>
            <FormattedMessage id="common.chat" defaultMessage="Czat" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toInvoices && (
        <Menu.Item key={Keys.ListBillingStatements} icon={<FilePdfOutlined />}>
          <NavLink to={Path.BillingStatements}>
            <FormattedMessage id="common.billing-statements" defaultMessage="Rozliczenia" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toLabClients && (
        <Menu.Item key={Keys.Dentists} icon={<MedicineBoxOutlined />}>
          <NavLink to={Path.ListDentists}>
            <FormattedMessage id="common.dentists" defaultMessage="Dentyści" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toLabEmployees && (
        <Menu.Item key={Keys.Employees} icon={<TeamOutlined />}>
          <NavLink to={Path.ListEmployees}>
            <FormattedMessage id="common.employees" defaultMessage="Pracownicy" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toOrderCatalogue && (
        <Menu.Item key={Keys.OrderCatalogue} icon={<CheckSquareOutlined />}>
          <NavLink to={Path.OrderCatalogue}>
            <FormattedMessage id="common.order-catalogue" defaultMessage="Katalog zamówień" />
          </NavLink>
        </Menu.Item>
      )}
      {access.isDentist && (
        <Menu.Item key={Keys.PricingLists} icon={<TagsOutlined />}>
          <NavLink to={Path.DentistsPricingLists}>
            <FormattedMessage id="common.pricing-catalogue" defaultMessage="Cenniki" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toPricingLists && access.isLabAccount && (
        <Menu.Item key={Keys.PricingLists} icon={<TagsOutlined />}>
          <NavLink to={Path.LaboratoryPricingLists}>
            <FormattedMessage id="common.pricing-catalogue" defaultMessage="Cenniki" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toSettings && (
        <Menu.Item key={Keys.Settings} icon={<SettingOutlined />}>
          <NavLink to={Path.Settings}>
            <FormattedMessage id="common.settings" defaultMessage="Ustawienia" />
          </NavLink>
        </Menu.Item>
      )}
      {access.toSubscriptions && (
        <Menu.Item key={Keys.Subscriptions} icon={<FileProtectOutlined />}>
          <NavLink to={Path.Subscriptions}>
            <FormattedMessage id="common.subscriptions" defaultMessage="Subskrypcje" />
          </NavLink>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default NavigationMenu;
