import { IDentistOrderReadModel, ILabOrderReadModel, Reader, UnifiedOrderReadModel } from "features/orders/order.model";

export const mapToUnifiedOrderReadModel = (
  model: IDentistOrderReadModel | ILabOrderReadModel
): UnifiedOrderReadModel => {
  // noinspection SuspiciousTypeOfGuard
  if (typeof (model as ILabOrderReadModel).orderNumber === "number") {
    return {
      reader: Reader.Lab,
      ...model,
    } as UnifiedOrderReadModel;
  } else {
    return {
      reader: Reader.Dentist,
      ...model,
    } as UnifiedOrderReadModel;
  }
};
