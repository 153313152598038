import { IDentist, IDentistId } from "features/dentist/dentist.model";
import { IOrderId, IOrderItemId, OrderNumberAvailability } from "features/orders/order.model";
import { IShortOrderType } from "features/catalogue/order-type.model";
import { OrderSpecification } from "features/orders/specification/order-specification";
import { IMoney } from "core/money/money.model";
import { ISODateTimeString } from "core/time/time.model";
import { Gender } from "core/gender/gender.model";
import { Country } from "core/countries/countries.model";
import { ColorOrNone } from "features/orders/color/color.model";
import { IUserId } from "features/auth/auth.model";
import { ILabId, IShortLab } from "features/dental-lab/dental-lab.model";
import { IResult } from "core/lib/types/result";
import { IOrderEntry } from "features/orders/components/order-entry/order-entry.model";
import { IShortPricingList } from "features/pricing-lists/pricing-list.models";
import { Status } from "features/orders/status/order-status.model";
import { IOrderCatalogue } from "features/catalogue/category.model";

export interface IBaseOrderSubmitValues {
  readonly startDate: ISODateTimeString | null;
  readonly dueDate: ISODateTimeString | null;

  readonly patientFirstName: string | null;
  readonly patientLastName: string | null;
  readonly patientCode: string | null;
  readonly patientAge: number | null;
  readonly patientGender: Gender;
  readonly patientCountry: Country | null;

  readonly orderColor: ColorOrNone;
  readonly orderNote: string;
}

type BaseOrderEntry = {
  orderType: IShortOrderType;
  specification: OrderSpecification;
  price: IMoney | null;
};

export interface ICreateLabOrderSubmitValues extends IBaseOrderSubmitValues {
  readonly dentistId: IDentistId;
  readonly orderNumber: number | null;
  readonly orderEntries: BaseOrderEntry[];
}

export interface IEditLabOrderSubmitValues extends IBaseOrderSubmitValues {
  readonly dentistId: IDentistId;
  readonly orderNumber: number | null;
  readonly orderEntries: (BaseOrderEntry & { orderItemId: IOrderItemId })[];
}

export interface ICreateDentistOrderSubmitValues extends IBaseOrderSubmitValues {
  readonly labId: ILabId;
  readonly orderEntries: BaseOrderEntry[];
}

export interface IEditDentistOrderSubmitValues extends IBaseOrderSubmitValues {
  readonly orderEntries: (BaseOrderEntry & { orderItemId: IOrderItemId })[];
}

export enum FormMode {
  LabCreate = "LabCreate",
  LabEdit = "LabEdit",
  DentistCreate = "DentistCreate",
  DentistEdit = "DentistEdit",
}

export enum CustomAlerts {
  EmptySpecification = "EmptySpecification",
  NoPricingAvailable = "NoPricingAvailable",
}

export type IsOrderNumberTakenCheckFn = (orderNumber: number) => Promise<IResult<OrderNumberAvailability>>;
export type OnLoadOrderCatalogueForLabFn = (labId: ILabId) => Promise<IResult<IOrderCatalogue>>;

export type LoadPricingListForDentistFn = (labId: ILabId, dentistId: IDentistId) => Promise<IResult<IShortPricingList>>;
export type OnLoadPricingListForLabFn = (dentistId: IUserId, labId: ILabId) => Promise<IResult<IShortPricingList>>;

export type IOrderFormValues = {
  labId: string; // only for dentist
  dentistId: string; // only for lab

  dueDate: ISODateTimeString | null;
  startDate: ISODateTimeString | null; // only for lab
  orderNumber: number | null;

  patientFirstName: string | null;
  patientLastName: string | null;
  patientCode: string | null;
  patientAge: number | null;
  patientGender: Gender | null;
  patientCountry: Country | null;

  orderEntries: IOrderEntry[];

  orderColor: ColorOrNone;
  orderNote: string;
};

type CreateLabOrderFormProps = {
  mode: FormMode.LabCreate;
  labId: ILabId;
  dentists: IDentist[];
  isOrderNumberTakenCheck: IsOrderNumberTakenCheckFn;
  onLoadOrderCatalogue: OnLoadOrderCatalogueForLabFn;
  onLoadPricingListForDentist: LoadPricingListForDentistFn;
  onSubmit: (submitValues: ICreateLabOrderSubmitValues) => Promise<IResult<IOrderId>>;
};

type EditLabOrderFormProps = {
  mode: FormMode.LabEdit;
  defaultValues?: Partial<IOrderFormValues> & { orderNumber: number };
  labId: ILabId;
  dentists: IDentist[];
  isOrderNumberTakenCheck: IsOrderNumberTakenCheckFn;
  onLoadOrderCatalogue: OnLoadOrderCatalogueForLabFn;
  onLoadPricingListForDentist: LoadPricingListForDentistFn;
  onSubmit: (submitValues: IEditLabOrderSubmitValues) => Promise<IResult<IOrderId>>;
};

type CreateDentistOrderFormProps = {
  mode: FormMode.DentistCreate;
  dentistUserId: IUserId;
  labs: IShortLab[];
  defaultValues?: Partial<IOrderFormValues>;
  onLoadOrderCatalogue: OnLoadOrderCatalogueForLabFn;
  onLoadPricingListForLab: OnLoadPricingListForLabFn;
  onSubmit: (submitValues: ICreateDentistOrderSubmitValues) => Promise<IResult<IOrderId>>;
};

type EditDentistOrderFormProps = {
  mode: FormMode.DentistEdit;
  dentistUserId: IUserId;
  labs: IShortLab[];
  orderStatus: Status;
  defaultValues?: Partial<IOrderFormValues>;
  onLoadOrderCatalogue: OnLoadOrderCatalogueForLabFn;
  onLoadPricingListForLab: OnLoadPricingListForLabFn;
  onSubmit: (submitValues: IEditDentistOrderSubmitValues) => Promise<IResult<IOrderId>>;
};

export type OrderFormProps =
  | CreateLabOrderFormProps
  | EditLabOrderFormProps
  | CreateDentistOrderFormProps
  | EditDentistOrderFormProps;
