import * as React from "react";
import { Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { Path } from "core/routes/routes";

interface ILoginRegisterTabsProps {
  activeTab: "login" | "register";
}

const LoginRegisterTabs: React.FC<ILoginRegisterTabsProps> = ({ activeTab }) => {
  const navigate = useNavigate();

  const handleChange = React.useCallback(
    (activeKey: string) => {
      if (activeKey === "login") {
        navigate(Path.Login);
      } else if (activeKey === "register") {
        navigate(Path.Register);
      }
    },
    [navigate]
  );

  return (
    <Tabs activeKey={activeTab} centered onChange={handleChange}>
      <Tabs.TabPane
        key={"login"}
        tab={<FormattedMessage id="login-register-tabs.login" defaultMessage="Logowanie" />}
      />
      <Tabs.TabPane
        key={"register"}
        tab={<FormattedMessage id="login-register-tabs.register" defaultMessage="Rejestracja" />}
      />
    </Tabs>
  );
};

export default LoginRegisterTabs;
