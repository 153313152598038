import * as React from "react";
import { useIntl } from "react-intl";
import { Status } from "features/orders/status/order-status.model";
import { statusNameFormatter } from "components/status/order-status/status-name.formatter";

const StatusName = React.memo<{ status: Status }>((props) => {
  const intl = useIntl();
  const statusName = statusNameFormatter(intl, props.status);
  return <>{statusName}</>;
});

export default StatusName;
