import * as React from "react";
import useSWR from "swr";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { nameof } from "ts-simple-nameof";
import { IPricingListApi } from "features/pricing-lists/service/IPricingListApi";
import { ILabId } from "features/dental-lab/dental-lab.model";
import useAuth from "features/auth/hooks/useAuth";

function useDentistPricingListForLab(labId: ILabId) {
  const { authContext } = useAuth();
  const pricingListApi = React.useContext(PricingListApiContext);

  const {
    data: getPricingList,
    isValidating: isValidating,
    mutate: revalidate,
  } = useSWR(
    () => [
      `method: ${nameof<IPricingListApi>((x) => x.getForLab)}`,
      `userId: ${authContext.userId!.value}`,
      `labId: ${labId.value}`,
    ],
    async () => pricingListApi.getForLab(authContext.userId!, labId)
  );

  return {
    getPricingList,
    isValidating: isValidating,
    revalidate: revalidate,
  };
}

export default useDentistPricingListForLab;
