import { ITaxIdDTO } from "contracts/common-dtos/tax-id.dto";
import { ITaxId } from "core/taxes/tax-id.model";
import {
  mapCountryCodeToCountry,
  mapCountryToCountryCode,
} from "core/countries/countries.functions";

export const mapTaxIdDTOToTaxId = (dto: ITaxIdDTO): ITaxId => ({
  country: mapCountryCodeToCountry(dto.country),
  id: dto.id,
});

export const mapTaxIdToTaxIdDTO = (taxId: ITaxId): ITaxIdDTO => ({
  country: mapCountryToCountryCode(taxId.country),
  id: taxId.id,
});
