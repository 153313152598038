import React, { useCallback, Suspense } from "react";
import { Card, Empty, Spin } from "antd";
import { saveAs } from "file-saver";
import { IOrderId } from "features/orders/order.model";
import { Image } from "antd";

import useOrderFiles from "features/order-files/hooks/useOrderFiles";
import styles from "./files.module.scss";
import File from "features/order-files/components/file/File";
import useBooleanFlag from "hooks/useBooleanFlag";
import Button from "components/button/Button";
import { UploadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import useConfirmations from "components/confirmations/useConfirmations";
import { IOrderFile } from "features/orders/order-files.model";

const FilesUploadModal = React.lazy(() => import("../uppload-button/FilesUploadModal"));

interface IOrderFilesProps {
  orderId: IOrderId;
}

const OrderFiles: React.FunctionComponent<IOrderFilesProps> = ({ orderId }: IOrderFilesProps) => {
  const { deleteOrderFileConfirmation } = useConfirmations();
  const { flag: isShown, check: show, uncheck: hide } = useBooleanFlag(false);
  const { getOrderFiles, refresh, deleteOrderFile, isLoading } = useOrderFiles(orderId);

  const handleModalHide = useCallback(
    (count) => {
      if (count > 0) {
        refresh();
      }

      hide();
    },
    [hide, refresh]
  );

  const handleOnConfirmDeleteClick = async (file: IOrderFile) => {
    const deleteResponse = await deleteOrderFile(file.fileId);

    if (deleteResponse.isOk()) {
      await refresh();
    }
  };

  const handleDeleteConfirm = (file: IOrderFile) => {
    deleteOrderFileConfirmation(file, async () => {
      await handleOnConfirmDeleteClick(file);
    });
  };

  const handleOnFileItemClick = async (file: IOrderFile) => {
    saveAs(file.url, file.name + "." + file.extension);
  };

  return (
    <>
      <Suspense fallback={null}>
        <FilesUploadModal orderId={orderId} onHide={handleModalHide} isOpen={isShown} onShow={show} />
      </Suspense>
      <Card
        className={styles.filesCard}
        title={
          <Button size={"large"} onClick={show} type={"primary"} icon={<UploadOutlined className={"mr-2"} />}>
            <FormattedMessage id="upload-button.upload-text" defaultMessage="Wyślij pliki" />
          </Button>
        }
      >
        <div>
          {getOrderFiles === undefined ? (
            <Spin />
          ) : (
            getOrderFiles
              .map((files) => {
                if (files.length === 0) {
                  return (
                    <Empty
                      key={"empty"}
                      description={
                        <FormattedMessage
                          id={"files.no-files-uploaded-yet"}
                          defaultMessage={"Nie ma żadnych przesłanych plików"}
                        />
                      }
                    />
                  );
                }
                return (
                  <div key={"file-gallery"} className={"flex flex-wrap gap-4"}>
                    <Image.PreviewGroup>
                      {files.map((file) => (
                        <File
                          key={file.fileId.value}
                          file={file}
                          onRemove={handleDeleteConfirm}
                          onSave={handleOnFileItemClick}
                        />
                      ))}
                    </Image.PreviewGroup>
                  </div>
                );
              })
              .unwrap()
          )}
        </div>
      </Card>
    </>
  );
};

export default OrderFiles;
