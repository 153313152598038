import React from "react";
import useSWR from "swr";
import OrderApiContext from "../order.context";
import { IOrderId } from "../order.model";
import {
  IChangeFinishDateRequest,
  IChangeOrderItemPriceRequest,
  IChangeOrderStatusRequest,
} from "../requests/orders.requests";
import useAuth from "features/auth/hooks/useAuth";
import { AccountType } from "features/auth/auth.model";
import { nameof } from "ts-simple-nameof";
import { IOrderApi } from "features/orders/service/orders.service";
import { mapToUnifiedOrderReadModel } from "features/orders/mappers/unified-order-read-model.mappers";

function useOrder(orderId: IOrderId) {
  const orderApi = React.useContext(OrderApiContext);
  const { authContext } = useAuth();

  const {
    data: getOrder,
    isValidating,
    mutate: revalidate,
  } = useSWR(
    () => [
      `method: ${
        authContext.accountType === AccountType.Dentist
          ? nameof<IOrderApi>((x) => x.getDentistOrderById)
          : nameof<IOrderApi>((x) => x.getLabOrderById)
      }`,
      `userId ${authContext.userId?.value}`,
      `accountType ${authContext.accountType}`,
      `orderId ${orderId.value}`,
    ],
    async () => {
      if (authContext.accountType === AccountType.Dentist) {
        const response = await orderApi.getDentistOrderById(orderId);
        return response.map(mapToUnifiedOrderReadModel);
      } else {
        const response = await orderApi.getLabOrderById(orderId);
        return response.map(mapToUnifiedOrderReadModel);
      }
    }
  );

  const changePrice = React.useCallback(
    async (request: IChangeOrderItemPriceRequest) => {
      const result = await orderApi.changeOrderItemPrice(request);
      await revalidate();
      return result;
    },
    [orderApi, revalidate]
  );

  const changeStatus = React.useCallback(
    async (request: IChangeOrderStatusRequest) => {
      const result = await orderApi.changeOrderStatus(request);
      await revalidate();
      return result;
    },
    [orderApi, revalidate]
  );

  const changeFinishDate = React.useCallback(
    async (request: IChangeFinishDateRequest) => {
      const result = await orderApi.changeFinishDate(request);
      await revalidate();
      return result;
    },
    [orderApi, revalidate]
  );

  const deleteOrder = React.useCallback(
    async (orderId: IOrderId) => {
      switch (authContext.accountType!) {
        case AccountType.LabOwner:
        case AccountType.LabEmployee:
          return orderApi.deleteLabOrder(orderId);
        case AccountType.Dentist:
          return orderApi.deleteDentistOrder(orderId);
      }
    },
    [authContext.accountType, orderApi]
  );

  return {
    getOrder: getOrder,
    changePrice,
    changeStatus,
    changeFinishDate,
    deleteOrder,
    isLoading: isValidating,
    refresh: revalidate,
  };
}

export default useOrder;
