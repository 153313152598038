import * as React from "react";
import { ShadeGuide } from "features/orders/color/color.model";

interface IShadeFormatterProps {
  guide: ShadeGuide;
}

const ShadeGuideFormatter: React.FunctionComponent<IShadeFormatterProps> = ({ guide }) => {
  switch (guide) {
    case ShadeGuide.VitaClassical:
      return <span>Vita Classic</span>;
    case ShadeGuide.Vita3dMaster:
      return <span>Vita 3D Master</span>;
    case ShadeGuide.Bleach:
      return <span>Bleach</span>;
    default:
      return <span>{"-"}</span>;
  }
};

export default ShadeGuideFormatter;
