import * as React from "react";
import { useState } from "react";
import { TreeSelect } from "antd";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { IOrderCatalogue, IOrderCategory } from "features/catalogue/category.model";
import { categoryFormatter } from "components/formatters/category/category-formatter";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";
import { ActiveStatus, CategoryNode, OrderTypeNode } from "features/catalogue/order-catalogue-nodes.models";
import { NodeType } from "features/catalogue/nodeType";
import { IOrderType } from "features/catalogue/order-type.model";
import { orderCodeFormatter } from "components/formatters/order-code-name/order-code-formatter";

interface ISearchOrderTypeProps {
  catalogue: IOrderCatalogue;
  hideNotActiveOrderTypes?: boolean;
  hideEmptyCategories?: boolean;
  onSelected?: (value: OrderTypeNode) => void;
}

export type OrderTypeNodeWithTranslation = OrderTypeNode & {
  key: string;
  value: string;
  title: React.ReactNode;
  searchable: string;
  selectable: true;
};

export type CategoryTypeWithTranslation = Omit<CategoryNode, "children"> & {
  key: string;
  value: string;
  title: React.ReactNode;
  selectable: false;
  children: OrderTypeNodeWithTranslation[];
};

const transformOrderTypeForTreeSelect = (
  intl: IntlShape,
  orderType: IOrderType,
  search: string
): OrderTypeNodeWithTranslation => {
  const orderTypeName = orderCodeFormatter(intl, { name: orderType.name, code: orderType.code });

  const index = orderTypeName.toLowerCase().indexOf(search);
  const beforeStr = orderTypeName.substr(0, index);
  const str = orderTypeName.substr(index, search.length);
  const afterStr = orderTypeName.substr(index + search.length);

  const title =
    index > -1 ? (
      <>
        {beforeStr}
        <span style={{ color: "#f50" }}>{str}</span>
        {afterStr}
      </>
    ) : (
      <>{orderTypeName}</>
    );

  return {
    key: orderType.id.value,
    value: orderType.id.value,
    id: orderType.id,
    code: orderType.code,
    type: NodeType.OrderType,
    name: orderTypeName,
    requiredSpecification: orderType.requiredSpecification,
    active: orderType.isActive ? ActiveStatus.Active : ActiveStatus.NotActive,
    selectable: true, // :thinking ...
    searchable: orderTypeName,
    title: title,
  };
};

const transformCategoryForTreeSelect = (
  intl: IntlShape,
  category: IOrderCategory,
  search: string,
  expandedKeys: string[],
  onExpand: (key: string) => () => void,
  onCollapse: (key: string) => () => void
): CategoryTypeWithTranslation => {
  const orderCategoryName = categoryFormatter(intl, { name: category.name, code: category.code });

  return {
    key: category.id.value,
    value: category.id.value,
    id: category.id,
    code: category.code,
    type: NodeType.Category,
    name: orderCategoryName,
    selectable: false,
    title: (
      <div
        onClick={expandedKeys.includes(category.id.value) ? onCollapse(category.id.value) : onExpand(category.id.value)}
      >
        {orderCategoryName}
      </div>
    ),
    children: category.orderTypes.map((orderType) => transformOrderTypeForTreeSelect(intl, orderType, search)),
  };
};

const OrderTypeSearch: React.FunctionComponent<ISearchOrderTypeProps> = ({
  catalogue,
  hideNotActiveOrderTypes = true,
  hideEmptyCategories = true,
  onSelected,
}) => {
  const intl = useIntl();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [value, setValue] = useState(undefined);
  const [search, setSearch] = useState("");
  const { flag: somethingSelected, check: somethingHasBeenSelected } = useBooleanFlag(false);

  const handleSelected = React.useCallback(
    (_, object: any) => {
      const { title, selectable, ...catalogueOrderType } = object as OrderTypeNodeWithTranslation;
      setSearch("");
      somethingHasBeenSelected();
      onSelected?.(catalogueOrderType);
    },
    [onSelected, somethingHasBeenSelected]
  );

  const handleSearch = React.useCallback((s) => setSearch(s), [setSearch]);

  const handleExpand = React.useCallback((key: (string | number)[]) => setExpandedKeys(key as any[] as string[]), []);

  const handleExpandKey = React.useCallback(
    (key: string) => () => setExpandedKeys([...expandedKeys, key]),
    [expandedKeys, setExpandedKeys]
  );

  const handleCollapseKey = React.useCallback(
    (key: string) => () => setExpandedKeys(expandedKeys.filter((k) => k !== key)),
    [expandedKeys, setExpandedKeys]
  );

  const handleChange = React.useCallback((value) => setValue(value), [setValue]);

  const handleDropdownVisibleChange = React.useCallback((isDropdownVisible) => {
    if (isDropdownVisible === false) {
      setValue(null as any);
    }
  }, []);

  const placeholderWhenEmpty = (
    <FormattedMessage id={"order-type-search.please-select-order"} defaultMessage={"Proszę wybierz zamówienie"} />
  );

  const placeholderWhenSomethingIsAdded = (
    <FormattedMessage id={"order-type-search.please-add-item-to-order"} defaultMessage={"Proszę dodaj do zamówienia"} />
  );

  let treeData = catalogue.categories.map((category) => {
    return transformCategoryForTreeSelect(
      intl,
      category,
      search.toLowerCase(),
      expandedKeys,
      handleExpandKey,
      handleCollapseKey
    );
  });

  if (hideNotActiveOrderTypes === true) {
    treeData = treeData.map((category) => ({
      ...category,
      children: category.children.filter((c) => c.active === ActiveStatus.Active),
    }));
  }

  if (hideEmptyCategories === true) {
    treeData = treeData.filter((category) => category.children.length >= 1);
  }

  return (
    <TreeSelect
      showSearch
      allowClear
      value={value}
      searchValue={search}
      treeExpandedKeys={search !== "" ? undefined : expandedKeys}
      treeDefaultExpandAll={search !== ""}
      style={{ width: "100%" }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={somethingSelected ? placeholderWhenSomethingIsAdded : placeholderWhenEmpty}
      treeNodeFilterProp={"searchable"}
      treeData={treeData}
      onChange={handleChange}
      onSearch={handleSearch}
      onSelect={handleSelected}
      onTreeExpand={handleExpand}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    />
  );
};

export default OrderTypeSearch;
