import * as React from "react";
import { Breadcrumb, PageHeader } from "antd";
import { FormattedMessage } from "react-intl";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import OrderCatalogueTable from "features/catalogue/components/order-catalogue-table/OrderCatalogueTable";
import { Helmet } from "react-helmet";

export interface IOrderCataloguePageProps {}

const OrderCataloguePage: React.FunctionComponent<IOrderCataloguePageProps> = () => {
  return (
    <>
      <FormattedMessage id={"page-titles.order-catalogue"} defaultMessage={"Katalog"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.OrdersList />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="order-catalogue.order-catalogue" defaultMessage="Katalog zamówień" />}
        />
      </HeaderContent>
      <PageContent className={"p-5"}>
        <OrderCatalogueTable />
      </PageContent>
    </>
  );
};

export default OrderCataloguePage;
