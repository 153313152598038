import { invalidArgumentError } from "core/errors/generate-error";

export const deepCopy = <T>(object: T): T => {
  try {
    return JSON.parse(JSON.stringify(object));
  } catch (error) {
    throw invalidArgumentError("could not preform deep copy" + error);
  }
};

export const isEmptyObject = (obj: any) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};
