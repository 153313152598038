import * as React from "react";
import { IDentist } from "features/dentist/dentist.model";
import { FormattedMessage } from "react-intl";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Descriptions, Typography } from "antd";
import PersonNameFormatter from "components/formatters/person-name/PersonNameFormatter";
import useModal from "components/modals/useModal";
import { isImageExtension } from "features/order-files/utils/file.utils";
import { Image } from "antd";
import { IOrderFile } from "features/orders/order-files.model";

function useConfirmations() {
  const modal = useModal();

  const deleteDentistConfirmation = (dentist: IDentist, onOk: () => void) => {
    modal.confirm({
      title: (
        <FormattedMessage id="confimrations.are-you-sure-to-delete" defaultMessage="Czy na pewno chcesz usunąć ?" />
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <Descriptions column={1} className={"mt-8"}>
          <Descriptions.Item label={<FormattedMessage id="confimrations.dentist" defaultMessage="Dentysta" />}>
            <PersonNameFormatter personName={dentist.name} />
          </Descriptions.Item>
        </Descriptions>
      ),
      okText: <FormattedMessage id="confimrations.yes" defaultMessage="Tak" />,
      okType: "danger",
      cancelText: <FormattedMessage id="confimrations.cancel" defaultMessage="Anuluj" />,
      onOk: onOk,
    });
  };

  const deleteOrderConfirmation = (onOk: () => void) => {
    modal.confirm({
      title: (
        <FormattedMessage
          id="confimrations.are-you-sure-to-delete-order"
          defaultMessage="Czy na pewno chcesz usunąć zamówienie ?"
        />
      ),
      icon: <ExclamationCircleOutlined />,
      okText: <FormattedMessage id="confimrations.yes" defaultMessage="Tak" />,
      okType: "danger",
      cancelText: <FormattedMessage id="confimrations.cancel" defaultMessage="Anuluj" />,
      onOk: onOk,
    });
  };

  const archiveOrderTypeConfirmation = (customOrderTypeName: string, onOk: () => void) => {
    modal.confirm({
      title: (
        <FormattedMessage
          id="confimrations.are-you-sure-to-archive-order-type"
          defaultMessage="Czy na pewno chcesz zarchiwizować typ zamówienia ?"
        />
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <Typography.Paragraph>
            Wszystkie istniejące już zamówienia typu
            <br />
            <Typography.Text code>{customOrderTypeName}</Typography.Text> pozostaną bez zmian
          </Typography.Paragraph>
          <Typography.Paragraph>
            Po zarchiwizowaniu nie będzie można już tworzyć nowych zamówień tego typu
          </Typography.Paragraph>
          <Typography.Paragraph>
            Ten typ zamówienia, nie będzie już widoczny w katalogu prac dostępnych do zamówienia
          </Typography.Paragraph>
        </div>
      ),
      okText: <FormattedMessage id="confimrations.archive" defaultMessage="Archiwizuj" />,
      okType: "danger",
      cancelText: <FormattedMessage id="confimrations.cancel" defaultMessage="Anuluj" />,
      onOk: onOk,
    });
  };

  const deleteOrderFileConfirmation = (file: IOrderFile, onOk: () => void) => {
    modal.confirm({
      title: (
        <FormattedMessage id="confimrations.are-you-sure-to-delete" defaultMessage="Czy na pewno chcesz usunąć ?" />
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <Descriptions column={1} className={"mt-8"}>
            <Descriptions.Item label={<FormattedMessage id="confimrations.file-name" defaultMessage="Nazwa pliku" />}>
              {file.name}
            </Descriptions.Item>
          </Descriptions>
          {isImageExtension(file) && <Image preview={false} src={file.url} width={200} height={200} />}
        </div>
      ),
      okText: <FormattedMessage id="confimrations.yes" defaultMessage="Tak" />,
      okType: "danger",
      cancelText: <FormattedMessage id="confimrations.cancel" defaultMessage="Anuluj" />,
      onOk: onOk,
    });
  };

  const deleteBillingStatementConfirmation = (onOk: () => void) => {
    modal.confirm({
      title: (
        <FormattedMessage id="confimrations.are-you-sure-to-delete" defaultMessage="Czy na pewno chcesz usunąć ?" />
      ),
      icon: <ExclamationCircleOutlined />,
      okText: <FormattedMessage id="confimrations.yes" defaultMessage="Tak" />,
      okType: "danger",
      cancelText: <FormattedMessage id="confimrations.cancel" defaultMessage="Anuluj" />,
      onOk: onOk,
    });
  };


  return {
    deleteDentistConfirmation,
    deleteOrderFileConfirmation,
    deleteOrderConfirmation,
    archiveOrderTypeConfirmation,
    deleteBillingStatementConfirmation,
  };
}

export default useConfirmations;
