import { PricingDTO, PricingStrategyCode } from "./pricing.response.dto";
import { Arch } from "core/tooth/tooth.model";
import { AnchorTypeCode, ArchCode } from "./orders.dto.models";
import { PointArea, PointAreaCode } from "core/point/point.enums";
import { AnchorType } from "../order-type/anchor-type.models";
import { Pricing, PricingStrategy } from "core/pricing/pricing.model";

export const mapPricingToPricingDTO = (pricing: Pricing): PricingDTO => {
  switch (pricing.strategy) {
    case PricingStrategy.NotSet:
      return {
        pricingStrategy: PricingStrategyCode.NotSet,
      };
    case PricingStrategy.Individual:
      return {
        pricingStrategy: PricingStrategyCode.Individual,
      };
    case PricingStrategy.Fixed:
      return {
        pricingStrategy: PricingStrategyCode.FixedPrice,
        fixedPrice: pricing.fixedPrice,
      };
    case PricingStrategy.PerQuantity:
      return {
        pricingStrategy: PricingStrategyCode.PerQuantity,
        pricePerItem: {
          amount: pricing.pricePerItem.amount,
          currency: pricing.pricePerItem.currency,
        },
      };
    case PricingStrategy.PerArch:
      return {
        pricingStrategy: PricingStrategyCode.PerArch,
        pricePerArch: {
          [ArchCode.Upper]: pricing.pricesPerArch[Arch.UpperArch],
          [ArchCode.Lower]: pricing.pricesPerArch[Arch.LowerArch],
        },
      };
    case PricingStrategy.PerPoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerPoint;

      return {
        pricingStrategy: PricingStrategyCode.PerPoint,
        pricePerPoint: { [PointAreaCode.Front]: front, [PointAreaCode.Back]: back },
      };
    }
    case PricingStrategy.PerPointWithArchBasePrice: {
      const { pricePerPoint, basePriceForArch } = pricing;

      return {
        pricingStrategy: PricingStrategyCode.PerPointWithArchBasePrice,
        baseArchPrice: basePriceForArch,
        pricePerPoint: pricePerPoint,
      };
    }
    case PricingStrategy.PerCrown:
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerCrown;

      return {
        pricingStrategy: PricingStrategyCode.PerCrown,
        pricePerCrown: {
          [PointAreaCode.Front]: {
            [AnchorTypeCode.ScrewedInToImplant]: front[AnchorType.ScrewedInToImplant],
            [AnchorTypeCode.CementedToImplant]: front[AnchorType.CementedToImplant],
            [AnchorTypeCode.CementedToPreparedTooth]: front[AnchorType.CementedToPreparedTooth],
          },
          [PointAreaCode.Back]: {
            [AnchorTypeCode.ScrewedInToImplant]: back[AnchorType.ScrewedInToImplant],
            [AnchorTypeCode.CementedToImplant]: back[AnchorType.CementedToImplant],
            [AnchorTypeCode.CementedToPreparedTooth]: back[AnchorType.CementedToPreparedTooth],
          },
        },
      };
    case PricingStrategy.PerBridgePoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerBridgePoint;

      return {
        pricingStrategy: PricingStrategyCode.PerBridgePoint,
        pricePerBridgePoint: {
          [PointAreaCode.Front]: {
            [AnchorTypeCode.ScrewedInToImplant]: front[AnchorType.ScrewedInToImplant],
            [AnchorTypeCode.CementedToImplant]: front[AnchorType.CementedToImplant],
            [AnchorTypeCode.CementedToPreparedTooth]: front[AnchorType.CementedToPreparedTooth],
            [AnchorTypeCode.Pontic]: front[AnchorType.Pontic],
          },
          [PointAreaCode.Back]: {
            [AnchorTypeCode.ScrewedInToImplant]: back[AnchorType.ScrewedInToImplant],
            [AnchorTypeCode.CementedToImplant]: back[AnchorType.CementedToImplant],
            [AnchorTypeCode.CementedToPreparedTooth]: back[AnchorType.CementedToPreparedTooth],
            [AnchorTypeCode.Pontic]: back[AnchorType.Pontic],
          },
        },
      };
    }
    case PricingStrategy.PerMarylandBridgePoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerMarylandBridgePoint;

      return {
        pricingStrategy: PricingStrategyCode.PerMarylandBridgePoint,
        pricePerMarylandBridgePoint: {
          [PointAreaCode.Front]: {
            [AnchorTypeCode.CementedToPreparedTooth]: front[AnchorType.CementedToPreparedTooth],
            [AnchorTypeCode.Pontic]: front[AnchorType.Pontic],
          },
          [PointAreaCode.Back]: {
            [AnchorTypeCode.CementedToPreparedTooth]: back[AnchorType.CementedToPreparedTooth],
            [AnchorTypeCode.Pontic]: back[AnchorType.Pontic],
          },
        },
      };
    }
    case PricingStrategy.PerFoundationPoint: {
      const {
        [AnchorType.ScrewedInToImplant]: screwedInToImplant,
        [AnchorType.CementedToImplant]: cementedToImplant,
        [AnchorType.Pontic]: pontic,
      } = pricing.pricesPerFoundationPoint;

      return {
        pricingStrategy: PricingStrategyCode.PerFoundationPoint,
        pricePerFoundationPoint: {
          [AnchorTypeCode.ScrewedInToImplant]: screwedInToImplant,
          [AnchorTypeCode.CementedToImplant]: cementedToImplant,
          [AnchorTypeCode.Pontic]: pontic,
        },
      };
    }
  }
};

export const mapPricingDTOtoPricing = (pricing: PricingDTO): Pricing => {
  switch (pricing.pricingStrategy) {
    case PricingStrategyCode.NotSet:
      return {
        strategy: PricingStrategy.NotSet,
      };
    case PricingStrategyCode.Individual:
      return {
        strategy: PricingStrategy.Individual,
      };
    case PricingStrategyCode.PerQuantity:
      return {
        strategy: PricingStrategy.PerQuantity,
        pricePerItem: pricing.pricePerItem,
      };
    case PricingStrategyCode.FixedPrice:
      return {
        strategy: PricingStrategy.Fixed,
        fixedPrice: pricing.fixedPrice,
      };
    case PricingStrategyCode.PerArch:
      const { pricePerArch } = pricing;
      const { [ArchCode.Upper]: upper, [ArchCode.Lower]: lower } = pricePerArch;
      return {
        strategy: PricingStrategy.PerArch,
        pricesPerArch: {
          [Arch.UpperArch]: upper,
          [Arch.LowerArch]: lower,
        },
      };
    case PricingStrategyCode.PerPoint: {
      const { pricePerPoint } = pricing;
      const { [PointAreaCode.Front]: front, [PointAreaCode.Back]: back } = pricePerPoint;
      return {
        strategy: PricingStrategy.PerPoint,
        pricesPerPoint: {
          [PointArea.Front]: front,
          [PointArea.Back]: back,
        },
      };
    }
    case PricingStrategyCode.PerPointWithArchBasePrice:
      return {
        strategy: PricingStrategy.PerPointWithArchBasePrice,
        pricePerPoint: pricing.pricePerPoint,
        basePriceForArch: pricing.baseArchPrice,
      };
    case PricingStrategyCode.PerCrown: {
      const { pricePerCrown } = pricing;
      const { [PointAreaCode.Front]: frontCrowns, [PointAreaCode.Back]: backCrowns } = pricePerCrown;
      return {
        strategy: PricingStrategy.PerCrown,
        pricesPerCrown: {
          [PointArea.Front]: {
            [AnchorType.ScrewedInToImplant]: frontCrowns[AnchorTypeCode.ScrewedInToImplant],
            [AnchorType.CementedToImplant]: frontCrowns[AnchorTypeCode.CementedToImplant],
            [AnchorType.CementedToPreparedTooth]: frontCrowns[AnchorTypeCode.CementedToPreparedTooth],
          },
          [PointArea.Back]: {
            [AnchorType.ScrewedInToImplant]: backCrowns[AnchorTypeCode.ScrewedInToImplant],
            [AnchorType.CementedToImplant]: backCrowns[AnchorTypeCode.CementedToImplant],
            [AnchorType.CementedToPreparedTooth]: backCrowns[AnchorTypeCode.CementedToPreparedTooth],
          },
        },
      };
    }
    case PricingStrategyCode.PerBridgePoint: {
      const { pricePerBridgePoint } = pricing;
      const { [PointAreaCode.Front]: front, [PointAreaCode.Back]: back } = pricePerBridgePoint;
      return {
        strategy: PricingStrategy.PerBridgePoint,
        pricesPerBridgePoint: {
          [PointArea.Front]: {
            [AnchorType.ScrewedInToImplant]: front[AnchorTypeCode.ScrewedInToImplant],
            [AnchorType.CementedToImplant]: front[AnchorTypeCode.CementedToImplant],
            [AnchorType.CementedToPreparedTooth]: front[AnchorTypeCode.CementedToPreparedTooth],
            [AnchorType.Pontic]: front[AnchorTypeCode.Pontic],
          },
          [PointArea.Back]: {
            [AnchorType.ScrewedInToImplant]: back[AnchorTypeCode.ScrewedInToImplant],
            [AnchorType.CementedToImplant]: back[AnchorTypeCode.CementedToImplant],
            [AnchorType.CementedToPreparedTooth]: back[AnchorTypeCode.CementedToPreparedTooth],
            [AnchorType.Pontic]: back[AnchorTypeCode.Pontic],
          },
        },
      };
    }
    case PricingStrategyCode.PerMarylandBridgePoint: {
      const { pricePerMarylandBridgePoint } = pricing;
      const { [PointAreaCode.Front]: front, [PointAreaCode.Back]: back } = pricePerMarylandBridgePoint;
      return {
        strategy: PricingStrategy.PerMarylandBridgePoint,
        pricesPerMarylandBridgePoint: {
          [PointArea.Front]: {
            [AnchorType.CementedToPreparedTooth]: front[AnchorTypeCode.CementedToPreparedTooth],
            [AnchorType.Pontic]: front[AnchorTypeCode.Pontic],
          },
          [PointArea.Back]: {
            [AnchorType.CementedToPreparedTooth]: back[AnchorTypeCode.CementedToPreparedTooth],
            [AnchorType.Pontic]: back[AnchorTypeCode.Pontic],
          },
        },
      };
    }
    case PricingStrategyCode.PerFoundationPoint: {
      const {
        [AnchorTypeCode.ScrewedInToImplant]: screwedInToImplant,
        [AnchorTypeCode.CementedToImplant]: cementedToImplant,
        [AnchorTypeCode.Pontic]: pontic,
      } = pricing.pricePerFoundationPoint;

      return {
        strategy: PricingStrategy.PerFoundationPoint,
        pricesPerFoundationPoint: {
          [AnchorType.CementedToImplant]: cementedToImplant,
          [AnchorType.ScrewedInToImplant]: screwedInToImplant,
          [AnchorType.Pontic]: pontic,
        },
      };
    }
  }
};
