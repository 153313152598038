import { AnchorType } from "../../../../order-type/anchor-type.models";
import { AnchorOptions, DotOptions, LabelOptions, PointOptions } from "./jaw-view.model";

export const defaultPointOptions: PointOptions = { allowedPoints: null };
export const defaultDotOptions: DotOptions = { visible: false, editable: false };
export const defaultAnchorOptions: AnchorOptions = { editable: false, allowedAnchors: [], visible: false };
export const defaultLabelOptions: LabelOptions = { visible: false };

export const anchorColors = {
  [AnchorType.CementedToImplant]: {
    border: "#b37feb",
    fill: "#d3adf7",
  },
  [AnchorType.ScrewedInToImplant]: {
    border: "#52c41a",
    fill: "#b7eb8f",
  },
  [AnchorType.CementedToPreparedTooth]: {
    border: "#87e8de",
    fill: "#b5f5ec",
  },
  [AnchorType.Pontic]: {
    border: "#ffec3d",
    fill: "#fffb8f",
  },
};

export const jawSvgStyles = `
svg text {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.cls-1 {
  fill: #f9f9fa;
}
.cls-2 {
  fill: #d0d0d1;
}
.cls-pts-fill {
  fill: #fff;
}
.cls-pts-fill--selected {
  fill: #acdeff;
}
.cls-pts-fill--selected-pontic {
  fill: ${anchorColors[AnchorType.Pontic].fill};
}
.cls-pts-fill--cemented-to-prepared-tooth {
  fill: ${anchorColors[AnchorType.CementedToPreparedTooth].fill};
}
.cls-pts-fill--cemented-to-implant {
  fill: ${anchorColors[AnchorType.CementedToImplant].fill};
}
.cls-pts-fill--screwed-in-to-implant {
  fill: ${anchorColors[AnchorType.ScrewedInToImplant].fill};
}
.cls-pts-border {
  fill: #c6c6c6;
}            
.cls-pts-border--selected {
  fill: #1b9aff;
  stroke: #1b9aff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-pts-border--selected-pontic {
  fill: ${anchorColors[AnchorType.Pontic].border};
  stroke: ${anchorColors[AnchorType.Pontic].border};
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-pts-border--cemented-to-prepared-tooth {
  fill: ${anchorColors[AnchorType.CementedToPreparedTooth].border};
  stroke: ${anchorColors[AnchorType.CementedToPreparedTooth].border};
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-pts-border--cemented-to-implant {
  fill: ${anchorColors[AnchorType.CementedToImplant].border};
  stroke: ${anchorColors[AnchorType.CementedToImplant].border};
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-pts-border--screwed-in-to-implant {
  fill: ${anchorColors[AnchorType.ScrewedInToImplant].border};
  stroke: ${anchorColors[AnchorType.ScrewedInToImplant].border};
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-pts-shadow {
  fill: #e4e4e4;
  opacity: 0.4;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.cls-text {
  font-size: 24px;
  fill: #00152e;
  font-family: SegoeUI-SemiBold, Segoe UI;
  font-weight: 600;
}
.cls-dot {
  fill: #f9f9fa;
  stroke: #c6c6c6;
  stroke-width: 2px;
}
.cls-dot--selected {
  fill: #f9f9fa;
  stroke: #1b9aff;
  stroke-width: 5px;
}
`;
