import * as React from "react";
import { useCallback, useState } from "react";
import { Steps } from "antd";
import { FormattedMessage } from "react-intl";
import { Status } from "features/orders/status/order-status.model";
import { IResult } from "core/lib/types/result";
import { LoadingOutlined } from "@ant-design/icons";
import StatusName from '../../../../../components/status/order-status/StatusName';

interface IOrderStatusCardProps {
  status: Status;
  onChange?: (status: Status) => Promise<IResult<any>>;
}

interface IOrderStatusCardState {
  currentStep: Step;
  isLoading: boolean;
}

enum Step {
  ReadyForPickUpFromClinic = 0,
  TodoInLab = 1,
  InProgressInLab = 2,
  InProgressInClinic = 3,
  Finished = 4,
}

const mapStatusToStepIndex = (status: Status) => {
  switch (status) {
    case Status.ReadyForPickUpFromClinic:
      return Step.ReadyForPickUpFromClinic;
    case Status.TodoInLab:
      return Step.TodoInLab;
    case Status.InProgressInLab:
      return Step.InProgressInLab;
    case Status.InProgressInClinic:
      return Step.InProgressInClinic;
    case Status.Finished:
      return Step.Finished;
  }
};

const mapStepIndexToStatus = (step: Step): Status => {
  switch (step) {
    case Step.ReadyForPickUpFromClinic:
      return Status.ReadyForPickUpFromClinic;
    case Step.TodoInLab:
      return Status.TodoInLab;
    case Step.InProgressInLab:
      return Status.InProgressInLab;
    case Step.InProgressInClinic:
      return Status.InProgressInClinic;
    case Step.Finished:
      return Status.Finished;
  }
};

export const OrderStatusCard: React.FunctionComponent<IOrderStatusCardProps> = ({ status, onChange }) => {
  const [state, setState] = useState<IOrderStatusCardState>({
    currentStep: mapStatusToStepIndex(status),
    isLoading: false,
  });

  const handleChange = useCallback(
    async (newStep: Step) => {
      if (onChange === undefined) {
        return;
      }

      const previousStep = state.currentStep;

      if (newStep === previousStep) {
        return;
      }

      setState({ currentStep: newStep, isLoading: true });
      const result = await onChange(mapStepIndexToStatus(newStep));

      if (result.isOk()) {
        setState({ currentStep: newStep, isLoading: false });
      } else {
        setState({ currentStep: previousStep, isLoading: false });
      }
    },
    [onChange, state.currentStep]
  );

  return (
    <Steps
      className={"pt-4"}
      direction={"vertical"}
      current={state.currentStep}
      onChange={onChange === undefined ? undefined : handleChange}
    >
      <Steps.Step
        icon={state.currentStep === Step.ReadyForPickUpFromClinic && state.isLoading ? <LoadingOutlined /> : undefined}
        title={<StatusName status={Status.ReadyForPickUpFromClinic} />}
        description={
          <FormattedMessage
            id="order-status-card.order-is-ready-for-pickup-in-clinic"
            defaultMessage="Praca gotowa na odbiór w klinice"
          />
        }
      />
      <Steps.Step
        icon={state.currentStep === Step.TodoInLab && state.isLoading ? <LoadingOutlined /> : undefined}
        title={<StatusName status={Status.TodoInLab} />}
        description={
          <FormattedMessage
            id="order-status-card.imprint-creation-and-analysis"
            defaultMessage="Odlewanie i analiza wycisków"
          />
        }
      />
      <Steps.Step
        icon={state.currentStep === Step.InProgressInLab && state.isLoading ? <LoadingOutlined /> : undefined}
        title={<StatusName status={Status.InProgressInLab} />}
        description={
          <FormattedMessage
            id="order-status-card.order-work-has-begun-or-is-resumed"
            defaultMessage="Prace nad zleceniem rozpoczęte/kontynuowane"
          />
        }
      />
      <Steps.Step
        icon={state.currentStep === Step.InProgressInClinic && state.isLoading ? <LoadingOutlined /> : undefined}
        title={<StatusName status={Status.InProgressInClinic} />}
        description={
          <FormattedMessage
            id="order-status-card.order-try-out-or-final-check"
            defaultMessage="Przymiarka lub ostateczne oddanie pracy"
          />
        }
      />
      <Steps.Step
        icon={state.currentStep === Step.Finished && state.isLoading ? <LoadingOutlined /> : undefined}
        title={<StatusName status={Status.Finished} />}
        description={
          <FormattedMessage
            id="order-status-card.doctor-has-accepted-the-order"
            defaultMessage="Doktor zaakceptował pracę, gotowa do fakturowania"
          />
        }
      />
    </Steps>
  );
};
