import React from "react";
import { Form, InputNumber} from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";

export const PatientAgeControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={<FormattedMessage id="common.age" defaultMessage="Wiek" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="patientAge"
      control={props.control}
      render={({ field: { value, ...rest } }) => (
        <InputNumber
          value={value === null ? undefined : value}
          {...rest}
          autoComplete={"nope"}
          className={"w-full"}
          min={5}
          max={120}
          step={10}
        />
      )}
    />
  </Form.Item>
);
