import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { NO_COLOR } from "features/orders/color/color.model";
import { isBleachColor, isVita3dMasterColor, isVitaClassicColor } from "features/orders/color/color.guards";
import { OrderSpecification } from "features/orders/specification/order-specification";
import { isEmptySpecification } from "features/orders/specification/order-specification.functions";
import { IMoney } from "core/money/money.model";
import { Money } from "core/money/money.functions";
import {
  CustomAlerts,
  FormMode,
  IsOrderNumberTakenCheckFn,
  OrderFormProps,
} from "features/orders/components/order-form/order-form.model";
import { argumentOutOfRangeError } from "core/errors/generate-error";

type LabEditSchemaProps = {
  mode: FormMode.LabEdit;
  orderNumber: number;
  isOrderNumberTakenCheck: IsOrderNumberTakenCheckFn;
};

type LabCreateSchemaProps = {
  mode: FormMode.LabCreate;
  isOrderNumberTakenCheck: IsOrderNumberTakenCheckFn;
};

type DentistCreateSchemaProps = {
  mode: FormMode.DentistCreate;
};

type DentistEditSchemaProps = {
  mode: FormMode.DentistEdit;
};

type OrderFormSchemaProps =
  | LabCreateSchemaProps
  | LabEditSchemaProps
  | DentistCreateSchemaProps
  | DentistEditSchemaProps;

export const mapOrderFormPropsToOrderFormSchemaProps = (props: OrderFormProps): OrderFormSchemaProps => {
  switch (props.mode) {
    case FormMode.LabCreate:
      return { mode: FormMode.LabCreate, isOrderNumberTakenCheck: props.isOrderNumberTakenCheck };
    case FormMode.LabEdit:
      return {
        mode: FormMode.LabEdit,
        isOrderNumberTakenCheck: props.isOrderNumberTakenCheck,
        orderNumber: props.defaultValues!.orderNumber,
      };
    case FormMode.DentistCreate:
      return {
        mode: FormMode.DentistCreate,
      };
    case FormMode.DentistEdit:
      return {
        mode: FormMode.DentistEdit,
      };
  }
};

export function useOrderFormValidationSchema(props: OrderFormSchemaProps) {
  const intl = useIntl();

  const schema = useMemo(() => {
    const labIdSchema = yup
      .string()
      .required(intl.formatMessage({ id: "order-form.lab-is-required", defaultMessage: "Laboratorium jest wymagane" }));

    const dentistIdSchema = yup
      .string()
      .required(intl.formatMessage({ id: "order-form.dentist-is-required", defaultMessage: "Dentysta jest wymagany" }));

    const startDateSchema = yup.date().nullable();

    const dueDateSchema = yup
      .date()
      .nullable()
      .when("startDate", (startDate, yup) => {
        if (startDate === null || startDate === undefined) {
          return yup;
        }

        return yup.min(
          startDate,
          intl.formatMessage({
            id: "order-form.due-date-cant-be-before-start-date",
            defaultMessage: "Ostateczny termin oddania pracy nie może być przed datą przyjścia pracy do laboratorium",
          })
        );
      });

    const orderColorSchema = yup
      .mixed()
      .test(
        "orderColorOrNone",
        intl.formatMessage({
          id: "order-form.order-color-is-required",
          defaultMessage: "Kolor do pracy jest wymagany",
        }),
        (value: any) =>
          value === NO_COLOR || isVitaClassicColor(value) || isVita3dMasterColor(value) || isBleachColor(value)
      )
      .required(
        intl.formatMessage({
          id: "order-form.order-color-is-required",
          defaultMessage: "Kolor do pracy jest wymagany",
        })
      );

    const orderEntrySchemas = yup
      .array()
      .min(
        1,
        intl.formatMessage({ id: "order-form.order-items-are-empty", defaultMessage: "Proszę określić zamówienie" })
      )
      .of(
        yup.object().shape({
          specification: yup
            .mixed()
            .test(
              "isNotEmpty",
              CustomAlerts.EmptySpecification,
              (value: OrderSpecification) => !isEmptySpecification(value)
            ),
          price: yup
            .object()
            .nullable()
            .test("isSomePriceSetForNonEmptySpecification", CustomAlerts.NoPricingAvailable, function (
              this: any,
              value: IMoney
            ) {
              if (isEmptySpecification(this.parent.specification)) {
                return true;
              }

              return value !== null && value !== undefined && !Money.isZero(value);
            } as any),
        })
      )
      .required(
        intl.formatMessage({ id: "order-form.order-items-are-empty", defaultMessage: "Proszę określić zamówienie" })
      );

    if (props.mode === FormMode.LabCreate || props.mode === FormMode.LabEdit) {
      const orderNumberSchema = yup.number().test(
        "isOrderNumberNotTaken",
        intl.formatMessage({
          id: "order-form.order-number-is-taken",
          defaultMessage: "Numer zamówienia jest zajęty",
        }),
        async function (orderNumber) {
          if (props.mode === FormMode.LabEdit) {
            const previousOrderNumber = props.orderNumber;

            if (
              !isNaN(previousOrderNumber) &&
              typeof orderNumber === "number" &&
              !isNaN(orderNumber) &&
              previousOrderNumber === orderNumber
            ) {
              return true;
            }
          }

          if (typeof orderNumber === "number" && !isNaN(orderNumber) && orderNumber > 0) {
            const result = await (props as LabEditSchemaProps).isOrderNumberTakenCheck(orderNumber);

            if (result.isErr()) {
              return true;
            }

            return result.unwrap().isAvailable;
          }

          return true;
        }
      );

      return yup
        .object({
          dentistId: dentistIdSchema,
          orderNumber: orderNumberSchema,
          dueDate: dueDateSchema,
          startDate: startDateSchema,
          orderColor: orderColorSchema,
          orderEntries: orderEntrySchemas,
        })
        .required();
    } else if (props.mode === FormMode.DentistCreate || props.mode === FormMode.DentistEdit) {
      return yup
        .object({
          labId: labIdSchema,
          dueDate: dueDateSchema,
          startDate: startDateSchema,
          orderColor: orderColorSchema,
          orderEntries: orderEntrySchemas,
        })
        .required();
    } else {
      throw argumentOutOfRangeError("", "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    intl,
    props.mode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (props as LabEditSchemaProps)?.isOrderNumberTakenCheck,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (props as LabEditSchemaProps)?.orderNumber,
  ]);

  return {
    orderFormSchema: schema,
  };
}
