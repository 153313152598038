import React, { useCallback, useEffect, useState } from "react";
import { DotId, PointId } from "core/point/point.enums";
import PointsView, { PointsViewAnchors, PointsViewDots, PointsViewPoints } from "../../points-view/PointsView";
import {
  createDotsVisibilityMap,
  mapPointsSelectionStateToSingleAndLinkedPoints,
  mapSingleAndLinkedPointsToPointsSelectionState,
} from "./point-selection.functions";
import { IPickedPoints } from "../../points-picker.model";
import { AnchorType } from "../../../../order-type/anchor-type.models";
import { JawViewProps } from "../../points-view/jaws/jaw-view.model";
import {
  defaultAnchorOptions,
  defaultDotOptions,
  defaultLabelOptions,
  defaultPointOptions,
} from "../../points-view/jaws/jaws.consts";

export interface IPointsSelectionProps
  extends Pick<JawViewProps, "pointOptions" | "dotOptions" | "labelOptions" | "anchorOptions"> {
  value: IPickedPoints;
  onChange?: (value: IPickedPoints) => void;
}

export type PointsSelectionState = {
  points: PointsViewPoints;
  dots: PointsViewDots;
  anchors: PointsViewAnchors;
};

const PointsSelection: React.FunctionComponent<IPointsSelectionProps> = ({
  value,
  pointOptions = defaultPointOptions,
  dotOptions = defaultDotOptions,
  anchorOptions = defaultAnchorOptions,
  labelOptions = defaultLabelOptions,
  onChange,
}) => {
  const [state, setState] = useState<PointsSelectionState>(mapSingleAndLinkedPointsToPointsSelectionState(value));

  const onPointSelected = useCallback(
    (point: PointId) => {
      let nextPoints;
      let nextAnchors = state.anchors;

      if (state.points[point] === undefined || !state.points[point]!.selected) {
        nextPoints = { ...state.points, [point]: { selected: true, linked: false } };
      } else {
        nextPoints = { ...state.points, [point]: { selected: false, linked: false } };
      }

      const dotVisibility = createDotsVisibilityMap(nextPoints);

      const nextDots = Object.entries(dotVisibility).reduce((acc, [dot, isVisible]) => {
        return {
          ...acc,
          [dot as DotId]: isVisible
            ? { selected: state.dots[dot as DotId]?.selected === true, visible: true }
            : { selected: false, visible: false },
        };
      }, state.dots);

      if (nextAnchors[point] !== undefined && nextAnchors[point] !== null) {
        const { [point]: _, ...otherAnchors } = nextAnchors;
        nextAnchors = otherAnchors;
      }

      setState({ ...state, points: nextPoints, dots: nextDots, anchors: nextAnchors });
    },
    [state]
  );

  const onDotSelected = useCallback(
    (dot: DotId) => {
      if (state.dots[dot] === undefined || !state.dots[dot]!.selected) {
        setState({
          ...state,
          dots: { ...state.dots, [dot]: { selected: true, visible: true } },
        });
      } else {
        setState({
          ...state,
          dots: { ...state.dots, [dot]: { selected: false, visible: true } },
        });
      }
    },
    [state]
  );

  const onPointAnchored = useCallback(
    (point: PointId, anchorType: AnchorType) => {
      if (state.points[point] === undefined || !state.points[point]!.selected) {
        return;
      }

      setState({ ...state, anchors: { ...state.anchors, [point]: anchorType } });
    },
    [state]
  );

  useEffect(() => {
    onChange?.(mapPointsSelectionStateToSingleAndLinkedPoints(state));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <PointsView
      points={state.points}
      dots={state.dots}
      anchors={state.anchors}
      pointOptions={pointOptions}
      dotOptions={dotOptions}
      anchorOptions={anchorOptions}
      labelOptions={labelOptions}
      onPointSelected={onPointSelected}
      onDotSelected={onDotSelected}
      onPointAnchored={onPointAnchored}
    />
  );
};
export default PointsSelection;
