// try to follow three letter convention
export enum Currency {
  PLN = "PLN",
  EUR = "EUR",
}

export interface IMoney {
  amount: number;
  currency: Currency;
}

export interface IEmptyMoney {
  amount: null;
  currency: Currency;
}


