import { OrderSpecification, SpecificationType } from "features/orders/specification/order-specification";
import { Money } from "core/money/money.functions";
import { IMoney } from "core/money/money.model";
import { Arch } from "core/tooth/tooth.model";
import { PointArea, pointToPointArea } from "core/point/point.enums";
import { CrownAnchorType, FoundationAnchorType, MarylandAnchorType } from "../../orders/order-type/anchor-type.models";
import { LocatedPoint } from "../../orders/specification/point-specification";
import { getAllArchesInPointArray } from "core/point/point.functions";
import { Pricing, PricingStrategy } from "core/pricing/pricing.model";

export const computePrice = (pricing: Pricing, specification: OrderSpecification): IMoney | null => {
  switch (pricing.strategy) {
    case PricingStrategy.NotSet:
    case PricingStrategy.Individual:
      return null;
    case PricingStrategy.Fixed: {
      return pricing.fixedPrice;
    }
    case PricingStrategy.PerQuantity: {
      const { pricePerItem } = pricing;

      switch (specification.specificationType) {
        case SpecificationType.Quantity:
          return Money.multiply(pricePerItem, specification.quantity);
        case SpecificationType.Arches:
          return Money.multiply(pricePerItem, specification.arches.length);
        case SpecificationType.LocatedPoints:
        case SpecificationType.Crowns:
          return Money.multiply(pricePerItem, specification.points.length);
        case SpecificationType.Bridges:
        case SpecificationType.MarylandBridges:
        case SpecificationType.Foundations:
          return Money.multiply(pricePerItem, specification.points.flatMap((x) => x).length);
        default:
          return null;
      }
    }
    case PricingStrategy.PerArch: {
      const {
        pricesPerArch: { [Arch.UpperArch]: upper, [Arch.LowerArch]: lower },
      } = pricing;

      if (specification.specificationType !== SpecificationType.Arches) {
        return null;
      }

      return specification.arches.reduce(
        (sum, arch) => Money.add(sum, arch === Arch.UpperArch ? upper : lower),
        Money.Zero()
      );
    }
    case PricingStrategy.PerPoint: {
      const {
        pricesPerPoint: { [PointArea.Front]: front, [PointArea.Back]: back },
      } = pricing;

      switch (specification.specificationType) {
        case SpecificationType.LocatedPoints:
        case SpecificationType.PartialDentures:
        case SpecificationType.SurgicalGuides: {
          return specification.points.reduce((sum, curr) => {
            const pointArea = pointToPointArea[curr.location];
            return Money.add(sum, pointArea === PointArea.Front ? front : back);
          }, Money.Zero());
        }
        case SpecificationType.Crowns:
          return specification.points.reduce((sum, curr) => {
            const pointArea = pointToPointArea[curr.location];
            return Money.add(sum, pointArea === PointArea.Front ? front : back);
          }, Money.Zero());
        case SpecificationType.Bridges:
        case SpecificationType.MarylandBridges:
        case SpecificationType.Foundations:
          return specification.points
            .flatMap((x) => x)
            .reduce((sum, curr) => {
              const pointArea = pointToPointArea[curr.location];
              return Money.add(sum, pointArea === PointArea.Front ? front : back);
            }, Money.Zero());
        default:
          return null;
      }
    }
    case PricingStrategy.PerPointWithArchBasePrice: {
      const { pricePerPoint, basePriceForArch } = pricing;

      let locatedPoints: LocatedPoint[];

      switch (specification.specificationType) {
        case SpecificationType.Arches:
        case SpecificationType.Quantity:
          return null;
        case SpecificationType.LocatedPoints:
        case SpecificationType.PartialDentures:
        case SpecificationType.SurgicalGuides:
        case SpecificationType.Crowns: {
          locatedPoints = specification.points;
          break;
        }
        case SpecificationType.Bridges:
        case SpecificationType.MarylandBridges:
        case SpecificationType.Foundations: {
          locatedPoints = specification.points.flatMap((x) => x);
          break;
        }
        default:
          return null;
      }

      const arches = getAllArchesInPointArray(locatedPoints.map((p) => p.location));
      const priceForPoints = locatedPoints.reduce((sum) => Money.add(sum, pricePerPoint), Money.Zero());

      if (arches[Arch.UpperArch] && arches[Arch.LowerArch]) {
        return Money.add(priceForPoints, Money.multiply(basePriceForArch, 2));
      } else if (arches[Arch.UpperArch] || arches[Arch.LowerArch]) {
        return Money.add(priceForPoints, basePriceForArch);
      } else {
        return Money.PLN(0);
      }
    }
    case PricingStrategy.PerCrown: {
      const {
        pricesPerCrown: { [PointArea.Front]: frontPrices, [PointArea.Back]: backPrices },
      } = pricing;

      if (specification.specificationType !== SpecificationType.Crowns) {
        return null;
      }

      return specification.points.reduce((sum, curr) => {
        const pointArea = pointToPointArea[curr.location];
        const anchorType = curr.anchor as CrownAnchorType;
        return Money.add(sum, pointArea === PointArea.Front ? frontPrices[anchorType] : backPrices[anchorType]);
      }, Money.Zero());
    }
    case PricingStrategy.PerBridgePoint: {
      const {
        pricesPerBridgePoint: { [PointArea.Front]: frontPrices, [PointArea.Back]: backPrices },
      } = pricing;

      if (specification.specificationType !== SpecificationType.Bridges) {
        return null;
      }

      return specification.points
        .flatMap((x) => x)
        .reduce((sum, curr) => {
          const pointArea = pointToPointArea[curr.location];
          const anchorType = curr.anchor;
          return Money.add(sum, pointArea === PointArea.Front ? frontPrices[anchorType] : backPrices[anchorType]);
        }, Money.Zero());
    }
    case PricingStrategy.PerMarylandBridgePoint: {
      const {
        pricesPerMarylandBridgePoint: { [PointArea.Front]: frontPrices, [PointArea.Back]: backPrices },
      } = pricing;

      if (specification.specificationType !== SpecificationType.MarylandBridges) {
        return null;
      }

      return specification.points
        .flatMap((x) => x)
        .reduce((sum, curr) => {
          const pointArea = pointToPointArea[curr.location];
          const anchorType = curr.anchor as MarylandAnchorType;
          return Money.add(sum, pointArea === PointArea.Front ? frontPrices[anchorType] : backPrices[anchorType]);
        }, Money.Zero());
    }
    case PricingStrategy.PerFoundationPoint: {
      const { pricesPerFoundationPoint } = pricing;

      if (specification.specificationType !== SpecificationType.Foundations) {
        return null;
      }

      return specification.points
        .flatMap((x) => x)
        .reduce((sum, curr) => {
          const anchorType = curr.anchor as FoundationAnchorType;
          return Money.add(sum, pricesPerFoundationPoint[anchorType]);
        }, Money.Zero());
    }
  }
};
