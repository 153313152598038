import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { PointBasedSpecification, SpecificationType } from "../../../specification/order-specification";
import { isEmptySpecification } from "../../../specification/order-specification.functions";

export const OpenModalButton = (props: { specification: PointBasedSpecification; onClick: () => void }) => {
  const isEmpty = isEmptySpecification(props.specification);
  let whenEmptySpecificationMsg;
  let changeSpecificationMsg;

  switch (props.specification.specificationType) {
    case SpecificationType.LocatedPoints:
    case SpecificationType.PartialDentures:
    case SpecificationType.SurgicalGuides:{
      whenEmptySpecificationMsg = (
        <FormattedMessage id={"open-modal-button.pick-points"} defaultMessage={"Wybierz punkty"} />
      );
      changeSpecificationMsg = (
        <FormattedMessage id={"open-modal-button.change-points"} defaultMessage={"Zmień punkty"} />
      );
      break;
    }
    case SpecificationType.Crowns: {
      whenEmptySpecificationMsg = (
        <FormattedMessage id={"open-modal-button.pick-crowns"} defaultMessage={"Wybierz korony"} />
      );
      changeSpecificationMsg = (
        <FormattedMessage id={"open-modal-button.change-crowns"} defaultMessage={"Zmień korony"} />
      );
      break;
    }
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges: {
      whenEmptySpecificationMsg = (
        <FormattedMessage id={"open-modal-button.pick-bridges"} defaultMessage={"Wybierz mosty"} />
      );
      changeSpecificationMsg = (
        <FormattedMessage id={"open-modal-button.change-bridges"} defaultMessage={"Zmień mosty"} />
      );
      break;
    }
    case SpecificationType.Foundations: {
      whenEmptySpecificationMsg = (
        <FormattedMessage id={"open-modal-button.pick-foundations"} defaultMessage={"Wybierz podbudowy"} />
      );
      changeSpecificationMsg = (
        <FormattedMessage id={"open-modal-button.change-foundations"} defaultMessage={"Zmień podbudowy"} />
      );
      break;
    }
  }

  return (
    <Button type={isEmpty ? "primary" : "default"} onClick={props.onClick}>
      {isEmpty ? whenEmptySpecificationMsg : changeSpecificationMsg}
    </Button>
  );
};
