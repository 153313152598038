import {
  KnownClaimTypes,
  KnownClaimTypes as KnownClaimType,
  UserRights,
} from "features/auth/contracts/auth-contracts.dto";
import { isNotEmptyString } from "core/utils/utils";
import { invalidArgumentError } from "core/errors/generate-error";
import { prettyJSON } from "core/utils/debugg";
import { AccountType, IClaim, ITenantId, IUserId, IUserProfile, UserRole } from "features/auth/auth.model";
import { IEmployeeId } from "features/employee/employee.model";

export const getClaimValueOrFallback = (claims: IClaim[], key: KnownClaimType, fallback: string): string => {
  const claim = claims.find((c) => c.type === key);

  if (claim === undefined) {
    return fallback;
  }

  if (Array.isArray(claim.value)) {
    if (claim.value.length != 1) {
      throw new Error("expected claim to be a value single string, but instead got an array");
    } else {
      return claim.value[0];
    }
  } else if (isNotEmptyString(claim.value)) {
    return claim.value;
  } else {
    throw new Error("expected claim to be a value single string, but got " + typeof claim.value);
  }
};

export const extractUserProfile = (claims: IClaim[] = []): IUserProfile | null => {
  const email = getClaimValueOrFallback(claims, KnownClaimType.Email, "");
  const emailVerified = getClaimValueOrFallback(claims, KnownClaimType.EmailVerified, "false");
  const firstName = getClaimValueOrFallback(claims, KnownClaimType.GivenName, "");
  const lastName = getClaimValueOrFallback(claims, KnownClaimType.FamilyName, "");

  if (email === "") {
    return null;
  }

  return {
    email: email,
    emailVerified: emailVerified === "true",
    firstName: firstName,
    lastName: lastName,
  };
};

export const extractUserId = (claims: IClaim[] = []): IUserId | null => {
  const userId = getClaimValueOrFallback(claims, KnownClaimType.UserId, "");

  if (userId === "") {
    return null;
  }

  return { type: "user-id", value: userId };
};

export const extractTenantId = (claims: IClaim[] = []): ITenantId | null => {
  const tenantId = getClaimValueOrFallback(claims, KnownClaimType.TenantId, "");

  if (tenantId === "") {
    return null;
  }

  return { type: "tenant-id", value: tenantId };
};

export const extractEmployeeId = (claims: IClaim[] = []): IEmployeeId | null => {
  const employeeId = getClaimValueOrFallback(claims, KnownClaimType.EmployeeId, "");

  if (employeeId === "") {
    return null;
  }

  return { type: "employee-id", value: employeeId };
};

export const extractAccountType = (claims: IClaim[] = []): AccountType | null => {
  const acKey = getClaimValueOrFallback(claims, KnownClaimType.AccountType, "") as keyof typeof AccountType;
  return AccountType[acKey] ?? null;
};

export const extractUserRoles = (claims: IClaim[] = []): UserRole[] | null => {
  const claim = claims.find((c) => c.type === KnownClaimTypes.Roles);
  if (claim === undefined) {
    return null;
  } else if (typeof claim.value === "string" && isNotEmptyString(claim.value)) {
    return [claim.value] as UserRole[];
  } else if (Array.isArray(claim.value)) {
    return [...claim.value] as UserRole[];
  } else {
    throw invalidArgumentError("role claim is invalid" + prettyJSON(claim));
  }
};

export const extractUserRights = (claims: IClaim[] = []): UserRights[] | null => {
  const claim = claims.find((c) => c.type === KnownClaimTypes.Rights);
  if (claim === undefined) {
    return null;
  } else if (typeof claim.value === "string" && isNotEmptyString(claim.value)) {
    return [claim.value] as UserRights[];
  } else if (Array.isArray(claim.value)) {
    return [...claim.value] as UserRights[];
  } else {
    throw invalidArgumentError("rights claim is invalid" + prettyJSON(claim));
  }
};
