import {
  Color,
  IVitaClassicalColor,
  ShadeGuide,
  VitaClassicalShade,
  IVita3dMasterColor,
  Vita3dMasterShade,
  BleachShade, IBleachColor,
} from "features/orders/color/color.model";

export const isVitaClassicColor = (color: Color): color is IVitaClassicalColor =>
  typeof color === "object" && color !== null && color.guide === ShadeGuide.VitaClassical;

export const isVita3dMasterColor = (color: Color): color is IVita3dMasterColor =>
  typeof color === "object" && color !== null && color.guide === ShadeGuide.Vita3dMaster;

export const isBleachColor = (color: Color): color is IBleachColor =>
  typeof color === "object" && color !== null && color.guide === ShadeGuide.Bleach;

export const isVitaClassicShade = (
  shade: VitaClassicalShade | Vita3dMasterShade | BleachShade | null
): shade is VitaClassicalShade =>
  shade !== null && typeof shade === "string" && shade.startsWith("VC");

export const isVita3dMasterShade = (
  shade: VitaClassicalShade | Vita3dMasterShade | BleachShade | null
): shade is Vita3dMasterShade =>
  shade !== null && typeof shade === "string" && shade.startsWith("V3D");

export const isBleachShade = (
  shade: VitaClassicalShade | Vita3dMasterShade | BleachShade | null
): shade is BleachShade =>
  shade !== null && typeof shade === "string" && shade.startsWith("BL");
