import { ISODateTimeString } from "core/time/time.model";
import { DomainClaim } from "../../auth/contracts/auth-contracts.dto";

export interface ISubscription {
  id: string
  tenantId: string
  typeCode: SubscriptionTypeCode
  stripeCustomerId: string
  activeUntil: ISODateTimeString
  checkoutCompleted: boolean
}

export enum PriceInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum SubscriptionTypeCode {
  Free = 100,
  Brown = 200,
  Silver = 300,
  Gold = 400,
}

export interface ISubscriptionProduct {
  name: string
  price: number
  priceId: string
  description: string
  currency: string // three-letter Currency lowercased
  interval: PriceInterval
  productId: string
  subscriptionTypeCode: SubscriptionTypeCode
  subscriptionClaims: DomainClaim[]
}