import { IApiResponseEnvelope } from "contracts/envelope/api-response-envelope";
import { IResult } from "core/lib/types/result";
import { IPaginatedList } from "core/pagination/paginated-list";
import {
  CheckOrderNumberAvailabilityRequest,
  IDentistOrderReadModel,
  ILabOrderReadModel,
  IOrderId,
  IOrderItem,
  NewOrderId,
  OrderCommentsListType,
  OrderNumberAvailability,
} from "features/orders/order.model";
import { IHttpService } from "core/http/http.service";
import {
  ChangeOrderFinishDateResponseDTO,
  ChangeOrderItemPriceResponseDTO,
  ChangeOrderStatusResponseDTO as IChangeOrderStatusResponseDTO,
  CheckOrderNumberAvailabilityResponseDTO,
  CreateOrderCommentResponseDTO,
  CreateOrderResponseDTO,
  DeleteOrderResponseDTO,
  EditOrderResponseDTO,
  GetDentistOrdersResponseDTO,
  GetLabOrdersResponseDTO,
  GetMultipleOrdersResponseDTO,
  GetNextOrderNumberResponseDTO,
  GetOrderCommentsResponseDTO,
  GetSingleDentistOrderResponseDTO,
  GetSingleLabOrderResponseDTO,
} from "features/orders/contracts/orders.response.dto";
import { appendQueryString, getDomainError, isErrorResponse } from "core/http/http.functions";
import { Err } from "core/lib/types/error";
import { Ok } from "core/lib/types/ok";
import { honorificCodeToHonorific } from "core/person-name/honorific.functions";
import { mapStatusCodeToStatus, mapStatusToStatusCode } from "features/orders/status/order-status.mappers";
import { mapSpecificationDtoToSpecification } from "features/orders/contracts/orders.dto.mappers";
import {
  IChangeFinishDateRequest,
  IChangeOrderItemPriceRequest,
  IChangeOrderStatusRequest,
  ICreateDentistOrderRequest,
  ICreateLabOrderRequest,
  IEditDentistOrderRequest,
  IEditLabOrderRequest,
} from "features/orders/requests/orders.requests";
import {
  IChangeOrderFinishDateRequestDTO,
  IChangeOrderItemPriceRequestDTO,
  IChangeOrderStatusRequestDTO,
  ICreateDentistOrderRequestDTO,
  ICreateLabOrderRequestDTO,
  IEditDentistOrderRequestDTO,
  IEditLabOrderRequestDTO,
} from "features/orders/contracts/orders.request.dto";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import { IDentalLabService } from "features/dental-lab/service/IDentalLabService";
import { IAuthService } from "features/auth/auth.service";
import { mapCountryCodeToCountry, mapCountryToCountryCode } from "core/countries/countries.functions";
import { mapGenderCodeToGender, mapGenderToGenderCode } from "core/gender/gender.functions";
import { ILabId } from "../../dental-lab/dental-lab.model";
import { IDentistOrderReadModelDTO, ILabOrderReadModelDTO } from "../contracts/orders.dto.models";
import { NO_COLOR } from "../color/color.model";
import { mapColorCodeToColor, mapShadeToShadeCode } from "../color/dto/color.mappers";
import DomainError, { IDomainError } from "core/errors/domain-error";
import { IJsonApiService } from "core/http/json-api.service";
import {
  GetAllOrderHistoryEntriesPayloadDTO,
  GetAllOrderHistoryEntriesResponseDTO,
} from "features/orders/contracts/order-history.dto";
import { Gender } from "core/gender/gender.model";
import { IOrderApi } from "features/orders/service/orders.service";
import {
  IOrderFeedbackSurvey,
  IOrderFeedbackModelDTO,
  IDentistOrderSurveyFeedback,
  ILabOrderSurveyFeedback,
} from "features/orders/feedback/feedback.model";
import {
  DentistOrderServerColumn,
  IDentistOrdersProviderParams,
} from "features/orders/pages/list/components/dentist-orders-table/dentist-orders-table.models";
import { ServerSortDirection } from "features/orders/pages/list/components/orders-table.models";
import {
  AssignmentFilter,
  ILabOrdersProviderParams,
  LabOrderServerColumn,
} from "features/orders/pages/list/components/lab-orders-table/lab-orders-table.models";
import { NewBillingStatementId } from "features/invoicing/models/invoicing.models";

class OrdersHttpService implements IOrderApi {
  private readonly httpService: IHttpService;
  private readonly dentalLabService: IDentalLabService;
  private readonly authService: IAuthService;
  private readonly jsonApi: IJsonApiService;

  constructor(
    jsonApi: IJsonApiService,
    httpService: IHttpService,
    authService: IAuthService,
    dentalLabService: IDentalLabService
  ) {
    this.jsonApi = jsonApi;
    this.httpService = httpService;
    this.authService = authService;
    this.dentalLabService = dentalLabService;
  }

  changeOrderItemPrice = async (request: IChangeOrderItemPriceRequest): Promise<IResult<IOrderId>> => {
    const requestBody: IChangeOrderItemPriceRequestDTO = {
      orderId: request.orderId.value,
      orderItemId: request.orderItem.value,
      price: request.newPrice,
      reasonForChange: request.reasonForChange,
    };

    const response = await this.jsonApi.post<ChangeOrderItemPriceResponseDTO, IChangeOrderItemPriceRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.ChangeOrderItemPrice,
          params: { labId: tenantId!.value },
        }),
      requestBody
    );

    return response.map((x) => ({ type: "order-id", value: x.data.result }));
  };

  deleteLabOrder = async (orderId: IOrderId) => {
    const response = await this.jsonApi.post<DeleteOrderResponseDTO, void>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.Orders_DeleteLabOrder,
        params: { labId: labId!.value, orderId: orderId.value },
      })
    );

    return response.map((body) => NewOrderId(body.data.result));
  };

  deleteDentistOrder = async (orderId: IOrderId) => {
    const response = await this.jsonApi.post<DeleteOrderResponseDTO, void>(({ userId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.Orders_DeleteDentistOrder,
        params: { dentistId: userId!.value, orderId: orderId.value },
      })
    );

    return response.map((body) => NewOrderId(body.data.result));
  };

  changeOrderStatus = async (request: IChangeOrderStatusRequest): Promise<IResult<IOrderId>> => {
    const response = await this.jsonApi.post<IChangeOrderStatusResponseDTO, IChangeOrderStatusRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({ path: ApiEndpointPath.ChangeOrderStatus, params: { labId: tenantId!.value } }),
      { orderId: request.orderId.value, status: mapStatusToStatusCode(request.status) }
    );

    return response.map((body) => ({ type: "order-id", value: body.data.result }));
  };

  changeFinishDate = async (request: IChangeFinishDateRequest): Promise<IResult<IOrderId>> => {
    const requestBody: IChangeOrderFinishDateRequestDTO = {
      orderId: request.orderId.value,
      finishDate: request.finishDate,
    };

    const response = await this.jsonApi.post<ChangeOrderFinishDateResponseDTO, IChangeOrderFinishDateRequestDTO>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.ChangeOrderFinishDate,
          params: { labId: labId!.value },
        }),
      requestBody
    );

    return response.map((body) => NewOrderId(body.data.result));
  };

  editLabOrder = async (request: IEditLabOrderRequest): Promise<IResult<IOrderId>> => {
    const requestBody: IEditLabOrderRequestDTO = {
      dentistId: request.dentistId.value,
      patient: {
        firstName: request.patient.firstName,
        lastName: request.patient.lastName,
        patientCode: request.patient.patientCode,
        gender: mapGenderToGenderCode(request.patient.gender),
        age: request.patient.age,
        country: request.patient.country === null ? null : mapCountryToCountryCode(request.patient.country),
      },
      orderNumber: request.orderNumber,
      orderNote: request.orderNote,
      startDate: request.startDate,
      dueDate: request.dueDate,
      orderItems: request.orderItems,
      color: request.orderColor === NO_COLOR ? null : mapShadeToShadeCode(request.orderColor.shade),
    };

    const response = await this.jsonApi.post<EditOrderResponseDTO, IEditLabOrderRequestDTO>(
      ({ tenantId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.Orders_EditLabOrder,
          params: { labId: tenantId!.value, orderId: request.orderId.value },
        }),
      requestBody
    );

    return response.map((x) => ({
      value: x.data.result,
      type: "order-id",
    }));
  };

  editDentistOrder = async (request: IEditDentistOrderRequest): Promise<IResult<IOrderId>> => {
    const requestBody: IEditDentistOrderRequestDTO = {
      patient: {
        firstName: request.patient.firstName,
        lastName: request.patient.lastName,
        patientCode: request.patient.patientCode,
        gender: mapGenderToGenderCode(request.patient.gender),
        age: request.patient.age,
        country: request.patient.country === null ? null : mapCountryToCountryCode(request.patient.country),
      },
      orderNote: request.orderNote,
      startDate: request.startDate,
      dueDate: request.dueDate,
      orderItems: request.orderItems,
      color: request.orderColor === NO_COLOR ? null : mapShadeToShadeCode(request.orderColor.shade),
    };

    const response = await this.jsonApi.post<EditOrderResponseDTO, IEditDentistOrderRequestDTO>(
      ({ userId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.Orders_EditDentistOrder,
          params: { dentistId: userId!.value, orderId: request.orderId.value },
        }),
      requestBody
    );

    return response.map((x) => ({
      value: x.data.result,
      type: "order-id",
    }));
  };

  getLabOrders = async (params: ILabOrdersProviderParams): Promise<IResult<IPaginatedList<ILabOrderReadModel>>> => {
    const {
      pagination,
      assignmentFilter = null,
      sortBy = null,
      orderNumber = null,
      dentistIds = null,
      patient = null,
      dueDateRange = null,
      finishDateRange = null,
      status = null,
      assignedEmployee = null,
    } = params;

    let queryObject: {
      pageSize: number;
      pageNumber: number;
      sortBy?: LabOrderServerColumn;
      sortDirection?: ServerSortDirection;
      assignmentFilter?: AssignmentFilter;
      orderNumber?: number;
      dentistIds?: string[];
      employeeId?: string;
      patient?: string;
      dueDateStart?: string;
      dueDateEnd?: string;
      finishDateStart?: string;
      finishDateEnd?: string;
      status?: string[];
    } = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
    };

    if (sortBy !== null && sortBy !== undefined) {
      queryObject.sortBy = sortBy.column;
      queryObject.sortDirection = sortBy.direction;
    }

    if (orderNumber !== null && orderNumber !== undefined) {
      queryObject.orderNumber = orderNumber;
    }

    if (dentistIds !== null && dentistIds !== undefined) {
      queryObject.dentistIds = dentistIds.map((d) => d.value);
    }

    if (patient !== null && patient !== undefined) {
      queryObject.patient = patient;
    }

    if (dueDateRange !== null && dueDateRange !== undefined) {
      queryObject.dueDateStart = dueDateRange.start;
      queryObject.dueDateEnd = dueDateRange.end;
    }

    if (finishDateRange !== null && finishDateRange !== undefined) {
      queryObject.finishDateStart = finishDateRange.start;
      queryObject.finishDateEnd = finishDateRange.end;
    }

    if (status !== null && status !== undefined) {
      queryObject.status = status.map((x) => x + "");
    }

    let labId: ILabId;

    const response = await this.jsonApi.get<GetLabOrdersResponseDTO>(({ labId: id, employeeId }) => {
      labId = id!;

      if (assignmentFilter !== null && assignmentFilter !== undefined) {
        if (assignmentFilter === AssignmentFilter.All) {
          queryObject.assignmentFilter = AssignmentFilter.All;

          if (assignedEmployee !== null && assignedEmployee !== undefined) {
            queryObject.employeeId = assignedEmployee.value;
          }
        } else if (assignmentFilter === AssignmentFilter.Unassigned) {
          queryObject.assignmentFilter = assignmentFilter;
        } else if (assignmentFilter === AssignmentFilter.Employee && employeeId !== null) {
          queryObject.assignmentFilter = assignmentFilter;
          queryObject.employeeId = employeeId!.value;
        }
      }

      const url = appendQueryString(
        parametrizeEndpointPath({
          path: ApiEndpointPath.GetLabsOrders,
          params: { labId: labId.value },
        }),
        queryObject
      );

      return url;
    });

    return response.map((body) => {
      const paginatedResponse: IPaginatedList<ILabOrderReadModel> = {
        data: body.data.result.value.map((r) => mapSingleLabOrderPayload(r)),
        pagination: body.data.result.pagination,
      };

      return paginatedResponse;
    });
  };

  getDentistOrders = async (
    params: IDentistOrdersProviderParams
  ): Promise<IResult<IPaginatedList<IDentistOrderReadModel>>> => {
    const { pagination, sortBy = null, labId = null, patient = null, dueDateRange = null } = params;

    let queryObject: {
      pageSize: number;
      pageNumber: number;
      sortBy?: DentistOrderServerColumn;
      sortDirection?: ServerSortDirection;
      labId?: string;
      patient?: string;
      dueDateStart?: string;
      dueDateEnd?: string;
    } = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
    };

    if (sortBy !== null) {
      queryObject.sortBy = sortBy.column;
      queryObject.sortDirection = sortBy.direction;
    }

    if (labId !== null) {
      queryObject.labId = labId.value;
    }

    if (patient !== null) {
      queryObject.patient = patient;
    }

    if (dueDateRange !== null) {
      queryObject.dueDateStart = dueDateRange.start;
      queryObject.dueDateEnd = dueDateRange.end;
    }

    const response = await this.jsonApi.get<GetDentistOrdersResponseDTO>(({ userId: dentistId }) =>
      appendQueryString(
        parametrizeEndpointPath({
          path: ApiEndpointPath.GetDentistOrders,
          params: { dentistId: dentistId.value },
        }),
        queryObject
      )
    );

    return response.map((body) => {
      const paginatedResponse: IPaginatedList<IDentistOrderReadModel> = {
        data: body.data.result.value.map((r) =>
          mapSingleDentistOrderPayload({ type: "dental-lab-id", value: r.labId }, r)
        ),
        pagination: body.data.result.pagination,
      };

      return paginatedResponse;
    });
  };

  getLabOrderById = async (orderId: IOrderId) => {
    const response = await this.jsonApi.get<GetSingleLabOrderResponseDTO>(({ labId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetLabOrder,
        params: { orderId: orderId.value, labId: labId!.value },
      })
    );

    return response.map((body) => mapSingleLabOrderPayload(body.data.result));
  };

  getOrderFeedback = async (orderId: IOrderId, labId: ILabId) => {
    const url = parametrizeEndpointPath({
      path: ApiEndpointPath.GetOrderFeedback,
      params: { orderId: orderId.value, labId: labId.value },
    });

    const response = await this.jsonApi.get<IApiResponseEnvelope<IOrderFeedbackModelDTO>>(url);

    return response.map((body) => body.data.result);
  };

  giveOrderFeedback = async (
    orderId: IOrderId,
    labId: ILabId,
    feedback: IDentistOrderSurveyFeedback | ILabOrderSurveyFeedback
  ) => {
    const url = parametrizeEndpointPath({
      path: ApiEndpointPath.GiveOrderFeedback,
      params: { orderId: orderId.value, labId: labId.value },
    });

    const response = await this.jsonApi.post<
      IOrderFeedbackSurvey,
      IDentistOrderSurveyFeedback | ILabOrderSurveyFeedback
    >(url, feedback);

    return response.map((_) => orderId);
  };

  getDentistOrderById = async (orderId: IOrderId) => {
    const response = await this.jsonApi.get<GetSingleDentistOrderResponseDTO>(({ userId: userId }) => {
      return parametrizeEndpointPath({
        path: ApiEndpointPath.GetDentistOrder,
        params: { orderId: orderId.value, dentistId: userId.value },
      });
    });

    return response.map((body) =>
      mapSingleDentistOrderPayload({ type: "dental-lab-id", value: body.data.result.labId }, body.data.result)
    );
  };

  getOrdersByIds = async (orderIds: IOrderId[]) => {
    const response = await this.jsonApi.get<GetMultipleOrdersResponseDTO>(({ labId }) =>
      appendQueryString(
        parametrizeEndpointPath({
          path: ApiEndpointPath.GetMultipleOrders,
          params: { labId: labId!.value },
        }),
        {
          orderIds: orderIds.map((o) => o.value),
        }
      )
    );

    return response.map((rootDTO) => rootDTO.data.result.map((dto) => mapSingleLabOrderPayload(dto)));
  };

  getOrderComments = async (request: { orderId: string }) => {
    const { tenantId } = await this.authService.getTenantId();
    const activeLabResult = await this.dentalLabService.getActiveDentalLab();

    if (activeLabResult.isErr()) {
      return new Err<never, IDomainError>(activeLabResult.err().unwrap());
    }

    const activeLab = activeLabResult.unwrap();

    const comments = await this.httpService.get<GetOrderCommentsResponseDTO>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetOrderComments,
        params: { tenantId: tenantId!.value, orderId: request.orderId, labId: activeLab.id.value },
      })
    );

    const responseBody = comments.data;

    if (isErrorResponse(responseBody)) {
      const domainError = getDomainError(responseBody);
      return new Err<never, DomainError>(domainError);
    }

    return new Ok<OrderCommentsListType, IDomainError>(responseBody.result);
  };

  createOrderComment = async (request: { orderId: string; data: FormData }) => {
    const { tenantId } = await this.authService.getTenantId();
    const activeLabResult = await this.dentalLabService.getActiveDentalLab();

    if (activeLabResult.isErr()) {
      return new Err<never, IDomainError>(activeLabResult.err().unwrap());
    }

    const activeLab = activeLabResult.unwrap();

    // TODO
    const response = await this.httpService.put<CreateOrderCommentResponseDTO, any>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.CreateOrderComment,
        params: {
          tenantId: tenantId!.value,
          orderId: request.orderId,
          labId: activeLab.id.value,
        },
      }),
      request.data
    );

    const responseBody = response.data;

    if (isErrorResponse(responseBody)) {
      const domainError = getDomainError(responseBody);
      return new Err<any, DomainError>(domainError);
    }

    return new Ok<string, DomainError>(responseBody.result);
  };

  createLabOrder = async (request: ICreateLabOrderRequest): Promise<IResult<IOrderId>> => {
    const body: ICreateLabOrderRequestDTO = {
      dentistId: request.dentistId.value,
      patient: {
        firstName: request.patient.firstName,
        lastName: request.patient.lastName,
        patientCode: request.patient.patientCode,
        gender: mapGenderToGenderCode(request.patient.gender),
        age: request.patient.age,
        country: request.patient.country === null ? null : mapCountryToCountryCode(request.patient.country),
      },
      orderNumber: request.orderNumber,
      orderNote: request.orderNote,
      startDate: request.startDate,
      dueDate: request.dueDate,
      orderItems: request.orderItems,
      color: request.orderColor === NO_COLOR ? null : mapShadeToShadeCode(request.orderColor.shade),
    };

    const result = await this.jsonApi.post<CreateOrderResponseDTO, ICreateLabOrderRequestDTO>(
      ({ labId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.Orders__CreateLabOrder,
          params: { labId: labId!.value },
        }),
      body
    );

    return result.map((response) => ({ type: "order-id", value: response.data.result }));
  };

  createDentistOrder = async (request: ICreateDentistOrderRequest): Promise<IResult<IOrderId>> => {
    const body: ICreateDentistOrderRequestDTO = {
      labId: request.labId.value,
      patient: {
        firstName: request.patient.firstName,
        lastName: request.patient.lastName,
        patientCode: request.patient.patientCode,
        gender: mapGenderToGenderCode(request.patient.gender),
        age: request.patient.age,
        country: request.patient.country === null ? null : mapCountryToCountryCode(request.patient.country),
      },
      orderNote: request.orderNote,
      startDate: request.startDate,
      dueDate: request.dueDate,
      orderItems: request.orderItems,
      color: request.orderColor === NO_COLOR ? null : mapShadeToShadeCode(request.orderColor.shade),
    };

    const result = await this.jsonApi.post<CreateOrderResponseDTO, ICreateDentistOrderRequestDTO>(
      ({ userId }) =>
        parametrizeEndpointPath({
          path: ApiEndpointPath.Orders_CreateDentistOrder,
          params: { dentistId: userId!.value },
        }),
      body
    );

    return result.map((response) => ({ type: "order-id", value: response.data.result }));
  };

  getOrderHistory = async (orderId: IOrderId): Promise<IResult<GetAllOrderHistoryEntriesPayloadDTO>> => {
    const response = await this.jsonApi.get<GetAllOrderHistoryEntriesResponseDTO>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetOrderHistory,
        params: { orderId: orderId.value },
      })
    );

    return response.map((body) => body.data.result);
  };

  isOrderNumberTaken = async (
    checkParams: CheckOrderNumberAvailabilityRequest
  ): Promise<IResult<OrderNumberAvailability>> => {
    const response = await this.jsonApi.get<CheckOrderNumberAvailabilityResponseDTO>(({ tenantId }) =>
      parametrizeEndpointPath({
        path: ApiEndpointPath.CheckOrderNumber,
        params: { labId: tenantId!.value, orderNumber: "" + checkParams.orderNumber },
      })
    );

    return response.map((body) => {
      const result: OrderNumberAvailability = {
        isAvailable: body.data.result.isAvailable,
        isTakenBy:
          body.data.result.takenByOrderId === null
            ? null
            : { orderId: { type: "order-id", value: body.data.result.takenByOrderId } },
      };

      return result;
    });
  };

  getNextDefaultOrderNumber = async (): Promise<IResult<number | null>> => {
    const activeLabResult = await this.dentalLabService.getActiveDentalLab();

    if (activeLabResult.isErr()) {
      return new Err<never, IDomainError>(activeLabResult.err().unwrap());
    }

    const activeLab = activeLabResult.unwrap();

    const response = await this.httpService.get<GetNextOrderNumberResponseDTO>(
      parametrizeEndpointPath({
        path: ApiEndpointPath.GetNextOrderNumber,
        params: { labId: activeLab.id.value },
      })
    );

    const responseBody = response.data;

    if (isErrorResponse(responseBody)) {
      let domainError = getDomainError(responseBody);
      return new Err<number | null, IDomainError>(domainError);
    }

    return new Ok<number | null, IDomainError>(responseBody.result);
  };
}

export default OrdersHttpService;

const mapSingleLabOrderPayload = (dto: ILabOrderReadModelDTO): ILabOrderReadModel => {
  try {
    return {
      orderId: { type: "order-id", value: dto.orderId },
      labId: { type: "dental-lab-id", value: dto.labId },
      dentist: {
        id: { type: "dentist-id", value: dto.dentist.id },
        name: {
          honorific: honorificCodeToHonorific(dto.dentist.name.honorific),
          firstName: dto.dentist.name.firstName,
          lastName: dto.dentist.name.lastName,
        },
        clinicName: dto.dentist.clinicName,
      },
      patient:
        dto.patient === null
          ? {
              firstName: null,
              lastName: null,
              patientCode: null,
              country: null,
              age: null,
              gender: Gender.Unknown,
            }
          : {
              firstName: dto.patient.firstName,
              lastName: dto.patient.lastName,
              patientCode: dto.patient.patientCode,
              country: dto.patient.country === null ? null : mapCountryCodeToCountry(dto.patient.country),
              age: dto.patient.age === null ? null : dto.patient.age,
              gender: mapGenderCodeToGender(dto.patient.gender),
            },
      orderNumber: dto.orderNumber,
      startDate: dto.startDate,
      dueDate: dto.dueDate,
      finishDate: dto.finishDate,
      status: mapStatusCodeToStatus(dto.status),
      orderNote: dto.orderNote,
      color: dto.color === null ? NO_COLOR : mapColorCodeToColor(dto.color),
      billingStatement:
        dto.billingStatementId === null || dto.billingStatementNumber === null
          ? null
          : {
              billingStatementId: NewBillingStatementId(dto.billingStatementId!),
              billingStatementNumber: dto.billingStatementNumber!,
            },
      orderItems: dto.orderItems.map((dto) => {
        const orderItem: IOrderItem = {
          id: { type: "order-item-id", value: dto.id },
          orderTypeId: { type: "order-type-id", value: dto.orderTypeId },
          totalPrice: dto.price,
          specification: mapSpecificationDtoToSpecification(dto.specification),
        };

        return orderItem;
      }),
      assignedEmployees: dto.assignedEmployees?.map((a) => ({
        ...a,
        employeeId: { type: "employee-id", value: a.employeeId },
      })),
    };
  } catch (e) {
    console.error("e: ", e);
    return null as never;
  }
};

const mapSingleDentistOrderPayload = (labId: ILabId, dto: IDentistOrderReadModelDTO): IDentistOrderReadModel => {
  try {
    const orderItems: IOrderItem[] = dto.orderItems.map((dto) => {
      const orderItem: IOrderItem = {
        id: { type: "order-item-id", value: dto.id },
        orderTypeId: { type: "order-type-id", value: dto.orderTypeId },
        totalPrice: dto.price,
        specification: mapSpecificationDtoToSpecification(dto.specification),
      };

      return orderItem;
    });

    return {
      orderId: { type: "order-id", value: dto.orderId },
      labId: { type: "dental-lab-id", value: dto.labId },
      labName: dto.labName,
      patient:
        dto.patient === null
          ? {
              firstName: null,
              lastName: null,
              patientCode: null,
              country: null,
              age: null,
              gender: Gender.Unknown,
            }
          : {
              firstName: dto.patient.firstName,
              lastName: dto.patient.lastName,
              patientCode: dto.patient.patientCode,
              country: dto.patient.country === null ? null : mapCountryCodeToCountry(dto.patient.country),
              age: dto.patient.age === null ? null : dto.patient.age,
              gender: mapGenderCodeToGender(dto.patient.gender),
            },
      startDate: dto.startDate,
      dueDate: dto.dueDate,
      finishDate: dto.finishDate,
      status: mapStatusCodeToStatus(dto.status),
      orderNote: dto.orderNote,
      color: dto.color === null ? NO_COLOR : mapColorCodeToColor(dto.color),
      orderItems: orderItems,
    };
  } catch (e) {
    console.error("e: ", e);
    return null as never;
  }
};
