import { Alert } from "antd";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { SpecificationType } from "../../../../specification/order-specification";

export const SelectAtLeastOnePoint = (props: {
  specificationType:
    | SpecificationType.Crowns
    | SpecificationType.LocatedPoints
    | SpecificationType.PartialDentures;
}) => {
  let message;

  switch (props.specificationType) {
    case SpecificationType.LocatedPoints:
    case SpecificationType.PartialDentures: {
      message = (
        <FormattedMessage
          id={"select-at-least-one-point.select-at-least-one-point"}
          defaultMessage={"Nie dokonano wyboru, proszę wybrać przynajmniej jeden punkt"}
        />
      );
      break;
    }
    case SpecificationType.Crowns: {
      message = (
        <FormattedMessage
          id={"select-at-least-one-point.select-at-least-one-crown"}
          defaultMessage={"Nie dokonano wyboru, proszę wybrać przynajmniej jedną koronę"}
        />
      );
      break;
    }
  }

  return <Alert className="m-4" type="error" showIcon message={message} />;
};
