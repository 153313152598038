import { IntlShape } from "react-intl";
import { Arch } from "core/tooth/tooth.model";

export const archesPartFormatter = (intl: IntlShape, arches: Arch[]) => {
  const onlyUpper = arches.length === 1 && arches.includes(Arch.UpperArch);
  const onlyLower = arches.length === 1 && arches.includes(Arch.LowerArch);
  const both = arches.length === 2 && arches.includes(Arch.UpperArch) && arches.includes(Arch.LowerArch);

  if (onlyUpper) {
    return intl.formatMessage({
      id: "order-item-formatter.arch-parts.upper",
      defaultMessage: "góra",
    });
  } else if (onlyLower) {
    return intl.formatMessage({
      id: "order-item-formatter.arch-parts.lower",
      defaultMessage: "dół",
    });
  } else if (both) {
    return intl.formatMessage({
      id: "order-item-formatter.arch-parts.both",
      defaultMessage: "góra i dół",
    });
  } else {
    return "";
  }
};
