import React, { useMemo } from "react";
import { mapWidthToSize } from "core/utils/resize-utils";
import useComponentSize from '@rehooks/component-size'

function useSize<K extends keyof any>(ref: React.MutableRefObject<any>, breakpoints: Record<K, number>) {
    let componentSize = useComponentSize(ref);

    const toSize = useMemo(() => {
        return mapWidthToSize(breakpoints);
    }, [breakpoints]);

    const size = toSize(componentSize.width);

    return [size, componentSize] as const;
}

export default useSize;
