import * as React from "react";
import { Breadcrumb, PageHeader } from "antd";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import Button from "components/button/Button";
import Links from "components/links/Links";
import { FilePdfOutlined, HomeOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import useAccess from "features/access/useAccess";
import LabOrdersListView from "features/orders/pages/list/views/LabOrdersListView";
import DentistOrdersListView from "features/orders/pages/list/views/DentistOrdersListView";
import useAuth from "features/auth/hooks/useAuth";
import { Helmet } from "react-helmet";

interface IListOrderPageProps {}

const ListOrdersPage: React.FunctionComponent<IListOrderPageProps> = () => {
  const access = useAccess();
  const { authContext } = useAuth();

  return (
    <>
      <FormattedMessage id={"page-titles.invitation"} defaultMessage={"Zamówienia"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div>
        <HeaderContent>
          <Breadcrumb className="px-4 py-2">
            <Breadcrumb.Item>
              <Links.Home>
                <HomeOutlined />
              </Links.Home>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Links.OrdersList />
            </Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={<FormattedMessage id="common.orders" defaultMessage="Zamówienia" />}
            extra={
              <div className="flex flex-row flex-wrap gap-2">
                {access.isLabOwner && (
                  <Links.CreateBillingStatement>
                    <Button>
                      <FilePdfOutlined className="mr-2" />
                      <FormattedMessage id="orders-list.generate-invoice" defaultMessage="Generuj nową fakturę" />
                    </Button>
                  </Links.CreateBillingStatement>
                )}
                <Links.CreateOrder>
                  <Button>
                    <PlusOutlined className="mr-2" />
                    <FormattedMessage id="orders-list.create-order" defaultMessage="Dodaj zamówienie" />
                  </Button>
                </Links.CreateOrder>
              </div>
            }
          />
        </HeaderContent>
        <PageContent>
          <div className="my-8 bg-white overflow-x-auto">
            {(access.isLabEmployee || access.isLabOwner) && <LabOrdersListView />}
          </div>
          <div className="my-8 bg-white overflow-x-auto">
            {access.isDentist && <DentistOrdersListView userId={authContext.userId!} />}
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default ListOrdersPage;
