export enum Path {
  // Root level
  Root = "/",
  SignUp = "/signup",
  SignIn = "/signin",
  Landing = "/landing",

  // Chat
  ChatList = "/chat",
  ChatChannel = "/chat/channel/:channelId",

  // Dentist
  ListDentists = "/dentists/list",
  InviteDentist = "/dentists/:dentistId/invite",
  RegisterDentist = "/dentists/:dentistId/register",

  // Employees
  ListEmployees = "/employees/list",
  InviteEmployee = "/employees/:employeeId/invite",
  RegisterEmployee = "/employees/:employeeId/register",

  // Orders
  ListOrders = "/orders/list",
  CreateOrder = "/orders/create",
  EditOrder = "/orders/:orderId/edit",
  OrderDetails = "/order/:orderId/details",
  EditOrderItems = "/order/:orderId/edit-order-items",

  // Orders catalogue
  OrderCatalogue = "/orders-catalogue",

  // Pricing lists
  LaboratoryPricingLists = "/pricing-lists/lab",
  LabPricingList = "/pricing-lists/lab/:pricingListId",
  DentistsPricingLists = "/pricing-lists/dentist",
  DentistPricingList = "/pricing-lists/dentist/:labId",

  // Invoicing
  BillingStatements = "/billing-statements/list",
  BillingStatementDetails = "/billing-statement/:billingStatementId/details",
  CreateBillingStatement = "/billing-statement/create",
  DownloadOrderList = "/invoices/:invoiceId/download-order-list",

  // Auth
  Subscriptions = "/subscriptions",
  SubscriptionsSuccess = "/subscriptions/success",
  SubscriptionsCanceled = "/subscriptions/canceled",

  // Auth
  Login = "/login",
  Register = "/register",
  ConfirmEmail = "/confirm-email",
  ConfirmEmailWithEmbeddedLink = "/confirm-email/:confirmEmailLink",
  ResetPassword = "/reset-password",
  SetNewPassword = "/set-new-password",
  EXTERNALY_REFERENCED_VerifyEmail = "/verify-email",

  // Settings
  Settings = "/settings",
}

export type OrderIdRouteParams = {
  orderId: string;
};

export type InvoiceRouteParams = {
  invoiceId: string;
};

export type PricingListPageRouteParams = {
  pricingListId: string;
};

export type InviteRouteParams = {
  employeeId?: string | undefined;
  dentistId?: string | undefined;
};

export type ParametrizedPath =
  | {
      path: Path.OrderDetails | Path.EditOrder;
      params: OrderIdRouteParams;
    }
  | {
      path: Path.BillingStatementDetails;
      params: { billingStatementId: string };
    }
  | {
      path: Path.DownloadOrderList;
      params: InvoiceRouteParams;
    }
  | {
      path: Path.InviteDentist | Path.InviteEmployee;
      params: InviteRouteParams;
    }
  | {
      path: Path.LabPricingList;
      params: PricingListPageRouteParams;
    }
  | {
      path: Path.DentistPricingList;
      params: { labId: string };
    }
  | {
      path: Path.ConfirmEmailWithEmbeddedLink;
      params: { confirmEmailLink: string };
    }
  | {
      path: Path.ChatChannel;
      params: { channelId: string };
    };
