import * as React from "react";
import moment from "moment";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { defaultLanguage, Language } from "i18n/i18n.models";
import { antDesignMessages } from "i18n/translations/3rd-party/3rd-party";
import localizedMessages from "i18n/messages";
import LocalizedToastMessages from "components/message/LocalizedToastMessages";
import LocalizedNotificationMessages from "../../components/notifications/LocalizedNotificationMessages";
import LocalizedModalMessages from "../../components/modals/LocalizedNotificationMessages";

interface ILocalizationProvidersProps {
  children: React.ReactChild;
}

interface ILocalizationProvidersState {
  language: Language;
}

class LocalizationProviders extends React.PureComponent<ILocalizationProvidersProps, ILocalizationProvidersState> {
  constructor(props: ILocalizationProvidersProps) {
    super(props);

    this.state = {
      language: Language.Polish,
    };
  }

  componentDidMount() {
    moment.locale("pl");
  }

  render() {
    const { language } = this.state;
    const translationMessages: any = localizedMessages[language] === null ? undefined : localizedMessages[language];
    const componentsLocale: any = antDesignMessages[language];

    return (
      <IntlProvider key={language} locale={language} messages={translationMessages} defaultLocale={defaultLanguage}>
        <ConfigProvider locale={componentsLocale}>
          <LocalizedToastMessages>
            <LocalizedNotificationMessages>
              <LocalizedModalMessages>
                {this.props.children}
              </LocalizedModalMessages>
            </LocalizedNotificationMessages>
          </LocalizedToastMessages>
        </ConfigProvider>
      </IntlProvider>
    );
  }
}

export default LocalizationProviders;
