import * as React from "react";
import { useContext, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { DownOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Spin } from "antd";
import AuthServiceContext from "../../auth/auth-service.context";
import Avatar from "components/avatar/Avatar";
import useAuth from "features/auth/hooks/useAuth";
import Links from "components/links/Links";
import useAccess from "features/access/useAccess";
import { useNavigate } from "react-router";
import { Path } from "core/routes/routes";

import styles from "./user-menu.module.scss";

interface IUserMenuProps {}

const UserMenu: React.FunctionComponent<IUserMenuProps> = (props) => {
  const navigate = useNavigate();
  const authService = useContext(AuthServiceContext);
  const access = useAccess();
  const { isLoggedIn, authContext } = useAuth();

  const handleLogout = useCallback(() => {
    authService.logout();
    navigate(Path.Root);
  }, [authService, navigate]);

  if (isLoggedIn === null) {
    return <Spin />;
  }

  if (!isLoggedIn) {
    return null;
  }

  if (authContext!.isEmpty()) {
    return null;
  }

  if (authContext!.userProfile === undefined || authContext!.userProfile === null) {
    return null;
  }

  const initials =
    authContext.userProfile.firstName !== undefined && authContext.userProfile.lastName !== undefined
      ? authContext.userProfile.firstName.substring(0, 1).toUpperCase() +
        authContext.userProfile.lastName.substring(0, 1).toUpperCase()
      : "Profil";

  return (
    <Dropdown
      trigger={["click"]}
      className={styles.userMenu}
      overlay={
        <Menu selectable={false} className={"p-2"}>
          {access.toSettings && (
            <>
              <Menu.Item key="user-settings" icon={<SettingOutlined />}>
                <Links.Settings>
                  <FormattedMessage id="common.settings" defaultMessage="Ustawienia" />
                </Links.Settings>
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
            <FormattedMessage id="user-menu.logout" defaultMessage="Wyloguj" />
          </Menu.Item>
        </Menu>
      }
    >
      <div className="cursor-pointer">
        <div className={styles.avatarContainer}>
          <Avatar name={initials} />
        </div>
        <span className={styles.name}>
          {`${authContext.userProfile.firstName} ${authContext.userProfile.lastName}`}
          <DownOutlined className={"ml-1"} />
        </span>
      </div>
    </Dropdown>
  );
};

export default UserMenu;
