import * as React from "react";
import { useIntl } from "react-intl";
import { OrderSpecification } from "../../../specification/order-specification";
import { orderItemFormatter } from "./order-item-formatter";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";

interface IOrderItemFormatterProps extends React.HTMLAttributes<HTMLSpanElement> {
  orderTypeName: string;
  orderCode: OrderCodeEnum | null;
  specification: OrderSpecification;
}

const OrderItemFormatter: React.FunctionComponent<IOrderItemFormatterProps> = ({
  orderTypeName,
  orderCode,
  specification,
}) => {
  const intl = useIntl();
  return <>{orderItemFormatter(intl, orderTypeName, orderCode, specification)}</>;
};

export default OrderItemFormatter;
