import {
  archToPointsArrayMap,
  PointId,
  pointToArchMap,
  pointToReadingIndexMap,
  pointToUniversalIndexMap,
} from "core/point/point.enums";
import { PointsSortOrder } from "core/point/point.sort";
import { Arch } from "../tooth/tooth.model";

export const areNeighbouring = (
  prev: PointId,
  next: PointId,
  sortOrder: PointsSortOrder = PointsSortOrder.Clockwise
) => {
  if (prev === next) {
    return false;
  }

  const prevPointArch = pointToArchMap[prev];
  const nextPointArch = pointToArchMap[next];

  if (prevPointArch !== nextPointArch) {
    return false;
  }

  if (sortOrder === PointsSortOrder.Clockwise) {
    return Math.abs(pointToUniversalIndexMap[prev] - pointToUniversalIndexMap[next]) === 1;
  } else if (sortOrder === PointsSortOrder.Reading) {
    return Math.abs(pointToReadingIndexMap[prev] - pointToReadingIndexMap[next]) === 1;
  }
};

export const isArchContained = (points: PointId[], arch: Arch): boolean => {
  const archPoints = archToPointsArrayMap[arch];
  return archPoints.every((p) => points.includes(p));
};

export const getAllArchesInPointArray = (points: PointId[]): Record<Arch, boolean> =>
  points.reduce((acc, point) => ({ ...acc, [pointToArchMap[point]]: true }), {
    [Arch.UpperArch]: false,
    [Arch.LowerArch]: false,
  } as Record<Arch, boolean>);
