import { Arch } from "core/tooth/tooth.model";
import { LocatedPoint, CrownPoint, BridgePoint, FoundationPoint, MarylandBridgePoint } from "./point-specification";

export enum SpecificationType {
  TextOnly = "TextOnly",
  Quantity = "Quantity",
  Arches = "Arches",
  LocatedPoints = "LocatedPoints",
  PartialDentures = "PartialDentures",
  SurgicalGuides = "SurgicalGuides",
  Crowns = "Crowns",
  Bridges = "Bridges",
  MarylandBridges = "MarylandBridges",
  Foundations = "Foundations",
}

export type QuantitySpecification = {
  readonly specificationType: SpecificationType.Quantity;
  readonly quantity: number;
};

export type ArchesSpecification = {
  readonly specificationType: SpecificationType.Arches;
  readonly arches: Arch[];
};

export type LocatedPointsSpecification = {
  readonly specificationType: SpecificationType.LocatedPoints;
  readonly points: LocatedPoint[];
};

export type PartialDenturesSpecification = {
  readonly specificationType: SpecificationType.PartialDentures;
  readonly points: LocatedPoint[];
};

export type SurgicalGuidesSpecification = {
  readonly specificationType: SpecificationType.SurgicalGuides;
  readonly points: LocatedPoint[];
};

export type CrownsSpecification = {
  readonly specificationType: SpecificationType.Crowns;
  readonly points: CrownPoint[];
};

export type BridgesSpecification = {
  readonly specificationType: SpecificationType.Bridges;
  readonly points: BridgePoint[][];
};

export type FoundationsSpecification = {
  readonly specificationType: SpecificationType.Foundations;
  readonly points: FoundationPoint[][];
};

export type MarylandBridgesSpecification = {
  readonly specificationType: SpecificationType.MarylandBridges;
  readonly points: MarylandBridgePoint[][];
};

export type PointBasedSpecification =
  | LocatedPointsSpecification
  | PartialDenturesSpecification
  | SurgicalGuidesSpecification
  | CrownsSpecification
  | BridgesSpecification
  | FoundationsSpecification
  | MarylandBridgesSpecification;

export type AnchorTypeBasedSpecification =
  | CrownsSpecification
  | BridgesSpecification
  | FoundationsSpecification
  | MarylandBridgesSpecification;

export type LinkedPointsSpecification = BridgesSpecification | FoundationsSpecification | MarylandBridgesSpecification;

export type OrderSpecification =
  | QuantitySpecification
  | ArchesSpecification
  | LocatedPointsSpecification
  | PartialDenturesSpecification
  | SurgicalGuidesSpecification
  | CrownsSpecification
  | BridgesSpecification
  | FoundationsSpecification
  | MarylandBridgesSpecification;
