import {
  IBaseOrderTableEntry,
  IDataProvider,
  IPaginatedProviderParams,
  ServerSortDirection,
} from "features/orders/pages/list/components/orders-table.models";
import { IDentistId, IShortDentist } from "features/dentist/dentist.model";
import { IBillingStatementId } from "features/invoicing/models/invoicing.models";
import { IEmployee, IEmployeeId } from "features/employee/employee.model";
import { ISODateTimeString } from "core/time/time.model";
import { StatusCode } from "features/orders/status/order-status.model";

export interface ILabOrderTableEntry extends IBaseOrderTableEntry {
  readonly dentist: IShortDentist;
  readonly orderNumber: number | null;
  readonly billingStatement: { billingStatementId: IBillingStatementId; billingStatementNumber: number } | null;
  readonly assignedEmployees: IEmployee[];
}

export enum LabOrderServerColumn {
  // do not change - without sync with backend
  OrderNumber = "orderNumber",
  Dentist = "dentist",
  DueDate = "dueDate",
  FinishDate = "finishDate",
  Status = "status",
}

export enum AssignmentFilter {
  // do not change - without sync with backend
  All = "all",
  Employee = "employee",
  Unassigned = "unassigned",
}

export interface ILabOrdersProviderParams extends IPaginatedProviderParams {
  sortBy?: { column: LabOrderServerColumn; direction: ServerSortDirection } | null;
  orderNumber?: number | null;
  dentistIds?: IDentistId[] | null;
  patient?: string | null;
  dueDateRange?: { start: ISODateTimeString; end: ISODateTimeString } | null;
  status?: StatusCode[] | null;
  finishDateRange?: { start: ISODateTimeString; end: ISODateTimeString } | null;
  assignmentFilter?: AssignmentFilter;
  assignedEmployee?: IEmployeeId | null;
}

export type LabOrdersDataProvider = IDataProvider<ILabOrderTableEntry, ILabOrdersProviderParams>;
