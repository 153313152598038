import { FormattedMessage } from "react-intl";
import { Typography, Row, Col } from "antd";

type PropsType = {
  onThumbsUpClick: () => void;
  onThumbsDownClick: () => void;
};

const OrderFeedbackThumbs = ({ onThumbsUpClick, onThumbsDownClick }: PropsType) => (
  <Row align="middle">
    <Col flex="auto" className="py-2">
      <Typography.Title level={4}>
        <FormattedMessage id="order-feedback.how-would-you-rate-the-order" defaultMessage="Jak oceniasz zamówienie ?" />
      </Typography.Title>
    </Col>

    <Col flex="none" className="p-3 cursor-pointer flex flex-nowrap gap-4">
      <svg
        onClick={onThumbsUpClick}
        className="h-10 w-12 text-green-400 inline transform scale-100 hover:scale-110"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
      </svg>
      <svg
        onClick={onThumbsDownClick}
        className="h-10 w-12 text-red-400 inline transform scale-100 hover:scale-110"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
      </svg>
    </Col>
  </Row>
);

export default OrderFeedbackThumbs;
