/* eslint-disable react/display-name */
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link, LinkProps } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { Path } from "core/routes/routes";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { IOrderId } from "features/orders/order.model";
import { IBillingStatementId } from "features/invoicing/models/invoicing.models";
import { IPricingListId } from "features/pricing-lists/pricing-list.models";
import { ILabId } from "features/dental-lab/dental-lab.model";

interface IDefaultLinkProps extends Omit<LinkProps, "to"> {}

const Home = React.forwardRef<any, IDefaultLinkProps>(({ children, ...props }, ref) => (
  <Link ref={ref} to={Path.Root} {...props}>
    <HomeOutlined />
  </Link>
));

const OrdersList = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.ListOrders} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.orders" defaultMessage="Zamówienia" />
    ) : (
      props.children
    )}
  </Link>
));

const OrderDetails = React.forwardRef<
  any,
  IDefaultLinkProps & { params: { orderId: IOrderId; orderNumber: number | null } }
>((props, ref) => (
  <Link
    ref={ref}
    to={parametrizePath({
      path: Path.OrderDetails,
      params: { orderId: props.params.orderId.value },
    })}
    {...props}
  >
    {props?.children === undefined ? (
      <>
        <FormattedMessage
          id="orders.order-with-number"
          defaultMessage="Zamówienie {orderNumber}"
          values={{ orderNumber: props.params.orderNumber }}
        />
      </>
    ) : (
      props.children
    )}
  </Link>
));

const CreateOrder = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.CreateOrder} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.create-order" defaultMessage="Stwórz zamówienie" />
    ) : (
      props.children
    )}
  </Link>
));

const EditOrder = React.forwardRef<any, IDefaultLinkProps & { params: { orderId: IOrderId } }>((props, ref) => (
  <Link
    ref={ref}
    to={parametrizePath({
      path: Path.EditOrder,
      params: { orderId: props.params.orderId.value },
    })}
    {...props}
  >
    {props?.children === undefined ? (
      <FormattedMessage id="common.edit-order" defaultMessage="Edytuj zamówienie" />
    ) : (
      props.children
    )}
  </Link>
));

const DentistsList = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.ListDentists} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.dentists" defaultMessage="Dentyści" />
    ) : (
      props.children
    )}
  </Link>
));

const EmployeesList = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.ListEmployees} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.employees" defaultMessage="Pracownicy" />
    ) : (
      props.children
    )}
  </Link>
));

const CreateBillingStatement = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.CreateBillingStatement} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.generate-billing-statement" defaultMessage="Generuj rozliczenie" />
    ) : (
      props.children
    )}
  </Link>
));

const BillingStatements = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.BillingStatements} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.billing-statements" defaultMessage="Rozliczenia" />
    ) : (
      props.children
    )}
  </Link>
));

const BillingStatementDetails = React.forwardRef<
  any,
  IDefaultLinkProps & { params: { billingStatementId: IBillingStatementId } }
>((props, ref) => (
  <Link
    ref={ref}
    to={parametrizePath({
      path: Path.BillingStatementDetails,
      params: { billingStatementId: props.params.billingStatementId.value },
    })}
    {...props}
  >
    {props?.children === undefined ? (
      <FormattedMessage id="common.billing-statement" defaultMessage="Rozliczenie" />
    ) : (
      props.children
    )}
  </Link>
));

const DownloadOrderList = React.forwardRef<any, IDefaultLinkProps & { params: { invoiceId: IBillingStatementId } }>(
  (props, ref) => (
    <Link
      ref={ref}
      to={parametrizePath({
        path: Path.DownloadOrderList,
        params: { invoiceId: props.params.invoiceId.value },
      })}
      {...props}
    >
      {props.children}
    </Link>
  )
);

const LoginPage = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.Login} {...props}>
    {props.children}
  </Link>
));

const RegisterPage = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.Register} {...props}>
    {props.children}
  </Link>
));

const Settings = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.Settings} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.settings" defaultMessage="Ustawienia" />
    ) : (
      props.children
    )}
  </Link>
));

const Subscriptions = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.Subscriptions} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.subscriptions" defaultMessage="Subskrypcje" />
    ) : (
      props.children
    )}
  </Link>
));

const LaboratoryPricingLists = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.LaboratoryPricingLists} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.pricing-lists" defaultMessage="Cenniki" />
    ) : (
      props.children
    )}
  </Link>
));

const DentistsPricingLists = React.forwardRef<any, IDefaultLinkProps>((props, ref) => (
  <Link ref={ref} to={Path.DentistsPricingLists} {...props}>
    {props?.children === undefined ? (
      <FormattedMessage id="common.pricing-lists" defaultMessage="Cenniki" />
    ) : (
      props.children
    )}
  </Link>
));

const DentistPricingList = React.forwardRef<any, IDefaultLinkProps & { params: { labId: ILabId } }>((props, ref) => (
  <Link
    ref={ref}
    to={parametrizePath({
      path: Path.DentistPricingList,
      params: { labId: props.params.labId.value },
    })}
    {...props}
  >
    {props.children}
  </Link>
));

const LabPricingList = React.forwardRef<
  any,
  IDefaultLinkProps & { pricingListId: IPricingListId; children: JSX.Element }
>((props, ref) => (
  <Link
    ref={ref}
    to={parametrizePath({ path: Path.LabPricingList, params: { pricingListId: props.pricingListId.value } })}
    {...props}
  >
    {props.children}
  </Link>
));

class Links {
  static Home = Home;
  static OrdersList = OrdersList;
  static OrderDetails = OrderDetails;
  static CreateOrder = CreateOrder;
  static EditOrder = EditOrder;
  static DentistsList = DentistsList;
  static CreateBillingStatement = CreateBillingStatement;
  static BillingStatements = BillingStatements;
  static RegisterPage = RegisterPage;
  static EmployeesList = EmployeesList;
  static BillingStatementDetails = BillingStatementDetails;
  static DownloadOrderList = DownloadOrderList;
  static Settings = Settings;
  static Subscriptions = Subscriptions;
  static LabPricingLists = LaboratoryPricingLists;
  static LabPricingList = LabPricingList;
  static DentistsPricingLists = DentistsPricingLists;
  static DentistPricingList = DentistPricingList;
  static LoginPage = LoginPage;
}

export default Links;
