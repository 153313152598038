import React from "react";
import useSWR from "swr";
import { IOrderId } from "features/orders/order.model";
import { parametrizeEndpointPath } from "core/lib/routing/parametrize-route";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import OrderApiContext from "features/orders/order.context";

function useOrderHistory(orderId: IOrderId) {
  const orderApi = React.useContext(OrderApiContext);

  const {
    data: getOrderHistory,
    isValidating,
    mutate: revalidate,
  } = useSWR(
    parametrizeEndpointPath({ path: ApiEndpointPath.GetOrderHistory, params: { orderId: orderId.value } }),
    () => orderApi.getOrderHistory(orderId)
  );

  return {
    getOrderHistory: getOrderHistory,
    isLoading: isValidating,
    refresh: revalidate,
  };
}

export default useOrderHistory;
