import * as React from "react";
import useBooleanFlag from "../../../../hooks/useBooleanFlag";

function useSettingsSection(initialIsEditing: boolean = false) {
  const { flag, check, uncheck } = useBooleanFlag(initialIsEditing);

  return {
    isEditing: flag,
    onCancel: uncheck,
    onEdit: check,
  };
}

export default useSettingsSection;
