import * as React from "react";
import { ProFormText, QueryFilter } from "@ant-design/pro-form";
import { IEmployeeQueryFilterValues } from "./EmployeeQueryFilterValues";
import { SearchOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

const propertyNames: Record<keyof IEmployeeQueryFilterValues, keyof IEmployeeQueryFilterValues> = {
  searchPhrase: "searchPhrase",
};

const EmployeeQueryFilter: React.FunctionComponent<{
  onSearch: (searchArgs: IEmployeeQueryFilterValues) => Promise<void>;
}> = (props) => (
  <QueryFilter<IEmployeeQueryFilterValues>
    onFinish={props.onSearch}
    onValuesChange={props.onSearch}
    optionRender={(_1, _2, dom) => {
      const [resetBtn, submitBtn] = dom as any;
      const initialReset = resetBtn?.props?.onClick;
      const initialSubmit = submitBtn?.props?.onClick;

      const resetClick = function () {
        initialReset?.();
        initialSubmit?.();
      };

      return [
        React.cloneElement(resetBtn, { type: "link", onClick: resetClick }),
        React.cloneElement(submitBtn, { type: "default", icon: <SearchOutlined /> }),
      ];
    }}
  >
    <ProFormText
      name={propertyNames.searchPhrase}
      placeholder={""}
      label={<FormattedMessage id="employee-query-filter.employee" defaultMessage="Pracownik" />}
    />
  </QueryFilter>
);

export default EmployeeQueryFilter;
