import * as React from "react";
import { IntlShape, defineMessages } from "react-intl";
import { IDomainError } from "core/errors/domain-error";

export type IFormattedDomainErrorMessages = {
  title: string;
  subTitle: string | null;
};

export const formatDomainErrorMessages = (intl: IntlShape, error: IDomainError): IFormattedDomainErrorMessages => {
  const { code, message, errorParameters } = error;

  const translationKeys = {
    title: `error-messages.${code}`,
    subTitle: `error-messages.${code}-sub-title`,
  };

  const translationsExist = {
    title: intl !== null && intl.messages !== null ? !!intl.messages[translationKeys.title] : false,
    subTitle: intl !== null && intl.messages !== null ? !!intl.messages[translationKeys.subTitle] : false,
  };

  if (translationsExist.title && translationsExist.subTitle) {
    return {
      title: intl.formatMessage({ id: translationKeys.title }, errorParameters),
      subTitle: intl.formatMessage({ id: translationKeys.subTitle }, errorParameters),
    };
  } else if (translationsExist.title) {
    return {
      title: intl.formatMessage({ id: translationKeys.title }, errorParameters),
      subTitle: null,
    };
  } else {
    console.error("no translations for error: ", code);

    return {
      title: code.indexOf("_") === -1 ? code : code.substring(code.indexOf("_") + 1, code.length),
      subTitle: message,
    };
  }
};

try {
  defineMessages({
    "error-messages.Auth_CouldNotConfirmEmail": {
      id: "error-messages.Auth_CouldNotConfirmEmail",
      defaultMessage: "Auth_CouldNotConfirmEmail",
    },
    "error-messages.Auth_CouldNotConfirmEmail-sub-title": {
      id: "error-messages.Auth_CouldNotConfirmEmail-sub-title",
      defaultMessage: "Auth_CouldNotConfirmEmail-sub-title",
    },
    "error-messages.Auth_RegistrationFailed": {
      id: "error-messages.Auth_RegistrationFailed",
      defaultMessage: "Auth_RegistrationFailed",
    },
    "error-messages.Auth_RegistrationFailed-sub-title": {
      id: "error-messages.Auth_RegistrationFailed-sub-title",
      defaultMessage: "Auth_RegistrationFailed-sub-title",
    },
    "error-messages.Auth_CouldNotAttachUserToRole": {
      id: "error-messages.Auth_CouldNotAttachUserToRole",
      defaultMessage: "Auth_CouldNotAttachUserToRole",
    },
    "error-messages.Auth_CouldNotAttachUserToRole-sub-title": {
      id: "error-messages.Auth_CouldNotAttachUserToRole-sub-title",
      defaultMessage: "Auth_CouldNotAttachUserToRole-sub-title",
    },
    "error-messages.Auth_PasswordToShort": {
      id: "error-messages.Auth_PasswordToShort",
      defaultMessage: "Auth_PasswordToShort",
    },
    "error-messages.Auth_PasswordToShort-sub-title": {
      id: "error-messages.Auth_PasswordToShort-sub-title",
      defaultMessage: "Auth_PasswordToShort-sub-title",
    },
    "error-messages.General_Unauthorized": {
      id: "error-messages.General_Unauthorized",
      defaultMessage: "General_Unauthorized",
    },
    "error-messages.General_Unauthorized-sub-title": {
      id: "error-messages.General_Unauthorized-sub-title",
      defaultMessage: "General_Unauthorized-sub-title",
    },
    "error-messages.General_InternalError": {
      id: "error-messages.General_InternalError",
      defaultMessage: "General_InternalError",
    },
    "error-messages.General_InternalError-sub-title": {
      id: "error-messages.General_InternalError-sub-title",
      defaultMessage: "General_InternalError-sub-title",
    },
    "error-messages.General_EntityUpdateFailed": {
      id: "error-messages.General_EntityUpdateFailed",
      defaultMessage: "General_EntityUpdateFailed",
    },
    "error-messages.General_EntityUpdateFailed-sub-title": {
      id: "error-messages.General_EntityUpdateFailed-sub-title",
      defaultMessage: "General_EntityUpdateFailed-sub-title",
    },
    "error-messages.General_RecordNotFound": {
      id: "error-messages.General_RecordNotFound",
      defaultMessage: "nie znaleziono danych na serwerze",
    },
    "error-messages.General_RecordNotFound-sub-title": {
      id: "error-messages.General_RecordNotFound-sub-title",
      defaultMessage: "",
    },
    "error-messages.ServerError": {
      id: "error-messages.ServerError",
      defaultMessage: "ServerError",
    },
    "error-messages.ServerError-sub-title": {
      id: "error-messages.ServerError-sub-title",
      defaultMessage: "ServerError-sub-title",
    },
    "error-messages.Orders_OrderAlreadyExists": {
      id: "error-messages.Orders_OrderAlreadyExists",
      defaultMessage: "Orders_OrderAlreadyExists",
    },
    "error-messages.Orders_OrderAlreadyExists-sub-title": {
      id: "error-messages.Orders_OrderAlreadyExists-sub-title",
      defaultMessage: "Orders_OrderAlreadyExists-sub-title",
    },
    "error-messages.Orders_OrderNumberAlreadyTaken": {
      id: "error-messages.Orders_OrderNumberAlreadyTaken",
      defaultMessage: "Orders_OrderNumberAlreadyTaken",
    },
    "error-messages.Orders_OrderNumberAlreadyTaken-sub-title": {
      id: "error-messages.Orders_OrderNumberAlreadyTaken-sub-title",
      defaultMessage: "Orders_OrderNumberAlreadyTaken-sub-title",
    },
    "error-messages.Orders_OrderItemNotFound": {
      id: "error-messages.Orders_OrderItemNotFound",
      defaultMessage: "Orders_OrderItemNotFound",
    },
    "error-messages.Orders_OrderItemNotFound-sub-title": {
      id: "error-messages.Orders_OrderItemNotFound-sub-title",
      defaultMessage: "Orders_OrderItemNotFound-sub-title",
    },
    "error-messages.Orders_OrderItemAlreadyExists": {
      id: "error-messages.Orders_OrderItemAlreadyExists",
      defaultMessage: "Orders_OrderItemAlreadyExists",
    },
    "error-messages.Orders_OrderItemAlreadyExists-sub-title": {
      id: "error-messages.Orders_OrderItemAlreadyExists-sub-title",
      defaultMessage: "Orders_OrderItemAlreadyExists-sub-title",
    },
    "error-messages.Orders_OrderNotFound": {
      id: "error-messages.Orders_OrderNotFound",
      defaultMessage: "Orders_OrderNotFound",
    },
    "error-messages.Orders_OrderNotFound-sub-title": {
      id: "error-messages.Orders_OrderNotFound-sub-title",
      defaultMessage: "Orders_OrderNotFound-sub-title",
    },
    "error-messages.Orders_CanNotRetrieveOrderAsThisOrderIsDeleted": {
      id: "error-messages.Orders_CanNotRetrieveOrderAsThisOrderIsDeleted",
      defaultMessage: "Orders_CanNotRetrieveOrderAsThisOrderIsDeleted",
    },
    "error-messages.Orders_CanNotRetrieveOrderAsThisOrderIsDeleted-sub-title": {
      id: "error-messages.Orders_CanNotRetrieveOrderAsThisOrderIsDeleted-sub-title",
      defaultMessage: "Orders_CanNotRetrieveOrderAsThisOrderIsDeleted-sub-title",
    },
    "error-messages.Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus": {
      id: "error-messages.Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus",
      defaultMessage: "Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus",
    },
    "error-messages.Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus-sub-title": {
      id: "error-messages.Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus-sub-title",
      defaultMessage: "Orders_CanNotChangeOrderFinishDateForOrderWithFollowingStatus-sub-title",
    },
    "error-messages.Orders_CanNotAccessOrder": {
      id: "error-messages.Orders_CanNotAccessOrder",
      defaultMessage: "Orders_CanNotAccessOrder",
    },
    "error-messages.Orders_CanNotAccessOrder-sub-title": {
      id: "error-messages.Orders_CanNotAccessOrder-sub-title",
      defaultMessage: "Orders_CanNotAccessOrder-sub-title",
    },
    "error-messages.OrderFiles_DeleteFailed": {
      id: "error-messages.OrderFiles_DeleteFailed",
      defaultMessage: "OrderFiles_DeleteFailed",
    },
    "error-messages.OrderFiles_DeleteFailed-sub-title": {
      id: "error-messages.OrderFiles_DeleteFailed-sub-title",
      defaultMessage: "OrderFiles_DeleteFailed-sub-title",
    },
    "error-messages.OrderFiles_FileTooBig": {
      id: "error-messages.OrderFiles_FileTooBig",
      defaultMessage: "OrderFiles_FileTooBig",
    },
    "error-messages.OrderFiles_FileTooBig-sub-title": {
      id: "error-messages.OrderFiles_FileTooBig-sub-title",
      defaultMessage: "OrderFiles_FileTooBig-sub-title",
    },
    "error-messages.OrderFiles_OrderFileDoesNotExist": {
      id: "error-messages.OrderFiles_OrderFileDoesNotExist",
      defaultMessage: "OrderFiles_OrderFileDoesNotExist",
    },
    "error-messages.OrderFiles_OrderFileDoesNotExist-sub-title": {
      id: "error-messages.OrderFiles_OrderFileDoesNotExist-sub-title",
      defaultMessage: "OrderFiles_OrderFileDoesNotExist-sub-title",
    },
    "error-messages.OrderFiles_GetFileFailed": {
      id: "error-messages.OrderFiles_GetFileFailed",
      defaultMessage: "OrderFiles_GetFileFailed",
    },
    "error-messages.OrderFiles_GetFileFailed-sub-title": {
      id: "error-messages.OrderFiles_GetFileFailed-sub-title",
      defaultMessage: "OrderFiles_GetFileFailed-sub-title",
    },
    "error-messages.OrderFiles_UploadFailed": {
      id: "error-messages.OrderFiles_UploadFailed",
      defaultMessage: "OrderFiles_UploadFailed",
    },
    "error-messages.OrderFiles_UploadFailed-sub-title": {
      id: "error-messages.OrderFiles_UploadFailed-sub-title",
      defaultMessage: "OrderFiles_UploadFailed-sub-title",
    },
    "error-messages.OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists": {
      id: "error-messages.OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists",
      defaultMessage: "OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists",
    },
    "error-messages.OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists-sub-title": {
      id: "error-messages.OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists-sub-title",
      defaultMessage: "OrderFiles_CanNotAddOrderFileBecauseItAlreadyExists-sub-title",
    },
    "error-messages.UploadFiled": {
      id: "error-messages.UploadFiled",
      defaultMessage: "UploadFiled",
    },
    "error-messages.UploadFiled-sub-title": {
      id: "error-messages.UploadFiled-sub-title",
      defaultMessage: "UploadFiled-sub-title",
    },
    "error-messages.OrderComments_OrderCreateFailed": {
      id: "error-messages.OrderComments_OrderCreateFailed",
      defaultMessage: "OrderComments_OrderCreateFailed",
    },
    "error-messages.OrderComments_OrderCreateFailed-sub-title": {
      id: "error-messages.OrderComments_OrderCreateFailed-sub-title",
      defaultMessage: "OrderComments_OrderCreateFailed-sub-title",
    },
    "error-messages.OrderComments_CommentNotFound": {
      id: "error-messages.OrderComments_CommentNotFound",
      defaultMessage: "OrderComments_CommentNotFound",
    },
    "error-messages.OrderComments_CommentNotFound-sub-title": {
      id: "error-messages.OrderComments_CommentNotFound-sub-title",
      defaultMessage: "OrderComments_CommentNotFound-sub-title",
    },
    "error-messages.OrderComments_OnlyOwnerCanManipulateComment": {
      id: "error-messages.OrderComments_OnlyOwnerCanManipulateComment",
      defaultMessage: "OrderComments_OnlyOwnerCanManipulateComment",
    },
    "error-messages.OrderComments_OnlyOwnerCanManipulateComment-sub-title": {
      id: "error-messages.OrderComments_OnlyOwnerCanManipulateComment-sub-title",
      defaultMessage: "OrderComments_OnlyOwnerCanManipulateComment-sub-title",
    },
    "error-messages.OrderTree_IsActiveValueIsMissing": {
      id: "error-messages.OrderTree_IsActiveValueIsMissing",
      defaultMessage: "OrderTree_IsActiveValueIsMissing",
    },
    "error-messages.OrderTree_IsActiveValueIsMissing-sub-title": {
      id: "error-messages.OrderTree_IsActiveValueIsMissing-sub-title",
      defaultMessage: "OrderTree_IsActiveValueIsMissing-sub-title",
    },
    "error-messages.OrderTree_OrderTypeIdMissing": {
      id: "error-messages.OrderTree_OrderTypeIdMissing",
      defaultMessage: "OrderTree_OrderTypeIdMissing",
    },
    "error-messages.OrderTree_OrderTypeIdMissing-sub-title": {
      id: "error-messages.OrderTree_OrderTypeIdMissing-sub-title",
      defaultMessage: "OrderTree_OrderTypeIdMissing-sub-title",
    },
    "error-messages.OrderTree_OrderTypeBelongsToDifferentTree": {
      id: "error-messages.OrderTree_OrderTypeBelongsToDifferentTree",
      defaultMessage: "OrderTree_OrderTypeBelongsToDifferentTree",
    },
    "error-messages.OrderTree_OrderTypeBelongsToDifferentTree-sub-title": {
      id: "error-messages.OrderTree_OrderTypeBelongsToDifferentTree-sub-title",
      defaultMessage: "OrderTree_OrderTypeBelongsToDifferentTree-sub-title",
    },
    "error-messages.OrderTree_OrderCategoryBelongsToDifferentTree": {
      id: "error-messages.OrderTree_OrderCategoryBelongsToDifferentTree",
      defaultMessage: "OrderTree_OrderCategoryBelongsToDifferentTree",
    },
    "error-messages.OrderTree_OrderCategoryBelongsToDifferentTree-sub-title": {
      id: "error-messages.OrderTree_OrderCategoryBelongsToDifferentTree-sub-title",
      defaultMessage: "OrderTree_OrderCategoryBelongsToDifferentTree-sub-title",
    },
    "error-messages.OrderTree_ParentIdMissing": {
      id: "error-messages.OrderTree_ParentIdMissing",
      defaultMessage: "OrderTree_ParentIdMissing",
    },
    "error-messages.OrderTree_ParentIdMissing-sub-title": {
      id: "error-messages.OrderTree_ParentIdMissing-sub-title",
      defaultMessage: "OrderTree_ParentIdMissing-sub-title",
    },
    "error-messages.Pricing_OrderSpecificationTypeIsInvalid": {
      id: "error-messages.Pricing_OrderSpecificationTypeIsInvalid",
      defaultMessage: "Pricing_OrderSpecificationTypeIsInvalid",
    },
    "error-messages.Pricing_OrderSpecificationTypeIsInvalid-sub-title": {
      id: "error-messages.Pricing_OrderSpecificationTypeIsInvalid-sub-title",
      defaultMessage: "Pricing_OrderSpecificationTypeIsInvalid-sub-title",
    },
    "error-messages.Pricing_CatalogDoesNotHavePricingForOrderType": {
      id: "error-messages.Pricing_CatalogDoesNotHavePricingForOrderType",
      defaultMessage: "Pricing_CatalogDoesNotHavePricingForOrderType",
    },
    "error-messages.Pricing_CatalogDoesNotHavePricingForOrderType-sub-title": {
      id: "error-messages.Pricing_CatalogDoesNotHavePricingForOrderType-sub-title",
      defaultMessage: "Pricing_CatalogDoesNotHavePricingForOrderType-sub-title",
    },
    "error-messages.Pricing_NoDefaultPricingCatalogueForDentalLabFound": {
      id: "error-messages.Pricing_NoDefaultPricingCatalogueForDentalLabFound",
      defaultMessage: "Pricing_NoDefaultPricingCatalogueForDentalLabFound",
    },
    "error-messages.Pricing_NoDefaultPricingCatalogueForDentalLabFound-sub-title": {
      id: "error-messages.Pricing_NoDefaultPricingCatalogueForDentalLabFound-sub-title",
      defaultMessage: "Pricing_NoDefaultPricingCatalogueForDentalLabFound-sub-title",
    },
    "error-messages.Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId": {
      id: "error-messages.Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId",
      defaultMessage: "Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId",
    },
    "error-messages.Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId-sub-title": {
      id: "error-messages.Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId-sub-title",
      defaultMessage: "Pricing_CouldNotAddOrUpdatePriceForThisCatalogueId-sub-title",
    },
    "error-messages.DentalLab_DentalLabNotFound": {
      id: "error-messages.DentalLab_DentalLabNotFound",
      defaultMessage: "DentalLab_DentalLabNotFound",
    },
    "error-messages.DentalLab_DentalLabNotFound-sub-title": {
      id: "error-messages.DentalLab_DentalLabNotFound-sub-title",
      defaultMessage: "DentalLab_DentalLabNotFound-sub-title",
    },
    "error-messages.DentalLab_DentalLabCreationFailure": {
      id: "error-messages.DentalLab_DentalLabCreationFailure",
      defaultMessage: "DentalLab_DentalLabCreationFailure",
    },
    "error-messages.DentalLab_DentalLabCreationFailure-sub-title": {
      id: "error-messages.DentalLab_DentalLabCreationFailure-sub-title",
      defaultMessage: "DentalLab_DentalLabCreationFailure-sub-title",
    },
    "error-messages.DentalLab_DentalLabAlreadyExists": {
      id: "error-messages.DentalLab_DentalLabAlreadyExists",
      defaultMessage: "DentalLab_DentalLabAlreadyExists",
    },
    "error-messages.DentalLab_DentalLabAlreadyExists-sub-title": {
      id: "error-messages.DentalLab_DentalLabAlreadyExists-sub-title",
      defaultMessage: "DentalLab_DentalLabAlreadyExists-sub-title",
    },
    "error-messages.Payment_GetSubscriptionProductsFailed": {
      id: "error-messages.Payment_GetSubscriptionProductsFailed",
      defaultMessage: "Payment_GetSubscriptionProductsFailed",
    },
    "error-messages.Payment_GetSubscriptionProductsFailed-sub-title": {
      id: "error-messages.Payment_GetSubscriptionProductsFailed-sub-title",
      defaultMessage: "Payment_GetSubscriptionProductsFailed-sub-title",
    },
    "error-messages.Payment_GetProductFailed": {
      id: "error-messages.Payment_GetProductFailed",
      defaultMessage: "Payment_GetProductFailed",
    },
    "error-messages.Payment_GetProductFailed-sub-title": {
      id: "error-messages.Payment_GetProductFailed-sub-title",
      defaultMessage: "Payment_GetProductFailed-sub-title",
    },
    "error-messages.Payment_CreateCustomerFailed": {
      id: "error-messages.Payment_CreateCustomerFailed",
      defaultMessage: "Payment_CreateCustomerFailed",
    },
    "error-messages.Payment_CreateCustomerFailed-sub-title": {
      id: "error-messages.Payment_CreateCustomerFailed-sub-title",
      defaultMessage: "Payment_CreateCustomerFailed-sub-title",
    },
    "error-messages.Payment_CreateCheckoutSessionFailed": {
      id: "error-messages.Payment_CreateCheckoutSessionFailed",
      defaultMessage: "Payment_CreateCheckoutSessionFailed",
    },
    "error-messages.Payment_CreateCheckoutSessionFailed-sub-title": {
      id: "error-messages.Payment_CreateCheckoutSessionFailed-sub-title",
      defaultMessage: "Payment_CreateCheckoutSessionFailed-sub-title",
    },
    "error-messages.Payment_CreateBillingPortalSessionFailed": {
      id: "error-messages.Payment_CreateBillingPortalSessionFailed",
      defaultMessage: "Payment_CreateBillingPortalSessionFailed",
    },
    "error-messages.Payment_CreateBillingPortalSessionFailed-sub-title": {
      id: "error-messages.Payment_CreateBillingPortalSessionFailed-sub-title",
      defaultMessage: "Payment_CreateBillingPortalSessionFailed-sub-title",
    },
    "error-messages.Idempotency_IdempotencyOperationDuplicated": {
      id: "error-messages.Idempotency_IdempotencyOperationDuplicated",
      defaultMessage: "Idempotency_IdempotencyOperationDuplicated",
    },
    "error-messages.Idempotency_IdempotencyOperationDuplicated-sub-title": {
      id: "error-messages.Idempotency_IdempotencyOperationDuplicated-sub-title",
      defaultMessage: "Idempotency_IdempotencyOperationDuplicated-sub-title",
    },
  });
} catch (e) {

}
