import * as React from "react";
import { AlphabeticCharacter } from "features/orders/components/letter-indicator/letter.model";
import classNames from "classnames";

import "./letter-indicator.scss";

export interface ILetterIndicatorProps extends React.HTMLAttributes<HTMLSpanElement> {
  letter: AlphabeticCharacter;
}

const LetterIndicator: React.FunctionComponent<ILetterIndicatorProps> = (props) => {
  const { letter, className, ...rest } = props;

  return (
    <span {...rest} className={classNames("letter-indicator", className)}>
      {letter}
    </span>
  );
};

export default LetterIndicator;
