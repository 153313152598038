import React from "react";
import { IOrderCategory } from "features/catalogue/category.model";
import { IOrderType } from "features/catalogue/order-type.model";
import { NodeType } from "features/catalogue/nodeType";

export enum ActiveStatus {
  Active = "active",
  NotActive = "not-active",
}

// todo: remove
// export type CatalogueItemBase = {
//   key: guid;
//   name: string;
//   active: ActiveStatus;
// };

export type CategoryNode = Pick<IOrderCategory, "id" | "name" | "code"> & {
  type: NodeType.Category;
  children: (OrderTypeNode | CatalogueAddNewButtonNode)[];
};

export type OrderTypeNode = Pick<IOrderType, "id" | "name" | "code" | "requiredSpecification"> & {
  type: NodeType.OrderType;
  active: ActiveStatus;
};

export type CatalogueAddNewButtonNode = {
  id: string;
  categoryId: IOrderCategory["id"],
  categoryCode: IOrderCategory["code"],
  name: string;
  type: NodeType.AddNew;
};

export type OrderCatalogueNode = OrderTypeNode | CategoryNode | CatalogueAddNewButtonNode;
export type PricingListNodes = OrderTypeNode | CategoryNode;
