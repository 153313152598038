import { Option } from "./option";
import { Ok } from "./ok";
import { IResult } from "./result";
import { None } from "./none";

export class Some<T> implements Option<T> {
  private value: T;

  constructor(v: T) {
    this.value = v;
  }

  static wrapNull<T>(value: T): Option<T> {
    if (value == null) {
      return new None<T>();
    } else {
      return new Some<T>(value);
    }
  }
  map<U>(fn: (a: T) => U): Option<U> {
    return new Some(fn(this.value));
  }
  isSome(): boolean {
    return true;
  }
  isNone(): boolean {
    return false;
  }
  isSomeAnd(fn: (a: T) => boolean): boolean {
    return fn(this.value);
  }
  isNoneAnd(fn: () => boolean): boolean {
    return false;
  }
  unwrap(): T {
    return this.value;
  }
  unwrapOr(def: T): T {
    return this.value;
  }
  unwrapOrElse(f: () => T): T {
    return this.value;
  }
  mapOr<U>(def: U, f: (t: T) => U): U {
    return f(this.value);
  }
  mapOrElse<U>(def: () => U, f: (t: T) => U): U {
    return f(this.value);
  }
  okOr<E>(err: E): IResult<T, E> {
    return new Ok<T, E>(this.value);
  }
  okOrElse<E>(err: () => E): IResult<T, E> {
    return new Ok<T, E>(this.value);
  }
  and<U>(optb: Option<U>): Option<U> {
    return optb;
  }
  andThen<U>(f: (a: T) => Option<U>): Option<U> {
    return f(this.value);
  }
  or(optb: Option<T>): Option<T> {
    return this;
  }
  orElse(f: () => Option<T>): Option<T> {
    return this;
  }
  toString(): string {
    return "Some " + this.value;
  }
}
