import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Arch } from "core/tooth/tooth.model";
import { Money } from "core/money/money.functions";
import { PointArea } from "core/point/point.enums";
import { AnchorType } from "features/orders/order-type/anchor-type.models";
import PricingTemplate from "../pricing-entry-template/PricingTemplate";
import { Pricing, PricingStrategy } from "core/pricing/pricing.model";

interface IPricingFormatterProps {
  pricing: Pricing;
}

const PricingFormatter: React.FunctionComponent<IPricingFormatterProps> = ({ pricing }) => {
  switch (pricing.strategy) {
    case PricingStrategy.NotSet: {
      return (
        <div className="flex">
          <FormattedMessage id={"pricing-formatter.not-set"} defaultMessage="Brak ceny" />
        </div>
      );
    }
    case PricingStrategy.Individual: {
      return (
        <div className="flex">
          <FormattedMessage id={"pricing-formatter.individual-pricing"} defaultMessage="Wycena indiwidualna" />
        </div>
      );
    }
    case PricingStrategy.Fixed: {
      const { fixedPrice } = pricing;

      return (
        <div className="flex">
          <PricingTemplate
            pricingEntries={[
              {
                label: <FormattedMessage id={"pricing-formatter.fixed-price"} defaultMessage="Stała cena" />,
                price: fixedPrice,
              },
            ]}
          />
        </div>
      );
    }
    case PricingStrategy.PerQuantity: {
      const { pricePerItem } = pricing;

      return (
        <div className="flex">
          <PricingTemplate
            pricingEntries={[
              {
                label: <FormattedMessage id={"pricing-formatter.price-for-one-item"} defaultMessage="Cena za 1 szt" />,
                price: pricePerItem,
              },
            ]}
          />
        </div>
      );
    }
    case PricingStrategy.PerArch: {
      const { [Arch.UpperArch]: upper, [Arch.LowerArch]: lower } = pricing.pricesPerArch;

      if (Money.isEqual(upper, lower)) {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.single-arch-price"} defaultMessage="Jeden łuk" />,
                  price: upper,
                },
              ]}
            />
          </div>
        );
      }

      return (
        <div className="flex">
          <PricingTemplate
            pricingEntries={[
              {
                label: <FormattedMessage id={"pricing-formatter.upper-arch-price"} defaultMessage="Górny łuk" />,
                price: upper,
              },
              {
                label: <FormattedMessage id={"pricing-formatter.lower-arch-price"} defaultMessage="Dolny łuk" />,
                price: lower,
              },
            ]}
          />
        </div>
      );
    }
    case PricingStrategy.PerPoint: {
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerPoint;

      if (Money.isEqual(front, back)) {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.single-point-price"} defaultMessage="Cena za jeden punkt" />,
                  price: front,
                },
              ]}
            />
          </div>
        );
      } else {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.front-point-price"} defaultMessage="Jeden punkt z przodu" />,
                  price: front,
                },
                {
                  label: <FormattedMessage id={"pricing-formatter.back-point-price"} defaultMessage="Jeden punkt z tyłu" />,
                  price: back,
                },
              ]}
            />
          </div>
        );
      }
    }
    case PricingStrategy.PerPointWithArchBasePrice: {
      const { pricePerPoint, basePriceForArch } = pricing;

      return (
        <div className="flex">
          <PricingTemplate
            pricingEntries={[
              {
                label: <FormattedMessage id={"pricing-formatter.base-price-per-arch"} defaultMessage="Cena bazowa" />,
                price: basePriceForArch,
              },
              {
                label: <FormattedMessage id={"pricing-formatter.single-point-price"} defaultMessage="Cena za jeden punkt" />,
                price: pricePerPoint,
              },
            ]}
          />
        </div>
      );
    }
    case PricingStrategy.PerCrown: {
      const defaultAnchorType = AnchorType.CementedToPreparedTooth;
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerCrown;

      const allFrontCrownsEqualInPriceWithBackCrowns =
        Money.isEqual(front[AnchorType.CementedToImplant], back[AnchorType.CementedToImplant]) &&
        Money.isEqual(front[AnchorType.ScrewedInToImplant], back[AnchorType.ScrewedInToImplant]) &&
        Money.isEqual(front[AnchorType.CementedToPreparedTooth], back[AnchorType.CementedToPreparedTooth]);

      if (allFrontCrownsEqualInPriceWithBackCrowns) {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.all-crown-prices-are-equal"} defaultMessage="Jedna korona" />,
                  price: front[defaultAnchorType],
                  subEntries: [
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.single-crown-cemented-to-implant"}
                          defaultMessage="Korona cementowana na implancie"
                        />
                      ),
                      price: front[AnchorType.CementedToImplant],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.single-crown-screwed-to-implant"}
                          defaultMessage="Korona przykręcana na implancie"
                        />
                      ),
                      price: front[AnchorType.ScrewedInToImplant],
                    },
                  ],
                },
              ]}
            />
          </div>
        );
      } else {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.front-crown"} defaultMessage="Jedna korona z przodu" />,
                  price: front[defaultAnchorType],
                  subEntries: [
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.front-crown-cemented-to-implant"}
                          defaultMessage="Korona cementowana na implancie"
                        />
                      ),
                      price: front[AnchorType.CementedToImplant],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.front-crown-screwed-to-implant"}
                          defaultMessage="Korona przykręcana na implancie"
                        />
                      ),
                      price: front[AnchorType.ScrewedInToImplant],
                    },
                  ],
                },
                {
                  label: <FormattedMessage id={"pricing-formatter.back-crown"} defaultMessage="Jedna korona z tyłu" />,
                  price: back[defaultAnchorType],
                  subEntries: [
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.back-crown-cemented-to-implant"}
                          defaultMessage="Korona cementowana na implancie"
                        />
                      ),
                      price: back[AnchorType.CementedToImplant],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.back-crown-screwed-to-implant"}
                          defaultMessage="Korona przykręcana na implancie"
                        />
                      ),
                      price: back[AnchorType.ScrewedInToImplant],
                    },
                  ],
                },
              ]}
            />
          </div>
        );
      }
    }
    case PricingStrategy.PerBridgePoint: {
      const defaultAnchorType = AnchorType.CementedToPreparedTooth;
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerBridgePoint;

      const allFrontBridgePointsEqualInPriceWithBackBridgePoints =
        Money.isEqual(front[AnchorType.CementedToImplant], back[AnchorType.CementedToImplant]) &&
        Money.isEqual(front[AnchorType.ScrewedInToImplant], back[AnchorType.ScrewedInToImplant]) &&
        Money.isEqual(front[AnchorType.CementedToPreparedTooth], back[AnchorType.CementedToPreparedTooth]) &&
        Money.isEqual(front[AnchorType.Pontic], back[AnchorType.Pontic]);

      if (allFrontBridgePointsEqualInPriceWithBackBridgePoints) {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: (
                    <FormattedMessage id={"pricing-formatter.all-bridge-point-prices-are-equal"} defaultMessage="Jedna korona w moście" />
                  ),
                  price: front[defaultAnchorType],
                  subEntries: [
                    {
                      label: <FormattedMessage id={"pricing-formatter.bridge-point-screwed-to-implant"} defaultMessage="Przęsło" />,
                      price: front[AnchorType.Pontic],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.single-bridge-point-cemented-to-implant"}
                          defaultMessage="Korona w moście cementowana na implancie"
                        />
                      ),
                      price: front[AnchorType.CementedToImplant],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.single-bridge-point-screwed-to-implant"}
                          defaultMessage="Korona w moście przykręcana na implancie"
                        />
                      ),
                      price: front[AnchorType.ScrewedInToImplant],
                    },
                  ],
                },
              ]}
            />
          </div>
        );
      } else {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.front-bridge-point"} defaultMessage="Jedna korona w moście z przodu" />,
                  price: front[defaultAnchorType],
                  subEntries: [
                    {
                      label: <FormattedMessage id={"pricing-formatter.bridge-point-screwed-to-implant"} defaultMessage="Przęsło" />,
                      price: front[AnchorType.Pontic],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.front-bridge-point-cemented-to-implant"}
                          defaultMessage="Korona w moście cementowana na implancie"
                        />
                      ),
                      price: front[AnchorType.CementedToImplant],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.front-bridge-point-screwed-to-implant"}
                          defaultMessage="Korona w moście przykręcana na implancie"
                        />
                      ),
                      price: front[AnchorType.ScrewedInToImplant],
                    },
                  ],
                },
                {
                  label: <FormattedMessage id={"pricing-formatter.back-bridge-point"} defaultMessage="Jedna korona w moście z tyłu" />,
                  price: back[defaultAnchorType],
                  subEntries: [
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.back-bridge-point-cemented-to-implant"}
                          defaultMessage="Korona w moście cementowana na implancie"
                        />
                      ),
                      price: back[AnchorType.CementedToImplant],
                    },
                    {
                      label: (
                        <FormattedMessage
                          id={"pricing-formatter.back-bridge-point-screwed-to-implant"}
                          defaultMessage="Korona w moście przykręcana na implancie"
                        />
                      ),
                      price: back[AnchorType.ScrewedInToImplant],
                    },
                    {
                      label: <FormattedMessage id={"pricing-formatter.bridge-point-screwed-to-implant"} defaultMessage="Przęsło" />,
                      price: back[AnchorType.Pontic],
                    },
                  ],
                },
              ]}
            />
          </div>
        );
      }
    }
    case PricingStrategy.PerMarylandBridgePoint: {
      const defaultAnchorType = AnchorType.CementedToPreparedTooth;
      const { [PointArea.Front]: front, [PointArea.Back]: back } = pricing.pricesPerMarylandBridgePoint;

      const allFrontPointsEqualInPriceWithBackPoints =
        Money.isEqual(front[AnchorType.CementedToPreparedTooth], back[AnchorType.CementedToPreparedTooth]) &&
        Money.isEqual(front[AnchorType.Pontic], back[AnchorType.Pontic]);

      if (allFrontPointsEqualInPriceWithBackPoints) {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: (
                    <FormattedMessage id={"pricing-formatter.all-bridge-point-prices-are-equal"} defaultMessage="Jedna korona w moście" />
                  ),
                  price: front[defaultAnchorType],
                  subEntries: [
                    {
                      label: <FormattedMessage id={"pricing-formatter.bridge-point-screwed-to-implant"} defaultMessage="Przęsło" />,
                      price: front[AnchorType.Pontic],
                    },
                  ],
                },
              ]}
            />
          </div>
        );
      } else {
        return (
          <div className="flex">
            <PricingTemplate
              pricingEntries={[
                {
                  label: <FormattedMessage id={"pricing-formatter.front-bridge-point"} defaultMessage="Jedna korona w moście z przodu" />,
                  price: front[defaultAnchorType],
                  subEntries: [
                    {
                      label: (
                        <FormattedMessage id={"pricing-formatter.bridge-point-screwed-to-implant-front"} defaultMessage="Przęsło z przodu" />
                      ),
                      price: front[AnchorType.Pontic],
                    },
                  ],
                },
                {
                  label: <FormattedMessage id={"pricing-formatter.back-bridge-point"} defaultMessage="Jedna korona w moście z tyłu" />,
                  price: back[defaultAnchorType],
                  subEntries: [
                    {
                      label: <FormattedMessage id={"pricing-formatter.bridge-point-screwed-to-implant-back"} defaultMessage="Przęsło z tyłu" />,
                      price: back[AnchorType.Pontic],
                    },
                  ],
                },
              ]}
            />
          </div>
        );
      }
    }
    case PricingStrategy.PerFoundationPoint: {
      const {
        [AnchorType.Pontic]: pontic,
        [AnchorType.CementedToImplant]: cementedToImplant,
        [AnchorType.ScrewedInToImplant]: screwedInToImplant,
      } = pricing.pricesPerFoundationPoint;

      return (
        <div className="flex">
          <PricingTemplate
            pricingEntries={[
              {
                label: (
                  <FormattedMessage id={"pricing-formatter.foundation-point-prices-are-equal"} defaultMessage="Jeden punkt podbudowy" />
                ),
                price: pontic,
                subEntries: [
                  {
                    label: (
                      <FormattedMessage
                        id={"pricing-formatter.foundation-point-screwed-to-implant"}
                        defaultMessage="Punkt podbudowy przykręcany"
                      />
                    ),
                    price: screwedInToImplant,
                  },
                  {
                    label: (
                      <FormattedMessage
                        id={"pricing-formatter.foundation-point-cemented-to-implant"}
                        defaultMessage="Punkt podbudowy cementowany"
                      />
                    ),
                    price: cementedToImplant,
                  },
                ],
              },
            ]}
          />
        </div>
      );
    }
  }
};

export default PricingFormatter;
