import { Alert } from "antd";
import { FormattedMessage } from "react-intl";
import * as React from "react";

export const EmptySpecificationAlert = () => (
  <Alert
    className={"mt-2"}
    message={<FormattedMessage id={"order-form.order-specification-is-empty"} defaultMessage={"Brak wyboru"} />}
    type={"error"}
  />
);