import * as React from "react";
import { notification } from "antd";
import NotificationMessageContext from "./NotificationMessageContext";

notification.config({
  placement: "topRight",
  top: 75,
})

const LocalizedNotificationMessages: React.FunctionComponent<{}> = (props) => {
  const [notificationApi, notificationsContext] = notification.useNotification();

  return (
    <NotificationMessageContext.Provider value={{ notificationApi }}>
      <>{notificationsContext}</>
      <>{props.children}</>
    </NotificationMessageContext.Provider>
  );
};

export default LocalizedNotificationMessages;
