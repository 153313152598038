import { IValueObject } from "core/value-object/value-object";
import { IOrderTypeId } from "features/catalogue/order-type.model";
import { Pricing } from "core/pricing/pricing.model";
import { IDentist } from "features/dentist/dentist.model";

export interface IPricingListId extends IValueObject<string> {
  type: "pricing-list-id";
}

export const NewPricingListId = (id: string): IPricingListId => ({ type: "pricing-list-id", value: id });

export interface IShortPricingList {
  id: IPricingListId;
  entries: IPricingEntry[];
}

export interface IPricingList extends IShortPricingList {
  name: string;
  isDefault: boolean;
  dentists: Pick<IDentist, "id" | "name" | "clinicName">[];
}

export interface IPricingEntry {
  orderTypeId: IOrderTypeId;
  pricing: Pricing;
}

export interface ICreatePricingListRequest extends Pick<IPricingList, "name"> {}
export interface IEditPricingListRequest extends Pick<IPricingList, "id" | "name"> {}
export interface IDeletePricingListRequest extends Pick<IPricingList, "id"> {}
export interface IUpdatePricingEntryRequest {
  pricingListId: IPricingListId;
  orderTypeId: IOrderTypeId;
  pricing: Pricing;
}
