import * as React from "react";
import { Reducer, useReducer, useRef } from "react";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import { IResult } from "core/lib/types/result";
import { IPricingList, IPricingListId, IUpdatePricingEntryRequest } from "features/pricing-lists/pricing-list.models";

import { Pricing, PricingStrategy } from "core/pricing/pricing.model";
import { IOrderTypeId } from "features/catalogue/order-type.model";
import { SpecificationType } from "features/orders/specification/order-specification";
import PriceEntryModalForm from "features/catalogue/prices/components/price-entry-modal/PriceEntryModal";
import useSize from "hooks/useSize";
import { OrderCodeEnum } from "features/catalogue/order-code.enum";
import { IOrderCatalogue } from "features/catalogue/category.model";
import {
  ActiveStatus,
  CategoryNode,
  OrderTypeNode,
  PricingListNodes,
} from "features/catalogue/order-catalogue-nodes.models";

import "./pricing-list-table.scss";
import CategoryName from "components/formatters/category/CategoryName";
import { FormattedMessage } from "react-intl";
import { NodeType } from "features/catalogue/nodeType";
import { isNumber } from "core/utils/utils";
import OrderCodeName from "components/formatters/order-code-name/OrderCodeName";
import SpecificationFormatter from "components/formatters/specification/SpecificationFormatter";
import PricingFormatter from "features/catalogue/prices/components/pricing-formatter/PricingFormatter";
import Button from "components/button/Button";
import { EditOutlined } from "@ant-design/icons";
import classNames from "classnames";

type PricingListTableEntry = Pick<IPricingList, "id" | "name" | "entries">;

interface IPricingListTableProps {
  orderCatalogue: IOrderCatalogue;
  pricingList: PricingListTableEntry;
  mainPricingList?: PricingListTableEntry;
  isDefaultPricingList?: boolean;
  readonly?: boolean;
  onUpdatePricing?: (updatePricing: IUpdatePricingEntryRequest) => Promise<IResult<void>>;
}

const dataIndexes = {
  name: "name",
  pricing: "pricing",
  requiredSpecification: "requiredSpecification",
};

enum Size {
  Small = "sm",
  Medium = "md",
  Large = "lg",
}

type CreatePricingEntry = {
  pricingListId: IPricingListId;
  orderType: {
    orderTypeId: IOrderTypeId;
    orderCode: OrderCodeEnum | null;
    name: string;
    requiredSpecification: SpecificationType;
  };
};

type EditPricingEntry = {
  pricingListId: IPricingListId;
  previousPricing: Pricing;
  orderType: {
    orderTypeId: IOrderTypeId;
    orderCode: OrderCodeEnum | null;
    name: string;
    requiredSpecification: SpecificationType;
  };
};

type State =
  | {
      isModalVisible: true;
      modalType: "create";
      modalParams: CreatePricingEntry;
    }
  | {
      isModalVisible: true;
      modalType: "edit";
      modalParams: EditPricingEntry;
    }
  | {
      isModalVisible: false;
      modalType: null;
      modalParams: null;
    };

type Action =
  | { type: "showCreateModal"; modalProps: CreatePricingEntry }
  | { type: "showEditModal"; modalProps: EditPricingEntry }
  | { type: "hideModal" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "showCreateModal":
      return { ...state, isModalVisible: true, modalType: "create", modalParams: action.modalProps };
    case "showEditModal":
      return { ...state, isModalVisible: true, modalType: "edit", modalParams: action.modalProps };
    case "hideModal":
      return { ...state, isModalVisible: false, modalType: null, modalParams: null };
  }
};

const PricingListTable: React.FunctionComponent<IPricingListTableProps> = ({
  orderCatalogue,
  pricingList,
  mainPricingList,
  isDefaultPricingList = false,
  readonly = false,
  onUpdatePricing,
}) => {
  const ref = useRef(null);
  const tableRef = useRef<ActionType>();

  const [size] = useSize(ref, {
    [Size.Small]: 0,
    [Size.Medium]: 800,
    [Size.Large]: 1100,
  });

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    isModalVisible: false,
    modalType: null,
    modalParams: null,
  });

  const handleSubmit = React.useCallback(
    async (pricing: Pricing, orderTypeId: IOrderTypeId) => {
      if (onUpdatePricing === undefined) {
        return;
      }

      const result = await onUpdatePricing({
        pricing,
        pricingListId: pricingList.id,
        orderTypeId,
      });

      if (result.isOk()) {
        dispatch({ type: "hideModal" });
      }
    },
    [onUpdatePricing, pricingList.id]
  );

  const mapOrderCatalogueToOrderCatalogueNodes = React.useCallback(
    (orderCatalogue: IOrderCatalogue): PricingListNodes[] => {
      return orderCatalogue.categories.map((category) => {
        const orderTypeNodes = category.orderTypes.map((orderType) => {
          const orderTypeNode: OrderTypeNode = {
            id: orderType.id,
            name: orderType.name,
            type: NodeType.OrderType,
            code: orderType.code,
            requiredSpecification: orderType.requiredSpecification,
            active: orderType.isActive ? ActiveStatus.Active : ActiveStatus.NotActive,
          };

          return orderTypeNode;
        });

        const categoryNode: CategoryNode = {
          id: category.id,
          name: category.name,
          code: category.code,
          type: NodeType.Category,
          children: orderTypeNodes.filter((n) => n.active === ActiveStatus.Active),
        };

        return categoryNode;
      });
    },
    []
  );

  const columns: ProColumns<PricingListNodes>[] = [
    {
      key: "name",
      title: <FormattedMessage id={"order-catalogue-table.order-name"} defaultMessage={"Nazwa zamówienia"} />,
      dataIndex: dataIndexes.name,
      render: (dom, record) => {
        if (record.type === NodeType.Category) {
          return isNumber(record.code) ? <CategoryName code={record.code} /> : <>{record.name}</>;
        }

        if (record.type === NodeType.OrderType) {
          return isNumber(record.code) ? (
            <span className={"flex"}>
              <OrderCodeName code={record.code} />
            </span>
          ) : (
            <span className={"flex"}>
              <>{record.name}</>
            </span>
          );
        }

        return (<></>) as any;
      },
    },
    ...(size === Size.Small
      ? []
      : [
          {
            key: "requiredSpecification",
            dataIndex: dataIndexes.requiredSpecification,
            title: <FormattedMessage id="catalogue.required-specification" defaultMessage="Typ zamówienia" />,
            editable: () => true,
            renderFormItem: (_, { record }) => {
              if (record === undefined || record.type === NodeType.Category) {
                return <></>;
              }

              return <SpecificationFormatter specification={record.requiredSpecification} withExplanation={true} />;
            },
            render: (_, record) => {
              if (record === undefined || record.type === NodeType.Category) {
                return <></>;
              }

              return <SpecificationFormatter specification={record.requiredSpecification} withExplanation={true} />;
            },
          } as ProColumns<PricingListNodes>,
        ]),
    ...(isDefaultPricingList
      ? [
          {
            key: "mainPricing",
            title: <FormattedMessage id="common.price" defaultMessage="Cena" />,
            render: (text, record, _) => {
              if (record.type !== NodeType.OrderType) {
                return (<></>) as any;
              }

              const pricingEntry = pricingList.entries.find((pe) => pe.orderTypeId.value === record.id.value);

              if (pricingEntry === undefined) {
                return (
                  <span className={"flex flex-row items-center"}>
                    <PricingFormatter pricing={{ strategy: PricingStrategy.NotSet }} />
                  </span>
                );
              }

              return (
                <div className={"flex flex-row items-center"}>
                  <PricingFormatter pricing={pricingEntry.pricing} />
                </div>
              );
            },
          } as ProColumns<PricingListNodes>,
        ]
      : [
          {
            key: "mainPricing",
            title: <FormattedMessage id="common.price-in-main-price-list" defaultMessage="Cena w cenniku głównym" />,
            render: (text, record, _) => {
              if (record.type !== NodeType.OrderType) {
                return (<></>) as any;
              }

              if (mainPricingList === undefined) {
                return (<></>) as any;
              }

              const mainPricingEntry = mainPricingList.entries.find((pe) => pe.orderTypeId.value === record.id.value);

              if (mainPricingEntry === undefined) {
                return (
                  <span className={"flex flex-row items-center"}>
                    <PricingFormatter pricing={{ strategy: PricingStrategy.NotSet }} />
                  </span>
                );
              }

              return (
                <div className={"flex flex-row items-center"}>
                  <PricingFormatter pricing={mainPricingEntry.pricing} />
                </div>
              );
            },
          } as ProColumns<PricingListNodes>,
          {
            key: "pricing",
            title: <FormattedMessage id="common.price" defaultMessage="Cena" />,
            render: (text, record, _) => {
              if (record.type !== NodeType.OrderType) {
                return (<></>) as any;
              }

              const pricingEntry = pricingList.entries.find((pe) => pe.orderTypeId.value === record.id.value);

              if (pricingEntry === undefined) {
                return (
                  <span className={"flex flex-row items-center"}>
                    <PricingFormatter pricing={{ strategy: PricingStrategy.NotSet }} />
                  </span>
                );
              }

              return (
                <div className={"flex flex-row items-center"}>
                  <PricingFormatter pricing={pricingEntry.pricing} />
                </div>
              );
            },
          } as ProColumns<PricingListNodes>,
        ]),
    ...(!readonly
      ? [
          {
            title: <FormattedMessage id="common.option" defaultMessage="Opcje" />,
            valueType: "option",
            render: (text, record) => {
              if (record.type !== NodeType.OrderType) {
                return (<></>) as any;
              }

              if (onUpdatePricing === undefined) {
                return (<></>) as any;
              }

              const pricingEntry = pricingList.entries.find((pe) => pe.orderTypeId.value === record.id.value);

              if (pricingEntry === undefined) {
                return (
                  <Button
                    onClick={() => {
                      dispatch({
                        type: "showCreateModal",
                        modalProps: {
                          pricingListId: pricingList.id,
                          orderType: {
                            orderTypeId: record.id,
                            name: record.name,
                            orderCode: record.code,
                            requiredSpecification: record.requiredSpecification,
                          },
                        },
                      });
                    }}
                    type={"ghost"}
                  >
                    Ustaw cenę
                  </Button>
                );
              } else {
                return (
                  <Button
                    onClick={() => {
                      dispatch({
                        type: "showEditModal",
                        modalProps: {
                          pricingListId: pricingList.id,
                          previousPricing: pricingEntry.pricing,
                          orderType: {
                            orderTypeId: record.id,
                            name: record.name,
                            orderCode: record.code,
                            requiredSpecification: record.requiredSpecification,
                          },
                        },
                      });
                    }}
                  >
                    <EditOutlined className="mr-2" />
                    {"Zmień cenę"}
                  </Button>
                );
              }
            },
          } as ProColumns<PricingListNodes>,
        ]
      : []),
  ];

  return (
    <div ref={ref as any}>
      {state.isModalVisible && (
        <PriceEntryModalForm
          key={state.modalType === "edit" ? state.modalParams.pricingListId.value : "create-dentist"}
          visible={true}
          modalType={state.modalType === "edit" ? "edit" : "create"}
          values={
            state.modalType === "edit"
              ? { pricing: state.modalParams.previousPricing }
              : { pricing: { strategy: PricingStrategy.NotSet } }
          }
          orderType={state.modalParams.orderType}
          onSubmit={(pricing: Pricing) => handleSubmit(pricing, state.modalParams.orderType.orderTypeId)}
          onCancel={() => dispatch({ type: "hideModal" })}
        />
      )}
      <ProTable<PricingListNodes>
        actionRef={tableRef}
        headerTitle={pricingList.name}
        rowKey={(node) => node.id.value}
        search={false}
        options={{ setting: false }}
        style={{ padding: 0 }}
        scroll={{ x: true }}
        columns={columns}
        dataSource={mapOrderCatalogueToOrderCatalogueNodes(orderCatalogue)}
        expandable={{ expandRowByClick: true }}
        rowClassName={(record) => {
          if (record.type === NodeType.Category) {
            return "";
          }

          return classNames("pricing-table-row", {
            "pricing-table-row--remove-expand-button": record.type === NodeType.OrderType,
          });
        }}
        pagination={{
          pageSize: 200,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default PricingListTable;
