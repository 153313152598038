export enum OrderCodeEnum {
  GlassCeramicCrowns = 20100,
  FullContourGlassCeramicCrown = 20200,
  ZirconiumCrowns = 20300,
  FullContourZirconiumCrown = 20400,
  LaserSinteredCrCoCrowns = 20500,
  CrCoMilledCrowns = 20600,
  CrCoMoldedCrowns = 20700,
  CompositeCrowns = 20800,
  GlassCeramicBridges = 30100,
  FullContourGlassCeramicBridges = 30200,
  ZirconiumBridges = 30300,
  FullContourZirconiumBridges = 30400,
  LaserSinteredCrCoBridges = 30500,
  CrCoMilledBridges = 30600,
  CrCoMoldedBridges = 30700,
  CompositeBridges = 30800,
  GlassCeramicMarylandBridges = 40100,
  FullContourGlassCeramicMarylandBridges = 40200,
  ZirconiumMarylandBridges = 40300,
  FullContourZirconiumMarylandBridges = 40400,
  LaserSinteredCrCoMarylandBridges = 40500,
  CrCoMilledMarylandBridges = 40600,
  CrCoMoldedMarylandBridges = 40700,
  CompositeMarylandBridges = 40800,
  GlassCeramicVeneers = 50100,
  PlatinumFoilVeneers = 50200,
  FeldspaticVeneers = 50300,
  CompositeVeneers = 50400,
  ZirconiumFoundation = 60100,
  LaserSinteredCrCoFoundation = 60200,
  MilledCrCoFoundation = 60300,
  PeekFoundation = 60400,
  StandardImplantAbutment = 70100,
  IndividualTitanAbutment = 70200,
  IndividualHybridAbutment = 70300,
  StandardTemporaryAbutment = 70400,
  GlassCeramicInlay = 100100,
  CompositeInlay = 100200,
  GlassCeramicOnlay = 90100,
  CompositeOnlay = 90200,
  GlassCeramicOverlay = 110100,
  CompositeOverlay = 110200,
  GlassCeramicEndoCrown = 120100,
  CompositeEndoCrown = 120200,
  WaxUp = 130100,
  PrintedWaxUp = 130200,
  TemporaryPmmaCrowns = 140100,
  TemporaryPrintedCrowns = 140200,
  MilledTryInCrowns = 140300,
  PrintedTryInCrowns = 140400,
  TemporaryPmmaBridges = 140500,
  TemporaryPrintedBridges = 140600,
  MilledTryInBridges = 140700,
  PrintedTryInBridges = 140800,
  RelaxationSplint = 150100,
  MilledRelaxationSplint = 150200,
  PrintedRelaxationSplint = 150300,
  MichiganSplint = 150400,
  PrintedMichiganSplint = 150500,
  MilledMichiganSplint = 150600,
  KoisSplint = 150700,
  PrintedKoisSplint = 150800,
  WhiteningSplint = 150900,
  Mouthguard = 151000,
  GingivectomySurgicalGuide = 151100,
  ImplantSurgicalGuide = 151200,
  MilledCrCoPost = 160100,
  LaserSinteredCrCoPost = 160200,
  CrCoMoldedPost = 160300,
  ZirconiumPost = 160400,
  FiberglassPost = 160500,
  PeekPost = 160600,
  MilledCrCoPostMultiRooted = 160700,
  LaserSinteredCrCoPostMultiRooted = 160800,
  CrCoMoldedPostMultiRooted = 160900,
  ZirconiumPostMultiRooted = 161000,
  FiberglassPostMultiRooted = 161100,
  PeekPostMultiRooted = 161200,
  CeramicGingiva = 170100,
  CompositeGingiva = 170200,
  DigitalPrintOrientationModel = 180100,
  PlasterOrientationModel = 180200,
  ColorSelectionInLaboratory = 190100,
  ColorSelectionInClinic = 190200,
  PorcelainMargin = 210100,
  BiteRegistrationRims = 210200,
  CustomTray = 210300,
  SiliconeIndex = 210400,
  TransparentSiliconeIndex = 210500,
  ImplantAbutmentPlacementJig = 210600,
  PreparationCup = 210700,
  MetalReinforcement = 210800,
  FiberglassReinforcement = 210900,
  FullAcrylicDentures = 51100,
  FullAcetalDentures = 51200,
  FullAcronDentures = 51300,
  FullNylonDentures = 51400,
  PartialAcrylicDentures = 52100,
  PartialAcetalDentures = 52200,
  PartialAcronDentures = 52300,
  PartialNylonDentures = 52400,
  PrecisionAttachmentsPartialDenture = 53100,
  CrCoMetalFrameworkClaspsPartialDentures = 53200,
  TitaniumAloyFrameworkClaspsPartialDentures = 53300,
  PeekFrameworkClaspsPartialDentures = 53400,
  CrCoMetalFrameworkContinuousClaspsPartialDentures = 53500,
  TitaniumAloyFrameworkContinuousClaspsPartialDentures = 53600,
  PeekFrameworkContinuousClaspsPartialDentures = 53700,
  CrCoSplintDentures = 55100,
  TitaniumAloyCrCoSplintDentures = 55200,
  PeekCrCoSplintDentures = 55300,
  AcrylicOverdentures = 56100,
  InterlockAttachments = 57100,
  RodAttachments = 57200,
  StudAttachments = 57300,
  LatchAttachments = 57400,
  OcclusalShoulder = 57500,
  FracturedDentureService = 58100,
  DentureReinforcement = 58200,
}
