export const argumentOutOfRangeError = (message: string, argumentValue: any): Error => {
  return new Error(
    `argument out of range error | ${message} | argument value: ${argumentValue}`
  );
};

export const invalidArgumentError = (message: string): Error => {
  return new Error(`invalid argument | ${message}`);
};

export const invalidOperationError = (message: string): Error => {
  return new Error(`invalid operation | ${message}`);
};

export const typeError = (message: string): Error => {
  return new Error(`type error | ${message}`);
};

export const notImplementedException = (message?: string): Error => {
  return new Error(`not implemented exception | ${message}`);
};
