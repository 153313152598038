export const isNumber = (value: string | number): boolean => {
  return value != null && value !== "" && !isNaN(Number(value.toString()));
};

export const isNullOrEmpty = (value: string | null | undefined) => {
  if (value === null || value === undefined) {
    return true;
  }

  return typeof (value as any) === "string" && value.trim() === "";
};

export const normalizedFileName = (file: string) => {
  // change all characters that should not be in a file name to "_"
  // https://docs.microsoft.com/en-us/windows/win32/fileio/naming-a-file

  const invalidCharacters = [
    `<`, // (less than)
    `>`, // ' (greater than)
    `:`, // (colon)
    `"`, //  (double quote)
    `/`, // (forward slash)
    `\\`, // '(backslash)
    `|`, // (vertical bar or pipe)
    `?`, // (question mark)
    `*`, // (asterisk)
    `.`, // dot
  ];

  const invalidNames = [
    "CON",
    "PRN",
    "AUX",
    "NUL",
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
    "LPT1",
    "LPT2",
    "LPT3",
    "LPT4",
    "LPT5",
    "LPT6",
    "LPT7",
    "LPT8",
    "LPT9",
  ];

  let normalizedFileName = file;

  normalizedFileName = invalidNames.reduce((f, name) => {
    if (f === name) {
      return "file";
    }
    return f;
  }, normalizedFileName);

  normalizedFileName = invalidCharacters.reduce((f, char) => {
    return f.replace(char, "_");
  }, normalizedFileName);

  return normalizedFileName;
};
