import React from "react";
import moment from "moment";
import { DatePicker, Form } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";

export const DueDateControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={<FormattedMessage id="common.due-date" defaultMessage="Na kiedy" />}
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="dueDate"
      control={props.control}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <DatePicker
          {...fieldProps}
          value={value === undefined || value === null ? undefined : moment(value)}
          onChange={(date) => onChange(moment(date).toISOString())}
          showNow={false}
          inputReadOnly
          className="w-full max-w-lg"
          format="YYYY-MM-DD HH:mm"
          showTime={{
            defaultValue: moment().minutes(0).seconds(0),
            showHour: true,
            hideDisabledOptions: true,
            disabledHours: () => [0, 1, 2, 3, 4, 5, 7, 9, 11, 13, 15, 17, 19, 21, 22, 23],
            showMinute: false,
            showSecond: false,
          }}
        />
      )}
    />
  </Form.Item>
);
