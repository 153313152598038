import * as React from "react";
import { Country } from "core/countries/countries.model";
import { useIntl } from "react-intl";

interface ICountryNameProps {
  country: Country;
}

export const mapCountryToCountryName = (country: Country) => {
  switch (country) {
    case Country.Austria:
      return "Austria";
    case Country.Belgium:
      return "Belgia";
    case Country.Bulgaria:
      return "Bułgaria";
    case Country.Cyprus:
      return "Cypr";
    case Country.CzechRepublic:
      return "Czechy";
    case Country.Germany:
      return "Niemcy";
    case Country.Denmark:
      return "Dania";
    case Country.Estonia:
      return "Estonia";
    case Country.Spain:
      return "Hiszpania";
    case Country.Finland:
      return "Finlandia";
    case Country.France:
      return "Francja";
    case Country.UnitedKingdom:
      return "Wielka Brytania";
    case Country.Greece:
      return "Grecja";
    case Country.Hungary:
      return "Węgry";
    case Country.Croatia:
      return "Chorwacja";
    case Country.Ireland:
      return "Irlandia";
    case Country.Italy:
      return "Włochy";
    case Country.Lithuania:
      return "Litwa";
    case Country.Luxembourg:
      return "Luxemburg";
    case Country.Latvia:
      return "Łotwa";
    case Country.Malta:
      return "Malta";
    case Country.Netherlands:
      return "Holandia";
    case Country.Poland:
      return "Polska";
    case Country.Portugal:
      return "Portugalia";
    case Country.Romania:
      return "Rumunia";
    case Country.Sweden:
      return "Szwecja";
    case Country.Slovenia:
      return "Słowenia";
    case Country.Slovakia:
      return "Słowacja";
  }
};

const CountryName: React.FunctionComponent<ICountryNameProps> = ({ country }) => {
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage({
        id: `country-name.${country}`,
        defaultMessage: `${mapCountryToCountryName(country)}`,
      })}
    </>
  );
};

export default CountryName;
