import * as React from "react";
import {
  isEmptySpecification,
  isSpecificationWithLinkedPoints,
  isSuitableForGraphicalPoints,
  mapSpecificationToPointsSelection,
} from "../../specification/order-specification.functions";
import { Tabs } from "antd";
import { IOrderEntry } from "../order-entry/order-entry.model";
import { mapSingleAndLinkedPointsToPointsSelectionState } from "../points-picker/selections/points-selection/point-selection.functions";
import OrderCodeName from "../../../../components/formatters/order-code-name/OrderCodeName";
import PointsView, { PointsViewPoints } from "../points-picker/points-view/PointsView";
import { FormattedMessage } from "react-intl";
import { PointId } from "core/point/point.enums";
import { PartialRecord } from "core/utils/type";
import { IAnchoredPoint } from "../points-picker/points-picker.model";
import { allLetters, AlphabeticCharacter } from "features/orders/components/letter-indicator/letter.model";
import LetterIndicator from "features/orders/components/letter-indicator/LetterIndicator";

type UnletteredOrderEntry = Pick<IOrderEntry, "id" | "orderType" | "specification">;

type LetteredOrderEntry = UnletteredOrderEntry & { letter: AlphabeticCharacter };

interface IOrderCanvas {
  orderEntries: UnletteredOrderEntry[];
}

interface ICombinedPointsViewProps {
  orderEntries: LetteredOrderEntry[];
}

interface ITab {
  key: string;
  orderEntry: LetteredOrderEntry;
}

const CombinedPointsView: React.FunctionComponent<ICombinedPointsViewProps> = ({ orderEntries }) => {
  const state = orderEntries.reduce(
    (acc, orderEntry) => {
      const letter = orderEntry.letter;
      const orderEntryPoints = mapSpecificationToPointsSelection(orderEntry.specification);
      const orderEntryPointsView = mapSingleAndLinkedPointsToPointsSelectionState(orderEntryPoints);

      const { points: allPoints, labels: allLabels } = acc;

      const singles: IAnchoredPoint[] = orderEntryPoints.singles;
      const linked: IAnchoredPoint[] = orderEntryPoints.linked.flat(1);

      const orderEntryLabels = [...singles, ...linked].reduce((acc, point) => {
        const prevLabels = allLabels[point.location];
        return { ...acc, [point.location]: prevLabels === undefined ? [letter] : [...prevLabels, letter] };
      }, {} as PartialRecord<PointId, string[]>);

      for (let [key, value] of Object.entries(orderEntryPointsView.points)) {
        const pointId = key as any as PointId;

        if (!value.selected) {
          continue;
        }

        allPoints[pointId] = { selected: true };
      }

      return {
        ...acc,
        points: {
          ...allPoints,
        },
        labels: {
          ...allLabels,
          ...orderEntryLabels,
        },
      };
    },
    { points: {}, labels: {} } as { points: PointsViewPoints; labels: PartialRecord<PointId, string[]> }
  );

  return <PointsView labelOptions={{ visible: true }} readonly={true} labels={state.labels} points={state.points} />;
};

const OrderCanvas: React.FunctionComponent<IOrderCanvas> = ({ orderEntries }) => {
  const orderEntriesWithLetters: LetteredOrderEntry[] = orderEntries.map((entry, idx) => ({
    ...entry,
    letter: allLetters[idx],
  }));

  const validEntries = orderEntriesWithLetters
    .filter((entry) => isSuitableForGraphicalPoints(entry.specification.specificationType))
    .filter((entry) => !isEmptySpecification(entry.specification));

  const tabs = validEntries.map((entry) => {
    const tab: ITab = {
      key: entry.id,
      orderEntry: entry,
    };

    return tab;
  });

  const showCombined = tabs.length >= 2;
  const showEmpty = validEntries.length === 0;

  if (showEmpty) {
    return (
      <PointsView
        dotOptions={{
          visible: false,
          editable: false,
        }}
        anchorOptions={{ visible: true, editable: false, allowedAnchors: [] }}
        readonly={true}
        points={{}}
      />
    );
  }

  return (
    <Tabs defaultActiveKey="1" centered>
      {showCombined && (
        <Tabs.TabPane
          tab={<FormattedMessage id={"order-canvas.combined-order"} defaultMessage={"Wszystko"} />}
          key={"combined"}
        >
          <CombinedPointsView orderEntries={validEntries} />
        </Tabs.TabPane>
      )}
      {tabs.map((tab) => {
        const { orderEntry, key } = tab;

        const tabName =
          orderEntry.orderType.code === null ? (
            orderEntry.orderType.name
          ) : (
            <OrderCodeName code={orderEntry.orderType.code} />
          );

        const combinedTabName = (
          <span>
            {showCombined && <LetterIndicator letter={orderEntry.letter} className={"mr-2"} />}
            {tabName}
          </span>
        );

        const points = mapSpecificationToPointsSelection(orderEntry.specification);
        const pointsView = mapSingleAndLinkedPointsToPointsSelectionState(points);

        return (
          <Tabs.TabPane tab={combinedTabName} key={key}>
            <PointsView
              dotOptions={{
                visible: isSpecificationWithLinkedPoints(orderEntry.specification.specificationType),
                editable: false,
              }}
              anchorOptions={{ visible: true, editable: false, allowedAnchors: [] }}
              readonly={true}
              {...pointsView}
            />
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
};

export default OrderCanvas;
