import { IValueObject } from "core/value-object/value-object";
import { IPersonName } from "core/person-name/person-name";
import { IBillingInfo } from "core/billing-info/billing-info";
import { IPricingListId } from "features/pricing-lists/pricing-list.models";

export interface IDentistId extends IValueObject<string> {
  type: "dentist-id";
}

export const NewDentistId = (id: string): IDentistId => ({ type: "dentist-id", value: id });

export interface IShortDentist {
  id: IDentistId;
  name: IPersonName;
  clinicName: string | null;
}

export interface IDentist extends IShortDentist {
  id: IDentistId;
  name: IPersonName;
  clinicName: string | null;
  pricingListId: IPricingListId;
}

export interface IDentistBillingInfo {
  defaultBillingInfo: IBillingInfo | null;
  localBillingInfos: IBillingInfo[];
  remoteBillingInfos: IBillingInfo[];
}

export interface ICreateDentistRequest extends Omit<IDentist, "id" | "pricingListId"> {}
export interface IEditDentistRequest extends Omit<IDentist, "pricingListId"> {}
export interface IAssignDentistToPriceListRequest extends Pick<IDentist, "id" | "pricingListId"> {}
export interface IDeleteDentistRequest extends Pick<IDentist, "id"> {}
