export type EnvironmentName = "development" | "production" | "test";

export interface IEnvironmentVariables {
  env: EnvironmentName;
  apiBaseUrl: string;
  useCraProxy: boolean;
  streamApiKey: string;
  recaptchaSiteKey: string;
}

export interface IStreamEvnVariables extends Pick<IEnvironmentVariables, "streamApiKey"> {}

const isValidEnvironmentName = (name: string) => {
  return name === "development" || name === "production" || name === "test";
};

const isValidBaseUrl = (baseUrl: string | undefined): baseUrl is string => {
  return !(baseUrl === null || baseUrl === undefined || baseUrl.trim() === "");
};

const throwWhenEmpty = (configName: string, configValue: string | undefined | null): string => {
  if (typeof configValue !== "string" || configValue.trim() === "") {
    throw Error(`configuration: ${configName} is invalid !`);
  }

  return configValue;
};

const nodeEnv = process.env.NODE_ENV;
const apiBaseUrl = process.env.REACT_APP_API_URL;
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const useCraProxy = process.env.REACT_APP_USE_CRA_PROXY;
const streamApiKey = process.env.REACT_APP_STREAM_API_KEY;

const defaultVariables: IEnvironmentVariables = {
  env: "development",
  apiBaseUrl: "/",
  useCraProxy: false,
  streamApiKey: "",
  recaptchaSiteKey: "",
};

const envVariables: IEnvironmentVariables = {
  env: isValidEnvironmentName(nodeEnv) ? nodeEnv : defaultVariables.env,
  apiBaseUrl: isValidBaseUrl(apiBaseUrl) ? apiBaseUrl : defaultVariables.apiBaseUrl,
  useCraProxy: useCraProxy !== undefined ? useCraProxy === "true" : defaultVariables.useCraProxy,
  streamApiKey: throwWhenEmpty("streamApiKey", streamApiKey),
  recaptchaSiteKey: throwWhenEmpty("recaptchaSiteKey", recaptchaSiteKey)
};

const streamVariables: IStreamEvnVariables = {
  streamApiKey: envVariables.streamApiKey,
};

// console.info("[env variables]: ", envVariables);

export { envVariables, streamVariables };
