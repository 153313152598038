import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Alert } from "antd";

export const SelectPointsFromSameArch = () => {
  return (
    <Alert
      className="m-4"
      type="error"
      showIcon
      message={
        <FormattedMessage
          id={"select-points-from-same-arch"}
          defaultMessage={"Proszę wybierz punkty które leżą na tym samym łuku zębowym"}
        />
      }
    />
  );
};
