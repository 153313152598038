import { Option } from "./option";
import { Some } from "./some";
import { None } from "./none";
import { IResult } from "./result";
import { Err } from "./error";
import { IDomainError } from "core/errors/domain-error";

export class Ok<T, E = IDomainError> implements IResult<T, E> {
  constructor(private value: T) {}

  map<U>(fn: (a: T) => U) {
    // todo: catch error here
    return new Ok<U, E>(fn(this.value));
  }
  mapErr<U>(fn: (a: E) => U) { // unused
    return this as unknown as Ok<T, U>;
  }
  isOk(): this is Ok<T, E> {
    return true;
  }
  isErr(): this is Err<T, E> {
    return false;
  }
  ok(): Option<T> {
    return new Some(this.value);
  }
  err(): Option<E> {
    return None.instance<E>();
  }
  and<U>(res: IResult<U, E>) {
    return res;
  }
  andThen<U>(op: (val: T) => IResult<U, E>) {
    return op(this.value);
  }
  or(res: IResult<T, E>) {
    return this;
  }
  orElse<U>(op: (err: E) => IResult<T, U>) {
    return this as unknown as Ok<T, U>;
  }
  unwrapOr(optb: T) {
    return this.value;
  }
  unwrapOrElse(op: (err: E) => T) {
    return this.value;
  }
  unwrapOrNull() {
    return this.value;
  }
  unwrap(): T {
    return this.value;
  }
  toString() {
    return "Some " + this.value;
  }
}
