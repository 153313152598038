import * as React from "react";
import { useCallback, useContext, useState } from "react";
import RegisterForm, { IRegisterFormSubmitValues } from "../../components/register-form/RegisterForm";
import { Ok } from "core/lib/types/ok";
import AuthServiceContext from "../../auth-service.context";
import { Navigate } from "react-router";
import { AuthPageLayout } from "../../components/auth-page-layout/AuthPageLayout";
import LoginRegisterTabs from "../../components/login-register-tabs/LoginRegisterTabs";
import { AccountType } from "../../auth.model";
import { Err } from "core/lib/types/error";
import { Path } from "core/routes/routes";
import { isNullOrEmpty } from "core/utils/string-utils";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

interface IRegisterPageProps {}

const RegisterPage: React.FC<IRegisterPageProps> = (props) => {
  const authService = useContext(AuthServiceContext);
  const [state, setState] = useState<
    null | { action: "confirm-email" } | { action: "confirm-email-with-link"; link: string }
  >(null);

  const handleRegister = useCallback(
    async (submitValues: IRegisterFormSubmitValues) => {
      const registerResponse = await authService.register({
        companyName: submitValues.companyName,
        password: submitValues.password,
        email: submitValues.email,
        fistName: submitValues.firstName,
        lastName: submitValues.lastName,
        type: AccountType.LabOwner,
        captchaToken: submitValues.captchaToken,
      });

      if (registerResponse.isErr()) {
        // TODO: show error here;
        console.error("failed to register");
        return new Err<void>(registerResponse.err().unwrap());
      }

      const { confirmEmailLink } = registerResponse.unwrap();

      if (!isNullOrEmpty(confirmEmailLink)) {
        setState({ action: "confirm-email-with-link", link: window.btoa(confirmEmailLink!) });
      } else {
        setState({ action: "confirm-email" });
      }

      return new Ok(undefined);
    },
    [authService]
  );

  if (state?.action === "confirm-email-with-link" && !isNullOrEmpty(state?.link)) {
    return (
      <Navigate
        to={parametrizePath({ path: Path.ConfirmEmailWithEmbeddedLink, params: { confirmEmailLink: state.link! } })}
      />
    );
  }

  if (state?.action === "confirm-email") {
    return <Navigate to={Path.ConfirmEmail} />;
  }

  return (
    <>
      <FormattedMessage id={"common.register"} defaultMessage={"Rejestracja"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AuthPageLayout>
        <LoginRegisterTabs activeTab={"register"} />
        <RegisterForm onRegisterSubmit={handleRegister} />
      </AuthPageLayout>
    </>
  );
};

export default RegisterPage;
