import IApiResponseEnvelope from "contracts/envelope/api-response-envelope";
import { ISODateTimeString } from "core/time/time.model";
import { StatusCode } from "features/orders/status/order-status.model";
import { ColorCode } from "features/orders/color/dto/color.dto";
import { AccountType } from "features/auth/auth.model";

export interface GetAllOrderHistoryEntriesPayloadDTO {
  orderId: string;
  events: OrderHistoryEventDTO[];
}

export type OrderHistoryEventDTO = {
  user: { "firstName": string, "lastName": string, "accountType": AccountType } | null,
  event: HistoryEventPayloadDTO;
  date: ISODateTimeString;
};

export enum HistoryEventType {
  Created = 1,
  StatusChanged = 2,
  StartDateChanged = 3,
  DueDateChanged = 4,
  PatientChanged = 5,
  ColorChanged = 6,
  NoteChanged = 7,
  NumberChanged = 8,
  Deleted = 9,
}

export type HistoryEventPayloadDTO =
  | { eventType: HistoryEventType.Created; orderStatus: StatusCode; userId: string }
  | { eventType: HistoryEventType.StatusChanged; statusChange: { previous: StatusCode; next: StatusCode }; userId: string }
  | {
      eventType: HistoryEventType.StartDateChanged;
      startDateChange: { previous: ISODateTimeString | null; next: ISODateTimeString | null };
      userId: string;
    }
  | {
      eventType: HistoryEventType.DueDateChanged;
      dueDateChange: { previous: ISODateTimeString | null; next: ISODateTimeString | null };
      userId: string;
    }
  | {
      eventType: HistoryEventType.PatientChanged;
      patientChange: {
        previous: {
          firstName: string | null;
          lastName: string | null;
          patientCode: string | null;
        };
        next: {
          firstName: string | null;
          lastName: string | null;
          patientCode: string | null;
        };
      };
      userId: string;
    }
  | {
      eventType: HistoryEventType.ColorChanged;
      colorChange: { previous: ColorCode | null; next: ColorCode | null };
      userId: string;
    }
  | {
      eventType: HistoryEventType.NoteChanged;
      noteChange: { previous: string | null; next: string | null };
      userId: string;
    }
  | {
      eventType: HistoryEventType.NumberChanged;
      numberChange: { previous: number; next: number };
      userId: string;
    }
  | {
      eventType: HistoryEventType.Deleted;
      numberChange: { previous: number; next: number };
      userId: string;
    };

export interface GetAllOrderHistoryEntriesResponseDTO
  extends IApiResponseEnvelope<GetAllOrderHistoryEntriesPayloadDTO> {}
