import * as React from "react";
import { Outlet } from "react-router-dom";
import { companyName } from "core/global/global-config";
import { Helmet } from "react-helmet";

export interface IEmptyLayoutProps {}

class EmptyLayout extends React.Component<IEmptyLayoutProps> {
  public render() {
    return (
      <>
        <Helmet titleTemplate={`%s | ${companyName}`} defaultTitle={companyName} />
        <div id="page-container">
          <main id="main-container">
            <Outlet />
          </main>
        </div>
      </>
    );
  }
}

export default EmptyLayout;
