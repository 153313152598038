import * as React from "react";
import { useContext } from "react";
import { FilePdfOutlined, HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, PageHeader } from "antd";
import { FormattedMessage } from "react-intl";
import HeaderContent from "features/layout/content/HeaderContent";
import PageContent from "features/layout/content/PageContent";
import Button from "components/button/Button";
import Links from "components/links/Links";
import InvoicingApiContext from "../invoice.context";
import { Helmet } from "react-helmet";
import BillingStatementTable from "features/invoicing/components/billing-statements-table/BillingStatementTable";
import { IPaginatedList } from "core/pagination/paginated-list";
import {
  BillingStatementsDataProvider,
  IBillingStatementProviderParams,
  IBillingStatementTableEntry,
} from "features/invoicing/components/billing-statements-table/billing-statement-table.models";
import { IBillingStatementId } from "features/invoicing/models/invoicing.models";

interface IListBillingStatementsPageProps {}

const ListBillingStatementsPage: React.FunctionComponent<IListBillingStatementsPageProps> = () => {
  const invoicingApi = useContext(InvoicingApiContext);

  const dataProvider: BillingStatementsDataProvider = React.useCallback(
    async (params: IBillingStatementProviderParams) => {
      const result = await invoicingApi.queryBillingStatements(params);

      return result.map((r) => {
        const paginatedEntries: IPaginatedList<IBillingStatementTableEntry> = {
          pagination: { ...r.pagination },
          data: r.data.map((x) => {
            const entry: IBillingStatementTableEntry = {
              ...x,
            };

            return entry;
          }),
        };

        return paginatedEntries;
      });
    },
    [invoicingApi]
  );

  const handleRemove = React.useCallback(
    async (id: IBillingStatementId) => {
      const result = await invoicingApi.removeBillingStatement(id);
      return result.map(_ => undefined);
    },
    [invoicingApi]
  );

  return (
    <>
      <FormattedMessage id={"page-titles.billingStatements"} defaultMessage={"Rozliczenia"}>
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div>
        <HeaderContent>
          <Breadcrumb className="px-4 py-2">
            <Breadcrumb.Item>
              <Links.Home>
                <HomeOutlined />
              </Links.Home>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Links.BillingStatements />
            </Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={<FormattedMessage id="common.billingStatements" defaultMessage="Rozliczenia" />}
            extra={
              <div className="flex flex-row flex-wrap gap-2">
                <Links.CreateBillingStatement>
                  <Button>
                    <FilePdfOutlined className="mr-2" />
                    <FormattedMessage
                      id="orders-list.generate-billing-statement"
                      defaultMessage="Generuj nowe rozliczenie"
                    />
                  </Button>
                </Links.CreateBillingStatement>
              </div>
            }
          />
        </HeaderContent>
        <PageContent>
          <div className="my-8 bg-white overflow-x-auto">
            <BillingStatementTable dataProvider={dataProvider} onDelete={handleRemove} />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default ListBillingStatementsPage;
