import React from "react";
import { Col, Row } from "antd";
// import Logo, { LogoType } from "components/logo/Logo";

const Header = () => {
  return (
    <div id="header" className="header">
      <Row>
        <Col xxl={4} xl={5} lg={8} md={8} sm={24} xs={24}>
          <div className={"flex w-full"}>
            {/*<Logo type={LogoType.FullLogoOnLightBackground} />*/}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
