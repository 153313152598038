import React from "react";
import { FormattedMessage } from "react-intl";
import { Breadcrumb, PageHeader, Spin } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Links from "components/links/Links";
import HeaderContent from "features/layout/content/HeaderContent";
import { Money } from "core/money/money.functions";
import { useNavigate } from "react-router-dom";
import { parametrizePath } from "core/lib/routing/parametrize-route";
import { Path } from "core/routes/routes";
import OrderForm from "features/orders/components/order-form/OrderForm";
import OrderApiContext from "features/orders/order.context";
import { IEditDentistOrderRequest } from "features/orders/requests/orders.requests";
import { mapSpecificationToSpecificationDto } from "features/orders/contracts/orders.dto.mappers";
import { ILabId, IShortLab } from "features/dental-lab/dental-lab.model";
import { whenEvery } from "core/lib/types/result.utils";
import { Ok } from "core/lib/types/ok";
// import { IOrderCatalogue } from "features/catalogue/components/catalogue.models";
import {
  FormMode,
  IEditDentistOrderSubmitValues,
  OnLoadPricingListForLabFn,
} from "features/orders/components/order-form/order-form.model";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { IUserId } from "features/auth/auth.model";
import { IDentistOrderReadModel, IOrderId } from "features/orders/order.model";
import useOrder from "features/orders/hooks/useOrder";
import { IOrderEntry } from "features/orders/components/order-entry/order-entry.model";
import useOrdersCatalogue from "features/orders/hooks/useOrdersCatalogue";
import ErrorScreen from "components/errors/ErrorScreen";
import { IOrderCatalogue } from "features/catalogue/category.model";

export interface IEditDentistOrderViewProps {
  userId: IUserId;
  orderId: IOrderId;
}

const EditDentistOrderView: React.FunctionComponent<IEditDentistOrderViewProps> = ({ userId, orderId }) => {
  const pricingListApi = React.useContext(PricingListApiContext);
  const ordersApi = React.useContext(OrderApiContext);
  const navigate = useNavigate();

  const { getOrder } = useOrder(orderId);
  const { getOrdersCatalogue } = useOrdersCatalogue(
    getOrder === undefined || getOrder.isErr() ? null : { mode: "dentist", labId: getOrder.unwrap().labId }
  );

  const handleLoadPricingListForDentist: OnLoadPricingListForLabFn = React.useCallback(
    (dentistId: IUserId, labId: ILabId) => pricingListApi.getForLab(dentistId, labId),
    [pricingListApi]
  );

  const handleEditOrder = React.useCallback(
    async (values: IEditDentistOrderSubmitValues) => {
      const request: IEditDentistOrderRequest = {
        orderId: orderId,
        patient: {
          age: values.patientAge,
          patientCode: values.patientCode,
          gender: values.patientGender,
          lastName: values.patientLastName,
          firstName: values.patientFirstName,
          country: values.patientCountry,
        },
        orderNote: values.orderNote,
        startDate: values.startDate,
        dueDate: values.dueDate,
        orderColor: values.orderColor,
        orderItems: values.orderEntries.map((orderEntry) => ({
          id: orderEntry.orderItemId.value,
          orderTypeId: orderEntry.orderType.id.value,
          specification: mapSpecificationToSpecificationDto(orderEntry.specification),
          price: orderEntry.price === null ? Money.PLN(0) : orderEntry.price,
        })),
      };

      const result = await ordersApi.editDentistOrder(request);

      if (result.isOk()) {
        navigate(parametrizePath({ path: Path.OrderDetails, params: { orderId: result.unwrap().value } }));
      }

      return result;
    },
    [navigate, orderId, ordersApi]
  );

  if (getOrder === undefined) {
    return <Spin />;
  } else if (getOrder.isErr()) {
    return <ErrorScreen error={getOrder.err().unwrap()} />;
  }

  if (getOrdersCatalogue === undefined) {
    return <Spin />;
  } else if (getOrdersCatalogue.isErr()) {
    return <ErrorScreen error={getOrdersCatalogue.err().unwrap()} />;
  }

  return (
    <div className={"w-full"}>
      <HeaderContent>
        <Breadcrumb className="px-4 py-2">
          <Breadcrumb.Item>
            <Links.Home>
              <HomeOutlined />
            </Links.Home>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Links.OrdersList />
          </Breadcrumb.Item>
          {getOrder
            .map((order) => (
              <Breadcrumb.Item>
                <Links.EditOrder params={{ orderId: order.orderId }} />
              </Breadcrumb.Item>
            ))
            .unwrap()}
        </Breadcrumb>
        <PageHeader
          title={<FormattedMessage id="create-order-wizard.edit-oder" defaultMessage="Edytuj zamówienie" />}
        />
      </HeaderContent>
      {whenEvery([getOrdersCatalogue, getOrder])
        .map(([ordersCatalogue, order]) => {
          const dentistOrder = order as IDentistOrderReadModel;
          const labs: IShortLab[] = [{ id: dentistOrder.labId, name: dentistOrder.labName }];

          const orderEntries = order.orderItems.map((oi) => {
            const orderType = ordersCatalogue.categories
              .flatMap((c) => c.orderTypes)
              .find((ot) => ot.id.value === oi.orderTypeId.value);

            if (orderType === undefined) {
              throw new Error("could not find order type" + oi.orderTypeId.value);
            }

            const orderEntry: IOrderEntry = {
              id: oi.id.value,
              orderType: {
                id: oi.orderTypeId,
                code: orderType.code,
                requiredSpecification: orderType.requiredSpecification,
                name: orderType.name,
              },
              price: oi.totalPrice,
              specification: oi.specification,
            };

            return orderEntry;
          });

          return (
            <OrderForm
              mode={FormMode.DentistEdit}
              dentistUserId={userId}
              labs={labs}
              orderStatus={dentistOrder.status}
              defaultValues={{
                labId: dentistOrder.labId.value,
                patientCode: dentistOrder.patient.patientCode,
                patientFirstName: dentistOrder.patient.firstName,
                patientLastName: dentistOrder.patient.lastName,
                patientAge: dentistOrder.patient.age,
                patientGender: dentistOrder.patient.gender,
                patientCountry: dentistOrder.patient.country,
                orderEntries: orderEntries,
                orderColor: dentistOrder.color,
                orderNote: dentistOrder.orderNote === null ? undefined : dentistOrder.orderNote,
              }}
              onLoadOrderCatalogue={async (_: ILabId) => new Ok<IOrderCatalogue>(ordersCatalogue)}
              onLoadPricingListForLab={handleLoadPricingListForDentist}
              onSubmit={handleEditOrder}
            />
          );
        })
        .unwrap()}
    </div>
  );
};

export default EditDentistOrderView;
