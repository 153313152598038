import { KnownClaimTypes as ClaimTypes, UserRights } from "features/auth/contracts/auth-contracts.dto";
import { IValueObject } from "core/value-object/value-object";
import { ILabId } from "../dental-lab/dental-lab.model";

export type UserRole = "lab_owner" | "lab_employee" | "dentist";

export interface IJWTStandardPayload {
  iss: "WT-SERVER";
  aud: "DentiMate";
  exp: number;
  userId: string;
  accountType: "LabOwner" | "Dentist" | "LabEmployee";
  email: string;
  emailVerified: "True" | "False";
  familyName: string;
  givenName: string;
  roles: UserRole | UserRole[];
  tenantId: string;
  employeeId: string;
  rights: UserRights[];
}

export interface IClaim {
  type: ClaimTypes;
  value: string | string[];
}

export interface ITenantId extends IValueObject<string> {
  type: "tenant-id";
}

export interface IUserId extends IValueObject<string> {
  type: "user-id";
}

export const NewUserId = (id: string): IUserId => ({ type: "user-id", value: id });

export interface IUserProfile {
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
}

export enum AccountType {
  LabOwner = 1,
  Dentist = 2,
  LabEmployee = 3,
}

export interface IRegisterModel {
  companyName: string;
  email: string;
  fistName: string;
  lastName: string;
  password: string;
  type: AccountType
  captchaToken: string;
}

export interface IRegisterResponse {
  userId: IUserId;
  tenantId: ITenantId | null;
  confirmEmailLink: string | null;
}

export interface IConfirmEmail {
  email: string;
  token: string;
  company: string;
}

export interface IConfirmEmailResponse {
  labId: ILabId;
}

export interface IResetPasswordModel {
  email: string;
}

export interface IResetPasswordResponse {
  url?: string;
}

export interface ISetNewPasswordModel {
  email: string;
  password: string;
  token: string;
}

export interface ISetNewPasswordResponse {}

export interface ILoginModel {
  login: string;
  password: string;
}
