import React from "react";
import { Form, InputNumber } from "antd";
import { FormattedMessage } from "react-intl";
import { Control, Controller, FieldError } from "react-hook-form";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { ExplanationTooltip } from "components/common-components/CommonTooltips";

export const OrderNumberControl = (props: {
  error: FieldError | undefined;
  control: Control<IOrderFormValues, Object>;
}) => (
  <Form.Item
    label={
      <span>
        <FormattedMessage id="common.order-number" defaultMessage="Numer zamówienia" />
        <ExplanationTooltip
          className={"ml-2"}
          title={
            <span>
              <FormattedMessage
                id="order-form.when-empty-order-number"
                defaultMessage="Gdy wartość jest pusta, automatycznie nadamy następny wolny numer zamówienia"
              />
            </span>
          }
        />
      </span>
    }
    validateStatus={props.error !== undefined ? "error" : undefined}
    help={props.error?.message}
  >
    <Controller
      name="orderNumber"
      control={props.control}
      render={({ field: { value, ...rest } }) => (
        <InputNumber
          value={value === null ? undefined : value}
          {...rest}
          autoComplete={"nope"}
          className="w-full max-w-lg"
          min={1}
        />
      )}
    />
  </Form.Item>
);
