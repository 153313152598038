import { Alert } from "antd";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import {
  AnchorTypeBasedSpecification,
  SpecificationType,
} from "../../../../specification/order-specification";
import { PointId } from "core/point/point.enums";

export const FollowingPointsDontHaveAnchorType = (props: {
  specificationType: AnchorTypeBasedSpecification["specificationType"];
  points: PointId[];
}) => {
  let message;

  switch (props.specificationType) {
    case SpecificationType.Crowns:
      {
        message = (
          <FormattedMessage
            id={"following-points-dont-have-anchor-type.provide-anchor-types-for-all-crowns"}
            defaultMessage={"Proszę określ łączenia dla następujących koron ({points})"}
            values={{ points: props.points.join(" ") }}
          />
        );
      }
      break;
    case SpecificationType.Bridges:
    case SpecificationType.MarylandBridges:
      message = (
        <FormattedMessage
          id={"following-points-dont-have-anchor-type.provide-anchor-types-for-all-bridge-points"}
          defaultMessage={"Proszę określ łączenia dla następujących punktów w moście ({points})"}
          values={{ points: props.points.join(" ") }}
        />
      );
      break;
    case SpecificationType.Foundations:
      message = (
        <FormattedMessage
          id={"following-points-dont-have-anchor-type.provide-anchor-types-for-all-foundation-points"}
          defaultMessage={"Proszę określ łączenia dla następujących punktów w podbudowie ({points})"}
          values={{ points: props.points.join(" ") }}
        />
      );
      break;
  }

  return <Alert className="m-4" type="error" showIcon message={message} />;
};
