import {
  Color,
  IVitaClassicalColor,
  ShadeGuide,
  VitaClassicalShade,
  Vita3dMasterShade,
  IVita3dMasterColor,
  IBleachColor,
  BleachShade,
} from "features/orders/color/color.model";

export const allVitaColors: IVitaClassicalColor[] = [
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.ZERO_M1 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.ZERO_M2 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.ZERO_M3 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A1 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A2 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A3 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A3_5 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.A4 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B1 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B2 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B3 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.B4 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C1 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C2 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C3 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.C4 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.D2 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.D3 },
  { guide: ShadeGuide.VitaClassical, shade: VitaClassicalShade.D4 },
];

export const allVita3dColors: IVita3dMasterColor[] = [
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ZERO_M1 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ZERO_M2 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ZERO_M3 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ONE_M1 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.ONE_M2 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_L1_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_L2_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_M1 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_M2 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_M3 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_R1_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.TWO_R2_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_L1_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_L2_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_M1 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_M2 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_M3 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_R1_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.THREE_R2_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_L1_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_L2_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_M1 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_M2 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_M3 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_R1_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FOUR_R2_5 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FIVE_M1 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FIVE_M2 },
  { guide: ShadeGuide.Vita3dMaster, shade: Vita3dMasterShade.FIVE_M3 },
];

export const allBleachColors: IBleachColor[] = [
  { guide: ShadeGuide.Bleach, shade: BleachShade.BL1 },
  { guide: ShadeGuide.Bleach, shade: BleachShade.BL2 },
  { guide: ShadeGuide.Bleach, shade: BleachShade.BL3 },
  { guide: ShadeGuide.Bleach, shade: BleachShade.BL4 },
];

export const allColors: Color[] = [...allVitaColors, ...allVita3dColors, ...allBleachColors];
