import * as React from "react";
import { Select } from "antd";
import {
  VitaClassicalShade,
  ShadeGuide,
  Vita3dMasterShade,
  BleachShade,
} from "features/orders/color/color.model";
import { SelectProps } from "antd/lib/select";
import { allColors } from "features/orders/color/color.const";
import ShadeFormatter from "components/formatters/shade/ShadeFormatter";

interface IVita3dMasterShadeSelectProps extends SelectProps<Vita3dMasterShade> {}

class Vita3dMasterShadeSelect extends React.Component<IVita3dMasterShadeSelectProps> {
  render() {
    const { children: _, ...rest } = this.props;
    const allShades = allColors.filter((s) => s.guide === ShadeGuide.Vita3dMaster);

    return (
      <Select {...rest}>
        {allShades.map((s) => (
          <Select.Option key={`${s.guide}-${s.shade}`} value={s.shade}>
            <ShadeFormatter shade={s.shade} />
          </Select.Option>
        ))}
      </Select>
    );
  }
}

interface IVitaClassicShadeSelectProps extends SelectProps<VitaClassicalShade> {}

class VitaClassicShadeSelect extends React.Component<IVitaClassicShadeSelectProps> {
  render() {
    const { children: _, ...rest } = this.props;
    const allShades = allColors.filter((s) => s.guide === ShadeGuide.VitaClassical);

    return (
      <Select {...rest}>
        {allShades.map((s) => (
          <Select.Option key={`${s.guide}-${s.shade}`} value={s.shade}>
            <ShadeFormatter shade={s.shade} />
          </Select.Option>
        ))}
      </Select>
    );
  }
}

interface IBleachShadeSelectProps extends SelectProps<BleachShade> {}

class BleachShadeSelect extends React.Component<IBleachShadeSelectProps> {
  render() {
    const { children: _, ...rest } = this.props;
    const allShades = allColors.filter((s) => s.guide === ShadeGuide.Bleach);

    return (
      <Select {...rest}>
        {allShades.map((s) => (
          <Select.Option key={`${s.guide}-${s.shade}`} value={s.shade}>
            <ShadeFormatter shade={s.shade} />
          </Select.Option>
        ))}
      </Select>
    );
  }
}

class ShadeSelect {
  static VitaClassic = VitaClassicShadeSelect;
  static Vita3dMaster = Vita3dMasterShadeSelect;
  static Bleach = BleachShadeSelect;
}

export default ShadeSelect;
