import { ChannelPreviewUIComponentProps, ChatContextValue, useChatContext } from "stream-chat-react";

import "./MessagingChannelPreview.scss";

import type { Channel, ChannelMemberResponse } from "stream-chat";

import {
  AttachmentType,
  ChannelType,
  CommandType,
  EventType,
  MessageType,
  ReactionType,
  UserType,
} from "features/chat/chat.model";

import moment from "moment";
import { useChatStore } from "features/chat/hooks/useChatStore";
import { Tooltip } from "antd";
import { isNotEmptyString } from "core/utils/utils";

const getTimeStamp = (channel: Channel) => {
  const timestamp = channel.state.last_message_at;

  if (timestamp === undefined || timestamp === null) {
    return "";
  }

  return moment(timestamp).format("HH:mm");
};

const getChannelName = (members: ChannelMemberResponse[]) => {
  const defaultName = undefined;

  if (!members.length || members.length === 1) {
    return members[0]?.user?.name || defaultName;
  }

  return `${members[0]?.user?.name || defaultName}, ${members[1]?.user?.name || defaultName}`;
};

const getChannelTooltipForMembers = (members: ChannelMemberResponse[]) => {
  return (
    members
      .map((m) => m?.user?.name)
      .filter((name) => isNotEmptyString(name))
      .join(", ") || ""
  );
};

type Props = ChannelPreviewUIComponentProps & {
  channel: Channel;
  latestMessage?: any; // string | Element | undefined;
  setActiveChannel?: ChatContextValue["setActiveChannel"];
};

const MessagingChannelPreview: React.FC<Props> = (props) => {
  const { channel, latestMessage, setActiveChannel, unread } = props;
  const setChatMode = useChatStore((store) => store.setChatMode);

  const { channel: activeChannel, client } = useChatContext<
    AttachmentType,
    ChannelType,
    CommandType,
    EventType,
    MessageType,
    ReactionType,
    UserType
  >();

  const members = Object.values(channel.state.members).filter(({ user }) => user?.id !== client.userID);

  const channelName = channel.data?.name || getChannelName(members);
  const isChannelNameTooltipEnabled = channel.data?.name === undefined && members.length >= 2;

  return (
    <div
      className={
        channel?.id === activeChannel?.id ? "channel-preview__container selected" : "channel-preview__container"
      }
      onClick={() => {
        setChatMode("chat-window");
        setActiveChannel?.(channel);
      }}
    >
      <div className="channel-preview__content-wrapper">
        <div className="channel-preview__content-top">
          {isChannelNameTooltipEnabled ? (
            <Tooltip title={getChannelTooltipForMembers(members)}>
              <div className="channel-preview__content-name">{channelName}</div>
            </Tooltip>
          ) : (
            <div className="channel-preview__content-name">{channelName}</div>
          )}
          <div className="channel-preview__content-time">{getTimeStamp(channel)}</div>
        </div>
        <div className="channel-preview__content-bottom">
          <div className="channel-preview__content-message">{latestMessage || "Wyślij pierwszą wiadomość"}</div>
          {unread !== undefined && unread >= 1 && <div className="channel-preview__unread-count">{unread}</div>}
        </div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
