export enum ApiEndpointPath {
  // Auth
  Login = "/api/v1/login",
  Register = "/api/v1/register",
  ConfirmLabOwnersEmail = "/api/v1/register-lab/confirm",
  ResetPassword = "/api/v1/reset",
  SetNewPassword = "/api/v1/reset/confirm",

  // Invites
  SendEmployeeInvitation = "/api/v1/register/:tenantId/employee/invite/send",
  SendDentistInvitation = "/api/v1/register/:tenantId/doctor/invite/send",
  RegisterEmployee = "/api/v1/register/employee/invite",
  RegisterDentist = "/api/v1/register/doctor/invite",

  // DentalLab
  GetDentalLab = "/api/v1/:labId/dental-labs",
  GetDentalLabs = "/api/v1/dentist/:dentistId/dental-labs",
  CreateDentalLab = "/api/v1/:tenantId/dental-lab/create",

  // DentalLab employees
  GetEmployees = "/api/v1/employees",
  GetEmployeesWithOrders = "/api/v1/employees/with-active-orders",
  EmployeesAssignOrder = "/api/v1/employees/assign-order",
  EmployeesAssignOrderSelf = "/api/v1/employees/assign-order-self",
  CreateEmployee = "/api/v1/employees/create",
  EditEmployee = "/api/v1/employees/edit",
  DeleteEmployee = "/api/v1/employees/:employeeId/delete",

  GetDentalClinic = "/api/v1/:tenantId/dental-clinics/:clinicId",
  CreateMultipleDentalClinics = "/api/v1/:tenantId/dental-clinics",
  CreateDentalClinic = "/api/v1/:tenantId/dental-clinic",
  GetAllDentalClinics = "/api/v1/:tenantId/all-dental-clinics",
  ChangeDentalClinicName = "/api/v1/:tenantId/dental-clinics/change-name",
  ChangeDentalClinicTaxId = "/api/v1/:tenantId/dental-clinics/change-tax-id",
  ChangeDentalClinicAddress = "/api/v1/:tenantId/dental-clinics/change-address",

  OldCreateDentistEndpoint2 = "/api/v1/:tenantId/dental-clinics/:clinicId/dentists/create",
  RemoveDentist = "/api/v1/:tenantId/dental-clinics/:clinicId/dentists/remove",
  EditDentist = "/api/v1/:tenantId/dental-clinics/:clinicId/dentists/edit",

  RemoveBillingDataSet = "/api/v1/:tenantId/dental-clinics/:clinicId/remove-billing-data-set",
  SetBillingDataSetAsDefault = "/api/v1/:tenantId/dental-clinics/:clinicId/set-billing-data-set-as-default",
  EditBilling = "/api/v1/:tenantId/dental-clinics/:clinicId/edit-billing-data-set",
  AddBillingDataSet = "/api/v1/:tenantId/dental-clinics/:clinicId/add-billing-data-set",

  // Dentists
  GetAllDentists = "/api/v1/:tenantId/dentists",
  CreateDentistEndpoint = "/api/v1/:tenantId/dentists/create",
  EditDentistEndpoint = "/api/v1/:tenantId/dentists/edit",
  DeleteDentistEndpoint = "/api/v1/:tenantId/dentists/delete",
  GetDentistsBillingInfo = "/api/v1/:tenantId/dentist/:dentistId/billing-infos",
  AddDentistsBillingInfo = "/api/v1/:tenantId/dentists/add/billing-info",
  AssignDentistToPricingList = "/api/v1/:tenantId/dentists/assign/pricing-list",

  // Invoicing
  QueryBillingStatements = "/api/v1/:labId/billing-statements",
  QueryBillingStatement = "/api/v1/:labId/billing-statement/:billingStatementId",
  CreateBillingStatement = "/api/v1/:labId/billing-statement/create",
  UpdateBillingStatementLines = "/api/v1/:labId/billing-statement/:billingStatementId/lines",

  // "/{labId}/billing-statement/{billingStatementId}";
  DeleteBillingStatement = "/api/v1/:labId/billing-statement/:billingStatementId/delete",
  DownloadInvoice = "/api/v1/:labId/invoices/:invoiceId/download-invoice",
  DownloadOrderList = "/api/v1/:labId/billing-statement/:billingStatementId/download",

  // Patients
  CreatePatient = "/api/v1/:tenantId/dental-clinic/create-patient",

  // Orders
  GetLabsOrders = "/api/v1/orders/lab/:labId",
  GetDentistOrders = "/api/v1/orders/dentist/:dentistId",
  GetLabOrder = "/api/v1/orders/lab/:labId/order/:orderId",
  GetDentistOrder = "/api/v1/orders/dentist/:dentistId/order/:orderId",
  ChangeOrderFinishDate = "api/v1/:labId/orders/change-finish-date",
  ChangeOrderItemNote = "/api/v1/:labId/orders/change-note",
  ChangeOrderItemPrice = "/api/v1/:labId/orders/change-order-item-price",
  ChangeOrderStatus = "/api/v1/:labId/orders/change-order-status",
  Orders__CreateLabOrder = "/api/v1/orders/lab/:labId/create-order",
  Orders_DeleteLabOrder = "/api/v1/orders/lab/:labId/order/:orderId/delete",
  Orders_DeleteDentistOrder = "/api/v1/orders/dentist/:dentistId/order/:orderId/delete",
  Orders_CreateDentistOrder = "/api/v1/orders/dentist/:dentistId/create-order",
  Orders_EditLabOrder = "/api/v1/orders/lab/:labId/order/:orderId/edit",
  Orders_EditDentistOrder = "/api/v1/orders/dentist/:dentistId/order/:orderId/edit",
  DeleteOrderFile = "/api/v1/:labId/orders/:orderId/file/:fileKey/delete",
  DeleteOrderItem = "/api/v1/:labId/orders/delete-order-item",
  GetMultipleOrders = "/api/v1/:labId/orders/multiple",
  GetNextOrderNumber = "/api/v1/:labId/orders/get-next-order-number",
  CheckOrderNumber = "api/v1/:labId/orders/check-order-number/:orderNumber",
  UploadOrderFile = "/api/v1/:labId/orders/:orderId/upload-files",
  GetOrderComments = "api/v1/:tenantId/:labId/orders/:orderId/comments",
  CreateOrderComment = "api/v1/:tenantId/lab/:labId/orders/:orderId/comments",

  // OrderFiles
  OrderFiles_GetOrderFiles = "/api/v1/order-files/order/:orderId",
  OrderFiles_GetOrderFile = "/api/v1/order-files/order/:orderId/file/:fileId",
  OrderFiles_DeleteOrderFile = "/api/v1/order-files/order/:orderId/file/:fileId/delete",
  OrderFiles_InitializeFileUpload = "/api/v1/order-files/order/:orderId/upload-url",
  OrderFiles_FinalizeFileUpload = "/api/v1/order-files/order/:orderId/file-uploaded",

  // OrderHistory
  GetOrderHistory = "/api/v1/order-history/:orderId",

  // OrderCatalogues
  GetOrderCatalogue = "/api/v1/order-catalogues/lab/:labId",
  CreateOrderType = "/api/v1/order-catalogues/lab/:labId/create-order-type",
  EditOrderType = "/api/v1/order-catalogues/lab/:labId/edit-order-type",
  ArchiveOrderType = "/api/v1/order-catalogues/lab/:labId/archive-order-type",
  CreateOrderCategory = "/api/v1/order-catalogues/lab/:labId/create-category",
  EditOrderCategory = "/api/v1/order-catalogues/lab/:labId/edit-category",
  AddOrUpdatePricingEntry = "/api/v1/pricing-catalogue/:labId/default/create",
  IsPricingEntrySet = "/api/v1/pricing-catalogue/:labId/default/:orderTypeId/is-pricing-set",

  // OrderFeedback
  GetOrderFeedback = "/api/v1/:labId/orders/:orderId/feedback",
  GiveOrderFeedback = "/api/v1/:labId/orders/:orderId/feedback/give",

  // pricing-lists
  PricingList_Get = "/api/v1/pricing-list/lab/:labId/list/:pricingListId",
  PricingList_GetDefault = "/api/v1/pricing-list/lab/:labId/list/default",
  PricingList_GetForLabDentist = "/api/v1/pricing-list/lab/:labId/dentist/:dentistId",
  PricingList_GetForLab = "/api/v1/pricing-list/dentist/:userId/lab/:labId",
  PricingList_GetAll = "/api/v1/pricing-list/lab/:labId/all",
  PricingList_Create = "/api/v1/pricing-list/lab/:labId/list/create",
  PricingList_Edit = "/api/v1/pricing-list/lab/:labId/list/:pricingListId/edit",
  PricingList_Delete = "/api/v1/pricing-list/lab/:labId/list/:pricingListId/delete",
  PricingList_SetPrice = "/api/v1/pricing-list/lab/:labId/list/:pricingListId/set-price",

  GetSubscriptions = "/api/v1/subscriptions",
  GetSubscriptionProducts = "/api/v1/payments/subscription-products",
  CreateCheckoutSession = "/api/v1/payments/checkout-session",
  CreateBillingSession = "/api/v1/payments/billing-portal-session",

  // Settings
  GetInvoicingSettings = "/api/v1/:tenantId/settings/invoicing",
  UpdateInvoicingSettings = "/api/v1/:tenantId/settings/invoicing",

  // Chat
  Chat_GetUserToken = "/api/v1/chats/user/:userId/token",
  Chat_GetOrderChannel = "/api/v1/chats/order/:orderId/channel",
  Chat_CreateOrderChannel = "/api/v1/chats/order/:orderId/channel/create",
  Chat_CreateConversation = "/api/v1/chats/conversation/user/:userId/create",
  Chat_JoinOrderChannel = "/api/v1/chats/order/:orderId/channel/user/:userId/join",
  Chat_SearchContacts = "/api/v1/chats/search/contacts/:userId",
}

export type ParametrizedEndpointPath =
  | {
      path: ApiEndpointPath.CreateDentalLab | ApiEndpointPath.CreateMultipleDentalClinics | ApiEndpointPath.CreateDentalClinic | ApiEndpointPath.ChangeDentalClinicName | ApiEndpointPath.ChangeDentalClinicTaxId | ApiEndpointPath.ChangeDentalClinicAddress | ApiEndpointPath.CreateDentistEndpoint | ApiEndpointPath.CreatePatient | ApiEndpointPath.GetInvoicingSettings | ApiEndpointPath.UpdateInvoicingSettings | ApiEndpointPath.GetAllDentalClinics | ApiEndpointPath.GetAllDentists | ApiEndpointPath.EditDentistEndpoint | ApiEndpointPath.DeleteDentistEndpoint | ApiEndpointPath.AddDentistsBillingInfo | ApiEndpointPath.SendDentistInvitation | ApiEndpointPath.SendEmployeeInvitation;
      params: { tenantId: string };
    }
  | {
      path: ApiEndpointPath.QueryBillingStatements;
      params: { labId: string };
    }
  | {
      path: ApiEndpointPath.CreateBillingStatement;
      params: { labId: string };
    }
  | {
      path: ApiEndpointPath.QueryBillingStatement;
      params: { labId: string; billingStatementId: string };
    }
  | {
      path: ApiEndpointPath.UpdateBillingStatementLines;
      params: { labId: string; billingStatementId: string };
    }
  | {
      path: ApiEndpointPath.DeleteBillingStatement;
      params: { labId: string; billingStatementId: string };
    }
  | {
      path: ApiEndpointPath.DownloadOrderList;
      params: { labId: string; billingStatementId: string };
    }
  | {
      path: ApiEndpointPath.DownloadInvoice;
      params: { tenantId: string; invoiceId: string };
    }
  | {
      path: ApiEndpointPath.ChangeOrderItemNote | ApiEndpointPath.ChangeOrderItemPrice | ApiEndpointPath.ChangeOrderFinishDate | ApiEndpointPath.ChangeOrderStatus | ApiEndpointPath.Orders__CreateLabOrder | ApiEndpointPath.DeleteOrderItem | ApiEndpointPath.GetMultipleOrders | ApiEndpointPath.GetNextOrderNumber | ApiEndpointPath.AddOrUpdatePricingEntry;
      params: { labId: string };
    }
  | {
      path: ApiEndpointPath.GetDentalClinic | ApiEndpointPath.RemoveBillingDataSet | ApiEndpointPath.SetBillingDataSetAsDefault | ApiEndpointPath.EditBilling | ApiEndpointPath.AddBillingDataSet | ApiEndpointPath.OldCreateDentistEndpoint2 | ApiEndpointPath.RemoveDentist | ApiEndpointPath.EditDentist;
      params: { tenantId: string; clinicId: string };
    }
  | {
      path: ApiEndpointPath.UploadOrderFile;
      params: { labId: string; orderId: string };
    }
  | {
      path: ApiEndpointPath.DeleteOrderFile;
      params: { labId: string; orderId: string; fileKey: string };
    }
  | {
      path: ApiEndpointPath.GetLabsOrders;
      params: { labId: string };
    }
  | {
      path: ApiEndpointPath.GetDentistOrders;
      params: { dentistId: string };
    }
  | {
      path: ApiEndpointPath.GetLabOrder;
      params: { labId: string; orderId: string };
    }
  | {
      path: ApiEndpointPath.GetDentistOrder;
      params: { dentistId: string; orderId: string };
    }
  | {
      path: ApiEndpointPath.IsPricingEntrySet;
      params: { labId: string; orderTypeId: string };
    }
  | {
      path: ApiEndpointPath.DeleteEmployee;
      params: { employeeId: string };
    }
  | { path: ApiEndpointPath.GetOrderComments | ApiEndpointPath.CreateOrderComment; params: { tenantId: string; labId: string; orderId: string } }
  | { path: ApiEndpointPath.GetDentistsBillingInfo; params: { tenantId: string; dentistId: string } }
  | { path: ApiEndpointPath.CheckOrderNumber; params: { labId: string; orderNumber: string } }
  | { path: ApiEndpointPath.GetOrderHistory; params: { orderId: string } }
  | { path: ApiEndpointPath.GetDentalLab; params: { labId: string } }
  | { path: ApiEndpointPath.GetDentalLabs; params: { dentistId: string } }
  | { path: ApiEndpointPath.PricingList_Get; params: { labId: string; pricingListId: string } }
  | { path: ApiEndpointPath.PricingList_GetDefault; params: { labId: string } }
  | { path: ApiEndpointPath.PricingList_GetForLabDentist; params: { labId: string; dentistId: string } }
  | { path: ApiEndpointPath.PricingList_GetAll; params: { labId: string } }
  | { path: ApiEndpointPath.PricingList_Create; params: { labId: string } }
  | { path: ApiEndpointPath.PricingList_Edit; params: { labId: string; pricingListId: string } }
  | { path: ApiEndpointPath.PricingList_SetPrice; params: { labId: string; pricingListId: string } }
  | { path: ApiEndpointPath.PricingList_Delete; params: { labId: string; pricingListId: string } }
  | { path: ApiEndpointPath.AssignDentistToPricingList; params: { tenantId: string } }
  | { path: ApiEndpointPath.PricingList_GetForLab; params: { userId: string; labId: string } }
  | { path: ApiEndpointPath.Orders_CreateDentistOrder; params: { dentistId: string } }
  | { path: ApiEndpointPath.Orders_EditDentistOrder; params: { dentistId: string; orderId: string } }
  | { path: ApiEndpointPath.Orders_EditLabOrder; params: { labId: string; orderId: string } }
  | { path: ApiEndpointPath.Orders_DeleteLabOrder; params: { labId: string; orderId: string } }
  | { path: ApiEndpointPath.Orders_DeleteDentistOrder; params: { dentistId: string; orderId: string } }
  | { path: ApiEndpointPath.OrderFiles_GetOrderFiles; params: { orderId: string } }
  | { path: ApiEndpointPath.OrderFiles_GetOrderFile; params: { orderId: string; fileId: string } }
  | { path: ApiEndpointPath.OrderFiles_DeleteOrderFile; params: { orderId: string; fileId: string } }
  | { path: ApiEndpointPath.OrderFiles_InitializeFileUpload; params: { orderId: string } }
  | { path: ApiEndpointPath.OrderFiles_FinalizeFileUpload; params: { orderId: string } }
  | { path: ApiEndpointPath.Chat_GetUserToken; params: { userId: string } }
  | { path: ApiEndpointPath.Chat_GetOrderChannel; params: { orderId: string } }
  | { path: ApiEndpointPath.Chat_CreateOrderChannel; params: { orderId: string } }
  | { path: ApiEndpointPath.Chat_JoinOrderChannel; params: { orderId: string; userId: string } }
  | { path: ApiEndpointPath.Chat_SearchContacts; params: { userId: string } }
  | { path: ApiEndpointPath.GetOrderCatalogue; params: { labId: string } }
  | { path: ApiEndpointPath.Chat_CreateConversation; params: { userId: string } }
  | { path: ApiEndpointPath.CreateOrderType; params: { labId: string } }
  | { path: ApiEndpointPath.EditOrderType; params: { labId: string } }
  | { path: ApiEndpointPath.ArchiveOrderType; params: { labId: string } }
  | { path: ApiEndpointPath.CreateOrderCategory; params: { labId: string } }
  | { path: ApiEndpointPath.EditOrderCategory; params: { labId: string } }
  | { path: ApiEndpointPath.GetOrderFeedback | ApiEndpointPath.GiveOrderFeedback; params: { labId: string; orderId: string } };
