import jwtDecode from "jwt-decode";
import Subject from "core/lib/observer/observer";
import { IHttpService } from "core/http/http.service";
import {
  IClaim,
  IConfirmEmail,
  IConfirmEmailResponse,
  IJWTStandardPayload,
  ILoginModel,
  IRegisterModel,
  IRegisterResponse,
  IResetPasswordModel,
  IResetPasswordResponse,
  ISetNewPasswordModel,
  ISetNewPasswordResponse,
  ITenantId,
} from "features/auth/auth.model";
import { IResult } from "core/lib/types/result";
import { getDomainError, isErrorResponse } from "core/http/http.functions";
import { Err } from "core/lib/types/error";
import {
  IConfirmEmailQueryParamsDTO,
  IConfirmEmailResponseDTO,
  ILoginRequestDTO,
  ILoginResponseDTO,
  IRegisterRequestDTO,
  IRegisterResponseDTO,
  IResetPasswordRequestDTO,
  IResetPasswordResponseDTO,
  ISetNewPasswordRequestDTO,
  ISetNewPasswordResponseDTO,
  KnownClaimTypes,
} from "./contracts/auth-contracts.dto";
import { Ok } from "core/lib/types/ok";
import DomainError, { IDomainError } from "../../core/errors/domain-error";
import { IBaseUrlService } from "services/base-url.service";
import { ApiEndpointPath } from "core/routes/api-endpoints";
import queryString from "query-string";
import { EventName, IEvents } from "root/IEvents";
import { ErrorCode } from "core/errors/error-code";
import TypedEmitter from "../../core/event-emitter/typed-event-emitter";
import AuthContext from "features/auth/auth-context";

export interface IAuthTokenProvider {
  getAccessToken: () => string | null;
}

export interface IAuthContextProvider {
  getAuthContext: () => AuthContext;
}

export interface IAuthService extends IAuthTokenProvider, IAuthContextProvider {
  register: (model: IRegisterModel) => Promise<IResult<IRegisterResponse>>;
  confirmEmail: (model: IConfirmEmail) => Promise<IResult<IConfirmEmailResponse>>;
  resetPassword: (model: IResetPasswordModel) => Promise<IResult<IResetPasswordResponse>>;
  setNewPassword: (model: ISetNewPasswordModel) => Promise<IResult<ISetNewPasswordResponse>>;

  login: (model: ILoginModel) => Promise<IResult<AuthContext>>;
  logout: () => void;

  isLoggedIn: () => boolean;
  getTenantId: () => Promise<
    Readonly<{ isLoggedIn: false; tenantId: null } | { isLoggedIn: true; tenantId: ITenantId }>
  >;

  authContextSubject: Subject<AuthContext>;
}

interface IHttpServiceProvider {
  provide: () => IHttpService;
}

const generateAuthContextFromAuthToken = (token: string): AuthContext => {
  const jwtPayload = jwtDecode<IJWTStandardPayload>(token);
  const { accountType, email, tenantId, employeeId, givenName, familyName, emailVerified, roles, userId, rights } =
    jwtPayload;

  const claimsArray: IClaim[] = [
    { type: KnownClaimTypes.UserId, value: userId },
    { type: KnownClaimTypes.TenantId, value: tenantId },
    { type: KnownClaimTypes.EmployeeId, value: employeeId },
    { type: KnownClaimTypes.Email, value: email },
    { type: KnownClaimTypes.GivenName, value: givenName },
    { type: KnownClaimTypes.FamilyName, value: familyName },
    { type: KnownClaimTypes.EmailVerified, value: emailVerified },
    { type: KnownClaimTypes.AccountType, value: accountType },
    { type: KnownClaimTypes.Roles, value: roles },
    { type: KnownClaimTypes.Rights, value: rights },
  ].filter((claim) => claim.value !== undefined && claim.value !== null);

  return AuthContext.New(claimsArray);
};

const isTokenExpired = (token: string, tokenExpiredThresholdInSeconds: number): boolean => {
  const jwtPayload = jwtDecode<IJWTStandardPayload>(token);
  const tokenExpiration = jwtPayload.exp;
  const expirationInSec = tokenExpiration - Math.floor(Date.now() / 1000);
  return expirationInSec < tokenExpiredThresholdInSeconds;
};

class AuthService implements IAuthService {
  private _baseUrlService: IBaseUrlService;
  private _eventEmitter: TypedEmitter<IEvents>;
  private _httpServiceProvider: IHttpServiceProvider;
  private _authToken: string | null = null;
  private tokenExpirationCheckInterval = 15 * 1000;
  private tokenExpirationThresholdInSec = 30;
  private localStorageTokenKey = "token";

  public authContextSubject: Subject<AuthContext> = new Subject();

  get authToken(): string | null {
    return this._authToken;
  }

  set authToken(value: string | null) {
    if (value === null) {
      global.window.localStorage.removeItem(this.localStorageTokenKey);
    } else {
      global.window.localStorage.setItem(this.localStorageTokenKey, value);
    }

    this._authToken = value;

    const authContext =
      this._authToken == null ? AuthContext.Empty() : generateAuthContextFromAuthToken(this._authToken);
    this.authContextSubject.notify(authContext);
  }

  constructor(
    baseUrlService: IBaseUrlService,
    eventEmitter: TypedEmitter<IEvents>,
    httpServiceProvider: IHttpServiceProvider
  ) {
    this._baseUrlService = baseUrlService;
    this._eventEmitter = eventEmitter;
    this._httpServiceProvider = httpServiceProvider;

    this.tryToLoadTokenFromLocalStorage();
    this.startTokenExpirationCheckLoop();
  }

  private tryToLoadTokenFromLocalStorage = () => {
    const token = global.window.localStorage.getItem(this.localStorageTokenKey);

    if (token !== null && !isTokenExpired(token, this.tokenExpirationThresholdInSec)) {
      this.authToken = token;
    } else {
      this.authToken = null;
    }
  };

  private startTokenExpirationCheckLoop = () => {
    setInterval(() => {
      const authTokenValue = this.authToken;
      if (authTokenValue === null) {
        return;
      }

      if (!isTokenExpired(authTokenValue, this.tokenExpirationThresholdInSec)) {
        return;
      }

      this._eventEmitter.emit(EventName.AuthTokenExpired);
      this.authToken = null;
    }, this.tokenExpirationCheckInterval);
  };

  getAccessToken = (): string | null => {
    const isLoggedIn = this.isLoggedIn();

    if (!isLoggedIn) {
      return null;
    }

    return this.authToken;
  };

  getAuthContext = (): AuthContext => {
    const token = this._authToken;

    if (token === null) {
      return AuthContext.Empty();
    }

    return generateAuthContextFromAuthToken(token);
  };

  getTenantId = async (): Promise<
    Readonly<{ isLoggedIn: false; tenantId: null } | { isLoggedIn: true; tenantId: ITenantId }>
  > => {
    const isLoggedIn = await this.isLoggedIn();

    if (!isLoggedIn) {
      return { isLoggedIn: false, tenantId: null };
    }

    const authContext = await this.getAuthContext();
    const tenantId = authContext.tenantId;

    if (tenantId === null) {
      console.error("tenantId is null");
      throw new Error("tenantId is null");
    }

    return { isLoggedIn: isLoggedIn, tenantId: tenantId };
  };

  isLoggedIn = (): boolean => this.authToken !== null;

  login = async (model: ILoginModel): Promise<IResult<AuthContext>> => {
    try {
      const response = await this._httpServiceProvider
        .provide()
        .post<ILoginResponseDTO, ILoginRequestDTO>(this._baseUrlService.getApiBaseUrl() + ApiEndpointPath.Login, {
          login: model.login,
          password: model.password,
        });

      const body = response.data;
      const { token } = body.result;

      this.authToken = token;
      const authContext = await this.getAuthContext();

      return new Ok(authContext);
    } catch (error: any) {
      const errorData = error?.response?.data;

      if (isErrorResponse(errorData)) {
        console.error(errorData);
        const domainError = getDomainError(errorData);
        this._eventEmitter.emit(EventName.ShowErrorToast, domainError);
        return new Err(domainError);
      } else {
        console.error(error);
        return new Err(new DomainError(ErrorCode.InternalError, "unknown error"));
      }
    }
  };

  logout = () => {
    this.authToken = null;
  };

  register = async (model: IRegisterModel): Promise<IResult<IRegisterResponse>> => {
    const response = await this._httpServiceProvider
      .provide()
      .post<IRegisterResponseDTO, IRegisterRequestDTO>(
        this._baseUrlService.getApiBaseUrl() + ApiEndpointPath.Register,
        {
          companyName: model.companyName,
          email: model.email,
          name: model.fistName,
          surname: model.lastName,
          password: model.password,
          type: model.type,
          captchaToken: model.captchaToken,
        }
      );

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err<IRegisterResponse>(getDomainError(body));
    }

    const { result } = body;
    const { tenantId, userId, confirmEmailLink } = result;

    return new Ok<IRegisterResponse>({
      userId: { type: "user-id", value: userId },
      tenantId: tenantId === undefined || tenantId === null ? null : { type: "tenant-id", value: tenantId },
      confirmEmailLink: confirmEmailLink,
    });
  };

  confirmEmail = async (model: IConfirmEmail): Promise<IResult<IConfirmEmailResponse>> => {
    const query: IConfirmEmailQueryParamsDTO = {
      email: model.email,
      company: model.company,
      token: model.token,
    };

    const response = await this._httpServiceProvider
      .provide()
      .get<IConfirmEmailResponseDTO>(
        this._baseUrlService.getApiBaseUrl() +
          ApiEndpointPath.ConfirmLabOwnersEmail +
          "?" +
          queryString.stringify(query)
      );

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err<IConfirmEmailResponse>(getDomainError(body));
    }

    const { result } = body;

    return new Ok<IConfirmEmailResponse>({
      labId: { type: "dental-lab-id", value: result },
    });
  };

  resetPassword = async (model: IResetPasswordModel): Promise<IResult<IResetPasswordResponse>> => {
    const response = await this._httpServiceProvider
      .provide()
      .post<IResetPasswordResponseDTO, IResetPasswordRequestDTO>(
        this._baseUrlService.getApiBaseUrl() + ApiEndpointPath.ResetPassword,
        { email: model.email }
      );

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err<IResetPasswordResponse>(getDomainError(body));
    }

    const {
      result: { url },
    } = body;

    return new Ok<IResetPasswordResponse>({ url });
  };

  setNewPassword: (model: ISetNewPasswordModel) => Promise<IResult<ISetNewPasswordResponse, IDomainError>> = async (
    model
  ) => {
    const response = await this._httpServiceProvider
      .provide()
      .put<ISetNewPasswordResponseDTO, ISetNewPasswordRequestDTO>(
        this._baseUrlService.getApiBaseUrl() + ApiEndpointPath.SetNewPassword,
        {
          email: model.email,
          password: model.password,
          token: model.token,
        }
      );

    const body = response.data;

    if (isErrorResponse(body)) {
      return new Err<ISetNewPasswordResponse>(getDomainError(body));
    }

    const { result } = body;

    return new Ok<ISetNewPasswordResponse>(result);
  };
}

export default AuthService;
