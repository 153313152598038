import {
  IDataProvider,
  IPaginatedProviderParams,
  ServerSortDirection,
} from "features/orders/pages/list/components/orders-table.models";
import { ISODateTimeString } from "core/time/time.model";
import { IMoney } from "core/money/money.model";
import { IBillingStatementId } from "features/invoicing/models/invoicing.models";
import { IShortDentist } from "features/dentist/dentist.model";

export interface IBillingStatementTableEntry {
  readonly id: IBillingStatementId,
  readonly recipient: IShortDentist,
  readonly statementNumber: number;
  readonly createdOn: ISODateTimeString;
  readonly grandTotal: IMoney;
  readonly notes: string;
}

export enum BillingStatementServerColumn {
  StatementNumber = "statementNumber",
  Dentist = "dentist",
  CreatedOn = "createdOn",
  GrandTotal = "grandTotal",

  // do not change - without sync with backend
  // OrderNumber = "orderNumber",
  // Dentist = "dentist",
  // DueDate = "dueDate",
  // FinishDate = "finishDate",
  // Status = "status",
}

export interface IBillingStatementProviderParams extends IPaginatedProviderParams {
  sortBy?: { column: BillingStatementServerColumn; direction: ServerSortDirection } | null;
  statementNumber?: number | null;
  createdOnRange?: { start: ISODateTimeString; end: ISODateTimeString } | null;
}

export type BillingStatementsDataProvider = IDataProvider<
  IBillingStatementTableEntry,
  IBillingStatementProviderParams
>;
