import * as React from "react";
import { Modal } from "antd";
import ModalMessageContext from "./ModalMessageContext";

const LocalizedModalMessages: React.FunctionComponent<{}> = (props) => {
  const [modalApi, modalContext] = Modal.useModal();

  return (
    <ModalMessageContext.Provider value={{ modalApi }}>
      <>{modalContext}</>
      <>{props.children}</>
    </ModalMessageContext.Provider>
  );
};

export default LocalizedModalMessages;
