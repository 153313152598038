import * as React from "react";
import { Select as AntSelect } from "antd";
// const { Option: AntOption, OptGroup: AntOptGroup } = AntSelect;
//
// const SelectSizes = ["default", "large", "small"];
//
// interface OptionProps {
//   disabled?: boolean;
//   value?: string | number;
//   title?: string;
//   children?: React.ReactNode;
//   className?: string;
//   style?: React.CSSProperties;
// }
//
// interface LabeledValue {
//   key: string;
//   label: React.ReactNode;
// }
//
// type SelectValue = string | string[] | number | number[] | LabeledValue | LabeledValue[];
//
// const ModeOptions = ["default", "multiple", "tags", "combobox", "SECRET_COMBOBOX_MODE_DO_NOT_USE"];
//
// type ModeOption = typeof ModeOptions[number];
//
// interface AbstractSelectProps {
//   prefixCls?: string;
//   className?: string;
//   showAction?: string | string[];
//   size?: typeof SelectSizes[number];
//   notFoundContent?: React.ReactNode | null;
//   transitionName?: string;
//   choiceTransitionName?: string;
//   showSearch?: boolean;
//   allowClear?: boolean;
//   disabled?: boolean;
//   showArrow?: boolean;
//   style?: React.CSSProperties;
//   tabIndex?: number;
//   placeholder?: string | React.ReactNode;
//   defaultActiveFirstOption?: boolean;
//   dropdownClassName?: string;
//   dropdownStyle?: React.CSSProperties;
//   dropdownMenuStyle?: React.CSSProperties;
//   dropdownMatchSelectWidth?: boolean;
//   onSearch?: (value: string) => void;
//   getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
//   filterOption?: boolean | ((inputValue: string, option: React.ReactElement<OptionProps>) => boolean);
//   id?: string;
//   defaultOpen?: boolean;
//   open?: boolean;
//   onDropdownVisibleChange?: (open: boolean) => void;
//   autoClearSearchValue?: boolean;
//   dropdownRender?: (menu?: React.ReactNode, props?: SelectProps) => React.ReactNode;
//   loading?: boolean;
// }
//
// interface SelectProps<T = SelectValue> extends AbstractSelectProps {
//   value?: T;
//   defaultValue?: T;
//   mode?: ModeOption;
//   optionLabelProp?: string;
//   firstActiveValue?: string | string[];
//   onChange?: (value: T, option: React.ReactElement<any> | React.ReactElement<any>[]) => void;
//   onSelect?: (value: T extends (infer I)[] ? I : T, option: React.ReactElement<any>) => void;
//   onDeselect?: (value: T extends (infer I)[] ? I : T) => void;
//   onBlur?: (value: T) => void;
//   onFocus?: () => void;
//   onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
//   onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
//   onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
//   onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
//   maxTagCount?: number;
//   maxTagTextLength?: number;
//   maxTagPlaceholder?: React.ReactNode | ((omittedValues: T[]) => React.ReactNode);
//   optionFilterProp?: string;
//   labelInValue?: boolean;
//   tokenSeparators?: string[];
//   getInputElement?: () => React.ReactElement<any>;
//   autoFocus?: boolean;
//   suffixIcon?: React.ReactNode;
//   removeIcon?: React.ReactNode;
//   clearIcon?: React.ReactNode;
//   menuItemSelectedIcon?: React.ReactNode;
// }
//
// class Select<T = SelectValue> extends React.Component<SelectProps<T>, {}> {
//   static Option: import("rc-select/lib/Option").OptionFC = AntOption;
//   static OptGroup: import("rc-select/lib/OptGroup").OptionGroupFC = AntOptGroup;
//
//   render() {
//     return <AntSelect {...(this.props as any)} />;
//   }
// }

export default AntSelect;
