import * as React from "react";
import { Form, List, Tooltip, Typography } from "antd";

import AddressFormatter from "../../../../../../components/formatters/address/AddressFormatter";
import { IInvoiceIssuerDataSet } from "../../../../models/settings.models";
import useSettingsSection from "../../../settings-section/useSettingsSection";
import { SettingsSection } from "../../../settings-section/SettingsSection";
import { Country } from "core/countries/countries.model";
import { FormProps } from "antd/lib/form";
import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import IssuerDataSetForm from "./IssuerDataSetForm";
import { FormattedMessage } from "react-intl";
import { useCallback } from "react";
import { guid } from "core/guid/guid";

interface IFormValues {
  dataSetName: string;
  companyName: string;
  taxId: string;
  addressLine1: string; // Street address, P.O box, company name
  addressLine2: string; // Apartment, suite, unit, building, flat, etc.
  addressLine3: string; // City.
  addressLine4: string; // State/Province/Region: string;
  postalCode: string;
  country: Country;
}

export const IssuerDataSet: React.FunctionComponent<{
  value: IInvoiceIssuerDataSet;
  onChange: (value: IInvoiceIssuerDataSet) => Promise<void>;
  initialIsEditing?: boolean;
  onSetDefault?: (id: guid) => Promise<void>;
  onRemove?: (id: guid) => Promise<void>;
  onCancel?: () => void;
}> = ({ initialIsEditing, value, onChange, onCancel, onSetDefault, onRemove }) => {
  const [form] = Form.useForm();
  const { isEditing, onEdit, onCancel: internalOnCancel } = useSettingsSection(initialIsEditing);

  const handleCancel = useCallback(() => {
    internalOnCancel();
    onCancel?.();
  }, [internalOnCancel, onCancel]);

  const handleSetDefault = useCallback(() => onSetDefault?.(value.id), [onSetDefault, value.id]);

  const handleSave = useCallback(() => {
    form.submit();
  }, [form]);

  const handleRemove = useCallback(async () => {
    await onRemove?.(value.id);
  }, [onRemove, value.id]);

  const onFinish: FormProps["onFinish"] = async (store) => {
    const values = store as IFormValues;

    const {
      companyName,
      dataSetName,
      country,
      postalCode,
      taxId,
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
    } = values;

    const issuerDataSet: IInvoiceIssuerDataSet = {
      id: value.id,
      dataSetName: dataSetName,
      isDefault: value.isDefault,
      companyName: companyName,
      taxId: {
        id: taxId,
        country: Country.Poland,
      },
      address: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        addressLine4: addressLine4,
        postalCode: postalCode,
        country: country,
      },
    };

    await onChange(issuerDataSet);

    handleCancel();
  };

  return (
    <SettingsSection
      isEditing={isEditing}
      onEdit={onEdit}
      onCancel={handleCancel}
      onSave={handleSave}
      onRemove={onRemove === undefined ? undefined : handleRemove}
    >
      <>
        {!isEditing && (
          <List.Item.Meta
            avatar={
              value.isDefault ? (
                <Tooltip
                  title={
                    <FormattedMessage
                      id="settings.issuer-data-set-is-default"
                      defaultMessage="Domyślny zestaw danych do wystawiania faktury"
                    />
                  }
                >
                  <CheckCircleTwoTone twoToneColor="#1890ff" />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <FormattedMessage
                      id="settings.issuer-data-set-as-default"
                      defaultMessage="Ustaw jako domyślny zestaw danych"
                    />
                  }
                >
                  <CheckCircleOutlined
                    className={"opacity-25 hover:opacity-50 cursor-pointer"}
                    onClick={handleSetDefault}
                  />
                </Tooltip>
              )
            }
            title={value.dataSetName}
            description={
              <div className={"flex flex-col"}>
                <Typography.Text type={"secondary"}>{value!.companyName}</Typography.Text>
                <Typography.Text type={"secondary"}>NIP: {value!.taxId.id}</Typography.Text>
                <Typography.Text type={"secondary"}>
                  <AddressFormatter address={value!.address} />
                </Typography.Text>
              </div>
            }
          />
        )}
        {isEditing && (
          <div className={"flex flex-1"}>
            <IssuerDataSetForm
              form={form}
              onFinish={onFinish}
              initialValues={{
                dataSetName: value.dataSetName,
                companyName: value.companyName,
                taxId: value.taxId.id,
                addressLine1: value.address.addressLine1,
                addressLine2: value.address.addressLine2,
                addressLine3: value.address.addressLine3,
                addressLine4: value.address.addressLine4,
                postalCode: value.address.postalCode,
                country: value.address.country,
              }}
            />
          </div>
        )}
      </>
    </SettingsSection>
  );
};
