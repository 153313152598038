import { useEffect } from "react";
import { Control, useWatch } from "react-hook-form";
import { isNullOrEmpty } from "core/utils/string-utils";
import { IOrderFormValues } from "features/orders/components/order-form/order-form.model";
import { usePrevious } from "react-use";

type OrderFormPricingListProps = {
  enabled: boolean;
  control: Control<IOrderFormValues, Object>;
  callback: () => void;
};

function useWatchOnLabChange(props: OrderFormPricingListProps) {
  const pickedLabIdString = useWatch({
    control: props.control,
    name: "labId",
    disabled: !props.enabled,
  });

  const previousPickedLabIdString = usePrevious(pickedLabIdString);

  useEffect(() => {
    if (!props.enabled) {
      return;
    }

    if (isNullOrEmpty(pickedLabIdString)) {
      return;
    }

    if (previousPickedLabIdString === undefined) {
      return;
    }

    if (previousPickedLabIdString === pickedLabIdString) {
      return;
    }

    props.callback();
  }, [pickedLabIdString, previousPickedLabIdString, props, props.callback, props.enabled]);
}

export default useWatchOnLabChange;
