export enum Status {
  ReadyForPickUpFromClinic = "ReadyForPickUpFromClinic",
  TodoInLab = "TodoInLab",
  InProgressInLab = "InProgressInLab",
  InProgressInClinic = "InProgressInClinic",
  Finished = "Finished",
}

export enum StatusCode {
  ReadyForPickUpFromClinic = 100,
  TodoInLab = 200,
  InProgressInLab = 300,
  InProgressInClinic = 400,
  Finished = 500,
}
