import { CreateGuid } from "core/guid/guid";
import { Country } from "core/countries/countries.model";

export const createEmptyIssuerDataSet = (isDefault: boolean) => {
  const emptyIssuerDataSet = {
    id: CreateGuid(),
    dataSetName: "",
    isDefault: isDefault,
    companyName: "",
    taxId: {
      country: Country.Poland,
      id: "",
    },
    address: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      addressLine4: "",
      postalCode: "",
      country: Country.Poland,
    },
  };

  return emptyIssuerDataSet;
};
