import { inRange } from "core/utils/number-utils";
import { PointId } from "core/point/point.enums";
import { LocatedPoint } from "features/orders/specification/point-specification";

export const isValidPointId = (number: number) =>
  inRange(PointId.P18, PointId.P11, number) ||
  inRange(PointId.P21, PointId.P28, number) ||
  inRange(PointId.P48, PointId.P41, number) ||
  inRange(PointId.P31, PointId.P38, number);

export const isPointId = (point: number): point is PointId =>
  typeof point === "number" && isValidPointId(point);

export const isArrayOfPointIds = (points: any): points is PointId[] =>
  Array.isArray(points) && points.every((p) => isPointId(p));

export const isArrayOfLocatedPoints = (points: any): points is LocatedPoint[] => {
  return (
    Array.isArray(points) &&
    points.every((p: LocatedPoint) => p.location !== undefined && isPointId(p.location))
  );
};
