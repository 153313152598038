import * as React from "react";
import useSWR from "swr";
import PricingListApiContext from "features/pricing-lists/pricing-list.context";
import { nameof } from "ts-simple-nameof";
import { IPricingListApi } from "features/pricing-lists/service/IPricingListApi";
import {
  IUpdatePricingEntryRequest,
} from "features/pricing-lists/pricing-list.models";

function useMainPricingList() {
  const pricingListApi = React.useContext(PricingListApiContext);

  const {
    data: getPricingList,
    isValidating: isValidating,
    mutate: revalidate,
  } = useSWR(
    () => [nameof<IPricingListApi>((x) => x.getDefault)],
    async () => pricingListApi.getDefault()
  );

  const setPricingEntry = React.useCallback(
    async (request: IUpdatePricingEntryRequest) => pricingListApi.setPricingEntry(request),
    [pricingListApi]
  );

  return {
    getPricingList,
    setPricingEntry,
    isValidating: isValidating,
    revalidate: revalidate,
  };
}

export default useMainPricingList;
