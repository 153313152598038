import AuthContext from "features/auth/auth-context";
import { UserRights } from "features/auth/contracts/auth-contracts.dto";

export const routeGuards = {
  isAuthenticated: (authContext: AuthContext) => authContext.userId !== null,
  isDentist: (authContext: AuthContext) => authContext.isDentist,
  isLabOwner: (authContext: AuthContext) => authContext.isLabOwner,
  isChatEnabled: () => false,
  isSubscriptionPageAvailable: () => false,
  isSettingsPageAvailable: () => false,
  hasAccessToInvoices: (authContext: AuthContext) => authContext.hasRight(UserRights.READ_INVOICES),
  hasAccessToLabClients: (authContext: AuthContext) => authContext.isLabOwner || authContext.isLabEmployee,
  hasAccessToLabEmployees: (authContext: AuthContext) => authContext.isLabOwner || authContext.isLabEmployee,
} as const;

export type Guard = keyof typeof routeGuards;
