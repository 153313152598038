import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Alert } from "antd";

export const SelectAtLeastTwoPoints = () =>
  <Alert
    className="m-4"
    type="error"
    showIcon
    message={
      <FormattedMessage
        id={"select-at-least-two-points.please-pick-at-least-2-points"}
        defaultMessage={"Nie dokonano wyboru, proszę wybrać przynajmniej 2 punkty"}
      />
    }
  />
