import * as React from "react";
import { ArrowRightOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { ColorOrNone, NO_COLOR } from "features/orders/color/color.model";
import ShadeGuideFormatter from "components/formatters/shade/ShadeGuideFormatter";
import ShadeFormatter from "components/formatters/shade/ShadeFormatter";
import { ColorCode } from "features/orders/color/dto/color.dto";
import { mapColorCodeToColor } from "features/orders/color/dto/color.mappers";

interface IColorChangedProps {
  previous: ColorCode | ColorOrNone | null;
  next: ColorCode | ColorOrNone | null;
}

const ColorChanged: React.FC<IColorChangedProps> = ({ previous: prevArg, next: nextArg }) => {
  const next =
    nextArg === null
      ? null
      : nextArg === NO_COLOR
      ? null
      : typeof nextArg !== "number"
      ? nextArg
      : mapColorCodeToColor(nextArg);

  const previous =
    prevArg === null
      ? null
      : prevArg === NO_COLOR
      ? null
      : typeof prevArg !== "number"
      ? prevArg
      : mapColorCodeToColor(prevArg);

  return (
    <>
      {previous === null ? (
        <CloseSquareOutlined />
      ) : (
        <>
          <ShadeGuideFormatter guide={previous.guide} />
          {" - "}
          <ShadeFormatter shade={previous.shade} />
        </>
      )}
      <ArrowRightOutlined className={"mx-2"} />
      {next === null ? (
        <CloseSquareOutlined />
      ) : (
        <>
          <ShadeGuideFormatter guide={next.guide} />
          {" - "}
          <ShadeFormatter shade={next.shade} />
        </>
      )}
    </>
  );
};

export default ColorChanged;
